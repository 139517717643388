<template>
  <search-dialog-list-item
    icon="clipboard-alt"
    :subtitle="
      selected.length
        ? $t('search_and_filter.n_items_selected', {
            count: selected.length,
            name: ''
          })
        : null
    "
    :title="$t('common.status', { count: 0 })"
    :toolbar-title="$t('common.status', { count: 0 })"
  >
    <base-list
      :multiple="true"
      :filterable="false"
      :value.sync="selected"
      :selected.sync="selected"
      :items="projectStatuses ? Object.values(projectStatuses) : []"
    />
  </search-dialog-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseList from '@/components/BaseList'
import SearchDialogListItem from '@/components/search/SearchDialogListItem'
export default {
  props: {
    option: {
      required: true
    }
  },
  computed: {
    ...mapGetters(['project', 'projectStatuses'])
  },
  data() {
    return {
      selected: []
    }
  },
  components: {
    BaseList,
    SearchDialogListItem
  },
  watch: {
    selected(newValue) {
      this.$emit('update:option', newValue)
    }
  }
}
</script>
