import { updateObject } from '@/utils/helpers'
import { groups, subsets } from '@/constants/my-tasks'
import { fetchMyTasks, fetchMyTasksCount } from '@/api/my-tasks'
import {
  SET_MY_TASKS,
  ADD_MY_TASKS,
  SET_MY_TASKS_COUNT,
  SET_MY_TASKS_GROUP,
  SET_MY_TASKS_SUBSET,
  SET_MY_TASKS_TASK_VALUE
} from '../mutation-types'

const myTasks = {}
const subsetsObj = {}

for (const key of subsets) {
  subsetsObj[key] = null
}

for (const key of groups) {
  myTasks[key] = { ...subsetsObj }
}

const state = {
  myTasks,
  myTasksCount: null,
  myTasksGroup: 'all',
  myTasksSubset: 'today'
}

const getters = {
  myTasks: state => state.myTasks,
  myTasksGroup: state => state.myTasksGroup,
  myTasksSubset: state => state.myTasksSubset,
  myTasksCount: state => state.myTasksCount
}

const mutations = {
  [SET_MY_TASKS](state, { group, subset, myTasks }) {
    state.myTasks[group][subset] = myTasks
  },
  [SET_MY_TASKS_COUNT](state, myTasksCount) {
    state.myTasksCount = myTasksCount
  },
  [SET_MY_TASKS_GROUP](state, myTasksGroup) {
    state.myTasksGroup = myTasksGroup
  },
  [SET_MY_TASKS_SUBSET](state, myTasksSubset) {
    state.myTasksSubset = myTasksSubset
  },
  [ADD_MY_TASKS](state, { group, subset, myTasks }) {
    const set = state.myTasks[group][subset]
    set.offset_token = myTasks.offset_token
    set.records = [...set.records, ...myTasks.records]
  },
  [SET_MY_TASKS_TASK_VALUE](state, { tid, keys, values }) {
    const myTasks = state.myTasks[state.myTasksGroup][
      state.myTasksSubset
    ]
    if (myTasks === null) {
      return
    }
    const task = myTasks.records.find(task => task.id === tid)
    if (!task) {
      return
    }
    for (let i = 0; i < keys.length; i++) {
      if (task[keys[i]] !== undefined) {
        updateObject(task, values[i], keys[i])
      }
    }
  }
}

const actions = {
  async getMyTasks({ commit }, params) {
    const { group, subset } = params
    const { data } = await fetchMyTasks(params)
    commit(SET_MY_TASKS, { myTasks: data.data, group, subset })
  },
  async getMyTasksTaskCount({ commit }, params) {
    const { data } = await fetchMyTasksCount(params)
    commit(SET_MY_TASKS_COUNT, data.data)
  },
  async addMyTasks({ commit }, params) {
    const { group, subset } = params
    const { data } = await fetchMyTasks(params)
    commit(ADD_MY_TASKS, { myTasks: data.data, group, subset })
  },
  setMyTasksGroup({ commit }, myTasksGroup) {
    commit(SET_MY_TASKS_GROUP, myTasksGroup)
  },
  setMyTasksSubset({ commit }, myTasksSubset) {
    commit(SET_MY_TASKS_SUBSET, myTasksSubset)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
