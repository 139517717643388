<template>
  <centered-layout>
    <template v-slot:appbar>
      <v-toolbar-title class="pa-0 flex-grow-1 d-flex justify-center">
        <base-logo />
      </v-toolbar-title>
    </template>
    <div class="flex-grow-1 d-flex justify-start flex-column fill-width">
      <sessions-login
        :loading="loading"
        v-if="!organizationNotFound"
        :organization="organization"
        :password.sync="form.password"
        :identifier.sync="form.identifier"
        @form-submitted="handleClickOnSignIn"
        :no-organization="!organizationSubdomain"
      />
    </div>
  </centered-layout>
</template>

<script>
import BaseLogo from '@/components/BaseLogo'
import { mapActions, mapGetters } from 'vuex'
import CenteredLayout from '@/layouts/CenteredLayout'
import SessionsLogin from '@/components/sessions/SessionsLogin'

export default {
  data() {
    return {
      loading: false,
      form: {
        identifier: null,
        password: null
      },
      organizationNotFound: false
    }
  },
  computed: {
    ...mapGetters(['invitation', 'organization', 'organizationSubdomain']),
    token() {
      return this.$route.query.invitationToken || null
    }
  },
  methods: {
    ...mapActions([
      'login',
      'getInvitation',
      'setInvitationToken',
      'getOrganizationByUrl'
    ]),
    toggleLoading() {
      this.loading = !this.loading
    },
    async getInvitationInfo() {
      const { token } = this
      try {
        await this.getInvitation(token)
        if (this.invitation.token_type === 'public') {
          this.setInvitationToken(this.invitation.token)
        }
      } catch (error) {
        this.setInvitationToken(null)
      }
    },
    async getData(url) {
      try {
        await this.getOrganizationByUrl({
          params: {
            url
          },
          errorHandle: false
        })
      } catch (error) {
        this.organizationNotFound = true
        this.$router.push({
          path: '/error'
        })
      }
    },
    handleClickOnSignIn() {
      const organizationObject = this.organizationSubdomain
        ? { organization_id: this.organizationSubdomain }
        : {}
      const user = {
        ...this.form,
        ...organizationObject
      }
      const requestOptions = {
        url: '/users/login'
      }
      this.loading = true
      this.login({ user, requestOptions, doneCallback: this.toggleLoading })
    }
  },
  created() {
    if (!this.organizationSubdomain) {
      return
    }
    if (!this.organization) {
      this.getData(location.origin)
    }
    if (this.token) {
      this.getInvitationInfo()
    }
  },
  components: {
    BaseLogo,
    SessionsLogin,
    CenteredLayout
  }
}
</script>
