<template>
  <div class="text-center pe-2" v-if="menuVisible">
    <v-btn
      icon
      small
      class="me-2"
      @click="$emit('create-task-clicked')"
      v-if="!isMobile && createTaskVisible"
    >
      <base-icon name="plus" width="20" height="20" color="secondary.darken2" />
    </v-btn>
    <v-menu
      bottom
      :right="$vuetify.rtl"
      :left="!$vuetify.rtl"
      transition="slide-x-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small color="info" v-bind="attrs" v-on="on">
          <base-icon name="ellipsis-v" color="secondary.darken2" />
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          :key="index"
          v-for="(item, index) in items"
          :class="{ 'd-none': !item.visible }"
          @click="$emit(`${item.action}-clicked`)"
        >
          <v-list-item-icon class="me-4">
            <base-icon
              :name="item.icon"
              :color="item.iconColor"
              className="d-flex align-center"
            />
          </v-list-item-icon>
          <v-list-item-title class="body-1" :class="item.textClass">
            {{ $t(`common.${item.title}`) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
const actions = ['watch', 'edit_title', 'edit_board', 'copy', 'archive']
export default {
  props: {
    list: {
      type: Object,
      required: true
    },
    createTaskVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['teamAbilities', 'isMobile', 'abilitiesPriorityMap']),
    itemsVisibility() {
      return actions.reduce((obj, action) => {
        obj[action] =
          this.teamAbilities.lists[this.list.id] >=
          this.abilitiesPriorityMap.List[action]
        return obj
      }, {})
    },
    items() {
      return [
        {
          action: 'watch',
          visible: this.itemsVisibility.watch,
          title: this.list.watched ? 'watching' : 'watch',
          icon: this.list.watched ? 'eye-slash' : 'eye',
          iconColor: this.list.watched ? 'primary' : 'secondary.darken2',
          textClass: this.list.watched ? 'primary--text' : 'info--text'
        },
        {
          action: 'rename',
          visible: this.itemsVisibility.edit_title,
          title: 'rename',
          icon: 'edit-alt',
          iconColor: 'secondary.darken2',
          textClass: 'info--text'
        },
        {
          action: 'move',
          visible: this.itemsVisibility.edit_board,
          title: 'move',
          icon: 'exchange',
          iconColor: 'secondary.darken2',
          textClass: 'info--text'
        },
        {
          action: 'copy',
          visible: this.itemsVisibility.copy,
          title: 'copy',
          icon: 'copy',
          iconColor: 'secondary.darken2',
          textClass: 'info--text'
        },
        {
          action: 'archive',
          visible: this.itemsVisibility.archive,
          title: this.list.archived ? 'unarchive' : 'archive',
          icon: this.list.archived ? 'archive' : 'archive',
          iconColor: 'secondary.darken2',
          textClass: 'info--text'
        }
      ]
    },
    menuVisible() {
      return Object.values(this.itemsVisibility).some((item) => item)
    }
  },
  components: {
    BaseIcon
  }
}
</script>
