<template>
  <centered-layout>
    <template v-slot:appbar>
      <v-toolbar-title class="pa-0 flex-grow-1 d-flex justify-center">
        <base-logo />
      </v-toolbar-title>
    </template>
    <sessions-organization-subdomain
      :loading="loading"
      :subdomain.sync="form.subdomain"
      @form-submitted="handleClickOnNext"
    />
    <v-btn
      block
      depressed
      color="primary"
      :loading="loading"
      v-text="$t('common.next')"
      @click="handleClickOnNext"
      :disabled="!form.subdomain"
    />
    <v-btn
      block
      depressed
      color="secondary"
      class="mt-4"
      outlined
      :loading="loading"
      v-text="$t('common.skip')"
      @click="$router.push('/signin')"
    />
    <p class="body-2 secondary--text mt-4">
      {{ $t('page.sign_in.do_not_have_an_account') }}
      <router-link
        :to="{ path: '/signup' }"
        class="text-decoration-none"
        v-text="$t('page.sign_in.sign_up')"
      />
    </p>
  </centered-layout>
</template>

<script>
import BaseLogo from '@/components/BaseLogo'
import CenteredLayout from '@/layouts/CenteredLayout'
import SessionsOrganizationSubdomain from '@/components/sessions/SessionsOrganizationSubdomain'
export default {
  data() {
    return {
      loading: false,
      form: {
        subdomain: null
      }
    }
  },
  methods: {
    async handleClickOnNext() {
      this.loading = true
      const { host } = window.location
      const { subdomain } = this.form
      try {
        this.loading = false
        window.location = `${location.protocol}//${subdomain}.${host}`
      } catch (error) {
        this.loading = false
      }
    }
  },
  components: {
    BaseLogo,
    CenteredLayout,
    SessionsOrganizationSubdomain
  }
}
</script>
