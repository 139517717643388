<template>
  <v-list dense flat color="transparent" class="pt-0">
    <v-list-item-group
      color="primary"
      v-if="showAnyAndNone"
      v-model="noneOrAnyTags"
      @change="handleNoneOrAnyTagChange"
    >
      <v-list-item :ripple="false" value="none">
        <v-list-item-content>
          <v-list-item-title v-text="'None'"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon class="my-2" v-if="noneOrAnyTags === 'none'">
          <base-icon name="check" color="primary" />
        </v-list-item-icon>
      </v-list-item>
      <v-list-item :ripple="false" value="any">
        <v-list-item-content>
          <v-list-item-title v-text="'Any'"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon class="my-2" v-if="noneOrAnyTags === 'any'">
          <base-icon name="check" color="primary" />
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
    <v-list-item-group
      v-model="taskTag"
      :multiple="multiple"
      :mandatory="mandatory"
      @change="handleTagChange"
      active-class="primary--text"
    >
      <v-list-item
        :key="tag.id"
        :ripple="false"
        :value="tag.id"
        v-for="tag in tags"
      >
        <template v-slot:default="{ active }">
          <v-list-item-avatar
            size="24"
            class="justify-center"
            color="secondary lighten-2"
          >
            <base-icon name="tag" width="13" height="13" color="primary" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="tag.title" />
          </v-list-item-content>
          <v-list-item-icon class="my-2" v-if="active">
            <base-icon name="check" color="primary" />
          </v-list-item-icon>
        </template>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import BaseIcon from './BaseIcon'
export default {
  components: { BaseIcon },
  props: {
    tag: {
      required: true
    },
    tags: {
      required: true
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showAnyAndNone: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    allTags() {
      if (this.noneOrAnyTags === 'any') {
        return ['any']
      }
      if (this.noneOrAnyTags === 'none') {
        return ['none', ...this.taskTag.filter((tag) => tag !== 'any')]
      }
      return this.taskTag
    }
  },
  data() {
    return {
      taskTag: this.tag,
      noneOrAnyTags: null
    }
  },
  methods: {
    emitChange() {
      this.$emit('update:tag', this.allTags)
    },
    handleNoneOrAnyTagChange(val) {
      if (val === 'any') {
        this.taskTag = []
      }
      this.emitChange()
    },
    handleTagChange() {
      if (this.noneOrAnyTags === 'any') {
        this.noneOrAnyTags = null
      }
      this.emitChange()
    },
    showCheckIcon(index) {
      const { taskTag } = this
      if (!this.multiple && taskTag === null) {
        return false
      }
      if (this.multiple) {
        return taskTag.indexOf(index) > -1
      }
      return index === taskTag
    },
    showItem(index) {
      if (!this.showAnyAndNone) {
        return true
      }
      return index > 0
    }
  }
}
</script>
