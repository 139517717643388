import {
  SET_LOADING,
  SET_IS_MOBILE,
  SET_INTERFACE_DIALOG,
  SET_TRANSLATION_INITIALIZED,
  SET_CREATE_PROJECT_DIALOG_VISIBLE,
  SET_CREATE_ORGINIZATION_DIALOG_VISIBLE
} from '../mutation-types'
import mobile from 'is-mobile'

const state = {
  interfaceDialogs: {
    inviteOrganizationMember: false
  },
  loading: false,
  isMobile: false,
  isMobileDevice: mobile(),
  translationInitialized: false,
  createProjectDialogVisible: false,
  createOrganizationDialogVisible: false
}

const getters = {
  loading: state => state.loading,
  isMobile: state => state.isMobile,
  isMobileDevice: state => state.isMobileDevice,
  interfaceDialogs: state => state.interfaceDialogs,
  responsiveDialogProps: state => ({
    fullscreen: state.isMobile,
    hideOverlay: state.isMobile,
    width: state.isMobile ? '100%' : '56%',
    height: state.isMobile ? '100%' : '90%'
  }),
  translationInitialized: state => state.translationInitialized,
  createProjectDialogVisible: state => state.createProjectDialogVisible,
  createOrganizationDialogVisible: state =>
    state.createOrganizationDialogVisible
}

const mutations = {
  [SET_LOADING](state, loading) {
    state.loading = loading
  },
  [SET_INTERFACE_DIALOG](state, { prop, value }) {
    state.interfaceDialogs[prop] = value
  },
  [SET_TRANSLATION_INITIALIZED](state, translationInitialized) {
    state.translationInitialized = translationInitialized
  },
  [SET_IS_MOBILE](state, isMobile) {
    state.isMobile = isMobile
  },
  [SET_CREATE_PROJECT_DIALOG_VISIBLE](state, createProjectDialogVisible) {
    state.createProjectDialogVisible = createProjectDialogVisible
  },
  [SET_CREATE_ORGINIZATION_DIALOG_VISIBLE](
    state,
    createOrganizationDialogVisible
  ) {
    state.createOrganizationDialogVisible = createOrganizationDialogVisible
  }
}

const actions = {
  toggleLoading({ commit }, loading) {
    commit(SET_LOADING, loading)
  },
  setInterfaceDialog({ commit }, { prop, value }) {
    commit(SET_INTERFACE_DIALOG, { prop, value })
  },
  setTranslationInitialized({ commit }, translationInitialized) {
    commit(SET_TRANSLATION_INITIALIZED, translationInitialized)
  },
  showCreateOrganizationDialog({ commit }) {
    commit(SET_CREATE_ORGINIZATION_DIALOG_VISIBLE, true)
  },
  hideCreateOrganizationDialog({ commit }) {
    commit(SET_CREATE_ORGINIZATION_DIALOG_VISIBLE, false)
  },
  showCreateProjectDialog({ commit }) {
    commit(SET_CREATE_PROJECT_DIALOG_VISIBLE, true)
  },
  hideCreateProjectDialog({ commit }) {
    commit(SET_CREATE_PROJECT_DIALOG_VISIBLE, false)
  },
  setIsMobile({ commit }, isMobile) {
    commit(SET_IS_MOBILE, isMobile)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
