<template>
  <centered-layout>
    Page Not Found!
  </centered-layout>
</template>

<script>
import CenteredLayout from '@/layouts/CenteredLayout'
export default {
  components: {
    CenteredLayout
  }
}
</script>
