import Vue from 'vue'
import appRoute from './app'
import VueRouter from 'vue-router'
import subdomainRoute from './subdomain'
import { getOrganizationSubdomain } from '@/utils/helpers'

Vue.use(VueRouter)

const routes = getOrganizationSubdomain() ? subdomainRoute : appRoute

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
