<template>
  <fragment>
    <h3
      v-if="showSubtitle"
      class="text-h1 info--text fill-width mt-4 mb-8"
      v-text="$t('page.account_verification.subtitle')"
    />
    <v-form
      class="
        d-flex
        fill-width
        flex-grow-1
        flex-column
        align-center
        justify-center
      "
    >
      <section class="body-2 secondary--text text--darken-2">
        <base-code-input
          :loading="loading"
          @change="handleCodeInputChange"
          @complete="handleAccountVerification"
        />
        <p
          class="mt-14 px-4 caption secondary--text text--darken-2"
          v-html="
            $t('common.check_email_for_verification_code', { email: handle })
          "
        />
        <p class="mt-6 px-4">
          <span class="caption secondary--text" v-text="$t('common.did_not_receive_email')" />
          <a
            href="#"
            v-if="!cooldown"
            :title="$t('common.resend')"
            class="caption text-decoration-none"
            @click.prevent="$emit('resend-clicked')"
            :class="{ 'text--disabled': resendLoading }"
          >
            {{ $t('common.resend') }}
            <v-progress-circular
              :size="10"
              :width="1"
              indeterminate
              v-show="resendLoading"
              color="secondary darken4"
            />
          </a>
          <template v-else>
            <span class="ms-1 caption secondary--text" v-text="$t('common.resend_in')" />
            <base-seconds-count-down :seconds.sync="cooldown" />
          </template>
        </p>
      </section>
    </v-form>
    <v-btn
      block
      depressed
      v-if="showBtn"
      ref="submitBtn"
      color="primary"
      :loading="loading"
      class="mb-8 flex-grow-0"
      :disabled="!codeInserted"
      @click="handleAccountVerification"
    >
      {{ $t('common.verify') }}
    </v-btn>
  </fragment>
</template>

<script>
import BaseCodeInput from '@/components/BaseCodeInput'
import BaseSecondsCountDown from '@/components/BaseSecondsCountDown'
export default {
  props: {
    handle: {
      type: String,
      requird: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    resendLoading: {
      type: Boolean,
      default: false
    },
    showSubtitle: {
      type: Boolean,
      default: true
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    verificationEmailCooldown: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      code: null,
      cooldown: 0,
      codeInserted: false
    }
  },
  methods: {
    handleCodeInputChange(code) {
      this.code = code
      this.codeInserted = false
    },
    handleCodeInputComplete() {
      const submitBtn = this.$refs.submitBtn
      submitBtn.click()
    },
    handleAccountVerification(code) {
      this.code = code
      this.codeInserted = true
      this.$emit('verify-clicked', Number(this.code))
    }
  },
  watch: {
    verificationEmailCooldown(cooldown) {
      this.cooldown = cooldown
    }
  },
  components: { BaseCodeInput, BaseSecondsCountDown }
}
</script>
