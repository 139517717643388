<template>
  <section class="px-4 flex-grow-1 d-flex flex-column">
    <div class="flex-grow-1">
      <h2
        class="my-4 text-h3 info--text"
        v-text="
          $t(
            'page.project_settings.members.invitation.invite_by_link.add_by_link'
          )
        "
      />
      <v-text-field
        flat
        dense
        outlined
        readonly
        hide-details
        :loading="loading"
        ref="invitationLink"
        :value="invitationLink"
        class="body-1 text-left"
        :color="loading ? 'primary' : 'secondary lighten-2'"
      >
        <v-tooltip
          slot="append"
          v-model="copied"
          :left="!$vuetify.rtl"
          :right="$vuetify.rtl"
        >
          <template v-slot:activator="{}">
            <span class="cursor pointer ps-4" @click="copyLinkClipboard">
              <base-icon name="copy" color="secondary.darken2" />
            </span>
          </template>
          <span>{{ $t('common.copied') }}</span>
        </v-tooltip>
      </v-text-field>
      <h3
        class="my-4 body-1 secondary--text text--darken-2"
        v-text="
          $t('page.project_settings.members.invitation.invite_by_link.content')
        "
      />
      <ul class="body-1 info--text">
        <li v-for="item in items" :key="item.id" v-text="item.title" />
      </ul>
    </div>
    <div class="flex-grow-0 py-4">
      <v-btn
        block
        depressed
        color="primary"
        v-text="$t('common.done')"
        @click="$emit('go-to-start')"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import { createInvitation } from '@/api/invitations'
export default {
  props: {
    teams: {
      type: Object,
      required: true
    },
    selectedTeams: {
      type: Array,
      required: true
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['organizationSubdomain']),
    items() {
      return this.teams.records.filter(
        (team) => this.selectedTeams.indexOf(team.id) > -1
      )
    }
  },
  data() {
    return {
      copied: false,
      loading: false,
      invitationLink: null
    }
  },
  methods: {
    toogleTooltip() {
      this.copied = !this.copied
    },
    copyLinkClipboard() {
      const textToCopy = this.$refs.invitationLink.$el.querySelector('input')
      textToCopy.select()
      document.execCommand('copy')
      this.toogleTooltip()
      setTimeout(this.toogleTooltip, 700)
    },
    async generateInvitationLink() {
      this.loading = true
      try {
        const { data } = await createInvitation({
          team_ids: this.selectedTeams,
          org_id: this.organizationSubdomain,
          project_id: this.$route.params.pid
        })
        this.invitationLink = data.data.url
      } catch (error) {}
      this.loading = false
    }
  },
  mounted() {
    this.generateInvitationLink()
  },
  watch: {
    dialog() {
      this.invitationLink = null
      this.generateInvitationLink()
    }
  },
  components: {
    BaseIcon
  }
}
</script>
