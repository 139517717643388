<template>
  <v-list>
    <settings-dialog-teams-and-access-teams-list-item
      :team="team"
      :key="team.id"
      v-for="team in teams"
      @item-clicked="$emit('item-clicked', $event)"
    />
  </v-list>
</template>

<script>
import SettingsDialogTeamsAndAccessTeamsListItem from '@/components/project/SettingsDialogTeamsAndAccessTeamsListItem'
export default {
  props: {
    teams: {
      type: Array,
      required: true
    }
  },
  components: {
    SettingsDialogTeamsAndAccessTeamsListItem
  }
}
</script>
