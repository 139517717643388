<template>
  <div v-if="members.length > 0">
    <section class="px-4 mt-2">
      <v-alert :icon="false" color="info" type="info" class="mb-2">
        <v-row align="center">
          <v-col
            class="grow caption py-0"
            v-text="
              $t('page.organization_settings.members.pending_members.alert', {
                count: members.length
              })
            "
          />
          <v-col class="shrink py-0 px-0">
            <v-btn text small @click="dialog = true">
              {{ $t('common.view') }}
              <base-angle-icon
                flipped
                width="20"
                height="20"
                fill="secondary.lighten2"
                class="d-flex align-center"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </section>
    <base-dialog
      card-color="white"
      :dialog.sync="dialog"
      content-class="small-level-two"
      :title="$t('page.project_settings.members.pending_members')"
    >
      <v-overlay :absolute="false" :value="loading">
        <v-progress-circular indeterminate size="32" />
      </v-overlay>
      <base-members-list-with-actions
        :members="members"
        @action-clicked="$emit('action-clicked', $event)"
      >
        <template v-slot:actions="scope">
          <v-btn icon @click="scope.next('resend')">
            <base-icon
              width="24"
              height="24"
              name="redo"
              color="secondary.base"
            />
          </v-btn>
          <v-btn icon @click="scope.next('delete')">
            <base-icon width="24" height="24" name="times" color="error" />
          </v-btn>
        </template>
      </base-members-list-with-actions>
    </base-dialog>
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import BaseDialog from '@/components/BaseDialog'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import BaseMembersListWithActions from '@/components/BaseMembersListWithActions'
export default {
  props: {
    members: {
      type: Array,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      member: null,
      dialog: false
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseAngleIcon,
    BaseMembersListWithActions
  }
}
</script>
