import {
  fetchTeam,
  fetchTeams,
  createTeam,
  deleteTeams,
  fetchTeamAbilities,
  createTeamMemberships,
  deleteTeamMemberships
} from '@/api/teams'
import {
  ADD_TEAM,
  SET_TEAM,
  SET_TEAMS,
  DELETE_TEAM,
  SET_USER_TEAMS,
  ADD_TEAM_MEMBERS,
  SET_TEAM_ABILITIES,
  DELETE_TEAM_MEMBERS
} from '../mutation-types'

const state = {
  team: null,
  teams: null,
  userTeams: null,
  teamAbilities: null
}

const mutations = {
  [SET_TEAMS](state, teams) {
    state.teams = teams
  },
  [SET_TEAM](state, team) {
    state.team = team
  },
  [SET_TEAM_ABILITIES](state, teamAbilities) {
    const convert = list => {
      return list.reduce((obj, item) => {
        obj[item.record.id] = item.priority
        return obj
      }, {})
    }
    state.teamAbilities = {
      lists: convert(
        teamAbilities.filter(ability => ability.record.type === 'List')
      ),
      boards: convert(
        teamAbilities.filter(ability => ability.record.type === 'Board')
      )
    }
  },
  [SET_USER_TEAMS](state, userTeams) {
    state.userTeams = userTeams
  },
  [ADD_TEAM](state, team) {
    state.teams.records = [...state.teams.records, { ...team, memberships: [] }]
  },
  [ADD_TEAM_MEMBERS](state, { tid, members }) {
    const index = state.teams.records.findIndex(team => team.id === tid)
    if (index < 0) {
      return
    }
    state.teams.records[index].memberships = [
      ...state.teams.records[index].memberships,
      ...members
    ]
  },
  [DELETE_TEAM](state, tid) {
    const index = state.teams.records.findIndex(team => team.id === tid)
    if (index < 0) {
      return
    }
    state.teams.records.splice(index, 1)
  },
  [DELETE_TEAM_MEMBERS](state, { tid, mids }) {
    const index = state.teams.records.findIndex(team => team.id === tid)
    if (index < 0) {
      return
    }
    for (let i = 0; i < mids.length; i++) {
      const membershipIndex = state.teams.records[index].memberships.findIndex(
        m => m.user.id === mids[i]
      )
      if (membershipIndex > -1) {
        state.teams.records[index].memberships.splice(membershipIndex, 1)
      }
    }
  }
}

const actions = {
  async getTeams({ commit }, { pid, params }) {
    const { data } = await fetchTeams(pid, params)
    commit(SET_TEAMS, data.data)
  },
  async getTeam({ commit }, { tid, params }) {
    const { data } = await fetchTeam(tid, params)
    commit(SET_TEAM, data.data)
  },
  async getTeamAbilities({ commit }, pid) {
    const { data } = await fetchTeamAbilities(pid)
    commit(SET_TEAM_ABILITIES, data.data)
  },
  setTeam({ commit }, team) {
    commit(SET_TEAM, team)
  },
  async getUserTeams({ commit }, { pid, params }) {
    commit(SET_USER_TEAMS, null)
    const { data } = await fetchTeams(pid, params)
    commit(SET_USER_TEAMS, data.data)
  },
  async addTeam({ commit }, { pid, data }) {
    const result = await createTeam(pid, data)
    commit(ADD_TEAM, result.data.data)
  },
  async addTeamMembers({ commit }, { tid, data }) {
    const result = await createTeamMemberships(tid, data)
    commit(ADD_TEAM_MEMBERS, { tid, members: result.data.data })
  },
  async deleteTeamMembers({ commit }, { tid, data }) {
    await deleteTeamMemberships(tid, data)
    commit(DELETE_TEAM_MEMBERS, { tid, mids: data.user_ids })
  },
  async deleteProjectTeams({ commit }, { pid, tid }) {
    await deleteTeams(pid, { ids: tid })
    commit(DELETE_TEAM, tid)
  }
}

const getters = {
  team: state => state.team,
  teams: state => state.teams,
  userTeams: state => state.userTeams,
  teamAbilities: state => state.teamAbilities
}

export default {
  state,
  actions,
  getters,
  mutations
}
