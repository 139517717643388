<template>
  <div
    class="d-flex flex-row me-10"
    :class="{ 'mb-2': !isLastItem, 'mb-6': isLastItem && isFirstGroup }"
  >
    <base-avatar
      :size="32"
      :title="comment.creator.name"
      :image="comment.creator.avatar ? comment.creator.avatar.medium : ''"
    />
    <v-card
      flat
      width="100%"
      :color="editing ? 'blue lighten-4' : 'white'"
      :class="{
        'rounded-tr-sm rounded-tl-lg': $vuetify.rtl,
        'rounded-tl-sm rounded-tr-lg': !$vuetify.rtl
      }"
      class="ms-2 rounded-bl-lg rounded-br-lg overflow-hidden"
    >
      <v-list-item class="pe-2" style="min-height: auto">
        <v-list-item-content class="py-0">
          <v-list-item-title
            v-text="comment.creator.name"
            class="caption secondary--text text--darken-2"
          />
        </v-list-item-content>
        <v-list-item-action class="ms-1 my-2">
          <task-dialog-comments-comment-actions
            @delete-comment-clicked="deleteConfiramtionSheet = true"
            @edit-comment-clicked="$emit('edit-comment-clicked', comment)"
          />
        </v-list-item-action>
      </v-list-item>
      <v-card-text
        style="height: auto"
        class="caption info--text py-0 ql-editor fill-width"
      >
        <base-file-preview
          class="px-0 mt-2"
          :deletable="false"
          v-if="comment.attachment"
          :file="comment.attachment"
          :failed="attachment.failed"
          :progress="attachment.progress"
          :uploading="attachment.uploading"
          @retry-file-upload-clicked="startFileUpload"
          @cancel-file-upload-clicked="cancelFileUpload"
        />
        <div class="comment-text-wrapper" v-html="html" />
      </v-card-text>
      <v-card-actions class="caption secondary--text px-4 justify-end">
        <span
          class="me-2"
          v-if="comment.edited"
          v-text="`(${$t('page.task_comments.edited')})`"
        />
        <base-date-time
          v-if="comment.created_at"
          :date="comment.created_at"
          format="time"
        />
      </v-card-actions>
    </v-card>
    <base-inline-form
      :title="$t('common.delete')"
      :value.sync="deleteConfiramtionSheet"
    >
      <p
        class="text-start body-2 secondary--text"
        v-text="$t('page.task_comments.delete_confirmation.content')"
      />
      <v-container fluid>
        <v-row>
          <v-col cols="4" class="ps-0">
            <v-btn
              block
              outlined
              color="secondary"
              :disabled="deleting"
              v-text="$t('common.no')"
              @click="deleteConfiramtionSheet = false"
            />
          </v-col>
          <v-col cols="8" class="pe-0">
            <v-btn
              block
              depressed
              color="error"
              :loading="deleting"
              v-text="$t('common.yes')"
              @click="handleCommentDelete"
            />
          </v-col>
        </v-row>
      </v-container>
    </base-inline-form>
  </div>
</template>

<script>
import { createFile } from '@/api/files'
import { mapGetters, mapActions } from 'vuex'
import BaseAvatar from '@/components/BaseAvatar'
import { convertDeltaToHtml } from '@/utils/quill'
import { createAttachment } from '@/api/attachments'
import uploadService from '@/services/uploadService'
import BaseDateTime from '@/components/BaseDateTime'
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseFilePreview from '@/components/BaseFilePreview'
import TaskDialogCommentsCommentActions from '@/components/task/TaskDialogCommentsCommentActions'
const attachment = {
  id: null,
  progress: 0,
  failed: false,
  cancelObj: {},
  uploading: false
}
export default {
  props: {
    comment: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      default: false
    },
    isLastItem: {
      type: Boolean,
      default: false
    },
    isFirstGroup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      attachment: {
        ...attachment
      },
      deleting: false,
      deleteConfiramtionSheet: false
    }
  },
  computed: {
    ...mapGetters(['task', 'project']),
    html() {
      const { comment } = this
      const { text, text_delta: delta } = comment
      if (delta) {
        return convertDeltaToHtml(delta)
      }
      return text || ''
    }
  },
  methods: {
    ...mapActions(['deleteTaskComment']),
    setUploadProgress(progress) {
      this.attachment.progress = progress
    },
    resetAttachment() {
      this.attachment = { ...attachment }
    },
    handleUploadCancel() {
      this.resetAttachment()
    },
    handleUploadDone() {
      this.addAttachement(true)
    },
    handleUploadError() {
      this.resetAttachment()
      this.attachment.failed = true
      this.attachment.uploading = false
    },
    async handleCommentDelete() {
      this.deleting = true
      try {
        await this.deleteTaskComment(this.comment.id)
      } catch (error) {}
      this.deleting = false
    },
    async addAttachement(isLocal = false) {
      const { attachment } = this.comment
      try {
        const { data } = await createAttachment(this.project.id, {
          source_type: attachment.source_type,
          file_attributes: isLocal
            ? { id: this.attachment.id }
            : attachment.file_attributes,
          parent_type: 'Task',
          parent_id: this.task.id
        })
        this.$emit('file-attached', {
          commentId: this.comment.id,
          attachmentId: data.data.id,
          delta: this.comment.text_delta
        })
      } catch (error) {}
    },
    async startFileUpload() {
      this.resetAttachment()
      this.attachment.uploading = true
      try {
        const { data } = await createFile()
        const { binary } = this.comment.attachment
        const { id } = data.data
        this.attachment.id = id
        this.attachment.progress = 0
        if (!binary) {
          return this.addAttachement()
        }
        uploadService.chunk(
          `/files/${id}`,
          binary,
          this.setUploadProgress,
          this.handleUploadError,
          this.handleUploadDone,
          this.attachment.cancelObj
        )
      } catch (error) {}
    },
    cancelFileUpload() {
      this.attachment.cancelObj.cancel()
      this.$emit('comment-canceled', this.comment.id)
    }
  },
  mounted() {
    if (this.comment.pending) {
      this.startFileUpload()
    }
  },
  components: {
    BaseAvatar,
    BaseDateTime,
    BaseInlineForm,
    BaseFilePreview,
    TaskDialogCommentsCommentActions
  }
}
</script>
<style scoped>
.comment-text-wrapper >>> p:last-child {
  margin-bottom: 0;
}
</style>
