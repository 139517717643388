import request from '@/api/axios'

export const createChecklist = (pid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}/checklists`,
    method: 'post',
    data
  })

export const editChecklist = (cid, data) =>
  request({
    url: `/orgs/#oid/checklists/${cid}`,
    method: 'put',
    data
  })

export const createChecklistItem = (pid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}/checklist_items`,
    method: 'post',
    data
  })

export const patchChecklistItem = (iid, data) =>
  request({
    url: `/orgs/#oid/checklist_items/${iid}`,
    method: 'patch',
    data
  })

export const deleteChecklist = cid =>
  request({
    url: `/orgs/#oid/checklists/${cid}`,
    method: 'delete'
  })

export const deleteChecklistItem = iid =>
  request({
    url: `/orgs/#oid/checklist_items/${iid}`,
    method: 'delete'
  })
