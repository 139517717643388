<template>
  <fragment>
    <base-dialog
      card-color="white"
      :show-save-btn="true"
      :dialog.sync="firstDialog"
      :disable-save-btn="!selected"
      :title="$t('common.copy_task')"
      :save-btn-text="$t('common.next')"
      @save-clicked="secondDialog = true"
      card-text-class="d-flex flex-column"
    >
      <task-dialog-actions-copy-task-dialog-chip :task="task" class="pa-4" />
      <base-expansion-list
        :selected.sync="selected"
        :items="boardsWithLists"
        :current-item-id="task.list.id"
        :empty-list-text="$t('search_and_filter.results.not_found.lists')"
      />
    </base-dialog>
    <base-dialog
      :loading="loading"
      card-color="white"
      @save-clicked="copy"
      :show-save-btn="true"
      :disable-save-btn="loading"
      :dialog.sync="secondDialog"
      :title="$t('common.copy_task')"
      :save-btn-text="$t('common.done')"
      card-text-class="d-flex flex-column"
    >
      <task-dialog-actions-copy-task-dialog-chip :task="task" class="pa-4" />
      <task-dialog-actions-copy-task-dialog-chip
        icon=""
        class="px-4"
        v-if="board && list"
      >
        {{
          $t('common.board_and_list', { board: board.title, list: list.title })
        }}
      </task-dialog-actions-copy-task-dialog-chip>
      <v-divider class="my-4" />
      <section class="flex-grow-1 info--text">
        <h2 class="body-1 mx-4">
          {{ $t('common.option', { count: 0 }) }}
        </h2>
        <v-list dense flat color="transparent">
          <v-list-item-group multiple v-model="parts" active-class="info--text">
            <v-list-item
              :ripple="false"
              :key="option.value"
              :value="option.value"
              v-for="option in options"
            >
              <template v-slot:default="{ active }">
                <v-list-item-icon class="me-4">
                  <base-icon
                    :name="option.icon"
                    className="d-flex align-center"
                    :color="active ? 'primary' : 'secondary.base'"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :class="{ 'primary--text': active }"
                    v-text="$t(`common.${option.text}`, { count: 0 })"
                  ></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon v-if="active">
                  <base-icon name="check" color="primary" />
                </v-list-item-icon>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </section>
    </base-dialog>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseDialog from '@/components/BaseDialog'
import BaseExpansionList from '@/components/BaseExpansionList'
import TaskDialogActionsCopyTaskDialogChip from '@/components/task/TaskDialogActionsCopyTaskDialogChip'

const options = [
  {
    text: 'assignee',
    value: 'assignees',
    icon: 'users-alt'
  },
  {
    text: 'checklist',
    value: 'checklists',
    icon: 'list-ul'
  },
  {
    text: 'attachment',
    value: 'attachments',
    icon: 'paperclip'
  },
  {
    text: 'dependency',
    value: 'dependencies',
    icon: 'repeat'
  },
  {
    text: 'tag',
    value: 'tags',
    icon: 'tag'
  }
]
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['task', 'projectLists', 'projectBoards', 'boardsWithLists']),
    list() {
      const { projectLists } = this
      if (!projectLists) {
        return null
      }
      return this.selected ? this.projectLists[this.selected] : null
    },
    board() {
      return this.list && this.projectBoards
        ? this.projectBoards[this.list.board.id]
        : null
    }
  },
  data() {
    return {
      selected: null,
      firstDialog: false,
      secondDialog: false,
      options: [...options],
      parts: options.map((option) => option.value)
    }
  },
  methods: {
    open() {
      this.firstDialog = true
    },
    close() {
      this.firstDialog = false
      this.secondDialog = false
    },
    copy() {
      this.$emit('task-copy-clicked', {
        list: this.list.id,
        parts: this.parts
      })
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseExpansionList,
    TaskDialogActionsCopyTaskDialogChip
  }
}
</script>
