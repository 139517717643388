<template>
  <v-list-item @click="$emit('item-clicked', team)" :ripple="false">
    <slot name="listitemstart" v-bind:team="team" />
    <v-list-item-content>
      <v-list-item-title
        v-text="team.title"
        class="body-2 info--text"
      />
    </v-list-item-content>
    <v-list-item-action-text>
      <base-avatar-group
        :limit="5"
        :avatar-size="24"
        :plain-more="true"
        text-size="caption"
        :overlapping="true"
        :list="team.memberships.map(member => member.user)"
      />
    </v-list-item-action-text>
    <v-list-item-action style="min-width:auto" class="ms-1" v-if="showActions">
      <base-angle-icon
        width="20"
        height="20"
        fill="secondary.darken2"
        class="flip-horizontal d-flex align-center"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import BaseAngleIcon from '@/components/BaseAngleIcon'
import BaseAvatarGroup from '@/components/BaseAvatarGroup'
export default {
  props: {
    team: {
      type: Object,
      required: true
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaseAngleIcon,
    BaseAvatarGroup
  }
}
</script>
