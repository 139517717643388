var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5"},[_c('base-inline-input',{attrs:{"loading":_vm.loading,"initial-value":_vm.item.value,"label":_vm.$t(("form.labels." + _vm.name + "_title"))},on:{"save-clicked":function($event){return _vm.$emit('save-clicked', {
      item: _vm.item,
      title: $event
    })},"edit-clicked":function($event){return _vm.$emit('edit-clicked', {
      item: _vm.item
    })}},scopedSlots:_vm._u([{key:"prependExtra",fn:function(){return [_c('v-btn',{staticClass:"handle me-2",staticStyle:{"margin-top":"2px"},attrs:{"icon":"","x-small":"","disabled":_vm.moveDisabled}},[_c('base-icon',{attrs:{"name":"draggabledots","color":_vm.moveDisabled ? 'secondary.lighten1' : 'secondary.darken2'}})],1)]},proxy:true},(_vm.isLast)?{key:"appendExtra",fn:function(){return [_c('v-menu',{staticClass:"t-box-shadow",attrs:{"top":"","offset-y":"","nudge-top":8,"max-width":300,"right":_vm.$vuetify.rtl,"left":!_vm.$vuetify.rtl,"transition":"slide-x-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-top":"-1px"},attrs:{"icon":"","small":"","dark":""}},'v-btn',attrs,false),on),[_c('base-icon',_vm._g(_vm._b({attrs:{"name":"info-circle","color":"primary"}},'base-icon',attrs,false),on))],1)]}}],null,false,2294400180),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_vm._t("lastItemContent")],2)]},proxy:true}:null],null,true)},[(!_vm.deleteDisabled)?_c('v-btn',{attrs:{"small":"","text":"","depressed":"","color":"error"},domProps:{"textContent":_vm._s(_vm.$t('common.delete'))},on:{"click":function($event){return _vm.$emit('delete-clicked', _vm.item)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }