<template>
  <v-container fluid>
    <task-dialog-details-informations-row
      :text="`${progress}%`"
      :editable="editable"
      icon="t-circular-progress"
      @row-clicked="sheet = true"
      :label="$t('common.progress')"
      :add-button-text="$t('common.add_progress')"
    />
    <base-inline-form
      :value.sync="sheet"
      card-text-class-name="px-4"
      :title="$t('common.progress')"
    >
      <v-slider
        class="mt-6"
        thumb-label="always"
        v-model="taskProgress"
        track-color="secondary lighten-2"
        :color="$vuetify.theme.themes.light.primary"
      />
      <base-inline-form-actions
        @ok-clicked="save"
        :loading="loading"
        @cancel-clicked="cancel"
        :ok-text="$t('common.save')"
      />
    </base-inline-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseInlineFormActions from '@/components/BaseInlineFormActions'
import TaskDialogDetailsInformationsRow from '@/components/task/TaskDialogDetailsInformationsRow'

export default {
  props: {
    progress: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(['task']),
    tid() {
      return this.task.id
    }
  },
  data() {
    return {
      sheet: false,
      loading: false,
      taskProgress: this.progress
    }
  },
  methods: {
    ...mapActions(['updateProjectTask']),
    async save() {
      const progress = this.taskProgress
      if (progress === this.progress) {
        this.sheet = false
        return true
      }
      try {
        this.loading = true
        await this.updateProjectTask({
          tid: this.tid,
          data: {
            progress
          },
          keys: ['progress'],
          updateListItem: true
        })
        this.sheet = false
      } catch (error) {}
      this.loading = false
    },
    cancel() {
      this.taskProgress = this.progress
      this.sheet = !this.sheet
    }
  },
  watch: {
    progress(newValue) {
      this.taskProgress = newValue
    }
  },
  components: {
    BaseInlineForm,
    BaseInlineFormActions,
    TaskDialogDetailsInformationsRow
  }
}
</script>
