<template>
  <v-card height="100%" class="d-flex flex-column" color="secondary lighten-5">
    <v-card-text
      class="
        py-0
        d-flex
        overflow-hidden
        comments-wrapper
        flex-column flex-grow-1
      "
      :class="{ 'editor-active': editorActive }"
    >
      <vuescroll
        ref="comments"
        :ops="scrollConfig"
        class="d-flex justify-center"
        :class="{ 'vertical-center': loading || groupItems.length === 0 }"
      >
        <div class="comments-list" @click="editorActive = false">
          <div class="intersect" v-intersect="onIntersect" />
          <base-loading-more v-if="loading" />
          <template v-if="fetched">
            <template v-if="groupItems.length">
              <section :key="group" v-for="(group, groupIndex) in groupItems">
                <div class="date my-3 d-flex justify-center fill-width">
                  <v-chip
                    small
                    color="secondary base"
                    v-html="getDateGroupTitle(group)"
                  />
                </div>
                <v-slide-y-reverse-transition group dense hide-on-leave>
                  <task-dialog-comments-comment
                    :key="comment.id"
                    :comment="comment"
                    v-for="(comment, index) in groups[group]"
                    :editing="editingCommentId === comment.id"
                    :is-last-item="
                      index === groups[group].length - 1 &&
                      pendingComments.length === 0
                    "
                    :is-first-group="
                      groupIndex === groupItems.length - 1 &&
                      pendingComments.length === 0
                    "
                    @edit-comment-clicked="setEditorContent"
                  />
                </v-slide-y-reverse-transition>
              </section>
              <section>
                <task-dialog-comments-comment
                  :key="comment.id"
                  :comment="comment"
                  :is-last-item="true"
                  :is-first-group="true"
                  v-for="comment in pendingComments"
                  @comment-canceled="deletePendingComment"
                  @file-attached="addCommentWithAttachement"
                />
              </section>
            </template>
            <comments-empty-state v-else />
          </template>
          <base-loading v-else />
        </div>
      </vuescroll>
    </v-card-text>
    <task-dialog-comments-footer
      ref="footer"
      :tasks="tasksLinkable"
      :loading="sending"
      :is-mobile="isMobile"
      :content="editorContent"
      :users="projectApprovedUsers"
      :task-items-path="taskItemsPath"
      :editor-active.sync="editorActive"
      :class="{ 'is-desktop': !isMobile }"
      :editing-comment-id="editingCommentId"
      @send-clicked="handleClickOnSendComment"
      @comment-edit-canceled="handleEditCancel"
    />
  </v-card>
</template>

<script>
import vuescroll from 'vuescroll'
import moment from 'jalali-moment'
import sortBy from 'lodash.sortby'
import { v4 as uuidv4 } from 'uuid'
import groupBy from 'lodash.groupby'
import { mapActions, mapGetters } from 'vuex'
import { scrollConfig } from '@/constants/scroll'
import BaseLoading from '@/components/BaseLoading'
import BaseLoadingMore from '@/components/BaseLoadingMore'
import CommentsEmptyState from '@/components/emptyStates/CommentsEmptyState'
import TaskDialogCommentsFooter from '@/components/task/TaskDialogCommentsFooter2'
import TaskDialogCommentsComment from '@/components/task/TaskDialogCommentsComment'
export default {
  computed: {
    ...mapGetters([
      'user',
      'task',
      'tasks',
      'project',
      'isMobile',
      'projectLists',
      'projectBoards',
      'commentRecords',
      'commentsOffsetToken',
      'projectApprovedUsers'
    ]),
    tasksLinkable() {
      return this.tasks.map((task) => {
        return {
          ...task,
          link: `/projects/${this.project.short_id}/${
            this.$route.params.view || 'kanban'
          }/tasks/${task.number}`
        }
      })
    },
    requestParams() {
      return {
        pid: this.project.id,
        params: {
          limit: 15,
          expand: ['creator'],
          task_id: this.task.id,
          offset_token: this.commentsOffsetToken
        }
      }
    },
    groups() {
      const sorted = sortBy(this.commentRecords, (item) => item.created_at)
      return groupBy(sorted, (item) => {
        const createdAt = new Date(item.created_at * 1000)
        return this.$i18n.t('date.formats.iso.medium', {
          date: createdAt
        })
      })
    },
    groupItems() {
      return Object.keys(this.groups)
    },
    taskItemsPath() {
      return this.tasks.reduce((result, task) => {
        const list = this.projectLists[task.list.id]
        result[task.id] = this.$i18n.t('common.task_board_and_list', {
          list: list.title,
          board: this.projectBoards[list.board.id].title
        })
        return result
      }, {})
    }
  },
  data() {
    return {
      scrollConfig: {
        ...scrollConfig,
        scrollPanel: {
          scrollingX: false,
          scrollingY: true
        }
      },
      fetched: false,
      loading: false,
      sending: false,
      editorContent: '',
      editorActive: false,
      pendingComments: [],
      isIntersecting: false,
      editingCommentId: null
    }
  },
  methods: {
    ...mapActions([
      'addComment',
      'getComments',
      'addComments',
      'updateComment',
      'resetComments'
    ]),
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting
    },
    scrollToBottom() {
      setTimeout(() => {
        this.$refs.comments.scrollTo(
          {
            y: '100%'
          },
          200
        )
      }, 200)
    },
    setEditorContent(comment) {
      this.editorContent = comment.text_delta
      this.editingCommentId = comment.id
      this.editorActive = true
    },
    getDateGroupTitle(group) {
      const diff = Math.abs(moment().diff(moment(new Date(group)), 'hours'))
      if (diff <= 24) {
        return this.$i18n.t('common.today')
      }
      return group
    },
    async getData() {
      try {
        await this.getComments(this.requestParams)
        this.fetched = true
        this.$nextTick(this.scrollToBottom)
      } catch (error) {}
    },
    async addData() {
      this.loading = true
      try {
        await this.addComments(this.requestParams)
      } catch (error) {}
      this.loading = false
    },
    addPendingComment(file, delta) {
      this.pendingComments = [
        ...this.pendingComments,
        {
          id: uuidv4(),
          pending: true,
          text_delta: delta,
          creator: this.user,
          attachment: {
            ...file,
            id: uuidv4(),
            creator: this.user
          }
        }
      ]
      this.$refs.footer.resetEditor()
      this.scrollToBottom()
    },
    async editComment(delta) {
      this.sending = true
      try {
        await this.updateComment({
          cid: this.editingCommentId,
          data: {
            text_delta: delta,
            expand: ['creator']
          }
        })
        this.editingCommentId = null
        this.$refs.footer.resetEditor()
      } catch (error) {}
      this.sending = false
    },
    async sendComment(delta, attachmentId = null) {
      this.sending = true
      try {
        await this.addComment({
          pid: this.project.id,
          data: {
            text_delta: delta,
            expand: ['creator'],
            task_id: this.task.id,
            attachment_id: attachmentId
          }
        })
        this.$refs.footer.resetEditor()
        this.scrollToBottom()
      } catch (error) {}
      this.sending = false
    },
    deletePendingComment(commentId) {
      const index = this.pendingComments.findIndex(
        (comment) => comment.id === commentId
      )
      this.pendingComments.splice(index, 1)
    },
    async addCommentWithAttachement({ delta, commentId, attachmentId }) {
      try {
        await this.sendComment(delta, attachmentId)
      } catch (error) {}
      this.deletePendingComment(commentId)
    },
    handleClickOnSendComment({ file }) {
      if (this.sending) {
        return
      }
      const delta = JSON.stringify(this.$refs.footer.getEditorContent())
      if (file) {
        return this.addPendingComment(file, delta)
      }
      if (this.editingCommentId) {
        return this.editComment(delta)
      }
      this.sendComment(delta)
    },
    handleEditCancel() {
      this.editingCommentId = null
      this.editorContent = ''
    },
    setLinkListener(func) {
      window.addEventListener('click', func)
      const removeListener = () => window.removeEventListener('click', func)
      return removeListener
    }
  },
  mounted() {
    this.resetComments()
    this.getData()
  },
  watch: {
    isIntersecting(newValue) {
      if (
        !newValue ||
        this.loading ||
        !this.fetched ||
        !this.commentsOffsetToken
      ) {
        return
      }
      this.addData()
    }
  },
  components: {
    vuescroll,
    BaseLoading,
    BaseLoadingMore,
    CommentsEmptyState,
    TaskDialogCommentsFooter,
    TaskDialogCommentsComment
  }
}
</script>

<style lang="scss">
.comments-wrapper {
  .date {
    top: 8px;
    z-index: 3;
    position: sticky;
  }
  .__panel {
    width: 100%;
  }
  .__view {
    display: flex;
    align-items: flex-end;
    .comments-list {
      width: 100%;
    }
  }
  .vertical-center {
    .__view {
      align-items: center;
    }
  }
}
.comments-actions {
  left: 0;
  bottom: 0;
  z-index: 3;
  width: 100%;
  position: fixed;
  transition: height 0.15s;
  &.is-desktop {
    position: absolute;
  }
  .expand-toggle {
    position: absolute;
    z-index: 5;
    right: 8px;
    top: 4px;
  }
  .quill-editor {
    width: 100%;
  }
  &.expanded {
    align-items: flex-end;
    .editor-container {
      flex-flow: row wrap;
      flex-direction: column;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      right: 0;
      padding: 0 8px 8px 8px;
      bottom: 0;
      .ql-container {
        overflow: hidden;
        .ql-editor {
          max-height: none;
        }
      }
    }
  }
}
</style>
