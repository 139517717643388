export const COLOR_LABEL_MAP = [
  '#4285F4',
  '#7B1FA2',
  '#F44336',
  '#FBBC04',
  '#34A853',
  '#EC407A'
]

export const THEME_PALETTE = {
  sky: '#2196F3',
  aqua: '#60FFFB',
  duskySky: '#191970',
  darkSand: '#757575',
  secondary: '#9E9E9E',
  ketchup: '#EF5350',
  blood: '#F44336',
  sun: '#FBBC04',
  marine: '#4285F4',
  reptile: '#34A853',
  desire: '#EC407A',
  amour: '#D32F2F',
  nasu: '#7B1FA2',
  darkSapphire: '#303F9F',
  clearChill: '#0288D1',
  greenSea: '#00796B',
  pistachio: '#689F38',
  tangerine: '#F57C00',
  sunflower: '#FBC02D',
  chocolate: '#5D4037'
}

export const SECONDARY_PALETTE = [
  THEME_PALETTE.amour,
  THEME_PALETTE.nasu,
  THEME_PALETTE.darkSapphire,
  THEME_PALETTE.clearChill,
  THEME_PALETTE.greenSea,
  THEME_PALETTE.pistachio,
  THEME_PALETTE.tangerine,
  THEME_PALETTE.sunflower,
  THEME_PALETTE.chocolate
]
