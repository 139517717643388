<template>
  <div class="color-label" :style="labelStyle" />
</template>

<script>
export default {
  props: {
    label: {
      required: true
    }
  },
  computed: {
    labelStyle() {
      const { label } = this
      if (!label || !label.id) {
        return ''
      }
      const color = `#${label.color_code}`
      const direction = this.$vuetify.rtl ? 'right' : 'left'
      return `background:${color};${direction}:0;`
    }
  }
}
</script>
