<template>
  <v-text-field
    dense
    required
    outlined
    autofocus
    min="0"
    type="number"
    color="secondary"
    :value="value"
    :suffix="suffix"
    :label="$t(`form.labels.${title}`)"
    @change="save"
  />
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
  props: {
    title: {
      type: String,
      default: 'from'
    },
    value: {
      required: true
    },
    suffix: {
      required: true
    }
  },
  data() {
    return {
      input: null
    }
  },
  methods: {
    save(val) {
      this.input = val
      this.$emit('update:value', this.input)
    }
  },
  directives: { mask }
}
</script>
