import { SET_INVITATION, SET_INVITATION_TOKEN } from '../mutation-types'
import { fetchInvitation } from '@/api/invitations'
const state = {
  invitation: false,
  invitationToken: null
}

const getters = {
  invitation: state => state.invitation,
  invitationToken: state => state.invitationToken
}

const mutations = {
  [SET_INVITATION](state, invitation) {
    state.invitation = invitation
  },
  [SET_INVITATION_TOKEN](state, invitationToken) {
    state.invitationToken = invitationToken
  }
}

const actions = {
  async getInvitation({ commit }, token) {
    const { data } = await fetchInvitation(token, false)
    commit(SET_INVITATION, data.data)
  },
  setInvitationToken({ commit }, invitationToken) {
    commit(SET_INVITATION_TOKEN, invitationToken)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
