<template>
  <centered-layout container-class="d-flex fill-height">
    <template v-slot:appbar>
      <v-toolbar-title class="pa-0 flex-grow-1 d-flex justify-center">
        <base-logo />
      </v-toolbar-title>
    </template>
    <div
      style="margin-top: -50px"
      class="pa-4 d-flex flex-grow-1 flex-column align-center justify-center"
    >
      <div class="d-flex flex-column align-center">
        <v-avatar color="error lighten-5" size="64">
          <base-icon
            width="24"
            height="24"
            name="times"
            color="error"
            className="d-flex align-center"
          />
        </v-avatar>
        <h1 v-text="$t('page.error.subtitle')" class="text-h1 mt-4 text-center" />
        <p
          v-text="$t('page.error.content')"
          class="body-1 secondary--text text--darken-2 text-center mt-4"
        />
      </div>
      <div class="flex-grow-0 mt-2 fill-width">
        <v-btn
          block
          depressed
          color="primary"
          :href="homeUrl"
          v-text="$t('common.back_to_home')"
        />
      </div>
    </div>
  </centered-layout>
</template>

<script>
import { getDomain } from '@/utils/auth'
import BaseIcon from '@/components/BaseIcon'
import BaseLogo from '@/components/BaseLogo'
import CenteredLayout from '@/layouts/CenteredLayout'
export default {
  components: {
    BaseLogo,
    BaseIcon,
    CenteredLayout
  },
  computed: {
    homeUrl() {
      const domain = getDomain()
      const { port, protocol } = window.location
      return `${protocol}//${domain}${port.length > 0 ? `:${port}` : ''}`
    }
  }
}
</script>
