<template>
  <fragment>
    <v-chip
      x-small
      outlined
      class="caption"
      style="height: 18px"
      :color="dueDateColor"
      v-if="dueDateColor.length"
    >
      <base-date-time :date="deadline" format="medium" />
    </v-chip>
    <base-date-time class="caption" v-else :date="deadline" format="medium" />
  </fragment>
</template>

<script>
import { getDiffInDays } from '@/utils/date'
import BaseDateTime from '@/components/BaseDateTime'
export default {
  props: {
    deadline: {
      require: true
    },
    jFormat: {
      requied: true
    },
    gFormat: {
      requied: true
    }
  },
  computed: {
    dueDateColor() {
      const diff = getDiffInDays(this.deadline * 1000)
      if (diff === 0) {
        return 'orange'
      }
      if (diff < 0) {
        return 'error base'
      }
      return ''
    }
  },
  components: {
    BaseDateTime
  }
}
</script>
