<template>
  <fragment>
    <base-loading v-if="!records" />
    <template v-else>
      <v-data-table
        dense
        :items="records"
        calculate-widths
        :headers="headers"
        :loading="loading"
        v-if="records.length"
        class="my-tasks-table"
        :options.sync="options"
        mobile-breakpoint="100"
        :disable-pagination="true"
        :hide-default-footer="true"
        @click:row="$emit('task-clicked', $event)"
      >
        <template v-slot:[`item.title`]="{ item }">
          <div
            v-text="item.title"
            class="text-truncate"
            style="display: block; max-width: 300px"
          />
        </template>
        <template v-slot:[`item.project`]="{ item }">
          <div
            class="text-truncate"
            v-text="item.project.title"
            style="display: block; max-width: 183px"
          />
        </template>
        <template v-slot:[`item.starting_at`]="{ item }">
          <base-date-time
            format="short"
            v-if="item.starting_at"
            :date="item.starting_at"
          />
          <span
            v-else
            class="body-2 secondary--text"
            v-text="$t('common.add_starting_at')"
          />
        </template>
        <template v-slot:[`item.due_at`]="{ item }">
          <base-date-time
            format="short"
            v-if="item.due_at"
            :date="item.due_at"
          />
          <span
            v-else
            class="body-2 secondary--text"
            v-text="$t('common.add_due_at')"
          />
        </template>
        <template v-slot:[`item.assignees`]="{ item }">
          <base-avatar-group
            :limit="5"
            :avatar-size="28"
            :overlapping="true"
            :circular-more="true"
            :list="item.assignees"
            v-if="item.assignees.length"
          />
          <div v-else class="d-flex align-center body-2 secondary--text">
            <v-btn
              fab
              x-small
              outlined
              disabled
              width="26"
              height="26"
              color="secondary"
            >
              <span class="subtitle-1" v-text="'+'" />
            </v-btn>
            <span class="ms-1">{{ $t('common.add_assignee') }}</span>
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div
            class="text-truncate"
            v-text="item.status.title"
            style="display: block; max-width: 100px"
          />
        </template>
        <template v-slot:[`item.label`]="{ item }">
          <v-chip
            label
            v-if="item.label"
            text-color="white"
            style="height: 100%"
            v-text="item.label.title"
            :color="`#${item.label.color_code}`"
            class="fill-width justify-center color-label"
          />
          <v-chip
            label
            v-else
            color="white"
            text-color="secondary"
            v-text="`+ ${$t('common.add_label')}`"
            class="fill-width justify-center color-label placeholder"
          />
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          <task-list-section-task-tags
            :tags="item.tags"
            v-if="item.tags.length"
          />
          <v-chip
            x-small
            v-else
            color="white"
            class="tag placeholder px-2"
            text-color="secondary darken-2"
            v-text="`+ ${$t('common.add_tag')}`"
          />
        </template>
        <template v-slot:[`item.progress`]="{ item }">
          <v-progress-linear
            height="100%"
            class="progress"
            :value="item.progress"
            background-color="transparent"
          >
            <template v-slot="{ value }">
              <div class="d-flex justify-start fill-width">
                <span class="ms-3" v-text="`${Math.ceil(value)}%`" />
              </div>
            </template>
          </v-progress-linear>
        </template>
      </v-data-table>
      <my-tasks-table-empty v-else />
    </template>
  </fragment>
</template>

<script>
import BaseLoading from '@/components/BaseLoading'
import BaseDateTime from '@/components/BaseDateTime'
import BaseAvatarGroup from '@/components/BaseAvatarGroup'
import MyTasksTableEmpty from '@/components/myTasks/MyTasksTableEmpty'
import TaskListSectionTaskTags from '@/components/project/TaskListSectionTaskTags'

export default {
  components: {
    BaseLoading,
    BaseDateTime,
    BaseAvatarGroup,
    MyTasksTableEmpty,
    TaskListSectionTaskTags
  },
  props: {
    records: {
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: {},
      headers: [
        {
          text: '#',
          value: 'number',
          align: 'start',
          sortable: true,
          width: 70
        },
        {
          text: this.$i18n.t('common.title'),
          align: 'start',
          sortable: true,
          value: 'title',
          width: 100
        },
        {
          text: this.$i18n.t('common.project'),
          align: 'start',
          sortable: true,
          value: 'project',
          width: 125
        },
        {
          text: this.$i18n.t('common.status'),
          value: 'status',
          align: 'start',
          sortable: true,
          width: 100
        },
        {
          text: this.$i18n.t('common.start_date'),
          value: 'starting_at',
          align: 'start',
          sortable: true,
          width: 140
        },
        {
          text: this.$i18n.t('common.due_date'),
          value: 'due_at',
          align: 'start',
          sortable: true,
          width: 140
        },
        {
          text: this.$i18n.t('common.assignee_plural'),
          value: 'assignees',
          align: 'start',
          width: 170,
          sortable: false
        },
        {
          text: this.$i18n.t('common.color_label'),
          value: 'label',
          align: 'start',
          width: 135,
          cellClass: 'px-0',
          sortable: true
        },
        {
          text: this.$i18n.t('common.tag_plural'),
          value: 'tags',
          align: 'start',
          sortable: false
        },
        {
          text: this.$i18n.t('common.progress'),
          value: 'progress',
          cellClass: 'px-0',
          sortable: true,
          align: 'start',
          width: 130
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc } = this.options
        if (!sortBy.length) {
          return
        }
        const sortSign = sortDesc[0] ? '-' : ''
        this.$emit('sort-changed', {
          sort_by: `${sortSign}${sortBy[0]}`
        })
      },
      deep: true
    }
  }
}
</script>
