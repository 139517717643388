<template>
  <fragment>
    <v-card-text class="flex-grow-1 py-2 px-0 overflow-auto">
      <div ref="quill" />
    </v-card-text>
    <v-card-actions>
      <div class="ql-toolbar-wrpper">
        <div id="toolbar" class="ql-toolbar" :class="{ 'is-mobile': isMobile }">
          <v-btn icon small class="ql-member" value="@">
            <base-icon name="at" width="20" height="20" color="info" />
          </v-btn>
          <v-btn icon small class="ql-task" value="#">
            <base-icon width="20" height="20" color="info" name="t-hash-tag" />
          </v-btn>
          <base-quill-basic-toolbar />
        </div>
      </div>
    </v-card-actions>
    <base-inline-form
      :value.sync="linkBottomSheet"
      :title="$t('common.add_link')"
    >
      <v-form class="pt-10" ref="linkForm" @submit.prevent="addLink">
        <v-text-field
          dense
          outlined
          autofocus
          type="text"
          v-model="link.url"
          validate-on-blur
          :rules="getLinkUrlRules()"
          :label="$t('form.labels.link_url')"
        />
      </v-form>
      <v-btn
        block
        depressed
        color="primary"
        @click="addLink"
        v-text="$t('common.add')"
      />
    </base-inline-form>
  </fragment>
</template>

<script>
import 'quill-mention'
import quill from 'quill'
import BaseIcon from '@/components/BaseIcon'
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseQuillBasicToolbar from '@/components/BaseQuillBasicToolbar'
import {
  mentionsTaskOrMember,
  getEditorMentionsConfig,
  createContentString
} from '@/utils/quill'
import LinkTypes from '@/plugins/quill/linkTypes'
const Quill = window.Quill || quill

export default {
  props: {
    tasks: {
      required: true
    },
    users: {
      required: true
    },
    content: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      link: {
        index: null,
        textLength: null,
        text: '',
        url: null
      },
      instance: null,
      linkBottomSheet: false,
      options: {
        theme: null,
        modules: {
          mention: getEditorMentionsConfig(this.tasks, this.users),
          toolbar: {
            container: '#toolbar',
            handlers: {
              member: (value) => {
                mentionsTaskOrMember(this.instance, value)
              },
              task: (value) => {
                mentionsTaskOrMember(this.instance, value)
              },
              link: (value) => {
                if (value) {
                  const content = createContentString(
                    this.instance.getContents()
                  )
                  const selection = this.instance.getSelection()
                  this.link.index =
                    selection.index === 0
                      ? 0
                      : (selection || {}).index || this.instance.getLength() - 1
                  this.link.textLength = selection.length
                  this.link.text = content.slice(
                    selection.index,
                    selection.index + selection.length
                  )
                  this.linkBottomSheet = true
                } else {
                  this.instance.format('link', false)
                }
              }
            }
          },
          keyboard: {
            bindings: {
              tab: {
                key: 9,
                handler: function() {
                  return false
                }
              }
            }
          }
        },
        placeholder: this.$i18n.t('common.description')
      }
    }
  },
  methods: {
    addLink() {
      if (this.$refs.linkForm.validate()) {
        this.instance.deleteText(this.link.index, this.link.textLength)
        this.instance.insertText(
          this.link.index || 0,
          `${this.link.text}`,
          'link',
          this.link.url
        )
        setTimeout(() => {
          this.instance.setSelection(
            (this.link.index || 0) + this.link.textLength,
            0
          )
          this.link = {
            index: null,
            textLength: null,
            text: '',
            url: null
          }
        })
        this.linkBottomSheet = false
        this.$refs.linkForm.resetValidation()
      }
    },
    initialize() {
      Quill.register(LinkTypes)
      this.instance = new Quill(this.$refs.quill, this.options)
      this.setEditorContent()
    },
    setEditorFocus() {
      setTimeout(() => {
        this.instance.focus()
      }, 100)
    },
    getEditorContent() {
      return this.instance.getContents()
    },
    setEditorContent() {
      const { instance } = this
      const delta = instance.clipboard.convert(this.content)
      instance.setContents(delta)
      instance.setSelection(instance.getLength(), 0)
      this.setEditorFocus()
    },
    resetContent() {}
  },
  beforeDestroy() {
    this.instance = null
  },
  mounted() {
    this.initialize()
  },
  components: {
    BaseIcon,
    BaseInlineForm,
    BaseQuillBasicToolbar
  }
}
</script>
