<template>
  <fragment>
    <div>
      <div class="d-flex justify-space-between">
        <span
          v-text="$t('common.title')"
          class="body-2 secondary--text text--darken-2"
        />
        <v-slide-x-transition hide-on-leave>
          <v-btn
            text
            small
            color="primary"
            @click="activate"
            v-text="$t('common.edit')"
            v-show="!editing && editable"
          />
        </v-slide-x-transition>
        <v-slide-x-transition hide-on-leave>
          <div v-show="editing && editable">
            <v-slide-x-transition>
              <v-btn
                text
                small
                @click="save"
                color="primary"
                :loading="loading"
                v-show="!buttonHidden"
                :disabled="buttonDisabled"
                v-text="$t('common.save')"
              />
            </v-slide-x-transition>
            <v-btn
              text
              small
              :disabled="loading"
              @click="inactivate"
              color="secondary darken-2"
              v-text="$t('common.cancel')"
            />
          </div>
        </v-slide-x-transition>
      </div>
      <v-textarea
        solo
        flat
        dense
        rows="1"
        auto-grow
        hide-details
        v-model="text"
        ref="inlineTextArea"
        @keydown="handleKeydown"
        :readonly="!editable || !editing"
        class="body-2 inline-input flex-grow-1"
      />
    </div>
  </fragment>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(['task']),
    tid() {
      return this.task.id
    },
    buttonHidden() {
      return this.text === this.title
    },
    buttonDisabled() {
      return this.text.length === 0
    }
  },
  data() {
    return {
      loading: false,
      editing: false,
      text: this.title
    }
  },
  methods: {
    ...mapActions(['updateProjectTask']),
    async save() {
      if (this.buttonDisabled) {
        return
      }
      try {
        this.loading = true
        await this.updateProjectTask({
          tid: this.tid,
          data: {
            title: this.text
          },
          keys: ['title'],
          updateListItem: true
        })
        this.editing = false
      } catch (error) {}
      this.loading = false
    },
    handleKeydown(event) {
      if (event.keyCode === 13) {
        event.preventDefault()
        this.save()
      }
    },
    setFocus() {
      this.$refs.inlineTextArea.$el.querySelectorAll('textarea')[0].focus()
    },
    setBlur() {
      this.$refs.inlineTextArea.$el.querySelectorAll('textarea')[0].blur()
    },
    activate() {
      this.editing = true
      this.setFocus()
    },
    inactivate() {
      this.editing = false
      this.text = this.title
      this.setBlur()
    }
  },
  watch: {
    title(newValue) {
      this.text = newValue
    }
  }
}
</script>
