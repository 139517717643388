var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(!_vm.records)?_c('base-loading'):[(_vm.records.length)?_c('v-data-table',{staticClass:"my-tasks-table",attrs:{"dense":"","items":_vm.records,"calculate-widths":"","headers":_vm.headers,"loading":_vm.loading,"options":_vm.options,"mobile-breakpoint":"100","disable-pagination":true,"hide-default-footer":true},on:{"update:options":function($event){_vm.options=$event},"click:row":function($event){return _vm.$emit('task-clicked', $event)}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"display":"block","max-width":"300px"},domProps:{"textContent":_vm._s(item.title)}})]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"display":"block","max-width":"183px"},domProps:{"textContent":_vm._s(item.project.title)}})]}},{key:"item.starting_at",fn:function(ref){
var item = ref.item;
return [(item.starting_at)?_c('base-date-time',{attrs:{"format":"short","date":item.starting_at}}):_c('span',{staticClass:"body-2 secondary--text",domProps:{"textContent":_vm._s(_vm.$t('common.add_starting_at'))}})]}},{key:"item.due_at",fn:function(ref){
var item = ref.item;
return [(item.due_at)?_c('base-date-time',{attrs:{"format":"short","date":item.due_at}}):_c('span',{staticClass:"body-2 secondary--text",domProps:{"textContent":_vm._s(_vm.$t('common.add_due_at'))}})]}},{key:"item.assignees",fn:function(ref){
var item = ref.item;
return [(item.assignees.length)?_c('base-avatar-group',{attrs:{"limit":5,"avatar-size":28,"overlapping":true,"circular-more":true,"list":item.assignees}}):_c('div',{staticClass:"d-flex align-center body-2 secondary--text"},[_c('v-btn',{attrs:{"fab":"","x-small":"","outlined":"","disabled":"","width":"26","height":"26","color":"secondary"}},[_c('span',{staticClass:"subtitle-1",domProps:{"textContent":_vm._s('+')}})]),_c('span',{staticClass:"ms-1"},[_vm._v(_vm._s(_vm.$t('common.add_assignee')))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"display":"block","max-width":"100px"},domProps:{"textContent":_vm._s(item.status.title)}})]}},{key:"item.label",fn:function(ref){
var item = ref.item;
return [(item.label)?_c('v-chip',{staticClass:"fill-width justify-center color-label",staticStyle:{"height":"100%"},attrs:{"label":"","text-color":"white","color":("#" + (item.label.color_code))},domProps:{"textContent":_vm._s(item.label.title)}}):_c('v-chip',{staticClass:"fill-width justify-center color-label placeholder",attrs:{"label":"","color":"white","text-color":"secondary"},domProps:{"textContent":_vm._s(("+ " + (_vm.$t('common.add_label'))))}})]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [(item.tags.length)?_c('task-list-section-task-tags',{attrs:{"tags":item.tags}}):_c('v-chip',{staticClass:"tag placeholder px-2",attrs:{"x-small":"","color":"white","text-color":"secondary darken-2"},domProps:{"textContent":_vm._s(("+ " + (_vm.$t('common.add_tag'))))}})]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{staticClass:"progress",attrs:{"height":"100%","value":item.progress,"background-color":"transparent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex justify-start fill-width"},[_c('span',{staticClass:"ms-3",domProps:{"textContent":_vm._s(((Math.ceil(value)) + "%"))}})])]}}],null,true)})]}}],null,true)}):_c('my-tasks-table-empty')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }