<template>
  <base-dialog
    :dialog="dialog"
    :loading="loading"
    card-color="white"
    :show-save-btn="false"
    content-class="large-level-three"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="
      $t('page.profile_setting.security.authentication.recovery_codes.title')
    "
  >
    <section class="px-4">
      <h2
        class="body-1 mt-4 info--text text-darken-4"
        v-text="
          $t(
            'page.profile_setting.security.authentication.recovery_codes.subtitle'
          )
        "
      />
      <p
        class="body-1 mt-4 secondary--text text--darken-2"
        v-text="
          $t(
            'page.profile_setting.security.authentication.recovery_codes.description'
          )
        "
      />
    </section>
    <v-row no-gutters class="mt-6" v-if="twoFactorAuthBackupCodes">
      <v-col
        cols="6"
        :key="item.code"
        v-for="item in twoFactorAuthBackupCodes.codes"
        class="text-center text-h5 info--text"
      >
        <div class="py-2 px-3" v-text="item.code" />
      </v-col>
    </v-row>
    <base-loading v-else />
    <template v-slot:cardActions>
      <div class="d-flex justify-space-between pa-4">
        <v-btn
          text
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="getNewCodes"
          v-text="
            $t(
              'page.profile_setting.security.authentication.recovery_codes.get_new_codes'
            )
          "
        />
        <v-btn
          depressed
          color="primary"
          :loading="exporting"
          @click="exportCodes"
          :disabled="exporting"
          v-text="$t('common.download')"
        />
      </div>
    </template>
  </base-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseLoading from '@/components/BaseLoading'
import { createTwoFactorAuthBackupCodesExport } from '@/api/users'
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      exporting: false
    }
  },
  computed: {
    ...mapGetters(['twoFactorAuthBackupCodes'])
  },
  methods: {
    ...mapActions([
      'getTwoFactorAuthBackupCodes',
      'generateTwoFactorAuthBackupCodes'
    ]),
    async exportCodes() {
      this.exporting = true
      try {
        const { data } = await createTwoFactorAuthBackupCodesExport()
        window.open(data.data.data.url)
      } catch (error) {}
      this.exporting = false
    },
    async getNewCodes() {
      this.loading = true
      try {
        await this.generateTwoFactorAuthBackupCodes()
      } catch (error) {}
      this.loading = false
    },
    async getData() {
      try {
        await this.getTwoFactorAuthBackupCodes()
      } catch (error) {}
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue && !this.twoFactorAuthBackupCodes) {
        this.getData()
      }
    }
  },
  components: {
    BaseDialog,
    BaseLoading
  }
}
</script>
