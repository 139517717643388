import request from '@/api/axios'

export const fetchTeams = (pid, params) =>
  request({
    url: `orgs/#oid/projects/${pid}/teams`,
    method: 'get',
    params
  })

export const fetchTeam = (tid, params) =>
  request({
    url: `orgs/#oid/teams/${tid}`,
    method: 'get',
    params
  })

export const fetchTeamAbilities = pid =>
  request({
    url: `orgs/#oid/projects/${pid}/teams/abilities`,
    method: 'get'
  })

export const createTeam = (pid, data) =>
  request({
    url: `orgs/#oid/projects/${pid}/teams`,
    method: 'post',
    data
  })

export const createTeamMemberships = (tid, data) =>
  request({
    url: `orgs/#oid/teams/${tid}/memberships`,
    method: 'post',
    data
  })

export const patchTeam = (tid, data) =>
  request({
    url: `/orgs/#oid/teams/${tid}`,
    method: 'patch',
    data
  })

export const patchTeamPermissions = (tid, data) =>
  request({
    url: `/orgs/#oid/teams/${tid}/permissions`,
    method: 'patch',
    data
  })

export const deleteTeam = tid =>
  request({
    url: `orgs/#oid/teams/${tid}`,
    method: 'delete'
  })

export const deleteTeams = (pid, data) =>
  request({
    url: `orgs/#oid/projects/${pid}/teams`,
    method: 'delete',
    data
  })

export const deleteTeamMemberships = (tid, data) =>
  request({
    url: `orgs/#oid/teams/${tid}/memberships`,
    method: 'delete',
    data
  })
