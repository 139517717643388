<template>
  <v-card height="100%" color="white" class="d-flex flex-column">
    <v-toolbar flat height="60" color="white" class="flex-grow-0">
      <base-dialog-back-btn
        back-icon="times"
        @go-back-clicked="hideCreateProjectDialog"
        :disabled="creatingProjectByTemplate || creatingProject"
      />
      <v-toolbar-title
        class="text-h3"
        v-text="$t('page.create_project.title')"
      />
    </v-toolbar>
    <v-card-text class="fill-width">
      <v-container class="d-flex flex-column align-center">
        <v-card class="fill-width" max-width="360" flat>
          <v-card-text class="px-0">
            <create-project-form
              :loading="creatingProject"
              @create-project-clicked="createProject"
            />
            <v-btn
              block
              outlined
              class="mt-4"
              @click="dialog = true"
              color="secondary darken-1"
              :loading="creatingProjectByTemplate"
              v-text="$t('page.create_project.use_template')"
            />
          </v-card-text>
        </v-card>
      </v-container>
    </v-card-text>
    <base-dialog
      card-color="white"
      :dialog.sync="dialog"
      content-class="small-level-two"
      :title="$t('page.create_project.title')"
    >
      <v-container class="d-flex flex-column align-center">
        <v-card class="fill-width" max-width="400" flat>
          <v-card-text>
            <template-selection
              @template-selected="handleTemplateSelection"
              text="page.create_project.template_selection.content"
            />
          </v-card-text>
        </v-card>
      </v-container>
    </base-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseDialogBackBtn from '@/components/BaseDialogBackBtn'
import CreateProjectForm from '@/components/project/CreateProjectForm'
import TemplateSelection from '@/components/onboarding/TemplateSelection'

export default {
  computed: {
    ...mapGetters(['project', 'organization'])
  },
  data() {
    return {
      dialog: false,
      creatingProject: false,
      creatingProjectByTemplate: false
    }
  },
  methods: {
    ...mapActions([
      'addProject',
      'getOrganization',
      'addProjectByTemplate',
      'hideCreateProjectDialog'
    ]),
    async handleCreateProjectSuccess() {
      this.hideCreateProjectDialog()
      const { projects } = this.organization
      const { id } = projects[projects.length - 1]
      this.$router.push({
        name: 'board',
        params: { pid: id, view: 'kanban' }
      })
    },
    async createProject(projectData) {
      this.creatingProject = true
      try {
        await this.addProject({ projectData })
        await this.getOrganization()
        this.handleCreateProjectSuccess()
      } catch (error) {}
      this.creatingProject = false
    },
    async handleTemplateSelection(id) {
      this.creatingProjectByTemplate = true
      this.dialog = false
      try {
        await this.addProjectByTemplate({ projectData: { template_id: id } })
        await this.getOrganization()
        this.handleCreateProjectSuccess()
      } catch (error) {}
      this.creatingProjectByTemplate = false
    }
  },
  components: {
    BaseDialog,
    CreateProjectForm,
    BaseDialogBackBtn,
    TemplateSelection
  }
}
</script>
