<template>
  <fragment>
    <v-dialog
      max-width="340"
      :persistent="loading"
      @click:outside="close"
      :value="dialog && !isMobile"
    >
      <v-card>
        <v-card-title class="text-h4 font-weight-medium" v-text="title" />
        <v-card-text class="body-2 secondary--text text--darken-2" :class="contentClass">
          {{ content }}
          <slot />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            min-width="80"
            @click="close"
            v-text="cancelText"
            :disabled="loading"
            :color="cancelColor"
          />
          <v-btn
            text
            min-width="80"
            v-text="okText"
            :color="okColor"
            :loading="loading"
            :disabled="disabled"
            @click="$emit('ok-clicked')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-sheet
      :persistent="loading"
      @click:outside="close"
      :value="dialog && isMobile"
    >
      <v-card flat tile color="white" class="text-start rounded-t-lg">
        <v-card-title class="px-4 d-flex align-center justify-space-between">
          <h3 class="text-h3 info--text" v-text="title" />
          <v-btn
            text
            @click="close"
            color="secondary darken-2"
            v-text="$t('common.close')"
          />
        </v-card-title>
        <v-card-text class="px-4 body-2 secondary--text text--darken-2">
          {{ content }}
          <slot />
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-container fluid>
            <v-row>
              <v-col cols="4" class="ps-0">
                <v-btn
                  block
                  outlined
                  depressed
                  @click="close"
                  v-text="cancelText"
                  :disabled="loading"
                  color="secondary darken-1"
                />
              </v-col>
              <v-col cols="8" class="pe-0">
                <v-btn
                  block
                  depressed
                  v-text="okText"
                  :color="okColor"
                  :loading="loading"
                  :disabled="disabled"
                  @click="$emit('ok-clicked')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    },
    okText: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    },
    okColor: {
      type: String,
      default: ''
    },
    cancelColor: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
      this.$emit('close-clicked', false)
    }
  }
}
</script>
