<template>
  <search-dialog-list-item
    :subtitle="
      selected.length
        ? $t('search_and_filter.n_items_selected', {
            count: selected.length,
            name: ''
          })
        : null
    "
    icon="swatchbook"
    :title="$t('common.color_label', { count: 0 })"
    :toolbar-title="$t('common.color_label', { count: 0 })"
  >
    <base-labels-list
      height="auto"
      :multiple="true"
      :label.sync="selected"
      :show-any-and-none="true"
      :labels="this.projectLabels ? Object.values(this.projectLabels) : []"
    />
  </search-dialog-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseLabelsList from '@/components/BaseLabelsList'
import SearchDialogListItem from '@/components/search/SearchDialogListItem'
export default {
  props: {
    option: {
      required: true
    },
    withAny: {
      required: true
    },
    withNo: {
      required: true
    }
  },
  components: {
    SearchDialogListItem,
    BaseLabelsList
  },
  computed: {
    ...mapGetters(['project', 'projectLabels'])
  },
  data() {
    return {
      selected: []
    }
  },
  watch: {
    selected(newValue) {
      if (newValue.indexOf('any') > -1) {
        this.$emit('update:withAny', 'labels')
      } else {
        this.$emit('update:withAny', null)
      }
      if (newValue.indexOf('none') > -1) {
        this.$emit('update:withNo', 'labels')
      } else {
        this.$emit('update:withNo', null)
      }
      this.$emit(
        'update:option',
        newValue.filter((item) => item !== 'any' && item !== 'none')
      )
    }
  }
}
</script>
