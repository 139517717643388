<template>
  <v-snackbar top v-model="show" :color="color" transition="slide-y-transition">
    {{ message }}
  </v-snackbar>
</template>

<script>
import { SHOW_MESSAGE } from '@/store/mutation-types'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      show: false,
      message: '',
      color: ''
    }
  },
  computed: {
    ...mapGetters(['snackbarContent', 'snackbarColor'])
  },
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === SHOW_MESSAGE) {
        this.message = this.snackbarContent
        this.color = this.snackbarColor
        this.show = true
      }
    })
  }
}
</script>
