import uniqBy from 'lodash.uniqby'
import sortBy from 'lodash.sortby'
import groupBy from 'lodash.groupby'
import { SECONDARY_PALETTE, COLOR_LABEL_MAP } from '@/constants/colors'

export const convertArrayToObject = (array, key = 'id') => {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    }
  }, initialValue)
}

export const getBoardsWithLists = (state, includeTasks = false) => {
  const { tasks, projectBoards, projectLists } = state
  if (!projectBoards || !projectLists) {
    return []
  }
  return Object.values(projectBoards)
    .map(board => {
      const items = Object.values(projectLists).reduce((filtered, list) => {
        if (list.board.id === board.id) {
          if (includeTasks) {
            filtered.push(list)
          } else {
            filtered.push({
              ...list,
              items: tasks ? tasks.filter(task => task.list.id === list.id) : []
            })
          }
        }
        return filtered
      }, [])
      return {
        ...board,
        active: true,
        items
      }
    })
    .filter(board => board.items.length > 0)
}

export const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getFilteredUsers = (collection, status) => {
  if (!collection) {
    return []
  }
  const items =
    typeof collection === 'object' ? Object.values(collection) : collection
  const list = status ? items.filter(member => member.status === status) : items
  return uniqBy(
    list.map(member => {
      return {
        ...member.user,
        membership_id: member.id
      }
    }),
    'id'
  )
}

export const kebabize = str => {
  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter
    })
    .join('')
}

export const truncate = (input, maxLength) => {
  return input.length > maxLength ? `${input.substring(0, maxLength)}⋯` : input
}

export const getInitials = (text) => {
  if (!text) {
    return ''
  }
  const splitted = text.split(' ')
  if (splitted.length < 2) {
    return `${splitted[0].charAt(0)}${splitted[0].charAt(1)}`.toUpperCase()
  }
  return splitted.map(word => word.charAt(0).toUpperCase()).join('')
}

export const getProjectTasksFromSheets = sheets => {
  return sheets
    .reduce((lists, sheet) => lists.concat(sheet.task_lists), [])
    .reduce(
      (acc, list) =>
        acc.concat(
          list.sections.reduce(
            (acc2, section) =>
              acc2.concat(
                section.tasks.map(task => {
                  return {
                    ...task,
                    section: section.name,
                    group: list.title,
                    list: list.title
                  }
                })
              ),
            []
          )
        ),
      []
    )
}

export const getProjectLabels = labels => {
  return [
    {
      color: '#fff',
      text: ''
    },
    ...labels.map((label, index) => {
      return {
        color: COLOR_LABEL_MAP[index],
        text: label
      }
    })
  ]
}

export const getUserAvatar = (user, size) => {
  if (!user.images) {
    return ''
  }
  const image = user.images[size]
  return image.indexOf('static/users-0') > -1 ? '' : image
}

export const getUserGroups = (users, prop = 'title', query = '') => {
  return groupBy(
    sortBy(
      users.filter(user => {
        const q = query ? query.toLowerCase() : ''
        return Object.values(user).some(val =>
          String(val)
            .toLowerCase()
            .includes(q)
        )
      }),
      [user => user[prop].toLowerCase()],
      ['desc']
    ),
    user => {
      return user[prop].charAt(0).toUpperCase()
    }
  )
}

export const getColorFromPaletteBasedOnText = text => {
  return SECONDARY_PALETTE[text.length % SECONDARY_PALETTE.length]
}

export const getUserWithAvatar = (user, size = 'small') => {
  const image = getUserAvatar(user, size)
  return {
    image,
    id: user.id,
    title: user.name,
    username: user.username,
    color: getColorFromPaletteBasedOnText(user.name)
  }
}

export const isUnicode = str => {
  return /[\u0591-\u07FF]/.test(str)
}

export const getTextDirection = text => {
  return isUnicode(text) ? 'rtl' : 'ltr'
}

export const sec2time = timeInSeconds => {
  const pad = (num, size) => {
    return ('000' + num).slice(size * -1)
  }
  const time = parseFloat(timeInSeconds).toFixed(3)
  const hours = Math.floor(time / 60 / 60)
  const minutes = Math.floor(time / 60) % 60
  const seconds = Math.floor(time - minutes * 60)
  return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2)
}

export const getRef = (obj, str) => {
  str = str.split('.')
  for (var i = 0; i < str.length; i++) obj = obj[str[i]]
  return obj
}

export const updateObject = (object, newValue, path) => {
  const stack = path.split('.')

  while (stack.length > 1) {
    object = object[stack.shift()]
  }
  object[stack.shift()] = newValue
}

export const filterDate = (date, range) => {
  if (range.length > 1) {
    return date >= range[0] && date <= range[1]
  }
  return date >= range[0]
}

export const filterCollectionsWithAnyOrNoneValues = (
  base,
  value,
  collection
) => {
  const hasAny = collection.includes('any')
  const hasNone = collection.includes('none')
  let list = collection
  if (hasAny) {
    list = base.reduce((result, item, index) => {
      if (item.text.length) {
        result.push(index)
      }
      return result
    }, [])
  }
  if (hasNone) {
    list.push(0)
  }
  return list.includes(value)
}

export const getOption = (optionLabel, options) => {
  return options.find(option => option.label === optionLabel)
}

export const getOptionValue = (optionLabel, options) => {
  return getOption(optionLabel, options).value
}

export const convertMegabytesToBytes = megabytes => megabytes * 1024 * 1024

export const getOrganizationSubdomain = () => {
  const domains = ['beta', 'pwa-staging', 'www']
  const domainParts = window.location.hostname.split('.')
  if (domainParts.length > 2 && domains.indexOf(domainParts[0]) < 0) {
    return domainParts[0]
  }
  return null
}
