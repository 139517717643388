<template>
  <fragment>
    <div class="d-flex align-center flex-column fill-width">
      <template v-if="organization">
        <base-avatar
          :size="64"
          class="mt-6"
          text-size="h1"
          :elevated="true"
          :title="organization.title"
          :image="organization.logo ? organization.logo.medium : ''"
        />
        <h2 class="text-h3 mt-2" v-text="organization.title" />
      </template>
      <h3
        v-text="$t('page.sign_in.title')"
        class="text-h1 info--text fill-width mt-4 mb-8"
      />
      <template v-if="noOrganization || organization">
        <v-form
          class="fill-width"
          :disabled="loading"
          @submit.prevent=""
        >
          <v-text-field
            dense
            outlined
            :value="identifier"
            :label="$t('form.labels.identifier')"
            @input="$emit('update:identifier', $event)"
          />
          <base-password
            :value="password"
            :label="$t('form.labels.password')"
            @password-updated="$emit('update:password', $event)"
          />
          <div class="d-flex flex-column justify-start fill-width mb-6">
            <router-link
              :to="'/reset-password'"
              class="text-button no text-decoration-none"
              v-text="$t('page.sign_in.forgot_password')"
            />
          </div>
          <div class="flex-grow-0 fill-width">
            <v-btn
              block
              depressed
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="!formIsValid"
              @click.prevent="$emit('form-submitted')"
              v-text="$t('common.sign_in')"
            />
          </div>
        </v-form>
      </template>
    </div>
    <base-loading v-if="!noOrganization && !organization" />
  </fragment>
</template>

<script>
import BaseAvatar from '@/components/BaseAvatar'
import BaseLoading from '@/components/BaseLoading'
import BasePassword from '@/components/BasePassword'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    noOrganization: {
      type: Boolean,
      default: false
    },
    organization: {
      required: true
    },
    password: {
      required: true
    },
    identifier: {
      required: true
    }
  },
  computed: {
    formIsValid() {
      return this.identifier && this.identifier.length && this.password && this.password.length
    }
  },
  components: {
    BaseAvatar,
    BaseLoading,
    BasePassword
  }
}
</script>
