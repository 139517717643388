<template>
  <v-container fluid>
    <task-dialog-details-informations-row
      :add-button-text="$t('common.add_list')"
      @row-clicked="dialog = true"
      :label="$t('common.list')"
      :text="taskListTitle"
      :editable="editable"
      :loading="loading"
      icon="columns"
    />
    <base-dialog
      card-color="white"
      :loading="loading"
      @save-clicked="save"
      :show-save-btn="true"
      :dialog.sync="dialog"
      content-class="large-level-two"
      :save-btn-text="$t('common.done')"
      card-text-class="d-flex flex-column"
      :title="$t('common.list', { count: 0 })"
      :disable-save-btn="!selected || loading"
    >
      <task-dialog-actions-copy-task-dialog-chip :task="task" class="pa-4" />
      <base-expansion-list
        :items="items"
        :selected.sync="selected"
        :current-item-disabled="true"
        :current-item-id="task.list.id"
        :empty-list-text="$t('search_and_filter.results.not_found.lists')"
      />
    </base-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseExpansionList from '@/components/BaseExpansionList'
import TaskDialogDetailsInformationsRow from '@/components/task/TaskDialogDetailsInformationsRow'
import TaskDialogActionsCopyTaskDialogChip from '@/components/task/TaskDialogActionsCopyTaskDialogChip'

export default {
  props: {
    lists: {
      type: Array,
      required: true
    },
    boardsWithLists: {
      type: Array,
      required: true
    },
    task: {
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['teamAbilities', 'abilitiesPriorityMap']),
    taskListTitle() {
      const list = this.lists.find((list) => list.id === this.task.list.id)
      return list ? list.title : ''
    },
    tid() {
      return this.task.id
    },
    items() {
      return this.boardsWithLists.reduce((filtered, board) => {
        const filteredLists = board.items.filter(list => this.teamAbilities.lists[list.id] >= this.abilitiesPriorityMap.List.receive_task)
        if (filteredLists.length > 0) {
          filtered.push({
            ...board,
            items: filteredLists
          })
        }
        return filtered
      }, [])
    }
  },
  data() {
    return {
      dialog: false,
      selected: null,
      loading: false
    }
  },
  methods: {
    ...mapActions(['updateTaskWithAlias']),
    async save() {
      const { selected } = this
      this.loading = true
      try {
        await this.updateTaskWithAlias({
          tid: this.tid,
          data: {
            list_id: selected
          },
          keys: ['list.id'],
          values: [selected],
          path: 'move'
        })
        this.dialog = false
      } catch (error) {}
      this.loading = false
    }
  },
  components: {
    BaseDialog,
    BaseExpansionList,
    TaskDialogDetailsInformationsRow,
    TaskDialogActionsCopyTaskDialogChip
  }
}
</script>
