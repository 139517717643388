import {
  SET_TASK,
  SET_TASK_VALUE,
  ADD_TASK_CHECKLIST,
  SET_TASK_CHECKLIST,
  SET_TASK_ACTIVITIES,
  ADD_TASK_ACTIVITIES,
  ADD_TASK_ATTACHMENT,
  DELETE_PROJECT_TASK,
  DELETE_TASK_CHECKLIST,
  SET_PROJECT_TASK_VALUE,
  DELETE_TASK_ATTACHMENT,
  SET_MY_TASKS_TASK_VALUE,
  SET_TASK_CHECKLIST_ITEM,
  ADD_TASK_CHECKLIST_ITEM,
  SET_TASK_DIALOG_VISIBLE,
  SET_TASK_ASSIGNABLE_USERS,
  DELETE_TASK_CHECKLIST_ITEM,
  SET_TASK_TIMELOGS_DURATION
} from '../mutation-types'
import { updateObject } from '@/utils/helpers'
import {
  editTask,
  fetchTask,
  patchTask,
  deleteTask,
  createTaskValue,
  fetchAssignableUsers
} from '@/api/tasks'

import { fetchActivities } from '@/api/activities'

import { deleteAttachment, createAttachment } from '@/api/attachments'
import { startTimelog, stopTimelog } from '@/api/timelogs'

import {
  editChecklist,
  createChecklist,
  deleteChecklist,
  patchChecklistItem,
  deleteChecklistItem,
  createChecklistItem
} from '@/api/checklists'

const params = {
  include: [
    'tags',
    'dependees',
    'assignees',
    'checklists',
    'dependents',
    'attachments',
    'timelogs_duration'
  ],
  expand: ['creator', 'status', 'label']
}
const state = {
  task: null,
  taskActivities: null,
  taskAssignableUsers: null,
  taskDialogIsVisible: false
}

const getters = {
  task: state => state.task,
  taskActivities: state => state.taskActivities,
  taskAssignableUsers: state => state.taskAssignableUsers,
  taskDialogIsVisible: state => state.taskDialogIsVisible
}

const mutations = {
  [SET_TASK](state, task) {
    state.task = task
  },
  [ADD_TASK_CHECKLIST](state, checklists) {
    state.task.checklists = [...state.task.checklists, checklists]
  },
  [ADD_TASK_ATTACHMENT](state, attachment) {
    state.task.attachments = [attachment, ...state.task.attachments]
  },
  [DELETE_TASK_CHECKLIST](state, index) {
    state.task.checklists.splice(index, 1)
  },
  [DELETE_TASK_ATTACHMENT](state, aid) {
    const index = state.task.attachments.findIndex(
      attachment => attachment.id === aid
    )
    if (index < 0) {
      return
    }
    state.task.attachments.splice(index, 1)
  },
  [SET_TASK_CHECKLIST](state, { cid, prop, value }) {
    const checklist = state.task.checklists.find(c => c.id === cid)
    if (!checklist) {
      return
    }
    checklist[prop] = value
  },
  [DELETE_TASK_CHECKLIST_ITEM](state, { cid, iid }) {
    const { checklists } = state.task
    const listIndex = checklists.findIndex(c => c.id === cid)
    if (listIndex < 0) {
      return
    }
    const itemIndex = checklists[listIndex].items.findIndex(
      item => item.id === iid
    )
    if (itemIndex < 0) {
      return
    }
    checklists[listIndex].items.splice(itemIndex, 1)
  },
  [ADD_TASK_CHECKLIST_ITEM](state, { cid, item }) {
    const checklist = state.task.checklists.find(c => c.id === cid)

    if (!checklist) {
      return
    }

    checklist.items = checklist.items ? [...checklist.items, item] : [item]
  },
  [SET_TASK_CHECKLIST_ITEM](state, { cid, iid, prop, value }) {
    const checklist = state.task.checklists.find(c => c.id === cid)
    if (!checklist) {
      return
    }
    const item = checklist.items.find(item => item.id === iid)
    if (!item) {
      return
    }
    item[prop] = value
  },
  [SET_TASK_DIALOG_VISIBLE](state, taskDialogIsVisible) {
    state.taskDialogIsVisible = taskDialogIsVisible
  },
  [SET_TASK_ACTIVITIES](state, activities) {
    state.taskActivities = activities
  },
  [SET_TASK_TIMELOGS_DURATION](state, timelogsDuration) {
    state.task.timelogs_duration = {
      ...state.task.timelogs_duration,
      ...timelogsDuration
    }
  },
  [ADD_TASK_ACTIVITIES](state, activities) {
    state.taskActivities.offset_token = activities.offset_token
    state.taskActivities.records = [
      ...state.taskActivities.records,
      ...activities.records
    ]
  },
  [SET_TASK_VALUE](state, { keys, values }) {
    for (let i = 0; i < keys.length; i++) {
      updateObject(state.task, values[i], keys[i])
    }
  },
  [SET_TASK_ASSIGNABLE_USERS](state, taskAssignableUsers) {
    state.taskAssignableUsers = taskAssignableUsers
  }
}

const actions = {
  async getTask({ commit }, tid) {
    commit(SET_TASK, null)
    commit(SET_TASK_ACTIVITIES, null)
    const { data } = await fetchTask(tid, params)
    const task = data.data
    commit(SET_TASK, task)
  },
  async getTaskActivities({ commit }, { pid, params }) {
    const { data } = await fetchActivities(pid, params)
    commit(SET_TASK_ACTIVITIES, data.data)
  },
  async getAssignableUsers({ commit }, tid) {
    const { data } = await fetchAssignableUsers(tid)
    commit(SET_TASK_ASSIGNABLE_USERS, data.data)
  },
  async addTaskActivities({ commit }, { pid, params }) {
    const { data } = await fetchActivities(pid, params)
    commit(ADD_TASK_ACTIVITIES, data.data)
  },
  async getTaskPageInfo({ dispatch, state, rootState }, { pid, tid }) {
    if (!rootState.projects.tasks) {
      await dispatch('getProjectTasks', pid, { root: true })
    }
    if (state.task && state.task.id === tid) {
      return
    }
    dispatch('getTask', tid)
    dispatch('getAssignableUsers', tid)
  },
  async openTaskDialogInEditMode(
    { commit, dispatch, rootState },
    { tid, pid }
  ) {
    commit(SET_TASK_DIALOG_VISIBLE, true)
    const { project } = rootState.projects
    if (!project || project.id !== pid) {
      Promise.all([
        dispatch('getProjectMemberships', pid, { root: true }),
        dispatch('getProjectFeatures', pid, { root: true }),
        dispatch('getProjectStatuses', pid, { root: true }),
        dispatch('getProjectLabels', pid, { root: true }),
        dispatch('getProjectBoards', pid, { root: true }),
        dispatch('getTeamAbilities', pid, { root: true }),
        dispatch('getProjectLists', pid, { root: true }),
        dispatch('getProjectTags', pid, { root: true }),
        dispatch('getProject', pid, { root: true })
      ]).then(() => {
        dispatch('getTaskPageInfo', { pid, tid })
      })
    } else {
      dispatch('getTaskPageInfo', { pid, tid })
    }
  },
  async updateProjectTask({ commit }, { tid, data, keys, updateListItem }) {
    const result = await patchTask(
      tid,
      (data = {
        ...data,
        ...{
          include: ['tags'],
          expand: ['tags', 'label', 'assignees', 'status']
        }
      })
    )
    const task = result.data.data
    const values = keys.map(key => task[key])
    commit(SET_TASK_VALUE, { keys, values })
    if (!updateListItem) {
      return
    }
    commit(SET_PROJECT_TASK_VALUE, { tid, keys, values }, { root: true })
    commit(SET_MY_TASKS_TASK_VALUE, { tid, keys, values }, { root: true })
  },
  async updateTaskWithAlias(
    { commit },
    { tid, path, keys, values, data, myTaskValues, myTaskKeys }
  ) {
    const body = data || {}
    await createTaskValue(tid, body, path)
    commit(SET_TASK_VALUE, { keys, values })
    commit(SET_PROJECT_TASK_VALUE, { tid, keys, values }, { root: true })
    if (myTaskValues && myTaskKeys) {
      commit(
        SET_MY_TASKS_TASK_VALUE,
        { tid, keys: myTaskKeys, values: myTaskValues },
        { root: true }
      )
    }
  },
  async updateTask({ commit }, { tid, data, path, values, updateMyTask }) {
    await editTask(tid, data, path)
    const keys = [path]
    commit(SET_TASK_VALUE, {
      keys,
      values
    })
    commit(SET_PROJECT_TASK_VALUE, { tid, keys, values }, { root: true })
    if (updateMyTask) {
      commit(SET_MY_TASKS_TASK_VALUE, { tid, keys, values }, { root: true })
    }
  },
  async addTaskChecklist({ commit }, { pid, data }) {
    const result = await createChecklist(pid, data)
    if (!result.data.data.items) result.data.data.items = []
    commit(ADD_TASK_CHECKLIST, {
      ...result.data.data
    })
  },
  async updateTaskChecklist({ commit }, { cid, data, prop }) {
    const result = await editChecklist(cid, data)
    commit(SET_TASK_CHECKLIST, { cid, prop, value: result.data.data[prop] })
  },
  async addTaskChecklistItem({ commit }, { pid, cid, data }) {
    const result = await createChecklistItem(pid, data)
    commit(ADD_TASK_CHECKLIST_ITEM, { cid, item: result.data.data })
  },
  async deleteProjectTask({ commit }, tid) {
    await deleteTask(tid)
    commit(DELETE_PROJECT_TASK, tid, { root: true })
  },
  async addTaskAttachment({ commit }, { pid, data }) {
    const result = await createAttachment(pid, data)
    commit(ADD_TASK_ATTACHMENT, result.data.data)
  },
  async startTaskTimelog({ commit }, { pid, data }) {
    await startTimelog(pid, data)
    const newValue = {
      active: 1
    }
    commit(SET_TASK_TIMELOGS_DURATION, newValue)
  },
  async stopTaskTimelog({ commit, state }, { pid, data }) {
    const result = await stopTimelog(pid, data)
    const { duration } = result.data.data
    const timelogsDuration = state.task.timelogs_duration
    const userTimelog = timelogsDuration.user
    const totalTimelog = timelogsDuration.total
    const newValue = {
      active: 0,
      user: userTimelog + duration,
      total: totalTimelog + duration
    }
    commit(SET_TASK_TIMELOGS_DURATION, newValue)
  },
  updateChecklistItem({ commit }, { cid, iid, data, prop }) {
    commit(SET_TASK_CHECKLIST_ITEM, {
      iid,
      cid,
      prop,
      value: data[prop]
    })
    patchChecklistItem(iid, data)
  },
  deleteTaskChecklist({ commit }, { cid, index }) {
    deleteChecklist(cid)
    commit(DELETE_TASK_CHECKLIST, index)
  },
  deleteTaskAttachment({ commit }, aid) {
    deleteAttachment(aid)
    commit(DELETE_TASK_ATTACHMENT, aid)
  },
  deleteTaskChecklistItem({ commit }, { cid, iid }) {
    deleteChecklistItem(iid)
    commit(DELETE_TASK_CHECKLIST_ITEM, { cid, iid })
  },
  hideTaskDialog({ commit }) {
    commit(SET_TASK_DIALOG_VISIBLE, false)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
