<template>
  <div
    style="position: relative"
    class="secondary lighten-5 fill-height"
    :class="{
      'left-bordered': $vuetify.rtl && !isMobile,
      'right-bordered': !$vuetify.rtl && !isMobile
    }"
  >
    <div
      v-if="lists.length"
      class="card-wrapper d-flex flex-column flex-wrap align-content-start"
    >
      <div :key="list.id" class="list-wrapper" v-for="list in lists">
        <task-list-kanban-list
          class="list-container"
          :list="list"
          :id="`list_${list.id}`"
          :create-task-visible="createTaskVisible"
          @move-list-clicked="$emit('move-list-clicked', list)"
          @copy-list-clicked="$emit('copy-list-clicked', list)"
          @watch-list-clicked="$emit('watch-list-clicked', list)"
          @project-task-moved="$emit('project-task-moved', $event)"
          @rename-list-clicked="$emit('rename-list-clicked', list)"
          @archive-list-clicked="$emit('archive-list-clicked', list)"
        />
      </div>
      <div class="list-wrapper" v-if="!isMobile && createListVisible">
        <task-list-kanban-list
          :isDummy="true"
          :create-task-visible="createTaskVisible"
          @create-list-clicked="$emit('create-list-clicked')"
        />
      </div>
    </div>
    <div class="fill-height d-flex align-center justify-center" v-else>
      <div class="d-flex flex-column align-center">
        <v-img
          width="168"
          height="148"
          src="../../assets/img/illustrations/project-empty-state.svg"
        />
        <h4 class="mt-6 h4-text secondary--text text--darken-2">
          {{ $t('page.kanban.board.empty_board') }}
        </h4>
        <v-btn
          depressed
          class="mt-6"
          color="primary"
          v-if="createListVisible"
          @click="$emit('add-list-clicked')"
        >
          + {{ $t('common.add_list') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskListKanbanList from '@/components/project/TaskListKanbanList'
export default {
  props: {
    lists: {
      type: Array,
      required: true
    },
    createListVisible: {
      type: Boolean,
      required: true
    },
    createTaskVisible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  components: {
    TaskListKanbanList
  }
}
</script>
<style lang="scss">
.card-wrapper {
  user-select: none;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.list-wrapper {
  width: 348px;
  height: 100%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  padding-bottom: 8px;
  .list {
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    position: relative;
    white-space: normal;
    .list-header {
      flex: 0 0 auto;
      position: relative;
      height: 60px;
    }
    .list-cards {
      flex: 1 1 auto;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 1;
      min-height: 0;
      .section {
        .moving-card {
          opacity: 1 !important;
          box-shadow: inset 0px 0.25px 1px rgba(0, 0, 0, 0.039),
            inset 0px 0.85px 3px rgba(0, 0, 0, 0.19) !important;
          .task-content {
            visibility: hidden;
          }
        }
        .chosen-card {
          background-color: var(--v-secondary-lighten5) !important;
        }
        &-header {
          background: var(--v-secondary-lighten5);
          position: sticky;
          top: 0;
          z-index: 1;
        }
      }
    }
  }
}
.right-bordered {
  .list-wrapper {
    border-right: 1px solid var(--v-secondary-lighten1);
  }
}
.left-bordered {
  .list-wrapper {
    border-left: 1px solid var(--v-secondary-lighten1);
  }
}
</style>
