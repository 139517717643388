<template>
  <task-dialog-files-list-item-uploading
    :item="item"
    @action-clicked="handleClick"
    v-if="item.uploading || item.hasError"
  >
    <task-dialog-files-list-item-content v-bind="contentProps" />
  </task-dialog-files-list-item-uploading>
  <task-dialog-files-list-item-uploaded
    v-else
    :item="item"
    :priority="priority"
    :fontSize="fontSize"
    :extention="extention"
    :icon-color="iconColor"
    @delete-clicked="handleDelete"
    @download-clicked="handleDownload"
  >
    <task-dialog-files-list-item-content v-bind="contentProps" />
  </task-dialog-files-list-item-uploaded>
</template>

<script>
import filesize from 'filesize'
import { mapActions, mapGetters } from 'vuex'
import { createFile } from '@/api/files'
import uploadService from '@/services/uploadService'
import { getColorFromPaletteBasedOnText } from '@/utils/helpers'
import TaskDialogFilesListItemContent from '@/components/task/TaskDialogFilesListItemContent'
import TaskDialogFilesListItemUploaded from '@/components/task/TaskDialogFilesListItemUploaded'
import TaskDialogFilesListItemUploading from '@/components/task/TaskDialogFilesListItemUploading'
const iconWidth = 13
const characterWidth = 8.3
export default {
  props: {
    attachment: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    priority: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      fileId: null,
      fileObj: null,
      endPoint: ''
    }
  },
  computed: {
    ...mapGetters(['project', 'task']),
    owner() {
      const user = this.users.find(
        (user) => user.id === this.attachment.creator.id
      )
      if (user) {
        return user.username
      }
      return ''
    },
    item() {
      return {
        ...this.attachment,
        size: this.attachment.size ? filesize(this.attachment.size) : null,
        owner: this.owner
      }
    },
    lastIndexOfDot() {
      return this.item.name.lastIndexOf('.')
    },
    extention() {
      if (this.lastIndexOfDot < 0) {
        return ''
      }
      return `${this.item.name.substring(this.lastIndexOfDot + 1)}`
    },
    name() {
      if (this.lastIndexOfDot < 0) {
        return this.item.name
      }
      return this.item.name.substring(0, this.lastIndexOfDot)
    },
    fontSize() {
      const characters = this.extention.length * 10
      return iconWidth / characters
    },
    iconColor() {
      return getColorFromPaletteBasedOnText(this.extention)
    },
    extentionWidth() {
      return this.extention.length * characterWidth
    },
    direction() {
      return this.$vuetify.rtl ? 'left' : 'right'
    },
    contentProps() {
      return {
        item: this.item,
        name: this.name,
        extention: this.extention
      }
    },
    pid() {
      return this.project.id
    },
    tid() {
      return this.task.id
    }
  },
  methods: {
    ...mapActions(['deleteTaskAttachment', 'addTaskAttachment']),
    handleDownload() {
      const { item } = this
      const link = document.createElement('a')
      link.style.display = 'none'
      document.body.appendChild(link)
      link.href = item.download_url
      link.setAttribute('download', item.name)
      link.click()
      document.body.removeChild(link)
    },
    handleError() {
      this.attachment.hasError = true
      this.attachment.uploading = false
    },
    async handleDone() {
      try {
        await this.addTaskAttachment({
          pid: this.pid,
          data: {
            source_type: 'Local',
            file_attributes: {
              id: this.fileId
            },
            parent_type: 'Task',
            parent_id: this.tid
          }
        })
        this.$emit('attachment-created', this.attachment.id)
      } catch (error) {}
    },
    handleCancel() {
      if (
        this.attachment.cancelUploadObj &&
        this.attachment.cancelUploadObj.cancel
      ) {
        this.attachment.cancelUploadObj.cancel()
      }
      this.$emit('attachment-upload-canceled', this.attachment.id)
    },
    handleRetry() {
      this.attachment.progress = 0
      this.attachment.hasError = false
      this.attachment.uploading = true
      this.uploadFile()
    },
    handleClick() {
      if (!this.attachment.hasError) {
        return this.handleCancel()
      }
      this.handleRetry()
    },
    handleDelete() {
      this.deleteTaskAttachment(this.attachment.id)
    },
    setProgress(percent) {
      this.attachment.progress = percent
    },
    async uploadFile() {
      const createdFile = await createFile()
      const id = createdFile.data.data.id
      this.endPoint = `/files/${id}`
      this.fileId = id
      this.progress = 0
      uploadService.chunk(
        `/files/${id}`,
        this.attachment.uploaded,
        this.setProgress,
        this.handleError,
        this.handleDone,
        this.attachment.cancelUploadObj
      )
    },
    deleteItem() {}
  },
  mounted() {
    if (!this.attachment.uploading) {
      return
    }
    this.uploadFile()
  },
  components: {
    TaskDialogFilesListItemContent,
    TaskDialogFilesListItemUploaded,
    TaskDialogFilesListItemUploading
  }
}
</script>
