<template>
  <v-list-item @click="$emit('item-clicked', timelog)">
    <v-list-item-avatar>
      <base-avatar
        :size="48"
        text-size="subtitle-1"
        :title="timelog.user.username"
        :image="timelog.user.avatar ? timelog.user.avatar.medium : ''"
      />
    </v-list-item-avatar>
    <v-list-item-content class="py-1">
      <v-list-item-title class="body-1 info--text">
        {{ timelog.user.name }}
      </v-list-item-title>
      <v-list-item-subtitle class="body-1 info--text">
        <strong>#{{ timelog.task.number }}</strong> {{ timelog.task.title }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="d-flex caption error--text text--darken-4">
        <base-icon
          width="6"
          height="6"
          name="t-dot"
          color="error"
          viewBox="0 0 6 6"
          className="d-flex align-center justify-center"
        />
        <base-stopwatch class="ms-1" :starting-seconds="startingSeconds" />
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import BaseAvatar from '@/components/BaseAvatar'
import BaseStopwatch from '@/components/BaseStopwatch'
export default {
  props: {
    timelog: {
      type: Object,
      required: true
    }
  },
  computed: {
    startingSeconds() {
      const now = new Date().getTime() / 1000
      return now - this.timelog.started_at
    }
  },
  components: {
    BaseIcon,
    BaseAvatar,
    BaseStopwatch
  }
}
</script>
