<template>
  <v-list-item-action class="mt-0">
    <v-menu
      bottom
      min-width="168"
      :right="$vuetify.rtl"
      :left="!$vuetify.rtl"
      transition="slide-x-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-on="on" v-bind="attrs" color="secondary.darken2">
          <base-icon name="ellipsis-v" color="secondary.darken2" />
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="$emit('download-clicked')" v-if="downloadable">
          <v-list-item-icon class="me-3">
            <base-icon
              name="import"
              color="secondary.darken2"
              className="d-flex align-center"
            />
          </v-list-item-icon>
          <v-list-item-title class="body-1 secondary--text text--darken-2">
            {{ $t('common.download') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="previewLink" :href="previewLink" target="_blank">
          <v-list-item-icon class="me-3">
            <base-icon
              color="secondary.darken2"
              name="external-link-alt"
              className="d-flex align-center"
            />
          </v-list-item-icon>
          <v-list-item-title class="body-1 secondary--text text--darken-2">
            {{ $t('common.open') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="deletable" @click="$emit('delete-clicked')">
          <v-list-item-icon class="me-3">
            <base-icon
              name="trash-alt"
              color="error"
              className="d-flex align-center"
            />
          </v-list-item-icon>
          <v-list-item-title class="body-1 error--text">
            {{ $t('common.delete') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item-action>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  props: {
    deletable: {
      type: Boolean,
      default: false
    },
    downloadable: {
      type: Boolean,
      default: false
    },
    previewLink: {
      type: String
    }
  },
  components: {
    BaseIcon
  }
}
</script>
