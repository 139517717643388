<template>
  <base-confirm-dialog
    :dialog="dialog"
    :loading="loading"
    content-class="pb-2"
    ok-color="error base"
    cancel-color="secondary darken-2"
    :ok-text="$t('common.yes')"
    :cancel-text="$t('common.no')"
    @ok-clicked="$emit('ok-clicked')"
    @close-clicked="$emit('update:dialog', $event)"
    :title="$t('confirmations.remove_member.title')"
  >
    <p v-text="$t('confirmations.remove_member.description')" class="mb-8" />
    <base-members-list-with-actions
      class="py-0"
      :members="[member]"
      :has-actions="false"
      :no-item-padding="true"
    />
  </base-confirm-dialog>
</template>

<script>
import BaseConfirmDialog from './BaseConfirmDialog'
import BaseMembersListWithActions from '@/components/BaseMembersListWithActions'
export default {
  props: {
    member: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    confirmDialog(newValue) {
      this.$emit('update:confirmDialog', newValue)
    }
  },
  components: { BaseMembersListWithActions, BaseConfirmDialog }
}
</script>
