import './mixins'
import './layouts'
import Vue from 'vue'
import i18n from './i18n.js'
import App from './App.vue'
import store from './store'
import Unicon from './icons'
import './registerServiceWorker'
import Fragment from 'vue-fragment'
import router from './router/switch'
import VueScrollTo from 'vue-scrollto'
import VueGtm from '@gtm-support/vue2-gtm'
import vuetify from './plugins/vuetify'
import BaseAvatarImageUploader from '@/components/BaseAvatarImageUploader'
Vue.config.productionTip = false

Vue.use(Unicon)
Vue.use(VueScrollTo)
Vue.use(Fragment.Plugin)
Vue.component('base-avatar-image-uploader', BaseAvatarImageUploader)

const getPageTitle = (title) => {
  let siteName = 'Taskulu'
  const userLanguage = store?.state?.user && store?.state?.user.locale
  switch (userLanguage) {
    case 'fa':
      siteName = 'تسکولو'
      break
    default:
      break
  }
  if (!title || !title.length) {
    return siteName
  }
  const organizationName = store?.state?.organization && store?.state?.organization.title
  const projectName = store?.state?.projects?.project && store.state.projects.project.title
  const organizationTitle = organizationName ? ` > ${organizationName}` : ''
  const projectTitle = projectName ? ` > ${projectName}` : ''
  return `${title}${projectTitle}${organizationTitle} | ${siteName}`
}

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)
  if (!nearestWithTitle) {
    return ''
  }
  const docTitle = getPageTitle(nearestWithTitle.meta.title)

  if (nearestWithTitle) {
    document.title = docTitle
  }

  next()
})

Vue.use(VueGtm, {
  // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  id: 'GTM-KD6S5M',
  defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
