<template>
  <fragment>
    <div @click="handleClickOnItemSelect">
      <v-select
        dense
        outlined
        hide-details
        :value="value"
        :items="items"
        :class="className"
        :disabled="disabled"
        :readonly="isMobile"
        @change="handleItemChange"
        class="body-2 mt-4 dark-disabled"
      >
        <template v-slot:selection="{ item }">
          <span class="info--text" v-text="item.text" />
        </template>
      </v-select>
    </div>
    <v-bottom-sheet v-model="sheet">
      <v-card height="400" color="white">
        <v-card-text class="px-0 overflow-y-auto" style="height: 400px">
          <base-list
            :value="value"
            :items="items"
            :multiple="false"
            :filterable="false"
            :title-prop="titleProp"
            @value-changed="handleValueChange"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseList from '@/components/BaseList'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    items: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    titleProp: {
      type: String,
      default: 'text'
    }
  },
  data() {
    return {
      sheet: false
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    handleClickOnItemSelect() {
      if (!this.isMobile || this.disabled) {
        return
      }
      this.sheet = true
    },
    handleValueChange(value) {
      this.sheet = false
      this.$emit('update:value', value)
      this.$emit('value-changed', value)
    },
    handleItemChange(value) {
      this.$emit('update:value', value)
      this.$emit('value-changed', value)
    }
  },
  components: {
    BaseList
  }
}
</script>
