<template>
  <section class="px-4">
    <h2
      class="py-2 my-0 body-1 info--text"
      v-text="$t('page.project_settings.advanced_settings.close_project.title')"
    />
    <p
      class="py-2 my-0 body-1 secondary--text text--darken-2"
      v-text="
        $t('page.project_settings.advanced_settings.close_project.description')
      "
    />
    <p class="py-2 my-0 d-flex align-center justify-center">
      <v-btn
        depressed
        width="148"
        color="error"
        @click="dialog = true"
        v-text="
          $t('page.project_settings.advanced_settings.close_project.button')
        "
      />
    </p>
    <base-dialog
      card-color="white"
      :dialog.sync="dialog"
      :hide-back-btn="true"
      content-class="small-level-two"
      :title="
        $t(
          'page.project_settings.advanced_settings.close_project.confirmation.title'
        )
      "
    >
      <section class="px-4">
        <h3
          class="py-2 my-0 body-1 info--text"
          v-html="
            $t(
              'page.project_settings.advanced_settings.close_project.confirmation.subtitle',
              {
                title: this.project.title,
                interpolation: { escapeValue: false }
              }
            )
          "
        />
        <p
          class="py-2 my-0 body-1 secondary--text text--darken-2"
          v-text="
            $t(
              'page.project_settings.advanced_settings.close_project.confirmation.description'
            )
          "
        />
        <p
          class="py-2 my-0 body-1 info--text"
          v-text="
            $t(
              'page.project_settings.advanced_settings.close_project.confirmation.close_confirmation'
            )
          "
        />
      </section>

      <template v-slot:cardActions>
        <v-card-actions class="pb-4 d-flex justify-space-between">
          <v-btn
            depressed
            width="136"
            color="primary"
            :disabled="loading"
            @click="dialog = false"
            v-text="$t('common.cancel')"
          />
          <v-btn
            text
            width="136"
            color="error"
            :loading="loading"
            :disabled="loading"
            @click="handleClickOnCloseConfirmation"
            v-text="
              $t(
                'page.project_settings.advanced_settings.close_project.confirmation.close_button'
              )
            "
          />
        </v-card-actions>
      </template>
    </base-dialog>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
export default {
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    ...mapActions(['closeProject']),
    async handleClickOnCloseConfirmation() {
      this.loading = true
      try {
        await this.closeProject(this.project.id)
        this.dialog = false
        this.$router.push({ path: '/' })
      } catch (error) {}
      this.loading = false
    }
  },
  components: {
    BaseDialog
  }
}
</script>
