import store from '@/store'
import Home from '@/views/MyTasks'
import SignIn from '@/views/SignIn'
import Boards from '@/views/Boards'
import Profile from '@/views/Profile'
import Timelogs from '@/views/Timelogs'
import ErrorComponent from '@/views/Error'
import Onboarding from '@/views/Onboarding'
import ProjectBase from '@/views/ProjectBase'
import SignUpInvited from '@/views/SignUpInvited'
import TwoFactorAuth from '@/views/TwoFactorAuth'
import ResetPassword from '@/views/ResetPassword'
import ProjectSetting from '@/views/ProjectSetting'
import DeleteCallback from '@/views/DeleteCallback'
import NotFoundComponent from '@/views/PageNotFound'
import AccountRestoration from '@/views/AccountRestoration'
import GoogleAuthCallback from '@/views/GoogleAuthCallback'
import GoogleAuthRedirect from '@/views/GoogleAuthRedirect'
import AccountVerification from '@/views/AccountVerification'
import OrganizationSettings from '@/views/OrganizationSettings'
import TasksAppBarContent from '@/components/tasks/TasksAppBarContent'
import GoogleCalendarAuthRedirect from '@/views/GoogleCalendarAuthRedirect'
import GoogleCalendarAuthCallback from '@/views/GoogleCalendarAuthCallback'
import TimelogsAppBarContent from '@/components/timelogs/TimelogsAppBarContent'
import TaskDialog from '@/components/task/TaskDialog'

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/signin')
}

export default [
  {
    path: '/',
    name: 'Home',
    beforeEnter: ifAuthenticated,
    components: {
      default: Home
    },
    meta: {
      title: 'Home',
      layout: 'default'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      title: 'profile',
      layout: 'default'
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/signin',
    name: 'SignIn',
    beforeEnter: ifNotAuthenticated,
    meta: {
      layout: 'plain',
      title: 'Sign In'
    },
    component: SignIn
  },
  {
    path: '/account-restoration',
    name: 'AccountRestoration',
    beforeEnter: ifAuthenticated,
    meta: {
      layout: 'plain',
      title: 'Account Restoration'
    },
    component: AccountRestoration
  },
  {
    path: '/google-auth-redirect',
    name: 'GoogleAuthRedirect',
    meta: {
      title: 'Google Auth Redirect',
      layout: 'plain'
    },
    component: GoogleAuthRedirect
  },
  {
    path: '/google-auth-callback',
    name: 'GoogleAuthCallback',
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Google Auth Callback',
      layout: 'plain'
    },
    component: GoogleAuthCallback
  },
  {
    path: '/google-calendar-auth-redirect',
    name: 'GoogleCalendarAuthRedirect',
    meta: {
      title: 'Google Calendar Auth Redirect',
      layout: 'plain'
    },
    component: GoogleCalendarAuthRedirect
  },
  {
    path: '/google-calendar-auth-callback',
    name: 'GoogleCalendarAuthCallback',
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Google Calendar Auth Callback',
      layout: 'plain'
    },
    component: GoogleCalendarAuthCallback
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      layout: 'plain',
      title: 'Reset Password'
    },
    beforeEnter: ifNotAuthenticated,
    component: ResetPassword
  },
  {
    path: '/auth/2fa',
    name: '2FA',
    meta: {
      title: '2Fa',
      layout: 'plain'
    },
    beforeEnter: ifNotAuthenticated,
    component: TwoFactorAuth
  },
  {
    path: '/invitations',
    name: 'Invitations',
    meta: {
      layout: 'plain',
      title: 'Invitations'
    },
    component: SignUpInvited
  },
  {
    path: '/account-verification',
    name: 'AccountVerification',
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: 'Account Verification',
      layout: 'plain'
    },
    component: AccountVerification
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Onboarding',
      layout: 'plain'
    },
    component: Onboarding
  },
  {
    path: '/messages/:mid',
    name: 'Message',
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Messages',
      layout: 'default'
    },
    component: () => import('../views/Messages.vue')
  },
  {
    path: '/channels/:cid',
    name: 'Channel',
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Channels',
      layout: 'default'
    },
    component: () => import('../views/Channels.vue')
  },
  {
    path: '/projects/:pid/timelogs',
    name: 'timelogs',
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Timelogs',
      layout: 'default'
    },
    components: {
      default: Timelogs,
      toolbar: TimelogsAppBarContent
    }
  },
  {
    path: '/projects/:pid/settings',
    name: 'ProjectSettings',
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Project Settings',
      layout: 'default'
    },
    component: ProjectSetting
  },
  {
    path: '/settings',
    name: 'OrganizationSettings',
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Organization Settings',
      layout: 'default'
    },
    component: OrganizationSettings
  },
  {
    path: '/projects/:pid',
    name: 'project',
    beforeEnter: ifAuthenticated,
    components: {
      default: ProjectBase,
      toolbar: TasksAppBarContent
    },
    children: [
      {
        path: ':view',
        name: 'board',
        meta: {
          title: 'Tasks',
          layout: 'default',
          showDialog: false
        },
        beforeEnter: ifAuthenticated,
        component: Boards,
        children: [
          {
            path: 'tasks/:tid',
            name: 'task',
            meta: {
              title: 'board',
              layout: 'default',
              showDialog: true
            },
            beforeEnter: ifAuthenticated,
            component: TaskDialog,
            children: [
              {
                path: 'checklists',
                name: 'checklists',
                meta: {
                  title: 'Checklists',
                  layout: 'default',
                  showDialog: true
                },
                beforeEnter: ifAuthenticated
              },
              {
                path: 'attachments',
                name: 'attachments',
                meta: {
                  title: 'Attachments',
                  layout: 'default',
                  showDialog: true
                },
                beforeEnter: ifAuthenticated
              },
              {
                path: 'comments',
                name: 'comments',
                meta: {
                  title: 'Comments',
                  layout: 'default',
                  showDialog: true
                },
                beforeEnter: ifAuthenticated
              },
              {
                path: 'relations',
                name: 'relations',
                meta: {
                  title: 'Dependencies',
                  layout: 'default',
                  showDialog: true
                },
                beforeEnter: ifAuthenticated
              },
              {
                path: 'tags',
                name: 'tags',
                meta: {
                  title: 'Tags',
                  layout: 'default',
                  showDialog: true
                },
                beforeEnter: ifAuthenticated
              },
              {
                path: 'assignees',
                name: 'assignees',
                meta: {
                  title: 'Assignees',
                  layout: 'default',
                  showDialog: true
                },
                beforeEnter: ifAuthenticated
              },
              {
                path: 'activities',
                name: 'activities',
                meta: {
                  title: 'Activities',
                  layout: 'default',
                  showDialog: true
                },
                beforeEnter: ifAuthenticated
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/error',
    name: 'error',
    meta: {
      title: 'An error occured!',
      layout: 'plain'
    },
    component: ErrorComponent
  },
  {
    path: '/account-deleted',
    name: 'Account deleted',
    meta: {
      title: 'Account Deleted!',
      layout: 'plain'
    },
    component: DeleteCallback
  },
  {
    path: '*',
    name: 'notFound',
    meta: {
      title: 'Not Found',
      layout: 'plain'
    },
    component: NotFoundComponent
  }
]
