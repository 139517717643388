import {
  ADD_TIMELOG,
  SET_TIMELOG,
  SET_TIMELOGS,
  ADD_TIMELOGS,
  DELETE_TIMELOG,
  SET_TIMELOG_VALUE,
  SET_TIMELOGS_GROUP,
  SET_ACTIVE_TIMELOGS,
  SET_TIMELOG_DURATION,
  DELETE_ACTIVE_TIMELOG,
  ADD_TIMELOG_EXPORT_LINK,
  ADD_TIMELOGS_SEARCH_RESULTS,
  SET_TIMELOGS_SEARCH_RESULTS
} from '../mutation-types'
import {
  stopTimelog,
  patchTimelog,
  fetchTimelog,
  createTimelog,
  fetchTimelogs,
  deleteTimelog,
  createTimelogExport
} from '@/api/timelogs'

const state = {
  timelog: null,
  timelogs: null,
  timelogsGroup: null,
  activeTimelogs: null,
  timelogExportLink: null,
  timelogsSearchResults: null
}

const mutations = {
  [SET_TIMELOG](state, timelog) {
    state.timelog = timelog
  },
  [ADD_TIMELOG_EXPORT_LINK](state, timelogExportLink) {
    state.timelogExportLink = timelogExportLink
  },
  [SET_TIMELOGS_GROUP](state, timelogsGroup) {
    state.timelogsGroup = timelogsGroup
  },
  [SET_TIMELOG_VALUE](state, { index, value, key }) {
    state.timelogs.records[index][key] = value
  },
  [SET_TIMELOG_DURATION](state, { index, value }) {
    const timelog = state.timelogs.records[index]
    const oldValue = timelog.duration
    timelog.duration = value
    state.timelogs.total_duration =
      state.timelogs.total_duration - oldValue + value
  },
  [SET_TIMELOGS](state, timelogs) {
    state.timelogs = timelogs
  },
  [SET_ACTIVE_TIMELOGS](state, activeTimelogs) {
    state.activeTimelogs = activeTimelogs
  },
  [ADD_TIMELOG](state, timelog) {
    state.timelogs.records.unshift(timelog)
    state.timelogs.total_duration =
      state.timelogs.total_duration + timelog.duration
  },
  [ADD_TIMELOGS](state, timelogs) {
    state.timelogs.records = [...state.timelogs.records, ...timelogs.records]
    state.timelogs.offset_token = timelogs.offset_token
    state.timelogs.total_duration = timelogs.total_duration
  },
  [SET_TIMELOGS_SEARCH_RESULTS](state, timelogsSearchResults) {
    state.timelogsSearchResults = timelogsSearchResults
  },
  [ADD_TIMELOGS_SEARCH_RESULTS](state, timelogsSearchResults) {
    state.timelogsSearchResults.records = [
      ...state.timelogsSearchResults.records,
      ...timelogsSearchResults.records
    ]
    state.timelogsSearchResults.offset_token =
      timelogsSearchResults.offset_token
    state.timelogsSearchResults.total_duration =
      timelogsSearchResults.total_duration
  },
  [DELETE_TIMELOG](state, index) {
    state.timelogs.records.splice(index, 1)
  },
  [DELETE_ACTIVE_TIMELOG](state, tid) {
    const index = state.activeTimelogs.records.findIndex(
      timelog => timelog.id === tid
    )
    if (index < 0) {
      return
    }
    state.activeTimelogs.records.splice(index, 1)
  }
}

const actions = {
  async getTimelogs({ commit }, { pid, params }) {
    const { data } = await fetchTimelogs(pid, params)
    const { data: timelogs } = data
    commit(SET_TIMELOGS, timelogs)
  },
  async getActiveTimelogs({ commit }, { pid, params }) {
    const { data } = await fetchTimelogs(pid, params)
    const { data: timelogs } = data
    commit(SET_ACTIVE_TIMELOGS, timelogs)
  },
  async addTimelog({ commit }, { pid, data }) {
    const result = await createTimelog(pid, data)
    commit(ADD_TIMELOG, result.data.data)
  },
  async exportTimelogs({ state, commit }, { pid, data }) {
    const result = await createTimelogExport(pid, data)
    commit(ADD_TIMELOG_EXPORT_LINK, result.data.data)
    if (state.timelogExportLink) {
      window.open(state.timelogExportLink.url)
    }
  },
  async addTimelogs({ commit }, { pid, params }) {
    const { data } = await fetchTimelogs(pid, params)
    const { data: timelogs } = data
    commit(ADD_TIMELOGS, timelogs)
  },
  async getTimelog({ commit }, { pid, tid }) {
    const { data } = await fetchTimelog(pid, tid)
    commit(SET_TIMELOG, data.data)
  },
  async getTimelogsSearchResults({ commit }, { pid, params }) {
    const { data } = await fetchTimelogs(pid, params)
    const { data: timelogs } = data
    commit(SET_TIMELOGS_SEARCH_RESULTS, timelogs)
  },
  async addTimelogsSearchResults({ commit }, { pid, params }) {
    const { data } = await fetchTimelogs(pid, params)
    const { data: timelogs } = data
    commit(ADD_TIMELOGS_SEARCH_RESULTS, timelogs)
  },
  async updateTimelog({ state, commit }, { tid, data, key }) {
    const result = await patchTimelog(tid, data)
    const timelog = result.data.data
    const index = state.timelogs.records.findIndex(
      timelog => timelog.id === tid
    )
    if (index < 0) {
      return
    }
    commit(SET_TIMELOG_VALUE, { index, value: timelog[key], key })
    if (key === 'started_at' || key === 'stopped_at') {
      commit(SET_TIMELOG_DURATION, { index, value: timelog.duration })
    }
  },
  async deleteTimelogEntry({ state, commit }, tid) {
    await deleteTimelog(tid)
    const index = state.timelogs.records.findIndex(
      timelog => timelog.id === tid
    )
    if (index < 0) {
      return
    }
    commit(SET_TIMELOG_DURATION, { index, value: 0 })
    commit(DELETE_TIMELOG, index)
  },
  async stopActiveTimelog({ commit }, { pid, tid, data }) {
    await stopTimelog(pid, data)
    commit(DELETE_ACTIVE_TIMELOG, tid)
  },
  setTimelogs({ commit }, timelogs) {
    commit(SET_TIMELOGS, timelogs)
  },
  setTimelogsSearchResults({ commit }, timelogs) {
    commit(SET_TIMELOGS_SEARCH_RESULTS, timelogs)
  },
  setTimelogsGroup({ commit }, timelogsGroup) {
    commit(SET_TIMELOGS_GROUP, timelogsGroup)
  }
}

const getters = {
  timelog: state => state.timelog,
  timelogs: state => state.timelogs,
  timelogsGroup: state => state.timelogsGroup,
  activeTimelogs: state => state.activeTimelogs,
  timelogExportLink: state => state.timelogExportLink,
  timelogsSearchResults: state => state.timelogsSearchResults
}

export default {
  state,
  actions,
  getters,
  mutations
}
