<template>
  <v-menu left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon small color="info" v-bind="attrs" v-on="on">
        <base-icon width="20" height="20" name="ellipsis-v" color="secondary.base" />
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        :key="index"
        @click="item.action"
        v-for="(item, index) in items"
      >
        <v-list-item-icon class="me-2">
          <base-icon :name="item.icon" :color="item.iconColor" />
        </v-list-item-icon>
        <v-list-item-title
          :class="`${item.color}--text`"
          v-text="$t(`common.${item.title}`)"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  props: {
    type: {
      type: String,
      default: 'direct'
    }
  },
  data() {
    return {
      items: [
        {
          title: 'edit',
          color: 'info',
          icon: 'pen',
          iconColor: 'info.base',
          action: () => {
            this.$emit('edit-comment-clicked')
          }
        },
        {
          title: 'delete',
          color: 'error',
          icon: 'trash-alt',
          iconColor: 'error',
          action: () => {
            this.$emit('delete-comment-clicked')
          }
        }
      ]
    }
  },
  components: {
    BaseIcon
  }
}
</script>
