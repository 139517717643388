<template>
  <v-card-actions class="cursor pointer pb-0 pt-1" @click="$emit('visibility-toggled')">
    <div class="text-button d-flex ms-2">
      <span>{{ title }}</span>
      <span class="ms-1">{{ getOptionsCounter() }}</span>
    </div>
    <v-spacer />
    <v-btn icon v-if="items && items.length > 0">
      <base-icon
        color="secondary.darken2"
        :name="visible ? 'angle-up' : 'angle-down'"
      />
    </v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="info" v-bind="attrs" v-on="on">
          <base-icon name="ellipsis-v" color="secondary.darken2" />
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="$emit('edit-clicked')" v-if="editable">
          <v-list-item-title class="info--text body-1">
            {{ $t('common.edit') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="$emit('items-visibility-toggled')">
          <v-list-item-title class="info--text body-1">
            <template v-if="!completedVisible">{{
              $t('page.task_checklist.menu.show_completed_items')
            }}</template>
            <template v-else>{{
              $t('page.task_checklist.menu.hide_completed_items')
            }}</template>
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="$emit('delete-clicked')" v-if="deletable">
          <v-list-item-title class="error--text body-1">
            {{ $t('common.delete') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-card-actions>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    completedVisible: {
      type: Boolean,
      default: false
    },
    deletable: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getOptionsCounter() {
      const { items } = this
      let length = null

      if (items) length = items.length
      else return

      const doneOptions = items.filter((item) => item.completed).length
      if (doneOptions) {
        return `(${doneOptions}/${length})`
      }
      return `(${length})`
    }
  },
  components: {
    BaseIcon
  }
}
</script>
