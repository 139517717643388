import request from '@/api/axios'

export const fetchUser = (params = {}) =>
  request({
    url: '/me',
    method: 'get',
    params
  })

export const fetchSecuritySettings = () =>
  request({
    url: '/me/settings/security',
    method: 'get'
  })

export const fetchNotificationSettings = () =>
  request({
    url: '/me/settings/notifications',
    method: 'get'
  })

export const patchNotificationSettings = (data) =>
  request({
    url: '/me/settings/notifications',
    method: 'patch',
    data
  })

export const fetchSessions = () =>
  request({
    url: '/me/sessions',
    method: 'get'
  })

export const deleteSessions = data =>
  request({
    url: '/me/sessions',
    method: 'delete',
    data
  })

export const fetchTwoFactorAuthBackupCodes = () =>
  request({
    url: '/me/2fa/backup-codes',
    method: 'get'
  })

export const createTwoFactorAuthBackupCodes = () =>
  request({
    url: '/me/2fa/backup-codes',
    method: 'post'
  })

export const createTwoFactorAuthBackupCodesExport = () =>
  request({
    url: '/me/2fa/backup-codes/export',
    method: 'post'
  })

export const createTwoFactorAuthSetup = (data = {}) =>
  request({
    url: '/me/2fa/setup',
    method: 'post',
    data
  })

export const createTwoFactorAuthEnable = data =>
  request({
    url: '/me/2fa/enable',
    method: 'post',
    data
  })

export const createTwoFactorAuthDisable = () =>
  request({
    url: '/me/2fa/disable',
    method: 'post'
  })

export const patchUser = data =>
  request({
    url: '/me',
    method: 'patch',
    data
  })

export const editUserAvatar = data =>
  request({
    url: '/me/avatar',
    method: 'put',
    data
  })

export const editUserPassword = data =>
  request({
    url: '/me/password',
    method: 'put',
    data
  })

export const editUserEmail = data =>
  request({
    url: '/me/email',
    method: 'put',
    data
  })

export const createUserEmailVerification = data =>
  request({
    url: '/me/email/verify',
    method: 'post',
    data
  })

export const createUserEmailVerificationResend = data =>
  request({
    url: '/me/email/resend',
    method: 'post',
    data
  })

export const createAccountRestoration = () =>
  request({
    url: '/me/restore',
    method: 'post'
  })

export const deleteUserAccount = data =>
  request({
    url: '/me',
    method: 'delete',
    data
  })

export const deleteUserAvatar = () =>
  request({
    url: '/me/avatar',
    method: 'delete'
  })
