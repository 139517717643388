import Vue from 'vue'
import store from '@/store'
import i18next from 'i18next'
import PN from 'persian-number'
import dompurify from 'dompurify'
import moment from 'jalali-moment'
import HttpApi from 'i18next-http-backend'
import VueI18Next from '@panter/vue-i18next'
import { isCurrentYear } from '@/utils/date'
Vue.use(VueI18Next)

i18next.use(HttpApi).init({
  lng: 'en',
  debug: true,
  fallbackLng: 'en',
  saveMissing: true,
  useSuspense: false,
  load: 'languageOnly',
  saveMissingTo: 'all',
  whitelist: ['en', 'fa', 'de'],
  backend: {
    addPath: null,
    loadPath: 'https://mock.taskulu.com/locales/{{lng}}.json'
  },
  interpolation: {
    format: (value, format, lng) => {
      const sanitizer = dompurify.sanitize
      if (format === 'emphasis') return `<strong>${sanitizer(value)}</strong>`
      if (format === 'localize') {
        if (lng === 'fa') {
          return PN.convertEnToPe(value)
        }
        return value
      }
      if (value instanceof Date) {
        if (moment(value, format, true).isValid()) {
          return moment(value)
            .locale(lng)
            .format(format)
        }
        const plainFormat = `date.formats.iso.plain.${format}`
        const currentYearPlainFormat = `date.formats.iso.plain.${format}_current_year`
        if (i18next.exists(plainFormat)) {
          if (isCurrentYear(value) && i18next.exists(currentYearPlainFormat)) {
            return moment(value)
              .locale(lng)
              .format(i18next.t(currentYearPlainFormat))
          }
          return moment(value)
            .locale(lng)
            .format(i18next.t(plainFormat))
        }
        return moment(value)
          .locale(lng)
          .format('YYYY/M/D')
      }
      return value
    }
  }
})
i18next.on('initialized', () => {
  store.dispatch('setTranslationInitialized', true)
})

export default new VueI18Next(i18next)
