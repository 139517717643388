<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    content-class="small-level-one"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.color_labels.title')"
  >
    <v-card
      tile
      flat
      height="100%"
      class="white d-flex info--text"
      v-if="!fetching && projectLabels"
    >
      <v-card-text class="flex-grow-1 d-flex flex-column pt-0">
        <section class="flex-grow-1 pt-2 pb-0 secondary lighten-5 rounded-sm">
          <v-container fluid class="px-4">
            <settings-dialog-list-color-labels-label
              :label="label"
              :key="label.id"
              :loading="loading"
              :editing="editing"
              @edit-clicked="save"
              :current-label="currentLabel"
              v-for="label in enabledLabels"
              @toggle-clicked="confirmToggle"
            />
          </v-container>
        </section>
        <h3
          v-text="$t('common.disabled')"
          v-show="disabledLabels.length > 0"
          class="body-1 px-4 my-2 secondary--text text--darken-2"
        />
        <section
          v-show="disabledLabels.length > 0"
          class="flex-grow-1 pt-2 pb-0 secondary lighten-5 rounded-sm"
        >
          <v-container fluid class="px-4">
            <settings-dialog-list-color-labels-label
              :label="label"
              :key="label.id"
              :loading="loading"
              :editing="editing"
              @edit-clicked="save"
              @toggle-clicked="toggle"
              :current-label="currentLabel"
              v-for="label in disabledLabels"
            />
          </v-container>
        </section>
      </v-card-text>
      <base-confirm-dialog
        :loading="loading"
        ok-color="error base"
        :dialog.sync="confirmationDialog"
        cancel-color="secondary darken-2"
        :ok-text="$t('common.disable')"
        @ok-clicked="toggle(labelPayload)"
        :cancel-text="$t('common.cancel')"
        :title="
          $t('page.project_settings.color_labels.disable_confirmation.title')
        "
      >
        <section
          v-html="
            $t(
              'page.project_settings.color_labels.disable_confirmation.content',
              {
                title: labelPayload ? labelPayload.title : '',
                interpolation: { escapeValue: false }
              }
            )
          "
        />
      </base-confirm-dialog>
    </v-card>
    <base-loading v-else />
  </base-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseLoading from '@/components/BaseLoading'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'
import SettingsDialogListColorLabelsLabel from './SettingsDialogListColorLabelsLabel2'
export default {
  props: {
    project: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaseDialog,
    BaseLoading,
    BaseConfirmDialog,
    SettingsDialogListColorLabelsLabel
  },
  data() {
    return {
      loading: false,
      editing: false,
      fetching: false,
      currentLabel: '',
      labelPayload: null,
      confirmationDialog: false
    }
  },
  computed: {
    ...mapGetters(['projectLabels']),
    labels() {
      return Object.values(this.projectLabels)
    },
    enabledLabels() {
      return this.labels.filter((label) => label.enabled)
    },
    disabledLabels() {
      return this.labels.filter((label) => !label.enabled)
    }
  },
  methods: {
    ...mapActions([
      'getProjectLabels',
      'updateProjectLabel',
      'toggleProjectLabel'
    ]),
    async save(payload) {
      this.editing = true
      this.currentLabel = payload.lid
      try {
        await this.updateProjectLabel(payload)
      } catch (error) {}
      this.editing = false
    },
    async toggle(payload) {
      this.loading = true
      this.confirmationDialog = false
      this.currentLabel = payload.lid
      try {
        await this.toggleProjectLabel(payload)
      } catch (error) {}
      this.loading = false
    },
    async getData() {
      this.fetching = true
      try {
        await this.getProjectLabels(this.project.id)
      } catch (error) {}
      this.fetching = false
    },
    confirmToggle(payload) {
      this.labelPayload = payload
      this.confirmationDialog = true
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
