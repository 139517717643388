<template>
  <v-list v-if="organizationPermissions">
    <v-list-item
      :key="item.title"
      v-for="item in items"
      @click="openDialog(item)"
    >
      <v-list-item-action class="me-4">
        <base-icon
          width="24"
          height="24"
          color="primary"
          :name="item.icon"
          className="d-flex align-center"
        />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title
          class="body-2 info--text"
          v-text="
            $t(`page.organization_settings.permissions.${item.title}.title`)
          "
        />
      </v-list-item-content>
      <v-list-item-icon>
        <base-angle-icon
          width="24"
          height="24"
          fill="secondary.base"
          class="flip-horizontal"
        />
      </v-list-item-icon>
    </v-list-item>
    <organization-settings-list-permissions-settings-dialog
      v-if="item"
      :item="item"
      :dialog.sync="dialog"
      @permission-changed="updatePermission"
    />
  </v-list>
  <base-loading v-else />
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapGetters, mapActions } from 'vuex'
import BaseLoading from '@/components/BaseLoading'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import OrganizationSettingsListPermissionsSettingsDialog from '@/components/organization/OrganizationSettingsListPermissionsSettingsDialog'
export default {
  components: {
    BaseIcon,
    BaseLoading,
    BaseAngleIcon,
    OrganizationSettingsListPermissionsSettingsDialog
  },
  computed: {
    ...mapGetters(['organizationPermissions'])
  },
  data() {
    return {
      item: null,
      dialog: true,
      items: [
        {
          title: 'create_project',
          icon: 'plus-circle'
        },
        {
          title: 'close_project',
          icon: 'times'
        },
        {
          title: 'delete_project',
          icon: 'trash-alt'
        },
        {
          title: 'manage_members',
          icon: 'users-alt'
        },
        {
          title: 'manage_billing',
          icon: 'invoice'
        },
        {
          title: 'edit_info',
          icon: 'info-circle'
        },
        {
          title: 'join_project',
          icon: 'layer-group'
        }
      ]
    }
  },
  methods: {
    ...mapActions([
      'getOrganizationPermissions',
      'updateOrganizationPermission'
    ]),
    getData() {
      this.getOrganizationPermissions()
    },
    openDialog(item) {
      const { organizationPermissions } = this
      if (!organizationPermissions) {
        return
      }
      this.item = organizationPermissions[item.title]
      this.dialog = true
    },
    updatePermission({ pid, data }) {
      this.updateOrganizationPermission({
        pid,
        data
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
