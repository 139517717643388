<template>
  <v-tabs
    height="42"
    v-model="tab"
    @change="change"
    class="subset-tabs"
    :show-arrows="false"
  >
    <v-tab class="text-h4" :key="subsetItem" v-for="subsetItem in subsets">
      <strong
        class="text-button"
        :class="
          subset === subsetItem
            ? 'primary--text'
            : 'info--text'
        "
      >
        {{ $t(`page.my_tasks.subsets.${subsetItem}`) }}
        <v-chip
          x-small
          outlined
          color="primary"
          class="px-1 ms-2"
          v-if="count[subsetItem] > 0"
          v-text="getLocalizedNumber(count[subsetItem])"
        />
      </strong>
    </v-tab>
  </v-tabs>
</template>

<script>
import { subsets } from '@/constants/my-tasks'
export default {
  props: {
    subset: {
      type: String,
      required: true
    },
    count: {
      require: true
    }
  },
  data() {
    return {
      tab: 0,
      subsets
    }
  },
  methods: {
    change(tab) {
      this.$emit('update:subset', subsets[tab])
    }
  }
}
</script>
