import {
  SET_ORGANIZATION,
  SET_ORGANIZATION_VALUE,
  SET_ORGANIZATION_ABILITIES,
  SET_ORGANIZATION_SUBDOMAIN,
  DELETE_ORGANIZATION_PROJECT,
  SET_ORGANIZATION_PERMISSION,
  ADD_ORGANIZATION_MEMBERSHIPS,
  SET_ORGANIZATION_PERMISSIONS,
  SET_ORGANIZATION_PROJECT_VALUE,
  DELETE_ORGANIZATION_MEMBERSHIPS,
  SET_ORGANIZATION_MEMBERSHIP_USER
} from '../mutation-types'
import {
  patchOrganization,
  fetchOrganization,
  createOrganization,
  editOrganizationLogo,
  deleteOrganizationLogo,
  fetchOrganizationByUrl,
  deleteOrganizationMembers,
  createOrganizationMembers,
  fetchOrganizationAbilities,
  patchOrganizationPermission,
  fetchOrganizationPermissions
} from '@/api/organizations'

import {
  updateObject,
  getFilteredUsers,
  convertArrayToObject,
  getOrganizationSubdomain
} from '@/utils/helpers'

const state = {
  organization: null,
  organizationAbilities: null,
  organizationPermissions: null,
  organizationSubdomain: getOrganizationSubdomain()
}

const mutations = {
  [SET_ORGANIZATION_SUBDOMAIN](state, organizationSubdomain) {
    state.organizationSubdomain = organizationSubdomain
  },
  [SET_ORGANIZATION_ABILITIES](state, organizationAbilities) {
    state.organizationAbilities = organizationAbilities
  },
  [SET_ORGANIZATION_PERMISSION](state, organizatioPermission) {
    state.organizationPermissions[
      organizatioPermission.id
    ] = organizatioPermission
  },
  [SET_ORGANIZATION_PERMISSIONS](state, organizationPermissions) {
    state.organizationPermissions = convertArrayToObject(
      organizationPermissions
    )
  },
  [ADD_ORGANIZATION_MEMBERSHIPS](state, memberships) {
    state.organization.memberships = [
      ...state.organization.memberships,
      ...memberships
    ]
  },
  [DELETE_ORGANIZATION_MEMBERSHIPS](state, mids) {
    for (let i = 0; i < mids.length; i++) {
      const mid = mids[i]
      const index = state.organization.memberships.findIndex(
        member => member.user.id === mid
      )
      if (index > -1) {
        state.organization.memberships.splice(index, 1)
      }
    }
  },
  [SET_ORGANIZATION](state, organization) {
    state.organization = organization
  },
  [SET_ORGANIZATION_VALUE](state, { key, value }) {
    state.organization[key] = value
  },
  [SET_ORGANIZATION_PROJECT_VALUE](state, { pid, keys, values }) {
    const project = state.organization.projects.find(
      project => project.id === pid
    )
    if (!project) {
      return
    }
    for (let i = 0; i < keys.length; i++) {
      updateObject(project, values[i], keys[i])
    }
  },
  [DELETE_ORGANIZATION_PROJECT](state, pid) {
    const index = state.organization.projects.findIndex(
      project => project.id === pid
    )
    if (!index < 0) {
      return
    }
    state.organization.projects.splice(index, 1)
  },
  [SET_ORGANIZATION_MEMBERSHIP_USER](state, user) {
    const index = state.organization.memberships.findIndex(
      member => member.user.id === user.id
    )
    if (index > -1) {
      state.organization.memberships[index].user = user
    }
  }
}

const actions = {
  setOrganzation({ commit }, data) {
    const { subdomain } = data.data
    commit(SET_ORGANIZATION, data.data)
    commit(SET_ORGANIZATION_SUBDOMAIN, subdomain)
  },
  async addOrganiztion({ commit, dispatch }, orgnizationData) {
    // commit(SET_ORGANIZATION, null)
    await createOrganization(orgnizationData)
    // dispatch('setOrganzation', data)
  },
  async getOrganization({ dispatch }) {
    const { data } = await fetchOrganization({
      include: ['projects', 'channels', 'direct_chats', 'memberships']
    })
    dispatch('setOrganzation', data)
  },
  async getOrganizationAbilities({ commit }) {
    const { data } = await fetchOrganizationAbilities()
    commit(SET_ORGANIZATION_ABILITIES, data.data)
  },
  async updateOrganization({ commit }, { data, key }) {
    const result = await patchOrganization(data)
    commit(SET_ORGANIZATION_VALUE, {
      key,
      value: result.data.data[key]
    })
  },
  async replaceOrganizationLogo({ commit }, data) {
    const result = await editOrganizationLogo(data)
    commit(SET_ORGANIZATION_VALUE, {
      key: 'logo',
      value: result.data.data.logo
    })
  },
  async removeOrganizationLogo({ commit }) {
    await deleteOrganizationLogo()
    commit(SET_ORGANIZATION_VALUE, {
      key: 'logo',
      value: null
    })
  },
  async getOrganizationByUrl({ commit, dispatch }, { params, errorHandle }) {
    commit(SET_ORGANIZATION, null)
    const { data } = await fetchOrganizationByUrl(params, errorHandle)
    dispatch('setOrganzation', data)
  },
  async addOrganizationMembers({ commit }, data) {
    const result = await createOrganizationMembers(data)
    commit(ADD_ORGANIZATION_MEMBERSHIPS, result.data.data)
  },
  async deleteOrganizationMemberships({ commit }, data) {
    await deleteOrganizationMembers(data)
    commit(DELETE_ORGANIZATION_MEMBERSHIPS, data.user_ids)
  },
  async getOrganizationPermissions({ commit }) {
    const { data } = await fetchOrganizationPermissions()
    commit(SET_ORGANIZATION_PERMISSIONS, data.data)
  },
  async updateOrganizationPermission({ commit }, { pid, data }) {
    const result = await patchOrganizationPermission(pid, data)
    commit(SET_ORGANIZATION_PERMISSION, result.data.data)
  }
}

const getters = {
  organization: state => state.organization,
  organizationAbilities: state => state.organizationAbilities,
  organizationSubdomain: state => state.organizationSubdomain,
  organizationPermissions: state => state.organizationPermissions,
  organizationUsers: state =>
    getFilteredUsers(
      state.organization ? state.organization.memberships : null
    ),
  organizationPendingUsers: state =>
    getFilteredUsers(
      state.organization ? state.organization.memberships : null,
      'pending'
    ),
  organizationApprovedUsers: state =>
    getFilteredUsers(
      state.organization ? state.organization.memberships : null,
      'approved'
    )
}

export default {
  mutations,
  actions,
  getters,
  state
}
