<template>
  <base-list :items="items" :mandatory="true" :value.sync="value" />
</template>

<script>
import BaseList from '@/components/BaseList'
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['notificationSettings']),
    items() {
      return this.options.map((option) => {
        return {
          title: this.$i18n.t(
            `page.profile_setting.notifications.email_notifications.options.${option}`
          ),
          id: option
        }
      })
    },
    form() {
      const { value } = this
      return {
        email_interval: value === 'disabled' ? '-1' : value
      }
    }
  },
  data() {
    return {
      value: null,
      options: ['disabled', '1s', '1h', '3h', '1d']
    }
  },
  methods: {
    ...mapActions(['updateUserNotificationSettings']),
    getValue() {
      const { notificationSettings } = this
      const { email_interval: interval } = notificationSettings
      return interval === '-1' ? 'disabled' : interval
    },
    handleValueChange() {
      this.updateUserNotificationSettings(this.form)
    }
  },
  mounted() {
    this.value = this.getValue()
  },
  watch: {
    form() {
      if (
        this.form.email_interval === this.notificationSettings.email_interval
      ) {
        return
      }
      this.handleValueChange()
    }
  },
  components: {
    BaseList
  }
}
</script>
