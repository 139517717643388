<template>
  <base-loading />
</template>

<script>
import BaseLoading from '@/components/BaseLoading'
export default {
  components: { BaseLoading },
  computed: {
    code() {
      const { query } = this.$route
      return query.code || null
    },
    state() {
      const { query } = this.$route
      return query.state || null
    },
    callbackUri() {
      const path = 'google-auth-callback'
      const { protocol, hostname, port } = location
      const host =
        process.env.NODE_ENV === 'development' ? 'taskulu.test' : hostname
      return `${protocol}//${this.state}.${host}:${port}/${path}?code=${this.code}&state=${this.state}`
    }
  },
  mounted() {
    if (!this.code || !this.state) {
      return
    }
    window.location = this.callbackUri
  }
}
</script>
