<template>
  <div class="results" :class="{ opened: visible }">
    <div
      @click="handleClick"
      class="handle d-flex align-center justify-space-between px-6"
      :class="{ curved: !visible, 'cursor pointer': hasResults && !searching }"
    >
      <template v-if="searching">
        <v-progress-circular
          width="2"
          size="16"
          indeterminate
          color="primary"
        />
      </template>
      <template v-else>
        <span
          class="text-button info--text"
          v-if="hasResults"
        >
          {{
            $t('common.result_with_count', {
              count: resultCount
            })
          }}
        </span>
        <span class="text-button secondary--text text--darken-2" v-else>
          {{ $t('search_and_filter.results.no_results') }}
        </span>
      </template>
      <section v-if="hasResults">
        <v-btn
          small
          outlined
          depressed
          v-if="visible"
          color="primary"
          @click.stop="$emit('export-clicked')"
        >
          {{ $t('page.timelogs.export_results') }}
        </v-btn>
        <v-btn icon>
          <base-icon
            color="primary"
            :name="`angle-${visible ? 'down' : 'up'}`"
          />
        </v-btn>
      </section>
    </div>
    <v-divider class="secondary lighten-2" dark v-if="visible" />
    <div class="content" v-show="visible">
      <div class="pa-3" v-if="hasResults">
        <slot />
        <base-loading-more v-if="loadingMore" />
        <div v-intersect="onIntersect" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import BaseLoadingMore from '@/components/BaseLoadingMore'
export default {
  name: 'SearchDialogResults',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    searching: {
      type: Boolean,
      required: true
    },
    loadingMore: {
      type: Boolean,
      required: true
    },
    hasResults: {
      type: Boolean,
      required: true
    },
    resultCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    handleClick() {
      if (!this.hasResults || this.searching) {
        return
      }
      this.visible = !this.visible
    },
    onIntersect(entries) {
      this.$emit('intersect-reached', entries)
    }
  },
  components: {
    BaseIcon,
    BaseLoadingMore
  }
}
</script>
