import request from '@/api/axios'

export const fetchNotifications = params =>
  request({
    url: '/orgs/#oid/notifications',
    method: 'get',
    params
  })

export const fetchUnreadNotificationCount = () =>
  request({
    url: '/orgs/#oid/notifications/count/unread',
    method: 'get'
  })

export const createAllNotificationsReadStatus = () =>
  request({
    url: '/orgs/#oid/notifications/read',
    method: 'post'
  })

export const createNotificationReadStatus = (nid) =>
  request({
    url: `/orgs/#oid/notifications/${nid}/read`,
    method: 'post'
  })
