<template>
  <v-container fluid class="px-3 py-4 white" v-if="project">
    <v-row class="align-center">
      <v-col class="text-h4" cols="8" v-text="project.title" />
      <v-col class="text-h6 text-end" cols="4" v-text="totalTime" />
    </v-row>
    <v-row class="align-center">
      <v-col class="pt-1 pb-4" cols="7">
        <v-select
          dense
          outlined
          depressed
          hide-details
          :items="groups"
          style="width: 153px"
          :value="timelogsGroup"
          @change="setTimelogsGroup($event)"
          :label="$t('form.labels.group_by')"
        >
          <template v-slot:selection="{ item }">
            <base-icon
              name="t-circle-dots"
              className="d-flex align-center"
            /><span
              class="ms-1"
              v-text="$t(`common.${item.text}`, { count: 0 })"
            />
          </template>
          <template v-slot:item="{ item }">
            {{ $t(`common.${item.text}`, { count: 0 }) }}
          </template>
        </v-select>
      </v-col>
      <v-col class="pt-1 pb-4 d-flex justify-end" cols="5">
        <v-btn
          outlined
          depressed
          class="me-2"
          color="secondary lighten-2"
          @click="$emit('add-clicked')"
          v-if="!isMobile && createTimelogVisible"
        >
          <base-icon
            name="plus"
            color="primary"
            className="d-flex align-center me-1"
          />
          <span
            class="secondary--text text--darken-2"
            v-text="$t('common.add')"
          />
        </v-btn>
        <v-btn
          outlined
          depressed
          color="secondary lighten-2"
          @click="$emit('export-clicked')"
        >
          <base-icon
            name="export"
            color="primary"
            className="d-flex align-center me-1"
          />
          <span
            v-text="$t('page.timelogs.export')"
            class="secondary--text text--darken-2"
          />
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import { getFormattedTime } from '@/utils/date'
export default {
  props: {
    createTimelogVisible: {
      required: true
    }
  },
  components: {
    BaseIcon
  },
  computed: {
    ...mapGetters(['project', 'timelogs', 'isMobile', 'timelogsGroup']),
    totalTime() {
      if (!this.timelogs) {
        return ''
      }
      return getFormattedTime(this.timelogs.total_duration)
    }
  },
  methods: {
    ...mapActions(['setTimelogsGroup'])
  },
  data() {
    return {
      groups: [
        {
          text: 'none',
          value: null
        },
        {
          text: 'user',
          value: 'users'
        },
        {
          text: 'task',
          value: 'tasks'
        }
      ]
    }
  }
}
</script>
