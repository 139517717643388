import request from '@/api/axios'

export const createAttachment = (pid, data) =>
  request({
    url: '/orgs/#oid/attachments',
    method: 'post',
    data
  })

export const deleteAttachment = (aid) =>
  request({
    url: `/orgs/#oid/attachments/${aid}`,
    method: 'delete'
  })
