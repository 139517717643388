<template>
  <div class="title">
    <h4 class="body-2 info--text">
      {{ title }}
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
