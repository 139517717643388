<template>
  <centered-layout>
    <template v-slot:appbar>
      <v-app-bar-nav-icon @click="$router.push({ path: '/signin' })">
        <base-angle-icon name="t-arrow" width="16" height="16" />
      </v-app-bar-nav-icon>
      <v-toolbar-title
        class="pa-0 flex-grow-1 d-flex justify-center"
        :style="`margin-${$vuetify.rtl ? 'right' : 'left'}:-38px`"
      >
        <base-logo />
      </v-toolbar-title>
    </template>
    <sessions-account-verification-form
      :handle="handle"
      :loading="loading"
      :resend-loading="resendLoading"
      @verify-clicked="handleAccountVerification"
      @resend-clicked="handleResendVerificationEmail"
      :verification-email-cooldown="verificationEmailCooldown"
    />
  </centered-layout>
</template>

<script>
import BaseLogo from '@/components/BaseLogo'
import { mapActions, mapGetters } from 'vuex'
import CenteredLayout from '@/layouts/CenteredLayout'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import SessionsAccountVerificationForm from '@/components/sessions/SessionsAccountVerificationForm'
export default {
  data() {
    return {
      handle: '',
      token: null,
      loading: false,
      resendLoading: false
    }
  },
  computed: {
    ...mapGetters(['verificationEmailCooldown', 'organizationSubdomain'])
  },
  methods: {
    ...mapActions([
      'verifyEmail',
      'displayResponseError',
      'resendVerificationEmailForRegisteredUser'
    ]),
    toggleResendLoading() {
      this.resendLoading = !this.resendLoading
    },
    toggleLoading() {
      this.loading = !this.loading
    },
    async handleAccountVerification(code) {
      this.toggleLoading()
      await this.verifyEmail({
        errorCallback: this.displayResponseError,
        doneCallback: this.toggleLoading,
        data: { token: this.token, code },
        url: '/users/confirmation/verify'
      })
    },
    async handleResendVerificationEmail() {
      this.toggleResendLoading()
      try {
        await this.resendVerificationEmailForRegisteredUser({
          url: '/users/confirmation/resend',
          data: { token: this.token },
          doneCallback: this.toggleResendLoading
        })
      } catch (error) {}
    }
  },
  mounted() {
    const { token, handle } = this.$route.query
    if (token && handle) {
      this.token = token
      this.handle = handle
    }
  },
  components: {
    BaseLogo,
    BaseAngleIcon,
    CenteredLayout,
    SessionsAccountVerificationForm
  }
}
</script>
