<template>
  <v-tabs
    grow
    :value="tab"
    @change="$emit('update:tab', $event)"
  >
    <v-tab class="text-h4" :key="index" v-for="(item, index) in items">
      <strong
        class="text-button"
        v-text="$t(`page.profile_setting.security.${item}.title`)"
        :class="
          tab === index ? 'primary--text' : 'info--text'
        "
      />
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: {
    tab: {
      required: true
    }
  },
  data() {
    return {
      items: ['authentication', 'active_sessions']
    }
  }
}
</script>
