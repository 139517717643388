<template>
  <v-list-item :value="item.id" class="attachment px-2">
    <v-badge
      avatar
      bottom
      left
      overlap
      bordered
      color="white"
      offset-x="15"
      offset-y="15"
      :value="item.source_type !== 'Local'"
    >
      <template v-slot:badge>
        <v-avatar>
          <base-icon
            width="13"
            height="13"
            :name="
              item.source_type === 'Dropbox' ? 't-dropbox' : 't-google-drive'
            "
          />
        </v-avatar>
      </template>
      <v-hover :disabled="isPreview">
        <template v-slot:default="{ hover }">
          <v-list-item-avatar
            tile
            size="48"
            class="ma-0 me-2"
            color="secondary lighten-2"
          >
            <v-img :src="item.thumbnail.small" v-if="item.thumbnail" />
            <div
              class="icon d-flex align-center justify-center fill-width"
              v-else
            >
              <unicon name="file" :width="30" :height="30" :fill="iconColor" />
              <div
                class="text"
                :style="`font-size:${fontSize}rem;color:${iconColor}`"
              >
                {{ extention.toUpperCase() }}
              </div>
            </div>
            <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                color="secondary"
                :style="{ cursor: 'pointer' }"
                :ripple="true"
              >
                <v-card
                  class="d-flex transparent justify-center align-center"
                  flat
                  min-width="50"
                  min-height="50"
                  @click="$refs.lightbox.handlePreviewURL()"
                >
                  <unicon
                    :name="previewIcon"
                    :width="25"
                    :height="25"
                    fill="white"
                  />
                </v-card>
              </v-overlay>
            </v-fade-transition>
            <task-dialog-files-list-item-uploaded-preview
              ref="lightbox"
              :dialog.sync="previewOverlay"
              :item="item"
              @go-back-clicked="goBack"
            />
          </v-list-item-avatar>
        </template>
      </v-hover>
    </v-badge>
    <slot />

    <task-dialog-files-list-item-actions
      :deletable="deletable"
      :preview-link="item.view_url"
      :downloadable="item.download_url !== null"
      @delete-clicked="$emit('delete-clicked', $event)"
      @download-clicked="$emit('download-clicked', $event)"
    />
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import TaskDialogFilesListItemActions from '@/components/task/TaskDialogFilesListItemActions'
import TaskDialogFilesListItemUploadedPreview from '@/components/task/TaskDialogFilesListItemUploadedPreview'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    extention: {
      type: String,
      required: true
    },
    fontSize: {
      type: Number,
      required: true
    },
    iconColor: {
      type: String,
      required: true
    },
    priority: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      previewOverlay: false,
      windowSize: {
        x: null,
        y: null
      }
    }
  },
  computed: {
    ...mapGetters(['abilitiesPriorityMap']),
    deletable() {
      return this.priority >= this.abilitiesPriorityMap.Attachment.delete
    },
    isPreview() {
      if (
        this.item?.mime_type.includes('image') ||
        this.item?.mime_type.includes('video/mp4') ||
        this.item?.mime_type.includes('video/quicktime') ||
        (this.item?.mime_type.includes('video/x-matroska') && navigator.userAgent.toLowerCase().includes('chrome')) ||
        this.item?.mime_type.includes('audio/wav') ||
        this.item?.mime_type.includes('audio/mp3') ||
        (this.item?.mime_type.includes('audio/ogg') && !navigator.userAgent.toLowerCase().includes('safari'))
      ) {
        return false
      }
      return true
    },
    previewIcon() {
      if (this.item?.mime_type.includes('image')) return 'eye'
      else if (this.item?.mime_type.includes('video')) return 'play'
      else if (this.item?.mime_type.includes('audio')) return 'play'
      else return ''
    }
  },
  methods: {
    goBack() {
      this.previewOverlay = false
    }
  },
  components: {
    TaskDialogFilesListItemActions,
    TaskDialogFilesListItemUploadedPreview,
    BaseIcon
  }
}
</script>
