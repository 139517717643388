<template>
  <fragment>
    <v-menu
      bottom
      v-if="menuVisible"
      :right="$vuetify.rtl"
      :left="!$vuetify.rtl"
      transition="slide-x-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small color="secondary.darken2" v-bind="attrs" v-on="on">
          <base-icon name="ellipsis-v" color="secondary.darken2" />
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          @click="startOrStopTime"
          v-if="itemVisibilities.create_timelog"
        >
          <v-list-item-icon class="me-1">
            <base-icon
              :color="
                task.timelogs_duration.active ? 'primary' : 'secondary.darken2'
              "
              :name="task.timelogs_duration.active ? 'square' : 'stopwatch'"
              className="d-flex align-center"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="body-1"
              :class="
                task.timelogs_duration.active
                  ? 'primary--text'
                  : 'secondary--text text--darken-2'
              "
            >
              {{
                task.timelogs_duration.active
                  ? $t('common.stop_time')
                  : $t('common.start_time')
              }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action-text
            class="body-1 primary--text ms-1"
            v-if="task.timelogs_duration.active"
          >
            <base-stopwatch :starting-seconds="task.timelogs_duration.active" />
          </v-list-item-action-text>
        </v-list-item>
        <v-list-item
          v-if="itemVisibilities.watch"
          @click="
            updateTaskWithAlias({
              tid: task.id,
              keys: ['watched'],
              values: [!task.watched],
              path: task.watched ? 'unwatch' : 'watch'
            })
          "
        >
          <v-list-item-icon class="me-1">
            <base-icon
              name="eye"
              className="d-flex align-center"
              :color="task.watched ? 'primary' : 'secondary.darken2'"
            />
          </v-list-item-icon>
          <v-list-item-title
            class="body-1"
            :class="
              task.watched ? 'primary--text' : 'secondary--text text--darken-2'
            "
          >
            {{
              task.watched
                ? $t('common.watching_task')
                : $t('common.watch_task')
            }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="itemVisibilities.copy"
          @click="$refs.taskCopyDialog.open()"
        >
          <v-list-item-icon class="me-1">
            <base-icon
              name="copy"
              color="secondary.darken2"
              className="d-flex align-center"
            />
          </v-list-item-icon>
          <v-list-item-title class="secondary--text text--darken-2 body-1">
            {{ $t('common.copy') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="handleArchiveToggle"
          v-if="itemVisibilities.archive"
        >
          <v-list-item-icon class="me-1">
            <base-icon
              name="archive"
              className="d-flex align-center"
              color="secondary.darken2"
            />
          </v-list-item-icon>
          <v-list-item-title class="secondary--text text--darken-2 body-1">
            {{ task.archived ? $t('common.unarchive') : $t('common.archive') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="dialog = true" v-if="itemVisibilities.delete">
          <v-list-item-icon class="me-1">
            <base-icon
              color="error"
              name="trash-alt"
              className="d-flex align-center"
            />
          </v-list-item-icon>
          <v-list-item-title class="error--text body-1">
            {{ $t('common.delete') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <base-confirm-dialog
      :loading="loading"
      cancelColor="primary"
      :dialog.sync="dialog"
      okColor="error base"
      @ok-clicked="deleteTask"
      :okText="$t('common.delete')"
      :cancelText="$t('common.cancel')"
      :title="$t('page.tasks.delete_confirmation.title')"
      :content="$t('page.tasks.delete_confirmation.content')"
    />
    <task-dialog-actions-copy-task-dialog
      :loading="loading"
      ref="taskCopyDialog"
      @task-copy-clicked="copyTask"
    />
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseStopwatch from '@/components/BaseStopwatch'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'
import TaskDialogActionsCopyTaskDialog from '@/components/task/TaskDialogActionsCopyTaskDialog'

export default {
  props: {
    priority: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters(['task', 'project', 'abilitiesPriorityMap']),
    itemVisibilities() {
      return {
        copy: this.priority >= this.abilitiesPriorityMap.Task.copy,
        watch: this.priority >= this.abilitiesPriorityMap.Task.watch,
        delete: this.priority >= this.abilitiesPriorityMap.Task.delete,
        archive: this.priority >= this.abilitiesPriorityMap.Task.archive,
        create_timelog:
          this.priority >= this.abilitiesPriorityMap.Task.create_timelog
      }
    },
    menuVisible() {
      return Object.values(this.itemVisibilities).some((item) => item)
    }
  },
  data() {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'copyProjectTask',
      'stopTaskTimelog',
      'startTaskTimelog',
      'deleteProjectTask',
      'updateTaskWithAlias',
      'displaySuccessMessage'
    ]),
    startOrStopTime() {
      const args = {
        pid: this.project.id,
        data: {
          task_id: this.task.id
        }
      }
      if (this.task.timelogs_duration.active > 0) {
        return this.stopTaskTimelog(args)
      }
      this.startTaskTimelog(args)
    },
    goBack() {
      const view = this.$route.params.view || 'kanban'
      this.$router.push({ name: 'board', view })
    },
    toggleLoading() {
      this.loading = !this.loading
    },
    async deleteTask() {
      this.toggleLoading()
      try {
        await this.deleteProjectTask(this.task.id)
        this.dialog = false
        this.goBack()
      } catch (error) {}
      this.toggleLoading()
    },
    async copyTask({ list, parts }) {
      this.toggleLoading()
      try {
        await this.copyProjectTask({
          tid: this.task.id,
          data: {
            parts,
            list_id: list
          },
          path: 'copy'
        })
        this.$refs.taskCopyDialog.close()
        this.displaySuccessMessage(this.$i18n.t('messages.task_copied'))
      } catch (error) {}
      this.toggleLoading()
    },
    handleArchiveToggle() {
      this.goBack()
      this.$emit('task-archive-toggled')
    }
  },
  components: {
    BaseIcon,
    BaseStopwatch,
    BaseConfirmDialog,
    TaskDialogActionsCopyTaskDialog
  }
}
</script>
