import request from '@/api/axios'

export const fetchOnboardingQuestions = () =>
  request({
    url: '/orgs/#oid/onboarding/questions',
    method: 'get'
  })

export const fetchOnboardingProjectTemplates = (params) =>
  request({
    url: '/orgs/#oid/onboarding/templates',
    method: 'get',
    params
  })

export const fetchTemplateCategories = () =>
  request({
    url: '/orgs/#oid/onboarding/template-categories',
    method: 'get'
  })

export const patchUnboardingQuestions = (data) =>
  request({
    url: '/orgs/#oid/onboarding/questions',
    method: 'patch',
    data
  })
