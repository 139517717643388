<template>
  <div class="mb-5">
    <base-inline-input
      :loading="editing"
      :initial-value="label.title"
      @save-clicked="
        $emit('edit-clicked', {
          lid: label.id,
          data: {
            title: $event
          }
        })
      "
    >
      <v-btn
        small
        text
        depressed
        color="error"
        class="ms-12"
        @click="toggle"
        :loading="loading"
        v-if="label.enabled"
        v-text="$t('common.disable')"
      />
      <v-btn
        small
        text
        depressed
        class="ms-12"
        color="success"
        @click="toggle"
        :loading="loading"
        v-if="!label.enabled"
        v-text="$t('common.enable')"
      />
      <template v-slot:prependExtra>
        <v-avatar class="me-2" :color="`#${label.color_code}`" :size="40" />
      </template>
    </base-inline-input>
  </div>
</template>

<script>
import BaseInlineInput from '@/components/BaseInlineInput'
export default {
  props: {
    label: {
      type: Object,
      required: true
    },
    currentLabel: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      if (this.loading) {
        return
      }
      this.$emit('toggle-clicked', {
        lid: this.label.id,
        title: this.label.title,
        enabled: !this.label.enabled,
        path: this.label.enabled ? 'disable' : 'enable'
      })
    }
  },
  components: {
    BaseInlineInput
  }
}
</script>
