<template>
  <fragment>
    <base-icon
      width="20"
      height="20"
      :name="name"
      color="primary"
      className="d-flex align-center"
    />
    <span class="body-1 secondary--text text--darken-2 ms-1">{{
      $t(`common.boards.${item}`)
    }}</span>
  </fragment>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  props: {
    item: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    BaseIcon
  }
}
</script>
