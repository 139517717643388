var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('v-checkbox',{staticClass:"pt-0 mt-0 flex-grow-0",attrs:{"small":"","hide-details":"","ripple":false,"disabled":!_vm.editable},on:{"change":function($event){return _vm.update({
            prop: 'completed',
            data: {
              checklist_id: _vm.cid,
              completed: _vm.option.completed
            }
          })}},model:{value:(_vm.option.completed),callback:function ($$v) {_vm.$set(_vm.option, "completed", $$v)},expression:"option.completed"}}),(_vm.editing)?_c('v-form',{staticClass:"flex-grow-1",on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('v-text-field',{staticClass:"silent body-2",attrs:{"flat":"","solo":"","dense":"","autofocus":"","full-width":"","height":"18","value":_vm.text,"hide-details":"","background-color":"secondary lighten-5"},on:{"blur":_vm.save,"input":_vm.handleInputChange}})],1):_c('span',{staticClass:"body-2 secondary--text text--darken-2 flex-grow-1 text-wrap",class:{
          'cursor pointer': _vm.editable,
          'text-decoration-line-through': _vm.option.completed
        },domProps:{"textContent":_vm._s(_vm.option.title)},on:{"click":_vm.handleClick}})],1)],1),(_vm.deletable)?_c('v-list-item-action',{staticClass:"my-0"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":_vm.deleteItem}},[_c('base-icon',{attrs:{"width":"14","height":"14","name":"times","color":"error","className":"flexbox align-center"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }