import request from '@/api/axios'

export const fetchMembers = (pid, params) =>
  request({
    url: `orgs/#oid/projects/${pid}/project-memberships`,
    method: 'get',
    params
  })

export const createProjectMembers = (pid, data) =>
  request({
    url: `orgs/#oid/projects/${pid}/project-memberships`,
    method: 'post',
    data
  })

export const deleteMembers = (pid, data) =>
  request({
    url: `orgs/#oid/projects/${pid}/project-memberships`,
    method: 'delete',
    data
  })
