import request from '@/api/axios'
export const fetchTimelogs = (pid, params) =>
  request({
    url: `orgs/#oid/projects/${pid}/timelogs`,
    method: 'get',
    params
  })

export const fetchTimelog = (pid, tid) =>
  request({
    url: `orgs/#oid/projects/${pid}/timelogs/${tid}`,
    method: 'get'
  })

export const createTimelog = (pid, data) =>
  request({
    url: `orgs/#oid/projects/${pid}/timelogs`,
    method: 'post',
    data
  })

export const createTimelogExport = (pid, data) =>
  request({
    url: `orgs/#oid/projects/${pid}/timelogs/export`,
    method: 'post',
    data
  })

export const patchTimelog = (tid, data) =>
  request({
    url: `orgs/#oid/timelogs/${tid}`,
    method: 'patch',
    data
  })

export const startTimelog = (pid, data) =>
  request({
    url: `orgs/#oid/projects/${pid}/timelogs/start`,
    method: 'post',
    data
  })

export const stopTimelog = (pid, data) =>
  request({
    url: `orgs/#oid/projects/${pid}/timelogs/stop`,
    method: 'post',
    data
  })

export const deleteTimelog = tid =>
  request({
    url: `orgs/#oid/timelogs/${tid}`,
    method: 'delete'
  })
