<template>
  <centered-layout>
    <template v-slot:appbar>
      <v-toolbar-title class="pa-0 flex-grow-1 d-flex justify-center">
        <base-logo />
      </v-toolbar-title>
    </template>
    <div
      class="d-flex flex-grow-1 flex-column fill-width align-center"
      v-if="invitation && !tokenInvalid"
    >
      <h2
        class="
          body-1
          mt-2
          mb-4
          fill-width
          text-center
          secondary--text
          text--darken-2
        "
      >
        {{ $t('page.invitations.content') }}
      </h2>
      <base-avatar
        :size="64"
        text-size="h1"
        :elevated="true"
        :title="invitation.organization.title"
        :image="
          invitation.organization.logo
            ? invitation.organization.logo.medium
            : ''
        "
      />
      <h3 class="text-h3 mt-2">{{ invitation.organization.title }}</h3>
      <sessions-invitations-form
        :name.sync="form.name"
        v-if="!isAuthenticated"
        :email.sync="form.email"
        :has-user-info="hasUserInfo"
        :username.sync="form.username"
        :password.sync="form.password"
        :form-is-valid.sync="formIsValid"
        :receive-news.sync="form.receive_news"
      />
      <div
        class="
          d-flex
          fill-width
          align-center
          justify-center
          flex-column flex-grow-1
        "
        v-else
      >
        <template v-if="acceptingInvitation">
          <v-progress-linear indeterminate color="primary" />
        </template>
      </div>
    </div>
    <div
      class="d-flex flex-grow-1 flex-column fill-width justify-center"
      v-if="tokenInvalid"
    >
      <v-alert class="fill-width" text type="error">
        <template slot="prepend">
          <base-icon name="exclamation-triangle" color="error" />
          <span class="ms-2 body-1">{{ invalidTokenErrorMsg }}</span>
        </template>
      </v-alert>
      <p class="body-2 fill-width text-start secondary--text text--darken-2">
        {{
          $t('page.invitations.ask_your_team_to_send_the_invitation_link_again')
        }}
      </p>
    </div>
    <div class="flex-grow-0 fill-width mb-8" v-if="!loading">
      <v-btn
        block
        depressed
        color="primary"
        v-if="tokenInvalid"
        @click="$router.push({ path: '/signin' })"
      >
        {{ $t('common.back_to_login') }}
      </v-btn>
      <template v-if="invitation && !isAuthenticated">
        <v-btn
          block
          depressed
          color="primary"
          @click="handleSignUp"
          :loading="signupLoading"
          :disabled="!formIsValid"
        >
          {{ $t('common.sign_up') }}
        </v-btn>
      </template>
    </div>
    <base-loading v-if="!invitation && loading" />
  </centered-layout>
</template>

<script>
import BaseLogo from '@/components/BaseLogo'
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import BaseAvatar from '@/components/BaseAvatar'
import BaseLoading from '@/components/BaseLoading'
import { acceptInvitation } from '@/api/invitations'
import CenteredLayout from '@/layouts/CenteredLayout'
import SessionsInvitationsForm from '@/components/sessions/SessionsInvitationsForm'
export default {
  data() {
    return {
      invalidTokenErrorMsg: null,
      acceptingInvitation: false,
      signupLoading: false,
      tokenInvalid: false,
      hasUserInfo: false,
      formIsValid: false,
      form: {
        name: '',
        email: '',
        password: '',
        username: '',
        receive_news: false
      }
    }
  },
  computed: {
    ...mapGetters(['loading', 'invitation', 'isAuthenticated']),
    token() {
      return this.$route.query.token || null
    }
  },
  methods: {
    ...mapActions([
      'register',
      'getInvitation',
      'signIn',
      'displayResponseError'
    ]),
    async handleSignUp() {
      const { name, email, username, password } = this.form
      const { token } = this
      const emailObj = this.hasUserInfo ? {} : { email }
      const data = {
        name,
        token,
        username,
        password,
        ...emailObj,
        receive_news: this.form.receive_news
      }
      const requestOptions = {
        url: '/users/register/by-invitation-token'
      }
      const successCallback = (res) => {
        this.signupLoading = false
        this.signIn(res)
      }
      const errorCallback = () => {
        this.signupLoading = false
      }
      this.signupLoading = true
      await this.register({
        data,
        requestOptions,
        successCallback,
        errorCallback
      })
    },
    setInvalidTokenErrorMsg(data) {
      this.invalidTokenErrorMsg = data.errors
        .map((err) => err.message)
        .join(' ')
    },
    handleError(error) {
      this.tokenInvalid = true
      this.setInvalidTokenErrorMsg(error.response.data)
    },
    goToOnboarding() {
      this.$router.push({ path: '/onboarding' })
    },
    async acceptInvitationToOrganization() {
      this.acceptingInvitation = true
      try {
        await acceptInvitation(this.token, false)
        this.goToOnboarding()
      } catch (error) {
        this.goToOnboarding()
      }
      this.acceptingInvitation = false
    },
    async getData() {
      try {
        await this.getInvitation(this.token)
        if (this.invitation.user) {
          this.form.email = this.invitation.user.email
          this.hasUserInfo = true
        }
        if (this.isAuthenticated) {
          this.acceptInvitationToOrganization()
        }
        this.tokenInvalid = false
        this.invalidTokenErrorMsg = null
      } catch (error) {
        if (!error.response) {
          return this.displayResponseError()
        }
        this.handleError(error)
      }
    }
  },
  created() {
    if (!this.token) {
      return this.$router.push({ path: 'signup' })
    }
    this.getData()
  },
  components: {
    BaseLogo,
    BaseIcon,
    BaseAvatar,
    BaseLoading,
    CenteredLayout,
    SessionsInvitationsForm
  }
}
</script>
