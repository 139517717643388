<template>
  <v-list-item>
    <v-list-item-content class="info--text">
      <v-list-item-title
        class="body-1 d-flex justify-space-between"
        v-text="$t('page.profile_setting.notifications.app_notifications')"
      />
    </v-list-item-content>
    <v-list-item-action>
      <v-switch
        hide-details
        class="mt-0 pt-0"
        v-model="pushEnabled"
        @change="handleValueChange"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['notificationSettings'])
  },
  data() {
    return {
      pushEnabled: false
    }
  },
  methods: {
    ...mapActions(['updateUserNotificationSettings']),
    handleValueChange() {
      this.updateUserNotificationSettings({
        push: this.pushEnabled
      })
    }
  },
  mounted() {
    this.pushEnabled = this.notificationSettings.push
  }
}
</script>
