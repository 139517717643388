import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'
import task from './modules/task'
import teams from './modules/teams'
import tasks from './modules/tasks'
import config from './modules/config'
import myTasks from './modules/my-tasks'
import timelogs from './modules/timelogs'
import comments from './modules/comments'
import projects from './modules/projects'
import notifier from './modules/notifier'
import onboarding from './modules/onboarding'
import invitations from './modules/invitations'
import integrations from './modules/integrations'
import organization from './modules/organization'
import userInterface from './modules/userInterface'
import organizations from './modules/organizations'
import notifications from './modules/notifications'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    auth,
    task,
    teams,
    tasks,
    config,
    myTasks,
    timelogs,
    comments,
    projects,
    notifier,
    onboarding,
    invitations,
    integrations,
    organization,
    userInterface,
    organizations,
    notifications
  }
})
