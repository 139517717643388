<template>
  <v-list>
    <v-list-item
      :key="item.name"
      v-for="item in lists"
      :class="item.className"
      @click="item.dialogVisible = true"
    >
      <v-list-item-action class="me-4">
        <base-icon
          width="24"
          height="24"
          :name="item.icon"
          className="d-flex align-center"
          :color="item.color ? 'error' : 'primary'"
        />
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title
          class="body-2"
          :class="item.color ? 'error--text' : 'info--text'"
          v-text="$t(`page.project_settings.${item.name}.title`)"
        />
      </v-list-item-content>

      <v-list-item-icon>
        <base-angle-icon
          width="24"
          height="24"
          fill="secondary.base"
          class="flip-horizontal"
        />
      </v-list-item-icon>
      <component
        :name="item.name"
        :project="project"
        :dialog.sync="item.dialogVisible"
        @go-back="item.dialogVisible = false"
        :is="`SettingsDialogList${item.component}`"
      />
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import SettingsDialogListTags from '@/components/project/SettingsDialogListTags'
import SettingsDialogListBoards from '@/components/project/SettingsDialogListBoards'
import SettingsDialogListStatus from '@/components/project/SettingsDialogListStatus'
import SettingsDialogListGeneral from '@/components/project/SettingsDialogListGeneral'
import SettingsDialogListMembers from '@/components/project/SettingsDialogListMembers2'
import SettingsDialogListFeatures from '@/components/project/SettingsDialogListFeatures'
import SettingsDialogListColorLabels from '@/components/project/SettingsDialogListColorLabels'
import SettingsDialogListLeaveProject from '@/components/project/SettingsDialogListLeaveProject'
import SettingsDialogListIntegrations from '@/components/project/SettingsDialogListIntegrations'
import SettingsDialogListTeamsAndAccess from '@/components/project/SettingsDialogListTeamsAndAccess'
import SettingsDialogListAdvancedSettings from '@/components/project/SettingsDialogListAdvancedSettings'
import SettingsDialogListTransferOwnership from '@/components/project/SettingsDialogListTransferOwnership'

export default {
  computed: {
    ...mapGetters([
      'user',
      'project',
      'isMobile',
      'projectAbilities',
      'projectApprovedUsers'
    ]),
    userIsOwner() {
      if (!this.project || !this.user) {
        return false
      }
      return this.project.owner.id === this.user.id
    },
    availableUsers() {
      return this.projectApprovedUsers.filter(
        (user) => user.id !== this.user.id
      )
    },
    lists() {
      return this.items.map((item) => {
        item.className = this.getItemClassName(item)
        return item
      })
    }
  },
  components: {
    BaseIcon,
    BaseAngleIcon,
    SettingsDialogListTags,
    SettingsDialogListBoards,
    SettingsDialogListStatus,
    SettingsDialogListMembers,
    SettingsDialogListGeneral,
    SettingsDialogListFeatures,
    SettingsDialogListColorLabels,
    SettingsDialogListLeaveProject,
    SettingsDialogListIntegrations,
    SettingsDialogListTeamsAndAccess,
    SettingsDialogListAdvancedSettings,
    SettingsDialogListTransferOwnership
  },
  data() {
    return {
      items: [
        {
          name: 'general',
          icon: 'cog',
          dialogVisible: false,
          component: 'General',
          ability: 'edit_info'
        },
        {
          name: 'members',
          icon: 'users-alt',
          dialogVisible: false,
          component: 'Members',
          ability: 'manage_members'
        },
        {
          ability: 'manage_teams',
          name: 'teams_and_access',
          icon: 'lock-alt',
          dialogVisible: false,
          component: 'TeamsAndAccess'
        },
        {
          ability: 'manage_statuses',
          name: 'status',
          icon: 'clipboard-alt',
          dialogVisible: false,
          component: 'Status'
        },
        {
          ability: 'manage_boards',
          name: 'boards',
          icon: 'clapper-board',
          dialogVisible: false,
          component: 'Boards'
        },
        {
          ability: 'manage_labels',
          name: 'color_labels',
          icon: 'swatchbook',
          dialogVisible: false,
          component: 'ColorLabels'
        },
        {
          ability: 'manage_tags',
          name: 'tags',
          icon: 'tag',
          dialogVisible: false,
          component: 'Tags'
        },
        {
          ability: 'manage_features',
          name: 'features',
          icon: 'create-dashboard',
          dialogVisible: false,
          component: 'Features'
        },
        {
          ability: 'manage_integrations',
          name: 'integrations',
          icon: 'puzzle-piece',
          dialogVisible: false,
          component: 'Integrations'
        },
        {
          ability: 'custom',
          name: 'advanced_settings',
          icon: 'cog',
          dialogVisible: false,
          component: 'AdvancedSettings'
        },
        {
          ability: 'transfer_ownership',
          name: 'transfer_ownership',
          icon: 'exchange',
          dialogVisible: false,
          component: 'TransferOwnership'
        },
        {
          ability: 'leave',
          name: 'leave_project',
          icon: 'sign-out-alt',
          dialogVisible: false,
          component: 'LeaveProject'
        }
      ]
    }
  },
  methods: {
    getItemClassName(item) {
      const { abilities } = this.projectAbilities
      const advancedAbilities = ['close', 'export', 'delete']
      if (
        abilities[item.ability] !== undefined &&
        abilities[item.ability] === false
      ) {
        return 'd-none'
      }
      if (
        item.name === 'advanced_settings' &&
        !advancedAbilities.some((ability) => abilities[ability])
      ) {
        return 'd-none'
      }
      const projectUserIds = this.projectApprovedUsers.map((user) => user.id)
      if (
        item.name === 'transfer_ownership' &&
        (!this.userIsOwner || this.availableUsers.length === 0)
      ) {
        return 'd-none'
      }
      if (
        item.name === 'leave_project' &&
        (this.availableUsers.length === 0 ||
          projectUserIds.indexOf(this.user.id) < 0)
      ) {
        return 'd-none'
      }
      return ''
    }
  }
}
</script>
