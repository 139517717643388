import {
  fetchUser,
  patchUser,
  fetchSessions,
  deleteSessions,
  editUserAvatar,
  editUserPassword,
  deleteUserAvatar,
  fetchSecuritySettings,
  createTwoFactorAuthSetup,
  fetchNotificationSettings,
  createTwoFactorAuthEnable,
  patchNotificationSettings,
  createTwoFactorAuthDisable,
  createUserEmailVerification,
  fetchTwoFactorAuthBackupCodes,
  createTwoFactorAuthBackupCodes,
  createUserEmailVerificationResend
} from '@/api/users'
import {
  SET_USER,
  SET_USER_VALUE,
  SET_USER_LOCALE,
  SET_USER_AVATAR,
  SET_USER_CALENDAR,
  SET_USER_SESSIONS,
  SET_USER_DIRECTION,
  DELETE_USER_SESSIONS,
  SET_USER_2FA_SETUP_INFO,
  SET_USER_2FA_BACKUP_CODES,
  SET_USER_SECURITY_SETTINGS,
  SET_USER_NOTIFICATION_SETTINGS,
  SET_ORGANIZATION_MEMBERSHIP_USER,
  SET_USER_VERIFICATION_EMAIL_COOLDOWN
} from '../mutation-types'

const websiteUrl = process.env.VUE_APP_WEBSITE_URL
const getLocalizedBaseUrl = locale =>
  locale === 'fa' ? `${websiteUrl}/fa` : websiteUrl

const state = {
  user: null,
  locale: 'en',
  direction: 'ltr',
  userSessions: null,
  calendar: 'gregorian',
  securitySettings: null,
  notificationSettings: null,
  twoFactorAuthSetupInfo: null,
  twoFactorAuthBackupCodes: null,
  userVerificationEmailCooldown: 0
}

const mutations = {
  [SET_USER](state, user) {
    state.user = user
  },
  [DELETE_USER_SESSIONS](state, ids) {
    if (!state.userSessions) {
      return
    }
    for (let i = 0; i < ids.length; i++) {
      const index = state.userSessions.findIndex(
        session => session.id === ids[i]
      )
      if (index < 0) {
        return
      }
      state.userSessions.splice(index, 1)
    }
  },
  [SET_USER_SESSIONS](state, userSessions) {
    state.userSessions = userSessions
  },
  [SET_USER_NOTIFICATION_SETTINGS](state, notificationSettings) {
    state.notificationSettings = notificationSettings
  },
  [SET_USER_2FA_SETUP_INFO](state, twoFactorAuthSetupInfo) {
    state.twoFactorAuthSetupInfo = twoFactorAuthSetupInfo
  },
  [SET_USER_2FA_BACKUP_CODES](state, twoFactorAuthBackupCodes) {
    state.twoFactorAuthBackupCodes = twoFactorAuthBackupCodes
  },
  [SET_USER_SECURITY_SETTINGS](state, securitySettings) {
    state.securitySettings = securitySettings
  },
  [SET_USER_LOCALE](state, user) {
    state.locale = user.language
  },
  [SET_USER_CALENDAR](state, user) {
    const { calendar } = user
    state.calendar = calendar
  },
  [SET_USER_DIRECTION](state, user) {
    state.direction = user.language === 'fa' ? 'rtl' : 'ltr'
  },
  [SET_USER_AVATAR](state, avatar) {
    state.user.avatar = avatar
  },
  [SET_USER_VALUE](state, { key, value }) {
    state.user[key] = value
  },
  [SET_USER_VERIFICATION_EMAIL_COOLDOWN](state, userVerificationEmailCooldown) {
    state.userVerificationEmailCooldown = Number(userVerificationEmailCooldown)
  }
}

const actions = {
  async getUser({ commit }) {
    const { data } = await fetchUser()
    const user = data.data
    commit(SET_USER, user)
    if (!user) {
      return
    }
    commit(SET_USER_LOCALE, user)
    commit(SET_USER_CALENDAR, user)
    commit(SET_USER_DIRECTION, user)
  },
  updateUserInstances({ commit, dispatch, rootState }, user) {
    if (rootState.projects.project) {
      dispatch('getProjectMemberships', rootState.projects.project.id, {
        root: true
      })
    }
    commit(SET_ORGANIZATION_MEMBERSHIP_USER, user, { root: true })
  },
  async updateAvatar({ commit, dispatch }, data) {
    const result = await editUserAvatar(data)
    commit(SET_USER_AVATAR, result.data.data.avatar)
    dispatch('updateUserInstances', result.data.data)
  },
  async updatePassword({ commit }, data) {
    await editUserPassword(data)
  },
  async updateUser({ commit, dispatch }, { data, key }) {
    const result = await patchUser(data)
    commit(SET_USER_VALUE, { key, value: result.data.data[key] })
    dispatch('updateUserInstances', result.data.data)
  },
  async verifyUserEmail({ commit, dispatch }, { data, key, value }) {
    const result = await createUserEmailVerification(data)
    commit(SET_USER_VALUE, { key, value })
    dispatch('updateUserInstances', result.data.data)
  },
  async resendVerificationEmail({ commit }, data) {
    const { headers } = await createUserEmailVerificationResend(data)
    commit(SET_USER_VERIFICATION_EMAIL_COOLDOWN, headers['ratelimit-reset'])
  },
  async getSecuritySettings({ commit }) {
    const { data } = await fetchSecuritySettings()
    commit(SET_USER_SECURITY_SETTINGS, data.data)
  },
  async getUserSessions({ commit }) {
    const { data } = await fetchSessions()
    commit(SET_USER_SESSIONS, data.data)
  },
  async deleteUserSessions({ commit }, data) {
    await deleteSessions(data)
    commit(DELETE_USER_SESSIONS, data.ids)
  },
  async enableTwoFactorAuth({ commit }, data) {
    const result = await createTwoFactorAuthEnable(data)
    commit(SET_USER_SECURITY_SETTINGS, result.data.data)
  },
  async disableTwoFactorAuth({ commit }) {
    const result = await createTwoFactorAuthDisable()
    commit(SET_USER_SECURITY_SETTINGS, result.data.data)
  },
  async getTwoFactorAuthBackupCodes({ commit }) {
    const { data } = await fetchTwoFactorAuthBackupCodes()
    commit(SET_USER_2FA_BACKUP_CODES, data.data)
  },
  async generateTwoFactorAuthBackupCodes({ commit }) {
    const { data } = await createTwoFactorAuthBackupCodes()
    commit(SET_USER_2FA_BACKUP_CODES, data.data)
  },
  async generateTwoFactorAuthSetup({ commit }, data) {
    const result = await createTwoFactorAuthSetup(data)
    commit(SET_USER_2FA_SETUP_INFO, result.data.data)
  },
  async getUserNotificationSettings({ commit }) {
    const { data } = await fetchNotificationSettings()
    commit(SET_USER_NOTIFICATION_SETTINGS, data.data)
  },
  async updateUserNotificationSettings({ commit }, data) {
    const result = await patchNotificationSettings(data)
    commit(SET_USER_NOTIFICATION_SETTINGS, result.data.data)
  },
  setTwoFactorAuthSetupInfo({ commit }, twoFactorAuthSetupInfo) {
    commit(SET_USER_2FA_SETUP_INFO, twoFactorAuthSetupInfo)
  },
  setTwoFactorAuthBackupCodes({ commit }, twoFactorAuthBackupCodes) {
    commit(SET_USER_2FA_BACKUP_CODES, twoFactorAuthBackupCodes)
  },
  async deleteAvatar({ commit, rootState, dispatch }) {
    await deleteUserAvatar()
    commit(SET_USER_AVATAR, null)
    dispatch('updateUserInstances', {
      ...rootState.user.user,
      avatar: null
    })
  }
}

const getters = {
  user: state => state.user,
  locale: state => state.locale,
  calendar: state => state.calendar,
  direction: state => state.direction,
  userSessions: state => state.userSessions,
  securitySettings: state => state.securitySettings,
  termsLink: state => `${getLocalizedBaseUrl(state)}/terms`,
  notificationSettings: state => state.notificationSettings,
  privacyLink: state => `${getLocalizedBaseUrl(state)}/privacy`,
  twoFactorAuthSetupInfo: state => state.twoFactorAuthSetupInfo,
  twoFactorAuthBackupCodes: state => state.twoFactorAuthBackupCodes,
  userVerificationEmailCooldown: state => state.userVerificationEmailCooldown
}

export default {
  mutations,
  actions,
  getters,
  state
}
