<template>
  <v-list>
    <v-list-item
      :key="item.name"
      v-for="item in items"
      @click="item.dialogVisible = true"
    >
      <v-list-item-icon class="me-2">
        <base-icon
          :name="item.icon"
          color="primary"
          className="d-flex align-center"
        />
      </v-list-item-icon>

      <v-list-item-content class="info--text">
        <v-list-item-title
          class="text-h5 d-flex justify-space-between"
          v-text="$t(`page.profile_setting.account.${item.name}.title`)"
        />
      </v-list-item-content>

      <v-list-item-icon>
        <base-angle-icon
          width="20"
          height="20"
          class="flip-horizontal"
          fill="secondary.darken2"
        />
      </v-list-item-icon>
      <base-dialog
        card-color="white"
        :loading="item.loading"
        content-class="large-level-three"
        @save-clicked="item.action(item)"
        :dialog.sync="item.dialogVisible"
        :disableSaveBtn="item.saveDisabled"
        :save-btn-text="$t('common.save')"
        :show-save-btn="item.hasSaveButton"
        :title="$t(`page.profile_setting.account.${item.name}.title`)"
      >
        <component
          :loading.sync="item.loading"
          :save-clicked.sync="item.saveClicked"
          :save-disabled.sync="item.saveDisabled"
          @action-done="item.dialogVisible = false"
          :is="`ProfileOverviewListAccountList${item.component}`"
        />
      </base-dialog>
    </v-list-item>
    <v-divider class="my-4 secondary lighten-2" />
    <profile-overview-list-account-list-delete-account />
  </v-list>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import BaseDialog from '@/components/BaseDialog'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import ProfileOverviewListAccountListChangeEmail from '@/components/user/ProfileOverviewListAccountListChangeEmail'
import ProfileOverviewListAccountListDeleteAccount from '@/components/user/ProfileOverviewListAccountListDeleteAccount'
import ProfileOverviewListAccountListChangePassword from '@/components/user/ProfileOverviewListAccountListChangePassword'
import ProfileOverviewListAccountListChangeLanguage from '@/components/user/ProfileOverviewListAccountListChangeLanguage'
export default {
  data() {
    return {
      items: [
        {
          name: 'change_password',
          icon: 'lock-alt',
          dialogVisible: false,
          hasSaveButton: true,
          saveClicked: false,
          saveDisabled: true,
          loading: false,
          component: 'ChangePassword',
          action: (item) => {
            item.saveClicked = true
          }
        },
        {
          name: 'change_email',
          icon: 'at',
          dialogVisible: false,
          hasSaveButton: true,
          saveClicked: false,
          saveDisabled: true,
          loading: false,
          component: 'ChangeEmail',
          action: (item) => {
            item.saveClicked = true
          }
        },
        {
          name: 'language',
          icon: 't-language',
          dialogVisible: false,
          hasSaveButton: false,
          saveDisabled: true,
          saveClicked: false,
          loading: false,
          component: 'ChangeLanguage',
          action: () => {}
        }
      ]
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseAngleIcon,
    ProfileOverviewListAccountListChangeEmail,
    ProfileOverviewListAccountListDeleteAccount,
    ProfileOverviewListAccountListChangePassword,
    ProfileOverviewListAccountListChangeLanguage
  }
}
</script>
