var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"px-4"},[_c('h2',{staticClass:"py-2 my-0 body-1 info--text",domProps:{"textContent":_vm._s(
      _vm.$t('page.project_settings.advanced_settings.export_project.title')
    )}}),_c('p',{staticClass:"py-2 my-0 body-1 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
      _vm.$t('page.project_settings.advanced_settings.export_project.description')
    )}}),_c('p',{staticClass:"py-2 my-0 body-1 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
      _vm.$t('page.project_settings.advanced_settings.export_project.caution')
    )}}),_c('p',{staticClass:"py-2 my-0 d-flex align-center justify-center"},[_c('v-btn',{attrs:{"depressed":"","width":"148","color":"primary","loading":_vm.loading,"disabled":_vm.loading},domProps:{"textContent":_vm._s(
        _vm.$t('page.project_settings.advanced_settings.export_project.button')
      )},on:{"click":_vm.handleClickOnExport}})],1),_c('base-inline-form',{attrs:{"value":_vm.sheet,"card-text-class-name":"px-4"},on:{"update:value":function($event){_vm.sheet=$event}}},[_c('v-card',{staticClass:"text-start",attrs:{"flat":"","tile":"","color":"white"}},[_c('v-card-title',{staticClass:"px-4 body-1 info--text",domProps:{"textContent":_vm._s(
          _vm.$t('page.project_settings.advanced_settings.export_project.title')
        )}}),_c('v-card-text',{staticClass:"px-4 py-0 body-1 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
          _vm.$t(
            'page.project_settings.advanced_settings.export_project.export_started'
          )
        )}}),_c('v-card-actions',{staticClass:"pb-2 d-flex justify-end"},[_c('v-btn',{attrs:{"text":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('common.okay'))},on:{"click":function($event){_vm.sheet = false}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }