<template>
  <search-dialog-list-item
    icon="columns"
    :subtitle="
      selected.length
        ? $t('search_and_filter.n_items_selected', {
            count: selected.length,
            name: $t('common.list', { count: selected.length })
          })
        : null
    "
    :title="$t('common.list', { count: 0 })"
    :toolbar-title="$t('common.list', { count: 0 })"
  >
    <base-expansion-list
      :multiple="true"
      :items="boardsWithLists"
      :selected.sync="selected"
      :empty-list-text="$t('search_and_filter.results.not_found.lists')"
    />
  </search-dialog-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseExpansionList from '@/components/BaseExpansionList'
import SearchDialogListItem from '@/components/search/SearchDialogListItem'
export default {
  props: {
    option: {
      required: true
    }
  },
  computed: {
    ...mapGetters(['boardsWithLists'])
  },
  data() {
    return {
      selected: []
    }
  },
  components: {
    BaseExpansionList,
    SearchDialogListItem
  },
  watch: {
    selected(newValue) {
      this.$emit('update:option', newValue)
    }
  }
}
</script>
