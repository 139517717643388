import request from '@/api/axios'

export const fetchMyTasks = params =>
  request({
    url: '/orgs/#oid/tasks/me',
    method: 'get',
    params
  })

export const fetchMyTasksCount = params =>
  request({
    url: '/orgs/#oid/tasks/me/count',
    method: 'get',
    params
  })
