<template>
  <v-list-item style="min-height: auto" class="mb-6">
    <v-list-item-avatar size="40" class="align-self-start mt-1 mb-1">
      <base-avatar
        :size="40"
        textSize="caption"
        :title="owner.name"
        :image="owner.avatar ? owner.avatar.medium : ''"
      />
    </v-list-item-avatar>
    <v-list-item-content class="align-self-start py-0">
      <v-list-item-title class="body-1 primary--text mb-1 d-flex align-center">
        <span class="flex-grow-1 font-weight-bold" v-text="owner.name" />
        <v-list-item-action-text
          class="body-2 secondary--text text--darken-2"
          v-html="
            $t('date.formats.iso.long_with_time', {
              date: new Date(activity.created_at * 1000)
            })
          "
        />
      </v-list-item-title>
      <v-list-item-subtitle class="info--text body-2 d-flex">
        <span class="flex-grow-1" v-html="activity.note_html" />
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import BaseAvatar from '@/components/BaseAvatar'

export default {
  props: {
    activity: {
      type: Object,
      required: true
    },
    owner: {
      type: Object,
      required: true
    },
    icon: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseAvatar
  }
}
</script>
