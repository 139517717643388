<template>
  <base-list
    :items="items"
    :multiple="true"
    :mandatory="false"
    :value.sync="value"
  />
</template>

<script>
import isEqual from 'lodash.isequal'
import BaseList from '@/components/BaseList'
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['notificationSettings']),
    items() {
      return this.options.map((option) => {
        return {
          title: this.$i18n.t(
            `page.profile_setting.notifications.task_reminder.options.${option}`
          ),
          id: option
        }
      })
    },
    form() {
      const { value } = this
      return {
        reminder_intervals: value
      }
    }
  },
  data() {
    return {
      value: [],
      options: ['1w', '1d', '1h']
    }
  },
  methods: {
    ...mapActions(['updateUserNotificationSettings']),
    handleValueChange() {
      this.updateUserNotificationSettings(this.form)
    }
  },
  mounted() {
    this.value = this.notificationSettings.reminder_intervals
  },
  watch: {
    form() {
      if (isEqual(this.form.reminder_intervals, this.notificationSettings.reminder_intervals)) {
        return
      }
      this.handleValueChange()
    }
  },
  components: {
    BaseList
  }
}
</script>
