var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-overlay',{attrs:{"color":"#fff","opacity":"0.92","value":_vm.fab,"z-index":5}}),_c('v-speed-dial',{staticStyle:{"z-index":"6"},attrs:{"fixed":"","bottom":true,"direction":"top","left":_vm.$vuetify.rtl,"right":!_vm.$vuetify.rtl,"open-on-hover":false,"transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"fab":"","dark":"","width":"58","height":"58","color":_vm.fab ? 'white' : 'primary'},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('base-icon',{attrs:{"width":"24","height":"24","name":"times","color":"secondary.darken2"}}):_c('unicon',{attrs:{"name":"plus","fill":"#ffffff"}})],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('div',{staticClass:"fab-with-content",class:_vm.$vuetify.rtl ? 'right' : 'left',on:{"click":function($event){return _vm.$emit('invite-by-email-clicked')}}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","width":"40","height":"40","color":"white"},on:{"click":function($event){return _vm.$emit('invite-by-email-clicked')}}},[_c('base-icon',{attrs:{"name":"at","color":"primary"}})],1),_c('div',{staticClass:"text info--text text-button pointer cursor",domProps:{"textContent":_vm._s(
          _vm.$t(
            'page.project_settings.members.invitation.invite_by_email.action_text'
          )
        )}})],1),_c('div',{staticClass:"fab-with-content",class:_vm.$vuetify.rtl ? 'right' : 'left',on:{"click":function($event){return _vm.$emit('invite-by-link-clicked')}}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","width":"40","height":"40","color":"white"},on:{"click":function($event){return _vm.$emit('invite-by-link-clicked')}}},[_c('base-icon',{attrs:{"name":"link","color":"primary"}})],1),_c('div',{staticClass:"text info--text text-button pointer cursor",domProps:{"textContent":_vm._s(
          _vm.$t(
            'page.project_settings.members.invitation.invite_by_link.action_text'
          )
        )}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }