<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    content-class="small-level-one"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.general.title')"
  >
    <v-sheet tile flat class="pa-4 white info--text">
      <base-inline-input
        :loading="loading"
        @save-clicked="save"
        :fixed-actions="true"
        :initial-value="project.title"
        :title="$t('form.labels.project_title')"
        @cancel-clicked="$emit('update:dialog', false)"
      />
    </v-sheet>
  </base-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseInlineInput from '@/components/BaseInlineInput'
export default {
  props: {
    project: {
      type: Object
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions(['updateProject']),
    async save(title) {
      this.loading = true
      try {
        await this.updateProject({
          pid: this.project.id,
          data: {
            title
          }
        })
      } catch (error) {}
      this.loading = false
    }
  },
  components: {
    BaseDialog,
    BaseInlineInput
  }
}
</script>
