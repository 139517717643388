<template>
  <v-card color="secondary lighten-5" height="100%" tile flat>
    <v-card-text v-if="securitySettings !== null" class="px-0">
      <section class="mb-6 px-4">
        <section class="d-flex align-center justify-space-between">
          <h3
            class="body-1 info--text"
            v-text="
              $t(
                'page.profile_setting.security.authentication.authenticator.title'
              )
            "
          />
          <v-switch
            hide-details
            class="mt-0 pt-0"
            @change="handleAuthChange"
            v-model="googleAuthEnabled"
          />
        </section>
        <p
          class="body-1 secondary--text text--darken-2 mt-1"
          v-text="
            $t(
              'page.profile_setting.security.authentication.authenticator.description'
            )
          "
        />
      </section>
      <v-list
        color="transparent"
        v-if="securitySettings.two_factor_auth.enabled"
      >
        <v-list-item @click="codeDialogVisible = true">
          <v-list-item-content>
            <v-list-item-title
              class="text-h5"
              v-text="
                $t(
                  `page.profile_setting.security.authentication.authenticator.get_recovery_codes`
                )
              "
            />
          </v-list-item-content>
          <v-list-item-icon>
            <base-angle-icon
              width="20"
              height="20"
              fill="secondary.darken2"
              class="flip-horizontal"
            />
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card-text>
    <base-loading v-else />
    <profile-overview-list-security-authentication-google
      :dialog.sync="googleAuthDialogVisible"
    />
    <profile-overview-list-security-authentication-backup-codes
      :dialog.sync="codeDialogVisible"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseLoading from '@/components/BaseLoading'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import ProfileOverviewListSecurityAuthenticationGoogle from './ProfileOverviewListSecurityAuthenticationGoogle'
import ProfileOverviewListSecurityAuthenticationBackupCodes from './ProfileOverviewListSecurityAuthenticationBackupCodes'

export default {
  computed: {
    ...mapGetters(['securitySettings'])
  },
  data() {
    return {
      googleAuthEnabled: false,
      codeDialogVisible: false,
      googleAuthDialogVisible: false
    }
  },
  methods: {
    ...mapActions(['getSecuritySettings', 'disableTwoFactorAuth']),
    handleAuthChange() {
      if (!this.googleAuthEnabled) {
        this.disableTwoFactorAuth()
        return
      }
      this.googleAuthDialogVisible = true
    },
    getData() {
      if (this.securitySettings) {
        return
      }
      this.getSecuritySettings()
    },
    setAuthEnabled() {
      this.googleAuthEnabled = this.securitySettings.two_factor_auth.enabled
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    securitySettings: {
      handler() {
        this.setAuthEnabled()
      },
      deep: true
    },
    googleAuthDialogVisible(newValue) {
      if (!newValue) {
        this.setAuthEnabled()
      }
    }
  },
  components: {
    BaseLoading,
    BaseAngleIcon,
    ProfileOverviewListSecurityAuthenticationGoogle,
    ProfileOverviewListSecurityAuthenticationBackupCodes
  }
}
</script>
