var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"dialog":_vm.dialog,"loading":_vm.loading,"card-color":"white","show-save-btn":false,"content-class":"large-level-three","title":_vm.$t('page.profile_setting.security.authentication.recovery_codes.title')},on:{"go-back-clicked":function($event){return _vm.$emit('update:dialog', false)}},scopedSlots:_vm._u([{key:"cardActions",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between pa-4"},[_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.loading,"disabled":_vm.loading},domProps:{"textContent":_vm._s(
          _vm.$t(
            'page.profile_setting.security.authentication.recovery_codes.get_new_codes'
          )
        )},on:{"click":_vm.getNewCodes}}),_c('v-btn',{attrs:{"depressed":"","color":"primary","loading":_vm.exporting,"disabled":_vm.exporting},domProps:{"textContent":_vm._s(_vm.$t('common.download'))},on:{"click":_vm.exportCodes}})],1)]},proxy:true}])},[_c('section',{staticClass:"px-4"},[_c('h2',{staticClass:"body-1 mt-4 info--text text-darken-4",domProps:{"textContent":_vm._s(
        _vm.$t(
          'page.profile_setting.security.authentication.recovery_codes.subtitle'
        )
      )}}),_c('p',{staticClass:"body-1 mt-4 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
        _vm.$t(
          'page.profile_setting.security.authentication.recovery_codes.description'
        )
      )}})]),(_vm.twoFactorAuthBackupCodes)?_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":""}},_vm._l((_vm.twoFactorAuthBackupCodes.codes),function(item){return _c('v-col',{key:item.code,staticClass:"text-center text-h5 info--text",attrs:{"cols":"6"}},[_c('div',{staticClass:"py-2 px-3",domProps:{"textContent":_vm._s(item.code)}})])}),1):_c('base-loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }