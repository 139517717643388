<template>
  <v-avatar
    :size="size"
    :left="isLeft"
    :right="isRight"
    :style="avatarStyle"
    :color="getUserColorBasedOnName(title)"
    :class="`avatar${elevated ? ' t-avatar-box-shadow' : ''}`"
  >
    <v-img v-if="image.length > 0" :src="image" :alt="title" />
    <span v-else :class="`white--text ${textSize}`">
      <template v-if="useWholeTitle">
        {{ title }}
      </template>
      <template v-else>
        {{ getNameInitials(title) }}
      </template>
    </span>
  </v-avatar>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 48
    },
    color: {
      type: String,
      default: 'secondary'
    },
    image: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    elevated: {
      type: Boolean,
      default: false
    },
    avatarStyle: {
      type: String,
      default: ''
    },
    textSize: {
      type: String,
      default: 'headline'
    },
    useWholeTitle: {
      type: Boolean,
      default: false
    },
    isLeft: {
      type: Boolean,
      default: false
    },
    isRight: {
      type: Boolean,
      default: false
    }
  }
}
</script>
