var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"secondary lighten-5","height":"100%","tile":"","flat":""}},[(_vm.securitySettings !== null)?_c('v-card-text',{staticClass:"px-0"},[_c('section',{staticClass:"mb-6 px-4"},[_c('section',{staticClass:"d-flex align-center justify-space-between"},[_c('h3',{staticClass:"body-1 info--text",domProps:{"textContent":_vm._s(
            _vm.$t(
              'page.profile_setting.security.authentication.authenticator.title'
            )
          )}}),_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"hide-details":""},on:{"change":_vm.handleAuthChange},model:{value:(_vm.googleAuthEnabled),callback:function ($$v) {_vm.googleAuthEnabled=$$v},expression:"googleAuthEnabled"}})],1),_c('p',{staticClass:"body-1 secondary--text text--darken-2 mt-1",domProps:{"textContent":_vm._s(
          _vm.$t(
            'page.profile_setting.security.authentication.authenticator.description'
          )
        )}})]),(_vm.securitySettings.two_factor_auth.enabled)?_c('v-list',{attrs:{"color":"transparent"}},[_c('v-list-item',{on:{"click":function($event){_vm.codeDialogVisible = true}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5",domProps:{"textContent":_vm._s(
              _vm.$t(
                "page.profile_setting.security.authentication.authenticator.get_recovery_codes"
              )
            )}})],1),_c('v-list-item-icon',[_c('base-angle-icon',{staticClass:"flip-horizontal",attrs:{"width":"20","height":"20","fill":"secondary.darken2"}})],1)],1)],1):_vm._e()],1):_c('base-loading'),_c('profile-overview-list-security-authentication-google',{attrs:{"dialog":_vm.googleAuthDialogVisible},on:{"update:dialog":function($event){_vm.googleAuthDialogVisible=$event}}}),_c('profile-overview-list-security-authentication-backup-codes',{attrs:{"dialog":_vm.codeDialogVisible},on:{"update:dialog":function($event){_vm.codeDialogVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }