<template>
  <v-dialog
    scrollable
    v-model="dialog"
    v-bind="responsiveDialogProps"
    transition="slide-x-transition"
    content-class="search-modal small-level-two"
    :width="dialogWidth"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" :ripple="false" v-on="on">
        <v-list-item-icon class="me-2">
          <base-icon
            width="20"
            height="20"
            :name="icon"
            color="primary"
            className="d-flex align-center"
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            class="text-button info--text"
            v-text="title"
          />
          <v-list-item-subtitle
            v-if="subtitle"
            v-text="subtitle"
            class="body-2 secondary--text text--darken-2"
          />
        </v-list-item-content>
        <v-list-item-action>
          <base-angle-icon
            width="20"
            height="20"
            fill="secondary.darken2"
            class="flip-horizontal"
          />
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-card color="white">
      <v-toolbar flat height="60" color="white" class="flex-grow-0">
        <base-dialog-back-btn @go-back-clicked="dialog = false" />
        <v-toolbar-title class="text-h3">
          {{ toolbarTitle }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-0 d-flex flex-column" :style="cardStyle">
        <slot :dismiss="dismiss" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '../BaseIcon'
import BaseAngleIcon from '../BaseAngleIcon'
import BaseDialogBackBtn from '../BaseDialogBackBtn'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String
    },
    toolbarTitle: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'columns'
    },
    cardStyle: {
      type: String,
      default: 'margin-bottom:54px'
    },
    dialogWidth: {
      type: String,
      default: 'auto'
    }
  },
  computed: {
    ...mapGetters(['responsiveDialogProps'])
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    dismiss() {
      this.dialog = false
    }
  },
  components: {
    BaseIcon,
    BaseAngleIcon,
    BaseDialogBackBtn
  }
}
</script>
