<template>
  <v-card
    :ripple="false"
    :data-task="task.id"
    active-class="white"
    class="t-box-shadow task"
    :to="{ name: 'task', params: { tid: task.number } }"
    :style="`direction:${$vuetify.rtl ? 'rtl' : 'ltr'};color:white;`"
  >
    <v-card-text class="task-content">
      <v-chip
        outlined
        v-if="board && list"
        color="secondary lighten-2"
        class="mb-2"
      >
        <span
          class="body-2 text-truncate secondary--text text--darken-2"
          v-text="
            $t('page.task_dependencies_selection.task_board_and_list', {
              list: list.title,
              board: board.title
            })
          "
        />
      </v-chip>
      <task-list-section-task-color-label
        v-if="task.label && projectLabels"
        :label="projectLabels[task.label.id]"
      />
      <div class="d-flex justify-space-between">
        <div>
          <task-list-section-task-title :title="task.title" />
          <task-list-section-task-date
            :deadline="task.due_at"
            :start-time="task.starting_at"
          />
        </div>
        <task-list-section-task-notifications-and-number
          :task-number="task.number"
          :task-notifications="task.notifications_count"
        />
      </div>
      <task-list-section-task-tags
        v-if="projectTags"
        :tags="
          Object.values(projectTags).filter((tag) => task.tags.map((t) => t.id).indexOf(tag.id) > -1)
        "
      />
      <task-list-section-task-users-and-weight
        :users="users"
        :assignees="task.assignees"
        :progress="task.progress || -1"
        :weight="task.weight ? `${task.weight}` : ''"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskListSectionTaskDate from '@/components/project/TaskListSectionTaskDate'
import TaskListSectionTaskTags from '@/components/project/TaskListSectionTaskTags'
import TaskListSectionTaskTitle from '@/components/project/TaskListSectionTaskTitle'
import TaskListSectionTaskColorLabel from '@/components/project/TaskListSectionTaskColorLabel'
import TaskListSectionTaskUsersAndWeight from '@/components/project/TaskListSectionTaskUsersAndWeight'
import TaskListSectionTaskNotificationsAndNumber from '@/components/project/TaskListSectionTaskNotificationsAndNumber'
export default {
  props: {
    task: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    board: {
      type: Object
    },
    list: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['projectLabels', 'projectTags'])
  },
  components: {
    TaskListSectionTaskDate,
    TaskListSectionTaskTags,
    TaskListSectionTaskTitle,
    TaskListSectionTaskColorLabel,
    TaskListSectionTaskUsersAndWeight,
    TaskListSectionTaskNotificationsAndNumber
  }
}
</script>
<style lang="scss" scoped>
.task {
  cursor: move;
}
.v-badge {
  &.no-margin.v-badge--inline {
    .v-badge__wrapper {
      margin: 0;
    }
  }
  &.auto-height {
    .v-badge__badge {
      height: auto;
      padding: 3px 4px 2px 4px;
      font-size: 8px;
      min-width: 16px;
      font-weight: 600;
    }
  }
}
</style>
