<template>
  <v-dialog
    scrollable
    v-bind="responsiveDialogProps"
    transition="slide-x-transition"
    content-class="small-level-one"
    :value="createOrganizationDialogVisible"
    @click:outside="hideCreateOrganizationDialog"
  >
    <create-organization-card v-if="createOrganizationDialogVisible" />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CreateOrganizationCard from '@/components/organization/CreateOrganizationCard'
export default {
  computed: {
    ...mapGetters(['responsiveDialogProps', 'createOrganizationDialogVisible'])
  },
  methods: {
    ...mapActions(['hideCreateOrganizationDialog'])
  },
  components: {
    CreateOrganizationCard
  }
}
</script>
