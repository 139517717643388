<template>
  <v-app id="taskulu" class="taskulu">
    <slot />
    <the-toast />
  </v-app>
</template>
<script>
import TheToast from '@/components/TheToast'
export default {
  components: {
    TheToast
  }
}
</script>
