<template>
  <div
    class="
      d-flex
      align-center
      justify-center
      fill-height fill-width
      flex-column flex-grow-1
    "
  >
    <section class="d-flex flex-column align-center">
      <v-img
        :width="width"
        :src="imageUrl"
        :height="height"
        v-if="imageUrl.length"
      />
      <slot name="svg" />
      <h3
        class="
          mt-6
          text-h4
          fill-width
          text-center
          text--darken-2
          secondary--text
        "
        v-text="text"
      />
      <slot name="actions" class="mt-5" />
    </section>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '136'
    },
    height: {
      type: String,
      default: '106'
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    imageUrl() {
      const { name } = this
      if (name.length === 0) {
        return ''
      }
      const images = require.context(
        '../assets/img/illustrations/',
        false,
        /\.svg$/
      )
      return images('./' + this.name + '.svg')
    }
  }
}
</script>
