<template>
  <section class="px-4">
    <h2
      class="py-2 my-0 body-1 info--text"
      v-text="
        $t('page.project_settings.advanced_settings.delete_project.title')
      "
    />
    <p
      class="py-2 my-0 body-1 secondary--text text--darken-2"
      v-text="
        $t('page.project_settings.advanced_settings.delete_project.description')
      "
    />
    <p class="py-2 my-0 d-flex align-center justify-center">
      <v-btn
        depressed
        width="148"
        color="error"
        @click="dialog = true"
        v-text="
          $t('page.project_settings.advanced_settings.delete_project.button')
        "
      />
    </p>
    <base-dialog
      card-color="white"
      :dialog.sync="dialog"
      :hide-back-btn="true"
      card-toolbar-class="ms-2"
      content-class="small-level-two"
      :title="
        $t(
          'page.project_settings.advanced_settings.delete_project.confirmation.title'
        )
      "
    >
      <section class="px-4">
        <v-slide-x-transition hide-on-leave>
          <div v-show="step === 0">
            <p
              class="py-2 my-0 body-1 secondary--text text--darken-2"
              v-text="
                $t(
                  'page.project_settings.advanced_settings.delete_project.confirmation.description',
                  {
                    title: this.project.title,
                    interpolation: { escapeValue: false }
                  }
                )
              "
            />
            <p
              class="py-2 my-0 text-h6 error--text"
              v-text="
                $t(
                  'page.project_settings.advanced_settings.delete_project.confirmation.warning'
                )
              "
            />
            <p
              class="py-2 my-0 body-1 secondary--text text--darken-2"
              v-text="
                $t(
                  'page.project_settings.advanced_settings.delete_project.confirmation.delete_confirmation'
                )
              "
            />
            <v-form @submit.prevent="handleClickOnDelete" class="py-2">
              <v-text-field
                flat
                dense
                outlined
                v-model="name"
                :label="$t('form.labels.project_title')"
              />
            </v-form>
          </div>
        </v-slide-x-transition>
        <v-slide-x-transition hide-on-leave>
          <div v-show="step === 1">
            <p
              class="py-2 my-0 body-1 secondary--text text--darken-2"
              v-text="
                $t(
                  'page.project_settings.advanced_settings.delete_project.authentication.delete_confirmation'
                )
              "
            />
            <v-form
              class="py-2"
              ref="secondForm"
              v-model="secondFormIsValid"
              @submit.prevent="handleClickOnDelete"
            >
              <base-password
                :value.sync="password"
                :validate-on-blur="false"
                :rules="getPasswordRules()"
                :label="$t('form.labels.password')"
              />
            </v-form>
          </div>
        </v-slide-x-transition>
      </section>

      <template v-slot:cardActions>
        <v-card-actions class="pb-4 d-flex justify-space-between">
          <v-btn
            depressed
            width="136"
            @click="cancel"
            color="primary"
            :disabled="loading"
            v-text="$t('common.cancel')"
          />
          <v-btn
            text
            width="136"
            color="error"
            :loading="loading"
            @click="handleClickOnDelete"
            :disabled="loading || deleteButtonDisabled"
            v-text="
              $t(
                'page.project_settings.advanced_settings.delete_project.confirmation.delete_button'
              )
            "
          />
        </v-card-actions>
      </template>
    </base-dialog>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BasePassword from '@/components/BasePassword'

export default {
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  computed: {
    data() {
      return {
        auth_type: 'password',
        auth_token: this.password
      }
    },
    deleteButtonDisabled() {
      if (this.step === 0) {
        return this.name !== this.project.title
      }
      return !this.secondFormIsValid
    }
  },
  data() {
    return {
      step: 0,
      name: '',
      password: '',
      dialog: false,
      loading: false,
      secondFormIsValid: false
    }
  },
  methods: {
    ...mapActions(['deleteOrganizationProject']),
    handleClickOnDelete() {
      if (this.step === 0) {
        this.step += 1
        return
      }
      this.deleteProject()
    },
    async deleteProject() {
      this.loading = true
      try {
        await this.deleteOrganizationProject({
          pid: this.project.id,
          data: this.data
        })
        this.dialog = false
        this.$router.push({ path: '/' })
      } catch (error) {}
      this.loading = false
    },
    cancel() {
      this.name = ''
      this.password = ''
      this.step = 0
      this.dialog = false
      this.$refs.secondForm.resetValidation()
    }
  },
  components: {
    BaseDialog,
    BasePassword
  }
}
</script>
