<template>
  <fragment>
    <v-list-item
      :ripple="false"
      @click="openLink"
      :style="`min-height:auto;cursor:${
        notification.read && path === null ? 'default' : 'pointer'
      }`"
      :class="notification.read ? 'background' : 'white'"
    >
      <v-list-item-avatar
        size="40"
        color="error lighten-5"
        style="overflow: visible"
        v-if="notification.creator"
        class="d-flex align-center justify-center"
      >
        <v-badge
          right
          avatar
          bottom
          overlap
          bordered
          color="white"
          offset-x="15"
          offset-y="15"
          v-if="notification.creator.type === 'User'"
        >
          <template v-slot:badge>
            <v-avatar>
              <base-icon
                width="12"
                height="12"
                :name="icon.name"
                :color="icon.color"
                className="d-flex align-center justify-center"
              />
            </v-avatar>
          </template>
          <base-avatar
            :size="40"
            :title="notification.creator.name"
            :image="
              notification.creator.avatar
                ? notification.creator.avatar.medium
                : ''
            "
          />
        </v-badge>
        <base-icon
          color="error"
          name="exclamation-triangle"
          v-if="notification.creator.type === 'Bot'"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle
          v-html="notification.note_html"
          class="info--text body-2"
        />
        <v-list-item-title class="body-2 secondary--text text--darken-2">
          <base-date-time
            format="medium_with_time"
            :date="notification.created_at"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider v-if="hasDivider" class="secondary lighten-2" />
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseAvatar from '@/components/BaseAvatar'
import BaseDateTime from '@/components/BaseDateTime'
import { notificationTypeIcons } from '@/constants/icons'
const noRedirectionActions = [
  'organization_archived',
  'organization_deleted',
  'organization_membership_created',
  'organization_membership_deleted',
  'project_archived',
  'project_deleted',
  'project_membership_deleted',
  'task_deleted',
  'task_moved'
]
export default {
  props: {
    notification: {
      type: Object,
      required: true
    },
    hasDivider: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['organizationUsers']),
    taskTab() {
      const { action } = this.notification
      if (action.indexOf('attachment') > -1) {
        return 'file'
      }
      if (action.indexOf('checklist') > -1) {
        return 'checklist'
      }
      return null
    },
    taskHash() {
      const { action } = this.notification
      if (
        action.indexOf('assigned') > -1 ||
        action.indexOf('task_assignees') > -1
      ) {
        return 'task_assignees'
      }
      if (action.indexOf('task_tags') > -1) {
        return 'task_tags'
      }
      if (action.indexOf('task_dependees') > -1) {
        return 'task_dependees'
      }
      if (action.indexOf('task_dependents') > -1) {
        return 'task_dependents'
      }
      return null
    },
    taskPath() {
      const { parent, grand_parent: grandParent } = this.notification
      const path = `/projects/${grandParent.id}/kanban/tasks/${parent.id}`
      if (this.taskTab) {
        return `${path}?tab=${this.taskTab}`
      }
      if (this.taskHash) {
        return `${path}#${this.taskHash}`
      }
      return path
    },
    path() {
      const { action, parent, grand_parent: grandParent } = this.notification
      if (noRedirectionActions.indexOf(action) > -1) {
        return null
      }
      if (
        parent &&
        grandParent &&
        parent.type === 'Task' &&
        grandParent.type === 'Project'
      ) {
        return this.taskPath
      }
      if (grandParent && grandParent.type === 'Project') {
        const projectPath = `/projects/${grandParent.id}`
        if (action === 'project_owernship_gained') {
          return `${projectPath}/settings`
        }
        return `${projectPath}/kanban`
      }
      return null
    },
    icon() {
      const { action } = this.notification
      const icon = notificationTypeIcons[action]
      return {
        name: icon ? icon.name : 'file-question',
        color: icon ? icon.color : 'primary'
      }
    }
  },
  methods: {
    ...mapActions(['markNotificationAsRead']),
    openLink() {
      if (!this.notification.read) {
        this.markNotificationAsRead(this.notification.id)
      }
      if (!this.path) {
        return
      }
      if (this.$route.path !== this.path) {
        this.$router.push({ path: this.path })
      }
    }
  },
  components: {
    BaseIcon,
    BaseAvatar,
    BaseDateTime
  }
}
</script>
