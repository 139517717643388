<template>
  <settings-dialog-list v-if="project && projectAbilities" />
  <base-loading v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseLoading from '@/components/BaseLoading'
import SettingsDialogList from '@/components/project/SettingsDialogList'
export default {
  computed: {
    ...mapGetters(['project', 'projectAbilities'])
  },
  components: {
    BaseLoading,
    SettingsDialogList
  },
  methods: {
    ...mapActions(['getProject', 'setProject', 'getProjectAbilities', 'getProjectMemberships']),
    async getData() {
      const { pid } = this.$route.params
      if (this.project && this.project.id === pid) {
        return
      }
      await this.setProject(null)
      await this.getProjectAbilities(pid)
      await this.getProjectMemberships(pid)
      await this.getProject(pid)
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    '$route.params.pid'() {
      this.getData()
    }
  }
}
</script>
