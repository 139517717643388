<template>
  <v-container fluid class="d-flex align-center justify-end">
    <v-row :style="`max-width:${isMobile ? 'auto' : '300px'}`">
      <v-col cols="4" class="ps-0">
        <v-btn
          block
          outlined
          color="secondary"
          :disabled="loading"
          v-text="$t('common.cancel')"
          @click="$emit('cancel-clicked')"
        />
      </v-col>
      <v-col cols="8" class="pe-0">
        <v-btn
          block
          depressed
          v-text="okText"
          :color="okColor"
          :loading="loading"
          @click="$emit('ok-clicked')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isMobile'])
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    okText: {
      type: String,
      default: ''
    },
    okColor: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
