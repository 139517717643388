<template>
  <div class="avatars overlapping">
    <base-avatar
      :title="item.name"
      :size="avatarSize"
      :text-size="textSize"
      :key="`avatar_${index}`"
      :avatar-style="getAvatarStyle(index)"
      v-for="(item, index) in list.slice(0, limit)"
      :image="item.avatar ? item.avatar.medium : ''"
    />
    <template v-if="list.length > limit">
      <span class="body-2 secondary--text" v-if="!circularMore">
        <template v-if="plainMore">+{{ getLocalizedNumber(list.length - limit) }}</template>
        <template v-else>
          {{
            $t('common.plus_n_more', {
              n: getLocalizedNumber(list.length - limit)
            })
          }}
        </template>
      </span>
      <base-avatar
        v-else
        color="secondary"
        :size="avatarSize"
        :text-size="textSize"
        :use-whole-title="true"
        :avatar-style="getAvatarStyle(-1)"
        :title="`+${getLocalizedNumber(list.length - limit)}`"
      />
    </template>
  </div>
</template>

<script>
import BaseAvatar from '@/components/BaseAvatar'
export default {
  props: {
    avatarSize: {
      type: Number,
      default: 48
    },
    avatarColor: {
      type: String,
      default: 'accent'
    },
    list: {
      type: Array,
      default: () => []
    },
    imageKey: {
      type: String,
      default: 'image'
    },
    titlesKey: {
      type: String,
      default: 'title'
    },
    overlapping: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 5
    },
    circularMore: {
      type: Boolean,
      default: false
    },
    textSize: {
      type: String,
      default: 'headline'
    },
    plainMore: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getAvatarStyle(index) {
      if (!this.overlapping || index === 0) {
        return ''
      }
      const direction = this.$vuetify.rtl ? 'right' : 'left'
      return `margin-${direction}:-${this.avatarSize / 1.8}px;`
    }
  },
  components: {
    BaseAvatar
  }
}
</script>
