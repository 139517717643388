import store from '@/store'
import SignIn from '@/views/SignIn'
import SignUp from '@/views/SignUp'
import ResetPassword from '@/views/ResetPassword'
import NotFoundComponent from '@/views/PageNotFound'
import SelectOrganization from '@/views/SelectOrganization'
import CreateOrganization from '@/views/CreateOrganization'
import ForgotOrganization from '@/views/ForgotOrganization'
import GoogleAuthRedirect from '@/views/GoogleAuthRedirect'
import GoogleAuthCallback from '@/views/GoogleAuthCallback'
import AccountVerification from '@/views/AccountVerification'
import GoogleCalendarAuthRedirect from '@/views/GoogleCalendarAuthRedirect'
import GoogleCalendarAuthCallback from '@/views/GoogleCalendarAuthCallback'
const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/select-organization')
}
export default [
  {
    path: '/',
    redirect: '/select-organization'
  },
  {
    path: '/select-organization',
    name: 'SelectOrganization',
    meta: {
      layout: 'plain',
      title: 'Select Organization'
    },
    component: SelectOrganization
  },
  {
    path: '/signin',
    name: 'SignIn',
    meta: {
      layout: 'plain',
      title: 'Sign In'
    },
    component: SignIn
  },
  {
    path: '/signup',
    name: 'SignUp',
    meta: {
      layout: 'plain',
      title: 'Sign Up'
    },
    component: SignUp
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      title: 'Reset Password',
      layout: 'plain'
    },
    component: ResetPassword
  },
  {
    path: '/account-verification',
    name: 'AccountVerification',
    meta: {
      title: 'Account Verification',
      layout: 'plain'
    },
    component: AccountVerification
  },
  {
    path: '/create-organization',
    name: 'CreateOrganization',
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Create Organization',
      layout: 'plain'
    },
    component: CreateOrganization
  },
  {
    path: '/forgot-organization',
    name: 'ForgotOrganization',
    meta: {
      title: 'Forgot Organization',
      layout: 'plain'
    },
    component: ForgotOrganization
  },
  {
    path: '/google-auth-redirect',
    name: 'GoogleAuthRedirect',
    meta: {
      title: 'Google Auth Redirect',
      layout: 'plain'
    },
    component: GoogleAuthRedirect
  },
  {
    path: '/google-auth-callback',
    name: 'GoogleAuthCallback',
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Google Auth Callback',
      layout: 'plain'
    },
    component: GoogleAuthCallback
  },
  {
    path: '/google-calendar-auth-redirect',
    name: 'GoogleCalendarAuthRedirect',
    meta: {
      title: 'Google Calendar Auth Redirect',
      layout: 'plain'
    },
    component: GoogleCalendarAuthRedirect
  },
  {
    path: '/google-calendar-auth-callback',
    name: 'GoogleCalendarAuthCallback',
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Google Calendar Auth Callback',
      layout: 'plain'
    },
    component: GoogleCalendarAuthCallback
  },
  {
    path: '*',
    name: 'notFound',
    meta: {
      title: 'Not Found',
      layout: 'plain'
    },
    component: NotFoundComponent
  }
]
