<template>
  <div class="d-flex flex-row justify-space-between">
    <div class="d-flex justify-start">
      <slot />
    </div>
    <div class="d-flex justify-end">
      <v-btn
        text
        small
        outlined
        depressed
        class="me-2"
        :disabled="loading"
        color="secondary darken-2"
        v-text="$t('common.cancel')"
        @click="$emit('cancel-clicked')"
      />
      <v-btn
        small
        depressed
        width="38"
        color="primary"
        :loading="loading"
        v-text="$t('common.save')"
        :disabled="buttonDisabled"
        @click="$emit('save-clicked')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    buttonDisabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
