<template>
  <div style="z-index: 3" class="fill-width stick-to-top py-2">
    <base-select-to-bottom-sheet
      :items="items"
      class-name="white"
      :value.sync="board"
      @change="board = $event"
    />
  </div>
</template>

<script>
import BaseSelectToBottomSheet from '@/components/BaseSelectToBottomSheet'

export default {
  props: {
    boards: {
      type: Array,
      required: true
    }
  },
  computed: {
    items() {
      return [
        {
          id: 'none',
          value: 'none',
          text: 'None'
        },
        ...this.boards.map((board) => ({
          id: board.id,
          value: board.id,
          text: board.title
        }))
      ]
    },
    selectedBoard() {
      if (this.board.length === 0) {
        return null
      }
      const found = this.items.find((item) => item.id === this.board)
      return !found ? null : found.title
    }
  },
  methods: {
    onScroll(e) {
      this.scrolling = e.target.scrollTop > 0
    },
    handleClick() {
      this.sheet = true
    }
  },
  components: {
    BaseSelectToBottomSheet
  },
  data() {
    return {
      board: 'none',
      sheet: false,
      scrolling: false
    }
  },
  watch: {
    board(newValue) {
      this.sheet = false
      if (newValue === 'none') {
        this.$emit('go-to-top', newValue)
        return
      }
      this.$emit('go-to-board', newValue)
    }
  }
}
</script>
