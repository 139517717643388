<template>
  <search-dialog-list-item
    icon="balance-scale"
    :subtitle="subtitle"
    :title="$t('common.task_weight')"
    :toolbar-title="$t('common.task_weight')"
    v-slot="{ dismiss }"
    card-style="margin-bottom: 25px"
  >
    <section class="px-4 pt-4">
      <search-dialog-weight-input :value.sync="weightFrom" :suffix="suffix" />
      <search-dialog-weight-input
        title="to"
        :suffix="suffix"
        :value.sync="weightTo"
      />
    </section>
    <section class="d-flex px-4">
      <v-btn
        depressed
        color="primary"
        v-text="$t('common.done')"
        @click="dismiss"
      />
    </section>
  </search-dialog-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import SearchDialogListItem from '@/components/search/SearchDialogListItem'
import SearchDialogWeightInput from '@/components/search/SearchDialogWeightInput'
export default {
  props: {
    from: {
      required: true
    },
    to: {
      required: true
    }
  },
  computed: {
    ...mapGetters(['projectFeatures']),
    subtitle() {
      const { weightFrom, weightTo, suffix } = this
      if (weightFrom && weightTo) {
        return `${weightFrom} - ${weightTo} ${suffix}`
      }
      return weightFrom ? `${weightFrom} ${suffix}` : ''
    },
    suffix() {
      const { projectFeatures } = this
      if (projectFeatures) {
        return ''
      }
      return projectFeatures.weight.settings.unit
    }
  },
  data() {
    return {
      weightTo: null,
      weightFrom: null
    }
  },
  components: {
    SearchDialogListItem,
    SearchDialogWeightInput
  },
  watch: {
    weightTo(newValue) {
      if (newValue === '') this.$emit('update:to', null)
      else this.$emit('update:to', newValue)
    },
    weightFrom(newValue) {
      if (newValue === '') this.$emit('update:from', null)
      else this.$emit('update:from', newValue)
    }
  }
}
</script>
