var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"dialog":_vm.dialog,"card-color":"white","content-class":"small-level-one","title":_vm.$t('page.project_settings.status.title')},on:{"go-back-clicked":function($event){return _vm.$emit('update:dialog', false)}}},[_c('template',{slot:"toolbarActions"},[(_vm.items.length < 10 && !_vm.isMobile)?_c('v-btn',{staticClass:"me-1",attrs:{"text":"","small":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('common.add_status'))},on:{"click":_vm.handleCreate}}):_vm._e()],1),(!_vm.fetching && _vm.projectStatuses)?_c('v-card',{staticClass:"white info--text d-flex",attrs:{"tile":"","flat":"","height":"100%"}},[_c('v-card-text',{staticClass:"flex-grow-1 d-flex pt-0"},[_c('section',{staticClass:"flex-grow-1 pa-4 secondary lighten-5 rounded-sm"},[_c('draggable',{attrs:{"group":"items","handle":".handle"},on:{"change":_vm.updatePosition},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},_vm._l((_vm.items),function(item,index){return _c('settings-dialog-list-draggable-item',{key:item.value,attrs:{"item":item,"loading":_vm.loading,"disabled":_vm.items.length < 4,"is-last":index === _vm.items.length - 1},on:{"save-clicked":_vm.handleEdit,"delete-clicked":_vm.confirmDelete}},[_c('template',{slot:"lastItemContent"},[_c('v-card',[_c('v-card-title',{staticClass:"text-h3 py-3",domProps:{"textContent":_vm._s(
                    _vm.$t(
                      'page.project_settings.status.status_information.title'
                    )
                  )}}),_c('v-card-text',{staticClass:"body-2 secondary--text text--darken-2",domProps:{"innerHTML":_vm._s(
                    _vm.$t(
                      'page.project_settings.status.status_information.content'
                    )
                  )}})],1)],1)],2)}),1)],1)]),_c('base-confirm-dialog',{attrs:{"loading":_vm.loading,"ok-color":"error base","ok-text":_vm.$t('common.delete'),"dialog":_vm.confirmatioDialog,"cancel-color":"secondary darken-2","cancel-text":_vm.$t('common.cancel'),"title":_vm.$t('page.project_settings.status.delete_confirmation.title')},on:{"ok-clicked":_vm.deleteStatus,"update:dialog":function($event){_vm.confirmatioDialog=$event}}},[_c('section',{domProps:{"innerHTML":_vm._s(
          _vm.$t('page.project_settings.status.delete_confirmation.content', {
            title: _vm.currentStatus ? _vm.currentStatus.value : '',
            interpolation: { escapeValue: false }
          })
        )}})]),(_vm.items.length < 10 && _vm.isMobile)?_c('v-btn',{attrs:{"fab":"","bottom":"","fixed":"","width":"56","height":"56","direction":"top","color":"primary","left":_vm.$vuetify.rtl,"right":!_vm.$vuetify.rtl},on:{"click":_vm.handleCreate}},[_c('unicon',{attrs:{"name":"plus","fill":"#ffffff"}})],1):_vm._e(),_c('base-inline-form',{attrs:{"card-text-class-name":"px-4","value":_vm.createStatusDialog,"title":_vm.$t('common.add_status')},on:{"closed":function($event){return _vm.toggle(false)}}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSave($event)}}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","autofocus":"","type":"text","label":_vm.$t('form.labels.status_title')},model:{value:(_vm.statusTitle),callback:function ($$v) {_vm.statusTitle=$$v},expression:"statusTitle"}})],1),_c('base-inline-form-actions',{attrs:{"loading":_vm.loading,"ok-text":_vm.$t('common.done')},on:{"ok-clicked":_vm.handleSave,"cancel-clicked":function($event){return _vm.toggle(false)}}})],1)],1):_c('base-loading')],2)}
var staticRenderFns = []

export { render, staticRenderFns }