<template>
  <v-btn
    fab
    dark
    small
    bottom
    absolute
    width="48"
    height="48"
    color="white"
    :left="$vuetify.rtl"
    :right="!$vuetify.rtl"
    @click="$emit('update:value', true)"
  >
    <base-icon v-if="!loading" name="camera-plus" color="primary" />
    <v-progress-circular
      v-else
      size="16"
      :width="1"
      indeterminate
      color="primary"
    />
  </v-btn>
</template>

<script>
import BaseIcon from '../BaseIcon'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseIcon
  }
}
</script>
