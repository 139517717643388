<template>
  <centered-layout>
    <template v-slot:appbar>
      <v-app-bar-nav-icon @click="$router.push({ path: '/select-organization' })">
        <base-angle-icon name="t-arrow" width="16" height="16" />
      </v-app-bar-nav-icon>
      <v-toolbar-title
        class="pa-0 flex-grow-1 d-flex justify-center"
        :style="`margin-${$vuetify.rtl ? 'right' : 'left'}:-38px`"
      >
        <base-logo />
      </v-toolbar-title>
    </template>
    <div class="d-flex flex-column justify-center fill-width flex-grow-1">
      <sessions-forgot-organization-form
        @form-submitted="handleClickOnSubmit"
        :form-is-valid.sync="formIsValid"
        :email.sync="form.email"
        :loading="loading"
        v-if="step === 1"
      />
      <div class="d-flex flex-column fill-width align-center" v-if="step === 2">
        <v-avatar color="success lighten-5" size="64">
          <base-icon name="check" color="success.base" width="34" height="34" />
        </v-avatar>
        <p class="body-2 secondary--text text--darken-2 mt-4 mb-1">
          {{ $t('page.forgot_organization.please_check_email') }}
        </p>
      </div>
    </div>
    <div class="flex-grow-0 mb-6 fill-width">
      <v-btn
        block
        depressed
        color="primary"
        v-if="step === 1"
        :loading="loading"
        :disabled="!formIsValid"
        @click="handleClickOnSubmit"
      >
        {{ $t('common.submit') }}
      </v-btn>
      <v-btn
        block
        depressed
        color="primary"
        v-if="step === 2"
        @click="$router.push({ path: 'select-organization' })"
      >
        {{ $t('common.back_to_login') }}
      </v-btn>
    </div>
  </centered-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseLogo from '@/components/BaseLogo'
import BaseIcon from '@/components/BaseIcon'
import CenteredLayout from '@/layouts/CenteredLayout'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import SessionsForgotOrganizationForm from '@/components/sessions/SessionsForgotOrganizationForm'

export default {
  computed: {
    ...mapGetters(['loading'])
  },
  data() {
    return {
      formIsValid: false,
      form: {
        email: ''
      },
      step: 1
    }
  },
  methods: {
    ...mapActions(['authRequest', 'displayResponseError']),
    handleClickOnSubmit() {
      const url = '/users/orgs/send'
      const data = {
        email: this.form.email
      }
      const method = 'post'
      const successCallback = () => {
        this.step = 2
      }
      const errorCallback = (error) => {
        this.displayResponseError(error)
      }
      this.authRequest({
        url, data, method, successCallback, errorCallback
      })
    }
  },
  components: {
    BaseLogo,
    BaseIcon,
    BaseAngleIcon,
    CenteredLayout,
    SessionsForgotOrganizationForm
  }
}
</script>
