<template>
  <div
    v-resize="setHeight"
    class="overflow-y-auto relative"
    :style="`height:${height}px`"
    :class="{ 'd-flex': !files.length }"
    @dragenter.prevent.stop="onDragEnter"
    @dragover.prevent.stop="onDragEnter"
    @drop.prevent.stop="onDrop"
    @dragleave.prevent.stop="onDragLeave"
  >
    <v-overlay
      absolute
      color="#fff"
      :z-index="7"
      opacity="0.92"
      :value="!overlay && dragged"
      class="dropzone-child"
    >
    </v-overlay>
    <v-overlay
      color="#fff"
      :z-index="5"
      opacity="0.92"
      :value="overlay"
      class="rounded-t-lg"
    >
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>
    <base-empty-state
      class="full"
      v-if="!files.length"
      :text="$t('common.file', { count: 0 })"
    />
    <div class="px-4 pt-4 pb-16" v-else>
      <v-card
        max-width="100%"
        color="transparent"
        :key="attachment.id"
        class="mx-auto t-box-shadow"
        :class="{ 'mt-2': index > 0 }"
        v-for="(attachment, index) in files"
      >
        <v-list one-line>
          <task-dialog-files-list-item
            :index="index"
            :users="users"
            :priority="priority"
            :attachment="attachment"
            @attachment-created="removeAttachmentFromUploadList"
            @attachment-upload-canceled="removeAttachmentFromUploadList"
          />
        </v-list>
      </v-card>
    </div>
    <base-confirm-dialog
      cancelColor="primary"
      okColor="error base"
      :dialog.sync="dialog"
      :okText="$t('common.yes')"
      :cancelText="$t('common.no')"
      @ok-clicked="cancelAllUploads"
      :title="$t('page.tasks.file_upload_cancel_confirmation.title')"
      :content="$t('page.tasks.file_upload_cancel_confirmation.content')"
    />

    <task-dialog-files-add-fab
      :is-mobile="isMobile"
      @files-changed="handleFileChange"
      @pick-from-google-clicked="uploadFromDrive"
      @pick-from-dropbox-clicked="createDropboxPicker"
      v-if="priority >= abilitiesPriorityMap.Task.create_attachment"
    />
  </div>
</template>

<script>
import randomstring from 'randomstring'
import { mapActions, mapGetters } from 'vuex'
import { driveAccessTokenName } from '@/utils/auth'
import BaseEmptyState from '@/components/BaseEmptyState'
import TaskDialogFilesAddFab from './TaskDialogFilesAddFab'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'
import TaskDialogFilesListItem from '@/components/task/TaskDialogFilesListItem'

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

export default {
  props: {
    user: {
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    attachments: {
      type: Array,
      required: true
    },
    priority: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'task',
      'project',
      'isMobile',
      'driveAuthUri',
      'driveAccessToken',
      'abilitiesPriorityMap',
      'organizationSubdomain'
    ]),
    files() {
      return [...this.uploading, ...this.attachments]
    },
    driveRedirectUri() {
      const path = 'google-auth-redirect'
      const { protocol, port } = location
      if (process.env.NODE_ENV === 'development') {
        return `${protocol}//localhost:${port}/${path}`
      }
      const url = location.origin.replace(`${this.organizationSubdomain}.`, '')
      return `${url}/${path}`
    },
    pid() {
      return this.project.id
    },
    tid() {
      return this.task.id
    }
  },
  data() {
    return {
      height: 0,
      uploading: [],
      dialog: false,
      overlay: false,
      pickerApiLoaded: false,
      dragged: false,
      dragElement: null
    }
  },
  methods: {
    ...mapActions([
      'addTaskAttachment',
      'setDriveAccessToken',
      'getGoogleDriveAccessToken'
    ]),
    createAttachment(file) {
      const now = new Date().getTime() / 1000
      const newAttachment = {
        creator: {
          id: this.user.id
        },
        progress: 0,
        uploaded: file,
        name: file.name,
        thumbnail: null,
        size: file.size,
        uploading: true,
        hasError: false,
        created_at: now,
        cancelUploadObj: {},
        id: `${randomstring.generate(12)}-${now}`
      }
      this.uploading.push(newAttachment)
    },
    handleFileChange(e) {
      const { files } = e.target
      if (!files) {
        return
      }
      files.forEach((file) => {
        this.createAttachment(file)
      })
    },
    removeAttachmentFromUploadList(id) {
      const index = this.uploading.findIndex((item) => item.id === id)
      if (index < 0) {
        return
      }
      this.uploading.splice(index, 1)
    },
    cancelAllUploads() {
      const { uploading } = this
      for (let i = 0; i < uploading.length; i++) {
        uploading[i].cancelUploadObj.cancel()
      }
      this.uploading = []
      this.dialog = false
      this.$emit('go-back-checked')
    },
    checkFileUploads() {
      if (this.uploading.length === 0) {
        return this.$emit('go-back-checked')
      }
      this.dialog = true
    },
    async attach(data) {
      try {
        await this.addTaskAttachment({
          pid: this.pid,
          data: {
            ...data,
            parent_type: 'Task',
            parent_id: this.tid
          }
        })
        this.$emit('attachment-created', this.attachment.id)
      } catch (error) {}
    },
    pickerCallback(data) {
      if (
        data[window.google.picker.Response.ACTION] ===
        window.google.picker.Action.PICKED
      ) {
        for (const file of data.docs) {
          this.attach({
            source_type: 'Drive',
            parent_type: 'Task',
            parent_id: this.tid,
            file_attributes: {
              id: file.id
            }
          })
        }
      }
    },
    createPicker() {
      this.overlay = false
      if (this.pickerApiLoaded && this.driveAccessToken) {
        const sharedView = new window.google.picker.DocsView()
        sharedView.setOwnedByMe(false)
        const mineView = new window.google.picker.DocsView()
        mineView.setOwnedByMe(true)
        const generalView = new window.google.picker.DocsView()
        generalView.setParent('root').setIncludeFolders(true)
        const picker = new window.google.picker.PickerBuilder()
          .addView(mineView)
          .addView(sharedView)
          .addView(generalView)
          .setOAuthToken(this.driveAccessToken)
          .setDeveloperKey(process.env.VUE_APP_GOOGLE_DEVELOPER_KEY)
          .setCallback(this.pickerCallback)
          .disableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
          .setLocale('en')
          .setOrigin(window.location.protocol + '//' + window.location.host)
          .build()
        picker.setVisible(true)
      }
    },
    createDropboxPicker() {
      const { Dropbox } = window
      this.overlay = false
      if (!Dropbox) {
        console.log('Dropbox sdk not loaded!')
        return
      }
      const options = {
        success: (files) => {
          for (const file of files) {
            this.attach({
              source_type: 'Dropbox',
              parent_type: 'Task',
              parent_id: this.tid,
              file_attributes: {
                id: file.id,
                name: file.name,
                size: file.bytes,
                view_url: file.link
              }
            })
          }
        },
        cancel: () => {},
        linkType: 'preview',
        multiselect: true
      }
      Dropbox.choose(options)
    },
    async uploadFromDrive() {
      this.overlay = true
      try {
        await this.getGoogleDriveAccessToken({
          redirect_uri: this.driveRedirectUri
        })
        if (this.driveAccessToken) {
          return this.createPicker()
        }
        window.open(this.driveAuthUri, '_blank')
      } catch (error) {
        this.overlay = false
      }
    },
    handleLocalStorageChange(event) {
      const { key, newValue } = event
      if (key === driveAccessTokenName) {
        this.setDriveAccessToken(newValue)
        this.createPicker()
      }
    },
    onDrop(e) {
      this.dragged = false
      const { files } = e?.dataTransfer
      if (!files) {
        return
      }
      files.forEach((file) => {
        this.createAttachment(file)
      })
    },
    onDragEnter(event) {
      this.dragElement = event.target
      if (!this.dragged) this.dragged = true
    },
    onDragLeave(event) {
      if (this.dragElement === event.target) this.dragged = false
    },
    setHeight() {
      const wrapper = document.getElementById('base_dialog_text')
      this.height = wrapper ? wrapper.clientHeight : 100
    },
    preventDefaults(e) {
      e.preventDefault()
    }
  },
  created() {
    window.addEventListener('storage', this.handleLocalStorageChange)
    if (!window.gapi) {
      console.log('Google API not loaded')
    } else {
      console.log('Google API loaded')
      window.gapi.load('auth2')
      window.gapi.load('picker', () => {
        this.pickerApiLoaded = true
      })
    }
    events.forEach((eventName) => {
      document.body.addEventListener(eventName, this.preventDefaults)
    })
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.handleLocalStorageChange)
    events.forEach((eventName) => {
      document.body.removeEventListener(eventName, this.preventDefaults)
    })
  },
  mounted() {
    this.setHeight()
  },
  components: {
    BaseEmptyState,
    BaseConfirmDialog,
    TaskDialogFilesAddFab,
    TaskDialogFilesListItem
  }
}
</script>
