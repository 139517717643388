<template>
  <base-inline-form
    :value="value"
    :title="title"
    card-text-class-name="px-4"
    @closed="$emit('update:value', false)"
  >
    <v-form @submit.prevent="handleSubmit">
      <v-text-field
        flat
        dense
        outlined
        required
        autofocus
        hide-details
        :value="input"
        :label="label"
        @input="$emit('update:input', $event)"
      />
    </v-form>
    <base-inline-form-actions
      :loading="loading"
      @ok-clicked="handleSubmit"
      :ok-text="$t('common.done')"
      @cancel-clicked="$emit('cancel-clicked')"
    />
  </base-inline-form>
</template>

<script>
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseInlineFormActions from '@/components/BaseInlineFormActions'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    input: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleSubmit() {
      if (!this.input || !this.input.length) {
        return
      }
      this.$emit('done-clicked')
    }
  },
  components: {
    BaseInlineForm,
    BaseInlineFormActions
  }
}
</script>
