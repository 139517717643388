<template>
  <centered-layout>
    <template v-slot:appbar>
      <v-toolbar-title class="pa-0 flex-grow-1 d-flex justify-center">
        <base-logo />
      </v-toolbar-title>
    </template>
    <create-organization-form
      v-if="!fetching"
      :loading="loading"
      @get-started-button-hit="handleSubmit"
      @image-uploaded="setOrganizationImage($event)"
    />
    <base-loading v-else />
  </centered-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseLogo from '@/components/BaseLogo'
import BaseLoading from '@/components/BaseLoading'
import CenteredLayout from '@/layouts/CenteredLayout'
import CreateOrganizationForm from '@/components/organization/CreateOrganizationForm'
export default {
  computed: {
    ...mapGetters(['organization', 'organizations'])
  },
  data() {
    return {
      loading: false,
      fetching: true
    }
  },
  methods: {
    ...mapActions(['addOrganiztion', 'getOrganizations']),
    goToOnboarding(subdomain) {
      window.location = `${location.protocol}//${subdomain}.${location.host}/onboarding`
    },
    async handleSubmit(form) {
      const { title, subdomain, logo_file_id: id } = form
      const logoFileObj = id ? { logo_file_id: id } : {}
      this.loading = true
      try {
        await this.addOrganiztion({
          title,
          subdomain,
          ...logoFileObj
        })
        this.goToOnboarding(subdomain)
      } catch (error) {
        this.loading = false
      }
    },
    async getData() {
      await this.getOrganizations({
        include: ['projects']
      })
      if (this.organizations && this.organizations.length) {
        this.goToOnboarding(this.organizations[0].subdomain)
      } else {
        this.fetching = false
      }
    }
  },
  created() {
    this.getData()
  },
  components: { BaseLogo, BaseLoading, CenteredLayout, CreateOrganizationForm }
}
</script>
