import request from '@/api/axios'

export const fetchStatuses = pid =>
  request({
    url: `/orgs/#oid/projects/${pid}/statuses`,
    method: 'get'
  })

export const createStatus = (pid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}/statuses`,
    method: 'post',
    data
  })

export const patchStatus = (sid, data) =>
  request({
    url: `/orgs/#oid/statuses/${sid}`,
    method: 'patch',
    data
  })

export const deleteStatus = sid =>
  request({
    url: `/orgs/#oid/statuses/${sid}`,
    method: 'delete'
  })
