<template>
  <v-card-actions class="white">
    <div
      @click="openEditor"
      v-show="!editorActive"
      class="fill-width align-center ps-4 pe-2"
      :class="{ 'd-flex': !editorActive }"
    >
      <section class="caption secondary--text flex-grow-1">
        <span v-text="$t('page.task_comments.comment_editor_placeholder')" />
      </section>
      <task-dialog-comments-footer-button :disabled="true" />
    </div>
    <v-card tile flat v-show="editorActive" class="fill-width">
      <v-card-text class="flex-grow-1 py-2 px-0">
        <div ref="quill" class="has-max-height" />
      </v-card-text>
      <v-card-actions class="flex-column align-start">
        <base-file-preview
          v-if="file"
          :file="file"
          class="px-2 mb-2"
          @remove-file-clicked="file = null"
        />
        <div
          class="
            d-flex
            align-center
            ql-toolbar-wrpper
            fill-width
            overflow-hidden
          "
        >
          <div
            id="toolbar"
            class="ql-toolbar flex-grow-1"
            :class="{ 'is-mobile d-flex overflow-x-auto': isMobile }"
          >
            <v-btn
              fab
              depressed
              width="24"
              height="24"
              color="secondary"
              v-if="editingCommentId"
              @click="handleEditCancel"
            >
              <base-icon width="12" height="12" name="times" color="white" />
            </v-btn>
            <v-btn
              icon
              small
              class="ql-attachment"
              v-show="editingCommentId === null"
            >
              <base-icon width="20" height="20" color="info" name="paperclip" />
            </v-btn>
            <v-btn icon small value="@" class="ql-member">
              <base-icon name="at" width="20" height="20" color="info" />
            </v-btn>
            <v-btn icon small value="#" class="ql-task">
              <base-icon
                width="20"
                height="20"
                color="info"
                name="t-hash-tag"
              />
            </v-btn>
            <base-quill-basic-toolbar />
          </div>
          <task-dialog-comments-footer-button
            :loading="loading"
            class="flex-grow-0"
            :disabled="editorIsEmpty && file === null"
            @send-clicked="handleSendComment"
          />
        </div>
      </v-card-actions>
      <base-inline-form
        :value.sync="linkBottomSheet"
        :title="$t('common.add_link')"
      >
        <v-form class="pt-10" ref="linkForm" @submit.prevent="addLink">
          <v-text-field
            dense
            outlined
            autofocus
            type="text"
            v-model="link.url"
            validate-on-blur
            :rules="getLinkUrlRules()"
            :label="$t('form.labels.link_url')"
          />
        </v-form>
        <v-btn
          block
          depressed
          color="primary"
          @click="addLink"
          v-text="$t('common.add')"
        />
      </base-inline-form>
      <base-inline-form
        :title="$t('common.send')"
        card-text-class-name="px-0"
        :value.sync="attachmentBottomSheet"
      >
        <base-file-upload-source-list
          @local-files-added="handleLocalAttachment"
          @drive-files-added="handleDriveAttachment"
          @dropbox-files-added="handleDropboxAttachment"
        />
      </base-inline-form>
    </v-card>
  </v-card-actions>
</template>

<script>
import 'quill-mention'
import quill from 'quill'
import BaseIcon from '@/components/BaseIcon'
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseFilePreview from '@/components/BaseFilePreview'
import BaseQuillBasicToolbar from '@/components/BaseQuillBasicToolbar'
import BaseFileUploadSourceList from '@/components/BaseFileUploadSourceList'
import {
  mentionsTaskOrMember,
  getEditorMentionsConfig,
  createContentString
} from '@/utils/quill'
import TaskDialogCommentsFooterButton from '@/components/task/TaskDialogCommentsFooterButton'

const Quill = window.Quill || quill

export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    editingCommentId: {
      required: true
    },
    tasks: {
      type: Array,
      required: true
    },
    editorActive: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      required: true
    },
    taskItemsPath: {
      type: Object,
      required: true
    },
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    editorIsEmpty() {
      return this.instance ? this.instance.getLength() <= 1 : false
    }
  },
  data() {
    return {
      file: null,
      link: {
        index: null,
        textLength: null,
        text: '',
        url: null
      },
      instance: null,
      linkBottomSheet: false,
      attachmentBottomSheet: false,
      options: {
        theme: null,
        modules: {
          mention: getEditorMentionsConfig(this.tasks, this.users),
          toolbar: {
            container: '#toolbar',
            handlers: {
              attachment: () => {
                this.attachmentBottomSheet = true
              },
              member: (value) => {
                mentionsTaskOrMember(this.instance, value)
              },
              task: (value) => {
                mentionsTaskOrMember(this.instance, value)
              },
              link: (value) => {
                if (value) {
                  const content = createContentString(
                    this.instance.getContents()
                  )
                  const selection = this.instance.getSelection()
                  this.link.index =
                    selection.index === 0
                      ? 0
                      : (selection || {}).index || this.instance.getLength() - 1
                  this.link.textLength = selection.length
                  this.link.text = content.slice(
                    selection.index,
                    selection.index + selection.length
                  )
                  this.linkBottomSheet = true
                } else {
                  this.instance.format('link', false)
                }
              }
            }
          },
          keyboard: {
            bindings: {
              tab: {
                key: 9,
                handler: function() {
                  return false
                }
              }
            }
          }
        },
        placeholder: this.$i18n.t(
          'page.task_comments.comment_editor_placeholder'
        )
      }
    }
  },
  methods: {
    addLink() {
      if (this.$refs.linkForm.validate()) {
        this.instance.deleteText(this.link.index, this.link.textLength)
        this.instance.insertText(
          this.link.index || 0,
          `${this.link.text}`,
          'link',
          this.link.url
        )
        setTimeout(() => {
          this.instance.setSelection(
            (this.link.index || 0) + this.link.textLength,
            0
          )
          this.link = {
            index: null,
            textLength: null,
            text: '',
            url: null
          }
        })
        this.linkBottomSheet = false
        this.$refs.linkForm.resetValidation()
      }
    },
    initialize() {
      this.instance = new Quill(this.$refs.quill, this.options)
    },
    setEditorFocus() {
      setTimeout(() => {
        this.instance.focus()
      }, 100)
    },
    getEditorContent() {
      return this.instance.getContents()
    },
    setEditorContent() {
      const { instance } = this
      instance.setContents(JSON.parse(this.content))
      instance.setSelection(instance.getLength(), 0)
      this.setEditorFocus()
    },
    getPreviewImage(file) {
      return URL.createObjectURL(file)
    },
    getImageThumbnail(src) {
      return {
        small: src,
        medium: src,
        large: src
      }
    },
    handleLocalAttachment(files) {
      const file = files[0]
      const { type, name, size } = file
      const thumbnail =
        type.indexOf('image') > -1
          ? this.getImageThumbnail(this.getPreviewImage(file))
          : null
      this.attachmentBottomSheet = false
      this.file = {
        name,
        size,
        thumbnail,
        binary: file,
        source_type: 'Local'
      }
    },
    resetEditor() {
      this.instance.setContents([{ insert: '' }])
      this.file = null
      this.editorExpanded = false
    },
    activateEditor() {
      this.$emit('update:editorActive', true)
    },
    disableEditor() {
      this.$emit('update:editorActive', false)
    },
    openEditor() {
      this.activateEditor()
      this.setEditorFocus()
    },
    handleEditCancel() {
      this.editorExpanded = false
      this.resetEditor()
      this.$emit('comment-edit-canceled')
      this.disableEditor()
    },
    handleDriveAttachment(files) {
      const file = files[0]
      const { id, name, sizeBytes: size } = file
      this.attachmentBottomSheet = false
      this.file = {
        name,
        size,
        thumbnail: null,
        source_type: 'Drive',
        file_attributes: {
          id
        }
      }
    },
    handleDropboxAttachment(files) {
      const file = files[0]
      const { id, thumbnailLink, name, bytes: size } = file
      const thumbnail = this.getImageThumbnail(thumbnailLink) || null
      this.attachmentBottomSheet = false
      this.file = {
        name,
        size,
        thumbnail,
        source_type: 'Dropbox',
        file_attributes: {
          id,
          name,
          size,
          view_url: file.link
        }
      }
    },
    handleSendComment() {
      this.$emit('send-clicked', {
        file: this.file
      })
    },
    resetContent() {}
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener)
    this.instance = null
  },
  mounted() {
    this.initialize()
    this._keyListener = function(e) {
      if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        this.handleSendComment()
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
  },
  watch: {
    content(newValue) {
      if (!newValue || !newValue.length) {
        return
      }
      this.setEditorContent()
    }
  },
  components: {
    BaseIcon,
    BaseInlineForm,
    BaseFilePreview,
    BaseQuillBasicToolbar,
    BaseFileUploadSourceList,
    TaskDialogCommentsFooterButton
  }
}
</script>
<style lang="scss" scoped>
.has-max-height {
  max-height: 120px;
}
</style>
