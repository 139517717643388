<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    :show-save-btn="true"
    @go-back-clicked="goBack"
    content-class="small-level-two"
    :save-btn-text="$t('common.next')"
    @save-clicked="secondDialog = true"
    :title="$t('common.team', { count: 0 })"
    :disable-save-btn="(!invitees.length && !members.length) || loading"
    :card-text-class="availableMemebers.length > 0 ? 'd-flex flex-column' : ''"
  >
    <h3
      class="text-h6 info--text pa-3 flex-grow-0"
      v-text="
        $t(
          'page.project_settings.members.invitation.invite_by_email.add_by_email'
        )
      "
    />
    <settings-dialog-list-members-invite-by-email-add-by-email
      class="flex-grow-0"
      :dialog="secondDialog"
      :invitees.sync="invitees"
    />
    <div
      class="d-flex flex-column flex-grow-1"
      v-if="availableMemebers.length > 0"
    >
      <v-divider />
      <h3
        class="text-h6 info--text pa-3"
        v-text="
          $t(
            'page.project_settings.members.invitation.invite_by_email.members_of',
            {
              organization: organization.title
            }
          )
        "
      />
      <base-members-list
        :avatar-size="32"
        :members.sync="members"
        subtitle-prop="username"
        :users="availableMemebers"
        placeholder="form.placeholders.search_members"
        emptyListText="search_and_filter.results.not_found.members"
      />
    </div>
    <base-dialog
      card-color="white"
      :loading="loading"
      :show-save-btn="true"
      @save-clicked="addMembers"
      :dialog.sync="secondDialog"
      content-class="small-level-three"
      :save-btn-text="$t('common.done')"
      :title="$t('common.member', { count: 0 })"
      :disable-save-btn="selectedTeams.length === 0"
    >
      <settings-dialog-list-members-invite-teams
        :teams="teams"
        :project="project"
        v-if="teams && secondDialog"
        @teams-changed="handleTeamChange"
      />
    </base-dialog>
  </base-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseMembersList from '@/components/BaseMembersList'
import SettingsDialogListMembersInviteTeams from '@/components/project/SettingsDialogListMembersInviteTeams'
import SettingsDialogListMembersInviteByEmailAddByEmail from '@/components/project/SettingsDialogListMembersInviteByEmailAddByEmail'
export default {
  props: {
    teams: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'organization',
      'projectApprovedUsers',
      'organizationApprovedUsers'
    ]),
    availableMemebers() {
      const projectUsersIds = this.projectApprovedUsers.map((user) => user.id)
      return this.organizationApprovedUsers.filter(
        (user) => projectUsersIds.indexOf(user.id) < 0
      )
    }
  },
  data() {
    return {
      members: [],
      invitees: [],
      loading: false,
      selectedTeams: [],
      secondDialog: false
    }
  },
  methods: {
    ...mapActions(['addProjectMembers']),
    goBack() {
      this.$emit('update:dialog', false)
    },
    handleTeamChange(teams) {
      this.selectedTeams = teams
    },
    goToStart() {
      this.secondDialog = false
      this.goBack()
    },
    async addMembers() {
      try {
        this.loading = true
        await this.addProjectMembers({
          pid: this.project.id,
          data: {
            expand: ['user'],
            emails: this.invitees,
            user_ids: this.members,
            team_ids: this.selectedTeams
          }
        })
        this.goToStart()
      } catch (error) {}
      this.loading = false
    }
  },
  watch: {
    dialog() {
      this.members = []
    },
    secondDialog() {
      this.selectedTeams = []
    }
  },
  components: {
    BaseDialog,
    BaseMembersList,
    SettingsDialogListMembersInviteTeams,
    SettingsDialogListMembersInviteByEmailAddByEmail
  }
}
</script>
