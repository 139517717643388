<template>
  <span>
    {{ formattedElapsedTime }}
  </span>
</template>

<script>
export default {
  props: {
    startingSeconds: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      elapsedTime: 0,
      timer: 0
    }
  },
  computed: {
    formattedElapsedTime() {
      const date = new Date(null)
      date.setSeconds(this.elapsedTime / 1000 + this.startingSeconds)
      const utc = date.toUTCString()
      return utc.substr(utc.indexOf(':') - 2, 8)
    }
  },
  methods: {
    start() {
      this.timer = setInterval(() => {
        this.elapsedTime += 1000
      }, 1000)
    },
    stop() {
      clearInterval(this.timer)
    },
    reset() {
      this.elapsedTime = 0
    }
  },
  mounted() {
    this.start()
  }
}
</script>
