<template>
  <div
    class="d-flex justify-space-between mt-3 align-center"
    v-if="assignees.length || weight.length || progress > -1"
  >
    <base-avatar-group
      :list="taskUsers"
      :avatar-size="26"
      :overlapping="true"
      text-size="subtitle-2"
    />
    <div
      class="d-flex align-center weight body-2 secondary--text text--darken-2"
      v-if="weight.length"
    >
      <div class="text-no-wrap d-flex align-center" v-if="progress > -1">
        <v-progress-circular
          width="1"
          size="12"
          :rotate="-90"
          color="primary"
          :value="progress"
        />
        <span class="body-2 secondary--text text--darken-2 ms-1">
          {{ getLocalizedNumber(progress) }}%
        </span>
      </div>
      <div class="d-flex align-center ms-3">
        <base-icon
          width="14"
          height="14"
          name="balance-scale"
          color="secondary.darken2"
        />
        <span class="ms-1">{{ weight }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseAvatarGroup from '@/components/BaseAvatarGroup'
import BaseIcon from '../BaseIcon.vue'
export default {
  props: {
    assignees: {
      type: Array,
      required: true
    },
    weight: {
      type: String,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    progress: {
      type: Number,
      required: true
    }
  },
  computed: {
    taskUsers() {
      return this.users.filter(
        (user) =>
          this.assignees.map((assignee) => assignee.id).indexOf(user.id) > -1
      )
    }
  },
  components: {
    BaseAvatarGroup,
    BaseIcon
  }
}
</script>
