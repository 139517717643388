<template>
  <section class="px-4">
    <h2
      class="py-2 my-0 body-1 info--text"
      v-text="
        $t('page.project_settings.advanced_settings.export_project.title')
      "
    />
    <p
      class="py-2 my-0 body-1 secondary--text text--darken-2"
      v-text="
        $t('page.project_settings.advanced_settings.export_project.description')
      "
    />
    <p
      class="py-2 my-0 body-1 secondary--text text--darken-2"
      v-text="
        $t('page.project_settings.advanced_settings.export_project.caution')
      "
    />
    <p class="py-2 my-0 d-flex align-center justify-center">
      <v-btn
        depressed
        width="148"
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="handleClickOnExport"
        v-text="
          $t('page.project_settings.advanced_settings.export_project.button')
        "
      />
    </p>
    <base-inline-form
      :value.sync="sheet"
      card-text-class-name="px-4"
    >
      <v-card flat tile color="white" class="text-start">
        <v-card-title
          class="px-4 body-1 info--text"
          v-text="
            $t('page.project_settings.advanced_settings.export_project.title')
          "
        />
        <v-card-text
          class="px-4 py-0 body-1 secondary--text text--darken-2"
          v-text="
            $t(
              'page.project_settings.advanced_settings.export_project.export_started'
            )
          "
        />
        <v-card-actions class="pb-2 d-flex justify-end">
          <v-btn
            text
            color="primary"
            @click="sheet = false"
            v-text="$t('common.okay')"
          />
        </v-card-actions>
      </v-card>
    </base-inline-form>
  </section>
</template>

<script>
import { createProjectExport } from '@/api/projects'
import BaseInlineForm from '@/components/BaseInlineForm'
export default {
  data() {
    return {
      sheet: false,
      loading: false
    }
  },
  methods: {
    async handleClickOnExport() {
      this.loading = true
      try {
        await createProjectExport(this.$route.params.pid)
        this.sheet = true
      } catch (error) {}
      this.loading = false
    }
  },
  components: {
    BaseInlineForm
  }
}
</script>
