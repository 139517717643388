<template>
  <div class="pa-4">
    <v-form ref="form" v-model="formIsValid" @submit.prevent="">
      <base-password
        :validate-on-blur="false"
        :rules="getPasswordRules()"
        :value.sync="form.old_password"
        :label="$t('form.labels.old_password')"
      />
      <base-password
        class="mt-1"
        :validate-on-blur="false"
        :rules="getPasswordRules()"
        :value.sync="form.new_password"
        :label="$t('form.labels.new_password')"
      />
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BasePassword from '@/components/BasePassword'
const form = {
  old_password: '',
  new_password: ''
}
export default {
  components: {
    BasePassword
  },
  props: {
    saveClicked: {
      type: Boolean,
      default: false
    },
    saveDisabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formIsValid: false,
      form: { ...form }
    }
  },
  methods: {
    ...mapActions(['updatePassword', 'displaySuccessMessage']),
    async changePassword() {
      this.$emit('update:loading', true)
      try {
        await this.updatePassword(this.form)
        this.displaySuccessMessage(this.$i18n.t('messages.password_changed'))
        this.form = { ...form }
        this.$refs.form.resetValidation()
      } catch (error) {}
      this.$emit('update:loading', false)
    }
  },
  watch: {
    formIsValid(newValue) {
      this.$emit('update:saveDisabled', !newValue)
    },
    saveClicked(newValue) {
      if (newValue) {
        this.changePassword()
      }
      this.$emit('update:saveClicked', false)
    }
  }
}
</script>
