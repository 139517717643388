import {
  SET_APP_NAME,
  SET_USER_LANGUAGES,
  SET_ABILITIES_PRIORITY_MAP,
  SET_TEAM_PERMISSION_ABILITIES
} from '../mutation-types'
import { fetchConfig } from '@/api/config'
const state = {
  appName: '',
  userLanguages: null,
  abilitiesPriorityMap: null,
  teamPermissionAbilities: null
}

const getters = {
  appName: state => state.appName,
  userLanguages: state => state.userLanguages,
  abilitiesPriorityMap: state => state.abilitiesPriorityMap,
  teamPermissionAbilities: state => state.teamPermissionAbilities
}

const mutations = {
  [SET_APP_NAME](state, appName) {
    state.appName = appName
  },
  [SET_USER_LANGUAGES](state, userLanguages) {
    state.userLanguages = userLanguages
  },
  [SET_ABILITIES_PRIORITY_MAP](state, abilitiesPriorityMap) {
    state.abilitiesPriorityMap = abilitiesPriorityMap
  },
  [SET_TEAM_PERMISSION_ABILITIES](state, teamPermissionAbilities) {
    state.teamPermissionAbilities = teamPermissionAbilities
  }
}

const actions = {
  async getConfig({ commit }) {
    const { data } = await fetchConfig()
    commit(SET_APP_NAME, data.data.app_name)
    commit(SET_USER_LANGUAGES, data.data.languages)
    commit(SET_ABILITIES_PRIORITY_MAP, data.data.abilities_priority_map)
    commit(SET_TEAM_PERMISSION_ABILITIES, data.data.team_permission_abilities)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
