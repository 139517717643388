var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(_vm.fetching)?_c('base-loading'):[(_vm.timelogs && _vm.timelogs.length)?_c('v-data-table',{staticClass:"timelogs-table mt-2 body-1",attrs:{"dense":"","bordered":"","calculate-widths":"","items":_vm.timelogs,"loader-height":"2","headers":_vm.headers,"loading":_vm.loading,"options":_vm.options,"mobile-breakpoint":"100","disable-pagination":true,"hide-default-footer":true},on:{"update:options":function($event){_vm.options=$event},"click:row":function($event){return _vm.$emit('row-clicked', $event)}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('base-avatar',{attrs:{"size":24,"text-size":"subtitle-1","title":_vm.getUser(item).username,"image":_vm.getUser(item).avatar ? _vm.getUser(item).avatar.medium : ''}}),_c('span',{staticClass:"text-truncate ms-2",staticStyle:{"display":"inline-block","max-width":"183px"}},[_vm._v(" "+_vm._s(_vm.getUser(item).name)+" ")])],1)]}},{key:"item.task_number",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getTask(item).number)+" ")])]}},{key:"item.task_title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-truncate",staticStyle:{"display":"block","max-width":"300px"}},[_vm._v(" "+_vm._s(_vm.getTask(item).title)+" ")])]}},{key:"item.started_at",fn:function(ref){
var item = ref.item;
return [_c('base-date-time',{attrs:{"date":item.started_at,"format":"short_with_time"}})]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLocalizedNumber(_vm.getTime(item.duration)))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('span',{staticClass:"text-truncate",staticStyle:{"display":"block","max-width":"440px"}},[_vm._v(" "+_vm._s(item.description)+" ")]):_c('span')]}}],null,true)}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }