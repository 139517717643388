<template>
  <base-dialog
    v-if="item"
    :dialog="dialog"
    card-color="white"
    content-class="small-level-two"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t(`page.organization_settings.permissions.${item.id}.title`)"
  >
    <organization-settings-list-permissions-settings-dialog-content
      v-if="dialog"
      :id="item.id"
      :granted="item.granted"
      @permission-changed="$emit('permission-changed', $event)"
      :exception-ids="item.exceptions.map((exception) => exception.id)"
    />
  </base-dialog>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'
import OrganizationSettingsListPermissionsSettingsDialogContent from '@/components/organization/OrganizationSettingsListPermissionsSettingsDialogContent'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseDialog,
    OrganizationSettingsListPermissionsSettingsDialogContent
  }
}
</script>
