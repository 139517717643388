<template>
  <v-card flat class="flex-grow-1 d-flex flex-column" max-width="100%">
    <v-card-title class="px-3 py-1 white flex-grow-0 body-1">
      <v-text-field
        dense
        outlined
        hide-details
        v-model="query"
        :label="$t('form.placeholders.search_lists')"
      />
    </v-card-title>
    <v-card-text
      class="pa-0 flex-grow-1"
      :class="{
        'd-flex align-center justify-center': filteredItems.length === 0
      }"
    >
      <v-list
        flat
        max-width="100%"
        color="transparent"
        v-if="filteredItems.length > 0"
      >
        <v-list-group
          no-action
          sub-group
          :key="item.id"
          :value="item.active"
          class="hide-prepend-icon"
          active-class="info--text"
          v-for="item in filteredItems"
          @click="item.active = !item.active"
        >
          <template v-slot:activator>
            <v-list-item-content class="overflow-hidden">
              <v-list-item-title class="body-1" v-text="item.title" />
            </v-list-item-content>
          </template>
          <template v-slot:appendIcon>
            <base-icon
              color="secondary.darken1"
              :name="item.active ? 'angle-down' : 'angle-up'"
            />
          </template>
          <v-list-item-group
            :value="selected"
            :multiple="multiple"
            active-class="primary--text"
            @change="$emit('update:selected', $event)"
          >
            <v-list-item
              class="ps-8"
              :key="child.id"
              :ripple="false"
              :value="child.id"
              v-for="child in item.items"
              :disabled="currentItemId === child.id && currentItemDisabled"
            >
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <v-list-item-title
                    class="body-2 d-flex align-center justify-start"
                  >
                    <span class="text-truncate" style="display: inline-block">
                      {{ child.title }}
                    </span>
                    <v-chip
                      label
                      x-small
                      color="secondary"
                      v-text="'current'"
                      style="min-width: 60px"
                      v-if="currentItemId === child.id"
                      class="overflow-chip flex-grow-0 justify-center ms-2 rounded-sm"
                    />
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="my-2" v-if="active" style="min-width: auto">
                  <base-icon
                    name="check"
                    color="primary"
                    className="d-flex align-center"
                  />
                </v-list-item-icon>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>
      </v-list>
      <div
        v-else
        v-text="emptyListText"
        class="text-h4 secondary--text text--darken-2 flex-grow-1 d-flex align-center justify-center"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    emptyListText: {
      type: String,
      default: ''
    },
    currentItemId: {
      type: String
    },
    currentItemDisabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selected: {
      required: true
    }
  },
  computed: {
    filteredItems() {
      const { query, items } = this
      if (!query || query.length === 0) {
        return items
      }
      return items
        .map((item) => {
          return {
            ...item,
            items: item.items.filter((child) => {
              return child.title.indexOf(query) > -1
            })
          }
        })
        .filter((item) => item.items.length > 0)
    }
  },
  data() {
    return {
      query: ''
    }
  },
  components: {
    BaseIcon
  }
}
</script>
