<template>
  <base-dialog
    :title="title"
    :dialog="dialog"
    card-color="white"
    :loading="loading"
    :show-save-btn="true"
    cardTextClass="d-flex flex-column"
    :disable-save-btn="!selectedItems"
    @save-clicked="$emit('done-clicked')"
    @go-back-clicked="$emit('cancel-clicked')"
  >
    <section class="mx-3 mb-4">
      <v-chip label color="secondary lighten-2">
        <base-icon
          name="exchange"
          color="secondary.darken2"
          className="d-flex align-center me-2"
        />
        <span class="secondary--text text--darken-2" v-text="list.title" />
      </v-chip>
    </section>
    <section class="d-flex flex-column flex-grow-1">
      <base-list
        :filterable="true"
        search-prop="title"
        :items="boardsList"
        :multiple="multiple"
        :value="selectedItems"
        :search-label="$t('form.placeholders.search_boards')"
        @value-changed="$emit('update:selectedItems', $event)"
        emptyListText="search_and_filter.results.not_found.boards"
      />
    </section>
  </base-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseList from '@/components/BaseList'
import BaseDialog from '@/components/BaseDialog'
export default {
  components: { BaseIcon, BaseList, BaseDialog },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    boards: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    board: {
      type: String,
      default: ''
    },
    list: {
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    selectedItems: {
      required: true
    }
  },
  computed: {
    ...mapGetters(['teamAbilities', 'abilitiesPriorityMap']),
    boardsList() {
      return this.boards.filter(
        (b) =>
          b.id !== this.board &&
          this.teamAbilities.boards[this.board] >=
            this.abilitiesPriorityMap.Board.receive_list
      )
    }
  }
}
</script>
