<template>
  <v-list-item>
    <v-badge
      avatar
      overlap
      offset-x="10"
      offset-y="10"
      :value="deletable"
      :color="failed || uploading ? 'white' : 'secondary darken-2'"
    >
      <template v-slot:badge>
        <v-btn
          fab
          icon
          width="16"
          height="16"
          @click="$emit('remove-file-clicked')"
        >
          <base-icon
            width="14"
            height="14"
            name="times"
            color="white"
            className="d-flex align-center"
          />
        </v-btn>
      </template>
      <v-list-item-avatar
        tile
        size="48"
        color="secondary lighten-2"
        class="rounded ma-0 justify-center"
      >
        <v-img
          height="48"
          max-width="48"
          :src="file.thumbnail.large"
          v-if="file.thumbnail && !uploading && !failed"
        />
        <template v-else>
          <template v-if="uploading || failed">
            <v-btn
              icon
              fab
              small
              v-if="failed"
              @click="$emit('retry-file-upload-clicked')"
            >
              <base-icon name="redo" width="24" height="24" />
            </v-btn>
            <v-progress-circular
              v-else
              size="20"
              width="2"
              color="primary"
              :value="progress"
              :indeterminate="progress === 100"
            >
              <v-btn
                icon
                fab
                x-small
                v-if="progress < 100"
                @click="$emit('cancel-file-upload-clicked')"
              >
                <base-icon
                  name="times"
                  width="12"
                  height="12"
                  className="d-flex align-center"
                />
              </v-btn>
            </v-progress-circular>
          </template>
          <base-icon v-else name="file" width="24" height="24" />
        </template>
      </v-list-item-avatar>
    </v-badge>
    <v-list-item-content style="height: 48px" class="py-0 ms-2">
      <v-list-item-title
        style="max-width: 100%"
        class="d-flex align-start caption"
      >
        <span v-text="fileName" class="flex-grow-0 text-truncate" />
        <span
          class="flex-grow-1"
          v-if="fileExtention"
          v-text="`.${fileExtention}`"
        />
      </v-list-item-title>
      <v-list-item-subtitle class="caption" v-text="fileSize" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import filesize from 'filesize'
import BaseIcon from '@/components/BaseIcon'

export default {
  props: {
    file: {
      type: Object,
      required: true
    },
    deletable: {
      type: Boolean,
      default: true
    },
    uploading: {
      type: Boolean,
      default: false
    },
    failed: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: 0
    }
  },
  computed: {
    dotIndex() {
      return this.file.name.lastIndexOf('.')
    },
    fileSize() {
      return filesize(this.file.size)
    },
    fileName() {
      if (this.dotIndex < 0) {
        return this.file.name
      }
      return this.file.name.substring(0, this.dotIndex)
    },
    fileExtention() {
      if (this.dotIndex < 0) {
        return null
      }
      return this.file.name.substring(this.dotIndex + 1, this.file.name.length)
    }
  },
  components: {
    BaseIcon
  }
}
</script>
