<template>
  <v-container fluid>
    <task-dialog-details-informations-row
      :editable="editable"
      icon="balance-scale"
      @row-clicked="sheet = true"
      :text="`${weight} ${weightUnit}`"
      :label="$t('common.task_weight')"
      :add-button-text="$t('common.add_task_weight')"
    />
    <base-inline-form
      :value.sync="sheet"
      card-text-class-name="px-4"
      :title="$t('common.task_weight')"
    >
      <v-form class="mt-4" @submit.prevent="save">
        <v-text-field
          dense
          required
          outlined
          autofocus
          type="number"
          v-model="taskWeight"
          :suffix="weightUnit"
          :label="$t('common.task_weight')"
        />
      </v-form>
      <base-inline-form-actions
        @ok-clicked="save"
        :loading="loading"
        @cancel-clicked="cancel"
        :ok-text="$t('common.save')"
      />
    </base-inline-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseInlineFormActions from '@/components/BaseInlineFormActions'
import TaskDialogDetailsInformationsRow from '@/components/task/TaskDialogDetailsInformationsRow'
export default {
  props: {
    weight: {
      type: Number,
      required: true
    },
    weightUnit: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['task']),
    tid() {
      return this.task.id
    }
  },
  data() {
    return {
      sheet: false,
      taskWeight: this.weight,
      loading: false
    }
  },
  methods: {
    ...mapActions(['updateProjectTask']),
    async save() {
      const weight = Number(this.taskWeight) || 0
      if (weight === this.weight) {
        this.sheet = false
        return
      }
      try {
        this.loading = true
        await this.updateProjectTask({
          tid: this.tid,
          data: {
            weight
          },
          keys: ['weight'],
          updateListItem: true
        })
        this.sheet = false
      } catch (error) {}
      this.loading = false
    },
    cancel() {
      this.taskWeight = this.weight
      this.sheet = !this.sheet
    }
  },
  watch: {
    weight(newValue) {
      this.taskWeight = newValue
    }
  },
  components: {
    BaseInlineForm,
    BaseInlineFormActions,
    TaskDialogDetailsInformationsRow
  }
}
</script>
