var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"px-4"},[_c('h2',{staticClass:"py-2 my-0 body-1 info--text",domProps:{"textContent":_vm._s(_vm.$t('page.project_settings.advanced_settings.close_project.title'))}}),_c('p',{staticClass:"py-2 my-0 body-1 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
      _vm.$t('page.project_settings.advanced_settings.close_project.description')
    )}}),_c('p',{staticClass:"py-2 my-0 d-flex align-center justify-center"},[_c('v-btn',{attrs:{"depressed":"","width":"148","color":"error"},domProps:{"textContent":_vm._s(
        _vm.$t('page.project_settings.advanced_settings.close_project.button')
      )},on:{"click":function($event){_vm.dialog = true}}})],1),_c('base-dialog',{attrs:{"card-color":"white","dialog":_vm.dialog,"hide-back-btn":true,"content-class":"small-level-two","title":_vm.$t(
        'page.project_settings.advanced_settings.close_project.confirmation.title'
      )},on:{"update:dialog":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"cardActions",fn:function(){return [_c('v-card-actions',{staticClass:"pb-4 d-flex justify-space-between"},[_c('v-btn',{attrs:{"depressed":"","width":"136","color":"primary","disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('common.cancel'))},on:{"click":function($event){_vm.dialog = false}}}),_c('v-btn',{attrs:{"text":"","width":"136","color":"error","loading":_vm.loading,"disabled":_vm.loading},domProps:{"textContent":_vm._s(
            _vm.$t(
              'page.project_settings.advanced_settings.close_project.confirmation.close_button'
            )
          )},on:{"click":_vm.handleClickOnCloseConfirmation}})],1)]},proxy:true}])},[_c('section',{staticClass:"px-4"},[_c('h3',{staticClass:"py-2 my-0 body-1 info--text",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'page.project_settings.advanced_settings.close_project.confirmation.subtitle',
            {
              title: this.project.title,
              interpolation: { escapeValue: false }
            }
          )
        )}}),_c('p',{staticClass:"py-2 my-0 body-1 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
          _vm.$t(
            'page.project_settings.advanced_settings.close_project.confirmation.description'
          )
        )}}),_c('p',{staticClass:"py-2 my-0 body-1 info--text",domProps:{"textContent":_vm._s(
          _vm.$t(
            'page.project_settings.advanced_settings.close_project.confirmation.close_confirmation'
          )
        )}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }