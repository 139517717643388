<template>
  <fragment>
    <template v-if="projects">
      <organizationSettings-list-projects-settings-list
        :items="activeProjects"
        v-show="activeProjects.length > 0"
        :title="$t('page.organization_settings.projects.active_projects')"
      />
      <v-divider
        class="secondary lighten-2 my-4"
        v-if="activeProjects.length > 0 && closedProjects.length > 0"
      />
      <organizationSettings-list-projects-settings-list
        :items="closedProjects"
        v-show="closedProjects.length > 0"
        :title="$t('page.organization_settings.projects.closed_projects')"
      />
    </template>
    <base-loading v-else />
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseLoading from '@/components/BaseLoading'
import OrganizationSettingsListProjectsSettingsList from '@/components/organization/OrganizationSettingsListProjectsSettingsList'
export default {
  computed: {
    ...mapGetters(['projects']),
    activeProjects() {
      return this.projects ? this.projects.filter((p) => !p.closed) : []
    },
    closedProjects() {
      return this.projects ? this.projects.filter((p) => p.closed) : []
    }
  },
  methods: {
    ...mapActions(['getProjects']),
    getData() {
      this.getProjects({
        require_membership: false,
        expand: ['owner'],
        limit: 0
      })
    }
  },
  mounted() {
    this.getData()
  },
  components: {
    BaseLoading,
    OrganizationSettingsListProjectsSettingsList
  }
}
</script>
