<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    content-class="small-level-one"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.advanced_settings.title')"
  >
    <template v-if="projectAbilities.abilities.export">
      <settings-dialog-list-advanced-settings-export />
      <v-divider class="my-4 secondary lighten-2" />
    </template>
    <template v-if="project && projectAbilities.abilities.close">
      <settings-dialog-list-advanced-settings-close
        :project="project"
      />
      <v-divider class="my-4 secondary lighten-2" />
    </template>
    <settings-dialog-list-advanced-settings-delete
      v-if="project && projectAbilities.abilities.delete"
      :project="project"
    />
  </base-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import SettingsDialogListAdvancedSettingsClose from './SettingsDialogListAdvancedSettingsClose'
import SettingsDialogListAdvancedSettingsExport from './SettingsDialogListAdvancedSettingsExport'
import SettingsDialogListAdvancedSettingsDelete from './SettingsDialogListAdvancedSettingsDelete'
export default {
  props: {
    project: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['projectAbilities'])
  },
  components: {
    BaseDialog,
    SettingsDialogListAdvancedSettingsClose,
    SettingsDialogListAdvancedSettingsExport,
    SettingsDialogListAdvancedSettingsDelete
  }
}
</script>
