var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('h3',{staticClass:"text-h1 info--text fill-width mt-4 mb-8",domProps:{"textContent":_vm._s(_vm.$t('page.sign_up.title'))}}),_c('v-form',{staticClass:"flex-grow-1 d-flex justify-start flex-column fill-width mt-6",attrs:{"disabled":_vm.loading},model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('section',[_c('v-text-field',{attrs:{"flat":"","dense":"","outlined":"","rules":_vm.rules.fullname,"label":_vm.$t('form.labels.full_name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","rules":_vm.rules.username,"label":_vm.$t('form.labels.username')},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","rules":_vm.rules.email,"label":_vm.$t('form.labels.email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('base-password',{attrs:{"rules":_vm.rules.password,"validate-on-blur":false,"value":_vm.form.password,"label":_vm.$t('form.labels.password')},on:{"update:value":function($event){return _vm.$set(_vm.form, "password", $event)}}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"dense":"","hide-details":"","label":_vm.$t('form.labels.sign_up_for_app_news_and_updates', {
            appName: 'Taskulu'
          })},model:{value:(_vm.form.receive_news),callback:function ($$v) {_vm.$set(_vm.form, "receive_news", $$v)},expression:"form.receive_news"}}),_c('p',{staticClass:"mt-4 caption secondary--text",domProps:{"innerHTML":_vm._s(
          _vm.$t('page.sign_up.tos', {
            privacyLink: _vm.privacyLink,
            termsLink: _vm.termsLink
          })
        )}})],1)]),_c('div',{staticClass:"flex-grow-0 mb-6 fill-width"},[_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary","loading":_vm.loading,"disabled":!_vm.formIsValid},on:{"click":function($event){return _vm.$emit('sign-up-button-hit', _vm.form)}}},[_vm._v(" "+_vm._s(_vm.$t('common.sign_up'))+" ")]),_c('p',{staticClass:"body-2 info--text mt-4"},[_vm._v(" "+_vm._s(_vm.$t('page.sign_up.already_have_an_account'))+" "),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ path: '/select-organization' }}},[_vm._v(" "+_vm._s(_vm.$t('page.sign_up.sign_in'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }