<template>
  <div
    v-resize="setHeight"
    class="overflow-y-auto"
    :style="`height:${height}px`"
    :class="{ 'd-flex': !checklists.length }"
  >

    <base-empty-state
      class="full"
      icon="file-times-alt"
      v-if="!checklists.length"
      :text="$t('common.checklist', { count: 0 })"
    />

    <template v-else>
      <v-card
        flat
        class="mx-auto"
        max-width="100%"
        :key="checklist.id"
        color="secondary lighten-5"
        v-for="(checklist, index) in checklists"
      >
        <task-dialog-checklist-items
          :pid="pid"
          :index="index"
          :priority="priority"
          :checklist="checklist"
          @item-changed="updateListItem(checklist, $event)"
          @edit-checklist-clicked="handleClickOnEditChecklist"
          @checklist-deleted="removeTaskChecklist(checklist, $event)"
        />
        <v-divider
          class="secondary lighten-1"
          v-if="index < checklists.length - 1"
        />
      </v-card>
    </template>

    <base-inline-form
      :value.sync="sheet"
      card-text-class-name="px-4"
      :title="$t('common.checklist')"
    >
      <v-form @submit.prevent="handleRequest">
        <v-text-field
          dense
          required
          outlined
          autofocus
          v-model="text"
          :label="$t('form.labels.checklist_title')"
        />
      </v-form>
      <base-inline-form-actions
        :loading="loading"
        @cancel-clicked="close"
        @ok-clicked="handleRequest"
        :ok-text="$t('common.save')"
      />
    </base-inline-form>
    <v-btn
      fab
      small
      bottom
      depressed
      width="56"
      height="56"
      color="primary"
      :fixed="isMobile"
      :left="$vuetify.rtl"
      :absolute="!isMobile"
      @click="sheet = true"
      :right="!$vuetify.rtl"
      :class="{ 'mb-9': !isMobile }"
      v-if="priority >= abilitiesPriorityMap.Task.create_checklist"
    >
      <unicon name="plus" fill="#ffffff" />
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseEmptyState from '@/components/BaseEmptyState'
import BaseInlineForm from '@/components/BaseInlineForm'
import TaskDialogChecklistItems from './TaskDialogChecklistItems'
import BaseInlineFormActions from '@/components/BaseInlineFormActions'
export default {
  props: {
    checklists: {
      type: Array,
      required: true
    },
    priority: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters(['abilitiesPriorityMap', 'isMobile', 'project', 'task']),
    tid() {
      return this.task.id
    },
    pid() {
      return this.project.id
    }
  },
  data() {
    return {
      text: '',
      height: 0,
      sheet: false,
      loading: false,
      currentChecklist: null
    }
  },
  methods: {
    ...mapActions([
      'addTaskChecklist',
      'deleteTaskChecklist',
      'updateTaskChecklist'
    ]),
    close() {
      this.text = ''
      this.sheet = false
      this.currentChecklist = null
    },
    async updateList() {
      await this.updateTaskChecklist({
        cid: this.currentChecklist.id,
        prop: 'title',
        data: {
          title: this.text
        }
      })
    },
    async addList() {
      await this.addTaskChecklist({
        pid: this.pid,
        data: {
          task_id: this.tid,
          title: this.text,
          include: ['items']
        }
      })
    },
    async handleRequest() {
      this.loading = true
      try {
        if (this.currentChecklist) {
          await this.updateList()
        } else {
          await this.addList()
        }
        this.close()
      } catch (error) {}
      this.loading = false
    },
    async removeTaskChecklist(checklist, index) {
      try {
        await this.deleteTaskChecklist({ cid: checklist.id, index })
      } catch (error) {}
    },
    handleClickOnEditChecklist(checklist) {
      this.currentChecklist = checklist
      this.text = checklist.title
      this.sheet = true
    },
    setHeight() {
      const wrapper = document.getElementById('base_dialog_text')
      this.height = wrapper ? wrapper.clientHeight : 100
    }
  },
  mounted() {
    this.setHeight()
  },
  components: {
    BaseInlineForm,
    BaseEmptyState,
    BaseInlineFormActions,
    TaskDialogChecklistItems
  }
}
</script>
