<template>
  <v-app id="taskulu" class="taskulu" v-resize="onResize">
    <template v-if="$route.meta.usePlain">
      <slot />
    </template>
    <template v-else>
      <the-navigation-drawer ref="navDrawer" />
      <the-app-toolbar
        @drawer-toggle-clicked="$refs.navDrawer.toggleDrawer()"
      />
      <v-main :style="`padding-top:${mainHeight}px;`" class="overflow-y-hidden">
        <slot />
      </v-main>
    </template>
    <the-toast />
    <v-overlay :value="overlay" color="#ffffff" opacity="1" z-index="10">
      <v-progress-circular color="primary" indeterminate size="48" />
    </v-overlay>
    <create-project v-if="translationInitialized" />
    <create-organization v-if="translationInitialized" />
  </v-app>
</template>

<script>
import TheToast from '@/components/TheToast'
import { mapGetters, mapActions } from 'vuex'
import TheAppToolbar from '@/components/TheAppToolbar'
import CreateProject from '@/components/project/CreateProject'
import TheNavigationDrawer from '@/components/TheSecondNavigationDrawer'
import CreateOrganization from '@/components/organization/CreateOrganization'
export default {
  computed: {
    ...mapGetters([
      'user',
      'locale',
      'direction',
      'organizations',
      'isAuthenticated',
      'translationInitialized'
    ]),
    mainHeight() {
      const appBarTopMargin = 8
      return this.$vuetify.breakpoint.lgAndUp ? 60 : 48 + appBarTopMargin
    },
    showBottomNavigation() {
      return this.$route.meta.showBottomNavigation
    }
  },
  data() {
    return {
      overlay: true
    }
  },
  created() {
    this.setAppDirection()
  },
  mounted() {
    this.fetchData()
    this.onResize()
    this.loadThirdParyScripts()
  },
  methods: {
    ...mapActions([
      'getUser',
      'getConfig',
      'setIsMobile',
      'getOrganization',
      'getOrganizations',
      'getOrganizationAbilities',
      'getUnreadNotificationCount'
    ]),
    setAppDirection() {
      this.$vuetify.rtl = this.direction === 'rtl'
    },
    setAppLocale() {
      this.$i18n.i18next.changeLanguage(this.locale)
    },
    async fetchData() {
      if (!this.isAuthenticated || this.$route.meta.noFetch) {
        return
      }
      Promise.all([
        this.getUser(),
        this.getUnreadNotificationCount(),
        this.getConfig()
      ]).then(() => {
        this.overlay = false
      })
      if (!this.organization) {
        this.getOrganization()
        this.getOrganizationAbilities()
      }
      if (!this.organizations) {
        this.getOrganizations({
          include: ['projects']
        })
      }
    },
    onResize() {
      this.setIsMobile(
        window.innerWidth <= this.$vuetify.breakpoint.thresholds.xs
      )
    },
    loadThirdParyScripts() {
      const googleApiScript = document.createElement('script')
      const dropboxApiScript = document.createElement('script')
      googleApiScript.setAttribute(
        'src',
        process.env.VUE_APP_GOOGLE_API_SCRIPT_URL
      )
      dropboxApiScript.setAttribute(
        'src',
        process.env.VUE_APP_DROPBOX_API_SCRIPT_URL
      )
      dropboxApiScript.setAttribute(
        'data-app-key',
        process.env.VUE_APP_DROPBOX_API_KEY
      )
      dropboxApiScript.setAttribute('id', 'dropboxjs')
      document.head.appendChild(googleApiScript)
      document.head.appendChild(dropboxApiScript)
    }
  },
  watch: {
    direction() {
      this.setAppDirection()
    },
    locale() {
      this.setAppLocale()
    }
  },
  components: {
    TheToast,
    CreateProject,
    TheAppToolbar,
    CreateOrganization,
    TheNavigationDrawer
  }
}
</script>
