<template>
  <centered-layout>
    <template v-slot:appbar v-if="organization && fetched">
      <v-app-bar-nav-icon
        @click="currentStep -= 1"
        v-if="currentStep < 5 && currentStep > 1"
      >
        <base-angle-icon name="t-arrow" width="16" height="16" />
      </v-app-bar-nav-icon>
      <v-spacer />
      <v-btn
        text
        small
        min-width="32"
        :ripple="false"
        color="secondary darken-2"
        @click="handleClickOnSkip"
        v-if="currentStep === 3 || currentStep === 6"
      >
        {{ $t('common.skip') }}
      </v-btn>
    </template>
    <template v-if="organization && fetched">
      <div
        class="
          d-flex
          fill-width
          flex-grow-1
          flex-column
          align-center
          justify-start
        "
      >
        <base-avatar
          :size="64"
          :title="name"
          text-size="h1"
          :image="image"
          :elevated="true"
        />
        <h2 class="text-h3 mt-2 mb-4 info--text" v-text="name" />
        <onboarding-question-steps
          :reset-answers="false"
          @next-clicked="modifyQuestions"
          :current-step.sync="currentStep"
          @answers-changed="answers = $event"
          v-show="currentStep === 1 || currentStep === 2"
        />
        <template-selection
          v-if="currentStep === 3"
          :params="templateParams"
          @template-selected="createNewProject($event)"
        />
        <create-project
          v-if="currentStep === 4"
          :loading="creatingProject"
          :project-title.sync="projectTitle"
          @project-created="createNewProject"
        />
        <creating-project :class="currentStep === 5 ? 'd-flex' : 'd-none'" />
        <onboarding-add-member
          v-if="project"
          :project="project"
          :invitees.sync="invitees"
          :inviting="invitingMembers"
          @invite-clicked="inviteMembersToProject"
          :class="currentStep === 6 ? 'd-flex' : 'd-none'"
        />
      </div>
      <div class="flex-grow-0 py-8" v-if="currentStep < 4">
        <div class="d-flex fill-width onboarding-nav mt-6">
          <div class="progress">
            <v-progress-linear
              depressed
              height="6"
              color="primary"
              :value="progress"
            />
          </div>
        </div>
      </div>
    </template>
    <base-loading v-else />
  </centered-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseAvatar from '@/components/BaseAvatar'
import BaseLoading from '@/components/BaseLoading'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import CenteredLayout from '@/layouts/CenteredLayout'
import CreateProject from '@/components/onboarding/CreateProject'
import CreatingProject from '@/components/onboarding/CreatingProject'
import TemplateSelection from '@/components/onboarding/TemplateSelection'
import OnboardingAddMember from '@/components/onboarding/OnboardingAddMember'
import OnboardingQuestionSteps from '@/components/onboarding/OnboardingQuestionSteps'
const totalPriorSteps = 1
export default {
  data() {
    return {
      answers: [],
      invitees: [],
      currentStep: 1,
      fetched: false,
      projectTitle: '',
      creatingProject: false,
      invitingMembers: false
    }
  },
  computed: {
    ...mapGetters([
      'project',
      'organization',
      'organizationSubdomain',
      'organizationAbilities'
    ]),
    totalSteps() {
      const totalStepsAfterQuestions = 1
      const questionPages = 2

      return totalPriorSteps + questionPages + totalStepsAfterQuestions
    },
    progress() {
      return Math.round(
        (100 * (this.currentStep + totalPriorSteps)) / this.totalSteps
      )
    },
    name() {
      return this.organization ? this.organization.title : ''
    },
    image() {
      return this.organization && this.organization.logo
        ? this.organization.logo.medium
        : ''
    },
    templateParams() {
      return this.answers
        .reduce((acc, val) => acc.concat(val), [])
        .reduce((obj, item) => {
          obj[item.id] = item.answer_id
          return obj
        }, {})
    }
  },
  methods: {
    ...mapActions([
      'addProject',
      'getOrganization',
      'addProjectMembers',
      'addProjectByTemplate',
      'getOrganizationAbilities',
      'modifyOnboardingQuestions'
    ]),
    handleClickOnSkip() {
      if (this.currentStep === 3) {
        this.currentStep += 1
        return
      }
      this.goToCreatedProject()
    },
    goToProject(pid) {
      this.$router.push({
        name: 'board',
        params: { pid, view: 'kanban' }
      })
    },
    goToCreatedProject() {
      this.goToProject(this.project.id)
    },
    async inviteMembersToProject() {
      const { invitees } = this
      if (!invitees.length) {
        return this.goToCreatedProject()
      }
      this.invitingMembers = true
      try {
        await this.addProjectMembers({
          pid: this.project.id,
          data: {
            emails: this.invitees
          }
        })
        this.goToCreatedProject()
      } catch (error) {}
      this.invitingMembers = false
    },
    async createNewProject(id = null) {
      const { projectTitle: title } = this
      const include = [
        'tags',
        'tasks',
        'lists',
        'labels',
        'boards',
        'statuses',
        'memberships'
      ]
      const projectData = id ? { template_id: id, include } : { title, include }
      const request = id ? this.addProjectByTemplate : this.addProject
      this.currentStep = 5
      this.creatingProject = true
      try {
        await request({ projectData, setProject: true })
        this.currentStep = 6
      } catch (error) {
        this.currentStep = 3
      }
      this.creatingProject = false
    },
    async fetchData() {
      const { organizationSubdomain } = this
      await this.getOrganization(organizationSubdomain)
      await this.getOrganizationAbilities()
      if (!this.organizationAbilities.abilities.create_project) {
        return this.$router.push({ path: '/' })
      }
      if (
        this.organization &&
        this.organization.projects &&
        this.organization.projects.length
      ) {
        this.goToProject(this.organization.projects[0].id)
      }
      this.fetched = true
    },
    async modifyQuestions() {
      const { templateParams } = this
      const data = {}
      for (const key in templateParams) {
        const value = templateParams[key]
        if (value !== null) {
          data[key] = value
        }
      }
      try {
        await this.modifyOnboardingQuestions(data)
      } catch (error) {}
    }
  },
  created() {
    this.fetchData()
  },
  components: {
    BaseAvatar,
    BaseLoading,
    BaseAngleIcon,
    CreateProject,
    CenteredLayout,
    CreatingProject,
    TemplateSelection,
    OnboardingAddMember,
    OnboardingQuestionSteps
  }
}
</script>
