<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    content-class="small-level-one"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.members.title')"
  >
    <template slot="toolbarActions">
      <v-menu
        bottom
        :right="$vuetify.rtl"
        :left="!$vuetify.rtl"
        transition="slide-x-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            small
            v-on="on"
            v-bind="attrs"
            color="primary"
            v-text="$t('common.add')"
          />
        </template>
        <v-list>
          <v-list-item
            dense
            :key="i"
            @click="item.action"
            v-for="(item, i) in items"
          >
            <v-list-item-icon class="me-2">
              <base-icon color="secondary.base" :name="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="body-2 info--text"
                v-text="
                  $t(
                    `page.project_settings.members.invitation.${item.title}.action_text`
                  )
                "
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <base-pending-members-list
      :loading="loading"
      :members="projectPendingUsers"
      @action-clicked="handleClickOnPendingMemberAction"
    />
    <base-members-list-with-actions
      @action-clicked="openDialog"
      :members="projectApprovedUsers"
    >
      <template v-slot:actions="scope">
        <v-btn icon @click="scope.next('delete')">
          <base-icon
            width="24"
            height="24"
            name="trash-alt"
            color="secondary.base"
          />
        </v-btn>
      </template>
    </base-members-list-with-actions>
    <base-remove-member-confiramtion-dialog
      v-if="member"
      :member="member"
      :loading="loading"
      @ok-clicked="deleteMember"
      :dialog.sync="confirmationDialog"
    />
    <settings-dialog-list-members-fab
      v-if="isMobile"
      @invite-by-link-clicked="inviteByLinkDialog = true"
      @invite-by-email-clicked="inviteByEmailDialog = true"
    />
    <settings-dialog-list-members-invite-by-email
      :teams="teams"
      :project="project"
      v-if="project && teams"
      :dialog.sync="inviteByEmailDialog"
    />
    <settings-dialog-list-members-invite-by-link
      v-if="teams"
      :teams="teams"
      :project="project && teams"
      :dialog.sync="inviteByLinkDialog"
    />
  </base-dialog>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapGetters, mapActions } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import { createProjectMembershipInvitation } from '@/api/projects'
import BasePendingMembersList from '@/components/BasePendingMembersList'
import BaseMembersListWithActions from '@/components/BaseMembersListWithActions'
import SettingsDialogListMembersFab from '@/components/project/SettingsDialogListMembersFab'
import BaseRemoveMemberConfiramtionDialog from '@/components/BaseRemoveMemberConfiramtionDialog'
import SettingsDialogListMembersInviteByLink from '@/components/project/SettingsDialogListMembersInviteByLink'
import SettingsDialogListMembersInviteByEmail from '@/components/project/SettingsDialogListMembersInviteByEmail'
export default {
  computed: {
    ...mapGetters([
      'teams',
      'isMobile',
      'projectPendingUsers',
      'projectApprovedUsers'
    ])
  },
  props: {
    project: {
      type: Object
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [
        {
          title: 'invite_by_email',
          icon: 'at',
          action: () => {
            this.inviteByEmailDialog = true
          }
        },
        {
          title: 'invite_by_link',
          icon: 'link',
          action: () => {
            this.inviteByLinkDialog = true
          }
        }
      ],
      member: null,
      loading: false,
      confirmationDialog: false,
      inviteByLinkDialog: false,
      inviteByEmailDialog: false
    }
  },
  methods: {
    ...mapActions([
      'getTeams',
      'getProjectMemberships',
      'displaySuccessMessage',
      'addOrganizationMembers',
      'deleteProjectMemberships',
      'deleteOrganizationMemberships'
    ]),
    openDialog({ member }) {
      this.confirmationDialog = true
      this.member = member
    },
    async deleteMember() {
      this.loading = true
      try {
        await this.deleteProjectMemberships({
          pid: this.project.id,
          mids: [this.member.membership_id],
          data: {
            user_ids: [this.member.id]
          }
        })
        this.confirmationDialog = false
      } catch (error) {}
      this.loading = false
    },
    async resendInvitation() {
      this.loading = true
      try {
        const { data } = await createProjectMembershipInvitation(
          this.member.membership_id
        )
        this.displaySuccessMessage(data.data.message)
      } catch (error) {}
      this.loading = false
    },
    handleClickOnPendingMemberAction({ member, e }) {
      this.member = member
      if (e === 'delete') {
        return this.deleteMember()
      }
      this.resendInvitation()
    },
    async getData() {
      this.fetching = true
      try {
        await this.getTeams({
          pid: this.project.id,
          params: {
            limit: 100,
            include: ['memberships']
          }
        })
        this.setItems()
      } catch (error) {}
      this.fetching = false
    }
  },
  mounted() {
    this.getData()
  },
  components: {
    BaseIcon,
    BaseDialog,
    BasePendingMembersList,
    BaseMembersListWithActions,
    SettingsDialogListMembersFab,
    BaseRemoveMemberConfiramtionDialog,
    SettingsDialogListMembersInviteByLink,
    SettingsDialogListMembersInviteByEmail
  }
}
</script>
