<template>
  <fragment>
    <base-list
      :multiple="true"
      icon-class="my-2"
      :mandatory="false"
      :filterable="false"
      :value.sync="setOne"
      :items="permanentTeams"
      :has-empty-state="false"
    />
    <v-divider v-if="permanentTeams.length && customTeams.length" />
    <base-list
      :multiple="true"
      icon-class="my-2"
      :mandatory="false"
      :filterable="false"
      :value.sync="setTwo"
      :items="customTeams"
      :has-empty-state="false"
    />
  </fragment>
</template>

<script>
import BaseList from '@/components/BaseList'

export default {
  props: {
    teams: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      team: null,
      setOne: [],
      setTwo: []
    }
  },
  computed: {
    selectedTeams() {
      return [...this.setOne, ...this.setTwo]
    },
    records() {
      if (!this.teams) {
        return []
      }
      return this.teams.records
    },
    permanentTeams() {
      return this.records.filter((team) => team.permanent)
    },
    customTeams() {
      return this.records.filter((team) => !team.permanent)
    }
  },
  watch: {
    selectedTeams(newValue) {
      this.$emit('teams-changed', newValue)
    }
  },
  components: {
    BaseList
  }
}
</script>
