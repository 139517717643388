<template>
  <base-dialog
    :dialog="visible"
    card-color="white"
    @go-back-clicked="$emit('update:visible', false)"
    content-class="search-modal small-level-one"
  >
    <template v-slot:toolbar>
      <v-toolbar flat height="60" color="white" class="flex-grow-0">
        <v-text-field
          flat
          dense
          outlined
          height="46"
          single-line
          hide-details
          v-model="query"
          class="ma-0 pa-0"
          background-color="white"
          :placeholder="$t('form.placeholders.search_timelogs')"
        >
          <template v-slot:prepend-inner class="mt-0">
            <v-btn
              icon
              x-small
              width="28"
              height="28"
              class="ma-0"
              @click="$emit('update:visible', false)"
            >
              <base-icon
                width="24"
                height="24"
                color="secondary.darken2"
                className="d-flex align-center"
                :name="!$vuetify.rtl ? 'arrow-left' : 'arrow-right'"
              />
            </v-btn>
          </template>
        </v-text-field>
      </v-toolbar>
    </template>
    <v-list dense flat color="transparent" style="margin-bottom: 54px">
      <timelogs-search-dialog-lists :option.sync="options.list_ids" />
      <timelogs-search-dialog-tasks :option.sync="options.task_ids" />
      <timelogs-search-dialog-users :option.sync="options.user_ids" />
      <timelogs-search-dialog-date
        :to.sync="options.started_at_to"
        :from.sync="options.started_at_from"
      />
      <timelogs-search-dialog-labels
        :withNo.sync="withNo.labels"
        :withAny.sync="withAny.labels"
        :option.sync="options.label_ids"
      />
      <timelogs-search-dialog-tags
        :withNo.sync="withNo.tags"
        :withAny.sync="withAny.tags"
        :option.sync="options.tag_ids"
      />
    </v-list>
    <timelogs-dialog-search-results
      @intersect-reached="onIntersect"
      @sort-changed="handleSortChange"
      @export-clicked="handleExport"
      :active="searchHasCriteria"
      :sort-loading="sortLoading"
      :loading-more="loading"
      :searching="searching"
    />
  </base-dialog>
</template>

<script>
import BaseIcon from '../BaseIcon'
import BaseDialog from '../BaseDialog'
import { mapActions, mapGetters } from 'vuex'
import TimelogsSearchDialogTags from '@/components/search/SearchDialogTags'
import TimelogsSearchDialogDate from '@/components/search/SearchDialogDate'
import TimelogsSearchDialogLists from '@/components/search/SearchDialogLists'
import TimelogsSearchDialogTasks from '@/components/search/SearchDialogTasks'
import TimelogsSearchDialogUsers from '@/components/search/SearchDialogUsers'
import TimelogsSearchDialogLabels from '@/components/search/SearchDialogLabels'
import TimelogsDialogSearchResults from '@/components/timelogs/TimelogsSearchDialogResults'

export default {
  computed: {
    ...mapGetters(['timelogsGroup', 'timelogsSearchResults']),
    pid() {
      return this.$route.params.pid
    },
    searchParams() {
      return {
        ...this.options,
        with_no: [this.withNo.labels, this.withNo.tags].filter(
          (item) => item !== null
        ),
        with_any: [this.withAny.labels, this.withAny.tags].filter(
          (item) => item !== null
        )
      }
    },
    params() {
      return {
        active: false,
        limit: this.limit,
        ...this.searchParams,
        aggregate_by: this.timelogsGroup,
        sort_by: this.timelogs ? this.sort : null,
        expand: !this.timelogsGroup ? ['user', 'task'] : null
      }
    },
    searchHasCriteria() {
      return Object.values(this.searchParams).some(
        (item) => item && item.length
      )
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: 0,
      limit: 20,
      sort: null,
      query: null,
      loading: false,
      searching: false,
      sortLoading: false,
      isIntersecting: false,
      withAny: {
        labels: null,
        tags: null
      },
      withNo: {
        labels: null,
        tags: null
      },
      options: {
        q: '',
        tag_ids: [],
        list_ids: [],
        task_ids: [],
        user_ids: [],
        label_ids: [],
        started_at_to: null,
        started_at_from: null
      }
    }
  },
  methods: {
    ...mapActions([
      'exportTimelogs',
      'displaySuccessMessage',
      'getTimelogsSearchResults',
      'addTimelogsSearchResults',
      'setTimelogsSearchResults'
    ]),
    async getData() {
      this.searching = true
      try {
        await this.getTimelogsSearchResults({
          pid: this.pid,
          params: this.params
        })
      } catch (error) {}
      this.sortLoading = false
      this.searching = false
    },
    async addData() {
      this.loading = true
      try {
        await this.addTimelogsSearchResults({
          pid: this.pid,
          params: {
            ...this.params,
            offset_token: this.timelogsSearchResults
              ? this.timelogsSearchResults.offset_token
              : null
          }
        })
      } catch (error) {}
      this.loading = false
    },
    async handleExport() {
      try {
        this.displaySuccessMessage(this.$i18n.t('page.timelogs.export_started'))
        await this.exportTimelogs({
          pid: this.pid,
          data: this.searchParams
        })
      } catch (error) {}
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting
    },
    handleSortChange(sort) {
      this.sort = sort
      this.sortLoading = true
      this.getData()
    }
  },
  watch: {
    isIntersecting(newValue) {
      if (
        !newValue ||
        !this.timelogsSearchResults ||
        !this.timelogsSearchResults.offset_token ||
        this.loading
      ) {
        return
      }
      this.addData()
    },
    searchParams: {
      handler() {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.getData()
        }, 1000)
      },
      deep: true
    },
    query(newValue) {
      this.options.q = newValue
    },
    timelogsGroup() {
      if (!this.searchHasCriteria) {
        return
      }
      this.setTimelogsSearchResults(null)
      this.getData()
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    TimelogsSearchDialogTags,
    TimelogsSearchDialogDate,
    TimelogsSearchDialogTasks,
    TimelogsSearchDialogUsers,
    TimelogsSearchDialogLists,
    TimelogsSearchDialogLabels,
    TimelogsDialogSearchResults
  }
}
</script>
