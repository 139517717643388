<template>
  <fragment>
    <v-list class="ma-2 py-0 rounded-sm" dense v-if="!isMobile">
      <v-list-item :ripple="false" @click="open">
        <v-list-item-content>
          <v-list-item-title
            class="h5-text info--text"
            v-text="$t('common.add_member')"
          />
        </v-list-item-content>
        <v-list-item-icon>
          <base-angle-icon
            width="24"
            height="24"
            fill="secondary.base"
            class="flip-horizontal"
          />
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <base-members-list-with-actions
      class="fill-width"
      :members="teamUsers"
      v-if="teamUsers.length > 0"
      @action-clicked="openConfirmationDialog"
    >
      <template v-slot:actions="scope">
        <v-btn icon @click="scope.next('delete')">
          <base-icon
            width="24"
            height="24"
            name="trash-alt"
            color="secondary.base"
          />
        </v-btn>
      </template>
    </base-members-list-with-actions>
    <div
      class="fill-width d-flex align-center justify-center flex-column"
      v-else
    >
      <members-empty-state @add-member-clicked="open" />
    </div>
    <v-btn
      fab
      fixed
      bottom
      depressed
      width="56"
      height="56"
      @click="open"
      direction="top"
      color="primary"
      :left="$vuetify.rtl"
      :right="!$vuetify.rtl"
      v-if="isMobile && teamUsers.length > 0 && availableMembers.length > 0"
    >
      <unicon name="user-plus" fill="#ffffff" />
    </v-btn>
    <base-dialog
      :loading="loading"
      card-color="white"
      :dialog.sync="dialog"
      :show-save-btn="true"
      @save-clicked="addMembers"
      @go-back-clicked="members = []"
      :save-btn-text="$t('common.add')"
      content-class="small-level-three"
      card-text-class="d-flex flex-column"
      :disable-save-btn="members.length === 0"
      :title="$t('common.member', { count: 0 })"
    >
      <section class="d-flex flex-column flex-grow-1">
        <h2
          class="mx-3 my-4 text-h6 info--text flex-grow-0"
          v-html="
            $t('page.project_settings.teams_and_access.add_members', {
              title: team.title
            })
          "
        />
        <base-members-list
          :avatar-size="32"
          :members.sync="members"
          :users="availableMembers"
          subtitle-prop="username"
          placeholder="form.placeholders.search_members"
          emptyListText="search_and_filter.results.not_found.members"
        />
      </section>
    </base-dialog>
    <base-confirm-dialog
      v-if="member"
      :loading="loading"
      ok-color="error base"
      cancel-color="secondary darken-2"
      @ok-clicked="removeMember"
      :ok-text="$t('common.remove')"
      :dialog.sync="confirmationDialog"
      :cancel-text="$t('common.cancel')"
      :title="
        $t(
          'page.project_settings.teams_and_access.remove_from_team_confirmation.title'
        )
      "
    >
      <div
        v-html="
          $t(
            'page.project_settings.teams_and_access.remove_from_team_confirmation.content',
            {
              team: team.title,
              user: member.name,
              project: project.title,
              interpolation: { escapeValue: false }
            }
          )
        "
      />
    </base-confirm-dialog>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseDialog from '@/components/BaseDialog'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import BaseMembersList from '@/components/BaseMembersList'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'
import MembersEmptyState from '@/components/emptyStates/MembersEmptyState'
import BaseMembersListWithActions from '@/components/BaseMembersListWithActions'

export default {
  props: {
    team: {
      type: Object,
      required: true
    },
    teams: {
      type: Array,
      required: true
    },
    project: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'projectApprovedUsers']),
    currentTeam() {
      if (!this.team) {
        return undefined
      }
      return this.teams.find((team) => team.id === this.team.id)
    },
    teamUsers() {
      return this.currentTeam
        ? this.currentTeam.memberships.map((member) => member.user)
        : []
    },
    availableMembers() {
      const teamUsersIds = this.teamUsers.map((user) => user.id)
      return this.projectApprovedUsers.filter(
        (user) => teamUsersIds.indexOf(user.id) < 0
      )
    }
  },
  data() {
    return {
      members: [],
      member: null,
      dialog: false,
      loading: false,
      confirmationDialog: false
    }
  },
  methods: {
    ...mapActions(['addTeamMembers', 'deleteTeamMembers']),
    open() {
      this.dialog = true
    },
    openConfirmationDialog({ member }) {
      this.member = member
      this.confirmationDialog = true
    },
    async addMembers() {
      this.loading = true
      try {
        await this.addTeamMembers({
          tid: this.team.id,
          data: {
            user_ids: this.members
          }
        })
        this.dialog = false
      } catch (error) {}
      this.loading = false
    },
    async removeMember() {
      this.loading = true
      try {
        await this.deleteTeamMembers({
          tid: this.team.id,
          data: {
            user_ids: [this.member.id]
          }
        })
        this.confirmationDialog = false
      } catch (error) {}
      this.loading = false
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseAngleIcon,
    BaseMembersList,
    BaseConfirmDialog,
    MembersEmptyState,
    BaseMembersListWithActions
  }
}
</script>
