var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"dialog":_vm.taskDialogIsVisible,"cardTextClass":"task-dialog-content","content-class":"task-modal full-dialog large-level-one"},on:{"go-back-clicked":_vm.handleClickOnGoBack},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('v-toolbar',{staticClass:"flex-grow-0 border-bottom-light-grey",attrs:{"flat":"","extended":"","height":"90","extension-height":"42"},scopedSlots:_vm._u([(_vm.task && _vm.tabs)?{key:"extension",fn:function(){return [_c('v-tabs',{staticClass:"flex-grow-0",attrs:{"height":"42","center-active":"","show-arrows":false},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(_tab,index){return _c('v-tab',{key:index,staticClass:"text-button",on:{"click":function () { return _vm.changeRoute(_tab.route); }}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t(("common." + (_tab.title)), { count: 0 }))+" ")])])}),1)]},proxy:true}:null],null,true)},[_c('base-dialog-back-btn',{on:{"go-back-clicked":_vm.handleClickOnGoBack}}),(_vm.task)?_c('v-toolbar-title',{staticClass:"text-h5 info--text"},[_vm._v(" "+_vm._s(_vm.task.title)+" "),(_vm.project && _vm.projectLists && _vm.projectBoards)?_c('h3',{staticClass:"caption secondary--text subtitle mt-2",domProps:{"innerHTML":_vm._s(
            _vm.$t('page.task_view.subtitle_new', {
              number: ("#" + (_vm.task.number)),
              creator: _vm.task.creator.name,
              createdDate: _vm.$t('date.formats.iso.long', {
                date: new Date(_vm.task.created_at * 1000)
              }),
              interpolation: { escapeValue: false }
            })
          )}}):_vm._e()]):_vm._e(),_c('v-spacer'),(_vm.task)?_c('task-dialog-actions',{attrs:{"priority":_vm.listAbilityPriority},on:{"task-archive-toggled":function($event){return _vm.$emit('task-archive-toggled', _vm.task)}}}):_vm._e()],1)]},proxy:true}])},[(_vm.project && _vm.task)?_c('div',{class:{ 'tab-wrapper': _vm.tabs[_vm.tab].title === 'comment' }},[_c('v-tabs-items',{staticClass:"sections flex-grow-1 secondary lighten-5",attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"fill-width",attrs:{"transition":false}},[_c('task-dialog-details',{ref:"taskDetails",attrs:{"priority":_vm.listAbilityPriority}})],1),_c('v-tab-item',{attrs:{"transition":false}},[_c('task-dialog-files',{ref:"taskAttachments",attrs:{"user":_vm.user,"users":_vm.organizationUsers,"priority":_vm.listAbilityPriority,"attachments":_vm.task.attachments},on:{"go-back-checked":_vm.goBack}})],1),_c('v-tab-item',{staticClass:"flex-grow-1",attrs:{"transition":false}},[(_vm.task)?_c('task-dialog-checklists',{attrs:{"checklists":_vm.task.checklists,"priority":_vm.listAbilityPriority}}):_vm._e()],1),_c('v-tab-item',{attrs:{"transition":false}},[_c('task-dialog-comments',{ref:"taskComments"})],1),_c('v-tab-item',{attrs:{"transition":false}},[_c('task-dialog-activities')],1)],1)],1):_c('base-loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }