<template>
  <v-select
    dense
    rounded
    outlined
    hide-details
    :value="group"
    :items="groups"
    style="width:230px"
    color="secondary darken-1"
    @change="$emit('update:group', $event)"
    class="body-2 secondary--text text--darken-2"
  >
    <template v-slot:selection="{ item }">
      {{ $t(`page.my_tasks.groups.${item}`) }}
    </template>
    <template v-slot:item="{ item }">
      <base-icon
        :name="getGroupIcon(item)"
        className="d-flex align-center me-3"
        :color="item === group ? 'primary' : 'secondary.darken2'"
      />
      <span
        class="body-2"
        v-text="$t(`page.my_tasks.groups.${item}`)"
        :class="item === group ? 'primary--text' : 'secondary--text text--darken-2'"
      />
    </template>
  </v-select>
</template>

<script>
import BaseIcon from '@/components/BaseIcon.vue'
import { groups, groupIcons } from '@/constants/my-tasks'
export default {
  props: {
    group: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      groups
    }
  },
  methods: {
    getGroupIcon(group) {
      return groupIcons[group] || 'eye'
    }
  },
  components: {
    BaseIcon
  }
}
</script>
