<template>
  <base-loading v-if="loading" />
  <div
    class="pa-8 d-flex fill-width flex-column align-center justify-center"
    v-else
  >
    <section class="d-flex flex-column align-center justify-center">
      <p>Auth completed!</p>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseLoading from '@/components/BaseLoading'
import { setDriveAccessTokenInLocalStorage } from '@/utils/auth'
export default {
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters(['driveAccessToken']),
    code() {
      const { query } = this.$route
      return query.code || null
    },
    state() {
      const { query } = this.$route
      return query.state || null
    }
  },
  methods: {
    ...mapActions(['generateGoogleDriveAccessToken']),
    close() {
      window.close()
    },
    async setToken() {
      if (!this.code || !this.state) {
        return
      }
      const pathname = '/google-auth-redirect'
      const redirectUrl =
        process.env.NODE_ENV === 'development'
          ? `${location.protocol}//localhost:${location.port}${pathname}`
          : `${location.origin}${pathname}`
      await this.generateGoogleDriveAccessToken({
        oid: this.state,
        data: {
          code: this.code,
          redirect_uri: redirectUrl
        }
      })
      await setDriveAccessTokenInLocalStorage(
        this.driveAccessToken
      )
      this.loading = false
      this.close()
    }
  },
  mounted() {
    this.setToken()
  },
  components: {
    BaseLoading
  }
}
</script>
