<template>
  <div class="mb-4 d-flex flex-column align-center justify-center">
    <input
      ref="file"
      type="file"
      class="d-none"
      accept="image/*"
      @change="handleFileChange"
    />
    <v-badge bottom avatar bordered offset-x="20" offset-y="20">
      <template v-slot:badge>
        <v-avatar color="white" size="24">
          <base-icon name="edit-alt" width="13" height="13" />
        </v-avatar>
      </template>
      <v-menu bottom left :disabled="isMobile">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            size="72"
            v-on="on"
            v-bind="attrs"
            color="secondary lighten-2"
            @click="handleClickOnAvatar"
            class="cursor pointer border-light-grey"
            style="background-color: white !important"
          >
            <v-progress-circular
              size="20"
              width="2"
              indeterminate
              color="primary"
              v-if="uploading"
            />
            <template v-else>
              <v-img :src="src" v-if="hasImage" />
              <template v-else>
                <base-avatar
                  image=""
                  :size="72"
                  :title="title"
                  v-if="hasTitle"
                  text-size="text-h2"
                />
                <base-icon name="camera-plus" width="24" height="24" v-else />
              </template>
            </template>
          </v-avatar>
        </template>
        <base-avatar-image-uploader-list
          :has-image="hasImage"
          @add-photo-clicked="handleClickOnAddImage"
          @remove-photo-clicked="handleClickOnRemoveImage"
        />
      </v-menu>
    </v-badge>
    <v-bottom-sheet v-model="sheet">
      <v-sheet>
        <base-avatar-image-uploader-list
          :has-image="hasImage"
          @add-photo-clicked="handleClickOnAddImage"
          @remove-photo-clicked="handleClickOnRemoveImage"
        />
      </v-sheet>
    </v-bottom-sheet>
    <span
      v-text="errorMsg"
      class="body-2 error--text"
      v-show="errorMsg.length > 0"
    />
  </div>
</template>

<script>
import filesize from 'filesize'
import { mapGetters } from 'vuex'
import { createFile } from '@/api/files'
import BaseIcon from '@/components/BaseIcon'
import BaseAvatar from '@/components/BaseAvatar'
import uploadService from '@/services/uploadService'
import BaseAvatarImageUploaderList from '@/components/BaseAvatarImageUploaderList'
export default {
  name: 'BaseAvatarImageUploader',
  props: {
    src: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    maxFileSize: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    hasImage() {
      return this.src.length > 0
    },
    hasTitle() {
      return this.title.length > 0
    }
  },
  data() {
    return {
      progress: 0,
      fileId: null,
      sheet: false,
      errorMsg: '',
      cancelObj: {},
      uploading: false
    }
  },
  methods: {
    toggleUploading() {
      this.uploading = !this.uploading
    },
    setFileSizeValidatioErrorMsg() {
      this.errorMsg = this.$i18n.t('form.errors.file_size_is_too_big', {
        size: filesize(this.maxFileSize, { exponent: 2 })
      })
    },
    setUploadProgress(progress) {
      this.progress = progress
    },
    handleUploadError() {
      this.toggleUploading()
      this.fileId = null
      this.progress = 0
      this.errorMsg = this.$i18n.t('form.errors.something_went_wrong')
      this.$emit('image-upload-failed')
    },
    handleUploadDone() {
      this.$emit('image-uploaded', this.fileId)
      this.progress = 0
      this.toggleUploading()
    },
    async uploadFile(file) {
      const createdFile = await createFile()
      const id = createdFile.data.data.id
      this.fileId = id
      uploadService.chunk(
        `/files/${id}`,
        file,
        this.setUploadProgress,
        this.handleUploadError,
        this.handleUploadDone,
        this.cancelObj
      )
    },
    setPreviewImage(file) {
      this.toggleUploading()
      this.$emit('image-src-updated', URL.createObjectURL(file))
      this.uploadFile(file)
    },
    handleClickOnAvatar() {
      this.sheet = this.isMobile
    },
    handleClickOnAddImage() {
      this.errorMsg = ''
      this.sheet = false
      this.$refs.file.value = ''
      this.$refs.file.click()
    },
    handleClickOnRemoveImage() {
      this.sheet = false
      this.$emit('remove-photo-clicked')
    },
    handleFileChange(e) {
      const { files } = e.target
      if (!files) {
        return
      }
      const file = files[0]
      if (!file) {
        return
      }
      const { maxFileSize } = this
      if (maxFileSize && file.size > maxFileSize) {
        return this.setFileSizeValidatioErrorMsg()
      }
      this.setPreviewImage(file)
    }
  },
  components: {
    BaseIcon,
    BaseAvatar,
    BaseAvatarImageUploaderList
  }
}
</script>
