import Quill from 'quill'
import { emailPattern, mobilePattern } from '@/mixins/FormValidations'
const Link = Quill.import('formats/link')

const validations = {
  isMobile: (v) => mobilePattern.test(v),
  isEmail: (v) => emailPattern.test(v)
}

class linkTypes extends Link {
  static create(value) {
    const node = super.create(value)
    value = this.sanitize(value)
    if (validations.isMobile(value)) {
      node.setAttribute('href', 'tel:' + value)
      node.className = 'link--tel'
    }
    if (validations.isEmail(value)) {
      node.setAttribute('href', 'mailto:' + value)
      node.className = 'link--mail'
    }
    if (value.startsWith('https://') || value.startsWith('http://')) {
      node.className = 'link--external'
    } else {
      node.removeAttribute('target')
    }
    return node
  }
}

export default linkTypes
