<template>
  <fragment>
    <v-btn
      :value="item"
      :color="isActive ? 'primary' : 'white'"
    >
      <base-icon
        height="20"
        :name="name"
        class="d-flex align-center"
        :color="isActive ? 'white' : 'primary'"
      />
      <span class="body-1 ms-1" :class="isActive ? 'white--text' : 'secondary--text text--darken-2'">
        {{ $t(`common.boards.${item}`) }}
      </span>
    </v-btn>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'

export default {
  components: { BaseIcon },
  props: {
    item: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'isMobileDevice'
    ])
  }
}
</script>
