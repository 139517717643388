<template>
  <v-list v-if="organizationAbilities">
    <v-list-item
      :key="item.name"
      v-for="item in items"
      @click="item.dialogVisible = true"
      :class="{
        'd-none':
          item.ability !== false &&
          organizationAbilities.abilities[item.ability] === false
      }"
    >
      <v-list-item-action class="me-4">
        <base-icon
          width="24"
          height="24"
          color="primary"
          :name="item.icon"
          className="d-flex align-center"
        />
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title
          class="body-2 info--text"
          v-text="$t(`page.organization_settings.${item.name}.title`)"
        />
      </v-list-item-content>
      <v-list-item-icon>
        <base-angle-icon
          width="24"
          height="24"
          fill="secondary.base"
          class="flip-horizontal"
        />
      </v-list-item-icon>
      <base-dialog
        card-color="white"
        :dialog.sync="item.dialogVisible"
        :show-save-btn="item.hasSaveButton"
        @save-clicked="item.dialogVisible = false"
        :content-class="`${item.contentClass} small-level-one`"
        :title="
          item.name === 'members'
            ? membersDialogTitle
            : $t(`page.organization_settings.${item.name}.title`)
        "
      >
        <template v-if="item.toolbarComponent && !isMobile" slot="toolbar">
          <compoent
            :is="item.toolbarComponent"
            :dialog.sync="item.dialogVisible"
            :title="$t(`page.project_settings.${item.name}.title`)"
          />
        </template>
        <component
          :name="item.name"
          v-if="organization"
          :dialog.sync="item.dialogVisible"
          :is="`OrganizationSettingsList${item.component}`"
        />
      </base-dialog>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseDialog from '@/components/BaseDialog'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import OrganizationSettingsListMembersSettings from '@/components/organization/OrganizationSettingsListMembersSettings'
import OrganizationSettingsListProjectsSettings from '@/components/organization/OrganizationSettingsListProjectsSettings'
import OrganizationSettingsListPermissionsSettings from '@/components/organization/OrganizationSettingsListPermissionsSettings'
import OrganizationSettingsListInformationsSettings from '@/components/organization/OrganizationSettingsListInformationsSettings'
import OrganizationSettingsListMembersSettingsToolbar from '@/components/organization/OrganizationSettingsListMembersSettingsToolbar'

export default {
  computed: {
    ...mapGetters([
      'isMobile',
      'organization',
      'organizationAbilities',
      'organizationApprovedUsers'
    ]),
    membersDialogTitle() {
      return this.$i18n.t(
        'page.organization_settings.members.title_with_count',
        { count: this.organizationApprovedUsers.length }
      )
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseAngleIcon,
    OrganizationSettingsListMembersSettings,
    OrganizationSettingsListProjectsSettings,
    OrganizationSettingsListPermissionsSettings,
    OrganizationSettingsListInformationsSettings,
    OrganizationSettingsListMembersSettingsToolbar
  },
  data() {
    return {
      items: [
        {
          contentClass: 'half-dialog',
          ability: 'edit_info',
          name: 'information',
          icon: 'exclamation-circle',
          dialogVisible: false,
          hasSaveButton: false,
          component: 'InformationsSettings'
        },
        {
          contentClass: '',
          ability: 'manage_members',
          name: 'members',
          icon: 'users-alt',
          dialogVisible: false,
          hasSaveButton: false,
          component: 'MembersSettings',
          toolbarComponent: OrganizationSettingsListMembersSettingsToolbar
        },
        {
          contentClass: '',
          ability: false,
          name: 'projects',
          icon: 'layer-group',
          dialogVisible: false,
          hasSaveButton: false,
          component: 'ProjectsSettings'
        },
        {
          contentClass: '',
          ability: 'manage_permissions',
          name: 'permissions',
          icon: 'shield-check',
          dialogVisible: false,
          hasSaveButton: false,
          component: 'PermissionsSettings'
        }
      ]
    }
  }
}
</script>
