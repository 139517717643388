<template>
  <fragment>
    <v-toolbar-title class="ms-0 pl-0">
      <v-btn
        text
        :class="isMobile ? 'pa-0' : 'ps-6'"
        color="secondary"
        :ripple="false"
        v-if="translationInitialized"
        @click="searchDialogIsVisible = true"
      >
        <base-icon
          v-if="!isMobile"
          name="search"
          class-name="d-flex align-center pe-2"
          color="secondary.base"
        />
        {{ $t('form.placeholders.search_tasks') }}
      </v-btn>
    </v-toolbar-title>
    <tasks-search-dialog
      v-if="project"
      :visible.sync="searchDialogIsVisible"
    />
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import TasksSearchDialog from '@/components/tasks/TasksSearchDialog'
import BaseIcon from '@/components/BaseIcon'

export default {
  computed: {
    ...mapGetters(['project', 'translationInitialized', 'isMobile'])
  },
  data() {
    return {
      searchDialogIsVisible: false
    }
  },
  components: {
    TasksSearchDialog,
    BaseIcon
  }
}
</script>
