<template>
  <v-bottom-sheet
    v-if="isMobile"
    :retain-focus="false"
    @click:outside="close"
    :value="value && isMobile"
    content-class="rounded-t-lg"
  >
    <v-card flat class="text-center rounded-t-lg">
      <v-card-title class="px-4 d-flex align-center justify-space-between">
        <h3 class="text-h3 info--text" v-text="title" />
        <v-btn
          text
          @click="close"
          color="secondary darken-2"
          v-text="$t('common.close')"
        />
      </v-card-title>
      <v-card-text :class="cardTextClassName"><slot /></v-card-text>
    </v-card>
  </v-bottom-sheet>
  <v-dialog
    v-else
    width="56%"
    max-width="470"
    @click:outside="close"
    @keydown.esc="close"
    :value="value && !isMobile"
    transition="slide-x-transition"
  >
    <v-card>
      <v-card-title class="text-h5 px-4 font-weight-medium" v-text="title" />
      <v-card-text class="body-2 secondary--text" :class="cardTextClassName">
        <slot />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    cardTextClassName: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    close() {
      this.$emit('update:value', false)
      this.$emit('closed')
    }
  }
}
</script>
