<template>
  <section class="flex-grow-0">
    <v-chip label class="overflow-chip rounded-sm" color="secondary lighten-2">
      <base-icon
        :name="icon"
        v-if="icon.length"
        color="secondary.darken2"
        className="d-flex align-center me-2"
      />
      <span class="secondary--text text--darken-2">
        <slot>
          <template v-if="task">
            <strong>#{{ task.number }}</strong>
            {{ task.title }}
          </template>
        </slot>
      </span>
    </v-chip>
  </section>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'

export default {
  props: {
    task: {
      type: Object
    },
    icon: {
      type: String,
      default: 'copy'
    }
  },
  components: {
    BaseIcon
  }
}
</script>
