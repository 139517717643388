<template>
  <centered-layout>
    <template v-slot:appbar>
      <v-app-bar-nav-icon @click="goBack">
        <base-angle-icon name="t-arrow" width="16" height="16" />
      </v-app-bar-nav-icon>
      <v-toolbar-title
        class="pa-0 flex-grow-1 d-flex justify-center"
        :style="`margin-${$vuetify.rtl ? 'right' : 'left'}:-38px`"
      >
        <base-logo />
      </v-toolbar-title>
    </template>
    <div class="">
      <h3
        v-if="step === 1"
        v-text="$t('page.reset_password.subtitle')"
        class="text-h1 info--text fill-width mt-4 mb-8"
      />
      <sessions-reset-password-form
        v-if="step === 1"
        :loading="loading"
        :email.sync="form.email"
        :form-is-valid.sync="formIsValid"
        @form-submitted="handleClickOnResetPassword"
      />
      <div class="fill-width" v-if="step === 2">
        <h3
          v-text="$t('page.reset_password.title')"
          class="text-h1 info--text fill-width mt-4 mb-8"
        />
        <base-code-input
          :loading="loading"
          @complete="handleClickOnDone"
          @change="handleCodeInputChange"
        />
        <p
          class="caption secondary--text text--darken-2 mt-8 mb-6"
          v-html="$t('page.reset_password.enter_code', { email: form.email })"
        />
      </div>
      <v-form
        v-if="step === 3"
        class="fill-width"
        v-model="passwordFormIsValid"
        @submit.prevent="handleClickOnSetPassword"
      >
        <h3
          v-text="$t('page.reset_password.title')"
          class="text-h1 info--text fill-width mt-4 mb-8"
        />
        <p
          class="caption secondary--text text--darken-2 my-6"
          v-html="
            $t('page.reset_password.password_reset_for', { email: form.email })
          "
        />
        <base-password
          :rules="rules.password"
          :validate-on-blur="false"
          :value.sync="form.password"
          :label="$t('form.labels.password')"
        />
      </v-form>
      <div class="d-flex flex-column fill-width align-center" v-if="step === 4">
        <v-avatar color="success lighten-5" size="64">
          <base-icon name="check" color="success.base" width="34" height="34" />
        </v-avatar>
        <p class="body-2 secondary--text text--darken-2 mt-4 mb-1">
          {{ $t('pages.resetPassword.passwordResetSuccessfully') }}
        </p>
        <router-link
          :to="{ path: '/signin' }"
          class="body-2 text-decoration-none"
        >
          {{ $t('common.sign_in') }}
        </router-link>
      </div>
    </div>
    <div class="flex-grow-0 mb-6 fill-width">
      <v-btn
        block
        depressed
        color="primary"
        v-if="step === 1"
        :loading="loading"
        :disabled="!formIsValid"
        @click="handleClickOnResetPassword"
      >
        {{ $t('common.reset_password') }}
      </v-btn>
      <v-btn
        block
        depressed
        color="primary"
        v-if="step === 2"
        :loading="loading"
        :disabled="!form.code"
        @click="handleClickOnDone(code)"
      >
        {{ $t('common.done') }}
      </v-btn>
      <v-btn
        block
        depressed
        color="primary"
        v-if="step === 3"
        :loading="loading"
        :disabled="!passwordFormIsValid"
        @click="handleClickOnSetPassword"
      >
        {{ $t('common.reset_password') }}
      </v-btn>
    </div>
  </centered-layout>
</template>

<script>
import BaseLogo from '@/components/BaseLogo'
import BaseIcon from '@/components/BaseIcon'
import { mapGetters, mapActions } from 'vuex'
import BasePassword from '@/components/BasePassword'
import CenteredLayout from '@/layouts/CenteredLayout'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import BaseCodeInput from '@/components/BaseCodeInput'
import SessionsResetPasswordForm from '@/components/sessions/SessionsResetPasswordForm'

export default {
  computed: {
    ...mapGetters(['loading'])
  },
  data() {
    return {
      step: 1,
      form: {
        email: '',
        key: null,
        code: null,
        token: null,
        password: ''
      },
      rules: {
        password: this.getPasswordRules()
      },
      formIsValid: false,
      passwordFormIsValid: false
    }
  },
  methods: {
    ...mapActions(['authRequest', 'signIn', 'displayResponseError']),
    handleClickOnResetPassword() {
      if (!this.formIsValid) {
        return
      }
      this.authRequest({
        url: '/users/password/send',
        data: {
          email: this.form.email
        },
        method: 'post',
        successCallback: (res) => {
          this.form.token = res.data.data.token
          this.step = 2
        },
        errorCallback: (error) => {
          this.displayResponseError(error)
        }
      })
    },
    handleClickOnDone(code) {
      this.authRequest({
        url: '/users/password/verify',
        data: {
          token: this.form.token,
          code
        },
        method: 'post',
        successCallback: (res) => {
          this.form.key = res.data.data.key
          this.step = 3
        }
      })
    },
    handleClickOnSetPassword() {
      this.authRequest({
        url: '/users/password/reset',
        data: {
          key: this.form.key,
          password: this.form.password
        },
        method: 'post',
        successCallback: (res) => {
          this.signIn(res)
        }
      })
    },
    handleCodeInputChange(code) {
      this.form.code = code
    },
    goBack() {
      const { step } = this
      if (step === 1 || step === 4) {
        return this.$router.push({ path: '/signin' })
      }
      this.step--
    }
  },
  components: {
    BaseLogo,
    BaseIcon,
    BasePassword,
    BaseAngleIcon,
    BaseCodeInput,
    CenteredLayout,
    SessionsResetPasswordForm
  }
}
</script>
