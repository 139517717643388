<template>
  <div class="mt-2 px-2 fill-width overflow-x-hidden" id="permissions">
    <settings-dialog-list-teams-and-access-permissions-board-select
      :boards="boards"
      @go-to-top="scrollToTop"
      @go-to-board="scrollToBoard"
    />
    <div class="fill-width overflow-y-auto overflow-x-auto permissions">
      <settings-dialog-list-teams-and-access-permissions-header
        v-if="boardPermissions.length > 0"
        :abilities="teamPermissionAbilities"
      />
      <template v-for="(board, index) in boardPermissions">
        <settings-dialog-list-teams-and-access-permissions-row
          :item="board"
          :id="`board_${board.id}`"
          :show-header="index === 0"
          :key="`board_${board.id}`"
          :abilities="teamPermissionAbilities"
          class="mt-2 info--text"
          @permission-changed="handleBoardPermissionChange($event)"
        />
        <section :key="`board_lists_${board.id}`">
          <settings-dialog-list-teams-and-access-permissions-row
            :item="list"
            :key="list.id"
            :padded="true"
            v-for="list in board.lists"
            class="secondary--text text--darken-2"
            :abilities="teamPermissionAbilities"
            @permission-changed="handleListPermissionChange($event)"
          />
        </section>
      </template>
    </div>
    <div class="fixed-button">
      <v-btn
        block
        depressed
        color="primary"
        :loading="loading"
        :disabled="loading"
        v-if="!team.permanent"
        @click="handleClickOnSave"
        v-text="$t('common.save')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { patchTeamPermissions } from '@/api/teams'
import SettingsDialogListTeamsAndAccessPermissionsRow from './SettingsDialogListTeamsAndAccessPermissionsRow'
import SettingsDialogListTeamsAndAccessPermissionsHeader from './SettingsDialogListTeamsAndAccessPermissionsHeader'
import SettingsDialogListTeamsAndAccessPermissionsBoardSelect from '@/components/project/SettingsDialogListTeamsAndAccessPermissionsBoardSelect'
export default {
  components: {
    SettingsDialogListTeamsAndAccessPermissionsRow,
    SettingsDialogListTeamsAndAccessPermissionsHeader,
    SettingsDialogListTeamsAndAccessPermissionsBoardSelect
  },
  props: {
    permissions: {
      type: Array,
      required: true
    },
    teamPermissionAbilities: {
      type: Array,
      required: true
    },
    boards: {
      type: Array,
      required: true
    },
    lists: {
      type: Array,
      required: true
    },
    team: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      boardPermissions: []
    }
  },
  methods: {
    ...mapActions(['displaySuccessMessage']),
    getData() {
      const flat = this.boardPermissions.reduce(
        (acc, val) => [
          {
            id: val.id,
            type: val.type,
            abilities: val.abilities
          },
          ...acc.concat(
            val.lists.map((list) => {
              return {
                id: list.id,
                type: list.type,
                abilities: list.abilities
              }
            })
          )
        ],
        []
      )
      return flat.map((item) => {
        const abilities = {}
        for (const key in item.abilities) {
          abilities[key] = item.abilities[key].value
        }
        return {
          record: {
            type: item.type,
            id: item.id
          },
          abilities
        }
      })
    },
    getAbilities(item, boardId) {
      const abilitiesObj = {}
      const { teamPermissionAbilities } = this
      if (item && item.abilities) {
        for (const key in item.abilities) {
          abilitiesObj[key] = {
            value: item.abilities[key],
            disabled: this.team.permanent ? true : this.getHasPriorAbility(item, key, boardId)
          }
        }
        return abilitiesObj
      }
      for (let i = 0; i < teamPermissionAbilities.length; i++) {
        abilitiesObj[teamPermissionAbilities[i].id] = {
          value: false,
          disabled: this.team.permanent
        }
      }
      return abilitiesObj
    },
    getBoardPermissions() {
      const { permissions } = this
      return this.boards.map((board) => {
        const boardPermission = permissions.find(
          (p) => p.record.id === board.id
        )
        const boardLists = this.lists.filter(
          (list) => list.board.id === board.id
        )
        return {
          id: board.id,
          type: 'Board',
          title: board.title,
          abilities: this.getAbilities(boardPermission),
          lists: boardLists.map((list) => {
            const listPermission = permissions.find(
              (p) => p.record.id === list.id
            )
            return {
              id: list.id,
              type: 'List',
              title: list.title,
              abilities: this.getAbilities(listPermission, board.id)
            }
          })
        }
      })
    },
    setBoardPermissions() {
      this.boardPermissions = this.getBoardPermissions()
    },
    getHasPriorAbility(item, ability, boardId = null) {
      const abilities = this.teamPermissionAbilities.map((a) => a.id)
      const length = abilities.length
      const index = abilities.indexOf(ability)
      if (index < 0 || index === length - 1) {
        return false
      }
      if (boardId) {
        const board = this.permissions.find(
          (permission) => permission.record.id
        )
        const boardAbility = board.abilities[ability]
        if (boardAbility) {
          return boardAbility
        }
      }
      const nextAbility = abilities[index + 1]
      return item.abilities[nextAbility]
    },
    handleListPermissionChange({ value, ability, item }) {
      const list = item
      const abilities = this.teamPermissionAbilities.map((a) => a.id)
      const index = abilities.indexOf(ability.id)
      const listAbilities = list.abilities
      for (const key in listAbilities) {
        const currentAbilityIndex = abilities.indexOf(key)
        if (currentAbilityIndex < index) {
          listAbilities[key].value = value
          listAbilities[key].disabled = value
        }
      }
    },
    handleBoardPermissionChange({ value, ability, item }) {
      const board = item
      const boardAbilities = board.abilities
      const abilities = this.teamPermissionAbilities.map((a) => a.id)
      const index = abilities.indexOf(ability.id)
      for (const key in boardAbilities) {
        const currentAbilityIndex = abilities.indexOf(key)
        if (currentAbilityIndex < index) {
          boardAbilities[key].value = value
          boardAbilities[key].disabled = value
        }
      }
      const { lists } = board
      for (let i = 0; i < lists.length; i++) {
        const listAbilities = lists[i].abilities
        for (const key in listAbilities) {
          const currentAbilityIndex = abilities.indexOf(key)
          if (currentAbilityIndex < index) {
            listAbilities[key].value = value
            listAbilities[key].disabled = value
          }
        }
        listAbilities[ability.id].value = value
        listAbilities[ability.id].disabled = value
      }
    },
    async handleClickOnSave() {
      this.loading = true
      try {
        await patchTeamPermissions(this.team.id, {
          permissions: this.getData()
        })
        this.displaySuccessMessage('Changes saved successfully')
      } catch (error) {}
      this.loading = false
    },
    scrollToBoard(boardId) {
      this.$scrollTo(`#board_${boardId}`, 300, {
        container: '#permissions',
        offset: -120
      })
    },
    scrollToTop() {
      this.$scrollTo('#permissions', 300, {
        container: '#permissions'
      })
    }
  },
  mounted() {
    this.setBoardPermissions()
  }
}
</script>

<style lang="scss">
.permissions {
  padding-bottom: 50px;
  .sticky {
    position: sticky;
    z-index: 1;
    &.stick {
      &-left {
        left: 16px;
      }
      &-right {
        right: 16px;
      }
    }
  }
}
.fixed-button {
  z-index: 2;
  left: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 8px;
}
</style>
