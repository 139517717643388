var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],attrs:{"fab":"","fixed":"","bottom":"","width":"56","height":"56","direction":"top","color":"primary","left":_vm.$vuetify.rtl,"right":!_vm.$vuetify.rtl},on:{"click":_vm.open}},[_c('base-icon',{attrs:{"name":"user-plus","color":"white"}})],1),_c('base-dialog',{attrs:{"card-color":"white","content-class":"small-level-two","dialog":_vm.interfaceDialogs.inviteOrganizationMember,"title":_vm.$t('page.organization_settings.members.add_members.title')},on:{"go-back-clicked":function($event){return _vm.toggle(false)}},scopedSlots:_vm._u([{key:"cardActions",fn:function(){return [_c('div',{staticClass:"flex-grow-0 pa-4"},[_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary","loading":_vm.loading,"disabled":_vm.emails.length === 0 || _vm.loading},domProps:{"textContent":_vm._s(_vm.$t('common.add'))},on:{"click":_vm.addMember}})],1)]},proxy:true}])},[_c('section',{staticClass:"px-4"},[_c('p',{staticClass:"mt-6 body-2 secondary--text",domProps:{"innerHTML":_vm._s(
          _vm.$t('page.organization_settings.members.add_members.description', {
            fee: '10$',
            plan: 'Professional',
            interpolation: { escapeValue: false },
            organization: this.organization.title
          })
        )}})]),_c('base-add-member-by-email',{staticClass:"mt-6",attrs:{"invitees":_vm.emails,"dialog":_vm.interfaceDialogs.inviteOrganizationMember},on:{"update:invitees":function($event){_vm.emails=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }