<template>
  <v-container fluid class="white rounded-sm" style="min-width:480px">
    <v-row no-gutters>
      <v-col
        cols="4"
        :class="{ 'stick-left': !$vuetify.rtl, 'stick-right': $vuetify.rtl }"
        class="white sticky text-button text-no-wrap text-truncate"
      >
        <span v-text="item.title" :class="{ 'ps-2': padded }" />
      </v-col>
      <v-col
        cols="2"
        :key="ability.id"
        v-for="ability in abilities"
        class="justify-center d-flex"
      >
        <v-checkbox
          hide-details
          :ripple="false"
          v-model="item.abilities[ability.id].value"
          :disabled="item.abilities[ability.id].disabled"
          class="d-flex justify-center ma-0 pa-0"
          @change="
            $emit('permission-changed', { id: item.id, value: $event, ability, item })
          "
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    showHeader: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    padded: {
      type: Boolean,
      default: false
    },
    abilities: {
      type: Array,
      required: true
    }
  }
}
</script>
