<template>
  <v-scroll-y-reverse-transition
    group
    tag="div"
    name="v-list"
    hide-on-leave
  >
    <v-list-item
      :key="member.id"
      v-for="member in members"
      :class="{ 'px-0': noItemPadding }"
    >
      <v-list-item-avatar size="40">
        <base-avatar
          :size="40"
          text-size="text-h2"
          :title="member.name"
          :image="member.avatar ? member.avatar.medium : ''"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="body-2 info--text" v-text="member.name" />
        <v-list-item-subtitle
          class="caption secondary--text"
          v-text="`@${member.username}`"
        />
      </v-list-item-content>
      <v-list-item-action class="flex-row" v-if="hasAction">
        <slot
          name="actions"
          :next="
            (e) => {
              $emit('action-clicked', { member, e })
            }
          "
        />
      </v-list-item-action>
    </v-list-item>
  </v-scroll-y-reverse-transition>
</template>

<script>
import BaseAvatar from '@/components/BaseAvatar'
export default {
  props: {
    members: {
      type: Array,
      required: true
    },
    hasAction: {
      type: Boolean,
      default: true
    },
    noItemPadding: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseAvatar
  }
}
</script>
