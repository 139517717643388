<template>
  <v-card tile flat color="white" height="100%">
    <v-toolbar flat height="60" color="white" class="flex-grow-0">
      <base-dialog-back-btn
        back-icon="times"
        :disabled="loading"
        @go-back-clicked="hideCreateOrganizationDialog"
      />
      <v-toolbar-title
        class="text-h3"
        v-text="$t('page.create_organization.title')"
      />
    </v-toolbar>
    <v-card-text class="px-0">
      <v-container class="py-0 d-flex flex-column align-center">
        <v-card max-width="360" flat>
          <div
            class="text-h1 info--text mb-8"
            v-text="$t('page.create_organization.subtitle')"
          />
          <v-card-text class="pa-0 overflow-y-hidden">
            <create-organization-form
              :loading="loading"
              ref="organizationForm"
              @get-started-button-hit="handleClickOnCreateOrganization"
            />
          </v-card-text>
        </v-card>
        <div class="d-flex fill-width onboarding-nav mt-6">
          <div class="progress">
            <v-progress-linear
              rounded
              height="6"
              color="primary"
              :value="progress"
            />
          </div>
        </div>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseDialogBackBtn from '@/components/BaseDialogBackBtn'
import CreateOrganizationForm from '@/components/organization/CreateOrganizationForm'

export default {
  computed: {
    ...mapGetters(['organizationSubdomain']),
    progress() {
      return 25
    }
  },
  data() {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'addOrganiztion',
      'getOrganizations',
      'hideCreateOrganizationDialog'
    ]),
    toggleLoading() {
      this.loading = !this.loading
    },
    goToOnboarding(subdomain) {
      window.open(
        `${location.protocol}//${location.host.replace(
          this.organizationSubdomain,
          subdomain
        )}/onboarding`,
        'blank'
      )
    },
    async handleClickOnCreateOrganization(form) {
      this.toggleLoading()
      try {
        await this.addOrganiztion(form)
        await this.getOrganizations()
        this.goToOnboarding(form.subdomain)
        this.hideCreateOrganizationDialog()
      } catch (error) {}
      this.toggleLoading()
    }
  },
  components: {
    BaseDialogBackBtn,
    CreateOrganizationForm
  }
}
</script>
