<template>
  <base-dialog
    :dialog="dialog"
    :loading="loading"
    card-color="white"
    :show-save-btn="true"
    :disable-save-btn="saveDisabled"
    content-class="large-level-three"
    @save-clicked="handleAccountVerification"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="
      $t('page.profile_setting.security.authentication.authenticator.title')
    "
  >
    <div class="pa-4">
      <h2
        class="body-1 info--text"
        v-text="
          $t(
            'page.profile_setting.security.authentication.authenticator.enable.title'
          )
        "
      />
      <p
        class="mt-3 body-1 secondary--text text--darken-2"
        v-text="
          $t(
            'page.profile_setting.security.authentication.authenticator.enable.instructions_part_one'
          )
        "
      />
      <section class="mt-4 mb-4">
        <v-img
          width="200"
          min-height="200"
          v-if="twoFactorAuthSetupInfo"
          :src="twoFactorAuthSetupInfo.qr_code"
        />
        <br />
        <input
          readonly
          type="text"
          id="app_code"
          style="width: 280px"
          class="muted body-1"
          :value="
            twoFactorAuthSetupInfo ? twoFactorAuthSetupInfo.otp_secret : null
          "
        />
        <v-btn
          icon
          v-if="twoFactorAuthSetupInfo"
          @click="copyVerificationCodeToClipboard"
        >
          <base-icon name="copy" color="secondary.darken2" />
        </v-btn>
        <v-progress-circular
          v-else
          :size="20"
          :width="1"
          indeterminate
          color="primary"
        />
      </section>
      <p
        class="mt-3 mb-6 body-1 secondary--text text--darken-2"
        v-text="
          $t(
            'page.profile_setting.security.authentication.authenticator.enable.instructions_part_two'
          )
        "
      />
      <div class="centered-container pb-4">
        <base-code-input
          v-if="dialog"
          :loading="loading"
          @change="handleCodeInputChange"
          @complete="handleAccountVerification"
        />
      </div>
    </div>
  </base-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseDialog from '@/components/BaseDialog'
import BaseCodeInput from '@/components/BaseCodeInput'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['twoFactorAuthSetupInfo']),
    saveDisabled() {
      return this.verificationCode.length < 6
    }
  },
  data() {
    return {
      loading: false,
      verificationCode: '',
      verificationCodeRules: this.getGoogleAuthCodeRules()
    }
  },
  methods: {
    ...mapActions([
      'enableTwoFactorAuth',
      'generateTwoFactorAuthSetup',
      'setTwoFactorAuthSetupInfo'
    ]),
    copyVerificationCodeToClipboard() {
      const textToCopy = document.getElementById('app_code')
      textToCopy.select()
      textToCopy.setSelectionRange(0, 99999)
      document.execCommand('copy')
    },
    handleCodeInputChange(code) {
      this.verificationCode = code
    },
    async handleAccountVerification() {
      this.loading = true
      try {
        await this.enableTwoFactorAuth({
          code: this.verificationCode
        })
        this.$emit('update:dialog', false)
      } catch (error) {}
      this.loading = false
    }
  },
  watch: {
    dialog(newValue) {
      this.setTwoFactorAuthSetupInfo(null)
      this.verificationCode = ''
      if (newValue) {
        try {
          this.generateTwoFactorAuthSetup({
            qr_code_size: 200
          })
        } catch (error) {}
      }
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseCodeInput
  }
}
</script>
