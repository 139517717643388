<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    content-class="half-dialog small-level-one"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.features.title')"
  >
    <v-list>
      <v-list-item
        :key="item.name"
        v-for="item in items"
        @click="item.dialogVisible = true"
      >
        <v-list-item-avatar min-width="22" width="22" class="me-2">
          <base-icon
            color="primary"
            :name="item.icon"
            className="d-flex align-center"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="
              body-1
              d-flex
              info--text
              text--darken-4
              justify-space-between
            "
          >
            <span>{{
              $t(`page.project_settings.features.${item.name}.title`)
            }}</span>
            <span v-if="item.subtitle" class="secondary--text text--darken-2">
              {{ item.subtitle }}
            </span>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <base-angle-icon
            width="20"
            height="20"
            class="flip-horizontal"
            fill="secondary.darken2"
          />
        </v-list-item-icon>
        <base-dialog
          cardColor="white"
          content-class="small-level-two"
          :dialog.sync="item.dialogVisible"
          :title="$t(`page.project_settings.features.${item.name}.title`)"
        >
          <template v-if="project && projectFeatures">
            <component
              :name="item.name"
              :project="project"
              :features="projectFeatures"
              @cancel-clicked="item.dialogVisible = false"
              :is="`SettingsDialogListFeatures${item.component}`"
            />
          </template>
          <base-loading v-else />
        </base-dialog>
      </v-list-item>
    </v-list>
  </base-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseDialog from '@/components/BaseDialog'
import BaseLoading from '@/components/BaseLoading'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import SettingsDialogListFeaturesTaskWeight from '@/components/project/SettingsDialogListFeaturesTaskWeight'

export default {
  props: {
    project: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['projectFeatures'])
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseLoading,
    BaseAngleIcon,
    SettingsDialogListFeaturesTaskWeight
  },
  data() {
    return {
      items: [
        {
          name: 'task_weight',
          icon: 'balance-scale',
          dialogVisible: false,
          component: 'TaskWeight'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['getProjectFeatures']),
    async getData() {
      try {
        this.getProjectFeatures(this.project.id)
      } catch (error) {}
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
