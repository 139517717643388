<template>
  <v-list-item dense color="#fff" :class="className" :disabled="loading">
    <v-list-item-avatar
      class="pa-0 ma-0"
      :width="avatarWidth"
      @click="dialog = true"
      :min-width="avatarWidth"
    >
      <v-btn
        fab
        x-small
        outlined
        color="primary"
        :width="avatarWidth"
        :height="avatarWidth"
        @click="$emit('add-clicked')"
      >
        <span class="subtitle-1">+</span>
      </v-btn>
    </v-list-item-avatar>
    <v-list-item-content
      class="cursor pointer ps-2"
      @click="$emit('add-clicked')"
    >
      <v-list-item-title>
        <v-progress-circular
          :size="10"
          :width="1"
          indeterminate
          v-if="loading"
          color="primary"
        />
        <span class="ms-1 body-2 primary--text">{{ text }}</span>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    avatarWidth: {
      type: String,
      default: '24'
    },
    className: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
