<template>
  <search-dialog-results
    :active="active"
    :searching="searching"
    :has-results="hasResults"
    :loading-more="loadingMore"
    @export-clicked="$emit('export-clicked')"
    @intersect-reached="$emit('intersect-reached', $event)"
    :result-count="hasResults ? tasksSearchResults.total_count : 0"
  >
    <template v-if="tasksSearchResults">
      <task-list-section-task
        class="mb-2"
        :task="task"
        :key="task.id"
        :users="projectUsers"
        :list="getTaskList(task)"
        :board="getTaskBoard(task)"
        v-for="task in tasksSearchResults.records"
      />
    </template>
  </search-dialog-results>
</template>

<script>
import { mapGetters } from 'vuex'
import SearchDialogResults from '@/components/search/SearchDialogResults'
import TaskListSectionTask from '@/components/project/TaskListSectionTask'
export default {
  name: 'TaskSearchDialogResults',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    searching: {
      type: Boolean,
      required: true
    },
    loadingMore: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['projectUsers', 'projectLists', 'projectBoards', 'tasksSearchResults']),
    hasResults() {
      return (
        this.tasksSearchResults !== null &&
        this.tasksSearchResults.total_count > 0 &&
        this.active
      )
    }
  },
  methods: {
    getTaskList(task) {
      return this.projectLists[task.list.id]
    },
    getTaskBoard(task) {
      const { projectBoards } = this
      const list = this.getTaskList(task)
      return projectBoards ? projectBoards[list.board.id] : ''
    }
  },
  components: {
    SearchDialogResults,
    TaskListSectionTask
  }
}
</script>
