<template>
  <fragment>
    <unicon
      v-if="viewBox"
      :name="name"
      :fill="fill"
      :width="width"
      :height="height"
      :viewBox="viewBox"
      :class="className"
    />
    <unicon
      v-else
      :name="name"
      :fill="fill"
      :width="width"
      :height="height"
      :class="className"
    />
  </fragment>
</template>

<script>
import { getRef } from '@/utils/helpers'
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    },
    viewBox: {
      type: String
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    fill() {
      const { color } = this
      const { theme } = this.$vuetify
      const themeType = theme.isDark ? 'dark' : 'light'
      return `${getRef(theme.themes[themeType], color)}`
    }
  }
}
</script>
