import request from '@/api/axios'

export const fetchLabels = (pid) =>
  request({
    url: `/orgs/#oid/projects/${pid}/labels`,
    method: 'get'
  })

export const patchLabel = (lid, data) =>
  request({
    url: `/orgs/#oid/labels/${lid}`,
    method: 'patch',
    data
  })

export const toggleLabel = (lid, path) =>
  request({
    url: `/orgs/#oid/labels/${lid}/${path}`,
    method: 'post'
  })
