import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import randomstring from 'randomstring'
import { VueAuthenticate } from 'vue-authenticate'

const { protocol, origin } = location
const redirectUri = `${origin}/auth/callback`

axios.defaults.baseURL = `${protocol}//${process.env.VUE_APP_MOCK_API_URL}/api/v2`

axios.defaults.headers.common = {
  'Accept-Version': 'v2'
}

Vue.use(VueAxios, axios)

export default new VueAuthenticate(Vue.prototype.$http, {
  tokenName: 'taskulu',
  responseDataKey: 'data',
  tokenPath: 'data.session.id',
  baseUrl: `${protocol}//${process.env.VUE_APP_API_URL}/api/oauth2/`,
  bindRequestInterceptor: function() {
    this.$http.interceptors.request.use(config => {
      if (this.isAuthenticated()) {
        config.headers.Authorization = [
          this.options.tokenType,
          this.getToken()
        ].join(' ')
      } else {
        delete config.headers.Authorization
      }
      return config
    })
  },
  providers: {
    google: {
      redirectUri,
      url: 'google/tokens',
      state: randomstring.generate(12),
      optionalUrlParams: ['display', 'state'],
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
    }
  }
})
