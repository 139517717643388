<template>
  <base-list
    :items="users"
    :value="members"
    title-prop="name"
    :filterable="true"
    :multiple="multiple"
    :subtitle-prop="subtitleProp"
    :empty-list-text="emptyListText"
    :search-label="$t(`${placeholder}`)"
    @item-clicked="$emit('item-clicked', $event)"
    @value-changed="$emit('update:members', $event)"
  >
    <template v-slot:listitemstart="{ item }">
      <v-list-item-avatar size="40">
        <base-avatar
          :size="40"
          text-size="text-h2"
          :title="item.name"
          :image="item.avatar ? item.avatar.medium : ''"
        />
      </v-list-item-avatar>
    </template>
    <template v-slot:listitemend="{ item }">
      <v-list-item-icon
        v-if="item.disabled !== undefined && item.disabled === true"
      >
        <base-icon
          width="20"
          height="20"
          color="error"
          name="exclamation-octagon"
        />
      </v-list-item-icon>
    </template>
  </base-list>
</template>

<script>
import BaseList from '@/components/BaseList'
import BaseIcon from '@/components/BaseIcon'
import BaseAvatar from '@/components/BaseAvatar'
export default {
  props: {
    emptyListText: {
      type: String,
      default: 'search_and_filter.results.not_found.assignees'
    },
    members: {
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    avatarSize: {
      type: Number,
      default: 24
    },
    subtitleProp: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'form.placeholders.search_assignees'
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaseIcon,
    BaseList,
    BaseAvatar
  }
}
</script>
