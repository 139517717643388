<template>
  <centered-layout>
    <template v-slot:appbar>
      <v-app-bar-nav-icon @click="$router.push({ path: '/signin' })">
        <base-angle-icon name="t-arrow" width="16" height="16" />
      </v-app-bar-nav-icon>
      <v-toolbar-title
        class="pa-0 flex-grow-1 d-flex justify-center"
        :style="`margin-${$vuetify.rtl ? 'right' : 'left'}:-38px`"
      >
        <base-logo />
      </v-toolbar-title>
    </template>
    <sessions-two-factor-auth-form
      :loading="loading"
      v-if="twoFactorAuthInfo"
      :resend-loading="resendLoading"
      @verify-clicked="handleCodeVerification"
      :two-factor-auth-info="twoFactorAuthInfo"
      @resend-clicked="handleResendVerificationSms"
    />
  </centered-layout>
</template>

<script>
import BaseLogo from '@/components/BaseLogo'
import { mapGetters, mapActions } from 'vuex'
import CenteredLayout from '@/layouts/CenteredLayout'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import SessionsTwoFactorAuthForm from '@/components/sessions/SessionsTwoFactorAuthForm'
export default {
  data() {
    return {
      loading: false,
      resendLoading: false
    }
  },
  computed: {
    ...mapGetters(['twoFactorAuthInfo', 'verificationSmsCooldown'])
  },
  mounted() {
    if (!this.twoFactorAuthInfo) {
      this.$router.push({ name: 'SignIn' })
    }
  },
  methods: {
    ...mapActions([
      'resendVerificationSms',
      'verifyTwoFactorAuthCode',
      'displayResponseError'
    ]),
    toggleResendLoading() {
      this.resendLoading = !this.resendLoading
    },
    toggleLoading() {
      this.loading = !this.loading
    },
    async handleCodeVerification(code) {
      this.toggleLoading()
      await this.verifyTwoFactorAuthCode({
        doneCallback: this.toggleLoading,
        errorCallback: this.displayResponseError,
        data: { token: this.twoFactorAuthInfo.token, code },
        url: '/users/2fa/verify'
      })
    },
    async handleResendVerificationSms() {
      this.toggleResendLoading()
      await this.resendVerificationSms({
        url: '/users/2fa/send',
        data: { token: this.twoFactorAuthInfo.token },
        doneCallback: this.toggleResendLoading
      })
    }
  },
  components: {
    BaseLogo,
    BaseAngleIcon,
    CenteredLayout,
    SessionsTwoFactorAuthForm
  }
}
</script>
