<template>
  <v-list>
    <v-overlay
      absolute
      color="#fff"
      opacity="0.92"
      :value="loading"
      :z-index="5"
    >
      <v-progress-circular indeterminate color="primary" size="32" />
    </v-overlay>
    <v-list-item
      :key="source.title"
      @click="source.action"
      v-for="source in sources"
    >
      <v-list-item-icon class="me-3">
        <base-icon
          width="24"
          height="24"
          color="info"
          :name="source.icon"
          className="d-flex align-center"
        />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          class="body-1 info--text text-start"
          v-text="$t(`page.task_files.picker.${source.title}`)"
        />
      </v-list-item-content>
    </v-list-item>
    <input
      ref="file"
      type="file"
      :multiple="multiple"
      style="display: none"
      @change="handleFileChange"
    />
  </v-list>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import { driveAccessTokenName } from '@/utils/auth'

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['driveAuthUri', 'driveAccessToken']),
    driveRedirectUri() {
      const path = 'google-auth-redirect'
      const { protocol, port } = location
      if (process.env.NODE_ENV === 'development') {
        return `${protocol}//localhost:${port}/${path}`
      }
      const url = location.origin.replace(`${this.organizationSubdomain}.`, '')
      return `${url}/${path}`
    }
  },
  data() {
    return {
      loading: false,
      pickerApiLoaded: false,
      sources: [
        {
          title: 'local',
          icon: 'file',
          action: this.uploadFromLocal
        },
        {
          title: 'dropbox',
          icon: 'dropbox',
          action: this.uploadFromDropbox
        },
        {
          title: 'drive',
          icon: 'google-drive-alt',
          action: this.uploadFromDrive
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setDriveAccessToken', 'getGoogleDriveAccessToken']),
    handleFileChange(e) {
      const { files } = e.target
      if (!files) {
        return
      }
      this.$emit('local-files-added', files)
    },
    pickerCallback(data) {
      if (
        data[window.google.picker.Response.ACTION] ===
        window.google.picker.Action.PICKED
      ) {
        this.$emit('drive-files-added', data.docs)
      }
    },
    createGoogleDrivePicker() {
      this.loading = false
      if (this.pickerApiLoaded && this.driveAccessToken) {
        const sharedView = new window.google.picker.DocsView()
        sharedView.setOwnedByMe(false)
        const mineView = new window.google.picker.DocsView()
        mineView.setOwnedByMe(true)
        const generalView = new window.google.picker.DocsView()
        generalView.setParent('root').setIncludeFolders(true)
        const picker = new window.google.picker.PickerBuilder()
          .addView(mineView)
          .addView(sharedView)
          .addView(generalView)
          .setOAuthToken(this.driveAccessToken)
          .setDeveloperKey(process.env.VUE_APP_GOOGLE_DEVELOPER_KEY)
          .setCallback(this.pickerCallback)
          .setLocale('en')
          .setOrigin(window.location.protocol + '//' + window.location.host)
          .build()
        picker.setVisible(true)
      }
    },
    uploadFromDropbox() {
      const { Dropbox } = window
      if (!Dropbox) {
        console.log('Dropbox sdk not loaded!')
        return
      }
      const options = {
        success: (files) => {
          this.$emit('dropbox-files-added', files)
        },
        cancel: () => {},
        linkType: 'preview',
        multiselect: this.multiple
      }
      Dropbox.choose(options)
    },
    async uploadFromDrive() {
      this.loading = true
      try {
        await this.getGoogleDriveAccessToken({
          redirect_uri: this.driveRedirectUri
        })
        if (this.driveAccessToken) {
          return this.createGoogleDrivePicker()
        }
        window.open(this.driveAuthUri, '_blank')
        this.loading = false
      } catch (error) {}
    },
    uploadFromLocal(e) {
      this.$refs.file.value = ''
      this.$refs.file.click()
    },
    handleLocalStorageChange(event) {
      const { key, newValue } = event
      if (key === driveAccessTokenName) {
        this.setDriveAccessToken(newValue)
        this.createGoogleDrivePicker()
      }
    }
  },
  created() {
    window.addEventListener('storage', this.handleLocalStorageChange)
    if (!window.gapi) {
      console.log('Google API not loaded')
    } else {
      console.log('Google API loaded')
      window.gapi.load('auth2')
      window.gapi.load('picker', () => {
        this.pickerApiLoaded = true
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.handleLocalStorageChange)
  },
  components: {
    BaseIcon
  }
}
</script>
