<template>
  <fragment>
    <div
      transition="slide-x-transition"
      class="
        fill-width
        d-flex
        flex-column
        align-center
        justify-center
        flex-grow-1
      "
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="fill-width"
        :disabled="authenticating"
      >
        <h3
          class="text-h1 info--text fill-width mt-4 mb-8"
          v-text="$t('page.two_factor_authentication.title')"
        />
        <p
          transition="slide-y-transition"
          class="caption secondary--text text--darken-2 mb-6"
          v-text="$t('page.two_factor_authentication.google_auth_description')"
        />
        <base-code-input
          class="mb-10"
          :loading="loading"
          @change="handleCodeInputChange"
          @complete="handleAccountVerification"
        />
      </v-form>
    </div>
    <div class="flex-grow-0 mb-8 fill-width d-flex flex-column">
      <v-btn
        block
        depressed
        color="primary"
        @click="validate"
        :loading="loading"
        :disabled="!valid || authenticating"
      >
        {{ $t('common.sign_in') }}
      </v-btn>
    </div>
  </fragment>
</template>

<script>
import { mapActions } from 'vuex'
import BaseCodeInput from '@/components/BaseCodeInput'
export default {
  props: {
    twoFactorAuthInfo: {
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    resendLoading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      code: '',
      cooldown: 0,
      valid: true,
      useSms: false,
      remember: false,
      codeInserted: false,
      authenticating: false
    }
  },
  methods: {
    ...mapActions(['validate2FAToken']),
    validate() {
      if (this.$refs.form.validate()) {
        this.validate2FAToken({
          url: 'v1/sessions/two_factor',
          data: {
            login_token: this.twoFactorAuthInfo.token,
            code: this.code
          }
        })
      }
    },
    handleCodeInputChange(code) {
      this.code = code
      this.codeInserted = false
    },
    handleAccountVerification() {
      this.codeInserted = true
      this.$emit('verify-clicked', Number(this.code))
    }
  },
  components: {
    BaseCodeInput
  }
}
</script>
