export const notificationTypeIcons = {
  comment_mentioned: { name: 'at', color: 'primary' },
  task_due_soon: { name: 'stopwatch', color: 'primary' },
  task_description_mentioned: { name: 'at', color: 'primary' },
  organization_membership_created: { name: 'user-plus', color: 'success' },
  organization_membership_deleted: { name: 'user-times', color: 'error' },
  project_membership_deleted: { name: 'user-times', color: 'error' },
  project_membership_created: { name: 'user-plus', color: 'success' },
  task_starting_soon: { name: 't-start-date', color: 'primary' },
  organization_archived: { name: 't-archive', color: 'primary' },
  organization_deleted: { name: 'building', color: 'error' },
  project_archived: { name: 't-archive', color: 'primary' },
  project_deleted: { name: 'layer-group', color: 'error' },
  project_owernship_gained: { name: 'user-arrows', color: 'success' },
  organization_owernship_gained: { name: 'user-arrows', color: 'success' },
  list_title_edited: { name: 'list-ul', color: 'primary' },
  list_archived: { name: 't-archive', color: 'primary' },
  task_archived: { name: 't-archive', color: 'primary' },
  attachment_created: { name: 't-add-attachment', color: 'success' },
  attachment_deleted: { name: 't-remove-attachment', color: 'error' },
  comment_deleted: { name: 't-remove-comment', color: 'error' },
  comment_edited: { name: 'comment-alt-edit', color: 'primary' },
  comment_created: { name: 'comment-add', color: 'success' },
  task_copied: { name: 'copy', color: 'primary' },
  task_created: { name: 'plus', color: 'success' },
  task_due_at_removed: { name: 'stopwatch-slash', color: 'error' },
  task_due_at_edited: { name: 'stopwatch', color: 'primary' },
  task_description_edited: { name: 'align-left', color: 'primary' },
  task_dependees_edited: { name: 'hourglass', color: 'primary' },
  task_dependents_edited: { name: 'ban', color: 'primary' },
  task_moved: { name: 'exchange-alt', color: 'primary' },
  task_label_edited: { name: 'swatchbook', color: 'primary' },
  task_progress_removed: {
    name: 't-remove-circular-progress',
    color: 'error'
  },
  task_progress_edited: { name: 't-circular-progress', color: 'primary' },
  task_starting_at_removed: {
    name: 't-start-date-remove',
    color: 'error'
  },
  task_starting_at_edited: { name: 't-start-date', color: 'primary' },
  task_status_edited: { name: 'clipboard-alt', color: 'primary' },
  task_tags_edited: { name: 'tag', color: 'primary' },
  task_title_edited: { name: 'align-left', color: 'primary' },
  task_weight_removed: { name: 'balance-scale', color: 'error' },
  task_weight_edited: { name: 'balance-scale', color: 'primary' },
  task_assignees_edited: { name: 'at', color: 'primary' },
  task_unassigned: { name: 'at', color: 'error' },
  task_assigned: { name: 'at', color: 'success' },
  checklist_deleted: { name: 'list-ul', color: 'error' },
  checklist_item_deleted: { name: 'list-ul', color: 'error' },
  checklist_item_unchecked: { name: 't-remove-list-item', color: 'error' },
  checklist_item_checked: { name: 'check', color: 'success' },
  checklist_item_edited: { name: 'list-ul', color: 'primary' },
  checklist_item_created: { name: 'list-ul', color: 'success' },
  checklist_created: { name: 'list-ul', color: 'success' }
}

export const taskActivityIcons = {
  fallback: { name: 'file-question', color: 'primary' },
  call_ended: { name: 'phone-slash', color: 'error' },
  call_started: { name: 'phone', color: 'success' },
  checklist_deleted: { name: 'list-ul', color: 'error' },
  checklist_item_deleted: { name: 'list-ul', color: 'error' },
  checklist_item_unchecked: { name: 't-remove-list-item', color: 'error' },
  checklist_item_checked: { name: 'check', color: 'success' },
  checklist_item_edited: { name: 'list-ul', color: 'primary' },
  checklist_item_created: { name: 'list-ul', color: 'success' },
  checklist_created: { name: 'list-ul', color: 'success' },
  task_unarchived: { name: 't-unarchive', color: 'primary' },
  task_archived: { name: 't-archive', color: 'primary' },
  attachment_deleted: { name: 't-remove-attachment', color: 'error' },
  attachment_created: { name: 't-add-attachment', color: 'success' },
  comment_deleted: { name: 't-remove-comment', color: 'error' },
  comment_edited: { name: 'comment-alt-edit', color: 'primary' },
  comment_created: { name: 'comment-add', color: 'success' },
  task_copied: { name: 'copy', color: 'primary' },
  task_created: { name: 'plus', color: 'success' },
  task_due_at_removed: { name: 'stopwatch-slash', color: 'error' },
  task_due_at_edited: { name: 'stopwatch', color: 'primary' },
  task_description_edited: { name: 'align-left', color: 'primary' },
  task_dependees_edited: { name: 'hourglass', color: 'primary' },
  task_dependents_edited: { name: 'ban', color: 'primary' },
  task_moved: { name: 'exchange-alt', color: 'primary' },
  task_label_edited: { name: 'swatchbook', color: 'primary' },
  task_progress_removed: {
    name: 't-remove-circular-progress',
    color: 'error'
  },
  task_progress_edited: { name: 't-circular-progress', color: 'primary' },
  task_starting_at_removed: {
    name: 't-start-date-remove',
    color: 'error'
  },
  task_starting_at_edited: { name: 't-start-date', color: 'primary' },
  task_status_edited: { name: 'clipboard-alt', color: 'primary' },
  task_tags_edited: { name: 'tag', color: 'primary' },
  task_title_edited: { name: 'align-left', color: 'primary' },
  task_weight_removed: { name: 'balance-scale', color: 'error' },
  task_weight_edited: { name: 'balance-scale', color: 'primary' },
  task_assignees_edited: { name: 'at', color: 'primary' },
  timelog_stopped: { name: 'stopwatch', color: 'error' },
  timelog_started: { name: 'stopwatch', color: 'primary' }
}
