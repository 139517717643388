var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-column",attrs:{"height":"100%","flat":"","tile":""}},[_c('v-card-title',{staticClass:"\n      body-1\n      d-flex\n      flex-grow-0\n      align-center\n      info--text\n      text--darken-4\n    "},[_c('base-icon',{attrs:{"className":"me-2","name":"t-google-calendar"}}),_vm._v(" "+_vm._s(_vm.$t('page.project_settings.integrations.google_calendar.title'))+" ")],1),_c('v-card-text',{staticClass:"flex-grow-1"},[_c('p',{staticClass:"body-1",domProps:{"textContent":_vm._s(
        _vm.$t(
          'page.project_settings.integrations.google_calendar.content.paragraph_one'
        )
      )}}),_c('p',{staticClass:"body-1",domProps:{"textContent":_vm._s(
        _vm.$t(
          'page.project_settings.integrations.google_calendar.content.paragraph_two'
        )
      )}})]),(_vm.calendarIntegration)?_c('v-card-actions',{staticClass:"flex-grow-0 py-6"},[(!_vm.calendarIntegration.authorized)?_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary","loading":_vm.loading,"disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('page.project_settings.integrations.connect'))},on:{"click":_vm.connect}}):[(_vm.calendarIntegration.enabled)?_c('v-btn',{attrs:{"block":"","depressed":"","color":"error","loading":_vm.loading,"disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('page.project_settings.integrations.disable'))},on:{"click":function($event){_vm.dialog = true}}}):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.calendarIntegration.enabled),expression:"!calendarIntegration.enabled"}],attrs:{"block":"","depressed":"","color":"primary","loading":_vm.loading,"disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('page.project_settings.integrations.enable'))},on:{"click":function($event){return _vm.toggleStatus('enable')}}})]],2):_vm._e(),_c('base-confirm-dialog',{attrs:{"loading":_vm.loading,"dialog":_vm.dialog,"ok-color":"error base","cancel-color":"secondary darken-2","ok-text":_vm.$t('common.disable'),"cancel-text":_vm.$t('common.cancel'),"title":_vm.$t('page.project_settings.integrations.disable_confirmation.title')},on:{"update:dialog":function($event){_vm.dialog=$event},"ok-clicked":function($event){return _vm.toggleStatus('disable')}}},[_c('section',{domProps:{"innerHTML":_vm._s(
        _vm.$t(
          'page.project_settings.integrations.disable_confirmation.content',
          {
            provider: _vm.$t(
              'page.project_settings.integrations.google_calendar.title'
            ),
            interpolation: { escapeValue: false }
          }
        )
      )}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }