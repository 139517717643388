<template>
  <fragment>
    <v-overlay color="#fff" opacity="0.92" :value="fab" :z-index="5" />
    <v-speed-dial
      fixed
      v-model="fab"
      :bottom="true"
      direction="top"
      style="z-index: 6"
      :left="$vuetify.rtl"
      :right="!$vuetify.rtl"
      :open-on-hover="false"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
          fab
          dark
          width="58"
          height="58"
          v-model="fab"
          :color="fab ? 'white' : 'primary'"
        >
          <base-icon
            v-if="fab"
            width="24"
            height="24"
            name="times"
            color="secondary.darken2"
          />
          <unicon v-else name="plus" fill="#ffffff" />
        </v-btn>
      </template>
      <div
        class="fab-with-content"
        @click="$emit('invite-by-email-clicked')"
        :class="$vuetify.rtl ? 'right' : 'left'"
      >
        <v-btn
          fab
          dark
          small
          width="40"
          height="40"
          color="white"
          @click="$emit('invite-by-email-clicked')"
        >
          <base-icon name="at" color="primary" />
        </v-btn>
        <div
          v-text="
            $t(
              'page.project_settings.members.invitation.invite_by_email.action_text'
            )
          "
          class="text info--text text-button pointer cursor"
        />
      </div>
      <div
        class="fab-with-content"
        @click="$emit('invite-by-link-clicked')"
        :class="$vuetify.rtl ? 'right' : 'left'"
      >
        <v-btn
          fab
          dark
          small
          width="40"
          height="40"
          color="white"
          @click="$emit('invite-by-link-clicked')"
        >
          <base-icon name="link" color="primary" />
        </v-btn>
        <div
          v-text="
            $t(
              'page.project_settings.members.invitation.invite_by_link.action_text'
            )
          "
          class="text info--text text-button pointer cursor"
        />
      </div>
    </v-speed-dial>
  </fragment>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  data: () => ({
    fab: null
  }),
  components: {
    BaseIcon
  }
}
</script>
