import Quill from 'quill'
import EmojiBlot from '@/plugins/quill/emojiBlot'
import { getInitials, getColorFromPaletteBasedOnText } from '@/utils/helpers'

Quill.register(
  {
    'formats/emoji': EmojiBlot
  },
  true
)

export const convertDeltaToHtml = delta => {
  const tempCont = document.createElement('div')
  new Quill(tempCont).setContents(JSON.parse(delta))
  return tempCont.getElementsByClassName('ql-editor')[0].innerHTML
}

const getHashValues = tasks => {
  return tasks.map(task => {
    return {
      name: '',
      id: task.id,
      type: 'task',
      value: task.title,
      number: task.number,
      link: task.link || '#'
    }
  })
}

const getAtValues = users => {
  return [
    ...users.map(user => {
      return {
        id: user.id,
        type: 'user',
        name: user.name,
        avatar: user.avatar,
        value: user.username
      }
    })
  ]
}

const getMentionValues = (mentionChar, tasks, users) => {
  if (mentionChar === '@') {
    return getAtValues(users)
  }
  return getHashValues(tasks)
}
export const createContentString = (delta) => {
  return delta.ops.map((i) => {
    if (
      typeof i.insert === 'object' &&
      'mention' in i.insert
    ) {
      return i.insert.mention.denotationChar
    } else if (typeof i.insert === 'string') {
      return i.insert
    }
    return ''
  }).join('')
}
export const mentionsTaskOrMember = (selectedEditor, value) => {
  if (value) {
    const selection = selectedEditor.getSelection(true)
    selectedEditor.focus()
    selectedEditor.insertText(selection.index, value)
    selectedEditor.blur()
    selectedEditor.focus()
  }
}

export const getEditorMentionsConfig = (tasks, users, pathObj = null) => {
  return {
    isolateCharacter: true,
    allowedChars: /(\w*)$/,
    fixMentionsToQuill: false,
    defaultMenuOrientation: 'bottom',
    positioningStrategy: 'fixed',
    mentionDenotationChars: ['@', '#'],
    linkTarget: '_self',
    dataAttributes: ['id', 'value', 'denotationChar', 'type', 'name'],
    source: (searchTerm, renderList, mentionChar) => {
      const values = getMentionValues(mentionChar, tasks, users)
      if (searchTerm.length === 0) {
        renderList(values, searchTerm)
      } else {
        const matches = []
        for (let i = 0; i < values.length; i++) {
          if (
            ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()) ||
            ~values[i].name.toLowerCase().indexOf(searchTerm.toLowerCase())
          ) {
            matches.push(values[i])
          }
        }
        renderList(matches, searchTerm)
      }
    },
    renderItem: item => {
      if (item.type === 'user') {
        const bgColor = getColorFromPaletteBasedOnText(item.name)
        const initals = getInitials(item.name)
        const avatar = item.avatar
          ? `<img src="${item.avatar.small}" />`
          : `<div class="white--text caption">${initals}</div>`
        return `
          <div style="background:${bgColor}" class="mention-item-avatar">${avatar}</div>
          <div class="mention-item-content">
            <div class="title text-h5 info--text">${item.name}</div>
            <div class="subtitle caption secondary--text">@${item.value}</div>
          </div>`
      }
      const kicker = pathObj
        ? `<div class="subtitle caption secondary--text">${pathObj[item.id]
        }</div>`
        : ''
      return `
        <div class="mention-item-content">
          ${kicker}
          <div class="title text-h5 info--text">${item.value}</div>
        </div>
        <div class="mention-item-action caption secondary--text">#${item.number}</div>
      `
    }
  }
}
