<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    content-class="small-level-one"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.leave_project.title')"
  >
    <div
      class="
        px-4
        body-1
        d-flex
        fill-height
        flex-column
        text--darken-2
        secondary--text
      "
    >
      <div class="flex-grow-1">
        <p
          class="flex-grow-0 mt-4"
          v-html="
            $t('page.project_settings.leave_project.description', {
              title: project.title,
              interpolation: { escapeValue: false }
            })
          "
        />
        <p
          class="flex-grow-0"
          v-text="$t('page.project_settings.leave_project.confirmation')"
        />
        <v-form
          class="py-2"
          :disabled="loading"
          @submit.prevent="handleClickOnLeave"
        >
          <v-text-field
            flat
            dense
            outlined
            v-model="input"
            :label="$t('form.labels.project_title')"
          />
        </v-form>
      </div>
      <div class="flex-grow-0 py-6 d-flex justify-space-between">
        <v-btn
          depressed
          width="136"
          color="primary"
          @click="cancel"
          :disabled="loading"
          v-text="$t('common.cancel')"
        />
        <v-btn
          text
          width="136"
          color="error"
          :loading="loading"
          @click="handleClickOnLeave"
          :disabled="buttonDisabled || loading"
          v-text="$t('page.project_settings.leave_project.leave_button')"
        />
      </div>
      <settings-dialog-list-transfer-ownership
        @go-back="leave"
        :project="project"
        v-if="transferDialog"
        :display-success="false"
        :dialog.sync="transferDialog"
        content-class="small-level-two"
        nested-content-class="small-level-three"
      />
    </div>
  </base-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import SettingsDialogListTransferOwnership from '@/components/project/SettingsDialogListTransferOwnership'
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    userIsOwner() {
      return this.project.owner.id === this.user.id
    },
    buttonDisabled() {
      return this.input !== this.project.title
    }
  },
  data() {
    return {
      input: '',
      loading: false,
      transferDialog: false
    }
  },
  methods: {
    ...mapActions(['leaveProject', 'displaySuccessMessage']),
    async leave() {
      this.loading = true
      this.transferDialog = false
      try {
        await this.leaveProject(this.project.id)
        this.displaySuccessMessage(
          this.$i18n.t('page.project_settings.leave_project.success')
        )
        this.$router.push({ path: '/' })
      } catch (error) {}
      this.loading = false
    },
    handleClickOnLeave() {
      if (this.userIsOwner) {
        this.transferDialog = true
        return
      }
      this.leave()
    },
    cancel() {
      this.input = ''
      this.$emit('go-back')
    }
  },
  components: {
    BaseDialog,
    SettingsDialogListTransferOwnership
  }
}
</script>
