<template>
  <organization-settings-list />
</template>

<script>
import OrganizationSettingsList from '@/components/organization/OrganizationSettingsList'
export default {
  components: {
    OrganizationSettingsList
  }
}
</script>
