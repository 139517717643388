<template>
  <fragment>
    <v-container>
      <v-row class="info--text text-h5 px-1">
        <v-col>
          {{ $t('common.total_tracked_time') }}
        </v-col>
        <v-col class="text-end" v-text="getLocalizedNumber(totalTrackedTime)" />
      </v-row>
      <v-row class="secondary--text text--darken-2 text-h6 mt-0 px-1">
        <v-col>
          {{ $t('common.my_tracked_time') }}
          <div></div>
        </v-col>
        <v-col class="text-end" v-text="getLocalizedNumber(myTrackedTime)" />
      </v-row>
    </v-container>
    <base-loading v-if="!taskActivities" />
    <v-list three-line max-width="100%" v-else>
      <task-dialog-activities-activity
        :key="activity.id"
        :activity="activity"
        :icon="getIcon(activity.action)"
        :owner="getOwner(activity.creator.id)"
        v-for="activity in taskActivities.records"
      />
    </v-list>
    <base-loading-more v-if="loading" />
    <div v-intersect="onScroll" />
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseLoading from '@/components/BaseLoading'
import { taskActivityIcons } from '@/constants/icons'
import BaseLoadingMore from '@/components/BaseLoadingMore'
import TaskDialogActivitiesActivity from '@/components/task/TaskDialogActivitiesActivity'
export default {
  components: { BaseLoading, BaseLoadingMore, TaskDialogActivitiesActivity },
  computed: {
    ...mapGetters(['task', 'project', 'taskActivities', 'projectApprovedUsers']),
    totalTrackedTime() {
      return this.getFormattedTime(this.task.timelogs_duration.total)
    },
    myTrackedTime() {
      return this.getFormattedTime(this.task.timelogs_duration.user)
    },
    offsetToken() {
      if (!this.taskActivities) {
        return null
      }
      return this.taskActivities.offset_token
    },
    requestOptions() {
      return {
        pid: this.project.id,
        params: {
          limit: this.limit,
          parent_type: 'Task',
          parent_id: this.task.id,
          offset_token: this.offsetToken
        }
      }
    }
  },
  data() {
    return {
      limit: 15,
      fetched: false,
      loading: false,
      isIntersecting: false
    }
  },
  methods: {
    ...mapActions(['getTaskActivities', 'addTaskActivities']),
    getOwner(id) {
      return this.projectApprovedUsers.find((user) => user.id === id) || {}
    },
    getIcon(action) {
      const icon = taskActivityIcons[action]
      return icon || taskActivityIcons.fallback
    },
    getFormattedTime(time) {
      return new Date(time * 1000).toISOString().substr(11, 8)
    },
    onScroll(entries) {
      this.isIntersecting = entries[0].isIntersecting
    },
    async getData() {
      try {
        await this.getTaskActivities({
          pid: this.project.id,
          params: {
            limit: this.limit,
            parent_type: 'Task',
            parent_id: this.task.id
          }
        })
        this.fetched = true
      } catch (error) {}
    },
    async addData() {
      this.loading = true
      try {
        await this.addTaskActivities(this.requestOptions)
      } catch (error) {}
      this.loading = false
    }
  },
  watch: {
    isIntersecting(newValue) {
      if (!newValue || !this.fetched || !this.offsetToken || this.loading) {
        return
      }
      this.addData()
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
