<template>
  <div class="pa-4">
    <v-text-field
      flat
      dense
      outlined
      v-model="input"
      class="cursor pointer"
      :label="$t('form.labels.weight_unit')"
    />
    <div class="d-flex justify-space-between">
      <v-btn
        text
        outlined
        depressed
        class="me-2"
        :disabled="loading"
        color="secondary darken-2"
        v-text="$t('common.cancel')"
        @click="$emit('cancel-clicked')"
      />
      <v-btn
        depressed
        width="38"
        @click="save"
        color="primary"
        :loading="loading"
        v-text="$t('common.save')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    project: {
      type: Object
    },
    features: {
      type: Object
    }
  },
  computed: {
    feature() {
      return this.features.weight
    }
  },
  data() {
    return {
      loading: false,
      input: ''
    }
  },
  methods: {
    ...mapActions(['updateProjectFeature']),
    async save() {
      this.loading = true
      try {
        await this.updateProjectFeature({
          pid: this.project.id,
          fid: this.feature.id,
          data: {
            settings: {
              unit: this.input
            }
          }
        })
        this.$emit('cancel-clicked')
      } catch (error) {}
      this.loading = false
    }
  },
  mounted() {
    this.input = this.feature.settings.unit
  },
  watch: {
    'feature.settings.unit'(newValue) {
      if (newValue) {
        this.input = newValue
      }
    }
  }
}
</script>
