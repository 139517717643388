<template>
  <v-row @click="handleClick" :class="{ 'cursor pointer': editable }">
    <v-col cols="5" class="py-2">
      <div class="body-2 secondary--text text--darken-2 d-flex align-center">
        <div class="d-flex align-center">
          <base-icon :name="icon" color="primary" width="18" height="23" />
        </div>
        <div class="ms-2">{{ label }}</div>
      </div>
    </v-col>
    <v-col cols="7" class="py-2 d-flex align-center">
      <div
        class="body-2 d-flex justify-space-between flex-grow-1"
        v-if="!loading"
        :class="{ 'secondary--text text--darken-2': text.length === 0 }"
      >
        <template v-if="text.length > 0">{{ text }}</template>
        <template v-else>{{ addText }}</template>
        <slot name="actions" />
      </div>
      <v-progress-circular
        v-else
        :size="10"
        :width="1"
        indeterminate
        color="secondary darken4"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  props: {
    icon: {
      type: String,
      default: 'check'
    },
    label: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    addButtonText: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    addText() {
      if (!this.editable) {
        return ''
      }
      return this.addButtonText
    }
  },
  methods: {
    handleClick() {
      if (!this.editable) {
        return
      }
      this.$emit('row-clicked')
    }
  },
  components: {
    BaseIcon
  }
}
</script>
