<template>
  <div class="pa-4">
    <v-form ref="form" v-model="formIsValid" @submit.prevent="">
      <v-text-field
        dense
        outlined
        disabled
        type="text"
        class="body-1"
        color="secondary lighten4"
        :value="user ? user.email : ''"
        :label="$t('form.labels.current_email')"
      />
      <v-text-field
        dense
        outlined
        type="text"
        class="body-1"
        v-model="form.email"
        color="secondary lighten4"
        :rules="getEmailRules()"
        :label="$t('form.labels.new_email')"
      />
    </v-form>
    <profile-overview-list-account-verification
      :token="token"
      :handle="form.email"
      :dialog.sync="dialog"
      @email-changed="resetForm"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { editUserEmail } from '@/api/users'
import ProfileOverviewListAccountVerification from '@/components/user/ProfileOverviewListAccountVerification'
export default {
  props: {
    saveClicked: {
      type: Boolean,
      default: false
    },
    saveDisabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      token: '',
      dialog: false,
      formIsValid: false,
      form: {
        email: ''
      }
    }
  },
  methods: {
    resetForm() {
      this.form.email = ''
      this.$refs.form.resetValidation()
    },
    async changeEmail() {
      this.$emit('update:loading', true)
      try {
        const { data } = await editUserEmail(this.form)
        this.token = data.data.token
        this.dialog = true
      } catch (error) {}
      this.$emit('update:loading', false)
    }
  },
  watch: {
    formIsValid(newValue) {
      this.$emit('update:saveDisabled', !newValue)
    },
    saveClicked(newValue) {
      if (newValue) {
        this.changeEmail()
      }
      this.$emit('update:saveClicked', false)
    }
  },
  components: {
    ProfileOverviewListAccountVerification
  }
}
</script>
