import Vue from 'vue'
import {
  ADD_COMMENT,
  SET_COMMENT,
  SET_COMMENTS,
  ADD_COMMENTS,
  DELETE_COMMENT,
  SET_COMMENT_RECORDS,
  SET_COMMENTS_OFFSET_TOKEN
} from '../mutation-types'

import { convertArrayToObject } from '@/utils/helpers'
import {
  deleteComment,
  fetchComments,
  createComment,
  patchComment
} from '@/api/comments'

const state = {
  commentRecords: null,
  commentsOffsetToken: null
}

const getters = {
  commentRecords: state => state.commentRecords,
  commentsOffsetToken: state => state.commentsOffsetToken
}

const mutations = {
  [SET_COMMENTS](state, comments) {
    state.commentsOffsetToken = comments.offset_token
    state.commentRecords = convertArrayToObject(comments.records)
  },
  [SET_COMMENT_RECORDS](state, records) {
    state.commentRecords = records
  },
  [SET_COMMENTS_OFFSET_TOKEN](state, offsetToken) {
    state.commentsOffsetToken = offsetToken
  },
  [ADD_COMMENT](state, comment) {
    state.commentRecords = {
      ...state.commentRecords,
      ...convertArrayToObject([comment])
    }
  },
  [ADD_COMMENTS](state, comments) {
    state.commentRecords = {
      ...state.commentRecords,
      ...convertArrayToObject(comments.records)
    }
    state.commentsOffsetToken = comments.offset_token
  },
  [SET_COMMENT](state, comment) {
    state.commentRecords[comment.id] = {
      ...comment,
      creator: state.commentRecords[comment.id].creator
    }
  },
  [DELETE_COMMENT](state, commentId) {
    Vue.delete(state.commentRecords, commentId)
  }
}

const actions = {
  async getComments({ commit }, { pid, params }) {
    const { data } = await fetchComments(pid, params)
    commit(SET_COMMENTS, data.data)
  },
  async resetComments({ commit }) {
    commit(SET_COMMENT_RECORDS, null)
    commit(SET_COMMENTS_OFFSET_TOKEN, null)
  },
  async addComments({ commit }, { pid, params }) {
    const { data } = await fetchComments(pid, params)
    commit(ADD_COMMENTS, data.data)
  },
  async addComment({ commit }, { pid, data }) {
    const result = await createComment(pid, data)
    commit(ADD_COMMENT, result.data.data)
  },
  async updateComment({ commit }, { cid, data }) {
    const result = await patchComment(cid, data)
    commit(SET_COMMENT, result.data.data)
  },
  async deleteTaskComment({ commit }, cid) {
    await deleteComment(cid)
    commit(DELETE_COMMENT, cid)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
