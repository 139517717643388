<template>
  <search-dialog-results
    :active="active"
    :searching="searching"
    :has-results="hasResults"
    :loading-more="loadingMore"
    @export-clicked="$emit('export-clicked')"
    @intersect-reached="$emit('intersect-reached', $event)"
    :result-count="hasResults ? timelogsSearchResults.total_count : 0"
  >
    <timelogs-table
      :loading="sortLoading"
      @sort-changed="$emit('sort-changed', $event)"
      :timelogs="timelogsSearchResults ? timelogsSearchResults.records : null"
    />
  </search-dialog-results>
</template>

<script>
import { mapGetters } from 'vuex'
import TimelogsTable from '@/components/timelogs/TimelogsTable'
import SearchDialogResults from '@/components/search/SearchDialogResults'

export default {
  name: 'TimelogsSearchDialogResults',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    searching: {
      type: Boolean,
      required: true
    },
    sortLoading: {
      type: Boolean,
      required: true
    },
    loadingMore: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['timelogsSearchResults']),
    hasResults() {
      return (
        this.timelogsSearchResults !== null &&
        this.timelogsSearchResults.total_count > 0 &&
        this.active
      )
    }
  },
  components: {
    TimelogsTable,
    SearchDialogResults
  }
}
</script>
