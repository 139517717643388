<template>
  <v-list v-if="notificationSettings">
    <v-list-item
      :key="item.name"
      v-for="item in items"
      @click="item.dialogVisible = true"
    >
      <v-list-item-icon class="me-2">
        <base-icon
          color="primary"
          :name="item.icon"
          className="d-flex align-center"
        />
      </v-list-item-icon>

      <v-list-item-content class="info--text">
        <v-list-item-title
          class="text-h5 d-flex justify-space-between"
          v-text="$t(`page.profile_setting.notifications.${item.name}.title`)"
        />
      </v-list-item-content>

      <v-list-item-icon>
        <base-angle-icon
          width="20"
          height="20"
          class="flip-horizontal"
          fill="secondary.darken2"
        />
      </v-list-item-icon>
      <base-dialog
        card-color="white"
        content-class="large-level-three"
        :dialog.sync="item.dialogVisible"
        :title="$t(`page.profile_setting.notifications.${item.name}.title`)"
      >
        <component
          :loading.sync="item.loading"
          :save-clicked.sync="item.saveClicked"
          :save-disabled.sync="item.saveDisabled"
          @action-done="item.dialogVisible = false"
          :is="`ProfileOverviewListNotificationSettings${item.component}`"
        />
      </base-dialog>
    </v-list-item>
    <v-divider class="my-4 secondary lighten-2" />
    <profile-overview-list-notification-settings-app />
  </v-list>
  <base-loading v-else />
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseLoading from '@/components/BaseLoading'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import ProfileOverviewListNotificationSettingsApp from '@/components/user/ProfileOverviewListNotificationSettingsApp'
import ProfileOverviewListNotificationSettingsEmail from '@/components/user/ProfileOverviewListNotificationSettingsEmail'
import ProfileOverviewListNotificationSettingsTaskReminder from '@/components/user/ProfileOverviewListNotificationSettingsTaskReminder'
export default {
  computed: {
    ...mapGetters(['notificationSettings'])
  },
  data() {
    return {
      items: [
        {
          name: 'email_notifications',
          icon: 'at',
          dialogVisible: false,
          component: 'Email'
        },
        {
          name: 'task_reminder',
          icon: 'bell',
          dialogVisible: false,
          component: 'TaskReminder'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['getUserNotificationSettings']),
    getData() {
      if (this.notificationSettings) {
        return
      }
      this.getUserNotificationSettings()
    }
  },
  mounted() {
    this.getData()
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseLoading,
    BaseAngleIcon,
    ProfileOverviewListNotificationSettingsApp,
    ProfileOverviewListNotificationSettingsEmail,
    ProfileOverviewListNotificationSettingsTaskReminder
  }
}
</script>
