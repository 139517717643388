<template>
  <div class="mb-5">
    <base-inline-input
      :loading="editing"
      :initial-value="item.title"
      @save-clicked="
        $emit('edit-clicked', {
          tid: item.id,
          title: $event
        })
      "
    >
      <v-btn
        small
        text
        depressed
        color="error"
        :loading="loading"
        v-text="$t('common.delete')"
        @click="$emit('delete-clicked', item)"
      />
    </base-inline-input>
  </div>
</template>

<script>
import BaseInlineInput from '@/components/BaseInlineInput'
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseInlineInput
  }
}
</script>
