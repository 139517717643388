<template>
  <v-navigation-drawer
    app
    touchless
    width="304"
    v-model="drawer"
    style="z-index: 4"
    :right="$vuetify.rtl"
    :clipped="$vuetify.breakpoint.lgAndUp"
  >
    <v-row class="fill-height" no-gutters>
      <the-second-navigation-drawer-mini-variant
        :organizations="organizations"
        :current-organization-id="organization ? organization.id : ''"
      />
      <div class="overflow-y-auto fill-height" style="width:238px">
        <v-list
          dense
          expand
          class="grow"
          color="white"
          v-if="currentOrganization"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="body-1"
                v-text="currentOrganization.title"
              />
              <v-list-item-subtitle
                class="body-2 text--secondary"
                v-text="currentOrganization.url"
              />
            </v-list-item-content>
            <v-list-item-action class="ms-0">
              <v-btn
                icon
                plain
                small
                :disabled="isSettings"
                :to="{ path: '/settings' }"
              >
                <base-icon name="cog" color="secondary.darken2" />
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="px-0">
              <v-text-field
                flat
                dense
                outlined
                full-width
                hide-details
                v-model="query"
                class="body-2 px-0"
                :placeholder="$t('form.placeholders.search_projects_members_etc')"
              /> </v-list-item-content
          ></v-list-item>
          <v-divider />
          <v-list-item :to="{ path: '/' }">
            <v-list-item-icon class="me-4">
              <base-icon
                width="16"
                color="secondary.darken2"
                name="t-clipboard-checked"
                className="d-flex align-center"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="body-2" v-text="$t('page.my_tasks.title')" />
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <the-second-navigation-drawer-projects
            :query="query"
            :projects="projects"
            v-if="organizationAbilities"
            :create-allowed="organizationAbilities.abilities.create_project"
          />
        </v-list>
        <v-skeleton-loader type="list-item-two-line" v-else />
      </div>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import TheSecondNavigationDrawerProjects from '@/components/TheSecondNavigationDrawerProjects'
import TheSecondNavigationDrawerMiniVariant from '@/components/TheSecondNavigationDrawerMiniVariant'
export default {
  data() {
    return {
      drawer: this.$vuetify.breakpoint.lgAndUp,
      mini: true,
      query: ''
    }
  },
  computed: {
    ...mapGetters([
      'organization',
      'organizations',
      'organizationSubdomain',
      'organizationAbilities'
    ]),
    direcMessages() {
      if (!this.organization) {
        return []
      }
      return this.getFilteredList(
        this.organization.direct_chats.map((msg) => {
          const user = msg.user ? this.getUserById(msg.user.id) : null
          return {
            id: msg.id,
            active: false,
            title: user ? user.name : '',
            unread_count: msg.unread_count,
            avatar: user && user.avatar ? user.avatar.small : ''
          }
        })
      )
    },
    channels() {
      if (!this.organization) {
        return []
      }
      return this.getFilteredList(
        this.organization.channels.map((channel) => {
          return {
            active: false,
            id: channel.id,
            title: channel.title
          }
        })
      )
    },
    projects() {
      if (!this.organization) {
        return []
      }
      return this.getFilteredList(
        this.organization.projects.reduce((filtered, project) => {
          if (!project.closed) {
            filtered.push({
              active: false,
              id: project.short_id,
              title: project.title
            })
          }
          return filtered
        }, [])
      )
    },
    conversations() {
      return [
        {
          isChannel: true,
          title: 'channel_plural',
          icon: 't-hash-tag',
          items: this.channels || []
        },
        {
          isChannel: false,
          icon: 'comments-alt',
          title: 'direct_message',
          items: this.direcMessages || []
        }
      ]
    },
    currentOrganization() {
      if (!this.organizations || !this.organizations.length) {
        return null
      }
      return (
        this.organizations.find(
          (org) => org.subdomain === this.organizationSubdomain
        ) || this.organizations[0]
      )
    },
    isSettings() {
      return this.$route.name === 'OrganizationSettings'
    }
  },
  methods: {
    getUserById(id) {
      const { organization } = this
      return organization.users.find((usr) => usr.id === id)
    },
    getFilteredList(list) {
      if (!this.query) {
        return list
      }
      return list.filter(
        (item) =>
          item.title.toLowerCase().indexOf(this.query.toLowerCase()) > -1
      )
    },
    toggleDrawer() {
      this.drawer = !this.drawer
    }
  },
  components: {
    BaseIcon,
    TheSecondNavigationDrawerProjects,
    TheSecondNavigationDrawerMiniVariant
  }
}
</script>
