<template>
  <fragment v-if="activeTimelogs && activeTimelogs.total_count > 0" class="d-flex">
    <h2
      class="text-h6 px-4 pt-4 pb-2 d-flex align-center"
      @click="expanded = !expanded"
    >
      <span class="me-1">{{ $t('page.timelogs.active_time') }}</span>
      <base-icon
        color="secondary.darken2"
        className="d-flex align-center"
        :name="`angle-${expanded ? 'up' : 'down'}`"
      />
    </h2>
    <v-expand-transition>
      <v-list dense class="py-0 t-box-shadow" v-show="expanded">
        <template v-for="(timelog, index) in activeTimelogs.records">
          <timelogs-list-item
            :key="timelog.id"
            :timelog="timelog"
            @item-clicked="openConfirmationSheet"
          />
          <v-divider
            class="secondary lighten-2"
            :key="`divider_${timelog.id}`"
            v-if="index < activeTimelogs.total_count - 1"
          />
        </template>
      </v-list>
    </v-expand-transition>
    <v-dialog v-model="sheet" :persistent="loading" max-width="fit-content">
      <base-bottom-sheet-form
        class="pb-2"
        :loading="loading"
        save-btn-color="error base"
        @cancel-clicked="sheet = false"
        :save-btn-text="$t('common.stop')"
        @save-clicked="stopCurrentTimelog"
        :title="$t('page.timelogs.stop_active_time_title')"
      >
        <p class="body-1">{{ $t('page.timelogs.stop_active_time_content') }}</p>
      </base-bottom-sheet-form>
    </v-dialog>
  </fragment>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapGetters, mapActions } from 'vuex'
import BaseBottomSheetForm from '@/components/BaseBottomSheetForm'
import TimelogsListItem from '@/components/timelogs/TimelogsListItem'
export default {
  props: {
    pid: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['activeTimelogs'])
  },
  data() {
    return {
      item: null,
      sheet: false,
      expanded: true,
      loading: false
    }
  },
  methods: {
    ...mapActions(['stopActiveTimelog']),
    openConfirmationSheet(item) {
      this.item = item
      this.sheet = true
    },
    async stopCurrentTimelog() {
      this.loading = true
      try {
        await this.stopActiveTimelog({
          pid: this.pid,
          tid: this.item.id,
          data: {
            task_id: this.item.task.id
          }
        })
        this.sheet = false
        this.$emit('list-updated')
      } catch (error) {}
      this.loading = false
    }
  },
  components: {
    BaseIcon,
    TimelogsListItem,
    BaseBottomSheetForm
  }
}
</script>
