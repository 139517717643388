var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"px-4"},[_c('h2',{staticClass:"py-2 my-0 body-1 info--text",domProps:{"textContent":_vm._s(
      _vm.$t('page.project_settings.advanced_settings.delete_project.title')
    )}}),_c('p',{staticClass:"py-2 my-0 body-1 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
      _vm.$t('page.project_settings.advanced_settings.delete_project.description')
    )}}),_c('p',{staticClass:"py-2 my-0 d-flex align-center justify-center"},[_c('v-btn',{attrs:{"depressed":"","width":"148","color":"error"},domProps:{"textContent":_vm._s(
        _vm.$t('page.project_settings.advanced_settings.delete_project.button')
      )},on:{"click":function($event){_vm.dialog = true}}})],1),_c('base-dialog',{attrs:{"card-color":"white","dialog":_vm.dialog,"hide-back-btn":true,"card-toolbar-class":"ms-2","content-class":"small-level-two","title":_vm.$t(
        'page.project_settings.advanced_settings.delete_project.confirmation.title'
      )},on:{"update:dialog":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"cardActions",fn:function(){return [_c('v-card-actions',{staticClass:"pb-4 d-flex justify-space-between"},[_c('v-btn',{attrs:{"depressed":"","width":"136","color":"primary","disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('common.cancel'))},on:{"click":_vm.cancel}}),_c('v-btn',{attrs:{"text":"","width":"136","color":"error","loading":_vm.loading,"disabled":_vm.loading || _vm.deleteButtonDisabled},domProps:{"textContent":_vm._s(
            _vm.$t(
              'page.project_settings.advanced_settings.delete_project.confirmation.delete_button'
            )
          )},on:{"click":_vm.handleClickOnDelete}})],1)]},proxy:true}])},[_c('section',{staticClass:"px-4"},[_c('v-slide-x-transition',{attrs:{"hide-on-leave":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 0),expression:"step === 0"}]},[_c('p',{staticClass:"py-2 my-0 body-1 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
              _vm.$t(
                'page.project_settings.advanced_settings.delete_project.confirmation.description',
                {
                  title: this.project.title,
                  interpolation: { escapeValue: false }
                }
              )
            )}}),_c('p',{staticClass:"py-2 my-0 text-h6 error--text",domProps:{"textContent":_vm._s(
              _vm.$t(
                'page.project_settings.advanced_settings.delete_project.confirmation.warning'
              )
            )}}),_c('p',{staticClass:"py-2 my-0 body-1 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
              _vm.$t(
                'page.project_settings.advanced_settings.delete_project.confirmation.delete_confirmation'
              )
            )}}),_c('v-form',{staticClass:"py-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleClickOnDelete($event)}}},[_c('v-text-field',{attrs:{"flat":"","dense":"","outlined":"","label":_vm.$t('form.labels.project_title')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1)]),_c('v-slide-x-transition',{attrs:{"hide-on-leave":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 1),expression:"step === 1"}]},[_c('p',{staticClass:"py-2 my-0 body-1 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
              _vm.$t(
                'page.project_settings.advanced_settings.delete_project.authentication.delete_confirmation'
              )
            )}}),_c('v-form',{ref:"secondForm",staticClass:"py-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleClickOnDelete($event)}},model:{value:(_vm.secondFormIsValid),callback:function ($$v) {_vm.secondFormIsValid=$$v},expression:"secondFormIsValid"}},[_c('base-password',{attrs:{"value":_vm.password,"validate-on-blur":false,"rules":_vm.getPasswordRules(),"label":_vm.$t('form.labels.password')},on:{"update:value":function($event){_vm.password=$event}}})],1)],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }