<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    :content-class="contentClass"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.transfer_ownership.title')"
  >
    <v-card class="d-flex flex-column" height="100%">
      <v-card-text class="px-0 d-flex flex-column flex-grow-1">
        <p
          class="px-4 body-1 secondary--text text--darken-2 flex-grow-0"
          v-text="$t('page.project_settings.transfer_ownership.description')"
        />
        <p
          class="px-4 body-1 secondary--text text--darken-2 flex-grow-0"
          v-text="$t('page.project_settings.transfer_ownership.instruction')"
        />
        <div class="flex-grow-1 d-flex flex-column">
          <base-members-list
            :avatar-size="32"
            :multiple="false"
            :users="availableUsers"
            :members.sync="newOwner"
            subtitle-prop="username"
            placeholder="form.placeholders.search_members"
            emptyListText="search_and_filter.results.not_found.members"
          />
        </div>
      </v-card-text>
      <base-dialog
        card-color="white"
        :dialog.sync="transferDialog"
        :content-class="nestedContentClass"
        card-text-class="d-flex flex-column"
        :title="$t('page.project_settings.transfer_ownership.title')"
      >
        <section
          class="
            body-1
            py-4
            secondary--text
            text--darken-2
            flex-grow-1 flex-column
          "
          :class="{ 'd-flex': step === 3 }"
        >
          <v-slide-x-transition hide-on-leave>
            <div v-show="step === 1">
              <p
                class="px-4"
                v-if="newOwnerInfo"
                v-html="
                  $t(
                    'page.project_settings.transfer_ownership.information.description',
                    {
                      title: newOwnerInfo.name,
                      interpolation: { escapeValue: false }
                    }
                  )
                "
              />
              <v-list-item v-if="newOwnerInfo">
                <v-list-item-avatar size="32" min-width="32">
                  <base-avatar
                    :size="32"
                    :title="newOwnerInfo.name"
                    :image="
                      newOwnerInfo.avatar ? newOwnerInfo.avatar.medium : ''
                    "
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="body-1"
                    v-text="newOwnerInfo.name"
                  />
                  <v-list-item-subtitle
                    class="body-2"
                    v-text="`@${newOwnerInfo.username}`"
                  />
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-slide-x-transition>
          <v-slide-x-transition hide-on-leave>
            <div v-show="step === 2" class="px-4">
              <p
                class="body-1 secondary--text text--darken-2 flex-grow-0"
                v-text="
                  $t(
                    'page.project_settings.transfer_ownership.authentication.description'
                  )
                "
              />
              <v-form
                ref="form"
                class="py-2"
                v-model="formIsValid"
                @submit.prevent="handleClickOnDone"
              >
                <base-password
                  :value.sync="password"
                  :validate-on-blur="false"
                  :rules="getPasswordRules()"
                  :label="$t('form.labels.password')"
                />
              </v-form>
            </div>
          </v-slide-x-transition>
          <v-slide-x-transition hide-on-leave>
            <div
              v-show="step === 3"
              class="flex-grow-1 flex-column justify-center"
              :class="{ 'd-flex': step === 3 }"
            >
              <section class="d-flex flex-column align-center">
                <v-avatar size="64" color="success lighten-5">
                  <base-icon
                    width="33"
                    height="33"
                    name="check"
                    color="success.base"
                  />
                </v-avatar>
                <h3
                  class="mt-4 text-h5 info--text"
                  v-text="
                    $t('page.project_settings.transfer_ownership.success.title')
                  "
                />
                <p
                  class="mt-2 body-1"
                  v-text="
                    $t(
                      'page.project_settings.transfer_ownership.success.content'
                    )
                  "
                />
              </section>
            </div>
          </v-slide-x-transition>
        </section>
        <template v-slot:cardActions>
          <div class="py-6 px-4 d-flex justify-space-between">
            <v-btn
              text
              width="136"
              color="primary"
              v-show="step === 1"
              @click="transferDialog = false"
              v-text="$t('common.cancel')"
            />
            <v-btn
              depressed
              width="136"
              color="primary"
              @click="step++"
              v-show="step === 1"
              v-text="
                $t(
                  'page.project_settings.transfer_ownership.information.continue_button'
                )
              "
            />
            <v-btn
              text
              width="136"
              color="primary"
              @click="step--"
              v-show="step === 2"
              :disabled="loading"
              v-text="$t('common.cancel')"
            />
            <v-btn
              depressed
              width="136"
              color="primary"
              :loading="loading"
              v-show="step === 2"
              @click="handleClickOnDone"
              v-text="$t('common.done')"
              :disabled="!formIsValid || loading"
            />
            <v-slide-x-transition hide-on-leave>
              <v-btn
                block
                depressed
                color="primary"
                v-show="step === 3"
                @click="$emit('go-back')"
                v-text="$t('common.done')"
              />
            </v-slide-x-transition>
          </div>
        </template>
      </base-dialog>
    </v-card>
  </base-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseDialog from '@/components/BaseDialog'
import BaseAvatar from '@/components/BaseAvatar'
import BasePassword from '@/components/BasePassword'
import BaseMembersList from '@/components/BaseMembersList'
export default {
  props: {
    displaySuccess: {
      type: Boolean,
      default: true
    },
    dialog: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: 'small-level-one'
    },
    nestedContentClass: {
      type: String,
      default: 'small-level-two'
    },
    project: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseAvatar,
    BasePassword,
    BaseMembersList
  },
  data() {
    return {
      step: 1,
      password: '',
      loading: false,
      newOwner: null,
      formIsValid: false,
      transferDialog: false
    }
  },
  computed: {
    ...mapGetters(['user', 'projectApprovedUsers']),
    availableUsers() {
      return this.projectApprovedUsers.filter(
        (user) => user.id !== this.user.id
      )
    },
    newOwnerInfo() {
      return this.newOwner
        ? this.availableUsers.find((user) => user.id === this.newOwner)
        : null
    }
  },
  methods: {
    ...mapActions(['changeProjectOwnership']),
    async handleClickOnDone() {
      this.loading = true
      try {
        await this.changeProjectOwnership({
          pid: this.project.id,
          data: {
            auth_type: 'password',
            user_id: this.newOwner,
            auth_token: this.password
          }
        })
        if (this.displaySuccess) {
          this.step = 3
        } else {
          this.$emit('go-back')
        }
      } catch (error) {}
      this.loading = false
    }
  },
  watch: {
    newOwner(newValue) {
      if (newValue !== null) {
        this.transferDialog = true
      }
    },
    transferDialog(newValue) {
      if (!newValue) {
        this.step = 1
        this.password = ''
        this.newOwner = null
        this.$refs.form.resetValidation()
      }
    }
  }
}
</script>
