<template>
  <v-toolbar flat height="60" color="white" class="flex-grow-0">
    <base-dialog-back-btn @go-back-clicked="$emit('update:dialog', false)" />
    <v-toolbar-title class="text-h3">
      {{ title }}
    </v-toolbar-title>
    <v-spacer />
    <v-btn
      text
      small
      color="primary"
      @click="
        setInterfaceDialog({
          prop: 'inviteOrganizationMember',
          value: true
        })
      "
      v-text="$t('common.add')"
    />
  </v-toolbar>
</template>

<script>
import { mapActions } from 'vuex'
import BaseDialogBackBtn from '@/components/BaseDialogBackBtn'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(['setInterfaceDialog'])
  },
  components: {
    BaseDialogBackBtn
  }
}
</script>
