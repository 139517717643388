<template>
  <v-list class="py-0">
    <v-subheader
      v-text="title"
      class="secondary--text text--darken-2 text-h5"
    />
    <v-list-item :key="item.id" @click="openDialog(item)" v-for="item in items">
      <v-list-item-action class="me-4">
        <base-icon
          width="24"
          height="24"
          color="primary"
          name="layer-group"
          className="d-flex align-center"
        />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="body-2 info--text" v-text="item.title" />
      </v-list-item-content>
      <v-list-item-icon>
        <base-angle-icon
          width="24"
          height="24"
          fill="secondary.base"
          class="flip-horizontal"
        />
      </v-list-item-icon>
    </v-list-item>
    <base-dialog
      card-color="white"
      :dialog.sync="dialog"
      :title="projectTitle"
      content-class="small-level-two"
    >
      <v-container fluid v-if="stats" class="px-4">
        <v-row class="align-center justify-center">
          <v-col
            cols="6"
            class="body-2 secondary--text"
            v-text="$t('page.organization_settings.projects.project.status')"
          />
          <v-col
            cols="6"
            class="body-2 text-end"
            :class="{
              'error--text': stats.closed,
              'success--text': !stats.closed
            }"
            v-text="$t(`common.${stats.closed ? 'closed' : 'active'}`)"
          />
        </v-row>
        <v-row class="align-center justify-center">
          <v-col
            cols="6"
            class="body-2 secondary--text"
            v-text="
              $t('page.organization_settings.projects.project.last_activity')
            "
          />
          <v-col cols="6" class="body-2 text-end info--text">
            <base-date-time
              format="long_with_time"
              :date="stats.last_activity_created_at"
            />
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-col
            cols="6"
            class="body-2 secondary--text"
            v-text="$t('page.organization_settings.projects.project.tasks')"
          />
          <v-col
            cols="6"
            v-text="$t('common.task_with_count', { count: stats.tasks_count })"
            class="body-2 text-end info--text"
          />
        </v-row>
        <v-row class="align-center justify-center">
          <v-col
            cols="6"
            class="body-2 secondary--text"
            v-text="$t('page.organization_settings.projects.project.members')"
          />
          <v-col
            cols="6"
            v-text="
              $t('common.member_with_count', {
                count: stats.members_count
              })
            "
            class="body-2 text-end info--text"
          />
        </v-row>
        <v-row class="align-center justify-center">
          <v-col
            cols="6"
            class="body-2 secondary--text"
            v-text="$t('page.organization_settings.projects.project.owner')"
          />
          <v-col
            cols="6"
            v-text="stats.owner.name"
            class="body-2 text-end info--text"
          />
        </v-row>
        <v-row class="align-center justify-center">
          <v-col
            cols="6"
            class="body-2 secondary--text"
            v-text="
              $t('page.organization_settings.projects.project.created_at')
            "
          />
          <v-col cols="6" class="body-2 text-end info--text">
            <base-date-time :date="stats.created_at" format="long_with_time" />
          </v-col>
        </v-row>
      </v-container>
      <base-loading v-else />
      <template v-slot:cardActions>
        <div class="pa-4 flex-grow-0" v-if="stats">
          <template v-if="!stats.closed">
            <v-btn
              block
              depressed
              @click="join"
              color="primary"
              :loading="loading"
              :disabled="loading"
              v-if="organization.owner.id === user.id && !stats.joined"
              v-text="
                $t(
                  'page.organization_settings.projects.project.actions.add_me_as_admin'
                )
              "
            />
            <v-btn
              block
              v-else
              depressed
              color="primary"
              :to="{
                name: 'board',
                params: { pid, view: 'kanban' }
              }"
            >
              <base-icon
                width="24"
                height="24"
                color="white"
                name="external-link-alt"
              />
              {{
                $t(
                  'page.organization_settings.projects.project.actions.go_to_project'
                )
              }}
            </v-btn>
          </template>
          <v-btn
            block
            v-else
            depressed
            color="primary"
            @click="restore"
            :loading="loading"
            :disabled="loading"
            v-text="
              $t('page.organization_settings.projects.project.actions.restore')
            "
          />
        </div>
      </template>
    </base-dialog>
  </v-list>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import { fetchProjectStats, createProjectAdminship } from '@/api/projects'
import BaseLoading from '@/components/BaseLoading'
import BaseDateTime from '@/components/BaseDateTime'
import BaseAngleIcon from '@/components/BaseAngleIcon'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: 'hello'
    }
  },
  computed: {
    ...mapGetters(['user', 'organization'])
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseLoading,
    BaseDateTime,
    BaseAngleIcon
  },
  data() {
    return {
      pid: '',
      stats: null,
      dialog: false,
      loading: false,
      projectTitle: null
    }
  },
  methods: {
    ...mapActions(['reopenProject', 'getOrganization']),
    async getStats() {
      try {
        const stats = await fetchProjectStats(this.pid)
        this.stats = stats.data.data
      } catch (error) {}
    },
    async openDialog(project) {
      this.dialog = true
      this.stats = null
      this.pid = project.id
      this.projectTitle = project.title
      this.getStats()
    },
    async restore() {
      this.loading = true
      try {
        await this.reopenProject(this.pid)
        this.stats.closed = false
      } catch (error) {}
      this.loading = false
    },
    async join() {
      this.loading = true
      try {
        await createProjectAdminship(this.pid)
        await this.getStats()
        this.getOrganization()
      } catch (error) {}
      this.loading = false
    }
  }
}
</script>
