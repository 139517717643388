import request from '@/api/axios'

export const fetchTags = (pid, params) =>
  request({
    url: `orgs/#oid/projects/${pid}/tags`,
    method: 'get',
    params
  })

export const createProjectTag = (pid, data) =>
  request({
    url: `orgs/#oid/projects/${pid}/tags`,
    method: 'post',
    data
  })

export const patchTag = (tid, data) =>
  request({
    url: `/orgs/#oid/tags/${tid}`,
    method: 'patch',
    data
  })

export const deleteTag = tid =>
  request({
    url: `orgs/#oid/tags/${tid}`,
    method: 'delete'
  })
