<template>
  <v-list-item-content class="py-0">
    <span class="filename body-2 info--text">
      <div class="d-flex" dir="ltr">
        <span class="name">{{ name }} </span>
        <span class="extension" v-if="extention.length">.{{ extention }}</span>
      </div>
    </span>
    <v-list-item-subtitle>
      <div class="d-flex align-center secondary--text text--darken-2">
        <span class="body-2 info--text">{{
          item.owner
        }}</span>
        <span class="subtitle-1 ms-2" v-if="item.size" v-text="item.size" />
        <base-date-time
          :auto-update="true"
          format="long_with_time"
          :date="item.created_at"
          className="subtitle-1 ms-2"
        />
      </div>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
import BaseDateTime from '../BaseDateTime'
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    extention: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  components: {
    BaseDateTime
  }
}
</script>
