<template>
  <div class="fill-width">
    <h3
      v-if="fetched"
      v-html="dyanmicSubtitle"
      class="body-2 secondary--text text--darken-2"
    />
    <template v-if="groups">
      <onboarding-question-steps-step
        :key="index"
        :items="items"
        v-show="currentStep === index + 1"
        v-for="(items, index) in groupValues"
        @answer-selected="handleAnswerSelection"
      />
      <v-btn
        block
        class="mt-7"
        color="primary"
        :disabled="!buttonEnabled"
        @click="handleClickOnNext"
      >
        {{ $t('common.next') }}
      </v-btn>
    </template>
    <base-loading v-else />
  </div>
</template>

<script>
import groupBy from 'lodash.groupby'
import { mapGetters, mapActions } from 'vuex'
import BaseLoading from '@/components/BaseLoading'
import OnboardingQuestionStepsStep from '@/components/onboarding/OnboardingQuestionStepsStep'
export default {
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    resetAnswers: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['onboardingQuestions', 'organizationSubdomain']),
    dyanmicSubtitle() {
      if (this.currentStep === 1) {
        return this.$i18n.t('page.onboarding.work_details.content')
      }
      const type = this.getAnswerTitleBasedOnAnswerId('work_type')
      const on = this.getAnswerTitleBasedOnAnswerId('current_work')
      if (!type || !on) {
        return ''
      }
      return this.$i18n.t('page.onboarding.company_details.content', {
        type,
        on
      })
    },
    groupValues() {
      const { groups } = this
      return groups ? Object.values(groups) : []
    }
  },
  data() {
    return {
      groups: null,
      fetched: false,
      buttonEnabled: false
    }
  },
  methods: {
    ...mapActions(['getOnboardingQuestions']),
    handleAnswerSelection() {
      const { groupValues } = this
      const group = groupValues[this.currentStep - 1]
      this.buttonEnabled = group ? group.every((item) => item.answer_id) : false
      if (!group) {
        return
      }
      this.$emit('answers-changed', groupValues)
    },
    setData() {
      this.groups = groupBy(
        this.onboardingQuestions.map((question) => {
          return {
            ...question,
            answer_title: '',
            answer_id: this.resetAnswers ? null : question.answer_id
          }
        }),
        'page_id'
      )
      this.setCurrentStep()
      this.fetched = true
    },
    setCurrentStep() {
      let currentStep = 0
      const { groupValues } = this
      for (let i = 0; i < groupValues.length; i++) {
        const values = groupValues[i]
        if (values.every((value) => value.answer_id)) {
          currentStep = i + 1
        }
      }
      this.handleAnswerSelection()
      this.$emit('update:currentStep', currentStep + 1)
    },
    getAnswerTitleBasedOnAnswerId(id) {
      if (!this.groups) {
        return null
      }
      const { work_details: details } = this.groups
      if (!details) {
        return null
      }
      const found = details.find((item) => item.id === id)
      return found ? found.answer_title : null
    },
    async getData() {
      if (this.onboardingQuestions) {
        return this.setData()
      }
      try {
        await this.getOnboardingQuestions(this.organizationSubdomain)
        this.setData()
      } catch (error) {}
    },
    handleClickOnNext() {
      this.$emit('update:currentStep', this.currentStep + 1)
      this.$emit('next-clicked')
    }
  },
  watch: {
    currentStep(newValue) {
      if (newValue === 1 || newValue === 2) {
        this.handleAnswerSelection()
      }
    }
  },
  mounted() {
    this.getData()
  },
  components: {
    BaseLoading,
    OnboardingQuestionStepsStep
  }
}
</script>
