import { render, staticRenderFns } from "./BaseAvatarImageUploader.vue?vue&type=template&id=76655c0e&"
import script from "./BaseAvatarImageUploader.vue?vue&type=script&lang=js&"
export * from "./BaseAvatarImageUploader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAvatar,VBadge,VBottomSheet,VImg,VMenu,VProgressCircular,VSheet})
