<template>
  <v-list dense class="py-0">
    <v-list-item>
      <v-list-item-icon class="me-2">
        <base-icon
          :name="titleIcon"
          :color="titleIconColor"
          className="d-flex align-center"
        />
      </v-list-item-icon>
      <v-list-item-content class="body-2 secondary--text text--darken-2">
        {{ titleText }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          fab
          x-small
          outlined
          width="24"
          height="24"
          color="primary"
          v-if="editable"
          @click="dialog = true"
        >
          <span class="subtitle-1">+</span>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-divider />
    <template v-for="(dependency, index) in items">
      <v-list-item :key="`dependency_${dependency.id}`">
        <v-list-item-content>
          <v-list-item-title class="body-1 info--text">
            {{ dependency.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider
        v-if="index < dependencies.length"
        :key="`dependency_divider_${dependency.id}`"
      />
    </template>
    <base-dialog
      :dialog="dialog"
      :loading="loading"
      cardColor="white"
      :title="titleText"
      @save-clicked="save"
      :show-save-btn="true"
      v-resize="setTaskListHeight"
      content-class="large-level-two"
      @go-back-clicked="dialog = false"
    >
      <task-dialog-details-task-dependencies-tasks
        ref="tasks"
        v-if="dialog"
        :tasks="tasks"
        :height="taskListHeight"
        :dependencies="dependencies"
      />
    </base-dialog>
  </v-list>
</template>

<script>
import BaseIcon from '../BaseIcon'
import BaseDialog from '../BaseDialog'
import { mapActions, mapGetters } from 'vuex'
import TaskDialogDetailsTaskDependenciesTasks from './TaskDialogDetailsTaskDependenciesTasks'
export default {
  props: {
    tasks: {
      type: Array,
      required: true
    },
    titleText: {
      type: String,
      default: ''
    },
    titleIcon: {
      type: String,
      default: 'hourglass'
    },
    titleIconColor: {
      type: String,
      default: 'primary'
    },
    dependencies: {
      type: Array,
      required: true
    },
    dependencyType: {
      type: String,
      default: 'dependees'
    },
    editable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(['task']),
    tid() {
      return this.task.id
    },
    items() {
      return this.tasks.filter(
        (task) => this.dependencies.indexOf(task.id) > -1
      )
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      taskListHeight: 0
    }
  },
  methods: {
    ...mapActions(['updateTask']),
    async save() {
      this.loading = true
      const { taskDependencies } = this.$refs.tasks
      const values = [taskDependencies.map((id) => {
        return {
          id,
          type: 'Task'
        }
      })]
      try {
        await this.updateTask({
          path: this.dependencyType,
          tid: this.tid,
          data: {
            ids: taskDependencies
          },
          values,
          updateMyTask: false
        })
        this.dialog = false
      } catch (error) {}
      this.loading = false
    },
    setTaskListHeight() {
      this.taskListHeight = window.innerHeight - 60
    }
  },
  mounted() {
    this.setTaskListHeight()
  },
  components: {
    BaseIcon,
    BaseDialog,
    TaskDialogDetailsTaskDependenciesTasks
  }
}
</script>
