<template>
  <div>
    <div
      class="
        list-header-wrapper
        d-flex
        justify-space-between
        align-center
        py-4
        px-3
      "
    >
      <h3 class="text-h4">
        <v-btn
          v-if="isDummy && !list"
          text
          plain
          x-small
          class="text-h4 pa-0"
          :ripple="false"
          color="secondary"
          @click="$emit('create-list-clicked')"
          v-text="$t('common.create_new_list')"
        />
        <template v-else>
          <span v-if="!renaming">{{ list.title }}</span>
          <v-text-field
            v-else
            solo
            flat
            dense
            autofocus
            height="24"
            hide-details
            class="silent"
            style="width: 260px"
            v-model="list.title"
            background-color="secondary lighten-5"
            @keydown.enter="handleClickOnRenameButton"
          />
        </template>
      </h3>
      <template v-if="!isDummy && list">
        <div v-if="!renaming" class="d-flex">
          <span style="position: relative; top: 4px">
            <base-icon
              name="eye"
              color="primary"
              className="me-2"
              v-if="list.watched && !renaming"
            />
          </span>
          <task-sheet-list-actions
            :list="list"
            @rename-clicked="renaming = !renaming"
            :create-task-visible="createTaskVisible"
            @move-clicked="$emit('move-list-clicked')"
            @copy-clicked="$emit('copy-list-clicked')"
            @create-task-clicked="handleClickOnCreateTask(list)"
            @watch-clicked="$emit('watch-list-clicked')"
            @archive-clicked="$emit('archive-list-clicked')"
          />
        </div>
        <div class="d-flex pe-4" v-else>
          <v-btn
            fab
            text
            depressed
            width="24"
            height="24"
            class="me-3"
            @click="renaming = false"
          >
            <base-icon
              width="24"
              height="24"
              name="times"
              color="secondary.base"
              className="d-flex align-center"
            />
          </v-btn>
          <v-btn
            fab
            depressed
            width="24"
            height="24"
            color="primary"
            @click="handleClickOnRenameButton"
          >
            <base-icon
              width="18"
              height="18"
              name="check"
              color="secondary.lighten5"
              className="d-flex align-center"
            />
          </v-btn>
        </div>
      </template>
    </div>
    <v-data-table
      v-if="!isDummy && list"
      dense
      class="tasks-table"
      calculate-widths
      mobile-breakpoint="100"
      :items="listTasks"
      :headers="headers"
      :options.sync="options"
      :disable-pagination="true"
      :hide-default-footer="true"
      @click:row="
        $router.push({ name: 'task', params: { tid: $event.number } })
      "
    >
      <template v-slot:[`item.title`]="{ item }">
        <div
          v-text="item.title"
          class="text-truncate d-block"
          :class="$vuetify.rtl ? 'text-right' : ''"
          dir="auto"
          :style="isMobile ? 'width:300px' : 'max-width:600px;min-width:300px;'"
        />
      </template>

      <template v-slot:[`item.starting_at`]="{ item }">
        <base-date-time
          format="short"
          v-if="item.starting_at"
          :date="item.starting_at"
        />
        <span
          v-else
          class="body-2 secondary--text"
          v-text="$t('common.add_starting_at')"
        />
      </template>
      <template v-slot:[`item.due_at`]="{ item }">
        <base-date-time format="short" v-if="item.due_at" :date="item.due_at" />
        <span
          v-else
          class="body-2 secondary--text"
          v-text="$t('common.add_due_at')"
        />
      </template>
      <template v-slot:[`item.assignees`]="{ item }">
        <base-avatar-group
          :limit="5"
          :avatar-size="28"
          :overlapping="true"
          :circular-more="true"
          :list="item.assignees"
          v-if="item.assignees.length"
        />
        <div v-else class="d-flex align-center body-2 secondary--text">
          <v-btn
            fab
            x-small
            outlined
            disabled
            width="26"
            height="26"
            color="secondary"
          >
            <span class="subtitle-1" v-text="'+'" />
          </v-btn>
          <span class="ms-1">{{ $t('common.add_assignee') }}</span>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div
          class="text-truncate"
          v-text="item.status.title"
          style="display: block; max-width: 100px"
        />
      </template>
      <template v-slot:[`item.label`]="{ item }">
        <v-chip
          label
          v-if="item.label"
          text-color="white"
          style="height: 100%"
          v-text="item.label.title"
          :color="`#${item.label.color_code}`"
          class="fill-width justify-center color-label"
        />
        <v-chip
          label
          v-else
          color="white"
          text-color="secondary"
          v-text="`+ ${$t('common.add_label')}`"
          class="fill-width justify-center color-label placeholder"
        />
      </template>
      <template v-slot:[`item.tags`]="{ item }">
        <task-list-section-task-tags
          :tags="item.tags"
          v-if="item.tags.length"
        />
        <v-chip
          x-small
          v-else
          color="white"
          class="tag placeholder px-2"
          text-color="secondary darken-2"
          v-text="`+ ${$t('common.add_tag')}`"
        />
      </template>
      <template v-slot:[`item.progress`]="{ item }">
        <v-progress-linear
          height="100%"
          class="progress"
          :value="item.progress"
          background-color="transparent"
        >
          <template v-slot="{ value }">
            <div class="d-flex justify-start fill-width">
              <span class="ms-3" v-text="`${Math.ceil(value)}%`" />
            </div>
          </template>
        </v-progress-linear>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import BaseAvatarGroup from '@/components/BaseAvatarGroup'
import TaskListSectionTaskTags from '@/components/project/TaskListSectionTaskTags'
import BaseDateTime from '@/components/BaseDateTime'
import BaseIcon from '@/components/BaseIcon'
import TaskSheetListActions from '@/components/project/TaskSheetListActions'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    list: {
      type: Object
    },
    isDummy: {
      type: Boolean,
      default: false
    },
    createTaskVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      renaming: false,
      newTaskClicked: false,
      options: {},
      headers: [
        {
          text: '#',
          value: 'number',
          align: 'start',
          sortable: true,
          width: 70
        },
        {
          text: this.$i18n.t('common.title'),
          align: 'start',
          sortable: true,
          value: 'title',
          width: this.isMobile ? 100 : ''
        },
        {
          text: this.$i18n.t('common.status'),
          value: 'status',
          align: 'start',
          sortable: true,
          width: 100
        },
        {
          text: this.$i18n.t('common.assignee_plural'),
          value: 'assignees',
          align: 'start',
          width: 170,
          sortable: false
        },
        {
          text: this.$i18n.t('common.start_date'),
          value: 'starting_at',
          align: 'start',
          sortable: true,
          width: 140
        },
        {
          text: this.$i18n.t('common.due_date'),
          value: 'due_at',
          align: 'start',
          sortable: true,
          width: 140
        },
        {
          text: this.$i18n.t('common.color_label'),
          value: 'label',
          align: 'start',
          width: 135,
          cellClass: 'px-0',
          sortable: true
        },
        {
          text: this.$i18n.t('common.tag_plural'),
          value: 'tags',
          align: 'start',
          sortable: false
        },
        {
          text: this.$i18n.t('common.progress'),
          value: 'progress',
          cellClass: 'px-0',
          sortable: true,
          align: 'start',
          width: 130
        }
      ]
    }
  },
  methods: {
    ...mapActions(['getProjectTasks']),
    handleClickOnRenameButton() {
      this.renaming = false
      this.$emit('rename-list-clicked')
    },
    handleClickOnCreateTask(list) {
      this.newTaskClicked = true
      console.log('create task')
      this.$emit('create-task-clicked', list)
    }
  },
  computed: {
    ...mapGetters(['project', 'tasks', 'isMobile', 'projectStatuses']),
    listTasks() {
      if ((!this.list && this.isDummy) || !this.tasks) {
        return []
      }
      if (this.newTaskClicked) {
      }
      return this.tasks.filter(
        (task) => task.list.id === this.list.id && !task.archived
      )
    }
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc } = this.options
        if (!sortBy.length) {
          return
        }
        const sortSign = sortDesc[0] ? '-' : ''
        this.$emit('sort-changed', {
          sort_by: `${sortSign}${sortBy[0]}`
        })
      },
      deep: true
    }
  },
  async created() {
    await this.getProjectTasks(this.project.id)
  },
  components: {
    BaseAvatarGroup,
    TaskListSectionTaskTags,
    BaseDateTime,
    TaskSheetListActions,
    BaseIcon
  }
}
</script>

<style>
.tasks-table tbody tr {
  cursor: pointer;
}
</style>
