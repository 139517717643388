<template>
  <v-navigation-drawer
    permanent
    mini-variant
    class="mini-nav-drawer"
    mini-variant-width="64"
    color="secondary lighten-2"
  >
    <template v-if="organizations">
      <v-list-item
        class="px-2"
        :ripple="false"
        :key="organization.id"
        :class="{ 'mt-3': index === 0 }"
        v-for="(organization, index) in organizations"
        :href="getOrganizationUrl(organization.subdomain)"
      >
        <v-list-item-avatar
          :size="organization.id === currentOrganizationId ? 54 : 48"
          :min-width="organization.id === currentOrganizationId ? 54 : 48"
          :max-width="organization.id === currentOrganizationId ? 54 : 48"
          :class="{ bordered: organization.id === currentOrganizationId }"
        >
          <base-avatar
            :size="48"
            :title="organization.title"
            :image="organization.logo ? organization.logo.medium : ''"
          />
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item class="px-2">
        <v-list-item-avatar size="48" min-width="48" max-width="48">
          <v-btn
            icon
            outlined
            width="48"
            height="48"
            color="secondary"
            @click="showCreateOrganizationDialog"
          >
            <base-icon name="plus" color="secondary.base" />
          </v-btn>
        </v-list-item-avatar>
      </v-list-item>
    </template>
    <v-list-item class="px-2 mt-5" v-else>
      <v-skeleton-loader type="avatar" />
    </v-list-item>
    <template v-slot:append>
      <div class="pa-2 text-center">
        <span class="body-2 secondary--text" v-text="version" />
        <v-btn block icon>
          <base-icon name="life-ring" color="secondary.darken2" />
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapGetters, mapActions } from 'vuex'
import BaseAvatar from '@/components/BaseAvatar'

export default {
  computed: {
    ...mapGetters(['organizationSubdomain']),
    version() {
      return `v${process.env.VUE_APP_VERSION}`
    }
  },
  props: {
    organizations: {
      required: true
    },
    currentOrganizationId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseIcon,
    BaseAvatar
  },
  methods: {
    ...mapActions(['showCreateOrganizationDialog']),
    getOrganizationUrl(subdomain) {
      const { origin } = location
      const { organizationSubdomain } = this
      return organizationSubdomain
        ? origin.replace(organizationSubdomain, subdomain)
        : '#'
    }
  }
}
</script>
