<template>
  <v-app-bar
    app
    dark
    fixed
    :color="$vuetify.theme.dark ? '' : 'white'"
    :height="$vuetify.breakpoint.lgAndUp ? 60 : 48"
    :style="`z-index:${$vuetify.breakpoint.lgAndUp ? 5 : 3}`"
    :clipped-left="$vuetify.breakpoint.lgAndUp && !$vuetify.rtl"
    :clipped-right="$vuetify.breakpoint.lgAndUp && $vuetify.rtl"
    :class="`t-box-shadow${!$vuetify.breakpoint.lgAndUp ? ' mx-2 mt-2' : ''}`"
  >
    <v-app-bar-nav-icon @click.stop="$emit('drawer-toggle-clicked')">
      <base-icon
        name="bars"
        color="secondary.base"
        className="d-flex align-center"
      />
    </v-app-bar-nav-icon>
    <router-view name="toolbar" v-if="isMobile"></router-view>
    <slot name="appBarContent" />
    <v-spacer />
    <router-view name="toolbar" v-if="!isMobile"></router-view>
    <v-badge
      offset-x="25"
      offset-y="20"
      class="auto-height"
      color="error darken-1"
      :content="notificationCount"
      :value="user && unreadNotificationCount > 0"
    >
      <v-btn icon @click="dialog = true">
        <base-icon width="28" height="25" name="bell" color="secondary.base" />
      </v-btn>
    </v-badge>
    <v-btn @click="$router.push({ path: '/profile' })" icon>
      <base-avatar
        :size="30"
        class="ms-2 cursor pointer"
        :title="user ? user.name : ''"
        :image="user && user.avatar ? user.avatar.medium : ''"
      />
    </v-btn>
    <base-dialog
      :dialog.sync="dialog"
      :overflow-hidden="false"
      v-if="translationInitialized"
      content-class="small-level-one position-end"
      :title="$t('page.notifications.title')"
    >
      <template slot="toolbarActions" v-if="user">
        <v-btn
          icon
          class="me-1"
          color="primary"
          :loading="loading"
          @click="readNotifications"
          :disabled="loading || unreadNotificationCount === 0"
        >
          <base-icon
            name="t-all-done"
            :color="
              unreadNotificationCount > 0
                ? 'secondary.darken2'
                : 'secondary.lighten1'
            "
          />
        </v-btn>
      </template>
      <organization-notifications :dialog.sync="dialog" />
    </base-dialog>
  </v-app-bar>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapGetters, mapActions } from 'vuex'
import BaseAvatar from '@/components/BaseAvatar'
import BaseDialog from '@/components/BaseDialog'
import OrganizationNotifications from '@/components/notifications/OrganizationNotifications'
export default {
  computed: {
    ...mapGetters([
      'user',
      'unreadNotificationCount',
      'translationInitialized',
      'isMobile'
    ]),
    notificationCount() {
      const { user } = this
      if (!user) {
        return 0
      }
      return this.getTruncatedNumber(this.unreadNotificationCount)
    }
  },
  data() {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    ...mapActions(['markAllNotificationsAsRead']),
    async readNotifications() {
      this.loading = true
      try {
        await this.markAllNotificationsAsRead()
      } catch (error) {}
      this.loading = false
    }
  },
  components: {
    BaseIcon,
    BaseAvatar,
    BaseDialog,
    OrganizationNotifications
  }
}
</script>
