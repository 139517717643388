<template>
  <v-dialog
    :color="overlayColor"
    :value="dialog"
    @click:outside="goBack"
    :transition="transition"
    content-class="transparent justify-center align-center elevation-0"
    :max-width="windowSize.x * 0.8"
    :max-height="windowSize.y * 0.8"
    overlay-color="white"
    overlay-opacity="0.92"
    internal-activator
  >
    <v-card
      class="
        d-flex
        transparent
        relative
        fill-height
        justify-center
        align-center
      "
      width="100%"
      flat
    >
      <v-btn
        class="fixed"
        :class="{ 'd-none': !showCloseBtn }"
        depressed
        fab
        fixed
        top
        right
        color="secondary"
        x-small
        @click="handlePreviewURL"
      >
        <base-icon name="times" width="25" height="25" color="white" />
      </v-btn>
      <img
        v-if="fileType === 'image'"
        ref="img"
        class="d-flex"
        :src="item.download_url"
        :style="{
          maxWidth: '100%',
          height: 'auto'
        }"
      />
      <!-- </div> -->
      <video
        ref="previewItem"
        v-else-if="fileType === 'video'"
        :style="{
          maxWidth: '100%',
          height: 'auto',
          maxHeight: '100%'
        }"
        controls
        autoplay
        :poster="item.thumbnail ? item.thumbnail : ''"
      >
        <source :src="item.download_url" :type="!unsupportedTypes.includes(item.mime_type) ? item.mime_type : 'video/mp4'" />
        Your browser does not support the video tag.
      </video>
      <audio
        ref="previewItem"
        v-else-if="fileType === 'audio'"
        :style="{
          maxWidth: '100%',
          height: 'auto'
        }"
        controls
        autoplay
        :poster="item.thumbnail ? item.thumbnail : ''"
      >
        <source :src="item.download_url" :type="item.mime_type" />
        Your browser does not support the audio tag.
      </audio>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  props: {
    transition: {
      type: String,
      default: 'slide-x-transition'
    },
    dialog: {
      type: Boolean,
      default: false
    },
    overlayColor: {
      type: String,
      default: 'secondary lighten-5'
    },
    item: {
      type: Object,
      required: true
    },
    isPreview: {
      type: String,
      default: ''
    }
  },
  computed: {
    fileType() {
      if (this.item?.mime_type.includes('image')) return 'image'
      else if (this.item?.mime_type.includes('video')) return 'video'
      else if (this.item?.mime_type.includes('audio')) return 'audio'
      else if (this.item?.mime_type.includes('text')) return 'text'
      else if (this.item?.mime_type.includes('pdf')) return 'pdf'
      else return 'other'
    }
  },
  data() {
    return {
      windowSize: {
        x: null,
        y: null
      },
      showCloseBtn: false,
      unsupportedTypes: [
        'video/x-matroska',
        'video/quicktime'
      ]
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener)
    this.instance = null
  },
  mounted() {
    this._keyListener = function(e) {
      // e.preventDefault()
      if (e.key === 'Escape') {
        e.preventDefault()
        this.goBack()
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
    if (this.$route.query.preview) {
      this.handlePreviewQuery(false)
    }
    this.windowSize = {
      x: window.innerWidth,
      y: window.innerHeight
    }
    setTimeout(() => {
      this.showCloseBtn = true
    }, 1500)
  },
  methods: {
    handlePreviewURL() {
      if (this.fileType === 'audio' || this.fileType === 'video') {
        const item = this.$refs?.previewItem
        if (item) item.pause()
      }
      this.handlePreviewRouter()
    },
    handlePreviewRouter() {
      if (this.dialog) {
        this.handlePreviewQuery()
        this.$emit('update:dialog', false)
      } else {
        this.handlePreviewQuery(true)
        this.$emit('update:dialog', true)
      }
    },
    handlePreviewQuery(_isPreview = false) {
      this.$router.push({
        name: 'attachments',
        query: _isPreview ? { preview: true } : null
      })
    },
    goBack() {
      this.$emit('update:dialog', false)
      this.$emit('go-back-clicked', false)
    }
  },
  components: {
    BaseIcon
  }
}
</script>
