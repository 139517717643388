<template>
  <v-btn
    icon
    x-small
    depressed
    class="ma-0"
    :ripple="false"
    :disabled="disabled"
    @click="$emit('go-back-clicked')"
  >
    <template v-if="backIcon === 'arrow'">
      <base-angle-icon
        width="20"
        height="20"
        v-if="isMobile"
        :name="backIcon"
        fill="secondary.darken2"
      />
      <base-icon
        v-else
        width="20"
        height="20"
        name="times"
        color="secondary.darken2"
      />
    </template>
    <base-icon
      v-else
      width="20"
      height="20"
      :name="backIcon"
      color="secondary.darken2"
    />
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from './BaseIcon'
import BaseAngleIcon from './BaseAngleIcon'
export default {
  props: {
    backIcon: {
      type: String,
      default: 'arrow'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  components: {
    BaseIcon,
    BaseAngleIcon
  }
}
</script>
