import request from '@/api/axios'

export const fetchBoards = (pid, params) =>
  request({
    url: `/orgs/#oid/projects/${pid}/boards`,
    method: 'get',
    params
  })

export const createBoard = (pid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}/boards`,
    method: 'post',
    data
  })

export const patchBoard = (sid, data) =>
  request({
    url: `/orgs/#oid/boards/${sid}`,
    method: 'patch',
    data
  })

export const deleteBoard = sid =>
  request({
    url: `/orgs/#oid/boards/${sid}`,
    method: 'delete'
  })
