<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    content-class="small-level-one"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.boards.title')"
  >
    <template slot="toolbarActions">
      <v-btn
        text
        small
        class="me-1"
        color="primary"
        @click="handleCreate"
        v-text="$t('common.add_board')"
        v-if="
          !isMobile &&
          projectAbilities &&
          projectAbilities.abilities.create_board
        "
      />
    </template>
    <v-card
      tile
      flat
      height="100%"
      class="white info--text d-flex"
      v-if="projectBoards && !fetching"
    >
      <v-card-text class="flex-grow-1 d-flex pt-0">
        <section class="flex-grow-1 pa-4 secondary lighten-5 rounded-sm">
          <draggable
            group="items"
            v-model="items"
            handle=".handle"
            @change="updatePosition"
          >
            <settings-dialog-list-draggable-item
              :item="item"
              name="board"
              :key="item.id"
              :loading="loading"
              @save-clicked="handleSave"
              v-for="item in items"
              @delete-clicked="confirmDelete"
            />
          </draggable>
        </section>
      </v-card-text>
      <base-confirm-dialog
        :loading="loading"
        ok-color="error base"
        @ok-clicked="deleteBoard"
        :disabled="deleteDisabled"
        :ok-text="$t('common.delete')"
        :dialog.sync="confirmationDialog"
        cancel-color="secondary darken-2"
        :cancel-text="$t('common.cancel')"
        :title="$t('page.project_settings.boards.delete_confirmation.title')"
      >
        <section
          v-html="
            $t('page.project_settings.boards.delete_confirmation.content', {
              title: currentBoard ? currentBoard.value : '',
              interpolation: { escapeValue: false }
            })
          "
        />
        <section class="mt-3">
          {{
            $t(
              'page.project_settings.boards.delete_confirmation.type_to_confirm'
            )
          }}
          <v-text-field
            dense
            outlined
            autofocus
            type="text"
            class="mt-4"
            hide-details
            v-model="input"
            :label="$t('form.labels.board_title')"
          />
        </section>
      </base-confirm-dialog>
      <v-btn
        fab
        bottom
        fixed
        width="56"
        height="56"
        direction="top"
        color="primary"
        :left="$vuetify.rtl"
        @click="handleCreate"
        :right="!$vuetify.rtl"
        v-if="
          isMobile &&
          projectAbilities &&
          projectAbilities.abilities.create_board
        "
      >
        <base-icon name="plus" color="white" />
      </v-btn>
      <base-inline-form
        @closed="toggle(false)"
        card-text-class-name="px-4"
        :value="createBoardDialog"
        :title="$t('common.add_board')"
      >
        <v-form @submit.prevent="handleSave">
          <v-text-field
            dense
            outlined
            autofocus
            type="text"
            v-model="boardTitle"
            :label="$t('form.labels.board_title')"
          />
          <base-inline-form-actions
            :loading="loading"
            @ok-clicked="add"
            :ok-text="$t('common.add')"
            @cancel-clicked="toggle(false)"
          />
        </v-form>
      </base-inline-form>
    </v-card>
    <base-loading v-else />
  </base-dialog>
</template>

<script>
import sortBy from 'lodash.sortby'
import draggable from 'vuedraggable'
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseLoading from '@/components/BaseLoading'
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'
import { getNewPositionOfDroppedItem } from '@/utils/collection'
import BaseInlineFormActions from '@/components/BaseInlineFormActions'
import SettingsDialogListDraggableItem from './SettingsDialogListDraggableItem'

export default {
  props: {
    project: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      items: [],
      input: '',
      boardTitle: '',
      loading: false,
      fetching: false,
      currentBoard: null,
      createBoardDialog: false,
      confirmationDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'teamAbilities',
      'projectBoards',
      'projectAbilities'
    ]),
    deleteDisabled() {
      if (!this.currentBoard) {
        return false
      }
      return this.input !== this.currentBoard.value
    }
  },
  methods: {
    ...mapActions([
      'addProjectBoard',
      'getProjectBoards',
      'getTeamAbilities',
      'deleteProjectBoard',
      'updateProjectBoard'
    ]),
    toggle(value) {
      this.createBoardDialog = value
    },
    setItems() {
      const availableBoards = Object.keys(this.teamAbilities.boards)
      console.log('teamAbilities', this.teamAbilities)
      console.log('projectBoards', this.projectBoards)
      this.items = sortBy(
        Object.values(this.projectBoards).reduce((filtered, item) => {
          if (availableBoards.indexOf(item.id) > -1) {
            filtered.push({
              id: item.id,
              mode: 'edit',
              value: item.title,
              position: item.position,
              permanent: item.permanent
            })
          }
          return filtered
        }, []),
        (board) => board.position
      )
    },
    confirmDelete(currentBoard) {
      this.currentBoard = currentBoard
      this.confirmationDialog = true
    },
    async deleteBoard() {
      this.loading = true
      try {
        await this.deleteProjectBoard(this.currentBoard.id)
        this.setItems()
        this.confirmationDialog = false
        this.currentBoard = null
      } catch (error) {}
      this.loading = false
    },
    async add() {
      console.log('add', this.boardTitle)
      const { id: pid } = this.project
      this.loading = true
      try {
        await this.addProjectBoard({
          pid,
          data: {
            title: this.boardTitle,
            position: 'last'
          }
        })
        await this.getTeamAbilities(pid)
        this.setItems()
        this.toggle(false)
      } catch (error) {}
      this.loading = false
    },
    async updatePosition(value) {
      const { element } = value.moved
      const position = getNewPositionOfDroppedItem(this.items, element.id)
      try {
        await this.updateProjectBoard({
          board: element.id,
          data: {
            position
          }
        })
        this.setItems()
      } catch (error) {}
    },
    async save() {
      const { id, position } = this.currentBoard
      this.loading = true
      try {
        await this.updateProjectBoard({
          board: id,
          data: {
            title: this.boardTitle,
            position
          }
        })
        this.setItems()
      } catch (error) {}
      this.loading = false
    },
    async getData() {
      const { id: pid } = this.project
      this.fetching = true
      try {
        await this.getTeamAbilities(pid)
        await this.getProjectBoards(pid)
        this.setItems()
      } catch (error) {}
      this.fetching = false
    },
    handleSave({ item, title }) {
      this.currentBoard = item
      this.boardTitle = title
      if (this.boardTitle.length === 0) {
        return
      }
      if (this.currentBoard) {
        return this.save()
      }
      this.add()
    },
    // handleEdit({ item }) {
    //   this.currentBoard = item
    //   this.boardTitle = item.value
    //   // this.handleSave()
    // },
    handleCreate() {
      this.currentBoard = null
      this.boardTitle = ''
      this.toggle(true)
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    confirmationDialog() {
      this.input = ''
    }
  },
  components: {
    BaseIcon,
    draggable,
    BaseDialog,
    BaseLoading,
    BaseInlineForm,
    BaseConfirmDialog,
    BaseInlineFormActions,
    SettingsDialogListDraggableItem
  }
}
</script>
