<template>
  <v-tabs-items v-model="tab" class="secondary lighten-5 flex-grow-1">
    <v-tab-item>
      <profile-overview-list-security-authentication />
    </v-tab-item>
    <v-tab-item>
      <profile-overview-list-security-active-sessions />
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
import ProfileOverviewListSecurityActiveSessions from './ProfileOverviewListSecurityActiveSessions'
import ProfileOverviewListSecurityAuthentication from './ProfileOverviewListSecurityAuthentication'
export default {
  props: {
    tab: {
      required: true
    }
  },
  data() {
    return {
      items: ['authentication', 'active_sessions']
    }
  },
  components: {
    ProfileOverviewListSecurityAuthentication,
    ProfileOverviewListSecurityActiveSessions
  }
}
</script>
