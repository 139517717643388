var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"py-0"},[_c('v-subheader',{staticClass:"secondary--text text--darken-2 text-h5",domProps:{"textContent":_vm._s(_vm.title)}}),_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-list-item-action',{staticClass:"me-4"},[_c('base-icon',{attrs:{"width":"24","height":"24","color":"primary","name":"layer-group","className":"d-flex align-center"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2 info--text",domProps:{"textContent":_vm._s(item.title)}})],1),_c('v-list-item-icon',[_c('base-angle-icon',{staticClass:"flip-horizontal",attrs:{"width":"24","height":"24","fill":"secondary.base"}})],1)],1)}),_c('base-dialog',{attrs:{"card-color":"white","dialog":_vm.dialog,"title":_vm.projectTitle,"content-class":"small-level-two"},on:{"update:dialog":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"cardActions",fn:function(){return [(_vm.stats)?_c('div',{staticClass:"pa-4 flex-grow-0"},[(!_vm.stats.closed)?[(_vm.organization.owner.id === _vm.user.id && !_vm.stats.joined)?_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary","loading":_vm.loading,"disabled":_vm.loading},domProps:{"textContent":_vm._s(
              _vm.$t(
                'page.organization_settings.projects.project.actions.add_me_as_admin'
              )
            )},on:{"click":_vm.join}}):_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary","to":{
              name: 'board',
              params: { pid: _vm.pid, view: 'kanban' }
            }}},[_c('base-icon',{attrs:{"width":"24","height":"24","color":"white","name":"external-link-alt"}}),_vm._v(" "+_vm._s(_vm.$t( 'page.organization_settings.projects.project.actions.go_to_project' ))+" ")],1)]:_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary","loading":_vm.loading,"disabled":_vm.loading},domProps:{"textContent":_vm._s(
            _vm.$t('page.organization_settings.projects.project.actions.restore')
          )},on:{"click":_vm.restore}})],2):_vm._e()]},proxy:true}])},[(_vm.stats)?_c('v-container',{staticClass:"px-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center justify-center"},[_c('v-col',{staticClass:"body-2 secondary--text",attrs:{"cols":"6"},domProps:{"textContent":_vm._s(_vm.$t('page.organization_settings.projects.project.status'))}}),_c('v-col',{staticClass:"body-2 text-end",class:{
            'error--text': _vm.stats.closed,
            'success--text': !_vm.stats.closed
          },attrs:{"cols":"6"},domProps:{"textContent":_vm._s(_vm.$t(("common." + (_vm.stats.closed ? 'closed' : 'active'))))}})],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-col',{staticClass:"body-2 secondary--text",attrs:{"cols":"6"},domProps:{"textContent":_vm._s(
            _vm.$t('page.organization_settings.projects.project.last_activity')
          )}}),_c('v-col',{staticClass:"body-2 text-end info--text",attrs:{"cols":"6"}},[_c('base-date-time',{attrs:{"format":"long_with_time","date":_vm.stats.last_activity_created_at}})],1)],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-col',{staticClass:"body-2 secondary--text",attrs:{"cols":"6"},domProps:{"textContent":_vm._s(_vm.$t('page.organization_settings.projects.project.tasks'))}}),_c('v-col',{staticClass:"body-2 text-end info--text",attrs:{"cols":"6"},domProps:{"textContent":_vm._s(_vm.$t('common.task_with_count', { count: _vm.stats.tasks_count }))}})],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-col',{staticClass:"body-2 secondary--text",attrs:{"cols":"6"},domProps:{"textContent":_vm._s(_vm.$t('page.organization_settings.projects.project.members'))}}),_c('v-col',{staticClass:"body-2 text-end info--text",attrs:{"cols":"6"},domProps:{"textContent":_vm._s(
            _vm.$t('common.member_with_count', {
              count: _vm.stats.members_count
            })
          )}})],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-col',{staticClass:"body-2 secondary--text",attrs:{"cols":"6"},domProps:{"textContent":_vm._s(_vm.$t('page.organization_settings.projects.project.owner'))}}),_c('v-col',{staticClass:"body-2 text-end info--text",attrs:{"cols":"6"},domProps:{"textContent":_vm._s(_vm.stats.owner.name)}})],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-col',{staticClass:"body-2 secondary--text",attrs:{"cols":"6"},domProps:{"textContent":_vm._s(
            _vm.$t('page.organization_settings.projects.project.created_at')
          )}}),_c('v-col',{staticClass:"body-2 text-end info--text",attrs:{"cols":"6"}},[_c('base-date-time',{attrs:{"date":_vm.stats.created_at,"format":"long_with_time"}})],1)],1)],1):_c('base-loading')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }