<template>
  <fragment>
    <h3
      v-text="$t('page.sign_up.title')"
      class="text-h1 info--text fill-width mt-4 mb-8"
    />
    <v-form
      :disabled="loading"
      v-model="formIsValid"
      class="flex-grow-1 d-flex justify-start flex-column fill-width mt-6"
    >
      <section>
        <v-text-field
          flat
          dense
          outlined
          v-model="form.name"
          :rules="rules.fullname"
          :label="$t('form.labels.full_name')"
        />
        <v-text-field
          dense
          outlined
          :rules="rules.username"
          v-model="form.username"
          :label="$t('form.labels.username')"
        />
        <v-text-field
          dense
          outlined
          v-model="form.email"
          :rules="rules.email"
          :label="$t('form.labels.email')"
        />
        <base-password
          :rules="rules.password"
          :validate-on-blur="false"
          :value.sync="form.password"
          :label="$t('form.labels.password')"
        />
        <v-checkbox
          dense
          class="mt-0"
          hide-details
          :label="
            $t('form.labels.sign_up_for_app_news_and_updates', {
              appName: 'Taskulu'
            })
          "
          v-model="form.receive_news"
        />
        <p
          class="mt-4 caption secondary--text"
          v-html="
            $t('page.sign_up.tos', {
              privacyLink,
              termsLink
            })
          "
        />
      </section>
    </v-form>
    <div class="flex-grow-0 mb-6 fill-width">
      <v-btn
        block
        depressed
        color="primary"
        :loading="loading"
        :disabled="!formIsValid"
        @click="$emit('sign-up-button-hit', form)"
      >
        {{ $t('common.sign_up') }}
      </v-btn>
      <p class="body-2 info--text mt-4">
        {{ $t('page.sign_up.already_have_an_account') }}
        <router-link
          class="text-decoration-none"
          :to="{ path: '/select-organization' }"
        >
          {{ $t('page.sign_up.sign_in') }}
        </router-link>
      </p>
    </div>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import BasePassword from '@/components/BasePassword'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['termsLink', 'privacyLink'])
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        username: '',
        password: '',
        receive_news: false
      },
      rules: {
        email: this.getEmailRules(),
        username: this.getUsernameRules(),
        fullname: this.getFullNameRules(),
        password: this.getPasswordRules()
      },
      formIsValid: false
    }
  },
  components: { BasePassword }
}
</script>
