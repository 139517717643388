import {
  SET_TASKS_SEARCH_RESULTS,
  ADD_TASKS_SEARCH_RESULTS
} from '../mutation-types'

import { fetchTasks, createTaskExport } from '@/api/tasks'

const state = {
  tasksSearchResults: null
}

const mutations = {
  [SET_TASKS_SEARCH_RESULTS](state, tasksSearchResults) {
    state.tasksSearchResults = tasksSearchResults
  },
  [ADD_TASKS_SEARCH_RESULTS](state, tasksSearchResults) {
    state.tasksSearchResults.records = [
      ...state.tasksSearchResults.records,
      ...tasksSearchResults.records
    ]
    state.tasksSearchResults.offset_token = tasksSearchResults.offset_token
    state.tasksSearchResults.total_duration = tasksSearchResults.total_duration
  }
}

const actions = {
  async getTasksSearchResults({ commit }, { pid, params }) {
    const { data } = await fetchTasks(pid, params)
    const { data: tasks } = data
    commit(SET_TASKS_SEARCH_RESULTS, tasks)
  },
  async addTasksSearchResults({ commit }, { pid, params }) {
    const { data } = await fetchTasks(pid, params)
    const { data: tasks } = data
    commit(ADD_TASKS_SEARCH_RESULTS, tasks)
  },
  async exportTasks({ state }, { pid, data }) {
    const result = await createTaskExport(pid, data)
    window.open(result.data.data.url)
  }
}

const getters = {
  tasksSearchResults: state => state.tasksSearchResults
}

export default {
  state,
  actions,
  getters,
  mutations
}
