<template>
  <div
    class="d-flex flex-column justify-start align-end"
    v-if="taskNumber || taskNotifications"
  >
    <v-badge
      inline
      color="error darken-1"
      v-if="taskNotifications"
      class="ms-0 auto-height no-margin mb-3"
      :content="getTruncatedNumber(taskNotifications)"
    />
    <div class="ms-1 weight body-2 secondary--text text--darken-2" v-if="taskNumber">
      #{{ taskNumber }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    taskNumber: {
      required: true
    },
    taskNotifications: {
      required: true
    }
  }
}
</script>
<style lang="scss">
.v-badge {
  &.no-margin.v-badge--inline {
    .v-badge__wrapper {
      margin: 0;
    }
  }
  &.auto-height {
    .v-badge__badge {
      height: auto;
      padding: 2px 4px 2px 4px;
      font-size: 8px;
      min-width: 16px;
      font-weight: 600;
    }
  }
}
</style>
