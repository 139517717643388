<template>
  <base-dialog
    :dialog="taskDialogIsVisible"
    cardTextClass="task-dialog-content"
    @go-back-clicked="handleClickOnGoBack"
    content-class="task-modal full-dialog large-level-one"
  >
    <template v-slot:toolbar>
      <v-toolbar
        flat
        extended
        height="90"
        extension-height="42"
        class="flex-grow-0 border-bottom-light-grey"
      >
        <base-dialog-back-btn @go-back-clicked="handleClickOnGoBack" />
        <v-toolbar-title class="text-h5 info--text" v-if="task">
          {{ task.title }}
          <h3
            v-if="project && projectLists && projectBoards"
            class="caption secondary--text subtitle mt-2"
            v-html="
              $t('page.task_view.subtitle_new', {
                number: `#${task.number}`,
                creator: task.creator.name,
                createdDate: $t('date.formats.iso.long', {
                  date: new Date(task.created_at * 1000)
                }),
                interpolation: { escapeValue: false }
              })
            "
          />
        </v-toolbar-title>

        <v-spacer />
        <task-dialog-actions
          v-if="task"
          :priority="listAbilityPriority"
          @task-archive-toggled="$emit('task-archive-toggled', task)"
        />
        <template v-slot:extension v-if="task && tabs">
          <v-tabs
            height="42"
            v-model="tab"
            center-active
            class="flex-grow-0"
            :show-arrows="false"
          >
            <v-tab
              :key="index"
              class="text-button"
              v-for="(_tab, index) in tabs"
              @click="() => changeRoute(_tab.route)"
            >
              <strong>
                {{ $t(`common.${_tab.title}`, { count: 0 }) }}
              </strong>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
    </template>
    <div
      :class="{ 'tab-wrapper': tabs[tab].title === 'comment' }"
      v-if="project && task"
    >
      <v-tabs-items
        touchless
        v-model="tab"
        class="sections flex-grow-1 secondary lighten-5"
      >
        <v-tab-item :transition="false" class="fill-width">
          <task-dialog-details
            ref="taskDetails"
            :priority="listAbilityPriority"
          />
        </v-tab-item>
        <v-tab-item :transition="false">
          <task-dialog-files
            :user="user"
            ref="taskAttachments"
            @go-back-checked="goBack"
            :users="organizationUsers"
            :priority="listAbilityPriority"
            :attachments="task.attachments"
          />
        </v-tab-item>
        <v-tab-item :transition="false" class="flex-grow-1">
          <task-dialog-checklists
            v-if="task"
            :checklists="task.checklists"
            :priority="listAbilityPriority"
          />
        </v-tab-item>
        <v-tab-item :transition="false">
          <task-dialog-comments ref="taskComments" />
        </v-tab-item>
        <v-tab-item :transition="false">
          <task-dialog-activities />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <base-loading v-else />
  </base-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseLoading from '@/components/BaseLoading'
import BaseDialogBackBtn from '@/components/BaseDialogBackBtn'
import TaskDialogFiles from '@/components/task/TaskDialogFiles'
import TaskDialogDetails from '@/components/task/TaskDialogDetails'
import TaskDialogActions from '@/components/task/TaskDialogActions'
import TaskDialogComments from '@/components/task/TaskDialogComments2'
import TaskDialogChecklists from '@/components/task/TaskDialogChecklists'
import TaskDialogActivities from '@/components/task/TaskDialogActivities'
// import { linkClickEventHandler } from '@/utils/quill'
export default {
  props: {
    goHome: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'task',
      'project',
      'organization',
      'projectLists',
      'projectBoards',
      'teamAbilities',
      'organizationUsers',
      'taskDialogIsVisible',
      'abilitiesPriorityMap'
    ]),
    taskDateInMiliseconds() {
      return new Date(this.task.created_at * 1000)
    },
    listAbilityPriority() {
      const { teamAbilities } = this
      if (!teamAbilities) {
        return 0
      }
      return teamAbilities.lists[this.task.list.id] || 0
    }
  },
  methods: {
    setTitle(task) {
      this.title = task.title
    },
    changeRoute(name) {
      if (this.$route.name !== 'Home') {
        const view = this.$route.params.view || 'kanban'
        this.$router.push({ name, view })
      }
    },
    goBack() {
      const view = this.$route.params.view || 'kanban'
      if (this.goHome) {
        this.$emit('task-go-back-clicked')
      } else if (this.$route.query.preview) {
        this.$router.push({
          name: 'attachments',
          query: null
        })
      } else {
        this.$router.push({ name: 'board', view })
      }
    },
    scrollFix(hashbang) {
      location.hash = hashbang
    },
    handleClickOnGoBack() {
      const { taskAttachments } = this.$refs
      if (!taskAttachments) {
        return this.goBack()
      }
      taskAttachments.checkFileUploads()
    },
    setActiveTab() {
      const { query, name } = this.$route
      if (query.tab) {
        const index = this.tabs.map((tab) => tab.title).indexOf(query.tab)
        this.tab = index > -1 ? index : 0
      } else {
        switch (name) {
          case 'attachments':
            this.tab = 1
            break
          case 'checklists':
            this.tab = 2
            break
          case 'comments':
            this.tab = 3
            break
          case 'activities':
            this.tab = 4
            break
          default:
            this.tab = 0
            break
        }
      }
    },
    linkClickEventHandler(event) {
      let { target } = event
      while (target && target.tagName !== 'A') target = target.parentNode
      if (target && target.matches("a:not([href*='://'])") && target.href) {
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } =
          event
        if (metaKey || altKey || ctrlKey || shiftKey) return
        if (defaultPrevented) return
        if (button !== undefined && button !== 0) return
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target')
          if (/\b_blank\b/i.test(linkTarget)) return
        }
        const url = new URL(target.href)
        const to = url.pathname
        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault()
          this.$router.push(to)
        } else if (window.location.pathname === to && event.preventDefault) {
          event.preventDefault()
        }
      }
    },
    checkLinkListener() {
      const { name } = this.$route
      if (name === 'comments' || name === 'task') {
        const isRemoveListenerMissing = typeof this.linkListener !== 'function'
        switch (name) {
          case 'comments':
            if (isRemoveListenerMissing) {
              setTimeout(() => {
                if ('taskComments' in this.$refs) {
                  this.linkListener = this.$refs.taskComments.setLinkListener(
                    this.linkClickEventHandler
                  )
                }
              }, 500)
            }
            break
          case 'task':
            if (isRemoveListenerMissing) {
              setTimeout(() => {
                if ('taskDetails' in this.$refs) {
                  this.linkListener = this.$refs.taskDetails.setLinkListener(
                    this.linkClickEventHandler
                  )
                }
              }, 500)
            }
            break
          default:
            break
        }
      } else {
        if (typeof this.linkListener === 'function') {
          this.linkListener()
          this.linkListener = null
        }
      }
    }
  },
  data: () => ({
    tab: null,
    tabs: [
      {
        title: 'detail',
        route: 'task'
      },
      {
        title: 'file',
        route: 'attachments'
      },
      {
        title: 'checklist',
        route: 'checklists'
      },
      {
        title: 'comment',
        route: 'comments'
      },
      {
        title: 'activity',
        route: 'activities'
      }
    ],
    linkListener: null
  }),
  mounted() {
    this.setActiveTab()
    this.checkLinkListener()
  },
  watch: {
    '$route.query'() {
      this.setActiveTab()
      this.checkLinkListener()
    }
  },
  components: {
    BaseDialog,
    BaseLoading,
    TaskDialogFiles,
    BaseDialogBackBtn,
    TaskDialogDetails,
    TaskDialogActions,
    TaskDialogComments,
    TaskDialogActivities,
    TaskDialogChecklists
  }
}
</script>
