<template>
  <v-btn
    fab
    depressed
    width="32"
    height="32"
    color="primary"
    style="z-index: 2"
    :loading="loading"
    :disabled="disabled"
    class="send flex-grow-0 me-0"
    @click="$emit('send-clicked')"
    :class="{ left: $vuetify.rtl }"
  >
    <unicon
      width="12"
      height="12"
      fill="#fff"
      name="message"
      :class="{ 'flip-horizontal': $vuetify.rtl }"
    />
  </v-btn>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
