<template>
  <fragment>
    <v-btn icon small class="ql-bold">
      <base-icon width="20" height="20" color="info" name="bold" />
    </v-btn>
    <v-btn icon small class="ql-italic">
      <base-icon width="20" height="20" color="info" name="italic" />
    </v-btn>
    <v-btn icon small class="ql-underline">
      <base-icon width="20" height="20" color="info" name="underline" />
    </v-btn>
    <v-btn icon small class="ql-strike">
      <base-icon
        width="20"
        height="20"
        color="info"
        name="text-strike-through"
      />
    </v-btn>
    <v-btn icon small class="ql-link">
      <base-icon width="20" height="20" color="info" name="link" />
    </v-btn>
    <v-btn icon small class="ql-code">
      <base-icon name="arrow" width="20" height="20" color="info" />
    </v-btn>
    <v-btn icon small class="ql-code-block">
      <base-icon width="20" height="20" color="info" name="brackets-curly" />
    </v-btn>
    <v-btn icon small class="ql-list" value="bullet">
      <base-icon width="20" height="20" color="info" name="list-ul" />
    </v-btn>
    <v-btn icon small class="ql-list" value="ordered">
      <base-icon width="20" height="20" color="info" name="t-list-ol" />
    </v-btn>
    <v-btn icon small class="ql-align" value="">
      <base-icon width="20" height="20" color="info" name="align-left" />
    </v-btn>
    <v-btn icon small class="ql-align" value="center">
      <base-icon width="20" height="20" color="info" name="align-center" />
    </v-btn>
    <v-btn icon small class="ql-align" value="right">
      <base-icon width="20" height="20" color="info" name="align-right" />
    </v-btn>
    <v-btn icon small class="ql-direction" value="rtl">
      <base-icon
        width="20"
        height="20"
        color="info"
        name="right-to-left-text-direction"
      />
    </v-btn>
  </fragment>
</template>
<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  components: {
    BaseIcon
  }
}
</script>
