var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"dialog":_vm.dialog,"card-color":"white","content-class":"small-level-one","title":_vm.$t('page.project_settings.boards.title')},on:{"go-back-clicked":function($event){return _vm.$emit('update:dialog', false)}}},[_c('template',{slot:"toolbarActions"},[(
        !_vm.isMobile &&
        _vm.projectAbilities &&
        _vm.projectAbilities.abilities.create_board
      )?_c('v-btn',{staticClass:"me-1",attrs:{"text":"","small":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('common.add_board'))},on:{"click":_vm.handleCreate}}):_vm._e()],1),(_vm.projectBoards && !_vm.fetching)?_c('v-card',{staticClass:"white info--text d-flex",attrs:{"tile":"","flat":"","height":"100%"}},[_c('v-card-text',{staticClass:"flex-grow-1 d-flex pt-0"},[_c('section',{staticClass:"flex-grow-1 pa-4 secondary lighten-5 rounded-sm"},[_c('draggable',{attrs:{"group":"items","handle":".handle"},on:{"change":_vm.updatePosition},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},_vm._l((_vm.items),function(item){return _c('settings-dialog-list-draggable-item',{key:item.id,attrs:{"item":item,"name":"board","loading":_vm.loading},on:{"save-clicked":_vm.handleSave,"delete-clicked":_vm.confirmDelete}})}),1)],1)]),_c('base-confirm-dialog',{attrs:{"loading":_vm.loading,"ok-color":"error base","disabled":_vm.deleteDisabled,"ok-text":_vm.$t('common.delete'),"dialog":_vm.confirmationDialog,"cancel-color":"secondary darken-2","cancel-text":_vm.$t('common.cancel'),"title":_vm.$t('page.project_settings.boards.delete_confirmation.title')},on:{"ok-clicked":_vm.deleteBoard,"update:dialog":function($event){_vm.confirmationDialog=$event}}},[_c('section',{domProps:{"innerHTML":_vm._s(
          _vm.$t('page.project_settings.boards.delete_confirmation.content', {
            title: _vm.currentBoard ? _vm.currentBoard.value : '',
            interpolation: { escapeValue: false }
          })
        )}}),_c('section',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t( 'page.project_settings.boards.delete_confirmation.type_to_confirm' ))+" "),_c('v-text-field',{staticClass:"mt-4",attrs:{"dense":"","outlined":"","autofocus":"","type":"text","hide-details":"","label":_vm.$t('form.labels.board_title')},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)]),(
        _vm.isMobile &&
        _vm.projectAbilities &&
        _vm.projectAbilities.abilities.create_board
      )?_c('v-btn',{attrs:{"fab":"","bottom":"","fixed":"","width":"56","height":"56","direction":"top","color":"primary","left":_vm.$vuetify.rtl,"right":!_vm.$vuetify.rtl},on:{"click":_vm.handleCreate}},[_c('base-icon',{attrs:{"name":"plus","color":"white"}})],1):_vm._e(),_c('base-inline-form',{attrs:{"card-text-class-name":"px-4","value":_vm.createBoardDialog,"title":_vm.$t('common.add_board')},on:{"closed":function($event){return _vm.toggle(false)}}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSave($event)}}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","autofocus":"","type":"text","label":_vm.$t('form.labels.board_title')},model:{value:(_vm.boardTitle),callback:function ($$v) {_vm.boardTitle=$$v},expression:"boardTitle"}}),_c('base-inline-form-actions',{attrs:{"loading":_vm.loading,"ok-text":_vm.$t('common.add')},on:{"ok-clicked":_vm.add,"cancel-clicked":function($event){return _vm.toggle(false)}}})],1)],1)],1):_c('base-loading')],2)}
var staticRenderFns = []

export { render, staticRenderFns }