<template>
  <fragment>
    <task-dialog-checklist-items-actions
      :visible="visible"
      :title="checklist.title"
      :items="checklistItems"
      :completed-visible="completedVisible"
      @delete-clicked="handleClickOnDeleteChecklist()"
      @visibility-toggled="toggleChecklistVisibility()"
      @edit-clicked="$emit('edit-checklist-clicked', checklist)"
      @items-visibility-toggled="toggleChecklistItemVisibility()"
      :editable="priority >= abilitiesPriorityMap.CheckList.edit"
      :deletable="priority >= abilitiesPriorityMap.CheckList.delete"
    />
    <v-expand-transition>
      <v-slide-y-transition
        group
        dense
        tag="div"
        name="v-list"
        v-show="visible"
        class="py-0 v-list--dense"
        color="secondary lighten-2"
      >
        <task-dialog-checklist-items-item
          :key="i"
          :option="item"
          :cid="checklist.id"
          v-for="(item, i) in checklistItems"
          :editable="priority >= abilitiesPriorityMap.CheckList.edit"
          :deletable="priority >= abilitiesPriorityMap.CheckList.delete"
        />
        <task-dialog-checklist-items-add-item
          key="add-item"
          :loading="loading"
          ref="addChecklistItem"
          @option-added="addListItem($event)"
          v-if="priority >= abilitiesPriorityMap.Task.create_checklist_item"
        />
      </v-slide-y-transition>
    </v-expand-transition>
    <base-confirm-dialog
      cancelColor="primary"
      :dialog.sync="dialog"
      okColor="error base"
      @ok-clicked="deleteChecklist"
      :okText="$t('common.delete')"
      :cancelText="$t('common.cancel')"
      :title="$t('page.task_checklist.delete_confirmation.title')"
      :content="$t('page.task_checklist.delete_confirmation.content')"
    />
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'
import TaskDialogChecklistItemsItem from '@/components/task/TaskDialogChecklistItemsItem'
import TaskDialogChecklistItemsActions from '@/components/task/TaskDialogChecklistItemsActions'
import TaskDialogChecklistItemsAddItem from '@/components/task/TaskDialogChecklistItemsAddItem'
export default {
  props: {
    checklist: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    pid: {
      type: String,
      required: true
    },
    priority: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters(['abilitiesPriorityMap']),
    checklistItems() {
      const { checklist } = this
      const { items } = checklist
      if (!items) return []
      if (this.completedVisible) {
        return items
      }
      return items.filter((item) => !item.completed)
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      completedVisible: true,
      visible: true
    }
  },
  methods: {
    ...mapActions(['addTaskChecklistItem']),
    async addListItem(title) {
      const { checklist } = this
      this.loading = true
      try {
        await this.addTaskChecklistItem({
          cid: checklist.id,
          pid: this.pid,
          data: {
            checklist_id: checklist.id,
            title
          }
        })
      } catch (error) {}
      this.loading = false
    },
    toggleChecklistVisibility() {
      this.visible = !this.visible
    },
    toggleChecklistItemVisibility() {
      this.completedVisible = !this.completedVisible
    },
    handleClickOnDeleteChecklist() {
      this.dialog = true
    },
    deleteChecklist() {
      this.dialog = false
      this.$emit('checklist-deleted', this.index)
    }
  },
  components: {
    BaseConfirmDialog,
    TaskDialogChecklistItemsItem,
    TaskDialogChecklistItemsActions,
    TaskDialogChecklistItemsAddItem
  }
}
</script>
