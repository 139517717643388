<template>
  <div class="d-flex flex-column fill-height">
    <div class="boards flex-grow-0">
      <template v-if="isMobile">
        <div class="px-3">
          <h2
            class="
            my-4
            overflow-hidden
            text-overflow-ellipsis text-h4 text-no-wrap text--darken-4
          "
            v-text="project.title"
          />
        </div>
        <div class="px-3 d-flex justify-space-between align-center">
          <boards-select
            :board="view"
            :boards="views"
            @board-changed="handleBoardChange($event)"
          />
        </div>
      </template>
      <template v-else>
        <div class="px-3">
          <div class="d-flex justify-space-between mt-3 mb-2 align-center">
            <h2
              class="
              my-4
              d-inline-block
              graphited--text
              overflow-hidden
              text-overflow-ellipsis text-h4 text-no-wrap text--darken-4
            "
              v-text="project.title"
            />
            <boards-select
              :board="view"
              :boards="views"
              @board-changed="handleBoardChange($event)"
            />
          </div>
        </div>
      </template>

    </div>
    <fragment v-if="view !== 'timeline' && view !== 'gantt'">
      <div class="mt-2 flex-grow-0">
        <v-tabs
          height="42"
          class="sheet-tabs"
          v-model="currentItem"
          :show-arrows="!isMobileDevice"
        >
          <v-tab
            class="text-h4"
            v-for="board in sortedBoards"
            :key="board.id"
            :href="`#tab-${board.id}`"
          >
            <strong
              class="text-button text-truncate"
              :class="
                currentItem === `tab-${board.id}` ? 'primary--text' : 'info--text'
              "
            >
              {{ board.title }}
            </strong>
          </v-tab>
          <v-btn
            small
            outlined
            depressed
            class="mt-2 mx-1"
            color="secondary lighten-2"
            @click="openAddBoardOrListSheet('board')"
            v-if="projectAbilities && projectAbilities.abilities.create_board"
          >
            <span class="secondary--text text--darken-1">
              + {{ $t('common.add_board') }}
            </span>
          </v-btn>
          <v-btn
            icon
            class="mt-1 mx-1"
            :to="{ name: 'ProjectSettings', params: { pid: project.id } }"
            v-if="projectAbilities && projectAbilities.abilities.manage_boards"
          >
            <base-icon name="cog" color="secondary.darken2" />
          </v-btn>
        </v-tabs>
      </div>
      <v-tabs-items
        touchless
        v-model="currentItem"
        class="flex-grow-1 task-lists"
      >
        <v-tab-item
          class="fill-height"
          v-for="board in sortedBoards"
          :key="board.id"
          :transition="false"
          :id="`tab-${board.id}`"
          :value="`tab-${board.id}`"
          :reverse-transition="false"
        >
          <task-list-kanban
            v-if="view === 'kanban'"
            :lists="currentBoardLists"
            :create-list-visible="createListVisible"
            :create-task-visible="createTaskVisible"
            @project-task-moved="handleTaskMove"
            @rename-list-clicked="changeListTitle"
            @watch-list-clicked="changeListWatchStatus"
            @archive-list-clicked="changeListArchiveStatus"
            @add-list-clicked="openAddBoardOrListSheet('list')"
            @create-list-clicked="openAddBoardOrListSheet('list')"
            @move-list-clicked="openMoveOrCopyListDialog('move', $event)"
            @copy-list-clicked="openMoveOrCopyListDialog('copy', $event)"
          />
          <task-list-table
            v-if="view === 'table'"
            :lists="currentBoardLists"
            :create-list-visible="createListVisible"
            :create-task-visible="createTaskVisible"
            @project-task-moved="handleTaskMove"
            @create-task-clicked="openAddTaskSheet"
            @rename-list-clicked="changeListTitle"
            @watch-list-clicked="changeListWatchStatus"
            @archive-list-clicked="changeListArchiveStatus"
            @add-list-clicked="openAddBoardOrListSheet('list')"
            @create-list-clicked="openAddBoardOrListSheet('list')"
            @move-list-clicked="openMoveOrCopyListDialog('move', $event)"
            @copy-list-clicked="openMoveOrCopyListDialog('copy', $event)"
          />
          <div v-else class="pa-4" v-text="view" />
        </v-tab-item>
      </v-tabs-items>
    </fragment>
    <fragment v-else>
      <!-- Timeline and gantt chart -->
      <div class="fill-height d-flex align-center justify-center">
        <div class="">
          <div class="d-flex flex-column align-center">
            <v-img
              width="196"
              height="161"
              src="../../assets/img/illustrations/coming-soon.svg"
            />
            <h4 class="mt-6 h4-text secondary--text text--darken-2">
              {{ $t('common.coming_soon') }}
            </h4>
          </div>
        </div>
      </div>
    </fragment>
    <task-list-new-task
      :sheet="newTaskSheet"
      :value.sync="newTaskSheet"
      :loading="creatingNewTask"
      :lists="currentBoardLists"
      :input.sync="newTaskForm.title"
      :list.sync="newTaskForm.list_id"
      @done-clicked="handleClickOnAddTaskDone"
      @cancel-clicked="handleClicOnAddTaskCancel"
    />
    <task-list-move-or-copy-list
      :multiple="false"
      :board="currentBoardId"
      :boards="sortedBoards"
      :list="listToBeMovedOrCopied"
      :loading="movingOrCopyingList"
      :dialog.sync="moveOrCopyListDialog"
      @cancel-clicked="moveOrCopyListDialog = false"
      @done-clicked="handleClickOnMoveOrCopyListDone"
      :title="$t(`common.${listTransferType}_to_board`)"
      :selectedItems.sync="selectedBoardsToMoveOrCopyList"
    />
    <task-list-new-board-or-list
      :input.sync="newBoardOrListName"
      :value.sync="newBoardOrListSheet"
      :loading="creatingNewBoardOrList"
      @cancel-clicked="closeAddBoardOrSheet"
      :title="$t(`common.new_${creationType}`)"
      :label="$t(`form.labels.${creationType}_name`)"
      @done-clicked="handleClickOnAddBoardOrListDone"
    />
    <task-list-add-fab
      @new-task-clicked="openAddTaskSheet"
      @new-list-clicked="openAddBoardOrListSheet('list')"
      :create-list-visible="createListVisible"
      :create-task-visible="createTaskVisible"
      v-if="
        isMobile &&
        (createListVisible || createTaskVisible) &&
        currentBoardHasAtLeastOneList
      "
    />
    <v-snackbar
      top
      class="body-1"
      :min-width="328"
      v-model="snackbar"
      color="info darken-2"
    >
      {{ $t(`messages.${taskUpdated ? 'task' : 'list'}_archived`) }}
      <template v-slot:action="{ attrs }">
        <v-btn text color="primary" v-bind="attrs" @click="handleUndo">
          {{ $t('common.undo') }}
        </v-btn>
      </template>
    </v-snackbar>
    <task-dialog @task-archive-toggled="changeTaskArchivedStatus" />
  </div>
</template>

<script>
import sortBy from 'lodash.sortby'
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import TaskDialog from '@/components/task/TaskDialog'
import BoardsSelect from '@/components/project/BoardsSelect'
import TaskListKanban from '@/components/project/TaskListKanban'
import TaskListTable from '@/components/project/TaskListTable'
import TaskListAddFab from '@/components/project/TaskListAddFab'
import TaskListNewTask from '@/components/project/TaskListNewTask'
import TaskListNewBoardOrList from '@/components/project/TaskListNewBoardOrList'
import TaskListMoveOrCopyList from '@/components/project/TaskListMoveOrCopyList'
export default {
  name: 'Cards',
  components: {
    BaseIcon,
    TaskDialog,
    BoardsSelect,
    TaskListKanban,
    TaskListTable,
    TaskListAddFab,
    TaskListNewTask,
    TaskListNewBoardOrList,
    TaskListMoveOrCopyList
  },
  props: {
    archived: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'project',
      'isMobile',
      'projectLists',
      'teamAbilities',
      'projectBoards',
      'isMobileDevice',
      'projectStatuses',
      'projectAbilities',
      'abilitiesPriorityMap',
      'organizationSubdomain'
    ]),
    view() {
      return this.$route.params.view || 'kanban'
    },
    sortedBoards() {
      return this.projectBoards
        ? sortBy(Object.values(this.projectBoards), (board) => board.position)
        : []
    },
    routeName() {
      return this.$route.name
    },
    currentBoardId() {
      return this.currentItem.replace('tab-', '')
    },
    currentBoardLists() {
      return this.getBoardLists(this.currentBoardId)
    },
    currentBoardHasAtLeastOneList() {
      return (
        this.currentBoardLists.length > 0 &&
        this.createTaskVisible &&
        this.createListVisible
      )
    },
    createTaskVisible() {
      const priorities = Object.values(this.teamAbilities.lists)
      return priorities.some(
        (priority) => priority >= this.abilitiesPriorityMap.List.create_task
      )
    },
    createListVisible() {
      return (
        this.teamAbilities.boards[this.currentBoardId] >=
        this.abilitiesPriorityMap.Board.create_list
      )
    }
  },
  data() {
    return {
      boards: [],
      snackbar: false,
      currentItem: '',
      newTaskForm: {
        title: '',
        list_id: ''
      },
      taskUpdated: false,
      newTaskSheet: false,
      creationType: 'board',
      selectedBoardId: null,
      newBoardOrListName: '',
      creatingNewTask: false,
      listToBeArchived: null,
      taskToBeArchived: null,
      listTransferType: 'copy',
      listToBeMovedOrCopied: '',
      newBoardOrListSheet: false,
      movingOrCopyingList: false,
      moveOrCopyListDialog: false,
      creatingNewBoardOrList: false,
      selectedBoardsToMoveOrCopyList: [],
      views: ['kanban', 'table', 'timeline', 'gantt']
    }
  },
  methods: {
    ...mapActions([
      'addProjectTask',
      'hideTaskDialog',
      'addProjectList',
      'addProjectBoard',
      'moveProjectList',
      'copyProjectList',
      'moveProjectTask',
      'getTeamAbilities',
      'modifyProjectList',
      'updateTaskWithAlias',
      'changeProjectListStatus',
      'openTaskDialogInEditMode'
    ]),
    toggleLoading() {
      this.creatingNewBoardOrList = !this.creatingNewBoardOrList
    },
    handleBoardChange(view) {
      this.$router.push({ name: 'board', params: { view: view } })
    },
    getScollWrappper() {
      return document
        .getElementById(this.currentItem)
        .getElementsByClassName('card-wrapper')[0]
    },
    goToTheNewList() {
      const wrapper = this.getScollWrappper()
      wrapper.scrollTo(wrapper.scrollWidth, 0)
    },
    closeAddBoardOrSheet() {
      this.newBoardOrListSheet = false
      this.newBoardOrListName = ''
    },
    handleAddBoardSuccess() {
      const boards = Object.values(this.projectBoards)
      const newBoard = boards[boards.length - 1]
      this.currentItem = `tab-${newBoard.id}`
      this.closeAddBoardOrSheet()
    },
    async handleClickOnAddBoardDone() {
      const { id: pid } = this.project
      this.toggleLoading()
      try {
        await this.addProjectBoard({
          pid,
          data: {
            title: this.newBoardOrListName
          }
        })
        await this.getTeamAbilities(pid)
        this.handleAddBoardSuccess()
      } catch (error) {}
      this.toggleLoading()
    },
    handleAddListSuccess() {
      this.closeAddBoardOrSheet()
      this.$nextTick(this.goToTheNewList)
    },
    async handleBoardOrListCreation(request) {
      this.creatingNewBoardOrList = true
      try {
        request()
      } catch (error) {}
      this.creatingNewBoardOrList = false
    },
    async handleClickOnAddListDone() {
      const { id: pid } = this.project
      this.toggleLoading()
      try {
        await this.addProjectList({
          pid,
          data: {
            position: 'last',
            board_id: this.currentBoardId,
            title: this.newBoardOrListName
          }
        })
        await this.getTeamAbilities(pid)
        this.handleAddListSuccess()
      } catch (error) {}
      this.toggleLoading()
    },
    handleClickOnAddBoardOrListDone() {
      if (this.creationType === 'board') {
        return this.handleClickOnAddBoardDone()
      }
      return this.handleClickOnAddListDone()
    },
    async handleClickOnMoveOrCopyListRequest(req, data) {
      try {
        this.movingOrCopyingList = true
        await req({
          lid: this.listToBeMovedOrCopied.id,
          data
        })
        this.moveOrCopyListDialog = false
      } catch (error) {}
      this.movingOrCopyingList = false
    },
    handleClickOnMoveOrCopyListDone() {
      if (this.listTransferType === 'move') {
        return this.handleClickOnMoveOrCopyListRequest(this.moveProjectList, {
          board_id: this.selectedBoardsToMoveOrCopyList,
          position: 'last'
        })
      }
      this.handleClickOnMoveOrCopyListRequest(this.copyProjectList, {
        board_id: this.selectedBoardsToMoveOrCopyList
      })
    },
    changeListTitle(list) {
      const { title } = list
      this.modifyProjectList({
        data: { title },
        lid: list.id
      })
    },
    changeListWatchStatus(list) {
      const { watched } = list
      const path = watched ? 'unwatch' : 'watch'
      this.changeProjectListStatus({
        newValue: !watched,
        prop: 'watched',
        lid: list.id,
        path
      })
    },
    async changeListArchiveStatus(list) {
      const { archived } = list
      const path = archived ? 'unarchive' : 'archive'
      this.listToBeArchived = list
      this.snackbar = false
      try {
        await this.changeProjectListStatus({
          newValue: !archived,
          prop: 'archived',
          lid: list.id,
          path
        })
        this.taskUpdated = false
        this.snackbar = !archived
      } catch (error) {}
    },
    async changeTaskArchivedStatus(task) {
      const { archived } = task
      this.taskToBeArchived = task
      this.snackbar = false
      try {
        await this.updateTaskWithAlias({
          tid: task.id,
          keys: ['archived'],
          values: [!archived],
          path: archived ? 'unarchive' : 'archive'
        })
        this.taskUpdated = true
        this.snackbar = !archived
      } catch (error) {}
    },
    handleUndo() {
      if (this.taskUpdated) {
        return this.changeTaskArchivedStatus(this.taskToBeArchived)
      }
      return this.changeListArchiveStatus(this.listToBeArchived)
    },
    getFirstStatusId() {
      return this.projectStatuses ? Object.keys(this.projectStatuses)[0] : ''
    },
    openAddBoardOrListSheet(creationType) {
      this.creationType = creationType
      this.newBoardOrListSheet = true
    },
    openAddTaskSheet(list) {
      this.newTaskForm.status_id = this.getFirstStatusId()
      if (list) {
        this.newTaskForm.list_id = list.id
      } else {
        this.newTaskForm.list_id = ''
      }
      this.newTaskSheet = true
    },
    openMoveOrCopyListDialog(listTransferType, list) {
      this.listToBeMovedOrCopied = list
      this.listTransferType = listTransferType
      this.selectedBoardsToMoveOrCopyList = null
      this.moveOrCopyListDialog = true
    },
    getBoardLists(boardId) {
      const { projectLists } = this
      if (!projectLists) {
        return []
      }
      return Object.values(projectLists).filter(
        (list) => list.board.id === boardId && !list.archived
      )
    },
    openTaskDialog() {
      const { tid } = this.$route.params
      this.openTaskDialogInEditMode({
        tid,
        pid: this.project.id
      })
    },
    openDialogBasedOnRouteName() {
      if (
        this.routeName === 'task' ||
        this.routeName === 'checklists' ||
        this.routeName === 'attachments' ||
        this.routeName === 'comments' ||
        this.routeName === 'relations' ||
        this.routeName === 'tags' ||
        this.routeName === 'assignees'
      ) {
        this.openTaskDialog()
      }
    },
    closeDialogBasedOnRouteName() {
      if (this.routeName === 'board') {
        this.hideTaskDialog()
      }
    },
    handleTaskMove({ lid, sid, tid, position }) {
      this.moveProjectTask({
        tid,
        data: {
          position,
          list_id: lid,
          status_id: sid
        }
      })
    },
    scrollToCreatedTask(listId, statusId) {
      const list = document.getElementById(listId)
      const wrapper = this.getScollWrappper()
      wrapper.scrollTo(list.offsetLeft, 0)
      this.$nextTick(() => {
        const section = list.querySelectorAll(`[data-status="${statusId}"]`)[0]
        const sectionTasks = section.querySelectorAll('.task')
        const sectionLastTask = sectionTasks[sectionTasks.length - 1]
        if (!section || !sectionTasks) {
          return
        }
        const scrollTop = list.clientHeight - sectionLastTask.clientHeight
        const scrollWrapper = list.getElementsByClassName('list-cards')[0]
        scrollWrapper.scrollTo(0, scrollTop)
      })
    },
    resetNewTaskForm() {
      this.newTaskForm.title = ''
      this.newTaskForm.list_id = ''
    },
    handleClicOnAddTaskCancel() {
      this.newTaskSheet = false
      this.resetNewTaskForm()
    },
    async handleClickOnAddTaskDone() {
      this.creatingNewTask = true
      try {
        await this.addProjectTask({
          pid: this.project.id,
          data: this.newTaskForm
        })
        this.scrollToCreatedTask(
          `list_${this.newTaskForm.list_id}`,
          this.newTaskForm.status_id
        )
      } catch (error) {}
      this.creatingNewTask = false
      this.newTaskSheet = false
      this.resetNewTaskForm()
    }
  },
  mounted() {
    if (this.$route.meta.showDialog) {
      this.openDialogBasedOnRouteName()
    }
  },
  watch: {
    '$route.meta'({ showDialog }) {
      if (showDialog) {
        this.openDialogBasedOnRouteName()
      } else {
        this.closeDialogBasedOnRouteName()
      }
    }
  }
}
</script>
