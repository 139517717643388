import store from '@/store'

export const requestInterceptor = config => {
  store.dispatch('toggleLoading', true)
  config.url = config.url.replace('#oid', store.getters.organizationSubdomain)
  if (store.getters.isAuthenticated) {
    const { appKey, sessionId } = store.getters
    config.headers['User-Session-Key'] = appKey
    config.headers['User-Session-ID'] = sessionId
    config.params = { ...config.params, sessionId }
  }
  if (store.getters.user) {
    config.headers['Accept-Language'] = store.getters.user.language
  }

  return config
}

export const responseInterceptorSuccess = response => {
  store.dispatch('toggleLoading', false)
  return response
}

export const responseInterceptorError = error => {
  store.dispatch('toggleLoading', false)
  if (
    Object.prototype.hasOwnProperty.call(error.config, 'errorHandle') &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error)
  }
  store.dispatch('displayResponseError', error)
  return Promise.reject(error)
}
