export const SET_TEAM = 'SET_TEAM'
export const ADD_TEAM = 'ADD_TEAM'
export const SET_TASK = 'SET_TASK'
export const SET_USER = 'SET_USER'
export const SET_TEAMS = 'SET_TEAMS'
export const ADD_COMMENT = 'ADD_COMMENT'
export const SET_COMMENT = 'SET_COMMENT'
export const ADD_TIMELOG = 'ADD_TIMELOG'
export const SET_TIMELOG = 'SET_TIMELOG'
export const SET_LOADING = 'SET_LOADING'
export const SET_APP_KEY = 'SET_APP_KEY'
export const SET_PROJECT = 'SET_PROJECT'
export const DELETE_TEAM = 'DELETE_TEAM'
export const SET_PROJECTS = 'SET_PROJECTS'
export const SET_APP_NAME = 'SET_APP_NAME'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const SET_TIMELOGS = 'SET_TIMELOGS'
export const SET_MY_TASKS = 'SET_MY_TASKS'
export const ADD_MY_TASKS = 'ADD_MY_TASKS'
export const SET_COMMENTS = 'SET_COMMENTS'
export const ADD_COMMENTS = 'ADD_COMMENTS'
export const ADD_TIMELOGS = 'ADD_TIMELOGS'
export const SET_USER_LOCALE = 'SET_LOCALE'
export const SET_IS_MOBILE = 'SET_IS_MOBILE'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const SET_USER_VALUE = 'SET_USER_VALUE'
export const SET_USER_TEAMS = 'SET_USER_TEAMS'
export const SET_INVITATION = 'SET_INVITATION'
export const SET_SESSION_ID = 'SET_SESSION_ID'
export const SET_TASK_VALUE = 'SET_TASK_VALUE'
export const DELETE_TIMELOG = 'DELETE_TIMELOG'
export const SET_USER_AVATAR = 'SET_USER_AVATAR'
export const ADD_PROJECT_TAG = 'ADD_PROJECT_TAG'
export const SET_PROJECT_TAG = 'SET_PROJECT_TAG'
export const ADD_PROJECT_LIST = 'ADD_PROJECT_LIST'
export const ADD_TEAM_MEMBERS = 'ADD_TEAM_MEMBERS'
export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const ADD_PROJECT_TASK = 'ADD_PROJECT_TASK'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const SET_PROJECT_TAGS = 'SET_PROJECT_TAGS'
export const SET_PROJECT_OWNER = 'SET_PROJECT_OWNER'
export const SET_PROJECT_VALUE = 'SET_PROJECT_VALUE'
export const ADD_PROJECT_TASKS = 'ADD_PROJECT_TASKS'
export const ADD_PROJECT_BOARD = 'ADD_PROJECT_BOARD'
export const SET_TIMELOG_VALUE = 'SET_TIMELOG_VALUE'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_PROJECT_TASKS = 'SET_PROJECT_TASKS'
export const SET_USER_CALENDAR = 'SET_USER_CALENDAR'
export const SET_PROJECT_LABEL = 'SET_PROJECT_LABEL'
export const SET_USER_SESSIONS = 'SET_USER_SESSIONS'
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS'
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const SET_PROJECT_BOARD = 'SET_PROJECT_BOARD'
export const SET_PROJECT_LISTS = 'SET_PROJECT_LISTS'
export const SET_PROJECT_BOARDS = 'SET_PROJECT_BOARDS'
export const SET_USER_LANGUAGES = 'SET_USER_LANGUAGES'
export const ADD_TASK_CHECKLIST = 'ADD_TASK_CHECKLIST'
export const SET_MY_TASKS_GROUP = 'SET_MY_TASKS_GROUP'
export const SET_MY_TASKS_COUNT = 'SET_MY_TASKS_COUNT'
export const SET_PROJECT_STATUS = 'SET_PROJECT_STATUS'
export const ADD_PROJECT_STATUS = 'ADD_PROJECT_STATUS'
export const SET_PROJECT_LABELS = 'SET_PROJECT_LABELS'
export const SET_TIMELOGS_GROUP = 'SET_TIMELOGS_GROUP'
export const SET_TASK_CHECKLIST = 'SET_TASK_CHECKLIST'
export const SET_TEAM_ABILITIES = 'SET_TEAM_ABILITIES'
export const SET_USER_DIRECTION = 'SET_USER_DIRECTION'
export const DELETE_PROJECT_TAG = 'DELETE_PROJECT_TAG'
export const SET_ACTIVE_TIMELOGS = 'SET_ACTIVE_TIMELOGS'
export const SET_COMMENT_RECORDS = 'SET_COMMENT_RECORDS'
export const SET_MY_TASKS_SUBSET = 'SET_MY_TASKS_SUBSET'
export const DELETE_TEAM_MEMBERS = 'DELETE_TEAM_MEMBERS'
export const SET_RENDER_ARCHIVED = 'SET_RENDER_ARCHIVED'
export const DELETE_PROJECT_TASK = 'DELETE_PROJECT_TASK'
export const SET_TASK_ACTIVITIES = 'SET_TASK_ACTIVITIES'
export const ADD_TASK_ACTIVITIES = 'ADD_TASK_ACTIVITIES'
export const SET_PROJECT_MEMBERS = 'SET_PROJECT_MEMBERS'
export const ADD_TASK_ATTACHMENT = 'ADD_TASK_ATTACHMENT'
export const SET_PROJECT_FEATURE = 'SET_PROJECT_FEATURE'
export const SET_PROJECT_STATUSES = 'SET_PROJECT_STATUSES'
export const SET_INTERFACE_DIALOG = 'SET_INTERFACE_DIALOG'
export const DELETE_USER_SESSIONS = 'DELETE_USER_SESSIONS'
export const DELETE_PROJECT_BOARD = 'DELETE_PROJECT_BOARD'
export const SET_PROJECT_FEATURES = 'SET_PROJECT_FEATURES'
export const SET_INVITATION_TOKEN = 'SET_INVITATION_TOKEN'
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED'
export const SET_TASK_DIALOG_MODE = 'SET_TASK_DIALOG_MODE'
export const SET_TIMELOG_DURATION = 'SET_TIMELOG_DURATION'
export const SET_PROJECT_ABILITIES = 'SET_PROJECT_ABILITIES'
export const SET_ORGANIZATION_LOGO = 'SET_ORGANIZATION_LOGO'
export const DELETE_ACTIVE_TIMELOG = 'DELETE_ACTIVE_TIMELOG'
export const DELETE_TASK_CHECKLIST = 'DELETE_TASK_CHECKLIST'
export const SET_ALL_NOTIFICATIONS = 'SET_ALL_NOTIFICATIONS'
export const DELETE_PROJECT_STATUS = 'DELETE_PROJECT_STATUS'
export const DELETE_TASK_ATTACHMENT = 'DELETE_TASK_ATTACHMENT'
export const SET_PROJECT_LIST_BOARD = 'SET_PROJECT_LIST_BOARD'
export const SET_PROJECT_TASK_VALUE = 'SET_PROJECT_TASK_VALUE'
export const SET_ORGANIZATION_VALUE = 'SET_ORGANIZATION_VALUE'
export const SET_PROJECT_LIST_VALUE = 'SET_PROJECT_LIST_VALUE'
export const SET_MY_TASKS_TASK_VALUE = 'SET_MY_TASKS_TASK_VALUE'
export const SET_PROJECT_MEMBERSHIPS = 'SET_PROJECT_MEMBERSHIPS'
export const SET_PROJECT_INTEGRATION = 'SET_PROJECT_INTEGRATION'
export const ADD_TIMELOG_EXPORT_LINK = 'ADD_TIMELOG_EXPORT_LINK'
export const SET_TWO_FATOR_AUTH_INFO = 'SET_TWO_FATOR_AUTH_INFO'
export const ADD_TASK_CHECKLIST_ITEM = 'ADD_TASK_CHECKLIST_ITEM'
export const SET_USER_2FA_SETUP_INFO = 'SET_USER_2FA_SETUP_INFO'
export const SET_TASK_DIALOG_VISIBLE = 'SET_TASK_DIALOG_VISIBLE'
export const SET_TASK_CHECKLIST_ITEM = 'SET_TASK_CHECKLIST_ITEM'
export const ADD_PROJECT_MEMBERSHIPS = 'ADD_PROJECT_MEMBERSHIPS'
export const SET_PROJECT_TASKS_COUNT = 'SET_PROJECT_TASKS_COUNT'
export const SET_PROJECT_INTEGRATIONS = 'SET_PROJECT_INTEGRATIONS'
export const SET_ONBOARDING_QUASTIONS = 'SET_ONBOARDING_QUASTIONS'
export const SET_TASKS_SEARCH_RESULTS = 'SET_TASKS_SEARCH_RESULTS'
export const ADD_TASKS_SEARCH_RESULTS = 'ADD_TASKS_SEARCH_RESULTS'
export const SET_COMMENTS_OFFSET_TOKEN = 'SET_COMMENTS_OFFSET_TOKEN'
export const SET_PROJECT_TASK_POSITION = 'SET_PROJECT_TASK_POSITION'
export const SET_USER_2FA_BACKUP_CODES = 'SET_USER_2FA_BACKUP_CODES'
export const SET_TASK_ASSIGNABLE_USERS = 'SET_TASK_ASSIGNABLE_USERS'
export const SET_NOTIFICATIONS_FETCHED = 'SET_NOTIFICATIONS_FETCHED'
export const SET_USER_SECURITY_SETTINGS = 'SET_USER_SECURITY_SETTINGS'
export const SET_TASK_TIMELOGS_DURATION = 'SET_TASK_TIMELOGS_DURATION'
export const SET_ABILITIES_PRIORITY_MAP = 'SET_ABILITIES_PRIORITY_MAP'
export const DELETE_PROJECT_MEMBERSHIPS = 'DELETE_PROJECT_MEMBERSHIPS'
export const SET_ORGANIZATION_SUBDOMAIN = 'SET_ORGANIZATION_SUBDOMAIN'
export const SET_ORGANIZATION_ABILITIES = 'SET_ORGANIZATION_ABILITIES'
export const DELETE_TASK_CHECKLIST_ITEM = 'DELETE_TASK_CHECKLIST_ITEM'
export const SET_TRANSLATION_INITIALIZED = 'SET_TRANSLATION_INITIALIZED'
export const SET_GOOGLE_DRIVE_TOKEN_INFO = 'SET_GOOGLE_DRIVE_TOKEN_INFO'
export const ADD_TIMELOGS_SEARCH_RESULTS = 'ADD_TIMELOGS_SEARCH_RESULTS'
export const SET_TIMELOGS_SEARCH_RESULTS = 'SET_TIMELOGS_SEARCH_RESULTS'
export const SET_ORGANIZATION_PERMISSION = 'SET_ORGANIZATION_PERMISSION'
export const DELETE_ORGANIZATION_PROJECT = 'DELETE_ORGANIZATION_PROJECT'
export const ADD_ORGANIZATION_MEMBERSHIPS = 'ADD_ORGANIZATION_MEMBERSHIPS'
export const SET_ORGANIZATION_PERMISSIONS = 'SET_ORGANIZATION_PERMISSIONS'
export const SET_TEAM_PERMISSION_ABILITIES = 'SET_TEAM_PERMISSION_ABILITIES'
export const SET_GOOGLE_DRIVE_ACCESS_TOKEN = 'SET_GOOGLE_DRIVE_ACCESS_TOKEN'
export const SET_UNREAD_NOTIFICATION_COUNT = 'SET_UNREAD_NOTIFICATION_COUNT'
export const SET_GOOGLE_CALENDAR_TOKEN_INFO = 'SET_GOOGLE_CALENDAR_TOKEN_INFO'
export const SET_ORGANIZATION_PROJECT_VALUE = 'SET_ORGANIZATION_PROJECT_VALUE'
export const SET_USER_NOTIFICATION_SETTINGS = 'SET_USER_NOTIFICATION_SETTINGS'
export const DELETE_ORGANIZATION_MEMBERSHIPS = 'DELETE_ORGANIZATION_MEMBERSHIPS'
export const SET_VERIFICATION_EMAIL_COOLDOWN = 'SET_VERIFICATION_EMAIL_COOLDOWN'
export const SET_ORGANIZATION_MEMBERSHIP_USER = 'SET_ORGANIZATION_MEMBERSHIP_USER'
export const SET_GOOGLE_CALENDAR_ACCESS_TOKEN = 'SET_GOOGLE_CALENDAR_ACCESS_TOKEN'
export const SET_ONBOARDING_PROJECT_TEMPLATES = 'SET_ONBOARDING_PROJECT_TEMPLATES'
export const SET_CREATE_PROJECT_DIALOG_VISIBLE = 'SET_CREATE_PROJECT_DIALOG_VISIBLE'
export const SET_ONBOARDING_TEMPLATE_CATEGORIES = 'SET_ONBOARDING_TEMPLATE_CATEGORIES'
export const SET_USER_VERIFICATION_EMAIL_COOLDOWN = 'SET_USER_VERIFICATION_EMAIL_COOLDOWN'
export const SET_CREATE_ORGINIZATION_DIALOG_VISIBLE = 'SET_CREATE_ORGINIZATION_DIALOG_VISIBLE'
