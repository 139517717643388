<template>
  <span v-if="showRelative">
    {{ relativeTime }}
  </span>
  <span :style="`direction:${direction}`" v-else>
    <template v-if="locale === 'fa'">
      {{ displayDate | persianDigit }}
    </template>
    <template v-else>{{ displayDate }}</template>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'jalali-moment'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import fa from 'javascript-time-ago/locale/fa'
TimeAgo.addLocale(en)
TimeAgo.addLocale(fa)

export default {
  props: ['date', 'gFormat', 'jFormat', 'useRelativeTime'],
  computed: {
    ...mapGetters(['user', 'calendar', 'locale', 'direction']),
    jalaliFormat() {
      return this.jFormat || 'jYYYY/jMM/jDD hh:mm A'
    },
    gergorianFormat() {
      return this.gFormat || 'DD MMM. YYYY hh:mm A'
    },
    format() {
      return this.calendar === 'jalali'
        ? this.jalaliFormat
        : this.gergorianFormat
    },
    displayDate() {
      const { locale } = this
      const date = this.date ? moment(new Date(this.date * 1000)) : moment()
      return date.locale(locale).format(this.format)
    },
    showRelative() {
      const dayInSeconds = 60 * 60 * 24
      const now = new Date().getTime() / 1000
      const { useRelativeTime, date } = this
      const diff = now - date
      return useRelativeTime && diff <= dayInSeconds
    },
    relativeTime() {
      TimeAgo.setDefaultLocale(this.locale)
      const timeAgo = new TimeAgo()
      return timeAgo.format(new Date(this.date * 1000))
    }
  }
}
</script>
