<template>
  <div class="fill-height d-flex flex-column justify-content-start">
    <my-tasks-title class="pa-4 flex-grow-0" />
    <my-tasks-groups class="px-4 pb-2 flex-grow-0" :group.sync="group" />
    <my-tasks-subsets
      class="flex-grow-0"
      v-if="myTasksCount"
      :subset.sync="subset"
      :count="myTasksCount"
    />
    <my-tasks-table
      :records="records"
      :loading="sortLoading"
      @sort-changed="handleSortChange"
      class="flex-grow-1 secondary lighten-5"
      @task-clicked="openTaskDialogFromRow"
    />
    <base-loading-more v-if="loading" />
    <div v-intersect="onIntersect" />
    <task-dialog
      :go-home="true"
      @task-go-back-clicked="hideTaskDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TaskDialog from '@/components/task/TaskDialog'
import BaseLoadingMore from '@/components/BaseLoadingMore'
import MyTasksTitle from '@/components/myTasks/MyTasksTitle'
import MyTasksTable from '@/components/myTasks/MyTasksTable'
import MyTasksGroups from '@/components/myTasks/MyTasksGroups'
import MyTasksSubsets from '@/components/myTasks/MyTasksSubsets'
export default {
  components: {
    TaskDialog,
    MyTasksTitle,
    MyTasksTable,
    MyTasksGroups,
    MyTasksSubsets,
    BaseLoadingMore
  },
  data() {
    return {
      limit: 20,
      sortObj: {},
      group: 'all',
      loading: false,
      subset: 'today',
      sortLoading: false,
      isIntersecting: false
    }
  },
  computed: {
    ...mapGetters(['myTasks', 'myTasksGroup', 'myTasksSubset', 'myTasksCount']),
    currentSet() {
      const { myTasks } = this
      if (!myTasks[this.group]) {
        return null
      }
      return myTasks[this.group][this.subset]
    },
    offsetToken() {
      const { currentSet } = this
      if (!currentSet) {
        return
      }
      return currentSet.offset_token
    },
    records() {
      const { currentSet } = this
      if (!currentSet) {
        return null
      }
      return currentSet.records
    },
    params() {
      return {
        ...this.sortObj,
        limit: this.limit,
        group: this.group,
        subset: this.subset,
        offset_token: this.offsetToken,
        expand: ['tags', 'label', 'project', 'assignees', 'status']
      }
    }
  },
  methods: {
    ...mapActions([
      'getMyTasks',
      'addMyTasks',
      'hideTaskDialog',
      'setMyTasksGroup',
      'setMyTasksSubset',
      'getMyTasksTaskCount',
      'openTaskDialogInEditMode'
    ]),
    async getData() {
      try {
        await this.getMyTasks(this.params)
      } catch (error) {}
      this.sortLoading = false
    },
    async addData() {
      this.loading = true
      try {
        await this.addMyTasks(this.params)
      } catch (error) {}
      this.loading = false
    },
    handleSortChange(sortObj) {
      this.sortObj = sortObj
      this.sortLoading = true
      this.getData()
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting
    },
    getCount() {
      this.getMyTasksTaskCount({
        group: this.group
      })
    },
    closeDialogBasedOnRouteName() {
      this.hideTaskDialog()
    },
    openTaskDialogFromRow(task) {
      this.openTaskDialogInEditMode({ pid: task.project.id, tid: task.id })
    }
  },
  mounted() {
    this.getCount()
    this.getData()
  },
  watch: {
    subset(subset) {
      this.setMyTasksSubset(subset)
      if (!this.currentSet) {
        this.getData()
      }
    },
    group(group) {
      this.setMyTasksGroup(group)
      if (!this.currentSet) {
        this.getData()
      }
      this.getCount()
    },
    isIntersecting(newValue) {
      if (!newValue || !this.currentSet || !this.offsetToken || this.loading) {
        return
      }
      this.addData()
    }
  }
}
</script>
