<template>
  <v-menu
    bottom
    min-width="188"
    :right="$vuetify.rtl"
    :left="!$vuetify.rtl"
    transition="slide-x-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        icon
        plain
        v-on="on"
        class="me-1"
        v-bind="attrs"
      >
        <base-icon name="ellipsis-v" color="info.lighten1" />
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item @click="$emit('delete-clicked')">
        <v-list-item-content>
          <v-list-item-title class="body-1 error--text">
            {{ $t('common.delete_entry') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  components: {
    BaseIcon
  }
}
</script>
