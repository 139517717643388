<template>
  <v-card class="d-flex flex-column" height="100%">
    <v-card-text
      class="flex-grow-1 d-flex flex-column align-center justify-center centered-container"
    >
      <section class="text-center">
        <base-icon name="frown" color="error" width="54" height="54" />
        <h1
          class="mt-6 text-h1 info--text"
          v-text="
            $t('page.profile_setting.account.delete_account.success.title')
          "
        />
        <p
          class="body-1 mt-4 secondary--text text--darken-2"
          v-html="
            $t(
              'page.profile_setting.account.delete_account.success.description'
            )
          "
        />
      </section>
      <v-btn
        block
        depressed
        color="primary"
        @click="logout"
        class="flex-grow-0"
        v-text="$t('common.done')"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
export default {
  components: { BaseIcon },
  methods: {
    ...mapActions(['logout'])
  }
}
</script>
