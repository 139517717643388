<template>
  <v-list-item>
    <v-list-item-content class="py-0">
      <v-list-item-title class="d-flex align-center">
        <v-checkbox
          small
          hide-details
          :ripple="false"
          :disabled="!editable"
          v-model="option.completed"
          class="pt-0 mt-0 flex-grow-0"
          @change="
            update({
              prop: 'completed',
              data: {
                checklist_id: cid,
                completed: option.completed
              }
            })
          "
        >
        </v-checkbox>
        <v-form @submit.prevent="save" v-if="editing" class="flex-grow-1">
          <v-text-field
            flat
            solo
            dense
            autofocus
            full-width
            height="18"
            @blur="save"
            :value="text"
            hide-details
            class="silent body-2"
            @input="handleInputChange"
            background-color="secondary lighten-5"
          />
        </v-form>
        <span
          v-else
          :class="{
            'cursor pointer': editable,
            'text-decoration-line-through': option.completed
          }"
          @click="handleClick"
          v-text="option.title"
          class="body-2 secondary--text text--darken-2 flex-grow-1 text-wrap"
          />
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="deletable" class="my-0">
      <v-btn icon x-small @click="deleteItem">
        <base-icon
          width="14"
          height="14"
          name="times"
          color="error"
          className="flexbox align-center"
        />
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions } from 'vuex'
import BaseIcon from '../BaseIcon'
export default {
  components: { BaseIcon },
  props: {
    option: {
      type: Object,
      required: true
    },
    cid: {
      type: String,
      required: true
    },
    deletable: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: this.option.title,
      editing: false
    }
  },
  methods: {
    ...mapActions(['updateChecklistItem', 'deleteTaskChecklistItem']),
    handleInputChange(text) {
      this.text = text
    },
    async update({ prop, data }) {
      try {
        await this.updateChecklistItem({
          iid: this.option.id,
          cid: this.cid,
          prop,
          data
        })
      } catch (error) {}
    },
    deleteItem() {
      this.deleteTaskChecklistItem({
        cid: this.cid,
        iid: this.option.id
      })
    },
    save() {
      this.editing = false
      const { text } = this
      if (text === this.option.title) {
        return
      }
      if (text.length === 0) {
        return this.deleteItem()
      }
      this.update({
        prop: 'title',
        data: {
          checklist_id: this.cid,
          title: text
        }
      })
    },
    handleClick() {
      if (!this.editable) {
        return
      }
      this.editing = true
    }
  }
}
</script>
