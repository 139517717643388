var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-list-item',{on:{"click":_vm.openDialog}},[_c('v-list-item-icon',{staticClass:"me-2"},[_c('base-icon',{attrs:{"name":"trash-alt","color":"error"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 d-flex justify-space-between error--text",domProps:{"textContent":_vm._s(_vm.$t('page.profile_setting.account.delete_account.title'))}})],1)],1),_c('base-dialog',{attrs:{"card-color":"white","show-save-btn":true,"save-btn-text":_vm.$t('common.skip'),"dialog":_vm.transferOwnershipDialog,"title":_vm.$t(
        'page.profile_setting.account.delete_account.transfer_ownership.title'
      )},on:{"update:dialog":function($event){_vm.transferOwnershipDialog=$event},"save-clicked":function($event){_vm.confirmationDialog = true}}},[_c('section',[_c('p',{staticClass:"px-4 body-1",domProps:{"textContent":_vm._s(
          _vm.$t(
            'page.profile_setting.account.delete_account.transfer_ownership.description'
          )
        )}}),_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:(index + "-" + (item.id)),attrs:{"ripple":false},on:{"click":function($event){return _vm.handleClick(item.url)}}},[_c('v-list-item-avatar',{attrs:{"size":"32"}},[_c('base-avatar',{attrs:{"size":32,"title":item.title,"image":item.avatar ? item.avatar.medium : ''}})],1),_c('v-list-item-content',{staticClass:"info--text"},[_c('v-list-item-title',{staticClass:"body-1 d-flex justify-space-between",domProps:{"textContent":_vm._s(item.title)}}),(item.subdomain)?_c('v-list-item-subtitle',{staticClass:"body-2 d-flex justify-space-between",domProps:{"textContent":_vm._s(item.subdomain)}}):_vm._e()],1),_c('v-list-item-action',[_c('base-angle-icon',{staticClass:"flip-horizontal",attrs:{"width":"20","height":"20","fill":"secondary.darken2"}})],1)],1)}),1)],1)]),_c('base-dialog',{attrs:{"card-color":"white","loading":_vm.loading,"show-save-btn":true,"dialog":_vm.reasonDialog,"save-btn-text":_vm.$t('common.continue'),"disable-save-btn":_vm.reason === null || !_vm.formIsValid,"title":_vm.$t('page.profile_setting.account.delete_account.title')},on:{"update:dialog":function($event){_vm.reasonDialog=$event},"save-clicked":_vm.handleDelete}},[_c('section',{staticClass:"pa-4 body-1",domProps:{"innerHTML":_vm._s(
        _vm.$t(
          'page.profile_setting.account.delete_account.confirmation.description'
        )
      )}}),_c('section',{staticClass:"pa-4"},[_c('v-form',{model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('base-password',{attrs:{"value":_vm.password,"validate-on-blur":false,"rules":_vm.getPasswordRules(),"label":_vm.$t('form.labels.password')},on:{"update:value":function($event){_vm.password=$event}}})],1),_c('p',{staticClass:"body-1 info--text",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'page.profile_setting.account.delete_account.confirmation.reason.title'
          )
        )}}),_c('v-select',{staticClass:"body-1",attrs:{"dense":"","outlined":"","items":_vm.reasons},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t( ("page.profile_setting.account.delete_account.confirmation.reason.options." + item) ))+" ")]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t( ("page.profile_setting.account.delete_account.confirmation.reason.options." + item) ))+" ")]}}]),model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.textAreaVisible),expression:"textAreaVisible"}],attrs:{"dense":"","outlined":"","label":_vm.$t('form.labels.description')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]),_c('base-confirm-dialog',{attrs:{"ok-color":"error base","cancel-color":"secondary darken-2","dialog":_vm.confirmationDialog,"cancel-text":_vm.$t('common.cancel'),"ok-text":_vm.$t(
        'page.profile_setting.account.delete_account.transfer_ownership.warning.continue'
      ),"title":_vm.$t(
        'page.profile_setting.account.delete_account.transfer_ownership.warning.title'
      ),"content":_vm.$t(
        'page.profile_setting.account.delete_account.transfer_ownership.warning.description'
      )},on:{"ok-clicked":_vm.openReasonDialog,"update:dialog":function($event){_vm.confirmationDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }