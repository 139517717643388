var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{staticClass:"fill-width mt-7",attrs:{"value":_vm.formIsValid},on:{"input":function($event){return _vm.$emit('update:formIsValid', $event)}}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","value":_vm.email,"rules":_vm.rules.email,"disabled":_vm.hasUserInfo,"label":_vm.$t('form.labels.email')},on:{"input":function($event){return _vm.$emit('update:email', $event)}}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","value":_vm.username,"rules":_vm.rules.username,"label":_vm.$t('form.labels.username')},on:{"input":function($event){return _vm.$emit('update:username', $event)}}}),_c('v-text-field',{attrs:{"flat":"","dense":"","outlined":"","value":_vm.name,"rules":_vm.rules.fullname,"label":_vm.$t('form.labels.full_name')},on:{"input":function($event){return _vm.$emit('update:name', $event)}}}),_c('base-password',{attrs:{"value":_vm.password,"rules":_vm.rules.password,"validate-on-blur":false,"label":_vm.$t('form.labels.password')},on:{"password-updated":function($event){return _vm.$emit('update:password', $event)}}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"dense":"","hide-details":"","value":_vm.receiveNews,"label":_vm.$t('form.labels.sign_up_for_app_news_and_updates', {
        appName: 'Taskulu'
      })},on:{"change":function($event){return _vm.$emit('update:receiveNews', $event)}}}),_c('p',{staticClass:"mt-4 caption secondary--text",domProps:{"innerHTML":_vm._s(
      _vm.$t('page.sign_up.tos', {
        privacyLink: _vm.privacyLink,
        termsLink: _vm.termsLink
      })
    )}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }