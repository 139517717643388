<template>
  <div>
    <div class="boards-select" v-if="isMobile">
      <v-select
        dense
        rounded
        outlined
        hide-details
        :items="boards"
        style="width:188px"
        v-model="selectedBoard"
        @change="$emit('board-changed', selectedBoard)"
      >
        <template v-slot:selection="{ item }">
          <boards-select-item :item="item" :name="selectedBoardIcon" />
        </template>
        <template v-slot:item="{ item }">
          <boards-select-item :item="item" :name="getIcon(item)" />
        </template>
      </v-select>
    </div>
    <div v-else>
      <v-btn-toggle
        v-model="selectedBoard"
        @change="$emit('board-changed', selectedBoard)"
        rounded
        mandatory
        color="primary"
        background-color="white"
      >
        <boards-select-btn-toggle-item
          v-for="item in boards"
          :key="item"
          :item="item"
          :name="getIcon(item)"
          :is-active="item === selectedBoard"
        />
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
import BoardsSelectItem from '@/components/project/BoardsSelectItem'
import BoardsSelectBtnToggleItem from '@/components/project/BoardsSelectBtnToggleItem'
import { mapGetters } from 'vuex'
export default {
  props: {
    board: {
      type: String,
      required: true
    },
    boards: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ]),
    selectedBoardIcon() {
      return this.getIcon(this.board)
    }
  },
  data() {
    return {
      icons: ['columns', 'rope-way', 'window-grid', 'horizontal-align-left'],
      selectedBoard: this.board
    }
  },
  methods: {
    getIcon(item) {
      const index = this.boards.indexOf(item)
      return this.icons[index]
    }
  },
  watch: {
    board(newValue) {
      this.selectedBoard = newValue
    }
  },
  components: {
    BoardsSelectItem,
    BoardsSelectBtnToggleItem
  }
}
</script>
