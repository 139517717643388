<template>
  <fragment>
    <task-list v-if="project && tasks" />
    <base-loading v-else />
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseLoading from '@/components/BaseLoading'
import TaskList from '@/components/project/TaskList'
export default {
  computed: {
    ...mapGetters([
      'user',
      'tasks',
      'project',
      'projectTags',
      'projectLists',
      'projectBoards',
      'projectLabels',
      'projectStatuses',
      'projectFeatures',
      'projectMemberships',
      'organizationSubdomain'
    ]),
    pid() {
      return this.$route.params.pid
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions([
      'logout',
      'getProject',
      'setProject',
      'getProjectTags',
      'getProjectLists',
      'getProjectTasks',
      'getProjectLabels',
      'getProjectBoards',
      'getTeamAbilities',
      'getProjectFeatures',
      'getProjectStatuses',
      'getProjectAbilities',
      'getProjectMemberships'
    ]),
    async fetchData() {
      if (
        this.tasks &&
        this.project &&
        this.projectTags &&
        this.projectLists &&
        this.projectBoards &&
        this.projectStatuses &&
        this.getProjectLabels &&
        this.projectMemberships &&
        this.project.id === this.pid
      ) {
        return
      }
      this.setProject(null)
      Promise.all([
        this.getProjectMemberships(this.pid),
        this.getProjectAbilities(this.pid),
        this.getProjectFeatures(this.pid),
        this.getProjectStatuses(this.pid),
        this.getTeamAbilities(this.pid),
        this.getProjectLabels(this.pid),
        this.getProjectBoards(this.pid),
        this.getProjectLists(this.pid),
        this.getProjectTags(this.pid),
        this.getProjectTasks(this.pid)
      ]).then(() => {
        this.getProject(this.pid)
      })
    }
  },
  watch: {
    pid() {
      this.fetchData()
    }
  },
  components: {
    BaseLoading,
    TaskList
  }
}
</script>
