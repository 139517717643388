<template>
  <section class="px-4" style="min-height: 200px">
    <base-avatar-image-uploader
      :src="imageSrc"
      v-if="organization"
      :title="organization.title"
      @image-uploaded="attachLogo"
      :max-file-size="maxFileSize"
      @remove-photo-clicked="removePhoto"
      @image-src-updated="imageSrc = $event"
    />
    <base-inline-input
      :loading="loading"
      @save-clicked="save"
      :fixed-actions="true"
      v-if="organization && dialog"
      :initial-value="organization.title"
      :title="$t('form.labels.organization_name')"
      @cancel-clicked="$emit('update:dialog', false)"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { convertMegabytesToBytes } from '@/utils/helpers'
import BaseInlineInput from '@/components/BaseInlineInput'
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  name: 'OrganizationSettingsListInformationsSettings',
  computed: {
    ...mapGetters(['organization'])
  },
  data() {
    return {
      imageSrc: '',
      loading: false,
      maxFileSize: convertMegabytesToBytes(5)
    }
  },
  methods: {
    ...mapActions([
      'getOrganizations',
      'updateOrganization',
      'removeOrganizationLogo',
      'replaceOrganizationLogo'
    ]),
    getData() {
      this.getOrganizations({
        include: ['projects']
      })
    },
    async attachLogo(fileId) {
      try {
        await this.replaceOrganizationLogo({
          file_id: fileId
        })
        this.getData()
      } catch (error) {}
    },
    async removePhoto() {
      this.imageSrc = ''
      try {
        await this.removeOrganizationLogo()
        this.getData()
      } catch (error) {}
    },
    async save(title) {
      this.loading = true
      try {
        await this.updateOrganization({
          data: { title },
          key: 'title'
        })
        this.getData()
      } catch (error) {}
      this.loading = false
    },
    setAvatarImage() {
      const { organization } = this
      const { logo } = organization
      this.imageSrc = logo ? logo.medium : ''
    }
  },
  mounted() {
    this.setAvatarImage()
  },
  components: {
    BaseInlineInput
  }
}
</script>
