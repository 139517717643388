import {
  SET_ONBOARDING_QUASTIONS,
  SET_ONBOARDING_PROJECT_TEMPLATES,
  SET_ONBOARDING_TEMPLATE_CATEGORIES
} from '../mutation-types'

import {
  fetchTemplateCategories,
  fetchOnboardingQuestions,
  patchUnboardingQuestions,
  fetchOnboardingProjectTemplates
} from '@/api/onboarding'

const state = {
  onboardingQuestions: null,
  onboardingProjectTemplates: null,
  onboardingTemplateCategories: null
}

const mutations = {
  [SET_ONBOARDING_QUASTIONS](state, onboardingQuestions) {
    state.onboardingQuestions = onboardingQuestions
  },
  [SET_ONBOARDING_TEMPLATE_CATEGORIES](state, onboardingTemplateCategories) {
    state.onboardingTemplateCategories = onboardingTemplateCategories
  },
  [SET_ONBOARDING_PROJECT_TEMPLATES](state, onboardingProjectTemplates) {
    state.onboardingProjectTemplates = onboardingProjectTemplates
  }
}

const actions = {
  async getOnboardingQuestions({ commit }) {
    commit(SET_ONBOARDING_QUASTIONS, null)
    const { data } = await fetchOnboardingQuestions()
    commit(SET_ONBOARDING_QUASTIONS, data.data)
  },
  async getOnboardingTemplateCategories({ commit }) {
    commit(SET_ONBOARDING_TEMPLATE_CATEGORIES, null)
    const { data } = await fetchTemplateCategories()
    commit(SET_ONBOARDING_TEMPLATE_CATEGORIES, data.data)
  },
  async getOnboardingProjectTemplates({ commit }, { params }) {
    commit(SET_ONBOARDING_PROJECT_TEMPLATES, null)
    const { data } = await fetchOnboardingProjectTemplates(params)
    commit(SET_ONBOARDING_PROJECT_TEMPLATES, data.data)
  },
  async modifyOnboardingQuestions({ commit }, data) {
    await patchUnboardingQuestions(data)
    commit(SET_ONBOARDING_QUASTIONS, data.data)
  }
}

const getters = {
  onboardingQuestions: state => state.onboardingQuestions,
  onboardingProjectTemplates: state => state.onboardingProjectTemplates,
  onboardingTemplateCategories: state => state.onboardingTemplateCategories
}

export default {
  mutations,
  actions,
  getters,
  state
}
