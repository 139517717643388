<template>
  <base-dialog
    :dialog="dialog"
    :title="$t(title)"
    card-color="white"
    card-text-class="d-flex flex-column"
    @go-back-clicked="$emit('update:dialog', false)"
  >
    <section class="d-flex flex-column align-center justify-center flex-grow-1 px-4">
      <sessions-account-verification-form
        v-if="dialog"
        :handle="handle"
        :show-btn="false"
        :loading="loading"
        :show-subtitle="false"
        @resend-clicked="handleResend"
        :resend-loading="resendLoading"
        @verify-clicked="code = $event"
        :verification-email-cooldown="userVerificationEmailCooldown"
      />
    </section>
    <template slot:cardActions>
      <div class="flex-grow-0 pa-4">
        <v-btn
          block
          depressed
          color="primary"
          :loading="loading"
          v-text="$t(btnText)"
          :disabled="code.length < 7 || loading"
        />
      </div>
    </template>
  </base-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import SessionsAccountVerificationForm from '@/components/sessions/SessionsAccountVerificationForm'

export default {
  components: { BaseDialog, SessionsAccountVerificationForm },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'page.account_verification.title'
    },
    token: {
      type: String,
      default: ''
    },
    handle: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: 'common.done'
    }
  },
  computed: {
    ...mapGetters(['userVerificationEmailCooldown'])
  },
  data() {
    return {
      code: '',
      loading: false,
      resendLoading: false,
      form: {
        token: this.token,
        code: ''
      }
    }
  },
  methods: {
    ...mapActions(['verifyUserEmail', 'resendVerificationEmail']),
    async handleResend() {
      this.resendLoading = true
      try {
        await this.resendVerificationEmail({
          token: this.token
        })
      } catch (error) {}
      this.resendLoading = false
    },
    async handleVerify() {
      this.loading = true
      try {
        await this.verifyUserEmail({
          data: {
            code: this.code,
            token: this.token
          },
          key: 'email',
          value: this.handle
        })
        this.$emit('update:dialog', false)
        this.$emit('email-changed')
      } catch (error) {}
      this.loading = false
    }
  },
  watch: {
    code() {
      this.handleVerify()
    }
  }
}
</script>
