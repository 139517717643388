import request from '@/api/axios'
export const fetchProjects = params =>
  request({
    url: '/orgs/#oid/projects',
    method: 'get',
    params
  })

export const fetchProject = (pid, params = {}) =>
  request({
    url: `/orgs/#oid/projects/${pid}`,
    method: 'get',
    params
  })

export const fetchProjectAbilities = (pid) =>
  request({
    url: `/orgs/#oid/projects/${pid}/abilities`,
    method: 'get'
  })

export const fetchProjectStats = pid =>
  request({
    url: `/orgs/#oid/projects/${pid}/stats`,
    method: 'get'
  })

export const fetchProjectIntegrations = pid =>
  request({
    url: `/orgs/#oid/projects/${pid}/integrations`,
    method: 'get'
  })

export const createProjectIntegrationStatus = (pid, id, status = 'enable') =>
  request({
    url: `/orgs/#oid/projects/${pid}/integrations/${id}/${status}`,
    method: 'post'
  })

export const createProjectTransfer = (pid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}/transfer`,
    method: 'post',
    data
  })

export const createProjectAdminship = pid =>
  request({
    url: `/orgs/#oid/projects/${pid}/join`,
    method: 'post'
  })

export const createProjectLeave = pid =>
  request({
    url: `/orgs/#oid/projects/${pid}/leave`,
    method: 'post'
  })

export const fetchProjectFeatures = pid =>
  request({
    url: `/orgs/#oid/projects/${pid}/features`,
    method: 'get'
  })

export const patchProject = (pid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}`,
    method: 'patch',
    data
  })

export const patchProjectFeature = (pid, fid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}/features/${fid}`,
    method: 'patch',
    data
  })

export const fetchProjectTasks = (pid, params = {}) =>
  request({
    url: `/orgs/#oid/projects/${pid}/tasks`,
    method: 'get',
    params
  })

export const createProject = (data = {}) =>
  request({
    url: '/orgs/#oid/projects',
    method: 'post',
    data
  })

export const createProjectByTemplate = (data = {}) =>
  request({
    url: '/orgs/#oid/projects/by-template',
    method: 'post',
    data
  })

export const createProjectMembers = (pid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}/project-memberships`,
    method: 'post',
    data
  })

export const deleteProjectMembers = (pid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}/project-memberships`,
    method: 'delete',
    data
  })

export const createProjectExport = pid =>
  request({
    url: `/orgs/#oid/projects/${pid}/export`,
    method: 'post'
  })

export const createProjectMembershipInvitation = mid =>
  request({
    url: `/orgs/#oid/project-memberships/${mid}/invitation/resend`,
    method: 'post'
  })

export const createProjectClosure = pid =>
  request({
    url: `/orgs/#oid/projects/${pid}/close`,
    method: 'post'
  })

export const createProjectRestorement = pid =>
  request({
    url: `/orgs/#oid/projects/${pid}/reopen`,
    method: 'post'
  })

export const deleteProject = (pid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}`,
    method: 'delete',
    data
  })
