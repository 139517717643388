<template>
  <fragment>
    <v-overlay color="#fff" opacity="0.92" :value="fab" :z-index="5" />
    <v-speed-dial
      fixed
      v-model="fab"
      :bottom="true"
      direction="top"
      style="z-index: 6"
      :left="$vuetify.rtl"
      :right="!$vuetify.rtl"
      :open-on-hover="false"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
          fab
          dark
          width="58"
          height="58"
          v-model="fab"
          :color="fab ? 'white' : 'primary'"
        >
          <base-icon
            v-if="fab"
            width="24"
            height="24"
            name="times"
            color="secondary.darken2"
          />
          <unicon v-else name="plus" fill="#ffffff" />
        </v-btn>
      </template>
      <div
        v-if="createTaskVisible"
        class="fab-with-content"
        @click="$emit('new-task-clicked')"
        :class="$vuetify.rtl ? 'right' : 'left'"
      >
        <v-btn
          fab
          dark
          small
          width="40"
          height="40"
          color="white"
        >
          <base-icon name="file-check-alt" color="primary" />
        </v-btn>
        <div class="text info--text text-button pointer cursor">
          {{ $t('common.new_task') }}
        </div>
      </div>
      <div
        v-if="createListVisible"
        class="fab-with-content"
        @click="$emit('new-list-clicked')"
        :class="$vuetify.rtl ? 'right' : 'left'"
      >
        <v-btn fab dark small width="40" height="40" color="white">
          <base-icon name="columns" color="primary" />
        </v-btn>
        <div class="text info--text text-button pointer cursor">
          {{ $t('common.new_list') }}
        </div>
      </div>
    </v-speed-dial>
  </fragment>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  props: {
    createListVisible: {
      type: Boolean,
      default: true
    },
    createTaskVisible: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    fab: null
  }),
  components: {
    BaseIcon
  }
}
</script>
