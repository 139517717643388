<template>
  <component :is="layout">
    <router-view v-if="translationInitialized" />
  </component>
</template>
<script>
import { mapGetters } from 'vuex'
const defaultLayout = 'plain'
export default {
  name: 'App',
  computed: {
    ...mapGetters(['translationInitialized']),
    layout() {
      return `${this.$route.meta.layout || defaultLayout}-layout`
    }
  }
}
</script>

<style lang="scss">
@import './styles/app';
</style>
