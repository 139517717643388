import request from '@/api/axios'

export const fetchComments = (pid, params) =>
  request({
    url: `/orgs/#oid/projects/${pid}/comments`,
    method: 'get',
    params
  })

export const createComment = (pid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}/comments`,
    method: 'post',
    data
  })

export const patchComment = (cid, data) =>
  request({
    url: `/orgs/#oid/comments/${cid}`,
    method: 'patch',
    data
  })

export const deleteComment = (cid) =>
  request({
    url: `/orgs/#oid/comments/${cid}`,
    method: 'delete'
  })
