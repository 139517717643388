<template>
  <v-list>
    <v-list-item
      :key="item.name"
      v-for="item in items"
      @click="item.dialogVisible = true"
    >
      <v-list-item-action class="me-4">
        <base-icon
          width="24"
          height="24"
          color="primary"
          :name="item.icon"
          className="d-flex align-center"
        />
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title
          :class="item.textColorClass"
          class="body-2 info--text d-flex justify-space-between"
        >
          <span>{{ $t(`page.profile_setting.${item.name}.title`) }}</span>
          <span v-if="item.subtitle" class="secondary--text text--darken-2">
            {{ item.subtitle }}
          </span>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon>
        <base-angle-icon
          width="24"
          height="24"
          fill="secondary.base"
          class="flip-horizontal"
        />
      </v-list-item-icon>
      <base-dialog
        :extended="item.extended"
        :card-color="item.cardColor"
        :dialog.sync="item.dialogVisible"
        @save-clicked="item.dialogVisible = false"
        :title="$t(`page.profile_setting.${item.name}.title`)"
        :content-class="`large-level-two ${item.contentClass}`"
      >
        <template v-slot:toolbarExtention>
          <profile-overview-list-security-toolbar-extension
            :tab.sync="item.tab"
          />
        </template>
        <component
          :is="`ProfileOverviewList${item.component}`"
          :tab="item.tab"
        />
      </base-dialog>
    </v-list-item>
    <v-divider class="my-4 secondary lighten-2" />
    <v-list-item @click="dialog = true">
      <v-list-item-action class="me-4">
        <base-icon
          width="24"
          height="24"
          color="error"
          name="signout"
          className="d-flex align-center"
        />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title
          class="body-2 d-flex justify-space-between error--text"
        >
          <span>{{ $t('page.profile_setting.sign_out.title') }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <base-confirm-dialog
      :loading="loading"
      :dialog.sync="dialog"
      ok-color="error base"
      @ok-clicked="deleteSession"
      :ok-text="$t('common.yes')"
      :cancel-text="$t('common.no')"
      cancel-color="secondary darken-2"
      :title="$t('page.profile_setting.sign_out.title')"
      :content="$t('page.profile_setting.sign_out.confirmation')"
    />
  </v-list>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'
import ProfileOverviewListAccount from '@/components/user/ProfileOverviewListAccount'
import ProfileOverviewListSecurity from '@/components/user/ProfileOverviewListSecurity'
import ProfileOverviewListNotificationSettings from '@/components/user/ProfileOverviewListNotificationSettings'
import ProfileOverviewListSecurityToolbarExtension from '@/components/user/ProfileOverviewListSecurityToolbarExtension'
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseAngleIcon,
    BaseConfirmDialog,
    ProfileOverviewListAccount,
    ProfileOverviewListSecurity,
    ProfileOverviewListNotificationSettings,
    ProfileOverviewListSecurityToolbarExtension
  },
  computed: {
    ...mapGetters(['sessionId'])
  },
  data() {
    return {
      dialog: false,
      loading: false,
      items: [
        {
          contentClass: '',
          name: 'account',
          icon: 'user-exclamation',
          textColorClass: 'info--text',
          iconColor: 'primary',
          cardColor: 'white',
          dialogVisible: false,
          hasSaveButton: true,
          extended: false,
          tab: null,
          component: 'Account',
          action: () => {}
        },
        {
          contentClass: '',
          name: 'security',
          icon: 'lock-alt',
          textColorClass: 'info--text',
          iconColor: 'primary',
          cardColor: 'secondary lighten-5',
          dialogVisible: false,
          hasSaveButton: false,
          tab: null,
          component: 'Security',
          extended: true,
          action: () => {}
        },
        {
          contentClass: 'half-dialog',
          name: 'notifications',
          icon: 'bell',
          textColorClass: 'info--text',
          iconColor: 'primary',
          cardColor: 'white',
          dialogVisible: false,
          hasSaveButton: false,
          tab: null,
          component: 'NotificationSettings',
          extended: false,
          action: () => {}
        }
      ]
    }
  },
  methods: {
    ...mapActions(['logout', 'deleteUserSessions']),
    async deleteSession() {
      this.loading = true
      try {
        await this.deleteUserSessions({
          ids: [this.sessionId]
        })
        this.dialog = false
        this.logout()
      } catch (error) {}
      this.loading = false
    }
  }
}
</script>
