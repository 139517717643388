<template>
  <v-select
    :disabled="item.parent_question_id && !this.parentAnswer"
    :label="item.placeholder"
    @change="handleChange"
    item-text="title"
    :items="options"
    item-value="id"
    :value="answer"
    class="body-2"
    hide-details
    outlined
    dense
    solo
    flat
  />
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    answer: {
      required: true
    },
    answerTitle: {
      type: String,
      required: true
    },
    parentAnswer: {
      required: true
    }
  },
  data() {
    return {
      value: null
    }
  },
  computed: {
    options() {
      const { answers } = this.item
      if (this.item.parent_question_id === null) {
        return answers[0].options
      }
      const group = answers.find(
        answerGroup => answerGroup.parent_answer_id === this.parentAnswer
      )
      return group ? group.options : []
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('update:answerTitle', this.options.find(option => option.id === value).title)
      this.$emit('update:answer', value)
      this.$emit('answer-selected')
    }
  },
  mounted() {
    if (!this.answer) {
      return
    }
    this.handleChange(this.answer)
  },
  watch: {
    parentAnswer(newAnswer, oldAnswer) {
      if (!oldAnswer || newAnswer === oldAnswer) {
        return
      }
      this.$emit('update:answerTitle', '')
      this.$emit('update:answer', null)
      this.$emit('answer-selected')
    }
  }
}
</script>
