<template>
  <v-form class="flex-grow-1 justify-start flex-column fill-width mt-6">
    <section>
      <template v-for="(item, index) in items">
        <h4
          class="body-1 info--text px-0 mb-2"
          :class="{ 'mt-4': index > 0 }"
          :key="`label_${item.id}`"
          v-text="item.title"
        />
        <onboarding-question-steps-step-question
          :item="item"
          :key="`select_${item.id}`"
          :answer.sync="item.answer_id"
          :answerTitle.sync="item.answer_title"
          @answer-selected="$emit('answer-selected')"
          :parent-answer="
            item.parent_question_id
              ? getParentAnswer(item.parent_question_id)
              : null
          "
        />
      </template>
    </section>
  </v-form>
</template>

<script>
import OnboardingQuestionStepsStepQuestion from '@/components/onboarding/OnboardingQuestionStepsStepQuestion'
export default {
  components: { OnboardingQuestionStepsStepQuestion },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    getParentAnswer(parentId) {
      const parent = this.items.find(item => item.id === parentId)
      return parent ? parent.answer_id : null
    }
  }
}
</script>
