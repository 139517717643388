const maxPasswordLength = 500
const maxFullNameLength = 100
const maxSubdomainLength = 63
const maxUsernameLength = 22
const minPasswordLength = 10
const minSubdomainLength = 3
const minFullNameLength = 1
const minUsernameLength = 4
export const mobilePattern = /^\+\d{1,3}\d{9,10}$/
export const emailPattern = /[^@\s]+@([^@\s]+\.)+[^@\W]+/
const urlPattern = /(https?:\/\/[\S]+)|(ftp:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/
export default {
  methods: {
    getEmptyFieldError(field) {
      return this.$i18n.t('form.errors.field_is_empty', {
        field: this.$i18n.t(`form.labels.${field}`)
      })
    },
    getInvalidInputError(input) {
      return this.$i18n.t('form.errors.input_is_invalid', {
        input: this.$i18n.t(`form.labels.${input}`)
      })
    },
    getShortInputError(input, c) {
      return this.$i18n.t('form.errors.input_is_too_short', {
        input: this.$i18n.t(`form.labels.${input}`),
        c: this.getLocalizedNumber(c)
      })
    },
    getLongInputError(input, c) {
      return this.$i18n.t('form.errors.input_is_too_long', {
        input: this.$i18n.t(`form.labels.${input}`),
        c: this.getLocalizedNumber(c)
      })
    },
    getEmailRules() {
      return [
        v => !!v || this.getEmptyFieldError('email'),
        v => emailPattern.test(v) || this.getInvalidInputError('email')
      ]
    },
    getLinkUrlRules() {
      return [
        v => !!v || this.getEmptyFieldError('link_url'),
        v => urlPattern.test(v) || this.getInvalidInputError('link_url')
      ]
    },
    getEnteredEmailRules() {
      return [
        v => {
          if (!v) {
            return true
          }
          return emailPattern.test(v) || this.getInvalidInputError('email')
        }
      ]
    },
    getMobileRules() {
      return [
        v => !!v || this.getEmptyFieldError('mobile'),
        v =>
          mobilePattern.test(v) || this.getInvalidInputError('mobile')
      ]
    },
    getGoogleAuthCodeRules() {
      return [v => !!v || this.getEmptyFieldError('verification_code')]
    },
    getUsernameRules() {
      return [
        v => !!v || this.getEmptyFieldError('username'),
        v =>
          /^[a-zA-Z]/.test(v) ||
          this.$i18n.t('form.errors.input_not_started_with_letter', {
            input: this.$i18n.t('form.labels.username')
          }),
        v =>
          /^\w+$/.test(v) ||
          this.$i18n.t('form.errors.input_contains_invalid_characters'),
        v =>
          v.length >= minUsernameLength ||
          this.getShortInputError('username', minUsernameLength),
        v =>
          v.length <= maxUsernameLength ||
          this.getLongInputError('username', maxUsernameLength)
      ]
    },
    getSubdomainRules() {
      return [
        v => !!v || this.getEmptyFieldError('subdomain'),
        v =>
          /^[a-zA-Z]/.test(v) ||
          this.$i18n.t('form.errors.input_not_started_with_letter', {
            input: this.$i18n.t('form.labels.subdomain')
          }),
        v =>
          /^[a-z0-9-]+$/.test(v) ||
          this.$i18n.t('form.errors.subdomain_contains_invalid_characters'),
        v =>
          v.length >= minUsernameLength ||
          this.getShortInputError('subdomain', minSubdomainLength),
        v =>
          v.length <= maxUsernameLength ||
          this.getLongInputError('subdomain', maxSubdomainLength)
      ]
    },
    getCompanyNameRules() {
      return [v => !!v || this.getEmptyFieldError('company_name')]
    },
    getUsernameSimpleRules() {
      return [v => !!v || this.getEmptyFieldError('username')]
    },
    getPasswordRules() {
      return [
        v => !!v || this.getEmptyFieldError('password'),
        v =>
          v.length >= minPasswordLength ||
          this.getShortInputError('password', minPasswordLength),
        v =>
          v.length <= maxPasswordLength ||
          this.getLongInputError('password', maxPasswordLength)
      ]
    },
    getFullNameRules() {
      return [
        v => !!v || this.getEmptyFieldError('full_name'),
        v =>
          v.length >= minFullNameLength ||
          this.getShortInputError('full_name', minFullNameLength),
        v =>
          v.length <= maxFullNameLength ||
          this.getLongInputError('full_name', maxFullNameLength)
      ]
    },
    getProjectNameRules() {
      return [
        v => !!v || this.getEmptyFieldError('project_name')
      ]
    },
    getEmptyFieldRules(field) {
      return [
        v => !!v || this.getEmptyFieldError(field)
      ]
    }
  }
}
