<template>
  <div>
    <v-form
      v-model="formIsValid"
      ref="newOrganizationForm"
      class="flex-grow-1 d-flex justify-center flex-column fill-width"
    >
      <section>
        <base-avatar-image-uploader
          :src="imageSrc"
          :max-file-size="maxFileSize"
          @remove-photo-clicked="removePhoto"
          @image-src-updated="imageSrc = $event"
          @image-uploaded="form.logo_file_id = $event"
          @image-upload-failed="form.logo_file_id = null"
        />
        <v-text-field
          flat
          dense
          outlined
          :rules="rules.subdomain"
          v-model="form.subdomain"
          :label="$t('form.labels.your_subdomain')"
        >
          <template v-slot:append>
            <span class="body-1 secondary--text text--darken-2"
              >.taskulu.com</span
            >
          </template>
        </v-text-field>
        <v-text-field
          flat
          dense
          outlined
          v-model="form.title"
          :rules="rules.title"
          :label="$t('form.labels.company_name')"
        />
      </section>
    </v-form>

    <v-btn
      block
      color="primary"
      :loading="loading"
      :disabled="!formIsValid"
      @click="$emit('get-started-button-hit', form)"
    >
      {{ $t('common.get_started') }}
    </v-btn>
  </div>
</template>

<script>
import { convertMegabytesToBytes } from '@/utils/helpers'

const form = {
  logo_file_id: null,
  subdomain: '',
  title: ''
}

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    resetForm() {
      this.form = { ...form }
      this.$refs.newOrganizationForm.resetValidation()
    },
    removePhoto() {
      this.imageSrc = ''
      form.logo_file_id = null
    }
  },
  data() {
    return {
      imageSrc: '',
      maxFileSize: convertMegabytesToBytes(5),
      formIsValid: false,
      form: { ...form },
      rules: {
        subdomain: this.getSubdomainRules(),
        title: this.getCompanyNameRules()
      }
    }
  }
}
</script>
