<template>
  <v-form
    ref="form"
    class="fill-width"
    :disabled="loading"
    :value="formIsValid"
    @submit.prevent="$emit('form-submitted')"
    @input="$emit('update:formIsValid', $event)"
  >
    <v-text-field
      dense
      outlined
      :value="email"
      :rules="rules.email"
      :label="$t('form.labels.email')"
      @input="$emit('update:email', $event)"
    />
    <p
      v-text="$t('page.reset_password.content')"
      class="text-h6 fill-width text-start secondary--text mb-6"
    />
  </v-form>
</template>

<script>
export default {
  props: {
    formIsValid: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rules: {
        email: this.getEmailRules()
      }
    }
  }
}
</script>
