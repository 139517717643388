<template>
  <centered-layout>
    <template v-slot:appbar>
      <v-toolbar-title class="pa-0 flex-grow-1 d-flex justify-center">
        <base-logo />
      </v-toolbar-title>
    </template>
    <sessions-sign-up-form
      :loading="loading"
      @sign-up-button-hit="handleSignUp"
    />
  </centered-layout>
</template>

<script>
import { mapActions } from 'vuex'
import BaseLogo from '@/components/BaseLogo'
import CenteredLayout from '@/layouts/CenteredLayout'
import SessionsSignUpForm from '@/components/sessions/SessionsSignUpForm'
export default {
  data() {
    return {
      verificationCode: null,
      loading: false,
      token: null,
      handle: ''
    }
  },
  computed: {
    verificationFormVisible() {
      return this.token !== null
    }
  },
  methods: {
    ...mapActions(['register']),
    toggleLoading() {
      this.loading = !this.loading
    },
    async handleSignUp(data) {
      const requestOptions = {
        url: '/users/register'
      }
      const successCallback = (res) => {
        this.$router.push({
          name: 'AccountVerification',
          query: { token: res.data.data.verification_token.token, handle: data.email }
        })
      }
      this.toggleLoading()
      await this.register({
        data,
        requestOptions,
        successCallback,
        errorCallback: this.toggleLoading
      })
    }
  },
  components: {
    BaseLogo,
    CenteredLayout,
    SessionsSignUpForm
  }
}
</script>
