<template>
  <fragment>
    <div class="px-3 py-1 white" v-if="filterable">
      <v-text-field
        dense
        outlined
        clearable
        hide-details
        v-model="search"
        :label="searchLabel"
      >
        <template v-slot:prepend-inner>
          <div class="me-2" style="margin-top: 3px">
            <base-icon
              width="16"
              height="16"
              name="search-alt"
              color="secondary.base"
              className="d-flex align-center"
            />
          </div>
        </template>
      </v-text-field>
    </div>
    <v-list
      flat
      :dense="dense"
      class="flex-grow-1"
      color="transparent"
      v-if="filteredItems.length || !hasEmptyState"
    >
      <v-list-item-group
        :value="value"
        :multiple="multiple"
        @change="handleChange"
        :mandatory="mandatory"
        active-class="primary--text"
      >
        <div
          :key="item.id"
          class="cursor pointer"
          v-for="item in filteredItems"
          @click.prevent="$emit('item-clicked', item)"
        >
          <v-list-item
            :ripple="false"
            :value="item.id"
            :selectable="false"
            :disabled="item.disabled !== undefined && item.disabled === true"
          >
            <template v-slot:default="{ active }">
              <slot name="listitemstart" v-bind:item="item" />
              <v-list-item-content>
                <v-list-item-title
                  class="body-2"
                  v-text="item[titleProp]"
                  :class="{
                    'primary--text': active,
                    'info--text':
                      item.disabled !== undefined && item.disabled === true
                  }"
                />
                <v-list-item-subtitle
                  v-if="subtitleProp"
                  class="caption secondary--text"
                  v-text="`${subtitlePrefix}${item[subtitleProp]}`"
                />
              </v-list-item-content>
              <v-list-item-icon v-if="active" :class="iconClass">
                <base-icon name="check" color="primary" />
              </v-list-item-icon>
              <slot name="listitemend" :item="item" />
            </template>
          </v-list-item>
        </div>
        <slot name="listend" />
      </v-list-item-group>
    </v-list>
    <div
      v-text="$t(emptyListText)"
      v-if="hasEmptyState && filteredItems.length === 0"
      class="
        d-flex
        text-h4
        flex-grow-1
        align-center
        justify-center
        text--darken-4
        secondary--text
      "
    />
  </fragment>
</template>

<script>
import BaseIcon from './BaseIcon'
export default {
  components: { BaseIcon },
  props: {
    emptyListText: {
      type: String,
      default: 'search_and_filter.results.not_found.assignees'
    },
    searchLabel: {
      type: String,
      default: ''
    },
    value: {
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    titleProp: {
      type: String,
      default: 'title'
    },
    subtitlePrefix: {
      type: String,
      default: '@'
    },
    subtitleProp: {
      type: String
    },
    searchProp: {
      type: String,
      default: 'name'
    },
    hasEmptyState: {
      type: Boolean,
      default: true
    },
    iconClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    filteredItems() {
      if (!this.search) {
        return this.items
      }
      return this.items.filter((item) =>
        item[this.searchProp].toLowerCase().includes(this.search.toLowerCase())
      )
    }
  },
  data() {
    return {
      search: ''
    }
  },
  methods: {
    handleChange($event) {
      this.$emit('update:value', $event)
      this.$emit('value-changed', $event)
    }
  }
}
</script>
