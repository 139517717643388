<template>
  <v-container fluid class="rounded-sm" style="min-width:480px">
    <v-row no-gutters>
      <v-col
        cols="4"
        :class="{ 'stick-left': !$vuetify.rtl, 'stick-right': $vuetify.rtl }"
        v-text="$t('common.board', { count: 0 })"
        class="
          sticky
          body-1
          info--text
          text-darken-4 text-no-wrap text-truncate
        "
      />
      <v-col
        cols="2"
        :key="ability.id"
        v-text="ability.title"
        v-for="ability in abilities"
        class="body-1 info--text text-darken-4 justify-center d-flex"
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    abilities: {
      type: Array,
      required: true
    }
  }
}
</script>
