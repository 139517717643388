<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    content-class="small-level-one"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.status.title')"
  >
    <template slot="toolbarActions">
      <v-btn
        text
        small
        class="me-1"
        color="primary"
        @click="handleCreate"
        v-text="$t('common.add_status')"
        v-if="items.length < 10 && !isMobile"
      />
    </template>
    <v-card
      tile
      flat
      height="100%"
      class="white info--text d-flex"
      v-if="!fetching && projectStatuses"
    >
      <v-card-text class="flex-grow-1 d-flex pt-0">
        <section class="flex-grow-1 pa-4 secondary lighten-5 rounded-sm">
          <draggable
            group="items"
            v-model="items"
            handle=".handle"
            @change="updatePosition"
          >
            <settings-dialog-list-draggable-item
              :item="item"
              :key="item.value"
              :loading="loading"
              @save-clicked="handleEdit"
              :disabled="items.length < 4"
              v-for="(item, index) in items"
              @delete-clicked="confirmDelete"
              :is-last="index === items.length - 1"
            >
              <template slot="lastItemContent">
                <v-card>
                  <v-card-title
                    class="text-h3 py-3"
                    v-text="
                      $t(
                        'page.project_settings.status.status_information.title'
                      )
                    "
                  />
                  <v-card-text
                    class="body-2 secondary--text text--darken-2"
                    v-html="
                      $t(
                        'page.project_settings.status.status_information.content'
                      )
                    "
                  />
                </v-card>
              </template>
            </settings-dialog-list-draggable-item>
          </draggable>
        </section>
      </v-card-text>
      <base-confirm-dialog
        :loading="loading"
        ok-color="error base"
        @ok-clicked="deleteStatus"
        :ok-text="$t('common.delete')"
        :dialog.sync="confirmatioDialog"
        cancel-color="secondary darken-2"
        :cancel-text="$t('common.cancel')"
        :title="$t('page.project_settings.status.delete_confirmation.title')"
      >
        <section
          v-html="
            $t('page.project_settings.status.delete_confirmation.content', {
              title: currentStatus ? currentStatus.value : '',
              interpolation: { escapeValue: false }
            })
          "
        />
      </base-confirm-dialog>
      <v-btn
        fab
        bottom
        fixed
        width="56"
        height="56"
        direction="top"
        color="primary"
        :left="$vuetify.rtl"
        @click="handleCreate"
        :right="!$vuetify.rtl"
        v-if="items.length < 10 && isMobile"
      >
        <unicon name="plus" fill="#ffffff" />
      </v-btn>
      <base-inline-form
        @closed="toggle(false)"
        card-text-class-name="px-4"
        :value="createStatusDialog"
        :title="$t('common.add_status')"
      >
        <v-form @submit.prevent="handleSave">
          <v-text-field
            dense
            outlined
            autofocus
            type="text"
            v-model="statusTitle"
            :label="$t('form.labels.status_title')"
          />
        </v-form>
        <base-inline-form-actions
          :loading="loading"
          @ok-clicked="handleSave"
          :ok-text="$t('common.done')"
          @cancel-clicked="toggle(false)"
        />
      </base-inline-form>
    </v-card>
    <base-loading v-else />
  </base-dialog>
</template>

<script>
import sortBy from 'lodash.sortby'
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseLoading from '@/components/BaseLoading'
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'
import { getNewPositionOfDroppedItem } from '@/utils/collection'
import BaseInlineFormActions from '@/components/BaseInlineFormActions'
import SettingsDialogListDraggableItem from './SettingsDialogListDraggableItem.vue'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    project: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'projectStatuses'])
  },
  data() {
    return {
      items: [],
      loading: false,
      fetching: false,
      statusTitle: '',
      currentStatus: null,
      confirmatioDialog: false,
      createStatusDialog: false
    }
  },
  methods: {
    ...mapActions([
      'addProjectStatus',
      'getProjectStatuses',
      'deleteProjectStatus',
      'updateProjectStatus'
    ]),
    toggle(value) {
      this.createStatusDialog = value
    },
    setItems() {
      const { projectStatuses } = this
      if (!projectStatuses) {
        this.items = []
        return
      }
      this.items = sortBy(
        Object.values(projectStatuses).map((item) => {
          return {
            id: item.id,
            value: item.title,
            position: item.position
          }
        }),
        (status) => status.position
      )
    },
    confirmDelete(currentStatus) {
      this.currentStatus = currentStatus
      this.confirmatioDialog = true
    },
    handleEdit({ item, title }) {
      this.currentStatus = item
      this.statusTitle = title
      this.handleSave()
    },
    handleCreate() {
      this.currentStatus = null
      this.statusTitle = ''
      this.toggle(true)
    },
    handleSave() {
      if (this.statusTitle.length === 0) {
        return
      }
      if (this.currentStatus) {
        return this.save()
      }
      this.add()
    },
    async deleteStatus() {
      this.loading = true
      try {
        await this.deleteProjectStatus(this.currentStatus.id)
        this.setItems()
        this.confirmatioDialog = false
      } catch (error) {}
      this.loading = false
    },
    async add() {
      this.loading = true
      try {
        await this.addProjectStatus({
          pid: this.project.id,
          data: {
            title: this.statusTitle,
            position: 'last'
          }
        })
        this.setItems()
        this.toggle(false)
      } catch (error) {}
      this.loading = false
    },
    async updatePosition(value) {
      const { element } = value.moved
      const position = getNewPositionOfDroppedItem(this.items, element.id)
      try {
        await this.updateProjectStatus({
          sid: element.id,
          data: {
            position
          }
        })
        this.setItems()
      } catch (error) {}
    },
    async save() {
      const { id, position } = this.currentStatus
      this.loading = true
      try {
        await this.updateProjectStatus({
          sid: id,
          data: {
            title: this.statusTitle,
            position
          }
        })
        this.setItems()
      } catch (error) {}
      this.loading = false
    },
    async getData() {
      this.fetching = true
      try {
        await this.getProjectStatuses(this.project.id)
        this.setItems()
      } catch (error) {}
      this.fetching = false
    }
  },
  mounted() {
    this.getData()
  },
  components: {
    draggable,
    BaseDialog,
    BaseLoading,
    BaseInlineForm,
    BaseConfirmDialog,
    BaseInlineFormActions,
    SettingsDialogListDraggableItem
  }
}
</script>
