<template>
  <fragment>
    <v-btn
      fab
      fixed
      bottom
      width="56"
      height="56"
      @click="open"
      direction="top"
      color="primary"
      v-show="isMobile"
      :left="$vuetify.rtl"
      :right="!$vuetify.rtl"
    >
      <base-icon name="user-plus" color="white" />
    </v-btn>
    <base-dialog
      card-color="white"
      @go-back-clicked="toggle(false)"
      content-class="small-level-two"
      :dialog="interfaceDialogs.inviteOrganizationMember"
      :title="$t('page.organization_settings.members.add_members.title')"
    >
      <section class="px-4">
        <p
          class="mt-6 body-2 secondary--text"
          v-html="
            $t('page.organization_settings.members.add_members.description', {
              fee: '10$',
              plan: 'Professional',
              interpolation: { escapeValue: false },
              organization: this.organization.title
            })
          "
        />
      </section>
      <base-add-member-by-email
        class="mt-6"
        :invitees.sync="emails"
        :dialog="interfaceDialogs.inviteOrganizationMember"
      />
      <template v-slot:cardActions>
        <div class="flex-grow-0 pa-4">
          <v-btn
            block
            depressed
            color="primary"
            :loading="loading"
            @click="addMember"
            v-text="$t('common.add')"
            :disabled="emails.length === 0 || loading"
          />
        </div>
      </template>
    </base-dialog>
  </fragment>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapGetters, mapActions } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseAddMemberByEmail from '@/components/BaseAddMemberByEmail'
export default {
  computed: {
    ...mapGetters(['isMobile', 'organization', 'interfaceDialogs'])
  },
  data() {
    return {
      loading: false,
      emails: []
    }
  },
  methods: {
    ...mapActions(['setInterfaceDialog', 'addOrganizationMembers']),
    toggle(value) {
      this.setInterfaceDialog({
        prop: 'inviteOrganizationMember',
        value
      })
    },
    open() {
      this.toggle(true)
    },
    async addMember() {
      this.loading = true
      try {
        await this.addOrganizationMembers({
          emails: this.emails
        })
        this.toggle(false)
      } catch (error) {}
      this.loading = false
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseAddMemberByEmail
  }
}
</script>
