<template>
  <v-card
    flat
    :tile="tile"
    color="white"
    class="text-start"
    :class="{ 'rounded-t-lg': !tile }"
  >
    <v-card-title class="pa-0">
      <h3 class="h3-text info--text text-start pa-4">
        {{ title }}
      </h3>
    </v-card-title>
    <v-card-text class="pa-3">
      <slot />
      <div class="d-flex justify-space-between">
        <v-btn
          text
          color="primary"
          @click="$emit('cancel-clicked')"
          :disabled="loading"
        >
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          text
          v-if="!loading"
          :color="saveBtnColor"
          :disabled="disabled"
          @click="$emit('save-clicked')"
        >
          {{ saveBtnText }}
        </v-btn>
        <v-progress-circular
          v-else
          :width="2"
          :size="26"
          class="mx-4"
          indeterminate
          color="primary"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    tile: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    saveBtnText: {
      type: String,
      default: ''
    },
    saveBtnColor: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
