<template>
  <base-detailed-tasks-list
    height="auto"
    :tasks="tasks"
    :value="dependencies"
    @value-changed="handleDependencyChange"
  />
</template>

<script>
import BaseDetailedTasksList from '@/components/BaseDetailedTasksList'
export default {
  props: {
    tasks: {
      type: Array,
      required: true
    },
    dependencies: {
      type: Array,
      required: true
    },
    height: {
      required: true
    }
  },
  data() {
    return {
      taskDependencies: this.dependencies
    }
  },
  methods: {
    handleDependencyChange(ids) {
      this.taskDependencies = ids
      this.$emit('update:dependencies', ids)
    }
  },
  components: {
    BaseDetailedTasksList
  }
}
</script>
