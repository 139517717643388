<template>
  <v-dialog
    scrollable
    :value="dialog"
    :extended="extended"
    @click:outside="goBack"
    :transition="transition"
    :content-class="contentClass"
    v-bind="responsiveDialogProps"
  >
    <v-card tile flat :color="cardColor">
      <slot name="toolbar">
        <v-toolbar
          flat
          height="60"
          color="white"
          class="flex-grow-0"
          :class="cardToolbarClass"
        >
          <base-dialog-back-btn
            v-if="!hideBackBtn"
            :disabled="loading"
            :back-icon="backIcon"
            @go-back-clicked="goBack"
          />
          <v-toolbar-title class="text-h3">
            {{ title }}
          </v-toolbar-title>
          <v-spacer />
          <slot name="toolbarActions">
            <v-btn
              text
              color="primary"
              v-if="showSaveBtn"
              :loading="loading"
              :disabled="disableSaveBtn"
              @click="$emit('save-clicked')"
              v-text="saveBtnText.length ? saveBtnText : $t('common.done')"
            />
          </slot>
          <template v-slot:extension v-if="extended">
            <slot name="toolbarExtention" />
          </template>
        </v-toolbar>
      </slot>
      <v-card-text
        id="base_dialog_text"
        :class="`px-0 pb-0 mb-0 justify-${cardJustification} ${cardColor} ${cardTextClass}${
          overflowHidden ? ' overflow-y-hidden' : ''
        }`"
      >
        <slot />
      </v-card-text>
      <slot name="cardActions" />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseDialogBackBtn from './BaseDialogBackBtn'
export default {
  computed: {
    ...mapGetters(['responsiveDialogProps'])
  },
  components: { BaseDialogBackBtn },
  props: {
    contentClass: {
      type: String,
      default: ''
    },
    transition: {
      type: String,
      default: 'slide-x-transition'
    },
    cardJustification: {
      type: String,
      default: 'space-between'
    },
    cardTextClass: {
      type: String,
      default: ''
    },
    cardToolbarClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    showSaveBtn: {
      type: Boolean,
      default: false
    },
    overflowHidden: {
      type: Boolean,
      default: false
    },
    hideBackBtn: {
      type: Boolean,
      default: false
    },
    extended: {
      type: Boolean,
      default: false
    },
    saveBtnText: {
      type: String,
      default: ''
    },
    disableSaveBtn: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    cardColor: {
      type: String,
      default: 'secondary lighten-5'
    },
    backIcon: {
      type: String,
      default: 'arrow'
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener)
    this.instance = null
  },
  mounted() {
    this._keyListener = function(e) {
      if (e.key === 'Escape') {
        e.preventDefault()
        this.goBack()
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
  },
  methods: {
    goBack() {
      this.$emit('update:dialog', false)
      this.$emit('go-back-clicked', false)
    }
  }
}
</script>
