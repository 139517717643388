import Unicon from 'vue-unicons'
import {
  uniPen,
  uniSort,
  uniPuzzlePiece,
  uniMoneyBill,
  uniCameraPlus,
  uniStar,
  uniUpload,
  uniCommentAlt,
  uniMessage,
  uniUsersAlt,
  uniSync,
  uniTrashAlt,
  uniShareAlt,
  uniPlay,
  uniSquare,
  uniImage,
  uniFile,
  uniMusic,
  uniVideo,
  uniVolumeMute,
  uniLayerGroup,
  uniFileTimesAlt,
  uniBracketsCurly,
  uniFileAlt,
  uniFileSlash,
  uniTrademark,
  uniPizzaSlice,
  uniPaperclip,
  uniUser,
  uniFlower,
  uniArchway,
  uniBasketball,
  uniWindsock,
  uniSmile,
  uniPalette,
  uniCheck,
  uniAlignCenter,
  uniAlignRight,
  uniAlignLeft,
  uniAt,
  uniTag,
  uniArrow,
  uniPostcard,
  uniClipboardAlt,
  uniEye,
  uniEyeSlash,
  uniGoogle,
  uniCog,
  uniSun,
  uniMoon,
  uniTimes,
  uniBars,
  uniBell,
  uniApps,
  uniPlus,
  uniLabel,
  uniArchive,
  uniLabelAlt,
  uniComment,
  uniAngleUp,
  uniColumns,
  uniRopeWay,
  uniLifeRing,
  uniCalender,
  uniAngleDown,
  uniHourglass,
  uniStopwatch,
  uniAngleLeft,
  uniEllipsisV,
  uniEllipsisH,
  uniPlusCircle,
  uniAngleRight,
  uniBalanceScale,
  uniDraggabledots,
  uniQuestionCircle,
  uniEditAlt,
  uniExchange,
  uniCopy,
  uniRepeat,
  uniHorizontalAlignLeft,
  uniLeftToRightTextDirection,
  uniRightToLeftTextDirection,
  uniArrowRight,
  uniArrowLeft,
  uniUserExclamation,
  uniLockAlt,
  uniInvoice,
  uniCalendarAlt,
  uniClapperBoard,
  uniUserPlus,
  uniRedo,
  uniMinusCircle,
  uniGripHorizontalLine,
  uniShieldCheck,
  uniAnalysis,
  uniChartBar,
  uniCreditCard,
  uniHistory,
  uniPaypal,
  uniCommentsAlt,
  uniUserMinus,
  uniCommentAdd,
  uniCommentEdit,
  uniStopwatchSlash,
  uniCalendarSlash,
  uniUserArrows,
  uniListUl,
  uniPhoneSlash,
  uniCommentAltEdit,
  uniExclamationTriangle,
  uniUserTimes,
  uniApple,
  uniFileCheckAlt,
  uniBan,
  uniImport,
  uniCommentAltMessage,
  uniExternalLinkAlt,
  uniSwatchbook,
  uniExport,
  uniExclamationOctagon,
  uniInfoCircle,
  uniPhone,
  uniFileQuestion,
  uniUserSquare,
  uniExchangeAlt,
  uniLink,
  uniSignOutAlt,
  uniSignout,
  uniCreateDashboard,
  uniBuilding,
  uniFrown,
  uniExclamationCircle,
  uniBold,
  uniItalic,
  uniUnderline,
  uniTextStrikeThrough,
  uniTextFields,
  uniAlignJustify,
  uniDropbox,
  uniGoogleDriveAlt,
  uniCompressAlt,
  uniExpandAlt,
  uniSearch,
  uniCheckSquare,
  uniSearchAlt,
  uniWindowGrid
} from 'vue-unicons/src/icons'
import {
  tDot,
  tLogo,
  tGoogle,
  tListOl,
  tDueDate,
  tHashTag,
  tLanguage,
  tAddLayer,
  tLargLogo,
  tStartDate,
  tArrowLeft,
  tStarFilled,
  tArrowRight,
  tMiniHashTag,
  tAddAttachment,
  tRemoveAttachment,
  tClipboardChecked,
  tCircularProgress,
  tMyTasksOverview,
  tMyTasksCreator,
  tMyTasksAssignee,
  tGoogleDrive,
  tDropbox,
  tRemoveCircularProgress,
  tCircleDots,
  tRemoveComment,
  tRemoveListItem,
  tArchive,
  tUnarchive,
  tStartDateRemove,
  tGoogleCalendar,
  tAllDone
} from './custom'

Unicon.add([
  uniSearchAlt,
  uniCheckSquare,
  uniSquare,
  uniSearch,
  uniExpandAlt,
  uniCompressAlt,
  uniPen,
  uniDropbox,
  uniGoogleDriveAlt,
  uniListUl,
  uniTextFields,
  uniBold,
  uniItalic,
  uniUnderline,
  uniBracketsCurly,
  uniTextStrikeThrough,
  uniAlignJustify,
  uniInfoCircle,
  uniExternalLinkAlt,
  uniExclamationCircle,
  uniFrown,
  uniUserArrows,
  uniBuilding,
  uniCreateDashboard,
  uniSignOutAlt,
  uniRedo,
  uniLink,
  uniExchangeAlt,
  uniUserSquare,
  uniFileQuestion,
  uniPhone,
  uniDraggabledots,
  uniInfoCircle,
  uniExclamationOctagon,
  uniExport,
  uniSwatchbook,
  uniExternalLinkAlt,
  uniCommentAltMessage,
  uniUsersAlt,
  uniImport,
  uniBan,
  uniFileCheckAlt,
  uniSort,
  uniApple,
  uniUserTimes,
  uniExclamationTriangle,
  uniCommentAltEdit,
  uniPhoneSlash,
  uniListUl,
  uniUserArrows,
  uniCalendarSlash,
  uniStopwatchSlash,
  uniCommentEdit,
  uniCommentAdd,
  uniUserMinus,
  uniCommentsAlt,
  uniPaypal,
  uniHistory,
  uniCreditCard,
  uniChartBar,
  uniAnalysis,
  uniShieldCheck,
  uniGripHorizontalLine,
  uniMinusCircle,
  uniRedo,
  uniUserPlus,
  uniPuzzlePiece,
  uniClapperBoard,
  uniInvoice,
  uniLockAlt,
  uniUserExclamation,
  uniMoneyBill,
  uniCameraPlus,
  uniStar,
  uniUpload,
  uniCommentAlt,
  uniLayerGroup,
  uniVolumeMute,
  uniMessage,
  uniUsersAlt,
  uniSync,
  uniTrashAlt,
  uniShareAlt,
  uniPlay,
  uniSquare,
  uniFileTimesAlt,
  uniImage,
  uniFile,
  uniMusic,
  uniVideo,
  uniBracketsCurly,
  uniFileAlt,
  uniFileSlash,
  uniTrademark,
  uniPizzaSlice,
  uniPaperclip,
  uniUser,
  uniFlower,
  uniArchway,
  uniBasketball,
  uniWindsock,
  uniSmile,
  uniPalette,
  uniCheck,
  uniAlignCenter,
  uniAlignRight,
  uniAlignLeft,
  uniPostcard,
  uniAt,
  uniTag,
  uniArrow,
  uniArrowRight,
  uniArrowLeft,
  uniClipboardAlt,
  uniEye,
  uniEyeSlash,
  uniGoogle,
  uniCog,
  uniSun,
  uniMoon,
  uniTimes,
  uniBars,
  uniBell,
  uniApps,
  uniPlus,
  uniLabel,
  uniArchive,
  uniLabelAlt,
  uniColumns,
  uniRopeWay,
  uniComment,
  uniAngleUp,
  uniLifeRing,
  uniCalender,
  uniAngleDown,
  uniHourglass,
  uniAngleLeft,
  uniStopwatch,
  uniEllipsisV,
  uniEllipsisH,
  uniPlusCircle,
  uniAngleRight,
  uniBalanceScale,
  uniDraggabledots,
  uniQuestionCircle,
  uniHorizontalAlignLeft,
  uniLeftToRightTextDirection,
  uniRightToLeftTextDirection,
  uniEditAlt,
  uniExchange,
  uniCopy,
  uniRepeat,
  uniCalendarAlt,
  uniSignout,
  uniWindowGrid,
  tDot,
  tLogo,
  tListOl,
  tCircleDots,
  tGoogle,
  tHashTag,
  tDueDate,
  tAddLayer,
  tLargLogo,
  tLanguage,
  tStartDate,
  tArrowLeft,
  tStarFilled,
  tArrowRight,
  tMiniHashTag,
  tAddAttachment,
  tRemoveAttachment,
  tClipboardChecked,
  tCircularProgress,
  tMyTasksOverview,
  tMyTasksCreator,
  tMyTasksAssignee,
  tRemoveComment,
  tGoogleDrive,
  tDropbox,
  tRemoveCircularProgress,
  tRemoveListItem,
  tArchive,
  tUnarchive,
  tStartDateRemove,
  tGoogleCalendar,
  tAllDone
])

export default Unicon
