<template>
  <v-list dense flat color="transparent" class="pt-0">
    <v-list-item-group
      color="primary"
      v-if="showAnyAndNone"
      v-model="noneOrAnyLabels"
      @change="handleNoneOrAnyLabelChange"
    >
      <v-list-item value="none">
        <v-list-item-content>
          <v-list-item-title class="body-2" v-text="'None'"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon class="my-2" v-if="noneOrAnyLabels === 'none'">
          <base-icon name="check" color="primary" />
        </v-list-item-icon>
      </v-list-item>
      <v-list-item value="any">
        <v-list-item-content>
          <v-list-item-title v-text="'Any'"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon class="my-2" v-if="noneOrAnyLabels === 'any'">
          <base-icon name="check" color="primary" />
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
    <v-list-item-group
      v-model="taskLabel"
      :multiple="multiple"
      :mandatory="mandatory"
      @change="handleLabelChange"
      active-class="primary--text"
    >
      <v-list-item
        :key="label.id"
        :ripple="false"
        :value="label.id"
        v-for="label in labels"
      >
        <template v-slot:default="{ active }">
          <v-list-item-avatar size="24" :color="`#${label.color_code}`" />
          <v-list-item-content>
            <v-list-item-title class="body-2" v-text="label.title"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="my-2" v-if="active">
            <base-icon name="check" color="primary" />
          </v-list-item-icon>
        </template>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import BaseIcon from './BaseIcon'
export default {
  components: { BaseIcon },
  props: {
    label: {
      required: true
    },
    labels: {
      required: true
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showAnyAndNone: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    allLabels() {
      if (this.noneOrAnyLabels === 'any') {
        return ['any']
      }
      if (this.noneOrAnyLabels === 'none') {
        return ['none', ...this.taskLabel.filter((label) => label !== 'any')]
      }
      return this.taskLabel
    }
  },
  data() {
    return {
      taskLabel: this.label,
      noneOrAnyLabels: null
    }
  },
  methods: {
    emitChange() {
      this.$emit('update:label', this.allLabels)
    },
    handleNoneOrAnyLabelChange(val) {
      if (val === 'any') {
        this.taskLabel = []
      }
      this.emitChange()
    },
    handleLabelChange() {
      if (this.noneOrAnyLabels === 'any') {
        this.noneOrAnyLabels = null
      }
      this.emitChange()
    },
    showCheckIcon(index) {
      const { taskLabel } = this
      if (!this.multiple && taskLabel === null) {
        return false
      }
      if (this.multiple) {
        return taskLabel.indexOf(index) > -1
      }
      return index === taskLabel
    }
  }
}
</script>
