<template>
  <fragment>
    <v-menu bottom left v-if="!isMobile" v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <profile-overview-avatar-fab
          v-on="on"
          v-bind="attrs"
          :loading="loading"
          :value.sync="sheet"
        />
      </template>
      <base-avatar-image-uploader-list
        :has-image="hasAvatar"
        @add-photo-clicked="handleClickOnAddImage"
        @remove-photo-clicked="handleClickOnRemoveImage"
      />
    </v-menu>
    <v-bottom-sheet v-model="sheet" v-if="isMobile">
      <template v-slot:activator="{ on, attrs }">
        <profile-overview-avatar-fab
          v-on="on"
          v-bind="attrs"
          :loading="loading"
          :value.sync="sheet"
        />
      </template>
      <v-sheet height="auto">
        <base-avatar-image-uploader-list
          :has-image="hasAvatar"
          @add-photo-clicked="handleClickOnAddImage"
          @remove-photo-clicked="handleClickOnRemoveImage"
        />
      </v-sheet>
    </v-bottom-sheet>
    <input
      ref="file"
      type="file"
      class="d-none"
      accept="image/*"
      @change="handleFileChange"
    />
  </fragment>
</template>

<script>
import { createFile } from '@/api/files'
import { mapActions, mapGetters } from 'vuex'
import uploadService from '@/services/uploadService'
import ProfileOverviewAvatarFab from '@/components/user/ProfileOverviewAvatarFab'
import BaseAvatarImageUploaderList from '@/components/BaseAvatarImageUploaderList'

export default {
  props: {
    hasAvatar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sheet: false,
      fileId: null,
      cancelObj: {},
      loading: false
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  methods: {
    ...mapActions(['updateAvatar', 'deleteAvatar', 'displayResponseError']),
    handleError(error) {
      this.loading = false
      this.displayResponseError(error)
    },
    async startUpload(file) {
      this.loading = true
      const createdFile = await createFile()
      const id = createdFile.data.data.id
      this.fileId = id
      uploadService.chunk(
        `/files/${id}`,
        file,
        () => {},
        this.handleError,
        this.setAvatar,
        this.cancelObj
      )
    },
    async setAvatar() {
      try {
        await this.updateAvatar({
          file_id: this.fileId
        })
      } catch (error) {
        this.displayResponseError(error)
      }
      this.loading = false
    },
    async unsetAvatar() {
      this.loading = true
      try {
        await this.deleteAvatar()
      } catch (error) {
        this.displayResponseError(error)
      }
      this.loading = false
    },
    handleFileChange(e) {
      const { files } = e.target
      if (!files) {
        return
      }
      const file = files[0]
      if (!file) {
        return
      }
      this.startUpload(file)
    },
    handleClickOnAddImage() {
      this.sheet = false
      this.$refs.file.click()
    },
    handleClickOnRemoveImage() {
      this.sheet = false
      this.unsetAvatar()
    }
  },
  components: {
    ProfileOverviewAvatarFab,
    BaseAvatarImageUploaderList
  }
}
</script>
