<template>
  <v-list-item
    :value="item.id"
    class="attachment px-2"
    :class="{ uploading: item.uploading, 'has-error': item.hasError }"
  >
    <v-list-item-avatar tile size="48" color="white" class="my-0 me-2">
      <div
        class="fill-width cursor pointer"
        @click="$emit('action-clicked', item)"
      >
        <base-icon
          width="24"
          height="24"
          :name="item.hasError ? 'sync' : 'times'"
          className="d-flex align-center justify-center"
          :color="item.hasError ? 'primary' : 'error'"
        />
      </div>
    </v-list-item-avatar>
    <slot />
    <v-list-item-action class="mt-0">
      <base-icon
        name="ellipsis-v"
        color="secondary.lighten2"
      />
    </v-list-item-action>
    <v-progress-linear
      :value="item.progress"
      class="upload-progress"
      :color="item.uploading ? 'primary' : 'error'"
    />
  </v-list-item>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseIcon
  }
}
</script>
