<template>
  <div class="fill-width d-flex flex-column justify-start">
    <h4
      class="body-2 secondary--text text--darken-2 mb-6"
      v-text="$t('page.onboarding.create_project.content')"
    />
    <v-form @submit.prevent="handleSubmit" v-model="formIsValid">
      <v-text-field
        flat
        dense
        outlined
        v-model="form.project"
        :rules="rules.project"
        :label="$t('form.labels.project_name')"
        @change="$emit('update:projectTitle', $event)"
      />
    </v-form>
    <v-btn
      block
      class="mt-2"
      color="primary"
      :loading="loading"
      @click="handleSubmit"
      :disabled="!formIsValid"
    >
      {{ $t('common.get_started') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean
    },
    projectTitle: {
      type: String
    }
  },
  data() {
    return {
      formIsValid: false,
      form: {
        project: ''
      },
      rules: {
        project: this.getProjectNameRules()
      }
    }
  },
  methods: {
    handleSubmit() {
      if (!this.formIsValid) {
        return
      }
      this.$emit('project-created')
    }
  }
}
</script>
