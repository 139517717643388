import { render, staticRenderFns } from "./TheSecondNavigationDrawerProjects.vue?vue&type=template&id=c6fecd86&"
import script from "./TheSecondNavigationDrawerProjects.vue?vue&type=script&lang=js&"
export * from "./TheSecondNavigationDrawerProjects.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VListGroup,VListItem,VListItemAction,VListItemActionText,VListItemContent,VListItemIcon,VListItemTitle})
