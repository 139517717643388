var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"dialog":_vm.dialog,"loading":_vm.loading,"card-color":"white","show-save-btn":true,"disable-save-btn":_vm.saveDisabled,"content-class":"large-level-three","title":_vm.$t('page.profile_setting.security.authentication.authenticator.title')},on:{"save-clicked":_vm.handleAccountVerification,"go-back-clicked":function($event){return _vm.$emit('update:dialog', false)}}},[_c('div',{staticClass:"pa-4"},[_c('h2',{staticClass:"body-1 info--text",domProps:{"textContent":_vm._s(
        _vm.$t(
          'page.profile_setting.security.authentication.authenticator.enable.title'
        )
      )}}),_c('p',{staticClass:"mt-3 body-1 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
        _vm.$t(
          'page.profile_setting.security.authentication.authenticator.enable.instructions_part_one'
        )
      )}}),_c('section',{staticClass:"mt-4 mb-4"},[(_vm.twoFactorAuthSetupInfo)?_c('v-img',{attrs:{"width":"200","min-height":"200","src":_vm.twoFactorAuthSetupInfo.qr_code}}):_vm._e(),_c('br'),_c('input',{staticClass:"muted body-1",staticStyle:{"width":"280px"},attrs:{"readonly":"","type":"text","id":"app_code"},domProps:{"value":_vm.twoFactorAuthSetupInfo ? _vm.twoFactorAuthSetupInfo.otp_secret : null}}),(_vm.twoFactorAuthSetupInfo)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.copyVerificationCodeToClipboard}},[_c('base-icon',{attrs:{"name":"copy","color":"secondary.darken2"}})],1):_c('v-progress-circular',{attrs:{"size":20,"width":1,"indeterminate":"","color":"primary"}})],1),_c('p',{staticClass:"mt-3 mb-6 body-1 secondary--text text--darken-2",domProps:{"textContent":_vm._s(
        _vm.$t(
          'page.profile_setting.security.authentication.authenticator.enable.instructions_part_two'
        )
      )}}),_c('div',{staticClass:"centered-container pb-4"},[(_vm.dialog)?_c('base-code-input',{attrs:{"loading":_vm.loading},on:{"change":_vm.handleCodeInputChange,"complete":_vm.handleAccountVerification}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }