import request from '@/api/axios'
export const fetchOrganizations = (params = {}) =>
  request({
    url: '/orgs',
    method: 'get',
    params
  })

export const fetchOrganization = (params = { include: ['projects'] }) =>
  request({
    url: '/orgs/#oid',
    method: 'get',
    params
  })

export const fetchOrganizationAbilities = () =>
  request({
    url: '/orgs/#oid/abilities',
    method: 'get'
  })

export const fetchOrganizationPermissions = () =>
  request({
    url: '/orgs/#oid/permissions',
    method: 'get'
  })

export const patchOrganizationPermission = (pid, data) =>
  request({
    url: `/orgs/#oid/permissions/${pid}`,
    method: 'patch',
    data
  })

export const patchOrganization = data =>
  request({
    url: '/orgs/#oid',
    method: 'patch',
    data
  })

export const editOrganizationLogo = data =>
  request({
    url: '/orgs/#oid/logo',
    method: 'put',
    data
  })

export const deleteOrganizationLogo = () =>
  request({
    url: '/orgs/#oid/logo',
    method: 'delete'
  })

export const deleteOrganizationMembers = data =>
  request({
    url: '/orgs/#oid/memberships',
    method: 'delete',
    data
  })

export const createOrganizationMembershipInvitation = mid =>
  request({
    url: `/orgs/#oid/memberships/${mid}/invitation/resend`,
    method: 'post'
  })

export const createOrganizationMembers = data =>
  request({
    url: '/orgs/#oid/memberships',
    method: 'post',
    data
  })

export const fetchOrganizationByUrl = (params, errorHandle) =>
  request({
    url: '/orgs/by-url',
    method: 'get',
    errorHandle,
    params
  })

export const createOrganization = (data = {}) =>
  request({
    url: '/orgs',
    method: 'post',
    data
  })
