<template>
  <div>
    <h2 class="caption info--text ma-4">
      {{ $t('common.information') }}
    </h2>
    <v-sheet color="white" width="100%" class="t-box-shadow pa-3">
      <task-dialog-details-title
        :title="task.title"
        :editable="editables.title"
      />
      <v-divider class="my-3" />
      <task-dialog-details-description
        :description="description"
        :editable="editables.description"
      />
    </v-sheet>
    <h2 class="caption info--text ma-4">
      {{ $t('common.details') }}
    </h2>
    <v-sheet color="white" width="100%" class="t-box-shadow">
      <task-dialog-details-status
        :status="task.status.id"
        :editable="editables.status"
        :statuses="projectStatuses ? Object.values(projectStatuses) : []"
      />
      <v-divider />
      <task-dialog-details-list
        :task="task"
        v-if="projectLists"
        :editable="editables.list"
        :lists="Object.values(projectLists)"
        :boards-with-lists="boardsWithLists"
      />
      <v-divider />
      <task-dialog-details-date
        name="starting_at"
        :max-date="task.due_at"
        :date="task.starting_at"
        :editable="editables.start_date"
      />
      <v-divider />
      <task-dialog-details-date
        name="due_at"
        icon="t-due-date"
        :date="task.due_at"
        :min-date="task.starting_at"
        :editable="editables.due_date"
      />
      <v-divider />
      <task-dialog-details-weight
        :weight="task.weight"
        :editable="editables.weight"
        :weight-unit="projectWeight"
      />
      <v-divider />
      <task-dialog-details-progress
        :progress="task.progress"
        :editable="editables.progress"
      />
      <v-divider />
      <task-dialog-details-label
        :editable="editables.label"
        :label="task.label ? task.label.id : task.label"
      />
    </v-sheet>
    <h2 ref="dependency_header" class="caption info--text ma-4">
      {{ $t('common.task_dependencies') }}
    </h2>
    <v-sheet
      color="white"
      width="100%"
      class="t-box-shadow"
      id="task_dependees"
    >
      <task-dialog-details-task-dependencies
        :dependencies="task.dependees.map((d) => d.id)"
        :title-text="$t('common.waiting_on')"
        :editable="editables.dependencies"
        dependency-type="dependees"
        :tasks="tasks"
      />
    </v-sheet>
    <v-sheet
      color="white"
      width="100%"
      class="t-box-shadow mt-4"
      id="task_dependents"
    >
      <task-dialog-details-task-dependencies
        :dependencies="task.dependents.map((d) => d.id)"
        :editable="editables.dependencies"
        :title-text="$t('common.blocks')"
        dependency-type="dependents"
        title-icon-color="error"
        title-icon="ban"
        :tasks="tasks"
      />
    </v-sheet>

    <div
      class="d-flex flex-row align-center"
      :class="{ 'justify-space-between': hasMembers }"
    >
      <h2 class="caption info--text ma-4">
        {{ $t('common.member', { count: 0 }) }}
      </h2>
      <v-btn
        v-if="hasMembers"
        class="mx-4"
        color="primary"
        depressed
        outlined
        x-small
        fab
        width="24"
        height="24"
        @click="$refs.membersDialog.switchDialogState()"
      >
        <base-icon name="pen" width="15" height="15" color="primary" />
      </v-btn>
    </div>

    <v-sheet color="white" width="100%" class="t-box-shadow">
      <task-dialog-details-members
        ref="membersDialog"
        :users="projectApprovedUsers"
        :editable="editables.assignees"
        :assignable-users="taskAssignableUsers"
        :members="task.assignees.map((assignee) => assignee.id)"
      />
    </v-sheet>
    <div
      class="d-flex flex-row align-center"
      :class="{ 'justify-space-between': hasTags }"
    >
      <h2 class="caption info--text pa-4">
        {{ $t('common.tags') }}
      </h2>
      <v-btn
        v-if="hasTags"
        class="mx-4"
        color="primary"
        depressed
        outlined
        x-small
        fab
        width="24"
        height="24"
        @click="$refs.tagsDialog.switchDialogState()"
      >
        <base-icon name="pen" width="15" height="15" color="primary" />
      </v-btn>
    </div>
    <v-sheet color="white" width="100%" class="t-box-shadow">
      <task-dialog-details-tags
        ref="tagsDialog"
        :pid="project.id"
        v-if="projectTags"
        :editable="editables.tags"
        :tags="task.tags.map((tag) => tag.id)"
        :tag-options="Object.values(projectTags)"
        @tags-saved="$emit('tags-saved', $event)"
      />
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { convertDeltaToHtml } from '@/utils/quill'
import TaskDialogDetailsList from '@/components/task/TaskDialogDetailsList'
import TaskDialogDetailsTags from '@/components/task/TaskDialogDetailsTags'
import TaskDialogDetailsDate from '@/components/task/TaskDialogDetailsDate'
import TaskDialogDetailsLabel from '@/components/task/TaskDialogDetailsLabel'
import TaskDialogDetailsTitle from '@/components/task/TaskDialogDetailsTitle'
import TaskDialogDetailsWeight from '@/components/task/TaskDialogDetailsWeight'
import TaskDialogDetailsStatus from '@/components/task/TaskDialogDetailsStatus'
import TaskDialogDetailsMembers from '@/components/task/TaskDialogDetailsMembers'
import TaskDialogDetailsProgress from '@/components/task/TaskDialogDetailsProgress'
import TaskDialogDetailsTaskDependencies from './TaskDialogDetailsTaskDependencies'
import TaskDialogDetailsDescription from '@/components/task/TaskDialogDetailsDescription'
import BaseIcon from '../BaseIcon.vue'
export default {
  props: {
    priority: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'task',
      'tasks',
      'project',
      'projectTags',
      'projectLists',
      'projectStatuses',
      'projectFeatures',
      'boardsWithLists',
      'taskAssignableUsers',
      'abilitiesPriorityMap',
      'projectApprovedUsers'
    ]),
    hasMembers() {
      return this.task.assignees.length > 0
    },
    hasTags() {
      return this.task.tags.length > 0
    },
    description() {
      const { task } = this
      const { description, description_delta: delta } = task
      if (delta) {
        return convertDeltaToHtml(delta)
      }
      return description || ''
    },
    projectWeight() {
      const { projectFeatures } = this
      if (!projectFeatures) {
        return ''
      }
      return projectFeatures.weight.settings.unit
    },
    editables() {
      return {
        tags: this.priority >= this.abilitiesPriorityMap.Task.edit_tags,
        list: this.priority >= this.abilitiesPriorityMap.Task.move,
        status: this.priority >= this.abilitiesPriorityMap.Task.move,
        label: this.priority >= this.abilitiesPriorityMap.Task.edit_label,
        start_date:
          this.priority >= this.abilitiesPriorityMap.Task.edit_start_date,
        title: this.priority >= this.abilitiesPriorityMap.Task.edit_title,
        description:
          this.priority >= this.abilitiesPriorityMap.Task.edit_description,
        dependencies:
          this.priority >= this.abilitiesPriorityMap.Task.edit_dependencies,
        weight: this.priority >= this.abilitiesPriorityMap.Task.edit_weight,
        due_date: this.priority >= this.abilitiesPriorityMap.Task.edit_due_date,
        progress: this.priority >= this.abilitiesPriorityMap.Task.edit_progress,
        assignees:
          this.priority >= this.abilitiesPriorityMap.Task.edit_assignees
      }
    }
  },
  methods: {
    scrollFix() {
      const { hash } = this.$route
      if (!hash) {
        return
      }
      const target = document.getElementById(hash.replace('#', ''))
      const wrapper = document.querySelectorAll('.task-dialog-content')[0]
      if (!target || !wrapper) {
        return
      }
      const scrollTop = target.offsetTop
      wrapper.scrollTo(0, scrollTop)
    },
    setLinkListener(func) {
      window.addEventListener('click', func)
      const removeListener = () => window.removeEventListener('click', func)
      return removeListener
    }
  },
  mounted() {
    setTimeout(this.scrollFix, 100)
    if (this.$route.name === 'relations') {
      this.$refs.dependency_header.scrollIntoView()
    }
  },
  watch: {
    '$route.hash'() {
      setTimeout(this.scrollFix, 100)
    }
  },
  components: {
    TaskDialogDetailsList,
    TaskDialogDetailsTags,
    TaskDialogDetailsDate,
    TaskDialogDetailsLabel,
    TaskDialogDetailsTitle,
    TaskDialogDetailsWeight,
    TaskDialogDetailsStatus,
    TaskDialogDetailsMembers,
    TaskDialogDetailsProgress,
    TaskDialogDetailsDescription,
    TaskDialogDetailsTaskDependencies,
    BaseIcon
  }
}
</script>
