<template>
  <centered-layout container-class="d-flex fill-height">
    <template v-slot:appbar>
      <v-toolbar-title class="pa-0 flex-grow-1 d-flex justify-center">
        <base-logo />
      </v-toolbar-title>
    </template>
    <section
      style="margin-top: -50px"
      class="pa-4 d-flex flex-grow-1 align-center justify-center flex-column"
    >
      <div class="flex-grow-0 d-flex flex-column align-center justify-center">
        <v-avatar class="mb-4" color="blue lighten-4" size="64">
          <base-icon width="24" height="24" name="trash-alt" color="primary" />
        </v-avatar>
        <h1
          class="text-h1 info--text mb-4"
          v-text="$t('page.restore_account.title')"
        />
        <p
          v-if="expiryDate"
          class="body-1 text-center secondary--text text--darken-2"
          v-text="
            $t('page.restore_account.description', {
              date: $t('date.formats.iso.long', { date: expiryDate })
            })
          "
        />
        <v-btn
          block
          depressed
          color="primary"
          :loading="loading"
          @click="handleAccountRestoration"
          v-text="$t('page.restore_account.restore_button')"
        />
        <v-btn
          block
          outlined
          depressed
          class="mt-4"
          @click="logout"
          color="secondary"
          :disabled="loading"
          v-text="$t('page.restore_account.cancel_button')"
        />
      </div>
    </section>
  </centered-layout>
</template>

<script>
import { mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseLogo from '@/components/BaseLogo'
import CenteredLayout from '@/layouts/CenteredLayout'
import { createAccountRestoration } from '@/api/users'
export default {
  computed: {
    expiryDate() {
      const { expiry } = this.$route.query
      return expiry ? new Date(expiry * 1000) : null
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions(['logout']),
    async handleAccountRestoration() {
      this.loading = true
      try {
        await createAccountRestoration()
      } catch (error) {}
      this.loading = false
      this.$router.push({ path: '/onboarding' })
    }
  },
  components: {
    BaseIcon,
    BaseLogo,
    CenteredLayout
  }
}
</script>
