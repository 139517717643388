<template>
  <search-dialog-list-item
    icon="tag"
    :subtitle="
      selected.length
        ? $t('search_and_filter.n_items_selected', {
            count: selected.length,
            name: ''
          })
        : null
    "
    :title="$t('common.tag', { count: 0 })"
    :toolbar-title="$t('common.tag', { count: 0 })"
  >
    <base-extended-tags-list
      height="auto"
      :multiple="true"
      v-if="projectTags"
      :tag.sync="selected"
      :show-any-and-none="true"
      :tags="Object.values(projectTags)"
    />
  </search-dialog-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseExtendedTagsList from '@/components/BaseExtendedTagsList'
import SearchDialogListItem from '@/components/search/SearchDialogListItem'
export default {
  props: {
    option: {
      required: true
    },
    withAny: {
      required: true
    },
    withNo: {
      required: true
    }
  },
  components: {
    SearchDialogListItem,
    BaseExtendedTagsList
  },
  computed: {
    ...mapGetters(['projectTags'])
  },
  data() {
    return {
      selected: []
    }
  },
  watch: {
    selected(newValue) {
      if (newValue.indexOf('any') > -1) {
        this.$emit('update:withAny', 'tags')
      } else {
        this.$emit('update:withAny', null)
      }
      if (newValue.indexOf('none') > -1) {
        this.$emit('update:withNo', 'tags')
      } else {
        this.$emit('update:withNo', null)
      }
      this.$emit(
        'update:option',
        newValue.filter((item) => item !== 'any' && item !== 'none')
      )
    }
  }
}
</script>
