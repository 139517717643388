<template>
  <draggable
    group="tasks"
    v-model="items"
    filter=".static"
    class="pe-2 ps-3"
    draggable=".task"
    :disabled="disabled"
    :data-list="list.id"
    :data-section="index"
    @add="handleDragEnded"
    style="min-height: 10px"
    :force-fallback="false"
    :fallback-on-body="true"
    :data-status="status.id"
    ghost-class="moving-card"
    chosen-class="chosen-card"
    :delay-on-touch-only="true"
    fallback-class="v-application"
  >
    <project-task-list-section-task
      :task="task"
      class="mb-2"
      v-for="task in items"
      :key="`task_${task.id}`"
      :users="projectApprovedUsers"
      :class="{ static: getListTaskIsStatic(task) }"
    />
    <v-scroll-y-transition hide-on-leave>
      <div
        ref="newInlineTask"
        v-show="createTaskVisible"
        class="mb-4 new-inline-task"
      >
        <v-textarea
          solo
          flat
          dense
          rows="1"
          autofocus
          auto-grow
          hide-details
          v-model="taskTitle"
          @keydown="handleKeydown"
          background-color="white"
          class="rounded-sm mb-2 t-box-shadow body-2"
        />
        <base-inline-edit-actions
          :loading="loading"
          @save-clicked="save"
          @cancel-clicked="close"
        />
      </div>
    </v-scroll-y-transition>
  </draggable>
</template>

<script>
import sortBy from 'lodash.sortby'
import draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'
import BaseInlineEditActions from '@/components/BaseInlineEditActions'
import ProjectTaskListSectionTask from '@/components/project/TaskListSectionTask'

export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    status: {
      type: Object,
      required: true
    },
    list: {
      type: Object,
      required: true
    },
    newTaskSection: {
      required: true
    },
    tasks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      items: [],
      taskTitle: '',
      loading: false,
      createTaskVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'project',
      'teamAbilities',
      'isMobileDevice',
      'projectApprovedUsers',
      'abilitiesPriorityMap'
    ]),
    sectionTasks() {
      return sortBy(
        this.tasks.filter((task) => task.status.id === this.status.id),
        (task) => task.position
      )
    },
    disabled() {
      return (
        this.isMobileDevice ||
        this.getListAbilityPriority(this.list.id) <
          this.abilitiesPriorityMap.List.receive_task
      )
    }
  },
  methods: {
    ...mapActions(['addProjectTask']),
    getNewPositionOfDroppedTask(taskIndex) {
      const nextTask = this.items[taskIndex + 1]
      const prevTask = this.items[taskIndex - 1]
      if (taskIndex === 0) {
        return nextTask ? nextTask.position - 1 : 0
      }
      if (taskIndex === this.items.length - 1) {
        return prevTask.position + 1
      }
      return (prevTask.position + nextTask.position) / 2
    },
    handleDragEnded(e) {
      const lid = e.to.attributes.getNamedItem('data-list').value
      const tid = e.clone.attributes.getNamedItem('data-task').value
      const sid = e.to.attributes.getNamedItem('data-status').value
      const taskIndex = this.items.findIndex((task) => task.id === tid)
      if (taskIndex < 0) {
        return
      }
      const position = this.getNewPositionOfDroppedTask(taskIndex)
      this.$emit('task-moved', { sid, lid, tid, position })
    },
    getListAbilityPriority(listId) {
      const { teamAbilities } = this
      if (!teamAbilities) {
        return 0
      }
      return teamAbilities.lists[listId] || 0
    },
    getListTaskIsStatic(task) {
      return (
        this.getListAbilityPriority(task.list.id) <
        this.abilitiesPriorityMap.Task.move
      )
    },
    handleKeydown(event) {
      if (event.keyCode === 13 && !this.loading) {
        event.preventDefault()
        this.save()
      }
    },
    close() {
      this.taskTitle = ''
      this.$emit('update:newTaskSection', null)
    },
    async save() {
      const { newTaskSection } = this
      if (!newTaskSection || this.taskTitle.length === 0) {
        return
      }
      this.loading = true
      try {
        await this.addProjectTask({
          pid: this.project.id,
          data: {
            title: this.taskTitle,
            list_id: newTaskSection.list,
            status_id: newTaskSection.status
          }
        })
      } catch (error) {}
      this.loading = false
      this.close()
    },
    scrollToBottomOfSection() {
      const lists = document.querySelectorAll(
        `#list_${this.newTaskSection.list}`
      )
      const list = lists[lists.length - 1]
      const section = list.querySelectorAll(
        `[data-status="${this.newTaskSection.status}"]`
      )[0]
      const scrollTop =
        section.clientHeight - this.$refs.newInlineTask.clientHeight

      const scrollWrapper = list.getElementsByClassName('list-cards')[0]
      scrollWrapper.scrollTo(0, scrollTop)
    }
  },
  mounted() {
    this.items = this.sectionTasks
  },
  watch: {
    sectionTasks(newValue) {
      this.items = newValue
    },
    newTaskSection(newValue) {
      this.createTaskVisible = newValue && newValue.status === this.status.id
    },
    createTaskVisible(newValue) {
      if (newValue) {
        setTimeout(this.scrollToBottomOfSection, 100)
      }
    }
  },
  components: {
    draggable,
    BaseInlineEditActions,
    ProjectTaskListSectionTask
  }
}
</script>

<style lang="scss">
.new-inline-task {
  textarea {
    margin-top: 16px !important;
    line-height: 1.5;
    margin-bottom: 16px !important;
    padding: 0 6px !important;
  }
}
</style>
