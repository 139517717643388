import { render, staticRenderFns } from "./TaskDialogFilesListItemUploaded.vue?vue&type=template&id=cd4c08bc&"
import script from "./TaskDialogFilesListItemUploaded.vue?vue&type=script&lang=js&"
export * from "./TaskDialogFilesListItemUploaded.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VAvatar,VBadge,VCard,VFadeTransition,VHover,VImg,VListItem,VListItemAvatar,VOverlay})
