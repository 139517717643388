<template>
  <search-dialog-list-item
    :icon="icon"
    :title="$t(title)"
    :subtitle="subtitle"
    :toolbar-title="$t(toolbarTitle)"
    card-style=""
    dialog-width="unset"
    v-slot="{ dismiss }"
  >
    <span id="date-picker-input"></span>
    <date-picker
      inline
      range
      custom-input="#date-picker-input"
      :locale="locale"
      v-model="range"
      :color="$vuetify.theme.themes.light.primary"
      format="YYYY-MM-DD"
      displayFormat="YYYY/MM/DD"
    >
      <!-- slot for "submit-btn" -->
      <template #submit-btn="{ vm, canSubmit }">
        <v-btn
          depressed
          color="primary"
          v-text="$t('common.done')"
          :disabled="!canSubmit"
          @click="submit(dismiss)"
        />
      </template>

      <!-- slot for "now-btn" -->
      <template #now-btn>
        <v-btn
          type="button"
          outlined
          color="secondary"
          v-text="$t('common.remove')"
          @click="clear"
        />
      </template>
    </date-picker>
  </search-dialog-list-item>
</template>

<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import SearchDialogListItem from '@/components/search/SearchDialogListItem'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    from: {
      required: true
    },
    to: {
      required: true
    },
    icon: {
      type: String,
      default: 't-due-date'
    },
    title: {
      type: String,
      default: 'common.date'
    },
    toolbarTitle: {
      type: String,
      default: 'common.date'
    }
  },
  components: {
    DatePicker: VuePersianDatetimePicker,
    SearchDialogListItem
  },
  computed: {
    ...mapGetters(['locale']),
    subtitle() {
      const { dateFrom, dateTo } = this
      if (dateFrom && dateTo) {
        return `${this.getFormattedDate(dateFrom)} - ${this.getFormattedDate(dateTo)}`
      }
      return dateFrom
    }
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
      range: []
    }
  },
  methods: {
    ...mapActions([
      'displayManualMessage'
    ]),
    getFormattedDate(date) {
      return this.$i18n.t('date.formats.iso.short', {
        date: new Date(date * 1000),
        interpolation: { escapeValue: false }
      })
    },
    submit(dismiss) {
      if (this.range.length === 0) {
        this.displayManualMessage({
          snackbarContent: this.$t('search_and_filter.must_select_a_range'),
          snackbarColor: 'red'
        })
        return
      }
      this.dateFrom = this.range[0] ? new Date(this.range[0]).getTime() / 1000 : Math.trunc(new Date(+0).getTime() / 1000)
      this.dateTo = this.range[1] ? new Date(this.range[1]).getTime() / 1000 : Math.trunc(new Date().getTime() / 1000)
      this.$emit('update:from', this.dateFrom)
      this.$emit('update:to', this.dateTo)
      dismiss()
    },
    clear() {
      this.range = []
    }
  }
}
</script>
