<template>
  <fragment>
    <base-loading v-if="fetching" />
    <template v-else>
      <v-data-table
        dense
        bordered
        calculate-widths
        :items="timelogs"
        loader-height="2"
        :headers="headers"
        :loading="loading"
        :options.sync="options"
        mobile-breakpoint="100"
        :disable-pagination="true"
        :hide-default-footer="true"
        v-if="timelogs && timelogs.length"
        class="timelogs-table mt-2 body-1"
        @click:row="$emit('row-clicked', $event)"
      >
        <template v-slot:[`item.user`]="{ item }">
          <div class="d-flex align-center">
            <base-avatar
              :size="24"
              text-size="subtitle-1"
              :title="getUser(item).username"
              :image="getUser(item).avatar ? getUser(item).avatar.medium : ''"
            />
            <span
              class="text-truncate ms-2"
              style="display: inline-block; max-width: 183px"
            >
              {{ getUser(item).name }}
            </span>
          </div>
        </template>
        <template v-slot:[`item.task_number`]="{ item }">
          <span>
            {{ getTask(item).number }}
          </span>
        </template>
        <template v-slot:[`item.task_title`]="{ item }">
          <span class="text-truncate" style="display: block; max-width: 300px">
            {{ getTask(item).title }}
          </span>
        </template>
        <template v-slot:[`item.started_at`]="{ item }">
          <base-date-time :date="item.started_at" format="short_with_time" />
        </template>
        <template v-slot:[`item.duration`]="{ item }">
          {{ getLocalizedNumber(getTime(item.duration)) }}
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <span
            v-if="item.description"
            class="text-truncate"
            style="display: block; max-width: 440px"
          >
            {{ item.description }}
          </span>
          <span v-else></span>
        </template>
      </v-data-table>
    </template>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import { getFormattedTime } from '@/utils/date'
import BaseAvatar from '@/components/BaseAvatar'
import BaseLoading from '@/components/BaseLoading'
import BaseDateTime from '@/components/BaseDateTime'
export default {
  components: { BaseLoading, BaseDateTime, BaseAvatar },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    fetching: {
      type: Boolean,
      default: false
    },
    timelogs: {
      required: true
    }
  },
  computed: {
    ...mapGetters(['timelogsGroup']),
    headers() {
      if (!this.timelogsGroup) {
        return this.headerOptions.none
      }
      return this.headerOptions[this.timelogsGroup]
    }
  },
  data() {
    return {
      options: {},
      headerOptions: {
        none: [
          {
            text: this.$i18n.t('common.user'),
            value: 'user',
            align: 'start',
            sortable: true
          },
          {
            text: '#',
            value: 'task_number',
            align: 'start',
            sortable: true,
            width: 70
          },
          {
            text: this.$i18n.t('common.task'),
            value: 'task_title',
            align: 'start',
            sortable: true
          },
          {
            sortable: true,
            align: 'start',
            text: this.$i18n.t('common.start_time'),
            value: 'started_at',
            width: 187
          },
          {
            sortable: true,
            align: 'start',
            text: this.$i18n.t('common.duration'),
            value: 'duration',
            width: 112
          },
          {
            sortable: false,
            align: 'start',
            text: this.$i18n.t('common.description'),
            value: 'description'
          }
        ],
        users: [
          {
            text: this.$i18n.t('common.user'),
            value: 'user',
            align: 'start',
            sortable: true
          },
          {
            sortable: true,
            align: 'start',
            text: this.$i18n.t('common.duration'),
            value: 'duration',
            width: 112
          }
        ],
        tasks: [
          {
            text: '#',
            value: 'task_number',
            align: 'start',
            sortable: true,
            width: 70
          },
          {
            text: this.$i18n.t('common.title'),
            value: 'task_title',
            align: 'start',
            sortable: true
          },
          {
            sortable: true,
            align: 'start',
            text: this.$i18n.t('common.duration'),
            value: 'duration',
            width: 112
          }
        ]
      }
    }
  },
  methods: {
    getTime(sec) {
      return getFormattedTime(sec)
    },
    getUser(item) {
      if (!this.timelogsGroup) {
        return item.user
      }
      return item
    },
    getTask(item) {
      if (!this.timelogsGroup) {
        return item.task
      }
      return item
    }
  },
  watch: {
    timelogs(newValue) {
      if (!newValue) {
        this.options = {}
      }
    },
    options: {
      handler() {
        const { sortBy, sortDesc } = this.options
        if (!sortBy || !sortDesc || !sortBy.length) {
          return
        }
        const sortSign = sortDesc[0] ? '-' : ''
        this.$emit('sort-changed', `${sortSign}${sortBy[0]}`)
      },
      deep: true
    }
  }
}
</script>
