<template>
  <fragment>
    <template v-for="member in memberItems">
      <v-list-item dense :ripple="false" :key="member.id">
        <base-avatar
          :size="24"
          class="me-4"
          :isLeft="true"
          textSize="caption"
          :title="member.name"
          :image="member.avatar ? member.avatar.medium : ''"
        />
        <v-list-item-content>
          <v-list-item-title>{{ member.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider :key="`member_divider_${member.id}`" />
    </template>
    <task-dialog-details-add-button
      v-if="editable"
      id="task_assignees"
      @add-clicked="addAssigneeHandler"
      :text="$t('common.add_assignee')"
    />
    <base-dialog
      card-color="white"
      :loading="loading"
      @save-clicked="save"
      :show-save-btn="true"
      :dialog.sync="dialog"
      @go-back-clicked="close"
      :title="$t('common.assignee', { count: 0 })"
      content-class="members-modal large-level-two"
    >
      <base-members-list
        v-if="dialog"
        ref="members"
        :users="items"
        @item-clicked="showInfo"
        :members.sync="taskMembers"
      />

      <v-bottom-sheet v-model="sheet">
        <v-card>
          <v-card-title
            class="text-h3"
            v-text="$t('page.task_assignees.permission_denied_info.title')"
          />
          <v-card-text
            class="body-1 secondary--text text--darken-2"
            v-text="$t('page.task_assignees.permission_denied_info.content')"
          />
          <v-card-actions class="d-flex justify-end" @click="sheet = false">
            <v-btn text color="primary">
              {{ $t('common.okay') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-bottom-sheet>
    </base-dialog>
  </fragment>
</template>
<script>
import isEqual from 'lodash.isequal'
import { mapActions, mapGetters } from 'vuex'
import BaseAvatar from '@/components/BaseAvatar'
import BaseDialog from '@/components/BaseDialog'
import BaseMembersList from '@/components/BaseMembersList'
import TaskDialogDetailsAddButton from '@/components/task/TaskDialogDetailsAddButton'

export default {
  props: {
    members: {
      required: true
    },
    assignableUsers: {
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(['task']),
    tid() {
      return this.task.id
    },
    memberItems() {
      return this.users.filter((user) => this.members.includes(user.id))
    },
    items() {
      return this.users.map((user) => {
        return {
          ...user,
          disabled: !this.assignableUsers
            .map((user) => user.id)
            .includes(user.id)
        }
      })
    }
  },
  data() {
    return {
      sheet: false,
      dialog: false,
      loading: false,
      taskMembers: this.members
    }
  },
  methods: {
    ...mapActions(['updateTask']),
    switchDialogState() {
      if (this.$route.name !== 'assignees') {
        this.$router.push({ name: 'assignees' })
      }
      this.dialog = !this.dialog
    },
    isRouteActive() {
      const { name } = this.$route
      if (name === 'assignees') {
        this.dialog = true
      }
    },
    addAssigneeHandler() {
      this.$router.push({ name: 'assignees' })
      this.dialog = true
    },
    close() {
      this.taskMembers = this.members
    },
    async save() {
      if (isEqual(this.taskMembers, this.members)) {
        this.dialog = false
        return
      }
      this.loading = true
      const values = [
        this.users.filter((member) => this.taskMembers.indexOf(member.id) > -1)
      ]
      try {
        await this.updateTask({
          path: 'assignees',
          tid: this.tid,
          data: {
            ids: this.taskMembers
          },
          values,
          updateMyTask: true
        })
        this.dialog = false
      } catch (error) {}
      this.loading = false
    },
    showInfo(item) {
      if (item.disabled) {
        this.sheet = true
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyEscapeListener)
  },
  mounted() {
    this.isRouteActive()
    this._keyEscapeListener = function(e) {
      if (e.key === 'Escape') {
        e.preventDefault()
        this.dialog = false
      }
    }
    document.addEventListener('keydown', this._keyEscapeListener.bind(this))
  },
  watch: {
    dialog(isActive) {
      if (!isActive) this.$router.push({ name: 'task' })
    },
    parentDialogState(value) {
      if (this.dialog !== value) {
        this.dialog = value
      }
    }
  },
  components: {
    BaseDialog,
    BaseAvatar,
    BaseMembersList,
    TaskDialogDetailsAddButton
  }
}
</script>
