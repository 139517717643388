<template>
  <fragment>
    <v-app-bar flat app height="54" color="white">
      <slot name="appbar" />
    </v-app-bar>
    <v-main class="white onboarding">
      <div :class="containerClass" class="px-4 py-0 centered-container">
        <slot />
      </div>
    </v-main>
  </fragment>
</template>

<script>
export default {
  props: {
    containerClass: {
      type: String,
      default: ''
    }
  }
}
</script>
