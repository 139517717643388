<template>
  <component :is="tagName" :class="className" v-html="text" />
</template>

<script>
import { mapGetters } from 'vuex'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import fa from 'javascript-time-ago/locale/fa'
import { isCurrentDay } from '@/utils/date'
TimeAgo.addLocale(en)
TimeAgo.addLocale(fa)

const SET_TIMEOUT_MAX_SAFE_INTERVAL = 2147483647
const getSafeTimeoutInterval = (interval) => {
  return Math.min(interval, SET_TIMEOUT_MAX_SAFE_INTERVAL)
}

export default {
  props: {
    date: {
      type: Number,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    tagName: {
      type: String,
      default: 'span'
    },
    autoUpdate: {
      type: Boolean,
      default: true
    },
    format: {
      type: String,
      default: 'short_with_time'
    }
  },
  data() {
    return {
      text: '',
      timeAgo: null,
      updater: null,
      localDate: this.getDateInMiliseconds()
    }
  },
  computed: {
    ...mapGetters(['locale'])
  },
  methods: {
    getFormattedDate() {
      const key = `date.formats.iso.${this.format}`
      return this.$i18n.t(key, {
        date: this.localDate
      })
    },
    getDateInMiliseconds() {
      return new Date(this.date * 1000)
    },
    setFormattedDate() {
      this.text = this.getFormattedDate()
    },
    startAutoUpdate() {
      const [formattedDate, timeToNextUpdate] = this.timeAgo.format(
        this.localDate,
        'floor',
        {
          getTimeToNextUpdate: true
        }
      )
      this.text = formattedDate
      this.updater = setTimeout(() => {
        this.startAutoUpdate()
      }, getSafeTimeoutInterval(timeToNextUpdate || 60 * 1000))
    },
    setRelativeTime() {
      TimeAgo.setDefaultLocale(this.locale)
      this.timeAgo = new TimeAgo()
      this.startAutoUpdate()
    },
    setText() {
      if (isCurrentDay(this.localDate)) {
        return this.setRelativeTime()
      }
      this.setFormattedDate()
    },
    reset() {
      clearTimeout(this.updater)
      this.updater = null
    }
  },
  created() {
    this.setText()
  },
  beforeDestroy() {
    this.reset()
  },
  watch: {
    date() {
      this.localDate = this.getDateInMiliseconds()
      this.reset()
      this.setText()
    }
  }
}
</script>
