<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    content-class="full-dialog small-level-one"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.teams_and_access.title')"
  >
    <template slot="toolbarActions">
      <v-btn
        icon
        small
        class="me-1"
        color="primary"
        target="_blank"
        href="https://taskulu.com/help/configuring-teams-and-access"
      >
        <base-icon
          width="18"
          height="18"
          name="info-circle"
          className="d-flex align-center"
        />
      </v-btn>
      <v-btn
        text
        small
        color="primary"
        v-if="!isMobile"
        @click="sheet = true"
        v-text="$t('common.add_team')"
      />
    </template>
    <v-card tile flat height="100%" class="white info--text">
      <v-card-text class="pa-0">
        <div v-if="teams" style="padding-bottom: 70px">
          <settings-dialog-teams-and-access-teams-list
            :teams="permanentTeams"
            @item-clicked="openDialog"
            v-if="permanentTeams.length"
          />
          <v-divider v-if="permanentTeams.length && customTeams.length" />
          <settings-dialog-teams-and-access-teams-list
            :teams="customTeams"
            v-if="customTeams.length"
            @item-clicked="openDialog"
          />
        </div>
        <base-loading v-else />
        <base-dialog
          :extended="true"
          :title="teamTitle"
          :dialog.sync="teamDialog"
          content-class="small-level-two"
        >
          <template v-slot:toolbarExtention v-if="!loadingTeam && team">
            <v-tabs v-model="tab" fixed-tabs height="100%">
              <v-tab class="text-button">
                {{ $t('common.member', { count: 0 }) }}
              </v-tab>
              <v-tab class="text-button">
                {{ $t('common.permission', { count: 0 }) }}
              </v-tab>
            </v-tabs>
          </template>
          <template v-slot:toolbarActions v-if="!loadingTeam">
            <v-menu
              bottom
              min-width="188"
              :right="$vuetify.rtl"
              :left="!$vuetify.rtl"
              v-if="team && !team.permanent"
              transition="slide-x-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  plain
                  v-on="on"
                  class="me-1"
                  v-bind="attrs"
                  :ripple="false"
                >
                  <base-icon name="ellipsis-v" color="info.lighten1" />
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="confirmationDialog = true">
                  <v-list-item-icon class="me-2">
                    <base-icon color="error" name="trash-alt" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="body-1 error--text">
                      {{
                        $t('page.project_settings.teams_and_access.delete_team')
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-if="!loadingTeam">
            <settings-dialog-list-teams-and-access-members
              :team="team"
              :project="project"
              v-if="team && tab === 0"
              :teams="[...permanentTeams, ...customTeams]"
            />
            <settings-dialog-list-teams-and-access-permissions
              :team="team"
              v-if="team && tab === 1"
              :permissions="team.permissions"
              :team-permission-abilities="teamPermissionAbilities"
              :lists="projectLists ? Object.values(projectLists) : []"
              :boards="projectBoards ? Object.values(projectBoards) : []"
            />
          </template>
          <base-loading v-else />
        </base-dialog>
        <v-btn
          fab
          fixed
          bottom
          width="56"
          height="56"
          v-if="isMobile"
          direction="top"
          color="primary"
          :left="$vuetify.rtl"
          @click="sheet = true"
          :right="!$vuetify.rtl"
        >
          <unicon name="plus" fill="#ffffff" />
        </v-btn>
        <base-inline-form
          :value.sync="sheet"
          card-text-class-name="px-4"
          :title="$t('common.add_team')"
        >
          <v-form @submit.prevent="add">
            <v-text-field
              flat
              dense
              outlined
              autofocus
              v-model="input"
              :label="$t('form.labels.team_name')"
            />
          </v-form>
          <base-inline-form-actions
            @ok-clicked="add"
            :loading="loading"
            @cancel-clicked="close"
            :ok-text="$t('common.done')"
          />
        </base-inline-form>
        <base-confirm-dialog
          v-if="team"
          :loading="loading"
          ok-color="error base"
          cancel-color="secondary darken-2"
          :ok-text="$t('common.delete')"
          @ok-clicked="deleteCurrentTeam"
          :dialog.sync="confirmationDialog"
          :cancel-text="$t('common.cancel')"
          :title="
            $t(
              'page.project_settings.teams_and_access.delete_confirmation.title'
            )
          "
        >
          <div
            v-html="
              $t(
                'page.project_settings.teams_and_access.delete_confirmation.content',
                {
                  title: team.title,
                  interpolation: { escapeValue: false }
                }
              )
            "
          />
        </base-confirm-dialog>
      </v-card-text>
    </v-card>
  </base-dialog>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseLoading from '@/components/BaseLoading'
import { getFilteredUsers } from '@/utils/helpers'
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'
import BaseInlineFormActions from '@/components/BaseInlineFormActions'
import SettingsDialogTeamsAndAccessTeamsList from './SettingsDialogTeamsAndAccessTeamsList'
import SettingsDialogListTeamsAndAccessPermissions from './SettingsDialogListTeamsAndAccessPermissions'
import SettingsDialogListTeamsAndAccessMembers from '@/components/project/SettingsDialogListTeamsAndAccessMembers'
export default {
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    project: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'team',
      'teams',
      'isMobile',
      'projectLists',
      'projectBoards',
      'teamPermissionAbilities'
    ]),
    records() {
      if (!this.teams) {
        return []
      }
      return this.teams.records
    },
    permanentTeams() {
      return this.records
        .filter((team) => team.permanent)
        .map((team) => {
          return {
            ...team,
            users: getFilteredUsers(team)
          }
        })
    },
    customTeams() {
      return this.records
        .filter((team) => !team.permanent)
        .map((team) => {
          return {
            ...team,
            users: getFilteredUsers(team)
          }
        })
    },
    teamTitle() {
      if (!this.team) {
        return ''
      }
      return this.team.title
    }
  },
  data() {
    return {
      tab: 0,
      input: '',
      sheet: false,
      loading: false,
      teamDialog: false,
      loadingTeam: false,
      confirmationDialog: false
    }
  },
  methods: {
    ...mapActions([
      'getTeam',
      'setTeam',
      'addTeam',
      'getTeams',
      'addTeamMembers',
      'getProjectLists',
      'getProjectBoards',
      'deleteProjectTeams'
    ]),
    close() {
      this.input = ''
      this.sheet = false
    },
    async add() {
      this.loading = true
      try {
        await this.addTeam({
          pid: this.project.id,
          data: {
            title: this.input
          }
        })
        this.close()
      } catch (error) {}
      this.loading = false
    },
    async deleteCurrentTeam() {
      this.loading = true
      try {
        await this.deleteProjectTeams({
          pid: this.project.id,
          tid: this.team.id
        })
        this.confirmationDialog = false
        this.teamDialog = false
      } catch (error) {}
      this.loading = false
    },
    async openDialog(team) {
      this.loadingTeam = true
      this.teamDialog = true
      try {
        await this.getTeam({
          tid: team.id,
          params: {
            include: ['memberships', 'permissions']
          }
        })
      } catch (error) {}
      this.loadingTeam = false
    },
    async getData() {
      const { id: pid } = this.project
      try {
        await this.getProjectLists(pid)
        await this.getProjectBoards(pid)
        await this.getTeams({
          pid,
          params: {
            limit: 100,
            include: ['memberships']
          }
        })
        this.setItems()
      } catch (error) {}
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    teamDialog(newValue) {
      if (!newValue) {
        this.setTeam(null)
      }
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseLoading,
    BaseInlineForm,
    BaseConfirmDialog,
    BaseInlineFormActions,
    SettingsDialogTeamsAndAccessTeamsList,
    SettingsDialogListTeamsAndAccessMembers,
    SettingsDialogListTeamsAndAccessPermissions
  }
}
</script>
