<template>
  <div>
    <section class="pa-4 px-4">
      <h2
        class="body-1 mb-4"
        v-text="$t('page.profile_setting.account.personal_information')"
      />
      <section class="mb-3">
        <base-inline-input
          :loading="loading"
          :initial-value="user.name"
          @save-clicked="save('name', { name: $event })"
        />
      </section>
      <base-inline-input
        :loading="loading"
        :initial-value="user.username"
        @save-clicked="save('username', { username: $event })"
      />
    </section>
    <v-divider class="secondary lighten-2" />
    <profile-overview-list-account-list />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseInlineInput from '@/components/BaseInlineInput'
import ProfileOverviewListAccountList from '@/components/user/ProfileOverviewListAccountList'
export default {
  components: { BaseInlineInput, ProfileOverviewListAccountList },
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      sheet: false,
      loading: false
    }
  },
  methods: {
    ...mapActions(['updateUser']),
    async save(key, data) {
      this.loading = true
      try {
        await this.updateUser({
          data,
          key
        })
        this.sheet = false
      } catch (error) {}
      this.loading = false
    }
  }
}
</script>
