import axios, { CancelToken } from 'axios'
import { requestInterceptor } from '@/api/interceptors'
import { mockBaseURL, adapter, timeout } from '@/api/common'
const api = axios.create({
  headers: {
    'Content-type': 'application/x-www-form-urlencoded',
    Accept: 'application/json'
  },
  timeout,
  adapter,
  baseURL: mockBaseURL
})
const chunkSize = 1024 * 1024

const chunkUploader = (endpoint, file, options) => {
  const start = options.chunkNumber * chunkSize
  const end = Math.min(file.size, start + chunkSize)
  const params = new FormData()

  params.append('file_chunk', file.slice(start, end), file.name)
  api.interceptors.request.use(requestInterceptor)
  api.defaults.headers['Content-Range'] = `bytes ${start}-${end - 1}/${
    file.size
  }`
  const config = {
    onUploadProgress: progressEvent => {
      options.onProgress &&
        options.onProgress(
          parseInt((end / file.size) * 100, 10),
          progressEvent.loaded
        )
    },
    cancelToken: new CancelToken(cancel => (options.cancelFun.cancel = cancel))
  }
  return api
    .patch(endpoint, params, config)
    .then(res => {
      if (end === file.size) {
        options.onSuccess && options.onSuccess(res)
      } else {
        options.chunkNumber++
        return chunkUploader(endpoint, file, options)
      }
    })
    .catch(err => {
      options.onError && options.onError(err)
    })
}

export default {
  chunk: (endpoint, file, onProgress, onError, onSuccess, cancelFun) => {
    const blockCount = Math.ceil(file.size / chunkSize)
    const chunkNumber = 0
    const identifier = `${file.size}-${file.name.replace('.', '')}`

    return chunkUploader(endpoint, file, {
      blockCount,
      identifier,
      chunkNumber,
      onProgress,
      onError,
      onSuccess,
      cancelFun
    })
  }
}
