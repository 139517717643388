import PN from 'persian-number'
import { truncate, getInitials, getColorFromPaletteBasedOnText } from '@/utils/helpers'
export default {
  methods: {
    getUserColorBasedOnName(name) {
      return getColorFromPaletteBasedOnText(name)
    },
    getLocalizedNumber(number) {
      if (this.$i18n.locale === 'fa') {
        return PN.convertEnToPe(number)
      }
      return number
    },
    getTruncatedNumber(number) {
      if (number < 100) {
        return this.getLocalizedNumber(number)
      }
      return `${this.getLocalizedNumber(99)}+`
    },
    getNameInitials(name) {
      return getInitials(name)
    },
    shortenString(text, limit) {
      return truncate(text, limit)
    },
    getSvgPath(icon) {
      const elem = document.createElement('span')
      elem.innerHTML = icon.path
      const children = elem.childNodes
      if (!children.length) {
        return ''
      }
      return children[0].attributes.getNamedItem('d').value
    }
  }
}
