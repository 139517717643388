export const createRange = (start, end) => {
  return Array.from({ length: end - start + 1 }, (x, i) => i + start)
}

export const transpose = (array, offset) => {
  const table = [...array]
  for (let i = 0; i < offset; i++) {
    table.push(table.shift())
  }
  return table
}

export const getNewPositionOfDroppedItem = (items, id, positionProp = 'position') => {
  const itemIndex = items.findIndex(item => item.id === id)
  if (itemIndex < 0) {
    return 0
  }
  const nextItem = items[itemIndex + 1]
  const prevItem = items[itemIndex - 1]
  if (itemIndex === 0) {
    return nextItem ? nextItem[positionProp] - 1 : 0
  }
  if (itemIndex === items.length - 1) {
    return prevItem[positionProp] + 1
  }
  return (prevItem[positionProp] + nextItem[positionProp]) / 2
}
