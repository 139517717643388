<template>
  <div class="flex-grow-1 justify-start flex-column fill-width">
    <h4 v-text="$t(text)" class="body-2 secondary--text text--darken-2" />
    <v-skeleton-loader
      class="mt-2"
      type="list-item-avatar-two-line"
      v-if="!onboardingProjectTemplates || !onboardingTemplateCategories"
    />
    <template v-else>
      <base-select-to-bottom-sheet :value.sync="category" :items="categories" />
      <v-list class="mt-4" v-if="items.length" dense two-line subheader>
        <template v-for="item in items">
          <v-subheader
            class="text-h5"
            v-text="item.title"
            :key="`category_${item.id}`"
          />
          <v-list-item
            @click="openDetailsDialog(template)"
            :key="`template_${item.id}_${index}`"
            v-for="(template, index) in item.templates"
          >
            <v-list-item-content>
              <v-list-item-title
                v-html="template.title"
                class="body-1 info--text"
              />
              <v-list-item-subtitle
                class="mt-1 body-2 secondary--text text--darken-2"
                v-html="template.short_description"
              />
            </v-list-item-content>
            <v-list-item-action>
              <base-angle-icon width="20" height="20" flipped />
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <h3
        v-else
        class="py-8 body-2 text-center"
        v-text="$t('empty_states.items')"
      />
    </template>
    <base-dialog
      v-if="item"
      back-icon="times"
      card-color="white"
      :dialog.sync="dialog"
      title="Template Details"
      card-justification="start"
      transition="slide-y-transition"
    >
      <div
        class="onboarding-gallery"
      >
        <v-carousel
          hide-delimiters
          show-arrows-on-hover
          v-model="currentSlide"
          height="auto"
        >
          <template v-slot:prev="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on">
              <base-icon name="arrow-left" color="white" />
            </v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on">
              <base-icon name="arrow-right" color="white" />
            </v-btn>
          </template>
          <v-carousel-item eager :key="i" v-for="(slide, i) in item.images">
            <v-sheet
              tile
              color="white"
              max-width="100%"
              style="margin: 0 auto"
            >
              <v-img position="center center" width="100%" :src="slide['large']" />
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
        <ul class="dots">
          <li
            :key="i"
            v-for="(slide, i) in item.images"
            :class="{ active: i === currentSlide }"
          >
            <span class="dot" />
          </li>
        </ul>
      </div>
      <v-divider class="mb-4 mt-2" />
      <div class="fill-width px-8 d-flex flex-column justify-space-between">
        <section class="flex-grow-1">
          <h2 class="text-h5 info--text" v-text="item.title" />
          <h3
            v-text="item.short_description"
            class="body-2 mt-2 secondary--text text--darken-2"
          />
          <article
            v-text="item.long_description"
            class="body-2 mt-4 info--text"
          />
        </section>
        <v-btn
          block
          color="primary"
          class="mt-6 mb-6 flex-grow-0"
          @click="handleTemplateSelection"
          v-text="$t('page.onboarding.template_selection.use_this_template')"
        />
      </div>
    </base-dialog>
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapGetters, mapActions } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import BaseSelectToBottomSheet from '@/components/BaseSelectToBottomSheet'
export default {
  props: {
    params: {
      type: Object
    },
    text: {
      type: String,
      default: 'page.onboarding.template_selection.content'
    }
  },
  computed: {
    ...mapGetters([
      'onboardingProjectTemplates',
      'onboardingTemplateCategories'
    ]),
    filteredCategories() {
      const { category, onboardingTemplateCategories } = this
      if (!onboardingTemplateCategories) {
        return []
      }
      if (category === 'all') {
        return onboardingTemplateCategories
      }
      return onboardingTemplateCategories.filter((item) => item.id === category)
    },
    items() {
      const { filteredCategories, onboardingProjectTemplates } = this
      if (!onboardingProjectTemplates) {
        return null
      }
      return filteredCategories
        .map((category) => {
          return {
            ...category,
            templates: onboardingProjectTemplates.filter(
              (template) => template.category_id === category.id
            )
          }
        })
        .filter((category) => category.templates.length > 0)
    },
    categories() {
      const { onboardingTemplateCategories } = this
      if (!onboardingTemplateCategories) {
        return []
      }
      return [
        {
          id: 'all',
          value: 'all',
          text: this.$i18n.t('common.all')
        },
        ...onboardingTemplateCategories.map((category) => ({
          id: category.id,
          value: category.id,
          text: category.title
        }))
      ]
    }
  },
  data() {
    return {
      item: null,
      sheet: false,
      dialog: false,
      category: 'all',
      currentSlide: 0
    }
  },
  methods: {
    ...mapActions([
      'getOnboardingProjectTemplates',
      'getOnboardingTemplateCategories',
      'isMobile'
    ]),
    handleTemplateSelection() {
      this.dialog = false
      this.$emit('template-selected', this.item.id)
    },
    openDetailsDialog(item) {
      this.item = item
      this.dialog = true
    },
    async getData() {
      const { params } = this
      if (
        !params &&
        this.onboardingProjectTemplates &&
        this.onboardingTemplateCategories
      ) {
        return
      }
      await this.getOnboardingTemplateCategories()
      this.getOnboardingProjectTemplates({ params })
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    dialog() {
      this.currentSlide = 0
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseAngleIcon,
    BaseSelectToBottomSheet
  }
}
</script>
