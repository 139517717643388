<template>
  <fragment>
    <h3
      v-text="$t('page.sign_in.content')"
      class="text-h1 info--text fill-width mt-4 mb-8"
    />
    <v-form
      :disabled="loading"
      v-model="formIsValid"
      @submit.prevent="$emit('form-submitted')"
      class="flex-grow-1 d-flex justify-center flex-column fill-width"
    >
      <section>
        <v-text-field
          flat
          dense
          outlined
          :value="subdomain"
          @input="$emit('update:subdomain', $event)"
          :label="$t('form.labels.your_organization')"
        >
          <template v-slot:append>
            <span class="body-1 secondary--text text--lighten-1"
              >.taskulu.com</span
            >
          </template>
        </v-text-field>
      </section>
    </v-form>
  </fragment>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    subdomain: {
      required: true
    }
  },
  data() {
    return {
      formIsValid: false
    }
  }
}
</script>
