<template>
  <v-container fluid>
    <task-dialog-details-informations-row
      :loading="loading"
      :editable="editable"
      icon="clipboard-alt"
      :text="taskStatusTitle"
      @row-clicked="sheet = true"
      :label="$t('common.status')"
      :add-button-text="$t('common.add_status')"
    />
    <base-inline-form
      :value.sync="sheet"
      card-text-class-name="px-0"
      :title="$t('common.status')"
    >
      <div class="fill-height d-flex align-content-space-between text-start flex-column">
        <base-list
          :value="status"
          :mandatory="true"
          :items="statuses"
          @value-changed="save"
        />
      </div>
    </base-inline-form>
  </v-container>
</template>
<script>
import BaseList from '@/components/BaseList'
import { mapActions, mapGetters } from 'vuex'
import BaseInlineForm from '@/components/BaseInlineForm'
import TaskDialogDetailsInformationsRow from '@/components/task/TaskDialogDetailsInformationsRow'
export default {
  props: {
    statuses: {
      type: Array,
      required: true
    },
    status: {
      required: true
    },
    editable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(['task']),
    tid() {
      return this.task.id
    },
    taskStatusTitle() {
      const status = this.statuses.find((status) => status.id === this.status)
      return status ? status.title : ''
    }
  },
  data() {
    return {
      sheet: false,
      loading: false
    }
  },
  methods: {
    ...mapActions(['updateTaskWithAlias']),
    async save(status) {
      try {
        this.loading = true
        this.sheet = false
        const myTaskValues = [this.statuses.find((s) => s.id === status)]
        await this.updateTaskWithAlias({
          tid: this.tid,
          data: {
            status_id: status
          },
          path: 'move',
          myTaskValues,
          values: [status],
          keys: ['status.id'],
          myTaskKeys: ['status']
        })
      } catch (error) {}
      this.loading = false
    }
  },
  components: {
    BaseList,
    BaseInlineForm,
    TaskDialogDetailsInformationsRow
  }
}
</script>
