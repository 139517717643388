import vueAuth from '../../api'
import axios from '../../api/axios'
import router from '../../router/switch'
import {
  getAppKey,
  setAppKey,
  getSessionId,
  setSessionId,
  clearCookies,
  getIsAuthenticated
} from '../../utils/auth'

import {
  SET_APP_KEY,
  SET_SESSION_ID,
  SET_IS_AUTHENTICATED,
  SET_TWO_FATOR_AUTH_INFO,
  SET_VERIFICATION_EMAIL_COOLDOWN
} from '../mutation-types'

const state = {
  appKey: getAppKey(),
  twoFactorAuthInfo: null,
  sessionId: getSessionId(),
  verificationEmailCooldown: 0,
  isAuthenticated: getIsAuthenticated()
}

const getters = {
  appKey: state => state.appKey,
  sessionId: state => state.sessionId,
  isAuthenticated: state => state.isAuthenticated,
  twoFactorAuthInfo: state => state.twoFactorAuthInfo,
  verificationEmailCooldown: state => state.verificationEmailCooldown
}

const mutations = {
  [SET_SESSION_ID](state, sessionId) {
    state.sessionId = sessionId
  },
  [SET_APP_KEY](state, appKey) {
    state.appKey = appKey
  },
  [SET_IS_AUTHENTICATED](state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
  },
  [SET_TWO_FATOR_AUTH_INFO](state, twoFactorAuthInfo) {
    state.twoFactorAuthInfo = twoFactorAuthInfo
  },
  [SET_VERIFICATION_EMAIL_COOLDOWN](state, verificationEmailCooldown) {
    state.verificationEmailCooldown = Number(verificationEmailCooldown)
  }
}

const actions = {
  signIn({ commit, rootState }, authResponse) {
    const result = authResponse.data.data
    const { session } = result
    if (result.verification_token) {
      return router.push({
        path: '/account-verification',
        query: { token: result.verification_token.token, handle: result.email }
      })
    }
    if (result.two_factor_auth_token) {
      commit(SET_TWO_FATOR_AUTH_INFO, result.two_factor_auth_token)
      return router.push({
        path: '/auth/2fa'
      })
    }
    const { id: sessionId, key: appKey, expires_at: expiresAt } = session
    const expires = new Date(expiresAt * 1000)
    setSessionId(sessionId, expires)
    setAppKey(appKey, expires)
    commit(SET_SESSION_ID, sessionId)
    commit(SET_APP_KEY, appKey)
    commit(SET_IS_AUTHENTICATED, true)
    if (result.session.user_deletion_at) {
      return router.push({
        path: '/account-restoration',
        query: {
          expiry: result.session.user_deletion_at
        }
      })
    }
    if (rootState.organization.organizationSubdomain) {
      return router.push({ path: '/onboarding' })
    }
    router.push({ path: '/create-organization' })
  },
  logout({ commit }) {
    commit(SET_IS_AUTHENTICATED, false)
    clearCookies()
    router.push({ name: 'SignIn' })
  },
  login(
    { commit, dispatch },
    { user, requestOptions, doneCallback = () => {} }
  ) {
    commit(SET_TWO_FATOR_AUTH_INFO, null)
    vueAuth
      .login(user, requestOptions)
      .then(res => {
        dispatch('signIn', res)
      })
      .catch(error => dispatch('displayResponseError', error))
      .finally(doneCallback)
  },
  register(
    { dispatch },
    { data, requestOptions, successCallback, errorCallback = () => {} }
  ) {
    vueAuth
      .register(data, requestOptions)
      .then(successCallback)
      .catch(error => {
        dispatch('displayResponseError', error)
        errorCallback(error)
      })
  },
  auth({ dispatch }, provider) {
    vueAuth
      .authenticate(provider)
      .then(authResponse => {
        dispatch('signIn', authResponse)
      })
      .catch(error => {
        dispatch('displayResponseError', error)
      })
  },
  authRequest(
    { dispatch },
    { url, data, method, showSuccess, successCallback, errorCallback }
  ) {
    axios[method](url, data)
      .then(res => {
        if (successCallback) {
          successCallback(res)
        }
        if (showSuccess) {
          dispatch('displayRequestSuccess', res)
        }
      })
      .catch(error => {
        if (errorCallback) {
          errorCallback(error)
        }
      })
  },
  validate2FAToken({ dispatch }, { url, data }) {
    axios.post(url, data).then(authResponse => {
      dispatch('signIn', authResponse)
    })
  },
  verifyEmail({ dispatch }, { url, data, errorCallback, doneCallback }) {
    axios
      .post(url, data)
      .then(response => {
        dispatch('signIn', response)
      })
      .catch(errorCallback)
      .finally(doneCallback)
  },
  verifyTwoFactorAuthCode({ dispatch }, { url, data, errorCallback, doneCallback }) {
    axios
      .post(url, data)
      .then(response => {
        dispatch('signIn', response)
      })
      .catch(errorCallback)
      .finally(doneCallback)
  },
  resendVerificationEmailForRegisteredUser({ commit }, { url, data, doneCallback }) {
    commit('SET_VERIFICATION_EMAIL_COOLDOWN', 0)
    axios
      .post(url, data)
      .then(response => {
        commit(
          'SET_VERIFICATION_EMAIL_COOLDOWN',
          response.headers['ratelimit-reset']
        )
      })
      .catch(error => {
        if (error.response) {
          commit(
            'SET_VERIFICATION_EMAIL_COOLDOWN',
            error.response.headers['ratelimit-reset']
          )
        }
      })
      .finally(doneCallback)
  }
}

export default {
  mutations,
  actions,
  getters,
  state
}
