<template>
  <div class="empty-state d-flex flex-column justify-center align-center flex-grow-1">
    <div class="d-flex flex-column align-center">
      <v-avatar size="110">
        <base-icon :name="icon" color="secondary.base" width="60" height="60" />
      </v-avatar>
      <h2 class="text-h4 secondary--text mt-4">{{ text }}</h2>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  props: {
    icon: {
      type: String,
      default: 'file-slash'
    },
    text: {
      type: String,
      default: ''
    }
  },
  components: {
    BaseIcon
  }
}
</script>
