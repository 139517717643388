<template>
  <span class="caption secondary--text">
    {{ $t('common.time.second_with_count', { count: remainingSeconds }) }}</span
  >
</template>

<script>
export default {
  props: {
    seconds: {
      default: 0
    }
  },
  data() {
    return {
      remainingSeconds: 0
    }
  },
  methods: {
    countDownTimer() {
      if (this.remainingSeconds > 0) {
        setTimeout(() => {
          this.remainingSeconds -= 1
          this.countDownTimer()
          this.$emit('update:seconds', this.remainingSeconds)
        }, 1000)
      }
    }
  },
  created() {
    this.remainingSeconds = this.seconds
    this.countDownTimer()
  }
}
</script>
