var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"dialog":_vm.dialog,"card-color":"white","content-class":"fsmall-level-one","title":_vm.$t('page.project_settings.tags.title')},on:{"go-back-clicked":function($event){return _vm.$emit('update:dialog', false)}}},[_c('template',{slot:"toolbarActions"},[(!_vm.isMobile && _vm.fabVisible)?_c('v-btn',{staticClass:"me-1",attrs:{"text":"","small":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('common.add_tag'))},on:{"click":_vm.openTagAdd}}):_vm._e()],1),(!_vm.fetching && _vm.projectTags)?_c('v-card',{staticClass:"white info--text d-flex flex-column",attrs:{"tile":"","flat":"","height":"100%"}},[_c('v-card-title',{staticClass:"px-4 pt-1 pb-4 white flex-grow-0 body-1"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"","label":_vm.$t('form.placeholders.search_tags')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"me-2",staticStyle:{"margin-top":"3px"}},[_c('base-icon',{attrs:{"width":"16","height":"16","name":"search-alt","color":"secondary.base","className":"d-flex align-center"}})],1)]},proxy:true}],null,false,1115706878),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('v-card-text',{staticClass:"d-flex flex-grow-1",class:{
        'flex-column align-center justify-center': _vm.items.length === 0
      }},[(_vm.items.length > 0)?_c('div',{staticClass:"flex-grow-1 pa-4 pb-14 secondary lighten-5 rounded-sm"},_vm._l((_vm.items),function(item){return _c('settings-dialog-list-tags-tag',{key:item.id,attrs:{"item":item,"loading":_vm.loading,"editing":_vm.editing},on:{"edit-clicked":_vm.editTag,"delete-clicked":_vm.confirmDelete}})}),1):[(!_vm.query)?_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-img',{attrs:{"width":"168","height":"148","src":require("../../assets/img/illustrations/project-empty-state.svg")}}),_c('h4',{staticClass:"mt-6 text-h4 secondary--text text--darken-2",domProps:{"textContent":_vm._s(_vm.$t('page.project_settings.tags.empty_state'))}}),_c('v-btn',{staticClass:"mt-6",attrs:{"large":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.sheet = true}}},[_vm._v(" + "+_vm._s(_vm.$t('common.add_tag'))+" ")])],1):[_c('h4',{staticClass:"mt-6 text-h4 secondary--text text--darken-2",domProps:{"textContent":_vm._s(_vm.$t('search_and_filter.results.not_found.tags'))}})]]],2),_c('base-confirm-dialog',{attrs:{"loading":_vm.loading,"ok-color":"error base","ok-text":_vm.$t('common.delete'),"dialog":_vm.confirmationDialog,"cancel-color":"secondary darken-2","cancel-text":_vm.$t('common.cancel'),"title":_vm.$t('page.project_settings.tags.delete_confirmation.title')},on:{"ok-clicked":_vm.deleteTag,"update:dialog":function($event){_vm.confirmationDialog=$event}}},[_c('section',{domProps:{"innerHTML":_vm._s(
          _vm.$t('page.project_settings.tags.delete_confirmation.content', {
            title: _vm.currentTag ? _vm.currentTag.title : '',
            interpolation: { escapeValue: false }
          })
        )}})]),_c('base-inline-form',{attrs:{"value":_vm.sheet,"card-text-class-name":"px-4","title":_vm.$t('common.add_tag')},on:{"update:value":function($event){_vm.sheet=$event}}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addTag($event)}}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","autofocus":"","type":"text","label":_vm.$t('form.labels.tag_title')},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('base-inline-form-actions',{attrs:{"loading":_vm.loading,"ok-text":_vm.$t('common.done')},on:{"ok-clicked":_vm.addTag,"cancel-clicked":_vm.cancel}})],1),(_vm.isMobile && _vm.fabVisible)?_c('v-btn',{attrs:{"fab":"","bottom":"","fixed":"","width":"56","height":"56","direction":"top","color":"primary","left":_vm.$vuetify.rtl,"right":!_vm.$vuetify.rtl},on:{"click":_vm.openTagAdd}},[_c('unicon',{attrs:{"name":"plus","fill":"#ffffff"}})],1):_vm._e()],1):_c('base-loading')],2)}
var staticRenderFns = []

export { render, staticRenderFns }