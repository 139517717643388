<template>
  <fragment>
    <v-overlay color="#fff" opacity="0.92" :value="fab" :z-index="5" />
    <v-speed-dial
      v-model="fab"
      :bottom="true"
      direction="top"
      :fixed="isMobile"
      style="z-index: 6"
      :left="$vuetify.rtl"
      :absolute="!isMobile"
      :right="!$vuetify.rtl"
      :open-on-hover="false"
      :class="{ 'mb-0': !isMobile }"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
          fab
          dark
          depressed
          width="56"
          height="56"
          v-model="fab"
          :color="fab ? 'white' : 'primary'"
        >
          <base-icon
            v-if="fab"
            width="24"
            height="24"
            name="times"
            color="secondary.darken2"
          />
          <unicon v-else name="plus" fill="#ffffff" />
        </v-btn>
      </template>
      <div
        class="fab-with-content"
        @click="openLocalFilePicker"
        :class="$vuetify.rtl ? 'right' : 'left'"
      >
        <v-btn fab dark small width="40" height="40" color="white">
          <base-icon name="file" color="primary" />
        </v-btn>
        <div class="text info--text text-button pointer cursor">
          {{ $t('page.task_files.picker.local') }}
        </div>
      </div>
      <div
        class="fab-with-content"
        @click="openDriveFilePicker"
        :class="$vuetify.rtl ? 'right' : 'left'"
      >
        <v-btn fab dark small width="40" height="40" color="white">
          <base-icon name="t-google-drive" color="primary" />
        </v-btn>
        <div class="text info--text text-button pointer cursor">
          {{ $t('page.task_files.picker.drive') }}
        </div>
      </div>
      <div
        class="fab-with-content"
        @click="openDropboxFilePicker"
        :class="$vuetify.rtl ? 'right' : 'left'"
      >
        <v-btn fab dark small width="40" height="40" color="white">
          <base-icon name="t-dropbox" color="primary" />
        </v-btn>
        <div class="text info--text text-button pointer cursor">
          {{ $t('page.task_files.picker.dropbox') }}
        </div>
      </div>
    </v-speed-dial>
    <input
      multiple
      ref="file"
      type="file"
      style="display: none"
      @change="handleFileChange"
    />
  </fragment>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    fab: false
  }),
  components: {
    BaseIcon
  },
  methods: {
    handleFileChange(e) {
      this.$emit('files-changed', e)
      this.$refs.file.value = ''
      this.fab = false
    },
    openLocalFilePicker() {
      this.$refs.file.click()
    },
    openDriveFilePicker() {
      this.$emit('pick-from-google-clicked')
    },
    openDropboxFilePicker() {
      this.$emit('pick-from-dropbox-clicked')
    }
  }
}
</script>
