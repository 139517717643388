import { SHOW_MESSAGE } from '../mutation-types'
const getErrorContentBasedOnStatus = (error, dispatch) => {
  if (!error || !error.response) {
    return 'Network error!'
  }
  const { status } = error.response
  if (status === 500) {
    return 'Server error!'
  }
  if (status === 404) {
    if (error.response.data && 'error' in error.response.data) return error.response.data.error
    return 'Not found'
  }
  const { data } = error.response
  if (status === 401 && data.errors.find(err => err.code === 'UNAUTHORIZED')) {
    return dispatch('logout')
  }
  if ('errors' in data) {
    return data.errors.map(err => err.message).join(' ')
  }
  if ('error' in data) {
    return error
  }
  return ''
}

const getSuccessContentBasedOnStatus = res => {
  if (res.data.data && 'message' in res.data.data) {
    return res.data.data.message
  }
  return 'Operation successful!'
}

const state = {
  snackbarContent: '',
  snackbarColor: ''
}

const getters = {
  snackbarContent: state => state.snackbarContent,
  snackbarColor: state => state.snackbarColor
}

const mutations = {
  [SHOW_MESSAGE](state, payload) {
    state.snackbarContent = payload.snackbarContent
    state.snackbarColor = payload.snackbarColor
  }
}

const actions = {
  displayResponseError({ commit, dispatch }, error) {
    const snackbarColor = 'error'
    const snackbarContent = getErrorContentBasedOnStatus(error, dispatch)
    if (!snackbarContent.length) {
      return
    }
    commit(SHOW_MESSAGE, { snackbarContent, snackbarColor })
  },
  displayRequestSuccess({ commit }, res) {
    const snackbarContent = getSuccessContentBasedOnStatus(res)
    commit(SHOW_MESSAGE, { snackbarContent, snackbarColor: 'success' })
  },
  displaySuccessMessage({ commit }, snackbarContent) {
    commit(SHOW_MESSAGE, { snackbarContent, snackbarColor: 'info darken-2' })
  },
  displayManualMessage({ commit }, { snackbarContent, snackbarColor }) {
    commit(SHOW_MESSAGE, { snackbarContent, snackbarColor })
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
