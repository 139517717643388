import Cookies from 'js-cookie'
import { getOrganizationSubdomain } from '@/utils/helpers'

const appName = 'taskulu'

const appKeyName = `${appName}_app_key`

const sessionIdName = `${appName}_session_id`

export const driveAccessTokenName = `${appName}_drive_access_token`

export const googleDriveAccessTokenName = `${appName}_google_drive_access_token`

export const getDomain = () => {
  const subdomain = getOrganizationSubdomain()
  const { hostname } = window.location
  if (!subdomain) {
    return hostname
  }
  const separate = hostname.split('.')
  separate.shift()
  return separate.join('.')
}

const attributes = {
  sameSite: 'strict',
  domain: getDomain()
}

export const getAppKey = () => Cookies.get(appKeyName) || null

export const getSessionId = () => Cookies.get(sessionIdName) || null

export const getIsAuthenticated = () => {
  return getAppKey() !== null && getSessionId() !== null
}

export const setAppKey = (appKey, expires = 7) => {
  Cookies.set(appKeyName, appKey, { ...attributes, expires })
}

export const setSessionId = (sessionId, expires = 7) => {
  Cookies.set(sessionIdName, sessionId, { ...attributes, expires })
}

export const setDriveAccessTokenInLocalStorage = accessToken => {
  localStorage.setItem(driveAccessTokenName, accessToken)
}

export const setGoogleDriveAccessTokenInLocalStorage = accessToken => {
  localStorage.setItem(googleDriveAccessTokenName, accessToken)
}

export const removeAppKey = () => {
  Cookies.remove(appKeyName, attributes)
}

export const removeSessionId = () => {
  Cookies.remove(sessionIdName, attributes)
}

export const clearCookies = () => {
  removeAppKey()
  removeSessionId()
  localStorage.clear()
}
