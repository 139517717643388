<template>
  <div class="mb-5">
    <base-inline-input
      :loading="loading"
      :initial-value="item.value"
      @save-clicked="$emit('save-clicked', {
        item,
        title: $event
      })"
      @edit-clicked="$emit('edit-clicked', {
        item
      })"
      :label="$t(`form.labels.${name}_title`)"
    >
      <v-btn
        small
        text
        depressed
        color="error"
        v-if="!deleteDisabled"
        v-text="$t('common.delete')"
        @click="$emit('delete-clicked', item)"
      />
      <template v-slot:prependExtra>
        <v-btn
          icon
          x-small
          class="handle me-2"
          style="margin-top:2px"
          :disabled="moveDisabled"
        >
          <base-icon
            name="draggabledots"
            :color="moveDisabled ? 'secondary.lighten1' : 'secondary.darken2'"
          />
        </v-btn>
      </template>
      <template v-if="isLast" v-slot:appendExtra>
        <v-menu
          top
          offset-y
          :nudge-top="8"
          v-model="menu"
          :max-width="300"
          class="t-box-shadow"
          :right="$vuetify.rtl"
          :left="!$vuetify.rtl"
          transition="slide-x-transition"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              dark
              v-on="on"
              v-bind="attrs"
              style="margin-top: -1px"
            >
              <base-icon
                name="info-circle"
                color="primary"
                v-bind="attrs"
                v-on="on"
              />
            </v-btn>
          </template>
          <slot name="lastItemContent" />
        </v-menu>
      </template>
    </base-inline-input>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseInlineInput from '@/components/BaseInlineInput'
export default {
  props: {
    name: {
      type: String,
      default: 'status'
    },
    item: {
      type: Object,
      required: true
    },
    isLast: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['teamAbilities', 'abilitiesPriorityMap']),
    boardPermissionAbilities() {
      return this.abilitiesPriorityMap.Board
    },
    boardPriority() {
      if (!this.teamAbilities) {
        return
      }
      const { id } = this.item
      const { boards } = this.teamAbilities
      return boards[id] ? boards[id] : 0
    },
    boardIsDeletable() {
      return this.boardPriority >= this.boardPermissionAbilities.delete
    },
    moveDisabled() {
      if (this.name === 'status') {
        return false
      }
      return this.boardPriority < this.boardPermissionAbilities.edit_position
    },
    renameDisabled() {
      if (this.name === 'status') {
        return false
      }
      return this.boardPriority < this.boardPermissionAbilities.edit_title
    },
    deleteDisabled() {
      if (this.name === 'status') {
        return this.disabled
      }
      return this.item.permanent || !this.boardIsDeletable
    }
  },
  data() {
    return {
      menu: false
    }
  },
  methods: {
    handleClick() {
      if (this.renameDisabled) {
        return
      }
      this.$emit('edit-clicked', this.item)
    }
  },
  components: {
    BaseIcon,
    BaseInlineInput
  }
}
</script>
