var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"dialog":_vm.dialog,"card-color":"white","content-class":"small-level-one","title":_vm.$t('page.project_settings.members.title')},on:{"go-back-clicked":function($event){return _vm.$emit('update:dialog', false)}}},[_c('template',{slot:"toolbarActions"},[_c('v-menu',{attrs:{"bottom":"","right":_vm.$vuetify.rtl,"left":!_vm.$vuetify.rtl,"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","small":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('common.add'))}},'v-btn',attrs,false),on))]}}])},[_c('v-list',_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i,attrs:{"dense":""},on:{"click":item.action}},[_c('v-list-item-icon',{staticClass:"me-2"},[_c('base-icon',{attrs:{"color":"secondary.base","name":item.icon}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2 info--text",domProps:{"textContent":_vm._s(
                _vm.$t(
                  ("page.project_settings.members.invitation." + (item.title) + ".action_text")
                )
              )}})],1)],1)}),1)],1)],1),_c('base-pending-members-list',{attrs:{"loading":_vm.loading,"members":_vm.projectPendingUsers},on:{"action-clicked":_vm.handleClickOnPendingMemberAction}}),_c('base-members-list-with-actions',{attrs:{"members":_vm.projectApprovedUsers},on:{"action-clicked":_vm.openDialog},scopedSlots:_vm._u([{key:"actions",fn:function(scope){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return scope.next('delete')}}},[_c('base-icon',{attrs:{"width":"24","height":"24","name":"trash-alt","color":"secondary.base"}})],1)]}}])}),(_vm.member)?_c('base-remove-member-confiramtion-dialog',{attrs:{"member":_vm.member,"loading":_vm.loading,"dialog":_vm.confirmationDialog},on:{"ok-clicked":_vm.deleteMember,"update:dialog":function($event){_vm.confirmationDialog=$event}}}):_vm._e(),(_vm.isMobile)?_c('settings-dialog-list-members-fab',{on:{"invite-by-link-clicked":function($event){_vm.inviteByLinkDialog = true},"invite-by-email-clicked":function($event){_vm.inviteByEmailDialog = true}}}):_vm._e(),(_vm.project && _vm.teams)?_c('settings-dialog-list-members-invite-by-email',{attrs:{"teams":_vm.teams,"project":_vm.project,"dialog":_vm.inviteByEmailDialog},on:{"update:dialog":function($event){_vm.inviteByEmailDialog=$event}}}):_vm._e(),(_vm.teams)?_c('settings-dialog-list-members-invite-by-link',{attrs:{"teams":_vm.teams,"project":_vm.project && _vm.teams,"dialog":_vm.inviteByLinkDialog},on:{"update:dialog":function($event){_vm.inviteByLinkDialog=$event}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }