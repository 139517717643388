<template>
  <div style="min-height:120px">
    <base-list
      :mandatory="true"
      :value.sync="language"
      :items="userLanguages"
      v-if="!loading && userLanguages"
    />
    <base-loading v-else />
  </div>
</template>

<script>
import BaseList from '@/components/BaseList'
import { mapGetters, mapActions } from 'vuex'
import BaseLoading from '@/components/BaseLoading'
export default {
  props: {
    saveClicked: {
      type: Boolean,
      default: false
    },
    saveDisabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['userLanguages', 'user', 'locale'])
  },
  data() {
    return {
      language: null,
      items: [
        {
          title: this.$i18n.t(
            'page.profile_setting.account.language.options.english'
          ),
          id: 'en'
        },
        {
          title: this.$i18n.t(
            'page.profile_setting.account.language.options.persian'
          ),
          id: 'fa'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['updateUser', 'getUser']),
    async save() {
      const { language } = this
      this.$emit('update:loading', true)
      try {
        await this.updateUser({
          data: {
            language
          },
          key: 'language'
        })
        await this.getUser()
        this.$emit('action-done')
        this.$vuetify.rtl = language === 'fa'
        this.$i18n.i18next.changeLanguage(language)
      } catch (error) {}
      this.$emit('update:loading', false)
    }
  },
  watch: {
    language(newValue) {
      if (newValue === this.user.language) {
        return
      }
      this.save()
    }
  },
  components: {
    BaseList,
    BaseLoading
  },
  mounted() {
    this.language = this.user.language
  }
}
</script>
