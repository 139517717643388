<template>
  <base-dialog
    :dialog="dialog"
    :loading="loading"
    card-color="white"
    content-class="small-level-one"
    :show-save-btn="mode === 'add'"
    :disable-save-btn="saveDisabled"
    :title="$t(`common.${mode}_entry`)"
    :save-btn-text="$t('common.done')"
    @save-clicked="$emit('add-timelog-clicked')"
    @go-back-clicked="$emit('update:dialog', false)"
  >
    <template v-slot:toolbarActions v-if="mode === 'edit'">
      <timelogs-timelog-dialog-actions
        @delete-clicked="confirmationDialogVisible = true"
      />
    </template>
    <base-confirm-dialog
      :loading="deleting"
      cancelColor="primary"
      okColor="error base"
      @ok-clicked="deleteTimelog"
      :okText="$t('common.delete')"
      :cancelText="$t('common.cancel')"
      :dialog.sync="confirmationDialogVisible"
      :title="$t('page.timelogs.delete_confirmation.title')"
      :content="$t('page.timelogs.delete_confirmation.content')"
    />
    <section class="pa-4" v-if="dialog">
      <header class="d-flex align-center">
        <base-avatar
          :size="32"
          :title="owner ? owner.name : ''"
          :image="owner && owner.avatar ? owner.avatar.medium : ''"
        />
        <span class="body-1 info--text ms-2" v-text="owner ? owner.name : ''" />
      </header>
      <main class="mt-11">
        <div v-if="mode === 'add'" class="mb-4" @click="tasksDialog = true">
          <v-text-field
            flat
            dense
            solo
            readonly
            outlined
            required
            hide-details
            ref="timelogTask"
            :label="$t('common.task')"
            class="cursor pointer body-1"
            :placeholder="$t('common.task')"
            :value="selectedTask ? selectedTask.title : null"
          >
            <template v-slot:append>
              <base-icon
                className="d-flex align-center"
                name="angle-down"
                color="secondary.base"
              />
            </template>
            <template v-if="selectedTask" v-slot:prepend-inner>
              <strong class="info--text"> #{{ selectedTask.number }} </strong>
            </template>
          </v-text-field>
          <base-dialog
            card-color="white"
            :dialog.sync="tasksDialog"
            content-class="small-level-two"
            :title="$t('page.timelogs.task_selection.title')"
          >
            <base-detailed-tasks-list
              height="100%"
              :value="taskId"
              :multiple="false"
              :tasks="filteredTasks"
              @value-changed="$emit('update:taskId', $event)"
            />
          </base-dialog>
        </div>
        <div v-else class="body-1 info--text d-flex flex-row mb-6">
          <template v-if="task">
            <div class="me-2">
              <base-icon name="clipboard-alt" color="primary" />
            </div>
            <div>
              <span class="text-h6 me-1" v-text="`#${task.number}`" />
              {{ task.title }}
            </div>
          </template>
        </div>
        <v-row class="mb-1">
          <v-col cols="6">
            <v-text-field
              dense
              outlined
              readonly
              hide-details
              class="body-1"
              id="timelog_from"
              :value="start.medium"
              :label="$t('form.labels.from')"
            />
            <date-picker
              type="datetime"
              :max="maxStart"
              :locale="locale"
              v-model="startedAt"
              :format="shortFormat"
              :displayFormat="datepickerDisplay"
              element="timelog_from"
              :initial-value="start.short"
              @change="changeFrom($event.unix())"
              :color="$vuetify.theme.themes.light.primary"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              outlined
              readonly
              hide-details
              class="body-1"
              id="timelog_to"
              :value="end.medium"
              :label="$t('form.labels.to')"
            />
            <date-picker
              :max="maxStop"
              type="datetime"
              :locale="locale"
              :min="datepickerToMin"
              v-model="stoppedAt"
              element="timelog_to"
              :format="shortFormat"
              :displayFormat="datepickerDisplay"
              :initial-value="end.short"
              @change="changeTo($event.unix())"
              :color="$vuetify.theme.themes.light.primary"
            />
          </v-col>
        </v-row>
        <v-textarea
          outlined
          :rows="3"
          class="body-1"
          name="input-7-4"
          @click="handleClick"
          :value="description"
          :label="$t('common.description')"
          @input="$emit('update:description', $event)"
        />
        <base-inline-form
          :value.sync="sheet"
          card-text-class-name="px-4"
          :title="$t('common.description')"
        >
          <v-textarea
            outlined
            :rows="3"
            class="body-1"
            name="input-7-4"
            @click="handleClick"
            v-model="modifiedDescription"
            :label="$t('common.description')"
          />
          <base-inline-form-actions
            @ok-clicked="save"
            @cancel-clicked="cancel"
            :ok-text="$t('common.done')"
          />
        </base-inline-form>
      </main>
    </section>
  </base-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseAvatar from '@/components/BaseAvatar'
import BaseDialog from '@/components/BaseDialog'
import { getFormattedLocaleDate } from '@/utils/date'
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import BaseInlineFormActions from '@/components/BaseInlineFormActions'
import BaseDetailedTasksList from '@/components/BaseDetailedTasksList'
import TimelogsTimelogDialogActions from '@/components/timelogs/TimelogsTimelogDialogActions'
export default {
  props: {
    tid: {
      required: true
    },
    mode: {
      type: String,
      default: 'add'
    },
    loading: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: String,
      default: ''
    },
    taskTitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    from: {
      required: true
    },
    to: {
      required: true
    },
    owner: {
      required: true
    },
    task: {
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'tasks',
      'locale',
      'project',
      'teamAbilities',
      'abilitiesPriorityMap'
    ]),
    format() {
      return this.$i18n.t('date.formats.iso.plain.medium_with_time')
    },
    shortFormat() {
      return this.$i18n.t('date.formats.iso.plain.short_with_time')
    },
    start() {
      return this.getDateObject(this.from)
    },
    end() {
      return this.getDateObject(this.to)
    },
    maxStop() {
      return getFormattedLocaleDate(
        new Date().getTime() / 1000,
        this.shortFormat,
        this.locale
      )
    },
    datepickerToMin() {
      return this.startedAt ? this.startedAt : this.start?.short
    },
    datepickerDisplay() {
      return this.locale === 'fa'
        ? 'jYYYY/jMM/jDD, hh:mm A'
        : 'YYYY/MM/DD, hh:mm A'
    },
    maxStart() {
      if (this.end.short) {
        return this.end.short
      }
      return this.maxStop
    },
    saveDisabled() {
      return this.taskId.length === 0 || !this.from || !this.to
    },
    selectedTask() {
      if (!this.taskId) {
        return null
      }
      return this.tasks.find((task) => task.id === this.taskId) || null
    },
    filteredTasks() {
      return this.tasks.filter(
        (task) =>
          this.teamAbilities.lists[task.list.id] >=
          this.abilitiesPriorityMap.Task.create_timelog
      )
    }
  },
  data() {
    return {
      sheet: false,
      deleting: false,
      startedAt: null,
      stoppedAt: null,
      tasksDialog: false,
      confirmationDialogVisible: false,
      modifiedDescription: this.description
    }
  },
  methods: {
    ...mapActions(['deleteTimelogEntry']),
    customFilter(item, queryText) {
      const textOne = item.title.toLowerCase()
      const textTwo = `${item.number}`
      const searchText = queryText.toLowerCase()
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      )
    },
    getDateObject(value) {
      if (!value) {
        return {
          medium: null,
          short: null
        }
      }
      return {
        medium: getFormattedLocaleDate(value, this.format, this.locale),
        short: getFormattedLocaleDate(value, this.shortFormat, this.locale)
      }
    },
    handleClick() {
      this.sheet = true
    },
    save() {
      const { modifiedDescription: description } = this
      this.sheet = false
      this.$emit('update:description', description)
      this.$emit('field-changed', {
        description
      })
    },
    cancel() {
      this.modifiedDescription = this.description
      this.sheet = false
    },
    changeFrom(date) {
      this.$emit('update:from', date)
      this.$emit('field-changed', {
        started_at: date
      })
    },
    changeTo(date) {
      this.$emit('update:to', date)
      this.$emit('field-changed', {
        stopped_at: date
      })
    },
    async deleteTimelog() {
      this.deleting = true
      try {
        await this.deleteTimelogEntry(this.tid)
        this.confirmationDialogVisible = false
        this.$emit('update:dialog', false)
      } catch (error) {}
      this.deleting = false
    }
  },
  watch: {
    description(newValue) {
      this.modifiedDescription = newValue
    },
    taskId(newValue) {
      if (newValue) {
        this.tasksDialog = false
        if (this.$refs.timelogTask) {
          this.$refs.timelogTask.blur()
        }
      }
    }
  },
  components: {
    BaseIcon,
    BaseAvatar,
    BaseDialog,
    BaseInlineForm,
    BaseConfirmDialog,
    BaseDetailedTasksList,
    BaseInlineFormActions,
    TimelogsTimelogDialogActions,
    DatePicker: VuePersianDatetimePicker
  }
}
</script>
