<template>
  <v-container fluid>
    <task-dialog-details-informations-row
      :icon="icon"
      :loading="loading"
      :editable="editable"
      :text="date ? $t('date.formats.iso.medium_with_time', {
        date: new Date(date * 1000)
      }) : ''"
      :label="$t(`common.${name}`)"

      :add-button-text="$t(`common.add_${name}`)"
      @row-clicked="showCalendar=true"
    >
      <template v-slot:actions v-if="editable">
        <v-btn
          icon
          x-small
          v-if="date"
          class="ms-2"
          @click.stop="clear"
        >
          <base-icon name="times" color="secondary.base" width="14" height="14" />
        </v-btn>
      </template>
    </task-dialog-details-informations-row>
    <div v-if="editable" class="d-none" :id="`task_date_${name}`"></div>
    <date-picker
      :max="max"
      :min="min"
      :show="showCalendar"
      type="datetime"
      :locale="locale"
      v-model="theDate"
      :format="shortFormat"
      :initial-value="taskDate"
      @change="save($event.unix())"
      :custom-input="`#task_date_${name}`"
      :color="$vuetify.theme.themes.light.primary"
      @close="showCalendar=false"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import { getFormattedLocaleDate } from '@/utils/date'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import TaskDialogDetailsInformationsRow from '@/components/task/TaskDialogDetailsInformationsRow'
export default {
  props: {
    date: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 't-start-date'
    },
    maxDate: {
      default: null
    },
    minDate: {
      default: null
    },
    editable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(['task', 'locale']),
    shortFormat() {
      if (this.locale === 'fa') {
        return 'jYYYY/jM/jD, hh:mm A'
      }
      return 'YYYY/M/D, hh:mm A'
    },
    min() {
      const { minDate } = this
      if (minDate) {
        return this.getFormattedDate(minDate)
      }
      return minDate
    },
    max() {
      const { maxDate } = this
      if (maxDate) {
        return this.getFormattedDate(maxDate)
      }
      return maxDate
    },
    taskDate() {
      const { date } = this
      if (date) {
        return this.getFormattedDate(date)
      }
      return ''
    },
    tid() {
      return this.task.id
    }
  },
  data() {
    return {
      loading: false,
      theDate: null,
      showCalendar: false
    }
  },
  methods: {
    ...mapActions(['updateProjectTask']),
    getFormattedDate(value) {
      if (!value) {
        return null
      }
      return getFormattedLocaleDate(value, this.shortFormat, this.locale)
    },
    async save(date) {
      if (date === this.date) {
        return
      }
      this.loading = true
      try {
        await this.updateProjectTask({
          tid: this.tid,
          data: {
            [this.name]: date
          },
          keys: [this.name],
          updateListItem: true
        })
        this.sheet = false
        this.loading = false
      } catch (error) {}
    },
    clear() {
      this.save(null)
    }
  },
  components: {
    BaseIcon,
    TaskDialogDetailsInformationsRow,
    DatePicker: VuePersianDatetimePicker
  }
}
</script>
