<template>
  <v-container fluid>
    <task-dialog-details-informations-row
      :add-button-text="$t('common.add_color_label')"
      :label="$t('common.color_label', 2)"
      @row-clicked="sheet = true"
      :text="currentLabel"
      :editable="editable"
      :loading="loading"
      icon="swatchbook"
    />
    <base-inline-form
      :value.sync="sheet"
      :title="$t('common.list')"
      card-text-class-name="px-0"
    >
      <div
        class="
          d-flex
          text-start
          fill-height
          flex-column
          align-content-space-between
        "
      >
        <base-list
          :mandatory="true"
          :value="taskLabel"
          :items="labelItems"
          @value-changed="save"
        >
          <template v-slot:listitemstart="{ item }">
            <v-list-item-avatar
              size="40"
              :color="`#${item.color_code}`"
              class="d-flex align-center justify-center"
              :class="{ 'border-secondary': item.id === 0 }"
            >
              <base-icon
                width="24"
                height="24"
                name="times"
                color="secondary.darken2"
                v-if="item.id === null"
              />
            </v-list-item-avatar>
          </template>
        </base-list>
      </div>
    </base-inline-form>
  </v-container>
</template>
<script>
import BaseList from '@/components/BaseList'
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import BaseInlineForm from '@/components/BaseInlineForm'
import TaskDialogDetailsInformationsRow from '@/components/task/TaskDialogDetailsInformationsRow'
export default {
  props: {
    label: {
      required: true
    },
    editable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(['task', 'projectLabels']),
    tid() {
      return this.task.id
    },
    currentLabel() {
      const label = this.labelItems.find((l) => l.id === this.label)
      return label ? label.title : ''
    },
    labelItems() {
      return [
        {
          color_code: 'F5F5F5',
          title: this.$i18n.t('common.no_label'),
          id: null
        },
        ...(this.projectLabels ? Object.values(this.projectLabels) : [])
      ]
    }
  },
  data() {
    return {
      taskLabel: this.label,
      loading: false,
      sheet: false
    }
  },
  methods: {
    ...mapActions(['updateProjectTask']),
    async save($event) {
      const label = $event === 0 ? null : $event
      if (label === this.taskLabel) {
        return
      }
      this.sheet = false
      try {
        this.loading = true
        await this.updateProjectTask({
          tid: this.tid,
          data: {
            label_id: label
          },
          keys: ['label'],
          updateListItem: true
        })
      } catch (error) {}
      this.loading = false
    }
  },
  watch: {
    label(newValue) {
      this.taskLabel = newValue
    }
  },
  components: {
    BaseIcon,
    BaseList,
    BaseInlineForm,
    TaskDialogDetailsInformationsRow
  }
}
</script>
