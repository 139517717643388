<template>
  <v-dialog
    scrollable
    :value="dialog"
    @click:outside="goBack"
    v-bind="responsiveDialogProps"
    transition="slide-x-transition"
    content-class="large-level-one"
  >
    <profile-overview v-if="user" @go-back-clicked="goBack" />
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileOverview from '@/components/user/ProfileOverview'
export default {
  computed: {
    ...mapGetters(['user', 'responsiveDialogProps'])
  },
  data() {
    return {
      dialog: true,
      prevRoute: null
    }
  },
  methods: {
    goBack() {
      const { prevRoute } = this
      const path = prevRoute || '/'
      this.$router.push({ path })
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.path
    })
  },
  components: {
    ProfileOverview
  }
}
</script>
