<template>
  <base-icon width="82" height="16" name="t-large-logo" viewBox="0 0 150 31" />
</template>

<script>
import BaseIcon from '@/components/BaseIcon'

export default {
  components: {
    BaseIcon
  }
}
</script>
