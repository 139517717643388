<template>
  <div class="d-flex flex-column">
    <div class="px-3 py-1 white">
      <v-text-field
        dense
        outlined
        hide-details
        v-model="search"
        :label="$t('form.placeholders.search_tags')"
      />
    </div>
    <base-list
      :value="tag"
      :filterable="false"
      :multiple="multiple"
      :mandatory="mandatory"
      :items="filteredTags"
      :hasEmptyState="false"
      @value-changed="handleTagsChange"
      emptyListText="search_and_filter.results.not_found.tags"
    >
      <template v-slot:listitemstart>
        <v-list-item-avatar
          size="24"
          class="justify-center"
          color="secondary lighten-2"
        >
          <base-icon name="tag" width="13" height="13" color="primary" />
        </v-list-item-avatar>
      </template>
      <template v-slot:listend>
        <v-list-item
          v-if="filteredTags.length === 0 && search && !loading"
          @click="handleClickOnAddTag"
        >
          <v-list-item-avatar
            width="24"
            min-width="24"
            class="pa-0 ma-0"
            @click="dialog = true"
          >
            <v-btn
              fab
              x-small
              outlined
              width="24"
              height="24"
              color="secondary darken-2"
            >
              <span class="subtitle-1">+</span>
            </v-btn>
          </v-list-item-avatar>
          <v-list-item-content
            @click="dialog = true"
            class="cursor pointer ps-2"
          >
            <v-list-item-title>
              <span class="ms-1 body-2 secondary--text text--darken-2"
                >{{ $t('common.add') }} {{ search }}</span
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </base-list>
    <base-loading v-if="loading" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BaseIcon from './BaseIcon'
import BaseList from './BaseList'
import BaseLoading from './BaseLoading'
export default {
  components: { BaseIcon, BaseList, BaseLoading },
  props: {
    pid: {
      type: String,
      required: true
    },
    tag: {
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    filteredTags() {
      if (!this.search) {
        return this.tags
      }
      return this.tags.filter((tag) =>
        tag.title.toLowerCase().includes(this.search.toLowerCase())
      )
    }
  },
  data() {
    return {
      search: null,
      loading: false
    }
  },
  methods: {
    ...mapActions(['addProjectTag']),
    reset() {
      this.search = ''
    },
    handleTagsChange($event) {
      this.$emit('update:tag', $event)
    },
    async handleClickOnAddTag() {
      this.loading = true
      try {
        await this.addProjectTag({
          pid: this.pid,
          data: {
            title: this.search
          }
        })
        const newTag = this.tags[this.tags.length - 1]
        this.handleTagsChange([...this.tag, newTag.id])
      } catch (error) {}
      this.loading = false
    }
  }
}
</script>
