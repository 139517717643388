<template>
  <v-list-group
    append-icon=""
    :value="groupValue"
    :disabled="!projects.length"
    active-class="info--text"
  >
    <template v-slot:activator>
      <v-list-item-icon class="me-4">
        <base-icon
          width="16"
          name="layer-group"
          color="secondary.darken2"
          className="d-flex align-center"
        />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="body-2" v-text="$t('common.project_plural')" />
      </v-list-item-content>
      <v-list-item-action-text @click.stop="showCreateProjectDialog" v-if="createAllowed">
        <base-icon
          width="12"
          name="plus-circle"
          color="secondary.darken2"
          className="d-flex align-center"
        />
      </v-list-item-action-text>
      <v-list-item-action style="min-width: 14px" v-if="projects.length">
        <base-icon
          width="12"
          name="sort"
          color="secondary.darken2"
          className="d-flex align-center"
        />
      </v-list-item-action>
    </template>
    <template v-if="projects.length">
      <v-list-group
        no-action
        sub-group
        :key="item.id"
        prepend-icon=""
        :value="item.active"
        v-for="item in items"
      >
        <template v-slot:activator>
          <v-list-item class="px-0" :value="item.id">
            <v-list-item-content>
              <v-list-item-title class="body-2 ms-8" v-text="item.title" />
            </v-list-item-content>
            <v-list-item-action class="justify-end">
              <base-icon
                width="16"
                color="secondary.darken2"
                className="d-flex align-center"
                :name="item.active ? 'angle-up' : 'angle-down'"
              />
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-list-item
          link
          class="ps-17"
          :key="projectItem"
          v-for="projectItem in projectItems"
          :to="getPath(projectItem.toLowerCase(), item.id)"
        >
          <v-list-item-title v-text="$t(`common.${projectItem}`)" class="body-2" />
        </v-list-item>
      </v-list-group>
    </template>
    <v-list-item class="ps-14" v-else value="not-found">
      <v-list-item-content>
        <v-list-item-title
          class="body-2 secondary--text text--darken-2"
          v-text="$t('empty_states.items')"
        />
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
import { mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
const projectItems = ['task_plural', 'timelogs', 'settings']
export default {
  props: {
    projects: {
      type: Array,
      required: true
    },
    query: {
      required: true
    },
    createAllowed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    groupValue() {
      if (!this.projects.length || (this.query.length && this.projects.length)) {
        return true
      }
      return this.getActiveProject()
    },
    items() {
      return this.projects.map(project => {
        return {
          ...project,
          active: project.id === this.$route.params.view
        }
      })
    }
  },
  data() {
    return {
      projectItems
    }
  },
  methods: {
    ...mapActions(['showCreateProjectDialog']),
    getActiveProject() {
      const { pid } = this.$route.params
      return pid ? [pid] : null
    },
    getPath(name, pid) {
      if (name === 'task_plural') {
        return {
          name: 'board',
          params: { pid, view: 'kanban' }
        }
      }
      if (name === 'timelogs') {
        return {
          path: `/projects/${pid}/timelogs`
        }
      }
      if (name === 'settings') {
        return {
          path: `/projects/${pid}/settings`
        }
      }
      return ''
    }
  },
  components: {
    BaseIcon
  }
}
</script>
