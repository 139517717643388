<template>
  <div class="text-no-wrap tags" v-if="tags.length">
    <v-chip
      x-small
      :key="i"
      color="blue lighten-5"
      class="subtitle-2 blue--text"
      :class="{ 'tag me-1': i < tags.length }"
      v-for="(tag, i) in tags.slice(0, tagNumberLimit)"
    >
      {{ shortenString(tag.title, tagCharacterLimit) }}
    </v-chip>
    <span
      class="body-2 secondary--text text--darken-2"
      v-if="tags.length > tagNumberLimit"
    >
      {{
        $t('common.plus_n_more', {
          n: getLocalizedNumber(tags.length - tagNumberLimit)
        })
      }}
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagCharacterLimit: 8,
      tagNumberLimit: 3
    }
  },
  props: {
    tags: {
      type: Array,
      required: true
    }
  }
}
</script>
