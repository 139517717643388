import { fetchOrganizations } from '@/api/organizations'
import { SET_ORGANIZATIONS } from '../mutation-types'

const state = {
  organizations: null
}

const mutations = {
  [SET_ORGANIZATIONS](state, organizations) {
    state.organizations = organizations
  }
}

const actions = {
  async getOrganizations({ commit }, params) {
    commit(SET_ORGANIZATIONS, null)
    const { data } = await fetchOrganizations(params)
    commit(SET_ORGANIZATIONS, data.data)
  }
}

const getters = {
  organizations: state => state.organizations
}

export default {
  mutations,
  actions,
  getters,
  state
}
