import {
  SET_NOTIFICATION,
  SET_NOTIFICATIONS,
  ADD_NOTIFICATIONS,
  SET_ALL_NOTIFICATIONS,
  SET_UNREAD_NOTIFICATION_COUNT
} from '../mutation-types'
import {
  fetchNotifications,
  fetchUnreadNotificationCount,
  createNotificationReadStatus,
  createAllNotificationsReadStatus
} from '@/api/notifications'
const state = {
  notifications: null,
  unreadNotificationCount: 0
}

const getters = {
  notifications: state => state.notifications,
  unreadNotificationCount: state => state.unreadNotificationCount
}

const actions = {
  async getNotifications({ commit }, params) {
    commit(SET_NOTIFICATIONS, null)
    const { data } = await fetchNotifications(params)
    commit(SET_NOTIFICATIONS, data.data)
  },
  async getUnreadNotificationCount({ commit }) {
    const { data } = await fetchUnreadNotificationCount()
    commit(SET_UNREAD_NOTIFICATION_COUNT, data.data.count)
  },
  async addNotifications({ commit }, params) {
    const { data } = await fetchNotifications(params)
    commit(ADD_NOTIFICATIONS, data.data)
  },
  async markNotificationAsRead({ commit }, nid) {
    await createNotificationReadStatus(nid)
    commit(SET_NOTIFICATION, { nid, key: 'read', value: true })
    commit(SET_UNREAD_NOTIFICATION_COUNT, state.unreadNotificationCount - 1)
  },
  async markAllNotificationsAsRead({ commit }) {
    await createAllNotificationsReadStatus()
    commit(SET_UNREAD_NOTIFICATION_COUNT, 0)
    commit(SET_ALL_NOTIFICATIONS, { key: 'read', value: true })
  },
  updateNotfications({ commit }, newNotifications) {
    commit(SET_NOTIFICATIONS, newNotifications)
  },
  updateUnreadNotficationsTotal({ commit }, total) {
    commit(SET_UNREAD_NOTIFICATION_COUNT, total)
  }
}

const mutations = {
  [SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications
  },
  [SET_ALL_NOTIFICATIONS](state, { key, value }) {
    state.notifications.records = state.notifications.records.map(n => {
      return {
        ...n,
        [key]: value
      }
    })
  },
  [SET_NOTIFICATION](state, { nid, key, value }) {
    const index = state.notifications.records.findIndex(n => n.id === nid)
    if (index < 0) {
      return
    }
    state.notifications.records[index][key] = value
  },
  [ADD_NOTIFICATIONS](state, notifications) {
    state.notifications.offset_token = notifications.offset_token
    state.notifications.records = [
      ...state.notifications.records,
      ...notifications.records
    ]
  },
  [SET_UNREAD_NOTIFICATION_COUNT](state, unreadNotificationCount) {
    state.unreadNotificationCount = unreadNotificationCount
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
