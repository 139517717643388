<template>
  <fragment>
    <v-list-item @click="openDialog">
      <v-list-item-icon class="me-2">
        <base-icon name="trash-alt" color="error" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          class="text-h5 d-flex justify-space-between error--text"
          v-text="$t('page.profile_setting.account.delete_account.title')"
        />
      </v-list-item-content>
    </v-list-item>
    <base-dialog
      card-color="white"
      :show-save-btn="true"
      :save-btn-text="$t('common.skip')"
      :dialog.sync="transferOwnershipDialog"
      @save-clicked="confirmationDialog = true"
      :title="
        $t(
          'page.profile_setting.account.delete_account.transfer_ownership.title'
        )
      "
    >
      <section>
        <p
          class="px-4 body-1"
          v-text="
            $t(
              'page.profile_setting.account.delete_account.transfer_ownership.description'
            )
          "
        />
        <v-list>
          <v-list-item
            :ripple="false"
            :key="`${index}-${item.id}`"
            @click="handleClick(item.url)"
            v-for="(item, index) in items"
          >
            <v-list-item-avatar size="32">
              <base-avatar
                :size="32"
                :title="item.title"
                :image="item.avatar ? item.avatar.medium : ''"
              />
            </v-list-item-avatar>
            <v-list-item-content class="info--text">
              <v-list-item-title
                class="body-1 d-flex justify-space-between"
                v-text="item.title"
              />
              <v-list-item-subtitle
                v-if="item.subdomain"
                v-text="item.subdomain"
                class="body-2 d-flex justify-space-between"
              />
            </v-list-item-content>
            <v-list-item-action>
              <base-angle-icon
                width="20"
                height="20"
                fill="secondary.darken2"
                class="flip-horizontal"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </section>
    </base-dialog>
    <base-dialog
      card-color="white"
      :loading="loading"
      :show-save-btn="true"
      :dialog.sync="reasonDialog"
      @save-clicked="handleDelete"
      :save-btn-text="$t('common.continue')"
      :disable-save-btn="reason === null || !formIsValid"
      :title="$t('page.profile_setting.account.delete_account.title')"
    >
      <section
        class="pa-4 body-1"
        v-html="
          $t(
            'page.profile_setting.account.delete_account.confirmation.description'
          )
        "
      />
      <section class="pa-4">
        <v-form v-model="formIsValid">
          <base-password
            :value.sync="password"
            :validate-on-blur="false"
            :rules="getPasswordRules()"
            :label="$t('form.labels.password')"
          />
        </v-form>
        <p
          class="body-1 info--text"
          v-html="
            $t(
              'page.profile_setting.account.delete_account.confirmation.reason.title'
            )
          "
        />
        <v-select
          dense
          outlined
          class="body-1"
          :items="reasons"
          v-model="reason"
        >
          <template v-slot:selection="{ item }">
            {{
              $t(
                `page.profile_setting.account.delete_account.confirmation.reason.options.${item}`
              )
            }}
          </template>
          <template v-slot:item="{ item }">
            {{
              $t(
                `page.profile_setting.account.delete_account.confirmation.reason.options.${item}`
              )
            }}
          </template>
        </v-select>
        <v-textarea
          dense
          outlined
          v-model="description"
          v-show="textAreaVisible"
          :label="$t('form.labels.description')"
        />
      </section>
    </base-dialog>
    <base-confirm-dialog
      ok-color="error base"
      cancel-color="secondary darken-2"
      @ok-clicked="openReasonDialog"
      :dialog.sync="confirmationDialog"
      :cancel-text="$t('common.cancel')"
      :ok-text="
        $t(
          'page.profile_setting.account.delete_account.transfer_ownership.warning.continue'
        )
      "
      :title="
        $t(
          'page.profile_setting.account.delete_account.transfer_ownership.warning.title'
        )
      "
      :content="
        $t(
          'page.profile_setting.account.delete_account.transfer_ownership.warning.description'
        )
      "
    />
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import { deleteUserAccount } from '@/api/users'
import BaseDialog from '@/components/BaseDialog'
import BaseAvatar from '@/components/BaseAvatar'
import BasePassword from '@/components/BasePassword'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'

export default {
  computed: {
    ...mapGetters([
      'user',
      'sessionId',
      'organization',
      'organizations',
      'organizationSubdomain'
    ]),
    textAreaVisible() {
      const reasonsWithDescription = [
        'used_another_tool',
        'too_simple',
        'other'
      ]
      return reasonsWithDescription.indexOf(this.reason) > -1
    },
    items() {
      const { user, organizations } = this
      if (!user || !organizations) {
        return []
      }
      const organization = organizations.reduce((filtered, org) => {
        const { origin } = location
        const orgUrl = origin.replace(this.organizationSubdomain, org.subdomain)
        if (org.owner.id === user.id) {
          filtered.push({
            ...org,
            url: orgUrl
          })
          const projects = org.projects.filter(
            (proj) => proj.owner.id === user.id && !proj.closed
          )
          for (let i = 0; i < projects.length; i++) {
            filtered.push({
              ...projects[i],
              url: `${orgUrl}/projects/${projects[i].id}/settings`
            })
          }
        }
        return filtered
      }, [])
      return organization
    }
  },
  data() {
    return {
      reason: null,
      password: '',
      loading: false,
      description: null,
      formIsValid: false,
      resendLoading: false,
      reasons: [
        'too_complex',
        'too_simple',
        'no_longer_used',
        'too_expensive',
        'used_another_tool',
        'other'
      ],
      transferOwnershipDialog: false,
      confirmationDialog: false,
      reasonDialog: false
    }
  },
  methods: {
    openDialog() {
      if (this.items.length) {
        this.transferOwnershipDialog = true
        return
      }
      this.reasonDialog = true
    },
    openReasonDialog() {
      this.confirmationDialog = false
      this.reasonDialog = true
    },
    async handleDelete() {
      this.loading = true
      try {
        await deleteUserAccount({
          reason: this.reason,
          auth_type: 'password',
          auth_token: this.password,
          description: this.description
        })
        this.$router.push({ path: '/account-deleted' })
      } catch (error) {}
      this.loading = false
    },
    handleClick(url) {
      window.open(url)
    }
  },
  watch: {
    reason() {
      this.description = null
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseAvatar,
    BasePassword,
    BaseAngleIcon,
    BaseConfirmDialog
  }
}
</script>
