<template>
  <unicon
    :width="width"
    :height="height"
    :fill="fillColor"
    :name="`${name}-${direction}`"
  />
</template>

<script>
import { getRef } from '@/utils/helpers'
export default {
  props: {
    fill: {
      type: String,
      default: 'secondary.darken2'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    },
    name: {
      type: String,
      default: 'angle'
    },
    flipped: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    direction() {
      const rtlDir = this.flipped ? 'left' : 'right'
      const ltrDir = this.flipped ? 'right' : 'left'
      return this.$vuetify.rtl ? rtlDir : ltrDir
    },
    fillColor() {
      const { fill } = this
      const { theme } = this.$vuetify
      const themeType = theme.isDark ? 'dark' : 'light'
      return `${getRef(theme.themes[themeType], fill)}`
    }
  }
}
</script>
