<template>
  <task-dialog-details-add-button
    v-if="!editing"
    avatarWidth="16"
    :loading="loading"
    @add-clicked="editing = true"
    :text="$t('common.add_item')"
  />
  <v-list-item dense v-else>
    <v-list-item-content>
      <v-form @submit.prevent="handleBlur" class="d-flex align-center">
        <v-row align="center" no-gutters>
          <v-checkbox
            small
            hide-details
            class="mt-0"
            :ripple="false"
          ></v-checkbox>
          <v-text-field
            background-color="secondary lighten-2"
            class="silent body-2"
            @blur="handleBlur"
            v-model="title"
            hide-details
            autofocus
            dense
            solo
            flat
          />
        </v-row>
      </v-form>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import TaskDialogDetailsAddButton from './TaskDialogDetailsAddButton.vue'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: { TaskDialogDetailsAddButton },
  data() {
    return {
      title: '',
      editing: false
    }
  },
  methods: {
    handleBlur() {
      const { title } = this
      this.editing = false
      if (!title.length) {
        return
      }
      this.$emit('option-added', title)
      this.title = ''
    }
  }
}
</script>
