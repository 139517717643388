<template>
  <fragment>
    <base-pending-members-list
      :loading="loading"
      :members="organizationPendingUsers"
      @action-clicked="handleClickOnPendingMemberAction"
    />
    <base-members-list-with-actions
      @action-clicked="openDialog"
      :members="organizationApprovedUsers"
    >
      <template v-slot:actions="scope">
        <v-btn icon @click="scope.next('delete')">
          <base-icon
            width="24"
            height="24"
            name="trash-alt"
            color="secondary.base"
          />
        </v-btn>
      </template>
    </base-members-list-with-actions>
    <base-remove-member-confiramtion-dialog
      v-if="member"
      :member="member"
      :loading="loading"
      :dialog.sync="dialog"
      @ok-clicked="deleteMember"
    />
    <organization-settings-list-members-settings-add-member />
  </fragment>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapGetters, mapActions } from 'vuex'
import BasePendingMembersList from '@/components/BasePendingMembersList'
import { createOrganizationMembershipInvitation } from '@/api/organizations'
import BaseMembersListWithActions from '@/components/BaseMembersListWithActions'
import BaseRemoveMemberConfiramtionDialog from '@/components/BaseRemoveMemberConfiramtionDialog'
import OrganizationSettingsListMembersSettingsAddMember from '@/components/organization/OrganizationSettingsListMembersSettingsAddMember'
export default {
  computed: {
    ...mapGetters(['organizationPendingUsers', 'organizationApprovedUsers'])
  },
  data() {
    return {
      member: null,
      dialog: false,
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'displaySuccessMessage',
      'addOrganizationMembers',
      'deleteOrganizationMemberships'
    ]),
    openDialog({ member }) {
      this.dialog = true
      this.member = member
    },
    async deleteMember() {
      this.loading = true
      try {
        await this.deleteOrganizationMemberships({
          user_ids: [this.member.id]
        })
      } catch (error) {}
      this.loading = false
      this.dialog = false
    },
    async resendInvitation() {
      this.loading = true
      try {
        const { data } = await createOrganizationMembershipInvitation(
          this.member.membership_id
        )
        this.displaySuccessMessage(data.data.message)
      } catch (error) {}
      this.loading = false
    },
    handleClickOnPendingMemberAction({ member, e }) {
      this.member = member
      if (e === 'delete') {
        return this.deleteMember()
      }
      this.resendInvitation()
    }
  },
  components: {
    BaseIcon,
    BasePendingMembersList,
    BaseMembersListWithActions,
    BaseRemoveMemberConfiramtionDialog,
    OrganizationSettingsListMembersSettingsAddMember
  }
}
</script>
