<template>
  <v-list-item :ripple="false" :value="task.id">
    <template v-slot:default="{ active }">
      <v-list-item-content>
        <v-list-item-subtitle class="body-2 secondary--text text--darken-2">
          {{
            $t('page.task_dependencies_selection.task_board_and_list', {
              board: boardTitle,
              list: listTitle
            })
          }}
        </v-list-item-subtitle>
        <v-list-item-title class="body-1">
          <strong>#{{ task.number }}</strong> {{ task.title }}
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="task.starting_at || task.due_at"
          class="body-2 secondary--text text--darken-2"
        >
          <template v-if="task.starting_at && task.due_at">
            <base-date-time
              :date="task.starting_at"
              format="long"
            />
            <span class="mx-1">{{ $vuetify.rtl ? '&lt;' : '&gt;' }} </span>
            <base-date-time
              :date="task.due_at"
              format="long"
            />
          </template>
          <template v-else>
            {{ $t(`page.task_dependencies_selection.task_${task.starting_at ? 'start' : 'due'}_on`) }}
            <base-date-time
              :date="task.starting_at ? task.starting_at : task.due_at"
              format="long"
            />
          </template>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="active">
        <base-icon name="check" color="primary" />
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseDateTime from '@/components/BaseDateTime'
export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['projectLists', 'projectBoards']),
    list() {
      if (!this.projectLists) {
        return null
      }
      return this.projectLists[this.task.list.id]
    },
    listTitle() {
      return this.list ? this.list.title : ''
    },
    board() {
      const { list } = this
      return list
        ? this.projectBoards[list.board.id]
        : undefined
    },
    boardTitle() {
      const { board } = this
      return board ? board.title : ''
    }
  },
  components: {
    BaseIcon,
    BaseDateTime
  }
}
</script>
