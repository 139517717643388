<template>
  <v-card class="d-flex flex-column" height="100%" flat tile>
    <v-card-title
      class="
        body-1
        d-flex
        flex-grow-0
        align-center
        info--text
        text--darken-4
      "
    >
      <base-icon className="me-2" name="t-google-calendar" />
      {{ $t('page.project_settings.integrations.google_calendar.title') }}
    </v-card-title>
    <v-card-text class="flex-grow-1">
      <p
        class="body-1"
        v-text="
          $t(
            'page.project_settings.integrations.google_calendar.content.paragraph_one'
          )
        "
      />
      <p
        class="body-1"
        v-text="
          $t(
            'page.project_settings.integrations.google_calendar.content.paragraph_two'
          )
        "
      />
    </v-card-text>
    <v-card-actions class="flex-grow-0 py-6" v-if="calendarIntegration">
      <v-btn
        block
        depressed
        color="primary"
        @click="connect"
        :loading="loading"
        :disabled="loading"
        v-if="!calendarIntegration.authorized"
        v-text="$t('page.project_settings.integrations.connect')"
      />
      <template v-else>
        <v-btn
          block
          depressed
          color="error"
          :loading="loading"
          :disabled="loading"
          @click="dialog = true"
          v-if="calendarIntegration.enabled"
          v-text="$t('page.project_settings.integrations.disable')"
        />
        <v-btn
          block
          depressed
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="toggleStatus('enable')"
          v-show="!calendarIntegration.enabled"
          v-text="$t('page.project_settings.integrations.enable')"
        />
      </template>
    </v-card-actions>
    <base-confirm-dialog
      :loading="loading"
      :dialog.sync="dialog"
      ok-color="error base"
      cancel-color="secondary darken-2"
      :ok-text="$t('common.disable')"
      :cancel-text="$t('common.cancel')"
      @ok-clicked="toggleStatus('disable')"
      :title="
        $t('page.project_settings.integrations.disable_confirmation.title')
      "
    >
      <section
        v-html="
          $t(
            'page.project_settings.integrations.disable_confirmation.content',
            {
              provider: $t(
                'page.project_settings.integrations.google_calendar.title'
              ),
              interpolation: { escapeValue: false }
            }
          )
        "
      />
    </base-confirm-dialog>
  </v-card>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapGetters, mapActions } from 'vuex'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'

export default {
  computed: {
    ...mapGetters([
      'projectIntegrations',
      'organizationSubdomain',
      'googleCalendarAuthUri',
      'googleCalendarAccessToken'
    ]),
    calendarIntegration() {
      const { projectIntegrations } = this
      if (!projectIntegrations) {
        return null
      }
      return (
        projectIntegrations.calendar
      )
    },
    calendarRedirectUri() {
      const path = 'google-calendar-auth-redirect'
      const { protocol, port } = location
      if (process.env.NODE_ENV === 'development') {
        return `${protocol}//localhost:${port}/${path}`
      }
      const url = location.origin.replace(`${this.organizationSubdomain}.`, '')
      return `${url}/${path}`
    },
    pid() {
      return this.$route.params.pid
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      connected: false
    }
  },
  methods: {
    ...mapActions(['getGoogleCalendarToken', 'updateProjectIntegrationStatus']),
    toggleLoading() {
      this.loading = !this.loading
    },
    async connect() {
      this.toggleLoading()
      try {
        await this.getGoogleCalendarToken({
          redirect_uri: this.calendarRedirectUri
        })
        if (this.googleCalendarAccessToken) {
          return
        }
        window.open(this.googleCalendarAuthUri, '_blank')
      } catch (error) {}
      this.toggleLoading()
    },
    async toggleStatus(status = 'disable') {
      this.toggleLoading()
      this.dialog = false
      try {
        await this.updateProjectIntegrationStatus({
          pid: this.$route.params.pid,
          iid: 'calendar',
          status
        })
      } catch (error) {}
      this.toggleLoading()
    }
  },
  watch: {
    googleCalendarAccessToken(newValue) {
      this.connected = newValue !== null
    }
  },
  components: {
    BaseIcon,
    BaseConfirmDialog
  }
}
</script>
