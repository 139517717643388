<template>
  <v-form @submit.prevent="save" :style="`min-height:${minHeight}`">
    <div class="d-flex flex-row align-center mb-3">
      <slot name="prependExtra" />
      <v-text-field
        dense
        outlined
        type="text"
        hide-details
        :label="label"
        v-model="input"
        class="body-2 white flex-grow-1"
        :readonly="!editing && !fixedActions"
      >
        <template slot="append" v-if="!fixedActions">
          <slot name="appendExtra" />
          <div
            v-if="!editing"
            style="margin-top: 2px"
            @click="handleEditClicked"
            class="cursor pointer ms-2"
          >
            <base-icon name="pen" color="secondary.darken2" />
          </div>
        </template>
      </v-text-field>
    </div>
    <v-expand-transition>
      <div v-show="editing">
        <base-inline-edit-actions
          :loading="loading"
          @save-clicked="save"
          @cancel-clicked="setInput"
          :button-disabled="buttonDisabled || buttonHidden"
          ><slot
        /></base-inline-edit-actions>
      </div>
    </v-expand-transition>
    <div class="d-flex justify-space-between" v-if="fixedActions">
      <v-btn
        text
        outlined
        depressed
        class="me-2"
        @click="cancel"
        :disabled="loading"
        color="secondary darken-2"
        v-text="$t('common.cancel')"
      />
      <v-btn
        depressed
        width="38"
        @click="save"
        color="primary"
        :loading="loading"
        v-text="$t('common.save')"
      />
    </div>
  </v-form>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import BaseInlineEditActions from '@/components/BaseInlineEditActions'
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    minHeight: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    initialValue: {
      type: String,
      default: ''
    },
    fixedActions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonHidden() {
      return this.input === this.initialValue
    },
    buttonDisabled() {
      return this.input.length === 0
    }
  },
  data() {
    return {
      input: '',
      editing: false
    }
  },
  methods: {
    save() {
      if (this.buttonDisabled) {
        return
      }
      return this.$emit('save-clicked', this.input)
    },
    setInput() {
      this.input = this.initialValue
      this.editing = false
    },
    cancel() {
      this.setInput()
      this.$emit('cancel-clicked')
    },
    handleEditClicked() {
      this.$emit('edit-clicked')
      this.editing = true
    }
  },
  mounted() {
    this.setInput()
  },
  watch: {
    initialValue() {
      this.setInput()
    },
    loading(newValue) {
      if (!newValue) {
        this.editing = false
      }
    }
  },
  components: {
    BaseIcon,
    BaseInlineEditActions
  }
}
</script>
