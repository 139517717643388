<template>
  <base-inline-form
    :value="value"
    card-text-class-name="px-4"
    :title="$t('common.new_task')"
    @closed="$emit('update:value', false)"
  >
    <v-form @submit.prevent="handleSubmit">
      <v-text-field
        flat
        dense
        outlined
        required
        autofocus
        hide-details
        :value="input"
        :label="$t('common.task_title')"
        @input="$emit('update:input', $event)"
      />
    </v-form>
    <section>
      <base-select-to-bottom-sheet
        :value="list"
        @value-changed="$emit('update:list', $event)"
        :items="
          lists.map((list) => ({
            id: list.id,
            text: list.title,
            value: list.id
          }))
        "
      />
    </section>
    <base-inline-form-actions
      :loading="loading"
      @ok-clicked="handleSubmit"
      :ok-text="$t('common.done')"
      @cancel-clicked="$emit('cancel-clicked')"
    />
  </base-inline-form>
</template>

<script>
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseInlineFormActions from '@/components/BaseInlineFormActions'
import BaseSelectToBottomSheet from '@/components/BaseSelectToBottomSheet'

export default {
  props: {
    input: {
      type: String,
      required: true
    },
    list: {
      type: String,
      required: true
    },
    lists: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleSubmit() {
      if (!this.input || !this.input.length || !this.list) {
        return
      }
      this.$emit('done-clicked')
    }
  },
  components: {
    BaseInlineForm,
    BaseInlineFormActions,
    BaseSelectToBottomSheet
  }
}
</script>
