import request from '@/api/axios'

export const fetchLists = (pid, params) =>
  request({
    url: `/orgs/#oid/projects/${pid}/lists`,
    method: 'get',
    params
  })

export const createList = (pid, data) =>
  request({
    url: `/orgs/#oid/projects/${pid}/lists`,
    method: 'post',
    data
  })

export const createListStatus = (lid, path) =>
  request({
    url: `/orgs/#oid/lists/${lid}/${path}`,
    method: 'post'
  })

export const updateList = (lid, data) =>
  request({
    url: `/orgs/#oid/lists/${lid}`,
    method: 'patch',
    data
  })

export const moveList = (lid, data) =>
  request({
    url: `/orgs/#oid/lists/${lid}/move`,
    method: 'post',
    data
  })

export const copyList = (lid, data) =>
  request({
    url: `/orgs/#oid/lists/${lid}/copy`,
    method: 'post',
    data
  })
