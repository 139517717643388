<template>
  <div class="secondary lighten-5 fill-height">
    <div v-if="lists.length" class="fill-height d-flex flex-column justify-content-start overflow-auto">
      <div v-for="list in lists" :key="list.id" class="list-table-wrapper">
        <task-list-table-list
          class="list-container"
          :list="list"
          :id="`list_${list.id}`"
          :create-task-visible="createTaskVisible"
          @move-list-clicked="$emit('move-list-clicked', list)"
          @copy-list-clicked="$emit('copy-list-clicked', list)"
          @watch-list-clicked="$emit('watch-list-clicked', list)"
          @project-task-moved="$emit('project-task-moved', $event)"
          @rename-list-clicked="$emit('rename-list-clicked', list)"
          @archive-list-clicked="$emit('archive-list-clicked', list)"
          @create-task-clicked="$emit('create-task-clicked', list)"
        />
      </div>
      <div class="list-table-wrapper" v-if="createListVisible">
        <task-list-table-list
          class="list-container"
          :is-dummy="true"
          :create-task-visible="createTaskVisible"
          @create-list-clicked="$emit('create-list-clicked')"
        />
      </div>
    </div>
    <div class="fill-height d-flex align-center justify-center" v-else>
      <!-- @todo: Move this to new component, remove from TaskListKanban as well -->
      <div class="d-flex flex-column align-center">
        <v-img
          width="168"
          height="148"
          src="../../assets/img/illustrations/project-empty-state.svg"
        />
        <h4 class="mt-6 h4-text secondary--text text--darken-2">
          {{ $t('page.kanban.board.empty_board') }}
        </h4>
        <v-btn
          depressed
          class="mt-6"
          color="primary"
          v-if="createListVisible"
          @click="$emit('add-list-clicked')"
        >
          + {{ $t('common.add_list') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskListTableList from '@/components/project/TaskListTableList'

export default {
  props: {
    lists: {
      type: Array,
      required: true
    },
    createListVisible: {
      type: Boolean,
      required: true
    },
    createTaskVisible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  components: {
    TaskListTableList
  }
}
</script>
