import request from '@/api/axios'
export const fetchGoogleDriveAccessToken = params =>
  request({
    url: '/orgs/#oid/oauth/drive',
    method: 'get',
    params
  })

export const createGoogleDriveAccessToken = (oid, data) =>
  request({
    url: `/orgs/${oid}/oauth/drive`,
    method: 'post',
    data
  })

export const fetchGoogleCalendarToken = params =>
  request({
    url: '/orgs/#oid/oauth/calendar',
    method: 'get',
    params
  })

export const createGoogleCalendarToken = (oid, data) =>
  request({
    url: `/orgs/${oid}/oauth/calendar`,
    method: 'post',
    data
  })
