<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    :show-save-btn="true"
    @go-back-clicked="goBack"
    content-class="small-level-two"
    :save-btn-text="$t('common.next')"
    @save-clicked="secondDialog = true"
    :title="$t('common.team', { count: 0 })"
    :disable-save-btn="selectedTeams.length === 0"
  >
    <settings-dialog-list-members-invite-teams
      :teams="teams"
      :project="project"
      v-if="teams && dialog"
      @teams-changed="handleTeamChange"
    />
    <base-dialog
      card-color="white"
      :dialog.sync="secondDialog"
      content-class="small-level-three"
      card-text-class="d-flex flex-column"
      :title="$t('common.member', { count: 0 })"
    >
      <settings-dialog-list-members-invite-by-link-generated-link
        :teams="teams"
        :dialog="secondDialog"
        @go-to-start="goToStart"
        :selectedTeams="selectedTeams"
        v-if="selectedTeams.length > 0"
      />
    </base-dialog>
  </base-dialog>
</template>

<script>
import BaseDialog from '@/components/BaseDialog'
import SettingsDialogListMembersInviteTeams from '@/components/project/SettingsDialogListMembersInviteTeams'
import SettingsDialogListMembersInviteByLinkGeneratedLink from '@/components/project/SettingsDialogListMembersInviteByLinkGeneratedLink'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    teams: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedTeams: [],
      secondDialog: false
    }
  },
  methods: {
    goBack() {
      this.$emit('update:dialog', false)
    },
    handleTeamChange(team) {
      this.selectedTeams = team
    },
    goToStart() {
      this.secondDialog = false
      this.goBack()
    }
  },
  watch: {
    dialog() {
      this.selectedTeams = []
    }
  },
  components: {
    BaseDialog,
    SettingsDialogListMembersInviteTeams,
    SettingsDialogListMembersInviteByLinkGeneratedLink
  }
}
</script>
