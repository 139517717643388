<template>
  <v-container class="px-3 pt-0">
    <v-scroll-y-transition hide-on-leave group>
      <v-text-field
        dense
        outlined
        readonly
        autofocus
        type="text"
        class="mb-4"
        hide-details
        :key="email"
        :value="email"
        color="secondary lighten-2"
        v-for="(email, index) in emails"
      >
        <template v-slot:append-outer>
          <v-btn icon small @click="emails.splice(index, 1)">
            <base-icon name="times" color="error" />
          </v-btn>
        </template>
      </v-text-field>
    </v-scroll-y-transition>
    <v-btn
      block
      outlined
      color="primary"
      class="justify-start"
      @click="sheet = true"
      v-text="`+ ${$t('common.add_email')}`"
    />
    <base-inline-form
      :value.sync="sheet"
      card-text-class-name="px-4"
      :title="$t('common.add_email')"
    >
      <v-form ref="form" v-model="formIsValid" @submit.prevent="addEmail">
        <v-text-field
          dense
          outlined
          autofocus
          type="text"
          v-model="input"
          :rules="emailRules"
          :label="$t('form.labels.email')"
        />
      </v-form>
      <base-inline-form-actions
        @ok-clicked="addEmail"
        @cancel-clicked="close"
        :ok-text="$t('common.done')"
      />
    </base-inline-form>
  </v-container>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseInlineFormActions from '@/components/BaseInlineFormActions'
export default {
  props: {
    invitees: {
      type: Array,
      required: true
    },
    dialog: {
      type: Boolean
    }
  },
  data() {
    return {
      input: '',
      emails: [],
      sheet: false,
      formIsValid: false
    }
  },
  computed: {
    emailRules() {
      return [
        ...this.getEmailRules(),
        (v) => this.emails.indexOf(v) < 0 || 'email is already entered!'
      ]
    }
  },
  methods: {
    close() {
      this.input = ''
      this.sheet = false
      this.$refs.form.resetValidation()
    },
    addEmail() {
      if (!this.formIsValid) {
        return
      }
      this.emails.push(this.input)
      this.close()
    }
  },
  watch: {
    emails(newValue) {
      this.$emit('update:invitees', newValue)
    },
    dialog(newValue) {
      if (!newValue) {
        this.emails = []
      }
    }
  },
  components: {
    BaseIcon,
    BaseInlineForm,
    BaseInlineFormActions
  }
}
</script>
