<template>
  <base-dialog
    :dialog="visible"
    card-color="white"
    @go-back-clicked="$emit('update:visible', false)"
    content-class="search-modal small-level-one"
  >
    <template v-slot:toolbar>
      <v-toolbar flat height="60" color="white" class="flex-grow-0">
        <v-text-field
          flat
          dense
          outlined
          height="46"
          single-line
          hide-details
          v-model="query"
          class="ma-0 pt-2"
          background-color="white"
          :placeholder="$t('form.placeholders.search_tasks')"
        >
          <template v-slot:prepend-inner class="mt-0">
            <v-btn
              icon
              x-small
              width="28"
              height="28"
              class="ma-0"
              @click="$emit('update:visible', false)"
            >
              <base-icon
                width="24"
                height="24"
                color="secondary.darken2"
                className="d-flex align-center"
                :name="!$vuetify.rtl ? 'arrow-left' : 'arrow-right'"
              />
            </v-btn>
          </template>
        </v-text-field>
      </v-toolbar>
    </template>
    <v-list flat color="transparent" style="margin-bottom: 54px">
      <search-dialog-users title="member" :option.sync="options.assignee_ids" />
      <search-dialog-date
        icon="t-start-date"
        title="common.start_date"
        toolbar-title="common.start_date"
        :to.sync="options.starting_at_to"
        :from.sync="options.starting_at_from"
      />
      <search-dialog-date
        title="common.due_date"
        toolbar-title="common.due_date"
        :to.sync="options.due_at_to"
        :from.sync="options.due_at_from"
      />
      <search-dialog-statuses :option.sync="options.status_ids" />
      <search-dialog-labels
        :withNo.sync="withNo.labels"
        :withAny.sync="withAny.labels"
        :option.sync="options.label_ids"
      />
      <search-dialog-weight
        :to.sync="options.weight_to"
        :from.sync="options.weight_from"
      />
      <search-dialog-tags
        :withNo.sync="withNo.tags"
        :withAny.sync="withAny.tags"
        :option.sync="options.tag_ids"
      />
      <search-dialog-progress
        :to.sync="options.progress_to"
        :from.sync="options.progress_from"
      />
    </v-list>
    <tasks-search-dialog-results
      :searching="searching"
      :loading-more="loading"
      :active="searchHasCriteria"
      @export-clicked="handleExport"
      @intersect-reached="onIntersect"
    />
  </base-dialog>
</template>

<script>
import BaseIcon from '../BaseIcon'
import BaseDialog from '../BaseDialog'
import { mapActions, mapGetters } from 'vuex'
import SearchDialogTags from '@/components/search/SearchDialogTags'
import SearchDialogDate from '@/components/search/SearchDialogDate'
import SearchDialogUsers from '@/components/search/SearchDialogUsers'
import SearchDialogWeight from '@/components/search/SearchDialogWeight'
import SearchDialogLabels from '@/components/search/SearchDialogLabels'
import SearchDialogProgress from '@/components/search/SearchDialogProgress'
import SearchDialogStatuses from '@/components/search/SearchDialogStatuses'
import TasksSearchDialogResults from '@/components/tasks/TasksSearchDialogResults'

export default {
  computed: {
    ...mapGetters(['tasksSearchResults']),
    pid() {
      return this.$route.params.pid
    },
    searchParams() {
      return {
        ...this.options,
        with_no: [this.withNo.labels, this.withNo.tags].filter(
          (item) => item !== null
        ),
        with_any: [this.withAny.labels, this.withAny.tags].filter(
          (item) => item !== null
        )
      }
    },
    params() {
      return {
        limit: this.limit,
        ...this.searchParams
      }
    },
    searchHasCriteria() {
      return Object.values(this.searchParams).some(
        (item) => item && item.length
      )
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: 0,
      limit: 10,
      query: null,
      loading: false,
      searching: false,
      isIntersecting: false,
      withAny: {
        labels: null,
        tags: null
      },
      withNo: {
        labels: null,
        tags: null
      },
      options: {
        q: '',
        tag_ids: [],
        list_ids: [],
        task_ids: [],
        label_ids: [],
        status_ids: [],
        due_at_to: null,
        weight_to: null,
        assignee_ids: [],
        due_at_from: null,
        progress_to: null,
        weight_from: null,
        progress_from: null,
        starting_at_to: null,
        starting_at_from: null
      }
    }
  },
  methods: {
    ...mapActions([
      'exportTasks',
      'displaySuccessMessage',
      'getTasksSearchResults',
      'addTasksSearchResults'
    ]),
    async getData() {
      this.searching = true
      try {
        await this.getTasksSearchResults({
          pid: this.pid,
          params: this.params
        })
      } catch (error) {}
      this.searching = false
    },
    async addData() {
      this.loading = true
      try {
        await this.addTasksSearchResults({
          pid: this.pid,
          params: {
            ...this.params,
            offset_token: this.tasksSearchResults
              ? this.tasksSearchResults.offset_token
              : null
          }
        })
      } catch (error) {}
      this.loading = false
    },
    async handleExport() {
      try {
        this.displaySuccessMessage(this.$i18n.t('page.timelogs.export_started'))
        await this.exportTasks({
          pid: this.pid,
          data: this.searchParams
        })
      } catch (error) {}
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting
    }
  },
  watch: {
    isIntersecting(newValue) {
      if (
        !newValue ||
        !this.tasksSearchResults ||
        !this.tasksSearchResults.offset_token ||
        this.loading
      ) {
        return
      }
      this.addData()
    },
    searchParams: {
      handler() {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.getData()
        }, 1000)
      },
      deep: true
    },
    query(newValue) {
      this.options.q = newValue
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    SearchDialogTags,
    SearchDialogDate,
    SearchDialogUsers,
    SearchDialogLabels,
    SearchDialogWeight,
    SearchDialogProgress,
    SearchDialogStatuses,
    TasksSearchDialogResults
  }
}
</script>
