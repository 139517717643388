<template>
  <search-dialog-list-item
    icon="users-alt"
    :subtitle="
      selected.length
        ? $t('search_and_filter.n_items_selected', {
            count: selected.length,
            name: $t(`common.${title}`, { count: selected.length })
          })
        : null
    "
    :title="$t(`common.${title}`, { count: 0 })"
    :toolbar-title="$t(`common.${title}`, { count: 0 })"
  >
    <base-members-list
      :members.sync="selected"
      :users="projectApprovedUsers"
      placeholder="form.placeholders.search_users"
    />
  </search-dialog-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseMembersList from '@/components/BaseMembersList'
import SearchDialogListItem from '@/components/search/SearchDialogListItem'
export default {
  props: {
    option: {
      required: true
    },
    title: {
      type: String,
      default: 'user'
    }
  },
  components: {
    BaseMembersList,
    SearchDialogListItem
  },
  computed: {
    ...mapGetters(['project', 'projectApprovedUsers'])
  },
  data() {
    return {
      selected: []
    }
  },
  watch: {
    selected(newValue) {
      this.$emit('update:option', newValue)
    }
  }
}
</script>
