<template>
  <div class="list">
    <div
      class="list-header d-flex justify-space-between align-center py-3 ps-4"
    >
      <h3
        class="
          text-h4
          info--text
          text-no-wrap
          overflow-hidden
          text-overflow-ellipsis
        "
      >
        <v-btn
          v-if="isDummy && !list"
          text
          plain
          x-small
          class="text-h6"
          :ripple="false"
          color="secondary"
          @click="$emit('create-list-clicked')"
          v-text="$t('common.create_new_list')"
        />
        <template v-else>
          <span style="position: relative; top: 4px">
            <base-icon
              name="eye"
              color="primary"
              className="me-2"
              v-if="list.watched && !renaming"
            />
          </span>
          <span v-if="!renaming">{{ list.title }}</span>
          <v-text-field
            v-else
            solo
            flat
            dense
            autofocus
            height="24"
            hide-details
            class="silent"
            style="width: 260px"
            v-model="list.title"
            @keydown.enter = "handleClickOnRenameButton"
            background-color="secondary lighten-5"
          />
        </template>
      </h3>
      <template v-if="!isDummy && list">
        <task-sheet-list-actions
          :list="list"
          v-if="!renaming"
          @rename-clicked="renaming = !renaming"
          :create-task-visible="createTaskVisible"
          @move-clicked="$emit('move-list-clicked')"
          @copy-clicked="$emit('copy-list-clicked')"
          @create-task-clicked="newTaskClicked = true"
          @watch-clicked="$emit('watch-list-clicked')"
          @archive-clicked="$emit('archive-list-clicked')"
        />
        <div class="d-flex pe-4" v-else>
          <v-btn
            fab
            text
            depressed
            width="24"
            height="24"
            class="me-3"
            @click="renaming = false"
          >
            <base-icon
              width="24"
              height="24"
              name="times"
              color="secondary.base"
              className="d-flex align-center"
            />
          </v-btn>
          <v-btn
            fab
            depressed
            width="24"
            height="24"
            color="primary"
            @click="handleClickOnRenameButton"
          >
            <base-icon
              width="18"
              height="18"
              name="check"
              color="secondary.lighten5"
              className="d-flex align-center"
            />
          </v-btn>
        </div>
      </template>
    </div>
    <v-divider />
    <task-list-kanban-section
      :list="list"
      v-if="!isDummy && list"
      :new-task-clicked.sync="newTaskClicked"
      :create-task-visible="createTaskVisible"
      @task-moved="$emit('project-task-moved', $event)"
    />
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import TaskSheetListActions from '@/components/project/TaskSheetListActions'
import TaskListKanbanSection from '@/components/project/TaskListKanbanSection'

export default {
  props: {
    list: {
      type: Object
    },
    isDummy: {
      type: Boolean,
      default: false
    },
    createTaskVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      renaming: false,
      newTaskClicked: false
    }
  },
  methods: {
    handleClickOnRenameButton() {
      this.renaming = false
      this.$emit('rename-list-clicked')
    }
  },
  components: {
    BaseIcon,
    TaskSheetListActions,
    TaskListKanbanSection
  }
}
</script>
