import axios from 'axios'
import {
  requestInterceptor,
  responseInterceptorSuccess,
  responseInterceptorError
} from '@/api/interceptors'
import { mockBaseURL, adapter, timeout } from '@/api/common'

const service = axios.create({
  headers: {
    'Accept-Version': 'v2',
    'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    'Time-Zone-Offset': new Date().getTimezoneOffset() / -60
  },
  timeout,
  adapter,
  baseURL: mockBaseURL
})

service.interceptors.request.use(requestInterceptor)
service.interceptors.response.use(
  responseInterceptorSuccess,
  responseInterceptorError
)
export default service
