var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"color":_vm.overlayColor,"value":_vm.dialog,"transition":_vm.transition,"content-class":"transparent justify-center align-center elevation-0","max-width":_vm.windowSize.x * 0.8,"max-height":_vm.windowSize.y * 0.8,"overlay-color":"white","overlay-opacity":"0.92","internal-activator":""},on:{"click:outside":_vm.goBack}},[_c('v-card',{staticClass:"\n      d-flex\n      transparent\n      relative\n      fill-height\n      justify-center\n      align-center\n    ",attrs:{"width":"100%","flat":""}},[_c('v-btn',{staticClass:"fixed",class:{ 'd-none': !_vm.showCloseBtn },attrs:{"depressed":"","fab":"","fixed":"","top":"","right":"","color":"secondary","x-small":""},on:{"click":_vm.handlePreviewURL}},[_c('base-icon',{attrs:{"name":"times","width":"25","height":"25","color":"white"}})],1),(_vm.fileType === 'image')?_c('img',{ref:"img",staticClass:"d-flex",style:({
        maxWidth: '100%',
        height: 'auto'
      }),attrs:{"src":_vm.item.download_url}}):(_vm.fileType === 'video')?_c('video',{ref:"previewItem",style:({
        maxWidth: '100%',
        height: 'auto',
        maxHeight: '100%'
      }),attrs:{"controls":"","autoplay":"","poster":_vm.item.thumbnail ? _vm.item.thumbnail : ''}},[_c('source',{attrs:{"src":_vm.item.download_url,"type":!_vm.unsupportedTypes.includes(_vm.item.mime_type) ? _vm.item.mime_type : 'video/mp4'}}),_vm._v(" Your browser does not support the video tag. ")]):(_vm.fileType === 'audio')?_c('audio',{ref:"previewItem",style:({
        maxWidth: '100%',
        height: 'auto'
      }),attrs:{"controls":"","autoplay":"","poster":_vm.item.thumbnail ? _vm.item.thumbnail : ''}},[_c('source',{attrs:{"src":_vm.item.download_url,"type":_vm.item.mime_type}}),_vm._v(" Your browser does not support the audio tag. ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }