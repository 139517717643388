<template>
  <fragment>
    <v-form
      v-model="formIsValid"
      ref="newOrganizationForm"
      @submit.prevent="$emit('create-project-clicked', { title })"
      class="flex-grow-1 d-flex justify-center flex-column fill-width"
    >
      <v-text-field
        flat
        dense
        outlined
        v-model="title"
        :rules="titleRules"
        :label="$t('form.labels.project_title')"
      />
    </v-form>
    <v-btn
      block
      depressed
      color="primary"
      :loading="loading"
      :disabled="!formIsValid"
      v-text="$t('page.create_project.action')"
      @click="$emit('create-project-clicked', { title })"
    />
  </fragment>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: '',
      formIsValid: false,
      titleRules: this.getCompanyNameRules()
    }
  }
}
</script>
