<template>
  <v-card flat height="100%" color="secondary lighten-5">
    <v-card-text>
      <template v-if="userSessions">
        <h2 class="body-2 mb-3">
          {{
            $t('page.profile_setting.security.active_sessions.current_sessions')
          }}
        </h2>
        <v-card class="t-box-shadow">
          <v-card-title
            class="justify-space-between"
            v-text="`${appName} ${currentSession.platform}`"
          />
          <v-card-subtitle
            class="body-1 secondary--text text--darken-2 pb-1 pt-1"
            v-text="`${currentSession.device}, ${currentSession.platform}`"
          />
          <v-card-text
            class="body-2 secondary--text text--darken-2"
            v-text="`${currentSession.ip_address} - ${currentSession.location}`"
          />
        </v-card>
        <template v-if="otherSessions.length">
          <v-btn
            block
            depressed
            class="my-4"
            color="error"
            :disabled="loading"
            @click="deleteSession(otherSessionsIds)"
          >
            {{
              $t(
                'page.profile_setting.security.active_sessions.terminate_all_other_sessions'
              )
            }}
          </v-btn>
          <h2 class="body-2 my-4">
            {{
              $t('page.profile_setting.security.active_sessions.other_sessions')
            }}
          </h2>
          <v-card
            class="t-box-shadow mt-2"
            :key="`session_${index}`"
            @click="handleClick(session.id)"
            v-for="(session, index) in otherSessions"
          >
            <v-card-title class="justify-space-between flex-nowrap">
              <span
                class="body-1 text-no-wrap text-truncate flex-shrink-1"
                v-text="`${appName} ${session.platform}`"
              />
              <base-date-time
                class="body-2 secondary--text text--darken-2 flex-shrink-0"
                :date="session.created_at"
              />
            </v-card-title>
            <v-card-subtitle
              class="body-1 secondary--text text--darken-2 pb-1 pt-1"
              v-text="`${session.device}, ${session.platform}`"
            />
            <v-card-text
              class="body-2 secondary--text text--darken-2"
              v-text="`${session.ip_address} - ${session.location}`"
            />
          </v-card>
          <h3 class="body-2 secondary--text text--darken-2 text-center my-4">
            {{
              $t(
                'page.profile_setting.security.active_sessions.tap_to_terminate_session'
              )
            }}
          </h3>
        </template>
      </template>
      <base-loading v-else />
    </v-card-text>
    <base-confirm-dialog
      :loading="loading"
      :dialog.sync="dialog"
      ok-color="error base"
      cancel-color="secondary darken-2"
      :cancel-text="$t('common.cancel')"
      @ok-clicked="deleteSession([session])"
      :title="
        $t(
          'page.profile_setting.security.active_sessions.terminate_session_confirmation.title'
        )
      "
      :content="
        $t(
          'page.profile_setting.security.active_sessions.terminate_session_confirmation.description'
        )
      "
      :ok-text="
        $t(
          'page.profile_setting.security.active_sessions.terminate_session_confirmation.terminate'
        )
      "
    />
  </v-card>
</template>

<script>
import BaseDateTime from '../BaseDate'
import BaseLoading from '../BaseLoading'
import { mapActions, mapGetters } from 'vuex'
import BaseConfirmDialog from '../BaseConfirmDialog'
export default {
  computed: {
    ...mapGetters(['appName', 'userSessions', 'sessionId']),
    currentSession() {
      return this.userSessions.find((session) => session.id === this.sessionId)
    },
    otherSessions() {
      return this.userSessions.filter(
        (session) => session.id !== this.sessionId
      )
    },
    otherSessionsIds() {
      return this.otherSessions.map((session) => session.id)
    }
  },
  data() {
    return {
      session: null,
      dialog: false,
      loading: false
    }
  },
  methods: {
    ...mapActions(['getUserSessions', 'deleteUserSessions']),
    getData() {
      this.getUserSessions()
    },
    async deleteSession(ids) {
      this.loading = true
      try {
        await this.deleteUserSessions({
          ids
        })
        this.dialog = false
      } catch (error) {}
      this.loading = false
    },
    handleClick(id) {
      this.session = id
      this.dialog = true
    }
  },
  mounted() {
    this.getData()
  },
  components: {
    BaseLoading,
    BaseDateTime,
    BaseConfirmDialog
  }
}
</script>
