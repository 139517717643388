import {
  fetchGoogleCalendarToken,
  createGoogleCalendarToken,
  fetchGoogleDriveAccessToken,
  createGoogleDriveAccessToken
} from '@/api/integrations'

import {
  SET_GOOGLE_DRIVE_TOKEN_INFO,
  SET_GOOGLE_DRIVE_ACCESS_TOKEN,
  SET_GOOGLE_CALENDAR_TOKEN_INFO,
  SET_GOOGLE_CALENDAR_ACCESS_TOKEN
} from '../mutation-types'

const state = {
  driveAuthUri: null,
  driveAccessToken: null,
  googleCalendarAuthUri: null,
  googleCalendarAccessToken: null
}

const getters = {
  driveAuthUri: state => state.driveAuthUri,
  driveAccessToken: state => state.driveAccessToken,
  googleCalendarAuthUri: state => state.googleCalendarAuthUri,
  googleCalendarAccessToken: state => state.googleCalendarAccessToken
}

const mutations = {
  [SET_GOOGLE_DRIVE_TOKEN_INFO](state, driveTokenInfo) {
    state.driveAuthUri = driveTokenInfo.auth_uri
    state.driveAccessToken = driveTokenInfo.token
  },
  [SET_GOOGLE_DRIVE_ACCESS_TOKEN](state, driveAccessToken) {
    state.driveAccessToken = driveAccessToken
  },
  [SET_GOOGLE_CALENDAR_ACCESS_TOKEN](state, googleCalendarAccessToken) {
    state.googleCalendarAccessToken = googleCalendarAccessToken
  },
  [SET_GOOGLE_CALENDAR_TOKEN_INFO](state, googleCalendarTokenInfo) {
    state.googleCalendarAuthUri = googleCalendarTokenInfo.auth_uri
    state.googleCalendarAccessToken = googleCalendarTokenInfo.token
  }
}

const actions = {
  async getGoogleDriveAccessToken({ commit }, params) {
    const { data } = await fetchGoogleDriveAccessToken(params)
    commit(SET_GOOGLE_DRIVE_TOKEN_INFO, data.data)
  },
  async generateGoogleDriveAccessToken({ commit }, { data, oid }) {
    const result = await createGoogleDriveAccessToken(oid, data)
    commit(SET_GOOGLE_DRIVE_TOKEN_INFO, result.data.data)
  },
  setDriveAccessToken({ commit }, driveAccessToken) {
    commit(SET_GOOGLE_DRIVE_ACCESS_TOKEN, driveAccessToken)
  },
  async getGoogleCalendarToken({ commit }, params) {
    const { data } = await fetchGoogleCalendarToken(params)
    commit(SET_GOOGLE_CALENDAR_TOKEN_INFO, data.data)
  },
  async generateGoogleCalendarAccessToken({ commit }, { data, oid }) {
    const result = await createGoogleCalendarToken(oid, data)
    commit(SET_GOOGLE_CALENDAR_TOKEN_INFO, result.data.data)
  },
  setGoogleCalendarAccessToken({ commit }, googleCalendarAccessToken) {
    commit(SET_GOOGLE_CALENDAR_ACCESS_TOKEN, googleCalendarAccessToken)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
