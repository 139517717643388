<template>
  <fragment>
    <h3
      class="px-4 py-2 text-h5 secondary--text text--darken-2"
      v-text="$t(`page.organization_settings.permissions.${id}.subtitle`)"
    />
    <base-list
      :dense="false"
      :items="items"
      :filterable="false"
      :value.sync="form.granted"
    />
    <v-list class="pt-0">
      <v-subheader
        class="text-h5 secondary--text text--darken-2"
        v-text="$t('page.organization_settings.permissions.exceptions')"
      />
      <v-list-item @click="exceptionsDialog = true">
        <v-list-item-content>
          <v-list-item-title
            class="body-2 info--text"
            v-text="
              $t(`page.organization_settings.permissions.${exception}_allow`)
            "
          />
        </v-list-item-content>
        <v-list-item-action-text
          v-if="form.exception_ids.length"
          class="caption secondary--text"
          v-text="
            $t('common.member_with_count', {
              count: form.exception_ids.length
            })
          "
        />
        <v-list-item-action>
          <base-angle-icon
            width="24"
            height="24"
            fill="secondary.base"
            class="flip-horizontal"
          />
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <base-dialog
      card-color="white"
      :dialog.sync="exceptionsDialog"
      content-class=" small-level-three"
      card-text-class="d-flex flex-column"
      :title="$t(`page.organization_settings.permissions.${exception}_allow`)"
    >
      <base-members-list
        :avatar-size="40"
        :members.sync="form.exception_ids"
        :users="organizationApprovedUsers"
        placeholder="form.placeholders.search_members"
        emptyListText="search_and_filter.results.not_found.members"
      />
    </base-dialog>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseList from '@/components/BaseList'
import BaseDialog from '@/components/BaseDialog'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import BaseMembersList from '@/components/BaseMembersList'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    granted: {
      type: Boolean,
      required: true
    },
    exceptionIds: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['organizationApprovedUsers']),
    exception() {
      return this.form.granted ? 'never' : 'always'
    }
  },
  data() {
    return {
      resetInMotion: false,
      exceptionsDialog: false,
      form: {
        granted: this.granted,
        exception_ids: this.exceptionIds
      },
      items: [
        {
          id: false,
          title: this.$i18n.t('page.organization_settings.permissions.no_one')
        },
        {
          id: true,
          title: this.$i18n.t('page.organization_settings.permissions.everyone')
        }
      ]
    }
  },
  watch: {
    'form.granted'() {
      if (this.form.exception_ids.length) {
        this.resetInMotion = true
        this.form.exception_ids = []
      }
      this.$emit('permission-changed', { pid: this.id, data: this.form })
    },
    'form.exception_ids'() {
      if (this.resetInMotion) {
        this.resetInMotion = false
        return
      }
      this.$emit('permission-changed', { pid: this.id, data: this.form })
    }
  },
  components: {
    BaseList,
    BaseDialog,
    BaseAngleIcon,
    BaseMembersList
  }
}
</script>
