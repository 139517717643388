<template>
  <div class="d-flex flex-column justify-center">
    <v-otp-input
      separator=""
      ref="otpInput"
      :num-inputs="length"
      :is-input-num="true"
      input-classes="otp-input"
      :should-auto-focus="true"
      @on-change="handleOnChange"
      @on-complete="handleOnComplete"
    />
  </div>
</template>

<script>
import OtpInput from '@bachdgvn/vue-otp-input'
export default {
  props: {
    length: {
      type: Number,
      default: 6
    }
  },
  data() {
    return {
      code: ''
    }
  },
  methods: {
    handleOnComplete(value) {
      this.$emit('complete', value)
    },
    handleOnChange(value) {
      if (!value || value.length === 0) {
        return
      }
      this.$emit('change', value)
    },
    setInputFocus() {
      setTimeout(() => {
        const { $children: children } = this.$refs.otpInput
        if (!children.length || !children[0].$el.children.length) {
          return
        }
        children[0].$el.children[0].focus()
      }, 200)
    }
  },
  components: {
    'v-otp-input': OtpInput
  },
  mounted() {
    this.setInputFocus()
  }
}
</script>
<style lang="scss">
.otp-input {
  width: 100%;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-bottom: 1px solid var(--v-secondary-lighten1);
  text-align: center;
  &:focus {
    outline: none;
    border-color: var(--v-primary-base);
  }
  &.error {
    border: 1px solid var(--v-error-base) !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  margin: 0;
}
</style>
