<template>
  <v-form
    :value="formIsValid"
    class="fill-width mt-7"
    @input="$emit('update:formIsValid', $event)"
  >
    <v-text-field
      dense
      outlined
      :value="email"
      :rules="rules.email"
      :disabled="hasUserInfo"
      :label="$t('form.labels.email')"
      @input="$emit('update:email', $event)"
    />
    <v-text-field
      dense
      outlined
      :value="username"
      :rules="rules.username"
      :label="$t('form.labels.username')"
      @input="$emit('update:username', $event)"
    />
    <v-text-field
      flat
      dense
      outlined
      :value="name"
      :rules="rules.fullname"
      :label="$t('form.labels.full_name')"
      @input="$emit('update:name', $event)"
    />
    <base-password
      :value="password"
      :rules="rules.password"
      :validate-on-blur="false"
      :label="$t('form.labels.password')"
      @password-updated="$emit('update:password', $event)"
    />
    <v-checkbox
      dense
      class="mt-0"
      hide-details
      :value="receiveNews"
      :label="
        $t('form.labels.sign_up_for_app_news_and_updates', {
          appName: 'Taskulu'
        })
      "
      @change="$emit('update:receiveNews', $event)"
    />
    <p
      class="mt-4 caption secondary--text"
      v-html="
        $t('page.sign_up.tos', {
          privacyLink,
          termsLink
        })
      "
    />
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import BasePassword from '@/components/BasePassword'
export default {
  props: {
    name: {
      type: String
    },
    email: {
      type: String
    },
    username: {
      type: String
    },
    password: {
      type: String
    },
    hasUserInfo: {
      type: Boolean
    },
    formIsValid: {
      type: Boolean
    },
    receiveNews: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(['termsLink', 'privacyLink'])
  },
  data() {
    return {
      rules: {
        email: this.getEmailRules(),
        username: this.getUsernameRules(),
        fullname: this.getFullNameRules(),
        password: this.getPasswordRules()
      }
    }
  },
  components: {
    BasePassword
  }
}
</script>
