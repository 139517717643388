<template>
  <v-card flat tile class="overflow-hidden" height="100%">
    <v-app-bar
      dark
      tile
      flat
      absolute
      prominent
      :color="color"
      shrink-on-scroll
      id="profile_app_bar"
      :height="appBarMaxHeight"
      :min-height="appBarMinHeight"
      scroll-target="#profile-items"
      :fade-img-on-scroll="hasAvatar"
      :extension-height="extensionHeight"
      :src="hasAvatar ? user.avatar.large : ''"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          :gradient="
            hasAvatar
              ? '0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 32.04%, rgba(255,255,255,0) 33%, rgba(255,255,255,0) 67%, rgba(0, 0, 0, 0) 68%, rgba(0, 0, 0, 0.5) 100%'
              : ''
          "
        />
      </template>
      <v-app-bar-nav-icon @click="$emit('go-back-clicked')">
        <base-angle-icon
          width="24"
          height="24"
          name="arrow"
          v-if="isMobile"
          :fill="appBarIsShrinked ? 'info.base' : 'secondary.lighten2'"
        />
        <base-icon
          v-else
          width="24"
          height="24"
          name="times"
          :color="appBarIsShrinked ? 'info.base' : 'secondary.lighten2'"
        />
      </v-app-bar-nav-icon>
      <v-slide-y-transition>
        <v-toolbar-title
          class="ps-0 text-h3 py-3"
          v-show="appBarIsShrinked"
          v-text="$t('page.profile_setting.title')"
          :class="{ 'info--text': appBarIsShrinked }"
        />
      </v-slide-y-transition>
      <template v-slot:extension>
        <div class="d-flex flex-row align-center pb-2 px-2">
          <base-avatar
            class="me-2"
            :title="user.name"
            v-show="appBarIsShrinked"
            :image="hasAvatar ? user.avatar.large : ''"
          />
          <div>
            <h2
              class="text-h6"
              :class="{ 'info--text': appBarIsShrinked }"
            >
              {{ user.name }}
            </h2>
            <h3
              :class="{ 'secondary--text text--darken-2': appBarIsShrinked }"
              class="subtitle-2"
            >
              @{{ user.username }}
            </h3>
          </div>
        </div>
        <profile-overview-avatar :has-avatar="hasAvatar" />
      </template>
    </v-app-bar>
    <v-sheet
      id="profile-items"
      :max-height="contentHeight"
      :style="`margin-top:${appBarHeight}px`"
      class="overflow-y-auto border-top-light-grey"
    >
      <v-container class="px-0">
        <profile-overview-list :user="user" />
      </v-container>
    </v-sheet>
  </v-card>
</template>

<script>
import BaseIcon from '../BaseIcon'
import BaseAvatar from '../BaseAvatar'
import BaseAngleIcon from '../BaseAngleIcon'
import { mapGetters, mapActions } from 'vuex'
import ProfileOverviewList from './ProfileOverviewList'
import ProfileOverviewAvatar from './ProfileOverviewAvatar'
export default {
  components: {
    BaseIcon,
    BaseAvatar,
    BaseAngleIcon,
    ProfileOverviewList,
    ProfileOverviewAvatar
  },
  data() {
    return {
      elem: null,
      appBarHeight: 0,
      extensionHeight: 60,
      appBarMinHeight: 56,
      appBarMaxHeight: 150,
      resizeObserver: null
    }
  },
  computed: {
    ...mapGetters(['user', 'isMobile']),
    color() {
      if (this.hasAvatar) {
        return 'white'
      }
      return this.appBarIsShrinked
        ? 'white'
        : this.getUserColorBasedOnName(this.user.name)
    },
    hasAvatar() {
      return this.user.avatar !== null
    },
    appBarIsShrinked() {
      return this.appBarHeight === this.appBarMinHeight + this.extensionHeight
    },
    contentHeight() {
      return window.innerHeight - this.appBarHeight
    }
  },
  methods: {
    ...mapActions(['logout'])
  },
  mounted() {
    this.elem = document.getElementById('profile_app_bar')
    this.resizeObserver = new ResizeObserver(() => {
      this.appBarHeight = this.elem.offsetHeight
    })
    this.resizeObserver.observe(this.elem)
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.elem)
  }
}
</script>
