<template>
  <div
    class="dates body-2 secondary--text text--darken-2 my-2 d-flex align-center"
    v-if="startTime || deadline"
  >
    <template v-if="startTime && deadline">
      <base-date-time class="caption" :date="startTime" format="medium" />
      <base-angle-icon
        width="18"
        height="18"
        fill="primary"
        :flipped="true"
        class="d-flex align-center"
      />
      <base-task-deadline
        :gFormat="gFormat"
        :jFormat="jFormat"
        :deadline="deadline"
      />
    </template>
    <template v-else>
      <template v-if="startTime">
        <base-icon
          width="18"
          height="18"
          name="t-start-date"
          className="me-1 d-flex align-center"
        />
        <base-date-time class="caption" :date="startTime" format="medium" />
      </template>
      <template v-if="deadline">
        <base-icon
          width="18"
          height="18"
          class="me-1"
          className="me-1"
          name="t-due-date"
          v-if="deadlineIsFar"
        />
        <base-task-deadline
          :gFormat="gFormat"
          :jFormat="jFormat"
          :deadline="deadline"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseDateTime from '@/components/BaseDateTime'
import BaseIcon from '@/components/BaseIcon'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import BaseTaskDeadline from '@/components/BaseTaskDeadline'
export default {
  props: {
    startTime: {
      required: true
    },
    deadline: {
      required: true
    }
  },
  computed: {
    ...mapGetters(['locale']),
    isPersian() {
      return this.locale === 'fa'
    },
    gFormat() {
      if (this.isPersian) {
        return 'DD MMMM YYYY'
      }
      return 'DD MMM. YYYY'
    },
    jFormat() {
      if (this.isPersian) {
        return 'jDD jMMMM jYYYY'
      }
      return 'jDD jMMM. jYYYY'
    },
    deadlineIsFar() {
      const oneDay = new Date().getTime() + 1 * 24 * 60 * 60 * 1000
      if (this.deadline * 1000 < oneDay) {
        return false
      }
      return true
    }
  },
  components: {
    BaseDateTime,
    BaseIcon,
    BaseAngleIcon,
    BaseTaskDeadline
  }
}
</script>
