<template>
  <div
    class="list-cards overflow-y-auto mt-2"
    v-if="listTasks.length || !isMobile"
  >
    <div
      class="section py-1"
      v-for="(status, i) in sortedStatuses"
      :key="`section_${status.id}_${status.title}_${list.id}`"
    >
      <div class="px-3 pb-2 text-button section-header" v-text="status.title" />
      <task-list-kanban-section-status
        :index="i"
        :list="list"
        :status="status"
        :tasks="listTasks"
        :new-task-section.sync="newTaskSection"
        @task-moved="$emit('task-moved', $event)"
      />
    </div>
  </div>
  <draggable
    v-else
    :sort="false"
    v-model="listd"
    drag-class="error"
    chosen-class="secondary"
    @add="handleDragEnded"
    class="empty-list ma-2"
    :dragover-bubble="true"
    ghost-class="empty-drag-ghost"
    :data-content="$t('page.kanban.board.empty_list')"
    :group="{ name: 'list', pull: 'clone', put: true }"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import sortBy from 'lodash.sortby'
import draggable from 'vuedraggable'
import TaskListKanbanSectionStatus from './TaskListKanbanSectionStatus.vue'
export default {
  components: { draggable, TaskListKanbanSectionStatus },
  props: {
    list: {
      type: Object,
      required: true
    },
    createTaskVisible: {
      type: Boolean,
      required: true
    },
    newTaskClicked: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['project', 'tasks', 'isMobile', 'projectStatuses']),
    sortedStatuses() {
      if (!this.projectStatuses) {
        return []
      }
      return sortBy(
        Object.values(this.projectStatuses),
        (status) => status.position
      )
    },
    listTasks() {
      if (!this.tasks) {
        return []
      }
      return this.tasks.filter(
        (task) => task.list.id === this.list.id && !task.archived
      )
    }
  },
  data() {
    return {
      listd: [],
      newTaskSection: null
    }
  },
  methods: {
    ...mapActions(['getProjectStatuses']),
    handleDragEnded(e) {
      const tid = e.clone.attributes.getNamedItem('data-task').value
      const sid = this.sortedStatuses[0].id
      const lid = this.list.id
      const position = 0
      this.$emit('task-moved', { sid, lid, tid, position })
    }
  },
  async created() {
    await this.getProjectStatuses(this.project.id)
  },
  watch: {
    newTaskClicked(newValue) {
      if (newValue) {
        this.newTaskSection = {
          status: this.sortedStatuses[0].id,
          list: this.list.id
        }
        this.$emit('update:newTaskClicked', false)
      }
    }
  }
}
</script>
<style lang="scss">
.empty-drag-ghost {
  height: 100%;
  opacity: 1 !important;
  background-color: var(--v-secondary-lighten2) !important;
  box-shadow: inset 0px 0.25px 1px rgba(0, 0, 0, 0.039),
    inset 0px 0.85px 3px rgba(0, 0, 0, 0.19) !important;
  .task-content {
    visibility: hidden;
    .v-badge {
      display: none;
    }
  }
}
</style>
