<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    content-class="small-level-one"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.integrations.title')"
  >
    <v-list v-if="!loading">
      <v-list-item
        :key="item.name"
        v-for="item in items"
        @click="item.dialogVisible = true"
      >
        <v-list-item-avatar min-width="22" width="22" class="me-2">
          <base-icon
            color="primary"
            :name="item.icon"
            className="d-flex align-center"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="
              body-1
              d-flex
              info--text
              text--darken-4
              justify-space-between
            "
          >
            <span>{{
              $t(`page.project_settings.integrations.${item.name}.title`)
            }}</span>
            <span v-if="item.subtitle" class="secondary--text text--darken-2">
              {{ item.subtitle }}
            </span>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <base-angle-icon
            width="20"
            height="20"
            fill="secondary.darken2"
            class="flip-horizontal"
          />
        </v-list-item-icon>
        <base-dialog
          cardColor="white"
          content-class="small-level-two"
          :dialog.sync="item.dialogVisible"
          :show-save-btn="item.hasSaveButton"
          @save-clicked="item.dialogVisible = false"
          :title="$t(`page.project_settings.integrations.${item.name}.title`)"
        >
          <component
            :name="item.name"
            :dialog="item.dialogVisible"
            :is="`SettingsDialogListIntegrations${item.component}`"
          />
        </base-dialog>
      </v-list-item>
    </v-list>
    <base-loading v-else />
  </base-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import BaseDialog from '@/components/BaseDialog'
import BaseLoading from '@/components/BaseLoading'
import BaseAngleIcon from '@/components/BaseAngleIcon'
import { googleDriveAccessTokenName } from '@/utils/auth'
import SettingsDialogListIntegrationsGoogleCalendar from '@/components/project/SettingsDialogListIntegrationsGoogleCalendar'

export default {
  props: {
    project: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseLoading,
    BaseAngleIcon,
    SettingsDialogListIntegrationsGoogleCalendar
  },
  methods: {
    ...mapActions(['getProjectIntegration', 'setGoogleCalendarAccessToken']),
    async getIntegrations() {
      try {
        await this.getProjectIntegration(this.$route.params.pid)
      } catch (error) {}
    },
    async getData() {
      this.loading = true
      this.getIntegrations()
      this.loading = false
    },
    async handleLocalStorageChange(event) {
      const { key, newValue } = event
      if (key === googleDriveAccessTokenName) {
        await this.setGoogleCalendarAccessToken(newValue)
        await this.getIntegrations()
      }
    }
  },
  data() {
    return {
      loading: false,
      items: [
        {
          name: 'google_calendar',
          icon: 't-google-calendar',
          dialogVisible: false,
          hasSaveButton: false,
          component: 'GoogleCalendar'
        }
      ]
    }
  },
  mounted() {
    this.getData()
  },
  created() {
    window.addEventListener('storage', this.handleLocalStorageChange)
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.handleLocalStorageChange)
  }
}
</script>
