import request from '@/api/axios'

export const fetchTasks = (pid, params) =>
  request({
    url: `orgs/#oid/projects/${pid}/tasks`,
    method: 'get',
    params
  })

export const fetchTask = (tid, params) =>
  request({
    url: `orgs/#oid/tasks/${tid}`,
    method: 'get',
    params
  })

export const fetchAssignableUsers = tid =>
  request({
    url: `orgs/#oid/tasks/${tid}/assignables`,
    method: 'get'
  })

export const patchTask = (tid, data) =>
  request({
    url: `orgs/#oid/tasks/${tid}`,
    method: 'patch',
    data
  })

export const createTask = (pid, data) =>
  request({
    url: `orgs/#oid/projects/${pid}/tasks`,
    method: 'post',
    data
  })

export const createTaskValue = (tid, data, path = 'move') =>
  request({
    url: `orgs/#oid/tasks/${tid}/${path}`,
    method: 'post',
    data
  })

export const editTask = (tid, data, path = 'dependees') =>
  request({
    url: `orgs/#oid/tasks/${tid}/${path}`,
    method: 'put',
    data
  })

export const deleteTask = tid =>
  request({
    url: `orgs/#oid/tasks/${tid}`,
    method: 'delete'
  })

export const createTaskExport = (pid, data) =>
  request({
    url: `orgs/#oid/projects/${pid}/tasks/export`,
    method: 'post',
    data
  })
