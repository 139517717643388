<template>
  <fragment>
    <template v-for="tag in tagItems">
      <v-list-item dense :key="tag.id" :value="tag.id">
        <v-list-item-avatar
          size="24"
          class="justify-center"
          color="secondary lighten-2"
        >
          <base-icon name="tag" width="13" height="13" color="primary" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="tag.title" />
        </v-list-item-content>
      </v-list-item>
      <v-divider :key="`tag_divider_${tag.id}`" />
    </template>
    <task-dialog-details-add-button
      id="task_tags"
      v-if="editable"
      @add-clicked="addTagHandler"
      :text="$t('common.add_tag')"
    />
    <base-dialog
      :loading="loading"
      card-color="white"
      @save-clicked="save"
      :show-save-btn="true"
      :dialog.sync="dialog"
      @go-back-clicked="cancel"
      :title="$t('common.tag', { count: 0 })"
      content-class="tags-modal large-level-two"
    >
      <base-tags-list
        :pid="pid"
        ref="tagsList"
        :multiple="true"
        :tags="tagOptions"
        :tag.sync="taskTags"
      />
    </base-dialog>
  </fragment>
</template>
<script>
import isEqual from 'lodash.isequal'
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseTagsList from '@/components/BaseTagsList'
import TaskDialogDetailsAddButton from '@/components/task/TaskDialogDetailsAddButton'

export default {
  props: {
    pid: {
      type: String,
      required: true
    },
    tags: {
      required: true
    },
    tagOptions: {
      required: true
    },
    editable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(['task']),
    tid() {
      return this.task.id
    },
    tagItems() {
      return this.tagOptions.filter((tag) => this.tags.indexOf(tag.id) > -1)
    }
  },
  data() {
    return {
      search: '',
      dialog: false,
      loading: false,
      taskTags: this.tags
    }
  },
  methods: {
    ...mapActions(['updateTask']),
    switchDialogState() {
      if (this.$route.name !== 'tags') {
        this.$router.push({ name: 'tags' })
      }
      this.dialog = !this.dialog
    },
    isRouteActive() {
      const { name } = this.$route
      if (name === 'tags') {
        this.dialog = true
      }
    },
    addTagHandler() {
      this.$router.push({ name: 'tags' })
      this.dialog = true
    },
    async save() {
      if (isEqual(this.taskTags, this.tags)) {
        this.dialog = false
        return
      }
      this.loading = true
      try {
        const values = [
          this.tagOptions.filter((tag) => this.taskTags.indexOf(tag.id) > -1)
        ]
        await this.updateTask({
          path: 'tags',
          tid: this.tid,
          data: {
            ids: this.taskTags
          },
          values,
          updateMyTask: true
        })
        this.dialog = false
        this.$refs.tagsList && this.resetTagsListRef()
      } catch (error) {}
      this.loading = false
    },
    cancel() {
      this.taskTags = this.tags
      this.$refs.tagsList && this.resetTagsListRef()
    },
    resetTagsListRef() {
      this.$refs.tagsList.reset()
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyEscapeListener)
  },
  mounted() {
    this.isRouteActive()
    this._keyEscapeListener = function(e) {
      if (e.key === 'Escape') {
        e.preventDefault()
        this.dialog = false
      }
    }
    document.addEventListener('keydown', this._keyEscapeListener.bind(this))
  },
  watch: {
    tags(newValue) {
      this.taskTags = newValue
    },
    dialog(isActive) {
      const view = this.$route.params.view || 'kanban'
      if (!isActive) {
        this.$router.push({ name: 'task', params: { view, tid: this.tid } })
      }
    }
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseTagsList,
    TaskDialogDetailsAddButton
  }
}
</script>
