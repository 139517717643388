<template>
  <search-dialog-list-item
    :subtitle="subtitle"
    icon="t-circular-progress"
    :title="$t('common.progress')"
    :toolbar-title="$t('common.progress')"
  >
    <section class="py-12 px-3">
      <v-range-slider
        min="0"
        max="100"
        v-model="range"
        :thumb-size="28"
        thumb-label="always"
        track-color="secondary lighten-2"
      >
      </v-range-slider>
    </section>
  </search-dialog-list-item>
</template>

<script>
import SearchDialogListItem from '@/components/search/SearchDialogListItem'
export default {
  props: {
    from: {
      required: true
    },
    to: {
      required: true
    }
  },
  computed: {
    subtitle() {
      const { from, to } = this
      if (from && to) {
        return `${from} - ${to} %`
      }
      return from ? `${from} %` : ''
    }
  },
  data() {
    return {
      range: [0, 100]
    }
  },
  components: {
    SearchDialogListItem
  },
  watch: {
    range(newValue) {
      this.$emit('update:from', newValue[0])
      this.$emit('update:to', newValue[1])
    }
  }
}
</script>
