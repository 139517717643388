import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: false,
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        white: '#FFFFFF',
        background: '#F5F5F5',
        primary: '#2196F3',
        accent: '#5CD4D1',
        secondary: {
          base: '#899EB2',
          darken2: '#526F8B',
          lighten1: '#BECEDE',
          lighten2: '#ECF2F8',
          lighten5: '#F4F7FB'
        },
        blue: {
          base: '#2196F3',
          lighten4: '#D3EAFD'
        },
        error: '#E13857',
        info: '#223140',
        success: '#38E07B',
        warning: '#EDB901'
      }
    }
  }
})
