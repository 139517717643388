<template>
  <div class="flex-grow-1 justify-start flex-column fill-width">
    <h4
      class="body-2 secondary--text text--darken-2 mb-6"
      v-text="$t('page.onboarding.invite_member_to_project.content')"
    />
    <section>
      <input ref="invitationLink" :value="invitationLink" type="hidden" />
      <v-form v-model="formIsValid">
        <v-text-field
          flat
          dense
          outlined
          autofocus
          :key="index"
          class="text-left"
          :rules="rules.email"
          v-model="email.value"
          :label="$t('form.labels.email')"
          v-for="(email, index) in emails"
        >
          <template v-slot:append-outer>
            <span class="cursor pointer px-4" @click="emails.splice(index, 1)">
              <base-icon name="times" color="error" />
            </span>
          </template>
        </v-text-field>
      </v-form>
      <v-btn
        text
        class="px-2"
        color="primary"
        @click="emails.push({ value: '' })"
      >
        <base-icon color="primary" name="plus" />
        <span class="ms-1" v-text="$t('common.add_email')" />
      </v-btn>
    </section>
    <v-row class="mt-4">
      <v-col cols="6">
        <v-btn
          block
          outlined
          depressed
          color="secondary"
          :loading="loading"
          :disabled="loading"
          @click="copyLinkClipboard"
        >
          <base-icon name="copy" color="secondary.base" />
          <span class="secondary--text ms-2" v-text="$t('common.copy_link')" />
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          block
          depressed
          color="primary"
          :loading="inviting"
          v-text="$t('common.done')"
          @click="$emit('invite-clicked')"
          :disabled="!formIsValid || invitees.length === 0 || inviting"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapGetters, mapActions } from 'vuex'
import { createInvitation } from '@/api/invitations'
export default {
  components: { BaseIcon },
  props: {
    project: {
      type: Object,
      required: true
    },
    invitees: {
      type: Array,
      required: true
    },
    inviting: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters(['organizationSubdomain']),
    filledEmails() {
      return this.emails.reduce((filtered, email) => {
        if (email.value.length) {
          filtered.push(email.value)
        }
        return filtered
      }, [])
    }
  },
  data() {
    return {
      emails: [],
      loading: false,
      formIsValid: false,
      invitationLink: null,
      tabs: ['link', 'email'],
      rules: {
        email: this.getEnteredEmailRules()
      }
    }
  },
  methods: {
    ...mapActions(['displaySuccessMessage']),
    toogleSnackbar() {
      this.displaySuccessMessage(this.$i18n.t('messages.link_copied'))
    },
    copyLinkClipboard() {
      const text = this.$refs.invitationLink.value
      const listener = (ev) => {
        ev.clipboardData.setData('text/plain', text)
        ev.preventDefault()
      }
      document.addEventListener('copy', listener)
      document.execCommand('copy')
      document.removeEventListener('copy', listener)
      this.toogleSnackbar()
    },
    async generateInvitationLink() {
      this.loading = true
      try {
        const { data } = await createInvitation({
          org_id: this.organizationSubdomain,
          project_id: this.project.id
        })
        this.invitationLink = data.data.url
      } catch (error) {}
      this.loading = false
    }
  },
  mounted() {
    this.generateInvitationLink()
  },
  watch: {
    filledEmails(newValue) {
      this.$emit('update:invitees', newValue)
    }
  }
}
</script>
