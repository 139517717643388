<template>
  <fragment>
    <div class="d-flex flex-column">
      <div class="d-flex justify-space-between align-center">
        <h2 class="body-2 secondary--text text--darken-2">
          {{ $t('common.description') }}
        </h2>
        <v-slide-x-transition hide-on-leave>
          <v-btn
            text
            small
            color="primary"
            :class="{ 'cursor pointer': editable }"
            @click="handleClick"
            v-text="$t('common.edit')"
            v-show="editable"
          />
        </v-slide-x-transition>
      </div>

      <div class="ql-container ql-snow view mt-2">
        <div class="subdescription-2 ql-editor" v-html="description" />
      </div>
    </div>
    <v-dialog
      scrollable
      v-model="dialog"
      v-bind="responsiveDialogProps"
      transition="slide-x-transition"
      @click:outside="dialog = false"
      content-class="full-dialog large-level-two"
    >
      <v-card class="d-flex flex-column">
        <v-toolbar flat color="white" class="flex-grow-0">
          <base-dialog-back-btn @go-back-clicked="cancel" :disabled="loading" />
          <v-toolbar-title class="text-h3">
            {{ $t('common.description') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn light text @click="save" color="primary" :loading="loading">
              {{ $t('common.save') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <task-dialog-details-description-editor
          :tasks="tasksLinkable"
          :is-mobile="isMobile"
          :content="description"
          ref="descriptionEditor"
          :users="projectApprovedUsers"
        />
      </v-card>
    </v-dialog>
  </fragment>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import BaseDialogBackBtn from '@/components/BaseDialogBackBtn'
import TaskDialogDetailsDescriptionEditor from '@/components/task/TaskDialogDetailsDescriptionEditor'

export default {
  props: {
    description: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters([
      'task',
      'tasks',
      'project',
      'isMobile',
      'projectApprovedUsers',
      'responsiveDialogProps'
    ]),
    tid() {
      return this.task.id
    },
    tasksLinkable() {
      return this.tasks.map((task) => {
        return {
          ...task,
          link: `/projects/${this.project.short_id}/${
            this.$route.params.view || 'kanban'
          }/tasks/${task.number}`
        }
      })
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      text: this.description
    }
  },
  mounted() {
    this._keyListener = function(e) {
      if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        this.save()
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener)
  },
  methods: {
    ...mapActions(['updateProjectTask']),
    async save() {
      const delta = this.$refs.descriptionEditor.getEditorContent()
      this.loading = true
      try {
        await this.updateProjectTask({
          tid: this.tid,
          data: {
            description_delta: JSON.stringify(delta)
          },
          keys: ['description_delta'],
          updateListItem: false
        })
        this.dialog = false
      } catch (error) {}
      this.loading = false
    },
    cancel() {
      this.dialog = !this.dialog
    },
    handleClick() {
      if (!this.editable) {
        return
      }
      this.dialog = true
    }
  },
  components: {
    BaseDialogBackBtn,
    TaskDialogDetailsDescriptionEditor
  },
  watch: {
    description(newValue) {
      this.text = newValue
    }
  }
}
</script>
