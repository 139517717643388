import Vue from 'vue'
import {
  fetchProject,
  patchProject,
  fetchProjects,
  createProject,
  deleteProject,
  fetchProjectTasks,
  createProjectLeave,
  patchProjectFeature,
  createProjectClosure,
  deleteProjectMembers,
  createProjectMembers,
  fetchProjectFeatures,
  fetchProjectAbilities,
  createProjectTransfer,
  createProjectByTemplate,
  createProjectRestorement,
  fetchProjectIntegrations,
  createProjectIntegrationStatus
} from '@/api/projects'
import { fetchMembers } from '@/api/project-memberships'
import { createTaskValue, createTask } from '@/api/tasks'
import { fetchLabels, patchLabel, toggleLabel } from '../../api/labels'
import { fetchTags, patchTag, deleteTag, createProjectTag } from '@/api/tags'
import { fetchBoards, createBoard, patchBoard, deleteBoard } from '@/api/boards'
import {
  updateObject,
  getFilteredUsers,
  getBoardsWithLists,
  convertArrayToObject
} from '@/utils/helpers'
import {
  moveList,
  copyList,
  createList,
  updateList,
  fetchLists,
  createListStatus
} from '@/api/lists'

import {
  deleteStatus,
  patchStatus,
  createStatus,
  fetchStatuses
} from '@/api/statuses'
import {
  SET_PROJECT,
  SET_PROJECTS,
  SET_TASK_VALUE,
  ADD_PROJECT_TAG,
  SET_PROJECT_TAG,
  SET_PROJECT_TAGS,
  ADD_PROJECT_LIST,
  ADD_PROJECT_TASK,
  SET_PROJECT_VALUE,
  SET_PROJECT_TASKS,
  SET_PROJECT_OWNER,
  ADD_PROJECT_BOARD,
  SET_PROJECT_BOARD,
  SET_PROJECT_LABEL,
  ADD_PROJECT_TASKS,
  SET_PROJECT_LISTS,
  SET_PROJECT_STATUS,
  ADD_PROJECT_STATUS,
  SET_PROJECT_LABELS,
  DELETE_PROJECT_TAG,
  SET_PROJECT_BOARDS,
  SET_PROJECT_FEATURE,
  DELETE_PROJECT_TASK,
  SET_RENDER_ARCHIVED,
  SET_PROJECT_FEATURES,
  DELETE_PROJECT_BOARD,
  SET_PROJECT_STATUSES,
  DELETE_PROJECT_STATUS,
  SET_PROJECT_ABILITIES,
  SET_PROJECT_TASK_VALUE,
  SET_PROJECT_LIST_VALUE,
  SET_PROJECT_INTEGRATION,
  ADD_PROJECT_MEMBERSHIPS,
  SET_PROJECT_MEMBERSHIPS,
  SET_PROJECT_TASKS_COUNT,
  SET_PROJECT_INTEGRATIONS,
  DELETE_PROJECT_MEMBERSHIPS,
  DELETE_ORGANIZATION_PROJECT,
  SET_ORGANIZATION_PROJECT_VALUE
} from '../mutation-types'

const state = {
  projectIntegrations: null,
  projectMemberships: null,
  projectAbilities: null,
  projectFeatures: null,
  projectStatuses: null,
  renderArchived: false,
  projectBoards: null,
  projectLabels: null,
  projectLists: null,
  projectTags: null,
  projects: null,
  tasksCount: 0,
  project: null,
  tasks: null
}

const mutations = {
  [SET_PROJECT_FEATURES](state, projectFeatures) {
    state.projectFeatures = convertArrayToObject(projectFeatures)
  },
  [SET_PROJECT_ABILITIES](state, projectAbilities) {
    state.projectAbilities = projectAbilities
  },
  [SET_PROJECT_TASKS_COUNT](state, tasksCount) {
    state.tasksCount = tasksCount
  },
  [SET_PROJECT_BOARDS](state, projectBoards) {
    state.projectBoards = convertArrayToObject(projectBoards)
  },
  [SET_PROJECT_LISTS](state, projectLists) {
    state.projectLists = convertArrayToObject(projectLists)
  },
  [SET_PROJECT_FEATURE](state, { id, value }) {
    state.projectFeatures[id].settings.unit = value
  },
  [SET_PROJECT_INTEGRATIONS](state, projectIntegrations) {
    state.projectIntegrations = convertArrayToObject(projectIntegrations)
  },
  [SET_PROJECT_INTEGRATION](state, { id, value, key }) {
    state.projectIntegrations[id][key] = value
  },
  [ADD_PROJECT_TASK](state, task) {
    state.tasks = [...state.tasks, task]
  },
  [ADD_PROJECT_STATUS](state, status) {
    state.projectStatuses = {
      ...state.projectStatuses,
      ...convertArrayToObject([status])
    }
  },
  [ADD_PROJECT_TASKS](state, task) {
    state.tasks = [...state.tasks, task]
  },
  [ADD_PROJECT_TASKS](state, tasks) {
    state.tasks = [...state.tasks, ...tasks]
  },
  [ADD_PROJECT_BOARD](state, board) {
    state.projectBoards = {
      ...state.projectBoards,
      ...convertArrayToObject([board])
    }
  },
  [ADD_PROJECT_LIST](state, list) {
    state.projectLists = {
      ...state.projectLists,
      ...convertArrayToObject([list])
    }
  },
  [ADD_PROJECT_TAG](state, tag) {
    state.projectTags = {
      ...state.projectTags,
      ...convertArrayToObject([tag])
    }
  },
  [ADD_PROJECT_MEMBERSHIPS](state, memberships) {
    state.projectMemberships = {
      ...state.projectMemberships,
      ...convertArrayToObject(memberships)
    }
  },
  [DELETE_PROJECT_MEMBERSHIPS](state, mids) {
    for (let i = 0; i < mids.length; i++) {
      Vue.delete(state.projectMemberships, mids[i])
    }
  },
  [DELETE_PROJECT_TASK](state, tid) {
    const index = state.tasks.findIndex(task => task.id === tid)
    if (index < 0) {
      return
    }
    state.tasks.splice(index, 1)
  },
  [SET_PROJECT_STATUSES](state, projectStatuses) {
    state.projectStatuses = convertArrayToObject(projectStatuses)
  },
  [DELETE_PROJECT_TAG](state, tid) {
    Vue.delete(state.projectTags, tid)
  },
  [DELETE_PROJECT_STATUS](state, sid) {
    Vue.delete(state.projectStatuses, sid)
  },
  [DELETE_PROJECT_BOARD](state, bid) {
    Vue.delete(state.projectBoards, bid)
  },
  [SET_PROJECTS](state, projects) {
    state.projects = projects
  },
  [SET_PROJECT](state, project) {
    state.project = project
  },
  [SET_PROJECT_LABELS](state, projectLabels) {
    state.projectLabels = convertArrayToObject(projectLabels)
  },
  [SET_PROJECT_MEMBERSHIPS](state, projectMemberships) {
    state.projectMemberships = convertArrayToObject(projectMemberships)
  },
  [SET_RENDER_ARCHIVED](state, renderArchived) {
    state.renderArchived = renderArchived
  },
  [SET_PROJECT_TASKS](state, tasks) {
    state.tasks = tasks
  },
  [SET_PROJECT_VALUE](state, { keys, values }) {
    for (let i = 0; i < keys.length; i++) {
      updateObject(state.project, values[i], keys[i])
    }
  },
  [SET_PROJECT_LIST_VALUE](state, { lid, keys, values }) {
    const list = state.projectLists[lid]
    if (!list) {
      return
    }
    for (let i = 0; i < keys.length; i++) {
      updateObject(list, values[i], keys[i])
    }
  },
  [SET_PROJECT_TASK_VALUE](state, { tid, keys, values }) {
    const task = state.tasks.find(task => task.id === tid)
    if (!task) {
      return
    }
    for (let i = 0; i < keys.length; i++) {
      updateObject(task, values[i], keys[i])
    }
  },
  [SET_PROJECT_STATUS](state, { sid, newValue }) {
    state.projectStatuses.$set(sid, newValue)
  },
  [SET_PROJECT_BOARD](state, { bid, newValue }) {
    state.projectBoards.$set(bid, newValue)
  },
  [SET_PROJECT_LABEL](state, { lid, keys, values }) {
    const label = state.projectLabels[lid]
    if (!label) {
      return
    }
    for (let i = 0; i < keys.length; i++) {
      updateObject(label, values[i], keys[i])
    }
  },
  [SET_PROJECT_TAGS](state, projectTags) {
    state.projectTags = convertArrayToObject(projectTags)
  },
  [SET_PROJECT_TAG](state, { tid, keys, values }) {
    const tag = state.projectTags[tid]
    if (!tag) {
      return
    }
    for (let i = 0; i < keys.length; i++) {
      updateObject(tag, values[i], keys[i])
    }
  },
  [SET_PROJECT_OWNER](state, id) {
    state.project.owner.id = id
  }
}

const actions = {
  async updateProject({ commit }, { pid, data }) {
    const result = await patchProject(pid, data)
    const keys = ['title']
    const values = [result.data.data.title]
    commit(SET_PROJECT_VALUE, {
      keys,
      values
    })
    commit(
      SET_ORGANIZATION_PROJECT_VALUE,
      { pid, keys, values },
      { root: true }
    )
  },
  async closeProject({ commit }, pid) {
    await createProjectClosure(pid)
    const keys = ['closed']
    const values = [true]
    commit(SET_PROJECT_VALUE, {
      keys,
      values
    })
    commit(
      SET_ORGANIZATION_PROJECT_VALUE,
      { pid, keys, values },
      { root: true }
    )
  },
  async reopenProject({ commit }, pid) {
    await createProjectRestorement(pid)
    const keys = ['closed']
    const values = [false]
    commit(
      SET_ORGANIZATION_PROJECT_VALUE,
      { pid, keys, values },
      { root: true }
    )
  },
  async deleteOrganizationProject({ commit }, { pid, data }) {
    await deleteProject(pid, data)
    commit(DELETE_ORGANIZATION_PROJECT, pid, { root: true })
    commit(SET_PROJECT, null)
  },
  async getProjects({ commit }, params) {
    commit(SET_PROJECTS, null)
    const { data } = await fetchProjects(params)
    commit(SET_PROJECTS, data.data.records)
  },
  async getProject({ commit }, pid) {
    const params = {
      include: ['memberships'],
      expand: ['owner']
    }
    const { data } = await fetchProject(pid, params)
    commit(SET_PROJECT, data.data)
  },
  async getProjectAbilities({ commit }, pid) {
    const { data } = await fetchProjectAbilities(pid)
    commit(SET_PROJECT_ABILITIES, data.data)
  },
  async getProjectFeatures({ commit }, pid) {
    const { data } = await fetchProjectFeatures(pid)
    commit(SET_PROJECT_FEATURES, data.data)
  },
  async updateProjectFeature({ commit }, { pid, fid, data }) {
    await patchProjectFeature(pid, fid, data)
    commit(SET_PROJECT_FEATURE, { id: fid, value: data.settings.unit })
  },
  async getProjectIntegration({ commit }, pid) {
    const { data } = await fetchProjectIntegrations(pid)
    commit(SET_PROJECT_INTEGRATIONS, data.data)
  },
  async updateProjectIntegrationStatus({ commit }, { pid, iid, status }) {
    await createProjectIntegrationStatus(pid, iid, status)
    commit(SET_PROJECT_INTEGRATION, {
      id: iid,
      value: status === 'enable',
      key: 'enabled'
    })
  },
  async changeProjectOwnership({ commit }, { pid, data }) {
    await createProjectTransfer(pid, data)
    commit(SET_PROJECT_OWNER, data.user_id)
  },
  async addProject({ commit }, { projectData, setProject }) {
    const result = await createProject(projectData)
    if (setProject) {
      commit(SET_PROJECT, result.data.data)
    }
  },
  async addProjectByTemplate({ commit }, { projectData, setProject }) {
    const result = await createProjectByTemplate(projectData)
    if (setProject) {
      commit(SET_PROJECT, result.data.data)
    }
  },
  async addProjectBoard({ commit }, { pid, data }) {
    const result = await createBoard(pid, data)
    commit(ADD_PROJECT_BOARD, result.data.data)
  },
  async updateProjectBoard({ commit }, { board, data }) {
    const result = await patchBoard(board, data)
    commit(SET_PROJECT_BOARD, { board, newValue: result.data.data })
  },
  async deleteProjectBoard({ commit }, bid) {
    await deleteBoard(bid)
    commit(DELETE_PROJECT_BOARD, bid)
  },
  async getProjectLabels({ commit }, pid) {
    const { data } = await fetchLabels(pid)
    commit(SET_PROJECT_LABELS, data.data)
  },
  async updateProjectLabel({ commit }, { lid, data }) {
    const result = await patchLabel(lid, data)
    commit(SET_PROJECT_LABEL, {
      lid,
      keys: ['title'],
      values: [result.data.data.title]
    })
  },
  async toggleProjectLabel({ commit }, { lid, path, enabled }) {
    await toggleLabel(lid, path)
    commit(SET_PROJECT_LABEL, { lid, keys: ['enabled'], values: [enabled] })
  },
  async addProjectList({ commit }, { pid, data }) {
    const result = await createList(pid, data)
    commit(ADD_PROJECT_LIST, result.data.data)
  },
  async modifyProjectList({ commit }, { lid, data }) {
    const result = await updateList(lid, data)
    commit(SET_PROJECT_LIST_VALUE, {
      lid,
      keys: ['title'],
      values: [result.data.data.title]
    })
  },
  async changeProjectListStatus({ commit }, { lid, path, prop, newValue }) {
    await createListStatus(lid, path)
    commit(SET_PROJECT_LIST_VALUE, { lid, keys: [prop], values: [newValue] })
  },
  async moveProjectList({ commit }, { lid, data }) {
    await moveList(lid, data)
    commit(SET_PROJECT_LIST_VALUE, {
      lid,
      keys: ['board.id'],
      values: [data.board_id]
    })
  },
  async copyProjectList({ commit }, { lid, data }) {
    const res = await copyList(lid, data)
    const result = res.data.data
    const { tasks } = result
    const list = Object.assign({}, result)
    delete list.tasks
    commit(ADD_PROJECT_LIST, list)
    commit(ADD_PROJECT_TASKS, tasks)
  },
  async moveProjectTask({ commit, rootState }, { tid, data }) {
    const keys = ['list.id', 'status.id', 'position']
    const values = [data.list_id, data.status_id, data.position]
    commit(SET_PROJECT_TASK_VALUE, { tid, keys, values })
    await createTaskValue(tid, data)
    if (rootState.task.task && rootState.task.task.id === tid) {
      commit(SET_TASK_VALUE, { keys, values }, { root: true })
    }
  },
  async copyProjectTask({ commit }, { tid, data, path }) {
    const result = await createTaskValue(tid, data, path)
    commit(ADD_PROJECT_TASK, result.data.data)
  },
  async addProjectTask({ commit }, { pid, data }) {
    const result = await createTask(pid, data)
    commit(ADD_PROJECT_TASK, result.data.data)
  },
  async addProjectTag({ commit }, { pid, data }) {
    const result = await createProjectTag(pid, data)
    commit(ADD_PROJECT_TAG, result.data.data)
  },
  async deleteProjectTag({ commit }, tid) {
    await deleteTag(tid)
    commit(DELETE_PROJECT_TAG, tid)
  },
  async updateProjectStatus({ commit }, { sid, data }) {
    const result = await patchStatus(sid, data)
    commit(SET_PROJECT_STATUS, { sid, newValue: result.data.data })
  },
  async updateProjectTag({ commit }, { tid, data }) {
    const result = await patchTag(tid, data)
    commit(SET_PROJECT_TAG, {
      tid,
      keys: ['title'],
      values: [result.data.data.title]
    })
  },
  async getProjectTags({ commit }, pid) {
    const params = { limit: 0 }
    const { data } = await fetchTags(pid, params)
    commit(SET_PROJECT_TAGS, data.data.records)
  },
  async getProjectBoards({ commit }, pid) {
    const params = { limit: 0 }
    const { data } = await fetchBoards(pid, params)
    commit(SET_PROJECT_BOARDS, data.data.records)
  },
  async getProjectMemberships({ commit }, pid) {
    const params = { limit: 0 }
    const { data } = await fetchMembers(pid, params)
    commit(SET_PROJECT_MEMBERSHIPS, data.data.records)
  },
  async getProjectLists({ commit }, pid) {
    const params = { limit: 0 }
    const { data } = await fetchLists(pid, params)
    commit(SET_PROJECT_LISTS, data.data.records)
  },
  async deleteProjectStatus({ commit }, sid) {
    await deleteStatus(sid)
    commit(DELETE_PROJECT_STATUS, sid)
  },
  async addProjectStatus({ commit }, { pid, data }) {
    const result = await createStatus(pid, data)
    commit(ADD_PROJECT_STATUS, result.data.data)
  },
  async getProjectTasks({ commit }, pid) {
    const params = {
      limit: 0,
      expand: ['status', 'label', 'tags', 'assignees']
    }
    const { data } = await fetchProjectTasks(pid, params)
    commit(SET_PROJECT_TASKS, data.data.records)
  },
  async getProjectStatuses({ commit }, pid) {
    const { data } = await fetchStatuses(pid)
    const sorted = data.data.sort((a, b) => {
      return a.position <= b.position ? -1 : 1
    })
    commit(SET_PROJECT_STATUSES, sorted)
  },
  async getProjectTasksCount({ commit }, pid) {
    const params = {
      limit: 1
    }
    const { data } = await fetchProjectTasks(pid, params)
    commit(SET_PROJECT_TASKS_COUNT, data.data.total_count)
  },
  async addProjectMembers({ commit }, { pid, data }) {
    const result = await createProjectMembers(pid, data)
    commit(ADD_PROJECT_MEMBERSHIPS, result.data.data)
  },
  async deleteProjectMemberships({ commit }, { pid, data, mids }) {
    await deleteProjectMembers(pid, data)
    commit(DELETE_PROJECT_MEMBERSHIPS, mids)
  },
  async leaveProject({ commit }, pid) {
    await createProjectLeave(pid)
    commit(DELETE_ORGANIZATION_PROJECT, pid, { root: true })
  },
  setRenderArchived({ commit }, renderArchived) {
    commit(SET_RENDER_ARCHIVED, renderArchived)
  },
  setProject({ commit }, project) {
    commit(SET_PROJECT, project)
  }
}

const getters = {
  projectApprovedUsers: state =>
    getFilteredUsers(state.projectMemberships, 'approved').sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
  projectPendingUsers: state =>
    getFilteredUsers(state.projectMemberships, 'pending'),
  projectUsers: state => getFilteredUsers(state.projectMemberships),
  boardsWithListsAndTasks: state => getBoardsWithLists(state, true),
  projectIntegrations: state => state.projectIntegrations,
  projectMemberships: state => state.projectMemberships,
  boardsWithLists: state => getBoardsWithLists(state),
  projectAbilities: state => state.projectAbilities,
  projectStatuses: state => state.projectStatuses,
  projectFeatures: state => state.projectFeatures,
  renderArchived: state => state.renderArchived,
  projectBoards: state => state.projectBoards,
  projectLabels: state => state.projectLabels,
  projectLists: state => state.projectLists,
  projectTags: state => state.projectTags,
  tasksCount: state => state.tasksCount,
  projects: state => state.projects,
  project: state => state.project,
  tasks: state => state.tasks
}

export default {
  mutations,
  actions,
  getters,
  state
}
