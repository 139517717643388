<template>
  <div class="d-flex flex-column secondary lighten-5 fill-height">
    <timelogs-top-area
      @export-clicked="handleExport"
      @add-clicked="openAddTimelogDialog"
      :createTimelogVisible="createTimelogVisible"
    />
    <timelogs-list
      v-if="!timelogsGroup"
      :pid="pid"
      @list-updated="getPrimaryData"
    />
    <timelogs-table
      :fetching="fetching"
      :loading="sortLoading"
      @sort-changed="handleSortChange"
      @row-clicked="openEditTimelogDialog"
      :timelogs="timelogs ? timelogs.records : null"
    />
    <base-loading-more v-if="loading" />
    <base-illustration-empty-state
      class="empty-state flex-grow-1"
      :text="$t('common.nothing_to_show')"
      v-if="timelogs && timelogs.records.length === 0"
    />
    <div v-intersect="onIntersect" />
    <v-btn
      fab
      fixed
      small
      bottom
      width="56"
      height="56"
      color="primary"
      :left="$vuetify.rtl"
      :right="!$vuetify.rtl"
      @click="openAddTimelogDialog"
      v-if="isMobile && createTimelogVisible"
    >
      <base-icon name="plus" color="white" />
    </v-btn>
    <timelogs-timelog-dialog
      :tid="tid"
      :mode="mode"
      :task="task"
      :owner="owner"
      v-if="project"
      :loading="creating"
      :dialog.sync="dialog"
      :to.sync="form.stopped_at"
      :task-id.sync="form.task_id"
      :from.sync="form.started_at"
      @field-changed="handleFieldChange"
      @add-timelog-clicked="addNewTimelog"
      :description.sync="form.description"
    />
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import BaseLoadingMore from '@/components/BaseLoadingMore'
import TimelogsList from '@/components/timelogs/TimelogsList'
import TimelogsTable from '@/components/timelogs/TimelogsTable'
import TimelogsTopArea from '@/components/timelogs/TimelogsTopArea'
import TimelogsTimelogDialog from '@/components/timelogs/TimelogsTimelogDialog'
import BaseIllustrationEmptyState from '@/components/BaseIllustrationEmptyState'
const form = {
  task_id: '',
  started_at: '',
  stopped_at: '',
  description: ''
}
const expand = ['user', 'task']
export default {
  components: {
    BaseIcon,
    TimelogsList,
    TimelogsTable,
    BaseIllustrationEmptyState,
    BaseLoadingMore,
    TimelogsTopArea,
    TimelogsTimelogDialog
  },
  computed: {
    ...mapGetters([
      'user',
      'tasks',
      'project',
      'timelogs',
      'isMobile',
      'teamAbilities',
      'timelogsGroup',
      'activeTimelogs',
      'abilitiesPriorityMap'
    ]),
    pid() {
      return this.$route.params.pid
    },
    params() {
      return {
        active: false,
        limit: this.limit,
        aggregate_by: this.timelogsGroup,
        sort_by: this.timelogs ? this.sort : null,
        expand: !this.timelogsGroup ? expand : null
      }
    },
    loadMoreParams() {
      return {
        ...this.params,
        offset_token: this.timelogs ? this.timelogs.offset_token : null
      }
    },
    createTimelogVisible() {
      if (!this.teamAbilities) {
        return 0
      }
      const priorities = Object.values(this.teamAbilities.lists)
      return priorities.some(
        (priority) => priority >= this.abilitiesPriorityMap.Task.create_timelog
      )
    }
  },
  data() {
    return {
      tid: null,
      limit: 20,
      sort: null,
      task: null,
      owner: null,
      mode: 'add',
      dialog: false,
      loading: false,
      fetching: false,
      creating: false,
      form: { ...form },
      sortLoading: false,
      isIntersecting: false
    }
  },
  methods: {
    ...mapActions([
      'setTimelogs',
      'getProject',
      'addTimelog',
      'getTimelogs',
      'addTimelogs',
      'updateTimelog',
      'exportTimelogs',
      'getProjectLists',
      'getProjectTasks',
      'getProjectBoards',
      'getProjectTags',
      'getProjectLabels',
      'getProjectMemberships',
      'getTeamAbilities',
      'getActiveTimelogs',
      'displaySuccessMessage'
    ]),
    async addNewTimelog() {
      this.creating = true
      try {
        await this.addTimelog({
          pid: this.pid,
          data: {
            ...this.form,
            expand
          }
        })
        this.dialog = false
        this.getData()
      } catch (error) {}
      this.creating = false
    },
    async getCurrentProject() {
      const { pid } = this
      if (this.project && this.project.id === pid) {
        return
      }
      await this.getProject(pid)
      if (this.tasks) {
        return
      }
      await this.getProjectLists(pid)
      await this.getProjectTasks(pid)
      await this.getProjectBoards(pid)
      await this.getTeamAbilities(pid)
      await this.getProjectMemberships(pid)
      await this.getProjectLabels(pid)
      await this.getProjectTags(pid)
    },
    async getData() {
      this.sortLoading = true
      try {
        await this.getTimelogs({
          pid: this.pid,
          params: this.params
        })
      } catch (error) {}
      this.sortLoading = false
    },
    async getPrimaryData() {
      const { pid } = this
      this.fetching = true
      if (!this.timelogsGroup) {
        await this.getActiveTimelogs({
          pid: this.pid,
          params: { limit: 0, active: true, expand }
        })
      }
      if (!this.project || this.project.id !== pid) {
        Promise.all([
          this.getProject(pid),
          this.getProjectMemberships(pid),
          this.getTeamAbilities(pid),
          this.getProjectLabels(pid),
          this.getProjectBoards(pid),
          this.getProjectLists(pid),
          this.getProjectTags(pid),
          this.getProjectTasks(pid),
          this.getTimelogs({
            pid,
            params: this.params
          })
        ]).finally(() => {
          this.fetching = false
        })
      } else {
        await this.getTimelogs({
          pid,
          params: this.params
        })
        this.fetching = false
      }
    },
    async addData() {
      this.loading = true
      try {
        await this.addTimelogs({
          pid: this.pid,
          params: this.loadMoreParams
        })
      } catch (error) {}
      this.loading = false
    },
    async handleFieldChange(data) {
      if (this.mode === 'add') {
        return
      }
      try {
        await this.updateTimelog({
          key: Object.keys(data)[0],
          tid: this.tid,
          data
        })
      } catch (error) {}
    },
    async handleExport() {
      try {
        this.displaySuccessMessage(this.$i18n.t('page.timelogs.export_started'))
        await this.exportTimelogs({
          pid: this.pid,
          data: { aggregate_by: this.timelogsGroup }
        })
      } catch (error) {}
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting
    },
    handleSortChange(sort) {
      this.sort = sort
      this.sortLoading = true
      this.getData()
    },
    openAddTimelogDialog() {
      this.owner = this.user
      this.dialog = true
      this.form = { ...form }
      this.mode = 'add'
    },
    openEditTimelogDialog(timelog) {
      if (!this.timelogsGroup) {
        this.dialog = true
        this.mode = 'edit'
        this.tid = timelog.id
        this.owner = timelog.user
        this.task = timelog.task
        this.form.task_id = timelog.task.id
        this.form.started_at = timelog.started_at
        this.form.stopped_at = timelog.stopped_at
        this.form.description = timelog.description
      }
    }
  },
  watch: {
    isIntersecting(newValue) {
      if (
        !newValue ||
        !this.timelogs ||
        !this.timelogs.offset_token ||
        this.loading
      ) {
        return
      }
      this.addData()
    },
    timelogsGroup() {
      this.setTimelogs(null)
      this.getPrimaryData()
    },
    pid() {
      this.setTimelogs(null)
      this.getPrimaryData()
    }
  },
  mounted() {
    this.getPrimaryData()
  }
}
</script>
