var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"dialog":_vm.dialog,"card-color":"white","show-save-btn":true,"content-class":"small-level-two","save-btn-text":_vm.$t('common.next'),"title":_vm.$t('common.team', { count: 0 }),"disable-save-btn":(!_vm.invitees.length && !_vm.members.length) || _vm.loading,"card-text-class":_vm.availableMemebers.length > 0 ? 'd-flex flex-column' : ''},on:{"go-back-clicked":_vm.goBack,"save-clicked":function($event){_vm.secondDialog = true}}},[_c('h3',{staticClass:"text-h6 info--text pa-3 flex-grow-0",domProps:{"textContent":_vm._s(
      _vm.$t(
        'page.project_settings.members.invitation.invite_by_email.add_by_email'
      )
    )}}),_c('settings-dialog-list-members-invite-by-email-add-by-email',{staticClass:"flex-grow-0",attrs:{"dialog":_vm.secondDialog,"invitees":_vm.invitees},on:{"update:invitees":function($event){_vm.invitees=$event}}}),(_vm.availableMemebers.length > 0)?_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('v-divider'),_c('h3',{staticClass:"text-h6 info--text pa-3",domProps:{"textContent":_vm._s(
        _vm.$t(
          'page.project_settings.members.invitation.invite_by_email.members_of',
          {
            organization: _vm.organization.title
          }
        )
      )}}),_c('base-members-list',{attrs:{"avatar-size":32,"members":_vm.members,"subtitle-prop":"username","users":_vm.availableMemebers,"placeholder":"form.placeholders.search_members","emptyListText":"search_and_filter.results.not_found.members"},on:{"update:members":function($event){_vm.members=$event}}})],1):_vm._e(),_c('base-dialog',{attrs:{"card-color":"white","loading":_vm.loading,"show-save-btn":true,"dialog":_vm.secondDialog,"content-class":"small-level-three","save-btn-text":_vm.$t('common.done'),"title":_vm.$t('common.member', { count: 0 }),"disable-save-btn":_vm.selectedTeams.length === 0},on:{"save-clicked":_vm.addMembers,"update:dialog":function($event){_vm.secondDialog=$event}}},[(_vm.teams && _vm.secondDialog)?_c('settings-dialog-list-members-invite-teams',{attrs:{"teams":_vm.teams,"project":_vm.project},on:{"teams-changed":_vm.handleTeamChange}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }