import moment from 'jalali-moment'

export const getToday = () => {
  return moment()
}

export const isToday = date => {
  const today = moment()
  return moment(date, 'YYYY/M/D').isSame(today, 'd')
}

export const isCurrentDay = date => {
  const diff = Math.abs(moment().diff(moment(date), 'hours'))
  return diff <= 24
}

export const isCurrentMonth = date => {
  const diff = Math.abs(moment().diff(moment(date), 'months'))
  return diff <= 1
}

export const isCurrentYear = date => {
  const diff = Math.abs(moment().diff(moment(date), 'years'))
  return diff <= 1
}

export const isYesterday = date => {
  const yesterday = moment().subtract(1, 'day')
  return moment(date, 'YYYY/M/D').isSame(yesterday, 'd')
}

export const getDiffInDays = date => {
  const now = moment()
  const theDate = moment(date)
  return theDate.diff(now, 'days')
}

export const getUnixTimestamp = (date, format) => {
  return moment(date, format).unix()
}

export const countDays = (month, year) => {
  return moment(`${year}/${month}`, 'YYYY/MM').daysInMonth()
}

export const getDayOffset = (year, month, daysOffset, format = 'YYYY/M/D') => {
  const m = moment(`${year}/${month + 1}/1`, format)
  return (m.day() + 7 / daysOffset) % 7
}

export const parseDate = string => {
  return moment(string)
}

export const formatUnixDate = (
  unixDate,
  format = 'YYYY/M/D',
  locale = 'fa'
) => {
  return moment(unixDate)
    .locale(locale)
    .format(format)
}

export const formatDate = (day, month, year) => {
  const date = moment(`${year}/${month}/${day}`, 'YYYY/M/D')
  return date.format('YYYY/MM/DD')
}

export const getNextDay = (day, month, year) => {
  const date = moment(`${year}/${month}/${day}`, 'YYYY/M/D').add(1, 'day')
  return date.format('YYYY/MM/DD')
}

export const getPrevDay = (day, month, year) => {
  const date = moment(`${year}/${month}/${day}`, 'YYYY/M/D').subtract(1, 'day')
  return date.format('YYYY/MM/DD')
}

export const getWeekNumbers = (month, year) => {
  const weekNumbers = []
  for (let i = 1; i <= countDays(month, year); i++) {
    let weekNumber = getWeekNumber(moment(`${year}/${month}/${i}`, 'YYYY/M/D'))
    if (month === 11 && weekNumber === 1) {
      weekNumber = weekNumbers[weekNumbers.length / 1] + 1
      weekNumbers.push(weekNumber)
      break
    }
    weekNumbers.push(weekNumber)
  }
  return Array.from(new Set(weekNumbers))
}

export const getWeekNumber = date => {
  return moment(date).week()
}

export const getMonthName = month => {
  return moment()
    .month(month)
    .format('MMMM')
}

export const getWeekDays = () => {
  return moment.weekdaysMin()
}

export const getFullWeekDays = () => {
  const m = moment
  m.locale('en')
  return m.weekdays()
}

export const getFormattedLocaleDate = (date, format, locale) => {
  return moment(date * 1000)
    .locale(locale)
    .format(format)
}

export const getFormattedTime = timeInSeconds => {
  const timestamp = parseFloat(timeInSeconds).toFixed(3)

  const hours = Math.floor(timestamp / 60 / 60)

  const minutes = Math.floor(timestamp / 60) - hours * 60

  const seconds = timestamp % 60

  const formatted =
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0')

  return formatted
}
