<template>
  <div fluid class="secondary lighten-5">
    <base-loading v-if="!notifications" />
    <v-list flat dense v-else three-line max-width="100%" color="transparent">
      <organization-notifications-notification
        :key="notification.id"
        :notification="notification"
        @go-back-clicked="$emit('update:dialog', false)"
        v-for="(notification, index) in notifications.records"
        :has-divider="index < notifications.records.length - 1"
      />
    </v-list>
    <base-loading-more v-if="loading" />
    <div v-intersect="onScroll" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseLoading from '@/components/BaseLoading'
import BaseLoadingMore from '@/components/BaseLoadingMore'
import OrganizationNotificationsNotification from '@/components/notifications/OrganizationNotificationsNotification'
export default {
  props: {
    dialog: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaseLoading,
    BaseLoadingMore,
    OrganizationNotificationsNotification
  },
  computed: {
    ...mapGetters(['notifications']),
    offsetToken() {
      if (!this.notifications) {
        return null
      }
      return this.notifications.offset_token
    },
    requestOptions() {
      return {
        limit: this.limit,
        offset_token: this.offsetToken
      }
    }
  },
  data() {
    return {
      limit: 15,
      fetched: false,
      loading: false,
      isIntersecting: false
    }
  },
  methods: {
    ...mapActions(['getNotifications', 'addNotifications']),
    onScroll(entries) {
      this.isIntersecting = entries[0].isIntersecting
    },
    async getData() {
      try {
        await this.getNotifications({
          limit: this.limit
        })
        this.fetched = true
      } catch (error) {}
    },
    addData() {
      this.loading = true
      try {
        this.addNotifications(this.requestOptions)
      } catch (error) {}
      this.loading = false
    }
  },
  watch: {
    isIntersecting(newValue) {
      if (!newValue || !this.fetched || !this.offsetToken || this.loading) {
        return
      }
      this.addData()
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
