<template>
  <fragment>
    <div class="px-3 py-1 white">
      <v-text-field
        dense
        outlined
        clearable
        hide-details
        v-model="search"
        :label="$t('form.placeholders.search_tasks')"
      />
    </div>
    <v-list dense flat color="transparent">
      <v-list-item-group
        :value="value"
        :multiple="multiple"
        :mandatory="mandatory"
        active-class="primary--text"
        @change="handleDependencyChange"
      >
        <base-detailed-tasks-list-item
          v-for="task in filteredTasks"
          :key="task.id"
          :task="task"
        />
      </v-list-item-group>
    </v-list>
  </fragment>
</template>

<script>
import BaseDetailedTasksListItem from '@/components/BaseDetailedTasksListItem'
export default {
  props: {
    tasks: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    value: {
      required: true
    },
    height: {
      required: true
    }
  },
  data() {
    return {
      search: null
    }
  },
  computed: {
    filteredTasks() {
      if (!this.search) {
        return this.tasks
      }
      const q = this.search.toLowerCase()
      return this.tasks.filter((task) => {
        return task.title.toLowerCase().includes(q) || `${task.number}` === q
      })
    }
  },
  methods: {
    handleDependencyChange(ids) {
      this.$emit('update:value', ids)
      this.$emit('value-changed', ids)
    }
  },
  components: {
    BaseDetailedTasksListItem
  }
}
</script>
