<template>
  <v-text-field
    dense
    outlined
    :label="label"
    :rules="rules"
    :value="value"
    class="text-left"
    @input="updateValue"
    :validate-on-blur="validateOnBlur"
    :type="show ? 'text' : 'password'"
  >
    <div slot="append" @click="show = !show" class="cursor pointer toggler">
      <base-icon color="secondary.darken2" :name="show ? 'eye-slash' : 'eye'" />
    </div>
  </v-text-field>
</template>

<script>
import BaseIcon from './BaseIcon'
export default {
  components: { BaseIcon },
  props: {
    value: {
      required: true
    },
    rules: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    validateOnBlur: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('update:value', value)
      this.$emit('password-updated', value)
    }
  }
}
</script>
<style lang="scss" scoped>
.toggler {
  margin-top: 2px;
}
</style>
