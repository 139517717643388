<template>
  <search-dialog-list-item
    icon="t-clipboard-checked"
    :subtitle="
      selected.length
        ? $t('search_and_filter.n_items_selected', {
            count: selected.length,
            name: $t('common.task', { count: selected.length })
          })
        : null
    "
    :title="$t('common.task', { count: 0 })"
    :toolbar-title="$t('common.task', { count: 0 })"
  >
    <base-detailed-tasks-list
      height="auto"
      :tasks="tasks"
      :value.sync="selected"
    />
  </search-dialog-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseDetailedTasksList from '@/components/BaseDetailedTasksList'
import SearchDialogListItem from '@/components/search/SearchDialogListItem'
export default {
  props: {
    option: {
      required: true
    }
  },
  components: {
    SearchDialogListItem,
    BaseDetailedTasksList
  },
  computed: {
    ...mapGetters(['project', 'tasks'])
  },
  data() {
    return {
      selected: []
    }
  },
  watch: {
    selected(newValue) {
      this.$emit('update:option', newValue)
    }
  }
}
</script>
