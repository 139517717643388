<template>
  <base-dialog
    :dialog="dialog"
    card-color="white"
    content-class="fsmall-level-one"
    @go-back-clicked="$emit('update:dialog', false)"
    :title="$t('page.project_settings.tags.title')"
  >
    <template slot="toolbarActions">
      <v-btn
        text
        small
        class="me-1"
        color="primary"
        @click="openTagAdd"
        v-text="$t('common.add_tag')"
        v-if="!isMobile && fabVisible"
      />
    </template>
    <v-card
      tile
      flat
      height="100%"
      v-if="!fetching && projectTags"
      class="white info--text d-flex flex-column"
    >
      <v-card-title class="px-4 pt-1 pb-4 white flex-grow-0 body-1">
        <v-text-field
          dense
          outlined
          hide-details
          v-model="query"
          :label="$t('form.placeholders.search_tags')"
        >
          <template v-slot:prepend-inner>
            <div class="me-2" style="margin-top: 3px">
              <base-icon
                width="16"
                height="16"
                name="search-alt"
                color="secondary.base"
                className="d-flex align-center"
              />
            </div>
          </template>
        </v-text-field>
      </v-card-title>
      <v-card-text
        class="d-flex flex-grow-1"
        :class="{
          'flex-column align-center justify-center': items.length === 0
        }"
      >
        <div
          v-if="items.length > 0"
          class="flex-grow-1 pa-4 pb-14 secondary lighten-5 rounded-sm"
        >
          <settings-dialog-list-tags-tag
            :item="item"
            :key="item.id"
            :loading="loading"
            :editing="editing"
            v-for="item in items"
            @edit-clicked="editTag"
            @delete-clicked="confirmDelete"
          />
        </div>
        <template v-else>
          <div class="d-flex flex-column align-center" v-if="!query">
            <v-img
              width="168"
              height="148"
              src="../../assets/img/illustrations/project-empty-state.svg"
            />
            <h4
              class="mt-6 text-h4 secondary--text text--darken-2"
              v-text="$t('page.project_settings.tags.empty_state')"
            />
            <v-btn
              large
              depressed
              class="mt-6"
              color="primary"
              @click="sheet = true"
            >
              + {{ $t('common.add_tag') }}
            </v-btn>
          </div>
          <template v-else>
            <h4
              class="mt-6 text-h4 secondary--text text--darken-2"
              v-text="$t('search_and_filter.results.not_found.tags')"
            />
          </template>
        </template>
      </v-card-text>
      <base-confirm-dialog
        :loading="loading"
        ok-color="error base"
        @ok-clicked="deleteTag"
        :ok-text="$t('common.delete')"
        :dialog.sync="confirmationDialog"
        cancel-color="secondary darken-2"
        :cancel-text="$t('common.cancel')"
        :title="$t('page.project_settings.tags.delete_confirmation.title')"
      >
        <section
          v-html="
            $t('page.project_settings.tags.delete_confirmation.content', {
              title: currentTag ? currentTag.title : '',
              interpolation: { escapeValue: false }
            })
          "
        />
      </base-confirm-dialog>
      <base-inline-form
        :value.sync="sheet"
        card-text-class-name="px-4"
        :title="$t('common.add_tag')"
      >
        <v-form @submit.prevent="addTag">
          <v-text-field
            dense
            outlined
            autofocus
            type="text"
            v-model="title"
            :label="$t('form.labels.tag_title')"
          />
        </v-form>
        <base-inline-form-actions
          :loading="loading"
          @ok-clicked="addTag"
          @cancel-clicked="cancel"
          :ok-text="$t('common.done')"
        />
      </base-inline-form>
      <v-btn
        fab
        bottom
        fixed
        width="56"
        height="56"
        direction="top"
        color="primary"
        @click="openTagAdd"
        :left="$vuetify.rtl"
        :right="!$vuetify.rtl"
        v-if="isMobile && fabVisible"
      >
        <unicon name="plus" fill="#ffffff" />
      </v-btn>
    </v-card>
    <base-loading v-else />
  </base-dialog>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/BaseDialog'
import BaseLoading from '@/components/BaseLoading'
import BaseInlineForm from '@/components/BaseInlineForm'
import BaseConfirmDialog from '@/components/BaseConfirmDialog'
import SettingsDialogListTagsTag from './SettingsDialogListTagsTag2'
import BaseInlineFormActions from '@/components/BaseInlineFormActions'

export default {
  props: {
    project: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'projectTags']),
    items() {
      const { projectTags } = this
      const tags = projectTags ? Object.values(projectTags) : []
      if (!this.query) {
        return tags
      }
      return tags.filter(
        (tag) => tag.title.toLowerCase().indexOf(this.query.toLowerCase()) > -1
      )
    },
    fabVisible() {
      if (this.query) {
        return true
      }
      return this.items.length > 0
    }
  },
  data() {
    return {
      title: '',
      query: null,
      sheet: false,
      editing: false,
      loading: false,
      fetching: false,
      currentTag: null,
      confirmationDialog: false
    }
  },
  methods: {
    ...mapActions([
      'addProjectTag',
      'getProjectTags',
      'updateProjectTag',
      'deleteProjectTag'
    ]),
    confirmDelete(currentTag) {
      this.currentTag = currentTag
      this.confirmationDialog = true
    },
    openTagEdit(currentTag) {
      this.currentTag = currentTag
      this.title = currentTag.title
      this.sheet = true
    },
    openTagAdd() {
      this.sheet = true
      this.title = ''
    },
    async deleteTag() {
      this.loading = true
      this.confirmationDialog = false
      try {
        await this.deleteProjectTag(this.currentTag.id)
      } catch (error) {}
      this.loading = false
    },
    async addTag() {
      this.loading = true
      try {
        await this.addProjectTag({
          pid: this.project.id,
          data: {
            title: this.title
          }
        })
        this.sheet = false
      } catch (error) {}
      this.loading = false
    },
    async editTag({ tid, title }) {
      this.editing = true
      try {
        await this.updateProjectTag({
          tid,
          data: {
            title
          }
        })
        this.sheet = false
      } catch (error) {}
      this.editing = false
    },
    cancel() {
      this.sheet = false
    },
    async getData() {
      this.fetching = true
      try {
        await this.getProjectTags(this.project.id)
      } catch (error) {}
      this.fetching = false
    }
  },
  mounted() {
    this.getData()
  },
  components: {
    BaseIcon,
    BaseDialog,
    BaseLoading,
    BaseInlineForm,
    BaseConfirmDialog,
    BaseInlineFormActions,
    SettingsDialogListTagsTag
  }
}
</script>
