import request from '@/api/axios'

export const fetchInvitation = (token, errorHandle = true) =>
  request({
    url: `/invitations/${token}`,
    method: 'get',
    errorHandle
  })

export const createInvitation = (data) =>
  request({
    url: '/invitations',
    method: 'post',
    data
  })

export const acceptInvitation = (token, errorHandle = true) =>
  request({
    url: `/invitations/${token}/accept`,
    method: 'post',
    errorHandle
  })
