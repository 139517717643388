<template>
  <v-list dense>
    <v-list-item
      :key="item.title"
      @click="item.action"
      v-for="item in items"
      :class="{ 'd-none': !item.visible }"
    >
      <v-list-item-content :class="item.class">
        <v-list-item-title
          v-text="$t(`page.profile_setting.avatar.${item.title}`)"
        />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    hasImage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items() {
      return [
        {
          visible: true,
          title: 'choose_photo',
          class: 'info--text text-darekn-4',
          action: () => {
            this.$emit('add-photo-clicked')
          }
        },
        {
          visible: this.hasImage,
          title: 'remove_photo',
          class: 'error--text text-darekn-4',
          action: () => {
            this.$emit('remove-photo-clicked')
          }
        }
      ]
    }
  }
}
</script>
